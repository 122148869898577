import { FieldType } from "components/helpers/form_fields";

const getPassFields = (attrs) => {
  const {
    customLabelClass = "",
    customInputClass = "",
    customDropdownInputClass,
    customExpiryDropdownInputClass,
    customExpiryLabel,
    years,
    customExpiryLabel2,
    months,
    t
  } = attrs;

  return [
    [
      {
        name: "full_name",
        label: t("events.city_events.get_pass_form.full_name.label"),
        mandatory: true,
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: "vehicle_lpn",
        label: t("events.city_events.get_pass_form.vehicle_lpn.label"),
        mandatory: true,
        disabled: false,
        customInputClass,
        customLabelClass,
      },
    ],

    [
      {
        name: "email",
        label: t("events.city_events.get_pass_form.email_address.label"),
        mandatory: true,
        customInputClass,
        customLabelClass,
        disabled: false,
      },
    ],
    [
      {
        name: "payment_amount",
        label: t("events.city_events.get_pass_form.payment.total_payable_amount"),
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: "event_name",
        label: t("events.city_events.event_name"),
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: "payment_methods",
        label: t("events.city_events.get_pass_form.payment.payment_method.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [
          {
            value: 0,
            label: "Google Pay",
          },
          {
            value: 1,
            label: "Credit Card",
          },
        ].map(({ value, label }) => {
          return { value, label };
        }),
        customInputClass: customDropdownInputClass,
        customLabelClass,
        mandatory: true,
        placeholder: t("events.city_events.get_pass_form.payment.payment_method.placeholder"),
      },
    ],
    [
      {
        name: "expiration_year",
        label: t("events.city_events.get_pass_form.payment.credit_card.expiration_year.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: t("events.city_events.get_pass_form.payment.credit_card.expiration_year.placeholder"),
        options: years
          ? years.map((y) => {
              return { value: y, label: y };
            })
          : [],
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel2,
        disabled: false,
      },
      {
        name: "expiration_month",
        label: t("events.city_events.get_pass_form.payment.credit_card.expiration_month.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: t("events.city_events.get_pass_form.payment.credit_card.expiration_month.placeholder"),
        options: months.map((m) => {
          return { value: m < 10 ? `0${m}` : m, label: m < 10 ? `0${m}` : m };
        }),
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel,
        disabled: false,
      },
    ],
  ];
};

export { getPassFields };