import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row,} from 'reactstrap';
import { Form } from 'informed';
import { list as selectList} from 'selectors/list';
import { useSelector } from 'react-redux';
import { isEmpty, map } from 'underscore';
/* API */
import { filterFetcher as adminFilterFetcher } from 'api/admins';
import { filterFetcher } from 'api/special_permit_types';
/* Helpers */
import { sortFields } from 'components/helpers/fields/special_permit_types';
import useFetch from 'components/helpers/api/useFetch';
/* Base */
import { renderField } from 'components/base/forms/common_form';
import Button from 'components/base/button';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as SearchIcon } from 'assets/search_icon.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const CollapseContent = (props) => {
  const { setFormApi, assignFilters } = props
  const [admins] = useFetch(() => adminFilterFetcher({perPage: 10000}), [])
  const filters = useSelector(state => state.special_permit_type.filters)

  const sortFieldProps = {
    lSize: 5,
    iSize: 7,
    events: {
      onChange: (_e) => assignFilters()
    }
  }

  return (
    <Row className={`${styles.collapseSection} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <Col className="col-12 p-0">
        <Form getApi={setFormApi} initialValues={{...filters}} className='d-flex px-3'>
          {map(sortFields(admins, styles.formLabel, styles.formInput), (field, idx) => {
            if(idx === 0) {
              field = {...field, options: map(field.options, ({value, label}) => ({
                value,
                label: <>
                  <FontAwesomeIcon 
                    className='mr-2' 
                    icon={faCircle}
                    style={{ 
                      color: `${value === 'active' ? 'green' : 'red'}`,
                      width: 10
                    }}
                  />
                  {label}
                </>
              }))}
            }
            return (
              <div key={idx} className='w-25'>
                {renderField(field, {...sortFieldProps })}
              </div>
            )
          })}
        </Form>
      </Col>
    </Row>
  )
}

const ToolBar = (props) => {

  const { startFetching, match, setList, openModal, setFilters, filters, activeTab, setActiveTab, total, isResourceFetching } = props
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [name, setName] = useState(filters.name);

  const formApiRef = useRef()

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const searchPermitTypes = useCallback((query={}) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, { query })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[match, startFetching, setList])
  
  const handleToggleCollapse = (tab) => {
    if(isResourceFetching) return
    setActiveTab(activeTab => activeTab === tab ? null : tab)
  }

  const assignFilters = (values) => {
    let formFilters = {}
    if(formApiRef.current) { formFilters = formApiRef.current.getValues() }

    setFilters(Object.assign(formFilters, values))
    searchPermitTypes(Object.assign(formFilters, values))
  }

  useEffect(() => {
    if(isEmpty(filters) && formApiRef.current && !isEmpty(formApiRef.current.getValues())) { 
      formApiRef.current.setValues({status: '', payment_type: '', category: '', created_by: ''})
    }
  }, [filters])

  useEffect(() => {
    setName(filters.name || '')
  },[ filters.name])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab && activeTab !== 'create'}
      collapseContent={
        <CollapseContent  
          setFilters={setFilters}
          assignFilters={assignFilters}
          formApiRef={formApiRef}
          setFormApi={setFormApi}
        />
      }
      backTab={'special_permits'}
      className='justify-content-end'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span className={styles.title}>{`Special Permit Types (${total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={showSearchBox}
          hideIconOnHover={true}
          icon={<SearchIcon />}
          onClick={() => {handleToggleCollapse(); setShowSearchBox(true)}}
        >
          <Form 
            className={`${styles.searchForm} d-flex position-relative`} 
            onSubmit={() => assignFilters({name})}
          >
            <SearchIcon style={{position: 'absolute', top: '8px', left: '6px'}} />
            <input 
              type='search' 
              field='name'
              value={name}
              placeholder='Type to search'
              className='form-control shadow-none'
              onChange={(e) => setName(e.target.value)}
            />
            <Button className={styles.headerButton} type='submit'>
              search
            </Button>
          </Form>
        </SlideButton>
        <SlideButton
          isActive={activeTab === 'create'}
          icon={<PenToSquareSvg />}
          onClick={() => {
            handleToggleCollapse('create'); 
            setShowSearchBox(false)
            openModal('create')
          }}
        >
          <span className='mr-3 d-inline-block' style={{width: '196px'}}>Create Special Permit Type</span>
        </SlideButton>
        <SlideButton
          isActive={activeTab === 'filters'}
          status={`${activeTab === 'filters' ? 'activeSecondary' : 'secondary'}`}
          className={`${activeTab === 'filters' ? styles.activeFilterTab : ''}`}
          icon={<ArrowDownIcon className={`${activeTab === 'filters' ? styles.activeFilterIcon : ''}`} />}
          onClick={() => {handleToggleCollapse('filters'); setShowSearchBox(false)}}
        >
          <span className='mr-3 d-inline-block'>Sort</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
