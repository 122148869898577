
import React from 'react'
import { Route, withRouter } from 'react-router'
import Index from '../components/pages/parking_regulations/index'
import PropTypes from 'prop-types'

const Routing = (props) => {
  const {match } = props
  return (
    <Route exact path={match.path} component={Index}></Route>
  )
}

Routing.propTypes = {
  match: PropTypes.object.isRequired
}

export default withRouter(Routing);
