import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';

const Confirmation = ({closeModal, permitName}) => {

  const message = () => 
    <React.Fragment>
      <br/>
      <span className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>Your event parking application is successfully submitted. You will be notified once your application is approved.</span>
    </React.Fragment>

  return (
    
    <Container className='p-0 m-0'>
      <Col className="d-flex justify-content-center my-2 pb-2">
        <SuccessIcon/>
      </Col>
      <span className='mx-1' style={{fontWeight:"", marginTop:"10%"}}>Event Parking Application Successful</span>
    <Container className='mb-4' >
      <Col className="d-flex justify-content-center my-2">
        { message() }
      </Col>
      <br/>

      <Col className="d-flex justify-content-center my-2">
        {
          <Button 
            type='button' 
            onClick={() => {closeModal()}} 
            className={styles.confirmBtn}
          >
            Ok
          </Button>
        }  
      </Col>
    </Container>
  </Container>
  )
}

export default Confirmation;