import React, { useEffect, useState } from 'react'
import { map } from 'underscore';
import styles from './index.module.sass';
import { capitalize } from 'components/helpers';
import { ReactComponent as SettingIcon } from 'assets/setting_icon.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx, disableHighlight, width } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx !== 0 ? 'border-left' : ''}`}
      role='button'
      style={{cursor: `${disableHighlight && 'not-allowed'}`, width: `${width}`}}
    >
      { showText
        ? <span 
            className={`${showText ? 'd-inline-flex justify-content-center align-items-center' : 'd-none'} ${highlightedClass}`}
            style={{padding: '5px 10px', borderRadius: '15px' }}
          >
            {text}
          </span>
        : <span className={`${showText ? 'd-none' : 'd-inline-flex justify-content-center align-items-center'}`}>{icon}</span>
      }
    </div>
  )
}

const TableRow = (props) => {
  const {record, setRecord, modalType, history, openModal, selectedRecord} = props

  const actions = [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => {history.push(`permit_waiting_lists/${record.permit_type.id}`, {data: record})},
      text: 'View',
      isActive: modalType === 'show' && selectedRecord?.id === record?.id,
      width: '80px'
    },
    { 
      icon: <SettingIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      onClick: () => {openModal('configure')},
      text: 'Configure',
      isActive: modalType === 'configure' && selectedRecord?.id === record?.id,
      width: '80px'
    },
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.permit_type?.name}</td>
      <td>{capitalize(record?.status)}</td>
      <td>{capitalize(record?.waitlist_type)}</td>
      <td>{record?.users_enrolled}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow