import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";
import fetchApi from 'components/modules/fetch_api';

const getDetails = ({ticket_id}) => {
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/parking/citation_tickets/${ticket_id}?online_portal=${'true'}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const citationPayment = (params = {}) => {
  const { id, data } = params
  return fetchApi(`v1/parking/citation_tickets/${id}/pay?online_portal=true`, { method: 'POST', data })
};

const createDispute = (params = {}) => {
  let url = new URL(env.backend_url);
  url = `v1/disputes/online_dispute`;
  return fetchApi(url, { method: 'POST', data: { dispute: params } });
};

export { getDetails, citationPayment, createDispute };
