import React, { useCallback, useContext, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { capitalize } from 'components/helpers';
/* API */
import { revokePermit, update } from 'api/permits';
import { extendPermit, renewPermit } from 'api/special_permits';
/* Actions */
import { SET_LIST_ELEMENT } from 'actions/permits';
/* Components */
import RevokePermit from '../revoke';
import Show from '../show';
import Edit from '../edit';
/* Styles/Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Extend from '../show/extend';
import { flatten, values } from 'underscore';

const initState = { errors: {}, isSaving: false, permitTypes: [] }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'permitTypes':
      return { ...state, permitTypes: payload }
    case 'vehicleLpns':
      return { ...state, vehicleLpns: payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const PermitModal = ({openModal, modalType }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const record = useSelector(state => state.special_permit.records.record);
  const dispatchAction = useDispatch();

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'show':
        return { width: '680px' };
      case 'edit':
        return { width: '680px' };
      default:
        return { width };
    }
  },[modalType])
      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
    setState('innerModal', null)
  }, [openModal, setState])

  const updatePermit = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({id: record.id, data: { permit: data}})
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text:  `Changes for ${capitalize(record?.permit_type)} were successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, closeModal, setListElement, record])
        
  const revokeIssuedPermit = useCallback(async (values) => {
    setState('isSaving', true)
    try {
      const response = await revokePermit(values)
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'danger',
          text:  `${capitalize(record?.permit_type)} was successfully revoked`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, setListElement, record])


  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null); closeModal()}}
    />
  },[setState, closeModal]);

  const renewIssuedPermit = async () => {
    try {
      await renewPermit({id: record.id})
      addAlertMessages([{
        type: 'primary',
        text:  `${capitalize(record?.permit_type)} permit was successfully renewed`,
        onlyMessage: true,
      }], 'center')
    } catch(error) {
        if(error?.response?.data?.errors) { 
          addAlertMessages([{
            type: 'danger',
            text:  flatten(values(error?.response?.data.errors)),
            onlyMessage: true,
          }], 'center')
        }
    }finally {
      closeModal()
    }
  }

  const extendIssuedPermit = async (data) => {
    try {
      await extendPermit({id: record.id, permit: data })
      addAlertMessages([{
        type: 'notice',
        text:  `${capitalize(record?.permit_type)} permit was successfully extended`,
        onlyMessage: true,
      }], 'center')
    } catch(error) {
      if(error?.response?.data?.errors) { 
        addAlertMessages([{
          type: 'danger',
          text:  flatten(values(error?.response?.data.errors)),
          onlyMessage: true,
        }], 'center')
      }
    }finally {
      closeModal()
    }
  }
 
  return (
    <Modal 
      isOpen={!!modalType} 
      centered contentClassName={styles.permitModal} 
      style={{maxWidth: modalProps().width, width: '100%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        {
          modalType === 'revoke' && 
          <RevokePermit
          {...state}
            revokePermit={revokeIssuedPermit} 
            setState={setState}
            record={record} 
            closeModal={closeModal} 
          />
        }
         { modalType === 'edit' &&  
          <Edit 
            record={record} 
            {...state}
            closeModal={closeModal}
            updatePermit={updatePermit}
            setState={setState}
          /> 
        }
        { modalType === 'show' &&  
          <Show 
            record={record} 
            isSaving={state.isSaving} 
            openInnerModal={() => setState('innerModal', 'extend')}
            renewIssuedPermit={renewIssuedPermit}
          /> }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            style={{maxWidth: '484px', width: '100%'}}
          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody>
              {state.innerModal === 'extend' && 
                <Extend
                  extendIssuedPermit={extendIssuedPermit}
                  closeModals={() => {closeModal(); setState('innerModal', null)}}
                  record={record}
                  isSaving={state.isSaving}
                  errors={state.errors}
                  setState={setState}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal
