import React, { useState } from 'react'
import { Col, Container } from 'reactstrap';
import PermitModal from '../permitModal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ROLES } from "config/permissions";
import TableData from './tableData';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* API */
import { filterFetcher } from 'api/special_permit_types';
/* Actions */
import { ADD_LIST_ELEMENT, SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/special_permit_types';
import { invoke } from 'actions';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolBar';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState();

  const openModal = (modalType) => {
    setModalType(modalType)
  }

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Col xs={12} className='p-0'>
          <ToolBar 
            activeTab={activeTab}  
            setActiveTab={setActiveTab}
            openModal={openModal}
            {...props}
          />
        </Col>
        <Col xs={12} className='p-0'>
          <TableData 
            {...props} 
            setModalType={setModalType} 
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            modalType={modalType}
          />
        </Col>
        <PermitModal
          modalType={modalType}
          openModal={openModal}
          {...props}
          setActiveTab={setActiveTab}
        />
      </Container>
    </AccessControl>
  )
}

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'special_permit_type';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
    }, 
    dispatch
  );
}

const mapState = (state) => {
  const record = state.special_permit_type.records.record
  const filters = state.special_permit_type.filters
  return { record, filters }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {}, 
  SET_FILTERS
);
