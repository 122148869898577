import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { flatten } from 'underscore';
import Shift from '../shift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { movePosition, destroy } from 'api/permit_waiting_lists';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Delete from '../delete';

const initState = { errors: {}, isSaving: false, parkingLots: [], permitTypes: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const WaitlistModal = (props) => {
  const { userWaitingListRecord, openModal, modalType, setUserList, setCallStatus, setActiveTab } = props
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])
  
  const moveWaitlistPosition = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await movePosition({data: {new_position: data['new_position']}, id: userWaitingListRecord?.id })
      if(response.status === 200 || response.status === 201){
        closeModal();
        addAlertMessages([{
          type: 'primary',
          text: `Position for user ${response?.data?.user_full_name} with ID ${response?.data?.id} was succesfully updated`,
          onlyMessage: true
          }],
          'center'
        )
        setUserList()
        setCallStatus(false)
      }
    } catch (error) {
      toast.error(`${flatten(Object.values(error?.response?.data?.errors))}`);
    }finally {
      setState('isSaving', false)
    }
  },[setState, addAlertMessages, userWaitingListRecord, closeModal, setUserList, setCallStatus])

  const deleteFromWaitlist = useCallback(async () => {
    setState('isDeleting', true)
    try {
      const response = await destroy({id: userWaitingListRecord?.id })
      if(response.status === 200 || response.status === 201){
        closeModal()
        addAlertMessages([{
          type: 'danger',
          text: `The waitlist for user ${userWaitingListRecord?.full_name} with ID ${userWaitingListRecord?.id} was successfully deleted`,
          onlyMessage: true
          }],
          'center'
        )
        setUserList()
        setCallStatus(false)
      }
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      setState('isDeleting', false)
    }
  },[setState,setErrors, addAlertMessages, closeModal, userWaitingListRecord, setUserList, setCallStatus])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'shift':
        return {title: 'Shift User', maxWidth: '480px'}
      case 'delete':
        return {title: 'Remove User', maxWidth: '480px'}
      default:
        return {maxWidth}
    }
  },[modalType]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'shift' && 
          <Shift
            closeModal={closeModal}
            userWaitingListRecord={userWaitingListRecord}
            moveWaitlistPosition={moveWaitlistPosition}
            errors={errors}
            setErrors={setErrors}
          />
        }
        {
          modalType === 'delete' && 
          <Delete
            closeModal={closeModal}
            userWaitingListRecord={userWaitingListRecord}
            deleteFromWaitlist={deleteFromWaitlist}
            errors={errors}
            setErrors={setErrors}
          />
        }
      </ModalBody>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Modal>
  )
}

export default WaitlistModal;