import { ValueAddedServicesActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records } = reduceEntity(ValueAddedServicesActions);

const ValueAddedServicesReducers = combineReducers({
  index,
  records,
});

export default ValueAddedServicesReducers;
