import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Col, Container,Row } from 'reactstrap';
import { Form } from 'informed';
/* Module */
import PropTypes from 'prop-types';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
/* API */
/* Helpers */
import Loader from 'components/helpers/loader';
import { displayUnixTimestamp } from 'components/helpers';
import {detailsFields, timeFields,lotFields } from 'components/helpers/fields/app/city_events/details'
/* Base */
import Button from 'components/base/button';
import { renderFieldsWithGrid, renderField } from 'components/base/forms/common_form';
/* Assets */
import styles from './index.module.sass'
import { isEmpty,map } from 'underscore'
import { renderFields } from 'components/base/forms/common_form';
import LocationForm from './location'
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'
import { show } from 'api/webapp/city_events'


const Details = (props) => {
  const {t, startFetching  ,record, errors, openInnerLocationModal, setFetchedEvent, setSelectedLot, fetchedEvent, setWhichLocation, openGetPassModal} = props
  const [isFetching, setIsFetching] = useState(false)
  const formApiRef = useRef();
  const [event, setEvent] = useState([])

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customDateTimeClass: styles.formDateInput
  }

  const singleFieldProps = {
    lSize: 3,
    iSize: 9,
  }
  const eventData = useCallback((data) => {
    const { description, location, parking_lots} = data  
    const lots = {}
    map(parking_lots, (lot, idx) => (lots[`lot-${idx}`] = {name:`${lot?.name}`}))
    // Sorting function
    const eventDatesAndTimes = data?.event_dates_and_times?.sort((a, b) => {
      const dateComparison = new Date(a.date) - new Date(b.date);
    
      if (dateComparison === 0) {
        const startTimeComparison = a.start_time.localeCompare(b.start_time);
        if (startTimeComparison === 0) {
          // If start times are the same, compare end times
          return a.end_time.localeCompare(b.end_time);
        }
        return startTimeComparison;
      }
    
      return dateComparison;
    });

    const convertTo12HourFormat = (time24) => {
      const [hours, minutes] = time24.split(':');
      const suffix = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
      return `${formattedHours}:${minutes} ${suffix}`;
    }
    
    const dateTime = {}
    map(eventDatesAndTimes, (datetime, idx) => (dateTime[`eventDateTime-${idx}`] = {
      date:`${displayUnixTimestamp(Date.parse(datetime.date) / 1000, 'Do MMM YYYY')}`, 
      time:`${convertTo12HourFormat(datetime?.start_time)} to ${convertTo12HourFormat(datetime?.end_time)}`}))
    return ({  
      location: location?.full_address,
      description: description,
      eventDateTime: dateTime ,  
      lot: lots     
    })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchEventApplication = async () => {
      try {
        const response = await show({id: record.id})
          setEvent(eventData(response?.data));
          setFetchedEvent(response.data);
        
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchEventApplication();

  }, [record, startFetching, eventData, setFetchedEvent])

  
  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        parkingLotLocation={event?.location}
        formApiRef={formApiRef}
        t={t}
      />
    );
  }

  const getFields = () => {
    return detailsFields({mute: true,t: t, renderLocationModal: renderLocationModal.bind(this),...fieldAttrs, customDateTimeClass: styles.formDateInput})
  }


  const doubleFieldProps = {
    lSize: 2,
    iSize: 9, 
   
  }

  const lotFieldProps = {
    lSize: 1,
    iSize: 12, 
   
  }

  if(isFetching) { return <Loader /> }
  const newFields = getFields()

  const handleParkingLotView = (lot) => {
    setSelectedLot(lot)
    setWhichLocation('parkingLot')
    openInnerLocationModal();
  }

  const handleEventLocationView = () => {
    setWhichLocation('event');
    openInnerLocationModal();
  }
  return (
    <Container className='p-0 m-0'>                  
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{record?.event_name}</p>
      <Form initialValues={event} getApi={setFormApi} className={styles.form} onSubmit={''}>
        <Row className='mx-auto w-100'>
        { map(newFields[0], (field, idx) => {
            if(idx === 0) {
              field = {
                ...field,
                icon: <LocationIcon className={styles.LocationIcon} />, 
                customInputClass: fieldAttrs.customInputClass.concat(styles.iconInput,' ')
              }
            }
            
            return (
              <React.Fragment>
                { idx === 0 && 
                  <Col className={`m-0 pt-1 ${styles['fieldset-single']} pl-1`} xs={12}>
                    { renderField(field, { ...singleFieldProps} )}
                    <Button className={styles.mapBtn} onClick={() => handleEventLocationView()}>{t("events.city_events.map_view")}</Button>
                  </Col>
                }
                {idx === 1 &&
                  <Col className={`m-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                    { renderField(field, { ...singleFieldProps} )}
                  </Col> 
                }
                
              </React.Fragment>
            )
        })
        }
        <div style={{display:'flex'}}>
          <label className={styles.label1}>{t("events.city_events.event_dates_and_times")}</label>
          <Col className='m-0 p-0 pl-2 ml-2'>
            {!isEmpty(event) && map(record?.event_dates_and_times, (date, idx) => <Col className="m-0 p-0  pl-4 pt-1" xs={12}>
            <Col className='d-flex flex-wrap px-0'>
              <div className={`${styles.fieldset} pl-1`}>
                {renderFieldsWithGrid(timeFields({date: idx, customInputClass: styles.formDateTimeInput, customLabelClass: styles.formLabel})?.slice(0,1),1,12, {...doubleFieldProps, errors, date: date})}
              </div>
              <Col> <span className={`${styles['fieldset-dateStroke']} position-absolute`}></span></Col>
              {<div className={`${styles.fieldset} pr-2`}>
                {renderFieldsWithGrid(timeFields({date:idx, customInputClass: styles.formDateTimeInput, customLabelClass: styles.formLabel})?.slice(1,2),1,12,{date: date}, {...doubleFieldProps, errors, date: date})}
              </div>}
            </Col>
          </Col>)
          
            }
          </Col>
        </div>
        <div style={{display:'flex'}}>
          <label className={styles.label2}>{t("events.city_events.parking_lots")}</label>
          <Row className={`m-0 p-0 ${styles.fieldset2}`}>
            {!isEmpty(fetchedEvent) && map(fetchedEvent?.parking_lots, (lot, idx) => 
              <Col className={`m-0 ${fetchedEvent?.parking_lots?.length === 1 ? 'pl-3 ml-1' : ""}`} xs={fetchedEvent?.parking_lots.length === 1 ? 9 : 6}>
              {renderFields(lotFields({idx: idx, customInputClass: fieldAttrs.customInputClass, 
                icon:<Button type='button' className={fetchedEvent?.parking_lots.length===1 ? `${styles.viewBtn} ${styles['viewBtn-bg-secondary']}` : styles.viewBtn}  onClick={() => handleParkingLotView(lot)}>{<EyeIcon/>}</Button>, 
                customLabelClass:styles.formLabel}), {...lotFieldProps, errors})
              }
             </Col>
            )}
          </Row>
         </div>
         </Row>
         <Row className={styles.noteRow}><p className={styles.note}>{t("events.city_events.pass_note")}</p></Row>
         {fetchedEvent?.parking_lots.map((lot) => {
            return lot.passes_sold_out}).includes('NO') && fetchedEvent?.status !== 'expired' &&
              <Button className={styles.getPassBtn} onClick={() => openGetPassModal()}>
                {`${t("events.city_events.get_parking_pass")} (${fetchedEvent?.attendees_cost_type==='paid'? `$ ${fetchedEvent?.attendees_cost}` : "Free"})`}
              </Button>
            }
      </Form>
    </Container> 
  )
}

Details.propTypes = {
  startFetching: PropTypes.func.isRequired,
};


export default Details;