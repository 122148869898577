import { EventsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(EventsActions);

const EventsReducers = combineReducers({
  index,
  records,
});

export default EventsReducers;
