import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const PermitSettingConfirmation = (props) => {

  const { updatePermitSettings, closeModal, allowMultipleVehicle, setting, allowMultipleDailyPermit } = props;

  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Are you sure?</p>
      <div className={styles.content}>
        {setting === 'vehicle_setting' && <Col className='mt-3 mb-3'>
          This will 
          <span className={`${styles.boldText} ml-1 mr-1`}>{allowMultipleVehicle ? 'Disable' : 'Enable'}</span>
          users to add multiple vehicles to a permit.
        </Col>}
        {setting === 'daily_permit_setting' && <Col className='mt-3 mb-3'>
          This will 
          <span className={`${styles.boldText} ml-1 mr-1`}>{allowMultipleDailyPermit ? 'Disable' : 'Enable'}</span>
          users to apply for multiple daily permits in single transaction.
        </Col>}
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        {setting === 'vehicle_setting' && <Button type='button' onClick={updatePermitSettings} className={allowMultipleVehicle ? styles.disableButton : styles.enableButton}>
          {allowMultipleVehicle ? 'Disable' : 'Enable'}
        </Button>}
        {setting === 'daily_permit_setting' && <Button type='button' onClick={updatePermitSettings} className={allowMultipleDailyPermit ? styles.disableButton : styles.enableButton}>
          {allowMultipleDailyPermit ? 'Disable' : 'Enable'}
        </Button>}
      </Col>
    </Col>
  )
}

export default PermitSettingConfirmation;