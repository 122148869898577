import React, { useEffect, useCallback, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { renderFieldsWithGrid } from 'components/base/forms/common_form'
import { fieldsNew } from 'components/helpers/fields/account_types'
import { isEmpty } from 'underscore'

const Create = ({closeModal, createAccountType, isSaving, errors, setErrors}) => {
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customInputTimeClass: styles.formInputTime
  }

  const fieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (_e) => setErrors({})
    }

  }

  const validateValues = (formState) => {
    const errors = {}
     const { name, description } = formState;
     if(!name) { errors['name'] = ['Account Type Name is required']}
     if(!description) { errors['description'] = ['Description is required']}
     return errors
   }

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef?.current?.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    createAccountType(values);
  },[setErrors, createAccountType]);


  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fields = fieldsNew(fieldAttrs)

  return (
    <Container>
      <p className={`${styles.title}  p-0`}></p>
      <Form getApi={setFormApi} className='m-auto' onSubmit={save}>
        {({ formState }) => {
          return (
            <Row className='w-100' style={{padding: '10px', marginLeft: '-5px'}}>
              <Col className={styles.modalContent}>
                {renderFieldsWithGrid(fields?.slice(0,2), 1, 12, { ...fieldProps, errors })}
              </Col>
              <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={styles.button}  isLoading={isSaving}>
                  Create
                </Button>
              </Col>
            </Row>
          )
        }}
      </Form>
    </Container>
  )
}

export default Create;
