import React, { useCallback } from 'react'
import { list as selectList } from 'selectors/list';
/* API */
import { filterFetcher } from 'api/permit_types';
/* Styles/Assets */
import DragScroll from 'components/helpers/dragScroll';
/* Base */
import { ReactComponent as SettingIcon } from 'assets/permit_type_code_icon.svg';
import Button from 'components/base/button';
import 'react-multi-carousel/lib/styles.css';
import styles from "./datacard.module.sass";
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash_icon.svg';
import { Card } from 'reactstrap';
  
const FilterForm = (props) => {
  const {  startFetching, setInitialList, match, permitTypeCodeList, setCode, openModal, modalType } = props

  const filterPermitTypeCodes = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, { query: filters })))
      .then((res) => {
        setInitialList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setInitialList, startFetching, match.params])

  const ClickCard = (code) => {
    filterPermitTypeCodes( {permit_type_code_id: code.id})
    setCode(code)
  }

  const Image = ({ item }) => {
    return <React.Fragment>
      {
        <Card 
          draggable
          className={`${styles.cardTemplate} d-flex flex-row block bg-white `}
          onClick={() => {ClickCard(item) }}>
          <div className={styles.cnt5}>
            <Button
              className={styles.btnCard}
              style={{ backgroundColor: item?.color }}
              icon={<SettingIcon />}
              >
            </Button>
          </div>
          <div className={styles.cnt4}>
            <EditIcon
              type='button'
              className={styles.cardbutton1}
              onClick={() => {
                openModal('update'); ClickCard(item)
              }}
              text='Edit'
              isActive={modalType === 'update'}
            />
          </div>
          <div className={styles.cnt6}>
            <TrashIcon
              type='button'
              className={styles.cardbutton2}
              onClick={() => {
                openModal('destroy'); ClickCard(item)
              }}
              text='Destroy'
              isActive={modalType === 'destroy'}
            />
          </div>
          <div class='container' className={styles.cnt3}>
            <div class='item'>{item?.code}</div>
            <div class='item' className={styles.cnt2}>{item?.permit_type_details?.length}</div>
            <div class='item' className={styles.cnt1}>Permit Types</div>
          </div>
        </Card>
      }
    </React.Fragment>
    };
  return (
    <div className={`${styles.cardContainer} hide-scroll`}>
      <DragScroll>
          {permitTypeCodeList?.map((item) => {
            return (
              <div className='mr-3'>
                { <Image item={item}/> }
                {
                  (
                    <p className='d-flex align-items-center pt-3 m-0' style={{borderRadius: 8}}></p>
                  )
                }
              </div>
            )
          })}
      </DragScroll>
    </div>
  );
}
  

export default FilterForm;