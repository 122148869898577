import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';

const { index, destroy, create, update } = resourceApi('parking_documents');

const upload = (data) => {
  return fetchApi(`dashboard/parking_documents/upload`, { method: 'PUT', data })  
}

export {
  index,
  upload,
  destroy,
  create,
  update
};