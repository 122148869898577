import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterFetcher } from 'api/parking_slots';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/parking_slots';
import { invoke } from 'actions';
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';
import TableData from './tableData';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>  
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} modalType={modalType} activeTab={activeTab} setModalType={setModalType} setActiveTab={setActiveTab} />
          </Col>
        </Row>
      </Container>
    </AccessControl>
  )
}

const resource = 'parking_slot';

Index.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object,
  currentUserPermissions: PropTypes.array,
  isResourceFetching: PropTypes.bool.isRequired,
};

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD) 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.parking_slot.filters,
    selectedRecord: state.parking_slot.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);