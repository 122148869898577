import { generatePath } from "react-router";
import fetchApi from "components/modules/fetch_api";

const index = (params = {}) => {
  const { id, page, perPage, query, permit_type_id, parking_lot_id, nestedParams = {} } = params;
  return fetchApi(
    generatePath(`dashboard/permit_waiting_lists`, nestedParams),
    { method: 'GET', params: { id, page, per_page: perPage, ...query, permit_type_id, parking_lot_id } }
  );
};

const filterFetcher = (params = {}) => {
  const { page = 1, perPage = 20, filters = {}, query, permit_type_id, parking_lot_id } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
      user_id: filters['user_id'],
      permit_type_id: filters['permit_type_id'],
    },
    permit_type_id,
    parking_lot_id
  });
};

const show = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permit_waiting_lists/${id}`, { method: 'GET', data })
};

const users = (params) => {
  const { id, page, perPage, query, permit_type_id, parking_lot_id, nestedParams = {}  } = params
  return fetchApi(
    generatePath(`dashboard/permit_waiting_lists/waitlist_users`, nestedParams),
    { method: 'GET', params: { id, page, per_page: perPage, ...query, permit_type_id, parking_lot_id } }
  );
};

const filterFetcherUser = (params = {}) => {
  const { page = 1, perPage = 20, filters = {}, query, permit_type_id, parking_lot_id } = params;

  return users({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
      user_id: filters['user_id'],
      permit_type_id: filters['permit_type_id'],
    },
    permit_type_id,
    parking_lot_id
  });
};

const movePosition = (params = {}) => {
  const { id, data = {} } = params;
  const { nestedParams } = data

  return fetchApi(generatePath(`dashboard/permit_waiting_lists/${id}/move_position`, nestedParams), {
    method: 'PUT',
    params: data,
  })
};

const destroy = (params = {}) => {
  const { id, nestedParams = {} } = params;
  return fetchApi(generatePath(`dashboard/permit_waiting_lists/${id}`, nestedParams), {
    method: 'DELETE'
  })
};

const update = (params = {}) => {
  const { id, data, nestedParams = {} } = params;
  return fetchApi(generatePath(`dashboard/permit_waitlist_settings/${id}`, nestedParams), {
    method: 'PUT',
    data
  })
};

export {filterFetcher, show, users, filterFetcherUser, movePosition, destroy, update};
