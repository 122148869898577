import React, {useState, useEffect, useCallback} from 'react';
import { Tabs, Input, Card, Button, Col, ConfigProvider } from 'antd';
import styles from './index.module.sass'; 
import {ReactComponent as VehicleIcon } from 'assets/app/vehicle-icon.svg'
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { getCurrentParkingSessions, getParkingSessionsHistory } from 'api/webapp/my_parkings';
import { displayUnixTimestamp, capitalize } from 'components/helpers';
import ParkingModal from './parking_modal'
import { isEmpty } from 'underscore';
import { CardBody, Collapse, Row } from 'reactstrap';
import BaseButton from 'components/base/button'

const { TabPane } = Tabs;

const MyParkings = () => {
  const [currentParkingSessions, setCurrentParkingSessions] = useState();
  const [recentParkingSessions, setRecentParkingSessions] = useState();
  const [filteredCurrentParkingSessions, setFilteredCurrentParkingSessions] = useState(currentParkingSessions)
  const [filteredRecentParkingSessions, setFilteredRecentParkingSessions] = useState(recentParkingSessions)
  const [selectedTab, setSelectedTab] = useState('current')
  const [searchText, setSearchText] = useState('');
  const [record, setRecord] = useState('')
  const [modalType, setModalType] = useState()
  const [showFilter, setShowFilter] = useState(false)
  const openModal = useCallback((modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'details':
        val = 'details'
        break;      
      default:
        break;
    }
    setModalType(val)
  },[setModalType])

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const fetchCurrentParkingSessions = async () => {
    try {
      const response = await getCurrentParkingSessions()
      setCurrentParkingSessions(response.data)
      setFilteredCurrentParkingSessions(response.data)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  useEffect(() => {
    if(selectedTab==='current'){fetchCurrentParkingSessions()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setCurrentParkingSessions, selectedTab])

  const fetchParkingSessionsHistory = async () => {
    try {
      const response = await getParkingSessionsHistory()
      setRecentParkingSessions(response.data)
      setFilteredRecentParkingSessions(response.data)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  useEffect(() => {
    if(selectedTab==='history'){fetchParkingSessionsHistory()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setRecentParkingSessions, selectedTab])

  const secondsToHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    var hDisplay = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes} Hour`
    var minsDisplay = `${minutes} Minutes`
    return hours>0 ? hDisplay : minsDisplay;
  }

  const handleSearch = (value) => {
    const parkingSessions = selectedTab === 'current' ? currentParkingSessions : recentParkingSessions
    setSearchText(value);
    if(!value){
      selectedTab==='current'? fetchCurrentParkingSessions() : fetchParkingSessionsHistory()
    }
    const filteredSessions = parkingSessions?.filter(vehicle =>
      Object.values(vehicle).some(val =>
        typeof val === 'object' && val 
          ? Object?.values(val)?.some(nestedVal => nestedVal?.toString()?.toLowerCase()?.includes(value?.toLowerCase()))
          : val?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
    selectedTab==='current'? setFilteredCurrentParkingSessions(filteredSessions) : setFilteredRecentParkingSessions(filteredSessions)
  };

  const onPrimaryTabChange = (key) => {
    setSelectedTab(key)
  };

  const renderCurrentParkingSessions = () => {
    return <React.Fragment>
      {!isEmpty(filteredCurrentParkingSessions) && <div className={styles.cardRow}>
        {filteredCurrentParkingSessions?.map((data, index) => (
          <Card className={styles.customCard} key={index} onClick={() => setRecord(data)}>
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>
                <Col className={`${styles.imageCol}`}>
                  <VehicleIcon className={styles.cardImage}/>
                  <p className={styles.status}>In progress</p>
                </Col>
                <div className='pl-3'>
                  <p className={styles.cardTitle}>{data?.lot.name}</p>
                  <p className={styles.cardText}><span><LocationIcon className={styles.LocationIcon}/></span>{"Address"}</p>
                  <p className={styles.cardText}>{data?.lot?.location?.full_address}</p>
                  <div className={styles.recordSection}>
                    <div className={styles.recordItem}>
                      <p className={styles.recordLabel}>Entry Time:</p>
                      <p className={styles.recordValue}>{displayUnixTimestamp(data?.check_in, 'hh:mm a, DD/MM/YYYY')}</p>
                    </div>
                    <div className={`${styles.recordItem} pl-4`}>
                      <p className={styles.recordLabel}>Exit Time:</p>
                      <p className={styles.recordValue}>{displayUnixTimestamp(data?.check_out, 'hh:mm a, DD/MM/YYYY')}</p>
                    </div>
                    <div className={`${styles.recordItem} pl-4`}>
                      <p className={styles.recordLabel}>Parking Duration</p>
                      <p className={styles.recordValue}>{secondsToHours(data?.check_out - data?.check_in)}</p>
                    </div>
                 </div>
                </div>
              </div>
              <div className={styles.rightContent}>
                <p className={data.paid? styles.paid : styles.unpaid}>{data.paid ? "PAID" : "UNPAID"}</p>
                <Button type="primary" onClick={() => openModal('details') && setRecord(record)}>{"More Details >"}</Button>
              </div>
            </div>
          </Card>
        ))}
      </div>}
      {<div>
          
      </div>}
    </React.Fragment>
  }

  const renderRecentParkingSessions = () => {
    return <React.Fragment>
      
      <div className={styles.cardRow}>
        {filteredRecentParkingSessions?.map((data, index) => (
          <Card className={styles.customCard} key={index} onClick={() => setRecord(data)}>
            <div className={styles.cardContent}>
              <div className={styles.leftContent}>
                <Col className={styles.imageCol}>
                  <VehicleIcon className={styles.cardImage}/>
                  <p className={`${styles.status} pl-2`}>{capitalize(data?.status)}</p>
                </Col>
                <div className='pl-3'>
                  <p className={styles.cardTitle}>{data?.lot.name}</p>
                  <p className={styles.cardText}><span><LocationIcon className={styles.LocationIcon}/></span>{"Address"}</p>
                  <p className={styles.cardText}>{data?.lot?.location?.full_address}</p>
                  <div className={styles.recordSection}>
                    <div className={styles.recordItem}>
                      <p className={styles.recordLabel}>Entry Time:</p>
                      <p className={styles.recordValue}>{displayUnixTimestamp(data?.check_in, 'hh:mm a, DD/MM/YYYY')}</p>
                    </div>
                    <div className={`${styles.recordItem} pl-4`}>
                      <p className={styles.recordLabel}>Exit Time:</p>
                      <p className={styles.recordValue}>{displayUnixTimestamp(data?.check_out, 'hh:mm a, DD/MM/YYYY')}</p>
                    </div>
                    <div className={`${styles.recordItem} pl-4`}>
                      <p className={styles.recordLabel}>Parking Duration</p>
                      <p className={styles.recordValue}>{secondsToHours(data?.check_out - data?.check_in)}</p>
                    </div>
                 </div>
                </div>
              </div>
              <div className={styles.rightContent}>
                <p className={data.paid? styles.paid : styles.unpaid}>{data.paid ? "PAID" : "UNPAID"}</p>
                <Button type="primary" onClick={() => openModal('details') && setRecord(record)}>{"More Details >"}</Button>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </React.Fragment>
  }

  const operations = (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3A9CED",
        },
      }}
    >
      <Row className="d-flex">
        <BaseButton
          onClick={toggleFilter}
          className={`${styles.btnFilter} ${showFilter ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${showFilter ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Row>
    </ConfigProvider>
  );

  return (
    <div className={styles.newPage}>
      <p className={styles.title}>My Parkings</p>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A9CED",
          },
        }}
      >
          <Tabs
            className={styles.tabs}
            defaultActiveKey={selectedTab}
            tabBarStyle={{ background: "#ffffff", padding: "10px" }}
            onChange={onPrimaryTabChange}
            tabBarExtraContent={operations}
          >
            <TabPane
              key="current"
              tab={
                <p style={{ fontSize: "18px", padding: "0", margin: "0"}}>
                  Current
                </p>
              }
            >
              <Collapse isOpen={showFilter} className={styles.collapse}>
                <Card className="border-0">
                  <CardBody className="p-0 m-0">
                    <Row
                      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`}
                      sm="12"
                    >
                      <Input.Search
                        className={styles.searchButton}
                        placeholder="Search"
                        enterButton="Search"
                        onSearch={handleSearch}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
              {renderCurrentParkingSessions()}
              {(isEmpty(filteredCurrentParkingSessions) && isEmpty(currentParkingSessions)) && <p className={styles.noCP}>You don't have any ongoing parking sessions present !</p>}
            </TabPane>
            <TabPane
              key="history"
              tab={
                <p style={{ fontSize: "18px", padding: "0", margin: "0" }}>
                  History
                </p>
              }
            >
              <Collapse isOpen={showFilter} className={styles.collapse}>
                <Card className="border-0">
                  <CardBody className="p-0 m-0">
                    <Row
                      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`}
                      sm="12"
                    >
                      <Input.Search
                        className={styles.searchButton}
                        placeholder="Search"
                        enterButton={"Search"}
                        onSearch={handleSearch}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
              {renderRecentParkingSessions()}
              {(isEmpty(filteredRecentParkingSessions) && isEmpty(recentParkingSessions)) && <p className={styles.noCP}>You don't have any parking sessions present !</p>}

            </TabPane>
          </Tabs>
      </ConfigProvider>
      <ParkingModal
        secondsToHours={secondsToHours}
        record={record}
        modalType={modalType}
        openModal={openModal}
      />
    </div>
  );
};

export default MyParkings;