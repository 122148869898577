import { FieldType } from "components/helpers/form_fields";

const auditLogFilterFields = (attrs) => {
  const { customInputClass='', customLabelClass='', options} = attrs;
  
  return [
    {
      name: "type_of",
      customInputClass,
      customLabelClass,
      type: FieldType.SELECT_FIELD,
      options: options,
    },
  ]
}

export {auditLogFilterFields};