import React, { useCallback, useEffect, useState } from 'react'
import {  Col, Row } from 'reactstrap';
/* Component */
import FilterForm from './filterForm';
/* API */
import { search as dropdownsSearch } from "api/dropdowns";
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as BoxedIcon } from 'assets/boxed-icon.svg';
import { ReactComponent as ExclamationIcon } from 'assets/exclamation.svg';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { ReactComponent as SettingIcon } from 'assets/setting-gear-icon.svg';
/* Base */
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import SlideButton from 'components/base/slide_button';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const CollapseContent = ({ startFetching, setList, setFilters, match, resource, currentUser, activeTab, autoAccept, openModal}) => {
  const [dropdowns, setDropdowns] = useState({
    agencies: [],
    officers: [],
    parkingLots: [],
    statuses: [],
    types: [],
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(
          dropdownsSearch("agency_list", { admin_id: currentUser.id })
        ).then((response) => setDropdowns(state => ({...state, agencies: response.data }))),
        startFetching(
          dropdownsSearch("admins_by_role-officer", { admin_id: currentUser.id })
        ).then((response) => setDropdowns(state => ({...state, officers: response.data }))),
        startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
          setDropdowns(state => ({...state, parkingLots: response.data }))),
        startFetching(
          dropdownsSearch("tickets_statuses_field")
        ).then((response) => setDropdowns(state => ({...state, statuses: response.data }))),
        startFetching(dropdownsSearch("tickets_types_field")).then((response) =>
          setDropdowns(state => ({...state, types: response.data }))),
      ])
    }

    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      {activeTab === 'filters' && <FilterForm 
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
      />}

      {activeTab === 'setting' && 
        <Row>
          <input
            type="checkbox"
            checked={autoAccept}
            onChange={() => openModal('setting_confirmation')}
            className={styles.customCheckStyle}
          />
          <span className={styles.checkboxDescription}>
            Auto-Accept violation reports when LPN is identified
          </span>
        </Row>
      }
    </Row>
  )
}

const ToolBar = (props) => {

  const {isFetching, activeTab, setActiveTab} = props;

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  const ViolationTypesIcon = () => {
    return (
      <div className='position-relative'>
        <BoxedIcon />
        <ExclamationIcon  style={{ position: 'absolute', top: '7px', left: '10px' }}/>
      </div>
    )
  }

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={`${styles.title} general-text-1`}>
          {`Violations (${props.total})`}
        </span>
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        <SlideButton
          isActive={true}
          className={`${styles.autoButton} ${activeTab === 'setting' ? styles.activeViolation : '' }`}
          onClick={() => handleToggleCollapse('setting')}
        >
            <span className={`${styles.btnViolation}`}>Violation Auto Accept</span>
        </SlideButton>
        <SlideButton
          icon={<ViolationTypesIcon />}
        >
          <Link to='violation_types' className='mr-3 d-inline-block text-decoration-none text-white' style={{width: '110px'}}>Violation Types</Link>
        </SlideButton>
        <SlideButton
          icon={<SettingIcon />}
        >
          <Link to='violators' className='mr-3 d-inline-block text-decoration-none text-white' style={{width: '160px'}}>Violators Management</Link>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;