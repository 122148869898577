import React, { useCallback, useMemo, useRef } from 'react'
import { Form } from 'informed';
import { list as selectList } from 'selectors/list';
/* API */
import { filterFetcher } from 'api/permit_applications';
/* Helpers */
import { searchFields } from 'components/helpers/fields/permit_applications/index';
import { displayDate } from 'components/helpers';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
import Button from 'components/base/button';

const FilterForm = (props) => {
  const { filters, permitTypes, setFilters, startFetching, setList, match, setListType } = props

  const formApiRef = useRef()
  const setFormApi = (formApi) => formApiRef.current = formApi;

  const filterPermitapplications = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, { query: filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const attrs = useMemo(() => (
    {
      permitTypes: permitTypes, maxDate: new Date(), 
      customLabelClass: styles.formLabel,
      customInputClass: styles.formInput,
    }
  ),[permitTypes])

  const initialValues = useMemo(() =>(
    {
      ...filters,
      status: '',
      submitted_date: {
        from: filters.submitted_date?.from ? new Date(filters.submitted_date.from) : '' ,
        to: filters.submitted_date?.to ? new Date(filters.submitted_date.to) : '' ,
      }
    }
  ),[filters])

  const resetFilters = useCallback(async () => {
    formApiRef.current.setValues({
      submitted_date: { from: '', to: ''}, 
      status: '', 
      application_type: '', 
      permit_type_id: ''
    })
    setFilters({})
    setListType('all_applications');
    filterPermitapplications({})
  },[setFilters, filterPermitapplications, setListType]);

  const handleSearchFilters = useCallback(() => {
    setFilters({})
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      submitted_date: {
        from: displayDate(filterValues.submitted_date?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.submitted_date?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(filters)
    setListType('all_applications');
    filterPermitapplications(filters)
  },[setFilters, filterPermitapplications, setListType])

  return (
    <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={handleSearchFilters} >
    {({formState}) => {
      const submittedDates = formState.values.submitted_date
      return <>
        <div lg={12} className=''>
          {renderFieldsWithGrid(
            searchFields({...attrs, submittedFromDate: submittedDates?.from, submittedToDate: submittedDates?.to}), 
            3, 4, 
            { iSize: 7, lSize: 5 }
          )}
        </div>
        <div className='d-flex justify-content-center pt-3 pb-2'>
          <Button
            className={`${styles.btn} ${styles['background-primary']} mr-5`}
            type='submit'
          >
            Filter
          </Button>
          <Button
            type='button'
            onClick={resetFilters}
            className={`${styles.btn} ${styles['background-secondary']}`}
          >
            Reset
        </Button>
        </div>
      </>
    }}
    </Form>
  )
}

export default FilterForm;
