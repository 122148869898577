import React from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/monitor_vehicles';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {
  const { isResourceFetching, list, activeTab } = props

  const renderRecords = () => {
    return list.map((record) => {
      return ( <TableRow 
        key={record.id} 
        record={record} 
      />);
    });
  }
  
  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      isFetching={() => isResourceFetching }
      filterFetcher={filterFetcher}
      columns={
        <>
          <th attr="created_at" style={{ width: '45%', minWidth: '180px' }}>Name</th>
          <th attr="monitor_vehicles.plate_number" style={{ width: '45%', minWidth: '180px' }}>Vehicle LPN</th>
          <th attr="monitor_vehicles.owner_name" style={{ width: '45%', minWidth: '180px' }}>Date</th>
          <th attr="monitor_vehicles.expected_arrival_time" style={{ width: '45%', minWidth: '210px' }}> Expected Arrival Time</th>
          <th attr="actual_arrival_time" style={{ width: '45%', minWidth: '210px' }}> Actual Arrival Time</th>
          <th attr="parking_lots.name" style={{ width: '45%', minWidth: '210px' }}> Parking Lot </th>
          <th attr="verdict" style={{ width: '45%', minWidth: '150px' }}> Verdict </th>
          <th attr="difference" style={{ width: '35%', minWidth: '50px' }}> Difference </th>
        </>
      }
      renderRecords={renderRecords}
      entityName='Monitor Vehicles'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData
