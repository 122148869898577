import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const report = (data) => {
  return fetchApi(`dashboard/charging_sessions/report`, { method: 'GET', data })  
}

const active = (params = {}) => {
  const { id, page, perPage, query, nestedParams = {} } = params;
  return fetchApi(
    generatePath(`dashboard/charging_sessions/active_sessions`, nestedParams),
    { method: 'GET', params: { id, page, per_page: perPage, ...query } }
  );
};

const past = (params = {}) => {
  const { id, page, perPage, query, nestedParams = {} } = params;
  return fetchApi(
    generatePath(`dashboard/charging_sessions/past_sessions`, nestedParams),
    { method: 'GET', params: { id, page, per_page: perPage, ...query } }
  );
};

export { report, active, past };
