import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import Loader from 'components/helpers/loader';

const withCurrentSubscriber = (Component, CustomLoader = null) => {
  const HOC = class extends React.Component {
    state = {
      currentSubscriber: null,
      currentSubscriberPermissions: []
    }

    componentWillUnmount() {
      this.setState({
        currentSubscriber: null,
        currentSubscriberPermissions: []
      })
    }

    componentWillReceiveProps(nextProps, nextContext) {
      if (!isEmpty(nextProps.currentSubscriber)) {
        this.setState({
          currentSubscriber: nextProps.currentSubscriber,
          currentSubscriberPermissions: []
        })
      }
    }

    componentDidMount() {
      this.setState({
        currentSubscriber: this.props.currentSubscriber,
        currentSubscriberPermissions: this.props.currentSubscriber ? [] : []
      })
    }

    render() {
      const { currentSubscriber, currentSubscriberPermissions, ...other_props} = this.props
      return this.state.currentSubscriber ?
            <Component {...this.state} {...other_props}/> :
            CustomLoader ? <CustomLoader/> : this.props.hideLoader ? null: <Loader/>
    }
  }

  return connect(
    mapState,
    null
  )(HOC)
};

const mapState = state => {
  const { subData = null } = state.user;
  return { currentSubscriber: subData };
};

export default withCurrentSubscriber;
