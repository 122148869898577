import React from "react";
import { useSelector } from "react-redux";
/* API */
import { vehicleFilterFetcher } from 'api/subscribers';
/* Base */
import IndexTable from "components/base/table";
/* Modules */
import TableRow from "./table_row";
/* Styles and Assets */
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, setRecord ,openModal, modalType, activeTab } = props;

  const filters = useSelector(state => state.subscriber_vehicle.filters)

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          history={history}
          match={match}
          setRecord={setRecord} 
          openModal={openModal}
          modalType={modalType}
        />
      );
    });
  }
  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={vehicleFilterFetcher}
      paginationQuery={filters}
      showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="vehicles.id" style={{minWidth: 225}}>Vehicle ID</th>
          <th attr="vehicles.plate_number">Vehicle Plate Number</th>
          <th attr="vehicles.registration_state">Registration State</th>
          <th attr="vehicles.manufacturer_id">Manufacturer</th>
          <th attr="vehicles.status">Status</th>
          <th 
            attr="actions"
            style={{ width: '20%', minWidth: '210px', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Subscriber Vehicles'
    />
  )
}

export default TableData;