import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* Module */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
/* API */
import { show } from 'api/permit_applications';
/* Helpers */
import Loader from 'components/helpers/loader';
import { capitalize } from 'components/helpers';
import { displayUnixTimestamp, camelize } from 'components/helpers';
import { showFields } from 'components/helpers/fields/permit_applications/index';
/* Base */
import Button from 'components/base/button';
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
/* Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


const Show = ({ startFetching, record, closeModal }) => {
  const [isFetching, setIsFetching] = useState()
  const [permitApplication, setPermitApplication] = useState({})

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel
  }

  const doubleFieldProps = {
    iSize: 7,
    lSize: 5
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }

  const applicationData = useCallback((data) => {
    const { vehicle={}, permit_type={}, permit_number, status, parking_lot={} } = data
    const { user } = vehicle
    return ({
      vehicle: {
        plate_number: vehicle.plate_number?.toUpperCase(),
        make: capitalize(vehicle.manufacturer?.name),
        color: capitalize(vehicle.color),
        model: capitalize(vehicle.model),
        registration_state: capitalize(vehicle.registration_state),
        year: vehicle.manufacture_year
      },
      applicant: { 
        full_name: `${user?.first_name} ${user?.last_name}`, 
        home_address: user.home_address?.address1,
        home_phone: user.home_address?.phone,
        work_address: user.work_address?.address1,
        work_phone: user.work_address?.phone,
      },
      application: {
        permit_number,
        issue_on: displayUnixTimestamp(new Date(record.submitted_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
        type: camelize(record.application_type),
        permit_type_name: permit_type?.name,
        parking_lot: `${parking_lot.name} (${parking_lot?.address})`,
        status: camelize(status)
      },
    })
  },[record])

  const dotIcon = (status, color='') => <FontAwesomeIcon icon={faCircle} className={`${styles[`${status}-color`]} ${styles.dotIcon}`} color={color}/>
  
  const renderPermitAppFields = (fields) => {
    fields[4] = {
      ...fields[4], 
      icon: dotIcon(record.status), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }

    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Permit Application Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 1, 12, { ...singleFieldProps })}
        </div>
      </Col>
    )
  }

  const renderVehicleFields = (fields) => {
    fields[2] = {
      ...fields[2], 
      icon: dotIcon(null, permitApplication.vehicle?.color), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }

    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Vehicle Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 2, 6, { ...doubleFieldProps })}
        </div>
      </Col>
    )
  }

  useEffect(() => {
    setIsFetching(true)
    const fetchPermitApplication = async () => {
      if(!record?.id)  return;

      try {
        const response = await startFetching(show({id: record.id}))
        if(response.status === 200) {
          const { data } = response;
          setPermitApplication(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchPermitApplication();
  }, [record, applicationData, startFetching])

  const fields = showFields(fieldAttrs)

  if(isFetching) { return <Loader /> }
  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Permit Application Details</p>
      <Form initialValues={permitApplication} className={styles.form}>
        { renderVehicleFields(fields[0]) }
        <Col>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>Owner Information</span>
            <span className="border border-2 flex-grow-1 ml-2"></span>
          </div>
          <div>
            {renderFieldsWithGrid(fields[1], 2, 6, { ...doubleFieldProps })}
          </div>
        </Col>
        { renderPermitAppFields(fields[2]) }
      </Form>
      <Col className='d-flex justify-content-center'>
        <Button 
          onClick={closeModal}
          className={`${styles.cancelButton} mb-5`}
        >
          Close
        </Button>

      </Col>
    </Container>
  )
}

export default withFetching(withCurrentUser(Show));
