import { CityEventsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(CityEventsActions);

const CityEventsReducers = combineReducers({
  index,
  records,
  filters
});

export default CityEventsReducers;