import { EventParkingApplicationsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(EventParkingApplicationsActions);

const EventParkingApplicationsReducers = combineReducers({
  index,
  records,
  filters
});

export default EventParkingApplicationsReducers;
