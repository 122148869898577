import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import AdminRoute from 'routes/admins';
import DashboardRoute from 'routes/dashboard';
import AgencyRoute from 'routes/agencies';
import AgencyTypesRoute from 'routes/agency_types';
import VehicleRoute from 'routes/vehicles';
import CameraRoute from 'routes/cameras';
import TicketRoute from 'routes/tickets';
import ParkingLotRoute from 'routes/parking_lots';
import PaymentRoute from 'routes/payments';
import ParkingSlotRoute from 'routes/parking_slots';
import ReportRoute from 'routes/reports';
import ProfileRoute from 'routes/profile';
import PrivateRoute from 'routes/private_route';
import ParkingLotCameras from 'routes/parking_lots_camera';
import RoleRoute from 'routes/roles';
import NotificationRoute from 'routes/notifications';
import SubscriberRoute from 'routes/subscribers';
import ViolationRoute from 'routes/violations';
import ViolationTypeRoute from 'routes/violation_types';
import PermitTypeRoutes from 'routes/permit_types';
import PermitApplicationRoutes from 'routes/permit_applications';
import DisputeRoute from 'routes/disputes';
import PermitRoutes from 'routes/permits';
import SpecialPermitTypeRoutes from 'routes/special_permit_types';
import SpecialPermitRoutes from 'routes/special_permits'
import IntegrationRoutes from 'routes/integrations';
import ParkingCitationTicketRoutes from 'routes/parking_citation_tickets';
import { AlertMessages } from 'components/helpers/alert_messages';
import  MonitorVehicleRoute from 'routes/monitor_vehicles';
import  EventParkingApplicationRoute from 'routes/event_parking_applications';
import  RewardTypeRoute from 'routes/reward_types';
import  CouponRoute from 'routes/coupons';
import subscrctiberPermits from  'routes/subscribers/permits';
import PermitTypeCodeRoute from 'routes/permit_type_codes';
import BootingsRoute from 'routes/bootings'
import PermitWaitingListRoute from 'routes/permit_waiting_lists';
import NewDashboardRoute from 'routes/new_dashboard';
import ViolatorRoute from 'routes/violators';
import AccountTypeRoute from 'routes/account_types';
import SelectListRoute  from 'routes/select_lists'
import ThemeRoute from 'routes/themes'
import BroadcastMessageRoute  from 'routes/broadcast_messages'
import ParkingRegulationRoute  from 'routes/parking_regulations'
import ChargingSessionRoute  from 'routes/charging_sessions'
import SystemLogsRoute from 'routes/system_logs';
import TowingsRoute from 'routes/towings'
import BootEligibleVehiclesRoute from 'routes/boot_eligible_vehicles'
import EmailsRoute  from 'routes/emails'
import ValetParkingRoute  from 'routes/valet_parkings'
import ValueAddedServiceRoute  from 'routes/value_added_services'
import ValetStaffManagementRoute  from 'routes/valet_staff_management'

function MainContent(props) {
  const { match } = props;

  return (
    <div className='position-relative'>
      <AlertMessages>
        <PrivateRoute path={`${match.path}`} component={DashboardRoute} />
        <PrivateRoute path={`${match.path}/new_dashboard`} component={NewDashboardRoute} />
        <PrivateRoute path={`${match.path}/profile`} component={ProfileRoute} />
        <PrivateRoute path={`${match.path}/admins`} component={AdminRoute} />
        <PrivateRoute path={`${match.path}/subscribers`} component={SubscriberRoute} />
        <PrivateRoute path={`${match.path}/agencies`} component={AgencyRoute} />
        <PrivateRoute path={`${match.path}/vehicles`} component={VehicleRoute} />
        <PrivateRoute
          path={`${match.path}/agency_types`}
          component={AgencyTypesRoute}
        />
        <PrivateRoute path={`${match.path}/tickets`} component={TicketRoute} />
        <PrivateRoute path={`${match.path}/cameras`} component={CameraRoute} />
        <PrivateRoute path={`${match.path}/payments`} component={PaymentRoute} />
        <PrivateRoute
          path={`${match.path}/parking_lots`}
          component={ParkingLotRoute}
        />
        <PrivateRoute
          path={`${match.path}/parking_slots`}
          component={ParkingSlotRoute}
        />
        <PrivateRoute
          path={`${match.path}/live/parking_lots`}
          component={ParkingLotCameras}
        />
        <PrivateRoute path={`${match.path}/reports`} component={ReportRoute} />
        <PrivateRoute path={`${match.path}/roles`} component={RoleRoute} />
        <PrivateRoute path={`${match.path}/disputes`} component={DisputeRoute} />
        <PrivateRoute path={`${match.path}/permit_types`} component={PermitTypeRoutes} />
        <PrivateRoute path={`${match.path}/permit_applications`} component={PermitApplicationRoutes} />
        <PrivateRoute path={`${match.path}/permits`} component={PermitRoutes} />
        <PrivateRoute path={`${match.path}/special_permit_types`} component={SpecialPermitTypeRoutes} />
        <PrivateRoute path={`${match.path}/special_permits`} component={SpecialPermitRoutes} />
        <PrivateRoute path={`${match.path}/integrations`} component={IntegrationRoutes} />
        <PrivateRoute path={`${match.path}/parking/citation_tickets`} component={ParkingCitationTicketRoutes} />
        <PrivateRoute path={`${match.path}/violations`} component={ViolationRoute} />
        <PrivateRoute path={`${match.path}/violation_types`} component={ViolationTypeRoute}/>
        <PrivateRoute path={`${match.path}/violators`} component={ViolatorRoute}/>
        <PrivateRoute path={`${match.path}/notifications`} component={NotificationRoute} />
        <PrivateRoute path={`${match.path}/monitor_vehicles`} component={MonitorVehicleRoute} />
        <PrivateRoute path={`${match.path}/event_parking_applications`} component={EventParkingApplicationRoute} />
        <PrivateRoute path={`${match.path}/reward_types`} component={RewardTypeRoute} />
        <PrivateRoute path={`${match.path}/coupons`} component={CouponRoute} />
        <PrivateRoute path={`${match.path}/permitdss`} component={subscrctiberPermits} />
        <PrivateRoute path={`${match.path}/permit_type_codes`} component={PermitTypeCodeRoute} />
        <PrivateRoute path={`${match.path}/bootings`} component={BootingsRoute} />
        <PrivateRoute path={`${match.path}/account_types`} component={AccountTypeRoute} />
        <PrivateRoute path={`${match.path}/select_lists`} component={SelectListRoute} />
        <PrivateRoute path={`${match.path}/permit_waiting_lists`} component={PermitWaitingListRoute} />
        <PrivateRoute path={`${match.path}/themes`} component={ThemeRoute} />
        <PrivateRoute path={`${match.path}/broadcast_messages`} component={BroadcastMessageRoute} />
        <PrivateRoute path={`${match.path}/parking_regulations`} component={ParkingRegulationRoute} />
        <PrivateRoute path={`${match.path}/charging_sessions`} component={ChargingSessionRoute} />
        <PrivateRoute path={`${match.path}/system_logs`} component={SystemLogsRoute} />
        <PrivateRoute path={`${match.path}/towings`} component={TowingsRoute} />
        <PrivateRoute path={`${match.path}/boot_eligible_vehicles`} component={BootEligibleVehiclesRoute} />
        <PrivateRoute path={`${match.path}/email_addresses`} component={EmailsRoute} />
        <PrivateRoute path={`${match.path}/valet_parkings`} component={ValetParkingRoute} />
        <PrivateRoute path={`${match.path}/value_added_services`} component={ValueAddedServiceRoute} />
        <PrivateRoute path={`${match.path}/staff_management`} component={ValetStaffManagementRoute} />
      </AlertMessages>
    </div>
  );
}

MainContent.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(MainContent);
