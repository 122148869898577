import { SubscriberVehiclesActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "../entities";

const { index, records } = reduceEntity(SubscriberVehiclesActions);

const SubscriberVehiclesReducers = combineReducers({
  index,
  records,
});

export default SubscriberVehiclesReducers;
