import React, { useCallback } from 'react'
import { Col, Row } from 'reactstrap';
import styles from './index.module.sass';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import FilterForm from './filterForm';
import { ReactComponent as PenToSquareSvg } from 'assets/people-icon.svg';
import SlideButton from 'components/base/slide_button';
import { Link } from 'react-router-dom';
import { ReactComponent as SettingIcon } from 'assets/setting-gear-icon.svg';
import Toggle from 'components/base/toggle';
import { updateSetting } from 'api/violations';

const CollapseContent = ({ startFetching,filters, setList, setFilters, match, resource, setListType, activeTab, openModal, captureViolation, captureNegative, setCaptureNegative, setCaptureViolation}) => {

  const updateViolationSetting = async () => {
    try {
      const response = await updateSetting({data: {unpaid_violation_negative_balance: captureNegative ? 'false' : 'true'}})
      setCaptureViolation(response?.data?.status)
      setCaptureNegative(response?.data?.unpaid_violation_negative_balance)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">

      {activeTab === 'filters' && 
        <FilterForm 
          startFetching={startFetching} 
          setList={setList} 
          match={match} 
          resource={resource} 
          setFilters={setFilters}
          filters={filters}
          setListType={setListType}
        />
      }

      {activeTab === 'setting' && 
        <Row className='p-2' style={{ flexDirection: "column"}}>
          <Row className='ml-4'>
          <span className={styles.checkboxDescription} >
            Capture Subscriber violations 
          </span>
          <div className='ml-4' style={{marginTop:'-0.2rem'}}>
          <Toggle
            onClick={() => {openModal('violation_confirmation')}}
            customToggleClass= {styles.toggle}
            customToggleOnClass= {styles.toggleOn}
            positiveText=''
            negativeText=''
            value={captureViolation === 'active' ? true : false}
            offHighlightClass={styles.toggleHighlight}
            onHighlightClass={styles.toggleOnHighlight}
          />
          </div>
        </Row>
        {captureViolation === 'active' && <Row className={styles.unpaidRow}>
          <input
            type="checkbox"
            checked={captureNegative}
            onChange={() => {updateViolationSetting()}}
            className={styles.customCheckStyle}
          />
          <span className={styles.checkboxDescription1}>
            Capture Unpaid violations only if the subscriber wallet has negative balance
          </span>
          </Row>}
        </Row>
      }
    </Row>
  )
};

const ToolBar = (props) => {
  const { activeTab, setActiveTab, isFetching, total} = props

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={`${styles.title} general-text-1`}>
          {`Subscribers (${total})`}
        </span>
      </Col>
      <Col className='d-flex justify-content-end p-0'>
      <SlideButton
          icon={<SettingIcon className={`${activeTab === 'setting' ? styles.activeIcon : ''}`}/>}
          onClick={() => handleToggleCollapse('setting')}
          className={`${styles.btnSetting} ${activeTab === 'setting' ? styles.activeTab : '' }`}
        >
          <span className='pr-3'>Violation Settings</span>
        </SlideButton>
        <SlideButton
          icon={<PenToSquareSvg />}
        >
          <Link
            to='account_types'
            className={`${styles.button} mr-3 d-inline-block text-decoration-none`}>
              Account Types
          </Link>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
