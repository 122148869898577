import { FieldType } from 'components/helpers/form_fields';

const filterFields = () => [
  { name: 'name', label: 'Name' },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.SELECT_FIELD,
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ],
  },
  { name: 'payment_type', label: 'Paid/Free' },
  { name: 'hourly_rate', label: 'Cost Per Hour' },
  { name: 'parking_hour_from', label: 'Time(From - To)',  type: FieldType.HOUR_FIELD, },
];

const statusField = () => (
  {
    name: 'status',
    type: FieldType.SWITCH_FIELD,
    positiveText: '',
    negativeText: '',
  }
)

const sortFields = (admins=[], customLabelClass='', customInputClass='') => ([
  {
    name: 'status',
    label: 'Status:',
    placeholder: 'Please select',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ],
    type: FieldType.DROPDOWN_SELECT_FIELD,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'payment_type',
    label: 'Paid/Free:',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: [
      { value: 'free', label: 'Free' },
      { value: 'paid', label: 'Paid' },
    ],
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'category',
    label: 'Category:',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: [
      { value: 'permanent', label: 'Permanent' },
      { value: 'temporary', label: 'Temporary' },
    ],
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'created_by',
    label: 'Created by:',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: admins.map(admin => ({value: admin.id, label: admin.name })),
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
]);

const periodOptions = (type, months) => {
  switch (type) {
    case 'days':
      return [1,2,3,4,5,6].map(val => ({value: `${val} ${val === 1 ? 'day' : 'days'}`, label: `${val} ${val === 1 ? 'day' : 'days'}`}))
    case 'weeks':
      return [1,2,3].map(val => ({value: `${val} ${val === 1 ? 'week' : 'weeks'}`, label: `${val} ${val === 1 ? 'week' : 'weeks'}`}))
    case 'months':
      return months.map(val => ({value: `${val} ${val === 1 ? 'month' : 'months'}`, label: `${val} ${val === 1 ? 'month' : 'months'}`}))
    default:
      return [];
  }
}

const fieldsNew = (attrs) => {
  const { 
    mute=true, parkingLots=[], validity='', customLabelClass='', customInputClass='', payment_type
  } = attrs
  return (
  [
    [
      {
        name: 'name',
        label: 'Name:',
        customInputClass,
        customLabelClass,
        mandatory: true
      },
    ],
    [
      {
        name: 'category',
        label: 'Category:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'permanent', label: 'Permanent' },
          { value: 'temporary', label: 'Temporary' },
        ],
        mandatory: true,
        disabled: mute,
        tooltipWidth: '25px',
        tooltipHeight: '25px',
        tooltipClass: 'mb-3',
        tooltipEnd: 'Permanent permit types are renewable and extendable by subscribers. Temporary permits can only be renewed or extended by an admin.',
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: 'validity',
        label: 'Validity:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'days', label: 'Days' },
          { value: 'weeks', label: 'Weeks' },
          { value: 'months', label: 'Months' },
        ],
        mandatory: true,
        disabled: mute,
        customInputClass,
        customLabelClass,
      },
      {
        name: 'period',
        label: 'Period:',
        type: FieldType.MULTISELECT_FIELD,
        placeholder: 'Please select',
        showToolTip: false,
        // handle permit_types created without a validity
        options: periodOptions(validity, [1,3,6]),
        mandatory: true,
        disabled: mute,
        customInputClass,
        customLabelClass,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_lot_ids',
        label: 'Parking Lots:',
        type: FieldType.MULTISELECT_FIELD,
        placeholder: 'Choose a Parking Lot',
        showToolTip: false,
        options: parkingLots.map(lot => ({value: lot.id, label: lot.name })),
        customInputClass,
        customLabelClass,
        mandatory: true,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_hour_from',
        label: 'Time From:',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'parking_hour_to',
        label: 'Time To:',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'payment_type',
        label: 'Paid/Free:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'free', label: 'Free' },
          { value: 'paid', label: 'Paid' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'hourly_rate',
        label: 'Hourly Rate:',
        type: FieldType.NUMBER_FIELD,
        disabled: payment_type === 'free',
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0,
      },
      {
        name: 'status',
        label: 'Status:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true
      },
    ],
  ]);
}

export { fieldsNew, filterFields, sortFields, statusField };