import React, { useCallback, useEffect, useState } from 'react'
import {  Col, Row } from 'reactstrap';
import { isEmpty } from 'underscore';
/* Component */
import AutoApproval from '../auto_approval/'
import FilterForm from './filterForm';
/* API */
import { filterFetcher as permitTypesFetcher } from 'api/permit_types';
import { filterFetcher } from 'api/permit_applications';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
/* Base */
import Button from 'components/base/button';
import Tabs from 'components/base/tabs';
import NavigationToolbar from 'components/base/navigationToolbar';
import { list as selectList } from 'selectors/list';

const tabs = [
  { value: 'all_applications', label: 'All Permit Applications'},
  { value: 'approved', label: 'Approved'},
  { value: 'rejected', label: 'Rejected'},
  { value: 'under_review', label: 'Under Review'},
]

const CollapseContent = ({ activeTab, filters, startFetching, setList, setFilters, match, resource, setListType}) => {
  const [permitTypes, setPermitTypes] = useState([])
  const [settingRecord, setSettingRecord] = useState({})

  useEffect(() => {
    const fetchPermitTypes = async () => {
      const response = await permitTypesFetcher({perPage: 100000});
      if(response.status === 200){
        setPermitTypes(response.data)
      }
    }

    if (isEmpty(permitTypes)) { fetchPermitTypes() };
  },[permitTypes])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm 
          startFetching={startFetching} 
          setList={setList} 
          match={match} 
          resource={resource} 
          permitTypes={permitTypes}
          filters={filters}
          setFilters={setFilters}
          setListType={setListType}
        /> }
      { activeTab === 'auto-approval' && 
        <AutoApproval 
          setSettingRecord={setSettingRecord}
          settingRecord={settingRecord}
        /> 
      }
    </Row>
  )
}

const ToolBar = (props) => {
  const {activeTab, setActiveTab, isResourceFetching, listType, setListType, startFetching, setFilters, match, setList} = props;

  const handleToggleCollapse = useCallback((tab) => {
     if(isResourceFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isResourceFetching])

  const filterPermitApplications = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])


  const handleTabsFilter = () => {
    const filters = { status: listType };
    if (listType === "all_applications" && activeTab !== "filters") {
      setFilters({});
      filterPermitApplications({});
    } else {
      setFilters(filters);
      filterPermitApplications(filters);
    }
  };
  
  useEffect(() => {
    if (listType) {
      handleTabsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters, setListType, listType]);

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      backTab='permits'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start'>
        <Tabs list={tabs} defaultTab={listType} onClick={setListType}/>
      </Col>
      <Col className='d-flex justify-content-end px-0'>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
        <Button
          onClick={() => handleToggleCollapse('auto-approval')}
          className={`${styles.autoApprovalButton} ${activeTab === 'auto-approval' ? styles.activeTab : '' }`}
          size="md"
        >
          Auto-approvals
        </Button>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;