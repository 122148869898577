import React, { useEffect, useState } from 'react'
import { map } from 'underscore';
import styles from './index.module.sass'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import Toggle from 'components/base/toggle';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className='d-flex align-items-center justify-content-center'
      style={{cursor: disableHighlight ? 'not-allowed' : ''}}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = ({record, openModal, modalType, setSelectedRecord, selectedRecord}) => {


  const actions = [
    { 
      icon: <EditIcon className='mr-3'/>,
      highlightedClass: `${styles['background-secondary']} mr-3 text-white`,
      onClick: () => {openModal('edit_service')},
      text: 'Edit',
      isActive: modalType === 'edit_service' && selectedRecord?.id === record?.id,
      disableHighlight: record?.status === 'disabled'
    }
  ]
  
  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td>{record?.name}</td>
      <td>
        <Toggle
          onClick={() => openModal(`${record?.status === 'enabled' ? 'disable_service' : 'enable_service'}`)}
          customToggleClass= {styles.toggle}
          customToggleOnClass= {styles.toggleOn}
          positiveText=''
          negativeText=''
          value={record.status === 'enabled'}
          offHighlightClass={styles.toggleHighlight}
          onHighlightClass={styles.toggleOnHighlight}
        />
      </td>
      <td>{record?.cost}</td>
      <td>{record?.description}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;
