import React, { useCallback, useMemo } from "react";
/* Styles */
import styles from "./index.module.sass";
/* Assets */
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
/* Components */
import Action from "./action";
import { displayUnixTimestamp } from "components/helpers";

const TableRow = (props) => {
  const { record, setRecord, selectedRecordId, openModal, modalType } = props;

  const secondsToHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return (
      <div>
        {hours > 0 ? (
          <div className="d-flex">
            <span className={`${styles.timeTxt} mr-1`}>
              {hours < 10 ? `0${hours}` : hours}
            </span>
            <span className="mr-2">Hours</span>
            <span className={`${styles.timeTxt} mr-1`}>
              {minutes < 10 ? `0${minutes}` : minutes}
            </span>
            <span>Minutes</span>
          </div>
        ) : (
          <div className="d-flex">
            <span className={`${styles.timeTxt} mr-1`}>{minutes}</span>
            <span>Minutes</span>
          </div>
        )}
      </div>
    );
  };

  const highlightAction = useCallback(
    (actionType) => {
      return actionType === modalType && record.id === selectedRecordId;
    },
    [modalType, selectedRecordId, record.id]
  );

  const actions = useMemo(() => {
    return [
      {
        icon: <EyeIcon />,
        className: `${styles["icon-eye"]}`,
        onClick: () => !record.shared && openModal("show"),
        activeClass: styles["icon-eye-active"],
        status: modalType === "show" && highlightAction("show"),
        allowActive: !record.shared,
      },
    ];
  }, [openModal, modalType, highlightAction, record.shared]);

  return (
    <tr onClick={() => setRecord(record)}>
      <td>
        {displayUnixTimestamp(
          new Date(record?.start_time).getTime() / 1000,
          "ddd, MMM Do YYYY, h:mm:ss A"
        )}
      </td>
      <td>
        {displayUnixTimestamp(
          new Date(record?.end_time).getTime() / 1000,
          "ddd, MMM Do YYYY, h:mm:ss A"
        )}
      </td>
      <td>{secondsToHours(record?.session_length)}</td>
      <td>{record?.vehicle.plate_number}</td>
      <td>{record?.parking_lot?.name}</td>
      <td>{record?.parking_slot?.name}</td>
      <td>{record?.energy_transferred}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {actions.map((action, idx) => (
            <Action key={idx} {...action} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;