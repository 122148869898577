import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Create from '../create';
import Edit from '../edit';
import styles from './index.module.sass'
import { create, update } from 'api/webapp/vehicles';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const VehicleModal = (props) => {
  const { openModal, modalType, setActiveTab, startFetching, record, setUpdateList } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
  }, [openModal, setActiveTab])

  

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const addVehicle = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await create({data:  {vehicle: data} })
      if(response.status===200 || response.status===201){
        setUpdateList(true)
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `Added Vehicle Successfully`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState,setErrors, addAlertMessages, closeModal, setUpdateList])

  const editVehicle = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await update({data: {vehicle: data}, id: data.id })
      if(response.status===200 || response.status===201){
        setUpdateList(true)
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `Successfully edited the vehicle`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState,setErrors, addAlertMessages, closeModal, setUpdateList])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'create':
        return {title: 'Add New Vehicle', maxWidth : '600px'}
      case 'edit':
        return {title: 'Edit Vehicle', maxWidth : '600px'}  
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const commonProps = {
    state,
    setState,
    closeModal,
    setErrors,
    errors,
    startFetching
  };


  return (
    <Modal 
      isOpen={!!modalType}  
      centered
      contentClassName={styles.vehicleModal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'create' && 
          <Create
            {...commonProps}
            addVehicle={addVehicle}
          />
        }
        {
          modalType === 'edit' && 
          <Edit
            {...commonProps}
            editVehicle={editVehicle}
            record={record}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default VehicleModal;
