import React, { useState, useEffect } from 'react';
import {filterFetcherDashboard} from 'api/themes'
export const ThemeContext = React.createContext();

const ThemeProvider = ({ children }) => {
  const [themeColors, setThemeColors] = useState({});

  useEffect(() => {
    const fetchThemeColors = async () => {
      try {
        const response = await filterFetcherDashboard({ query:{ applied: "true", theme_category: "dashboard"} });
        setThemeColors(response.data[0]);
        updateThemeColors(response.data[0]);
      } catch (error) {
        console.error('Error fetching theme colors:', error);
      }
    };

    fetchThemeColors();
  }, []);

  const updateThemeColors = (colors) => {

    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    })
  };

  return (
    <ThemeContext.Provider value={themeColors}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;