import { FieldType } from "components/helpers/form_fields";

const residentialTypeFields = (attrs) => {
  const { customLabelClass = "", customInputClass = "" } = attrs;
  return [
    [
      {
        name: "name",
        label: "Name",
        mandatory: true,
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: "type_of",
        label: "Type of",
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: [
          { value: "residence", label: "Residence" },
          { value: "property", label: "Property" },
        ],
        mandatory: true,
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: "eligible",
        label: "Eligibility",
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: [
          { value: true, label: "Eligible" },
          { value: false, label: "Not Eligible" },
        ],
        customInputClass,
        customLabelClass,
        mandatory: false,
      },
    ],
		[
      {
        name: "status",
        label: "Status",
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: [
          { value: true, label: "Activate" },
          { value: false, label: "Deactivate" },
        ],
        customInputClass,
        customLabelClass,
        mandatory: false,
      },
    ],
  ];
};

export { residentialTypeFields };