import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { ReactComponent as RefreshIcon } from 'assets/refresh_icon.svg';
import { ReactComponent as CalenderIcon } from 'assets/dashboard_card_icons/calender_icon.svg';
import { ReactComponent as EditIcon } from 'assets/dashboard_card_icons/edit_icon.svg';
import { ReactComponent as BoardIcon } from 'assets/dashboard_card_icons/board-view.svg';
import { ReactComponent as ListIcon } from 'assets/dashboard_card_icons/list-view.svg';
import styles from './toolbar.module.sass';
import Tabs from 'components/base/tabs';
import Button from 'components/base/button';

const Toolbar = () => {

  const tabs = [
    { value: 'board',
      label: (
        <span>
          <BoardIcon style={{marginTop: '-2px', marginRight: '4px'}} /> Board View
        </span>
      ),
    },
    { value: 'list',
      label: (
        <span>
          <ListIcon style={{marginTop: '-2px', marginRight: '4px'}} /> List View
        </span>
      ),
  
    },
  ]

  return (
    <Row className={`${styles.toolBar} w-100 align-items-center m-0`} sm="12">
      <Col className='d-flex justify-content-start'>
        <Tabs className={styles.tabs}  list={tabs} defaultTab='board' classNameInnerTab={styles.innerTab}/>
      </Col>
      <Col className='d-flex justify-content-end pr-3'>
        <Button
          className={styles.btnRefresh}
          icon={<RefreshIcon />}
        >
          Refresh
        </Button>
        <Button
          className={styles.btnCalender}
          icon={<CalenderIcon />}
        />
        <Button
          className={styles.btnCalender}
          icon={<EditIcon />}
        />
      </Col>
    </Row>
  );
}

Toolbar.propTypes = {
  dateRange: PropTypes.object.isRequired,
  filter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

export default Toolbar;
