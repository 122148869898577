import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const { update, create } = resourceApi('parking/valet_settings');

const index = (params = {}) => {
  const { id, page, perPage, query, parking_lot_id } = params;
  return fetchApi(
    generatePath(`dashboard/parking/valet_settings?parking_lot_id=${parking_lot_id}`),
    { method: 'GET', params: { id, page, per_page: perPage, ...query } }
  );
};

export { index, update, create };
