import {vehicleDetails} from "./apply_permit";
import {commuterVehicleDetails} from './apply_permit'



const vehicleFields = (attrs) => {
  return [
    vehicleDetails(attrs),
    commuterVehicleDetails(attrs)
  ]
}

export default vehicleFields;