import React, {useState, useCallback} from "react";
import { Col, Container, Row } from 'reactstrap';
/* Actions */
import { SET_LIST, SET_LIST_ELEMENT , ADD_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS } from "actions/coupons";
/* API */
import { filterFetcher } from "api/coupons";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import { connect } from 'react-redux';
/* Components */
import ToolBar from './toolbar';
import TableData from './table_data'
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import CouponModal from "../coupons_modal";

const Coupons = (props) => {

    const [modalType, setModalType] = useState()
    const [activeTab, setActiveTab] = useState()

    const openModal = useCallback((modalType) => {
      let val
      // Evaluate modalType
      switch (true) {
        case modalType === 'create':
          val = 'create'
          break;
        case modalType === 'add':
          val = 'add'
          break;
        default:
          break;
      }
      setModalType(val)
    },[setModalType])
  

    return (
        <Container className='mw-100 p-0'>
          <Row className='m-auto'>
            <Col xs={12} className='p-0'>
              <ToolBar
              openModal={openModal} 
              activeTab={activeTab} 
              setActiveTab={setActiveTab}
              total={props.total}
              modalType={modalType}
              {...props}
              />
            </Col>
            <Col xs={12} className='p-0'>
              <TableData {...props} modalType={modalType} openModal={openModal} activeTab={activeTab} />
            </Col>
          </Row>
          <CouponModal {...props} openModal={openModal} modalType={modalType} setActiveTab={setActiveTab}  />
        </Container>
    )
  }

const resource = 'coupons';

const  mapDispatch = (dispatch) => {
    return bindActionCreators(
      { 
        setListElement: invoke(SET_LIST_ELEMENT), 
        setFilters: invoke(SET_FILTERS), 
        addListElement: invoke(ADD_LIST_ELEMENT),
        setRecord: invoke(SET_SINGLE_RECORD) 

      }, 
      dispatch);
  }
  
  const  mapState = (state) => {
    return { 
      filters: state.coupons.filters,
      record: state.coupons.records.record
    }
  }
  

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Coupons)))
);


