import React from 'react'
import { Col, Container } from 'reactstrap';
import Button from 'components/base/button';
import styles from './index.module.sass'

const ActivateDeactivate = ({toggleStatus, closeModal, record, status}) => {

  const message = () => <React.Fragment>
    <div>
    <span className='my-3'>The subscription for user </span>
    <span className={styles.boldText}>{record.first_name} {record.last_name} </span>
    <span >with </span>
    <span className={styles.boldText}>ID {record.id}</span>
    <span className='mr-1'> will be </span>
    <span>{`${status === 'activate' ? 'activated' : 'deactivated' }`}</span>
    <span > immediately.</span>
    </div>
  </React.Fragment>

  return (
    <Container style={{padding: '15px'}}>
    <Container className={styles.content} >
      <Col className='mb-4'>
        { message() }
      </Col>
      <Col className="d-flex justify-content-center my-2">
        <Button onClick={() => closeModal()} type='button' className={`${styles.button} ${styles['button-bg-light']} mr-4`}>
          Cancel
        </Button>
        {
          status === 'activate' 
            && <Button 
                type='button' 
                onClick={() => {toggleStatus({status: "active"}); closeModal()}} 
                className={`${styles.button} ${styles['button-bg-primary']}`}
              >
                Activate
              </Button>
        }
        {
          status === 'deactivate' 
            && <Button 
                type='button' 
                onClick={() => {toggleStatus({status: "deactivated"}); closeModal()}} 
                className={`${styles.button} ${styles['button-bg-danger']}`} 
              >
                Deactivate
              </Button>
        }
      </Col>
    </Container>
  </Container>
  )
}

export default ActivateDeactivate;
