import { ManufacturersActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(ManufacturersActions);

const ManufacturersReducers = combineReducers({
  index,
  records,
  filters
});

export default ManufacturersReducers;