import React, { useCallback, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
/* API */
import { filterFetcher } from 'api/permit_types';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import connectList from 'components/modules/connect_list';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/* Actions */
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/permit_types';
import { invoke } from 'actions';
/* Components */
import ToolBar from './toolBar';
import PermitModal from '../permitModal';
import TableData from './tableData';
import resourceFetcher from 'components/modules/resource_fetcher';
import {exampleData} from 'components/helpers/fields/permit_types/index'
import { showSetting as showPermitSetting } from 'api/permit_applications';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [currentLocation , setCurrentLocation] = useState(exampleData)
  const [userLocation, setUserLocation] = useState()
  const [allowMultipleVehicle, setAllowMultipleVehicle] = useState(false)
  const [allowMultipleDailyPermit, setAllowMultipleDailyPermit] = useState(false)
  const [setting, setSetting] = useState('')
  
  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  useEffect(() => {
    const showPermitSettings = async () => {
      try {
        const response = await showPermitSetting()
        setAllowMultipleVehicle(response?.data?.allow_to_add_multiple_vehicle)
        setAllowMultipleDailyPermit(response?.data?.apply_for_multiple_daily_permit)
      } catch (error) {
        console.log(error)
      }
    }
    showPermitSettings()
  },[])

  const commonProps = {
    openModal: openModal,
    currentLocation: currentLocation,
    setCurrentLocation: setCurrentLocation,
    userLocation: userLocation,
    setUserLocation: setUserLocation,
    modalType: modalType,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
    setAllowMultipleVehicle: setAllowMultipleVehicle,
    setAllowMultipleDailyPermit: setAllowMultipleDailyPermit,
    allowMultipleVehicle: allowMultipleVehicle,
    allowMultipleDailyPermit: allowMultipleDailyPermit,
    setSetting: setSetting,
    setting: setting,
  };

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              {...commonProps}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} activeTab={activeTab} modalType={modalType} />
          </Col>
        </Row>
        <PermitModal
          {...props}
          {...commonProps}
        />
      </Container>
    </AccessControl>
  )
  
}

const resource = 'permit_type';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD)
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.permit_type.filters,
    record: state.permit_type.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {},
  SET_FILTERS
);
