import React, { useMemo } from 'react'
/* Helpers */
import { displayUnixTimestamp } from "components/helpers";
/* Styles */
import styles from './index.module.sass'

const TableRow = (props) => {
  const { record } = props
  const { difference } = record 

  const toHoursAndMinutes= (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const hrs = Math.floor(totalMinutes / 60);
    const hours = Math.abs(hrs)
    const minutes = totalMinutes % 60;
    return `${hours <= 9 && hours >= 0 ? `0${hours}` : hours}:${minutes <= 9 ? `0${minutes}` : minutes}:${seconds<=  9 ? `0${seconds}` : seconds}`;
  }

  const statusColor = useMemo(() => {
    if(!record.verdict) return;
    const colorMapping = {
      late: 'danger',
      early: 'success',
      'on time': 'secondary',
    }
    return colorMapping[record.verdict?.toLowerCase()] || 'default'
  },[record.verdict])
  

  return (
    <tr>
      <td>{record.owner_name} </td>
      <td>{record.vehicle_lpn}</td>
      <td>
        {record?.created_at ? displayUnixTimestamp(record?.created_at,'ddd, MMM Do YYYY') : ""}
      </td>
      <td>{ record?.expected_arrival_time ? new Date(record?.expected_arrival_time).toLocaleTimeString('en-US', { timeZone: 'America/New_York' }):"" }</td>
      <td>{ record?.actual_arrival_time ? new Date(record?.actual_arrival_time).toLocaleTimeString('en-US', { timeZone: 'America/New_York' }) :"" }</td>
      <td>{record?.parking_lot_name}</td>
      <td>
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`}></span>
          <span>{record.verdict && record.verdict.toUpperCase()}</span>
        </div>
      </td>
      <td>
        <div style={{'color': record.verdict === 'Early' ? 'green' : 'red', fontWeight: 'bold'}}>
           { record?.difference ? toHoursAndMinutes(difference) :"-----" }
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow