import React from 'react';
import PropTypes from 'prop-types';
import { ACTIONS } from 'config/permissions';
import CheckBox from 'components/base/check_box';
import styles from './permission_table.module.sass';
import { camelize } from 'components/helpers';
import isEqualIgnoreCase from 'components/modules/is_equal_ignore_case';
import { Col, Row } from 'reactstrap';

function getPermissionLabel (permission, availablePermissions = []) {
  const { name } = permission;
  const availablePermission = availablePermissions.find(
    p => isEqualIgnoreCase(name, p.name)
  );
  return availablePermission?.label || name;
}

const PermissionRow = ({ value, label, onChange, disabled }) => {
  const { name, ...actions } = value;
  return (
    <Row>
      <Col className={styles.checkBoxColumns1} xs={4}>
        {label}
      </Col>
      <Col xs={8} className={styles.checkBoxColumns}>
      <Row >
      {ACTIONS.map((actionType, index) => (
        <Col xs={3} key={index}>
          <CheckBox
            className={styles.checkBox}
            value={actions[`record_${actionType}`]}
            onChange={(v) => onChange(name, actionType, v)}
            // disabled={disabled}
          />
        </Col>
      ))}
      </Row>
      </Col>
    </Row>
  );
};

PermissionRow.propTypes = {
  value: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const PermissionTable = ({ value, onChange, disabled, availablePermissions }) => {
  const handleChange = (permissionName, actionType, isChecked) => {
    const newValue = value.map(permission => {
      if (permission.name !== permissionName) return permission;
      return {
        ...permission,
        [`record_${actionType}`]: isChecked
      };
    });
    onChange(newValue);
  };

  return (
    <Col className={`${styles.table} general-text-1`}>
        <Row>
          <Col xs={4}>Permission Name</Col>
          {ACTIONS.map((action, index) => (
            <Col className={styles.header} xs={2} key={index}>{camelize(action)}</Col>
          ))}
        </Row>
        {value.map((permission, index) => (
          <PermissionRow
            key={index}
            value={permission}
            label={getPermissionLabel(permission, availablePermissions)}
            onChange={handleChange}
            disabled={disabled}
          />
        ))}
    </Col>
  );
};

PermissionTable.propTypes = {
  value: PropTypes.array.isRequired,
  availablePermissions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default PermissionTable;
