import React, { useState, useCallback} from 'react'
import { isEmpty, map } from 'underscore';
import {Table, Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
import DragScroll from 'components/helpers/dragScroll';
/* Helpers */
import { displayDate } from 'components/helpers';
import { displayUnixTimestamp } from 'components/helpers';
import Loader from 'components/helpers/loader';
import PaymentModal from './payment_model';
import DisputeModal from './dispute_model';
/* Styles */
import styles from './index.module.sass';
import { ReactComponent as MarkerIcon } from 'assets/marker.svg'
import { ReactComponent as PlayIcon } from 'assets/play-icon.svg';
import { ReactComponent as ImagePlaceholderIcon } from 'assets/image-placeholder-icon.svg';
import { ReactComponent as ClockIcon } from 'assets/clock-icon.svg';
import { ReactComponent as QrCodeIcon } from 'assets/qr-code.svg';
import { ReactComponent as PfdIcon } from 'assets/pdf-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import CitationStatus from './payment_model/payment_status_model'

const Details = (props) => {
  const {citation, citationTicketStatus, setCitationTicketStatus, setState,} = props
  const [errors, setErrors] = useState({})
  const [paymentStatus, setPaymentStatus] = useState()
  const { t } = useTranslation();

  const Image = ({ url }) => {
    const [preview, setPreview] = useState(false)
    return <React.Fragment>
      {
        preview 
          ? <img src={url} alt="" className={`${styles.imgThumb} ${styles.image}`} />
          : <div className={`${styles.imgThumb} d-flex justify-content-center align-items-center bg-white`}>
              <ImagePlaceholderIcon className='pointer' onClick={() => setPreview(true)} />
          </div>
      }
    </React.Fragment>
  };
  
  const Video = ({ url }) => {
    const [preview, setPreview] = useState(false)
    return (
      <React.Fragment>
        {
          preview 
            ? (
              <video controls  autoPlay className={styles.imgThumb}>
                <source type="video/webm" src={url} />
                <source type="video/mp4" src={url} />
              </video>
            )
            : <div 
                className={`${styles.imgThumb} d-flex justify-content-center align-items-center bg-white `} 
                style={{borderRadius: 8}}
  
              >
                <div 
                  className={`${styles.playWrapper} d-flex justify-content-center align-items-center pointer`}
                  onClick={() => setPreview(true)} 
                >
                  <PlayIcon />
                </div>
            </div>
        }
      </React.Fragment>
    );
  }
  
  const Document = ({ url, idx }) => {
    return (
      <React.Fragment>
        {
        <div className={styles.downloadbutton}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PfdIcon className={styles.documentIcon} />
            </a>
              <p className={`${styles.namebox} d-flex align-items-center pt-3`} style={{borderRadius: 8}}> 
                <span className={`${styles.name} general-text-1`}>{t("citation_management.document")} {idx+1}</span>
              </p>
        </div>
        }
      </React.Fragment>
    );
  }
  
  
  const VideoImage = ({values, type}) => {
    return (
      <div className='d-flex align-items-center'>
        <DragScroll>
          { map(values, ({url, date}, idx) => (
                <div className='mr-3' key={idx}>
                  {type === 'Images' 
                    ? (<Image url={url} />)
                    : type === 'Videos' ?
                    (<Video url={url} />)
                    :(
                      <Document url={url} idx={idx} />
                    )
                  }
                  {
                    date && (
                      <p className='d-flex align-items-center pt-3 m-0' style={{borderRadius: 8}}> 
                        <ClockIcon className='ml-1 mr-2'/>
                        <span className='general-text-1'>{ displayUnixTimestamp(Date.parse(date) / 1000, 'ddd, MMM Do YYYY, h:mm:ss A')}</span>
                      </p>
                    )
                  }
                </div>
              ))
            }
        </DragScroll>
      </div>
    )
  }
  
  const [showModal, setShow] = useState(false);
  const [showInnerModal, setInnerShow] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (citation.citation_details.status !== 'settled'){
      setShow(true);
    }
  }

  const handleInnerClose = () => {
    setInnerShow(false)
    setShow(false);
  }

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={handleClose}
    />
  },[handleClose]);

  const [showDisputeModal, setDisputeShow] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDisputeClose = () => setDisputeShow(false);
  const handleDisputeShow = () =>{
    if (citationTicketStatus !== 'disputed'){
      setDisputeShow(true);
    }
  }

  const closeDisputeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={handleDisputeClose}
    />
  },[handleDisputeClose]);

  
  if(isEmpty(citation)) return <span>{t("citation_management.not_found")}</span>

  return (
    <div className="d-flex flex-column align-items-center w-100 p-3">
      <Button
        className={`${styles.btn} ${styles["btn-plate-number"]} d-flex justify-content-center align-items-center mb-4`}
      >
        <span className="mr-2" style={{ opacity: 0.75 }}>
          {`${t("citation_management.vehicle_license_plate_number")}:`}
        </span>
        <span>{citation.plate_number}</span>
      </Button>
      <p className={`${styles["heading-2"]} my-3`}>
        {t("citation_management.citation_details")}
      </p>
      <Table bordered className={styles.table}>
        <thead>
          <tr className="text-center">
            <th>
              <span className="border">
                {t("citation_management.citation_date")}
              </span>
            </th>
            <th>
              <span className="border">
                {t("citation_management.parking_lot")}
              </span>
            </th>
            <th>
              <span className="border">
                {t("citation_management.slot_number")}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-primary text-center">
            <td>
              {displayDate(
                citation.citation_details.citation_date,
                "MM/DD/YYYY"
              )}
            </td>
            <td>{citation.violation_details.parking_lot}</td>
            <td>{citation.citation_details.slot_number}</td>
          </tr>
        </tbody>
      </Table>
      <span className="w-100">
        <hr />
      </span>
      <p className={`${styles["heading-2"]} my-3`}>
        {t("citation_management.violation_details")}
      </p>
      <Table bordered className={styles.table}>
        <thead>
          <tr className="text-center">
            <th>
              <span>{t("citation_management.violation_date")}</span>
            </th>
            <th>
              <span>{t("citation_management.violation_time")}</span>
            </th>
            <th>
              <span>{t("citation_management.violation_type")}</span>
            </th>
            <th>
              <span>{t("citation_management.parking_lot")}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-primary text-center">
            <td>
              {displayDate(
                citation.violation_details.violation_date,
                "MM/DD/YYYY"
              )}
            </td>
            <td>{citation.violation_details.violation_time}</td>
            <td>{citation.violation_details.violation_type}</td>
            <td>{citation.violation_details.parking_lot}</td>
          </tr>
        </tbody>
      </Table>
      <span className='w-100'><hr /></span>
      <p className='d-flex align-items-center'>
        <span className='mr-2'>Parking Lot Address:</span>
        <span className='mr-2  text-primary'>{citation.parking_lot_full_address}</span>
        {citation.parking_lot_full_address && <MarkerIcon className={styles.locationIcon} style={{width: 30, height: 'auto'}} />}
      </p>
      <span className="w-100">
        <hr />
      </span>
      {citation.violation_photos?.length > 0 && (
        <React.Fragment>
          <div className="w-100">
            <p>{`${t("citation_management.images")} (${citation.violation_photos?.length})`}</p>
            <div className="hide-scroll">
              <VideoImage values={citation.violation_photos} type="Images" />
            </div>
          </div>
          <span className="w-100">
            <hr />
          </span>
        </React.Fragment>
      )}
      {citation.violation_videos?.length > 0 && (
        <React.Fragment>
          <div className="w-100">
            <p>{`Videos (${citation.violation_videos?.length || 0})`}</p>
            <div className="hide-scroll">
              <VideoImage values={citation.violation_videos} type="Videos" />
            </div>
          </div>
          <span className="w-100">
            <hr />
          </span>
        </React.Fragment>
      )}
      {citation.violation_documents?.length > 0 && (
        <React.Fragment>
          <div className="w-100">
            <p>{`${t("citation_management.documents")} (${citation.violation_documents?.length || 0})`}</p>
            <div className="hide-scroll">
              <VideoImage
                values={citation.violation_documents}
                type="Documents"
              />
            </div>
          </div>
          <span className="w-100">
            <hr />
          </span>
        </React.Fragment>
      )}
      <Button
        className={`${styles.btn} ${styles["btn-fine"]} d-flex justify-content-center align-items-center my-4`}
      >
        <span className="mr-1" style={{ opacity: 0.75 }}>
          {t("citation_management.fine")}:
        </span>
        <span>{`$${citation.fine}`}</span>
      </Button>
      <p className={styles.text}>
        <span className="mr-1">
          {t("citation_management.payment_instructions.instruction1")}
        </span>
        <span className="mr-1">
          <Trans
            i18nKey="citation_management.payment_instructions.instruction2"
            components={[<em key="0" />, <em key="1" />]}
          />
        </span>
        <span>
          <Trans
            i18nKey="citation_management.payment_instructions.instruction3"
            components={[<em key="0" />,  <em key="1" />]}
            values={{ parkingLot: citation.violation_details.parking_lot }}
          />
        </span>
      </p>
      <p className="my-3 d-flex flex-column">
        <QrCodeIcon />
        <span
          className="text-primary my-3"
          style={{ fontWeight: 500, fontSize: 17 }}
        >
          {t("citation_management.scan_to_pay")}
        </span>
        <Button className={styles.btn} onClick={handleShow}>
          {t("citation_management.pay_now")}
        </Button>
      </p>
      <Modal 
      isOpen={showModal} 
      centered 
      contentClassName={styles.appModal}
      backdropClassName={styles.backdropC}
      modalClassName={styles.modal}
      style={{maxWidth : '615px', width: '98%'}} 
      >
        <ModalHeader className="border-0 pb-0" close={closeBtn()} />
        <ModalBody>
          {<PaymentModal
            amount={citation?.fine}
            citationTicketID={citation?.id}
            record={citation}
            handleClose={handleClose}
            toast={toast}
            t={t}
            setState={setState}
            setPaymentStatus={setPaymentStatus}
            setInnerShow={setInnerShow}
            errors={errors}
            setErrors={setErrors}
          />}
          <Modal 
            isOpen={showInnerModal} 
            centered 
            contentClassName={styles.appModal}
            backdropClassName={styles.backdropC}
            modalClassName={styles.modal}
            style={{maxWidth : '480px', width: '98%'}} 
          >
            <ModalHeader className="border-0 pb-0" close={closeBtn()} />
            <ModalBody>
                <CitationStatus
                  closeModal={() => handleInnerClose()}
                  record={citation}
                  paymentStatus={paymentStatus}
                  errors={errors}
              />
            </ModalBody>
            </Modal>
        </ModalBody>
      </Modal>
      <span className="w-100">
        <hr />
      </span>
      <p className={`${styles.text} mt-5`}>
        <span className="mr-1">
          <Trans
            i18nKey="citation_management.dispute.instruction1"
            components={[<em key="0" />, <em key="1" />]}           
          />
        </span>
        <span className="">
          <Trans
            i18nKey="citation_management.dispute.instruction2"
            components={[<em key="0" />, <em key="1" />]}           
            values={{ days: 25 }}
          />
        </span>
      </p>
      <Button
        className={`${styles.btn} ${styles["btn-dispute"]} my-4`}
        onClick={handleDisputeShow}
      >
        {t("citation_management.dispute_btn")}
      </Button>
      <Modal 
        isOpen={showDisputeModal} 
        centered 
        contentClassName={styles.appModal}
        backdropClassName={styles.backdropC}
        modalClassName={styles.modal}
        style={{maxWidth: 550, width: '98%'}} 
      >
        <ModalHeader className='border-0 pb-0' close={closeDisputeBtn()} />
        <ModalBody className='d-flex justify-content-center align-items-center'>
          <Col>
            <p className={`${styles.modalTitle} m-0 p-0`}> Raise a Dispute </p>
            <p className={styles.modalContent}> Select a dispute type below. Please note that if your dispute is rejected, this citation will be sent to court after <span className="font-weight-bold">25 days</span>. </p>
            <Row className={`flex-column justify-content-center align-items-center ${styles.modalBody}`}>
              {
                <DisputeModal 
                  {...props} 
                  citationTicketID={citation?.id} 
                  handleDisputeClose={handleDisputeClose} 
                  toast={toast} 
                  closeDisputeBtn={closeDisputeBtn} 
                  citationTicketStatus={citationTicketStatus}
                  setCitationTicketStatus={setCitationTicketStatus}
                />
              }
            </Row>
          </Col>
        </ModalBody>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

const TicketDetails = ({t, fetching, citation, citationTicketStatus, setCitationTicketStatus, setPaymentStatus}) => {
  return (
    <div className={`${styles['section-citation-details']} mt-4 mb-5 w-100`}>
      <section className={`${styles.section} h-100 d-flex align-items-center justify-content-center`}>
        { 
          fetching 
            ? <Loader />
            : <Details t={t} citation={citation} citationTicketStatus={citationTicketStatus} setCitationTicketStatus={setCitationTicketStatus} setPaymentStatus={setPaymentStatus}/>
        }
      </section>
    </div>
  )
}
Details.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object
};


export default TicketDetails