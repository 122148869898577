import React, { useCallback, useMemo } from 'react'
/* Styles/Assets */
import styles from '../index.module.sass';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as ApproveIcon } from 'assets/approve-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import { ReactComponent as BarIcon } from 'assets/bar-icon.svg';
import { capitalize } from 'components/helpers';
import Action from './action'

const TableRow = (props) => {
  const { record, setRecord, selectedRecordId, openModal, modalType } = props

  const highlightAction = useCallback((actionType) => {
    return actionType === modalType && record.id === selectedRecordId
  }
    , [modalType, selectedRecordId, record.id])

  const actions = useMemo(() => {
    return [
      {
        icon: <EyeIcon />,
        className: `${styles['icon-eye']}`,
        onClick: () => openModal('show'),
        activeClass: styles['icon-eye-active'],
        status: modalType === 'show' && highlightAction('show'),
        allowActive: true
      },
      {
        icon: <ApproveIcon />,
        className: styles.icon,
        onClick: () => { (record?.status === 'pending' || record?.status === 'on_hold') && openModal('approve', record.status) },
        activeClass: styles['icon-success'],
        status: highlightAction(''),
        allowActive: record?.status === 'pending' || record?.status === 'on_hold'
      },
      {
        icon: <CloseIcon style={{ width: '8px' }} />,
        className: styles.icon,
        onClick: () => { ['pending', 'on_hold'].includes(record?.status) && openModal('reject', record.status) },
        activeClass: styles['icon-danger'],
        status: modalType === 'reject' && highlightAction('reject'),
        allowActive: ['pending', 'on_hold'].includes(record?.status),
        disableHighlight: record?.status === "approved"
      },
      {
        icon: <><BarIcon style={{ marginRight: '2px' }} /><BarIcon /></>,
        className: styles.icon,
        onClick: () => { record?.status === 'pending' && openModal('on_hold', record?.status) },
        activeClass: styles['icon-alert'],
        status: modalType === 'on_hold' && highlightAction('on_hold'),
        allowActive: record?.status === 'pending',
        disableHighlight: record?.status !== "pending"
      },
    ]
  }, [openModal, modalType, highlightAction, record])


  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.event_name}</td>
      <td>{record?.organizer_email}</td>
      <td>+{record?.organizer_phone_number} </td>
      <td>{record?.event_days}</td>
      <td>{record?.total_spaces}</td>
      <td>{record?.cost}</td>
      <td>{capitalize(record?.event_status)}</td>
      <td>{capitalize(record?.payment_status)}</td>
      <td>
        <div className='d-flex justify-content-between align-items-center'>
          {actions.map((action, idx) => <Action key={idx} {...action} />)}
        </div>
      </td>
    </tr>
  );

};

export default TableRow