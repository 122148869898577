import { FieldType } from "components/helpers/form_fields";

const searchFields = (attrs) => {
  const { parkingLots, permitTypes, permitTypeCategory, customInputClass, customLabelClass, applicationFromDate, applicationToDate, permitFromDate, permitToDate } = attrs

  return [
  {
    name: 'permit_number',
    label: 'Permit Number:',
    placeholder: 'Type here to search',
    customLabelClass,
    customInputClass,
  },
  {
    name: 'application_date[from]',
    label: 'Application From (Date):',
    type: FieldType.SINGLE_DATE_FIELD,
    placeholder: 'Choose a date',
    max: applicationToDate || new Date(),
    customLabelClass,
    customInputClass,
  },
  {
    name: 'application_date[to]',
    label: 'Application To (Date):',
    type: FieldType.SINGLE_DATE_FIELD,
    placeholder: 'Choose a date',
    max: new Date(),
    min: applicationFromDate,
    customLabelClass,
    customInputClass,
  },
  {
    name: 'permit_type[name]',
    label: 'Permit Type:',
    placeholder: 'Choose a Type',
    type: FieldType.SELECT_FIELD,
    customLabelClass,
    customInputClass,
    options: permitTypes
  },
  {
    name: 'permit_valid[from]',
    label: 'Permit Valid From (Date):',
    placeholder: 'Choose a date',
    max: permitToDate || new Date(),
    type: FieldType.SINGLE_DATE_FIELD,
    customLabelClass,
    customInputClass,
  },
  {
    name: 'permit_valid[to]',
    label: 'Permit Valid To (Date):',
    placeholder: 'Choose a date',
    max: new Date(),
    min: permitFromDate,
    type: FieldType.SINGLE_DATE_FIELD,
    customLabelClass,
    customInputClass,
  },
  {
    name: 'vehicle[plate_number]',
    label: 'Vehicle Plate Number:',
    placeholder: 'Type here to search',
    customLabelClass,
    customInputClass,
  },
  {
    name: 'vehicle[model]',
    label: 'Vehicle Model:',
    placeholder: 'Type here to search',
    customLabelClass,
    customInputClass,
  },
  {
    name: 'vehicle_owner_name',
    label: 'Vehicle Owner Name:',
    placeholder: 'Vehicle Owner Name',
    customLabelClass,
    customInputClass,
  },
  {
    name: 'status',
    label: 'Permit Status:',
    type: FieldType.SELECT_FIELD,
    placeholder: 'Choose a status',
    customLabelClass,
    customInputClass,
    options: [
      {label: 'Pending', value: 'pending'},
      {label: 'Active', value: 'active'},
      {label: 'Revoked', value: 'revoked'},
      {label: 'Expired', value: 'expired'},
    ]
  },
  {
    name: 'issue_date',
    label: 'Issued Date:',
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    placeholder: 'Choose a date',
    customLabelClass,
    customInputClass,
  },
  {
    name: 'permit_type[parking_lot_ids]',
    label: 'Parking Lots',
    type: FieldType.MULTISELECT_FIELD,
    showToolTip: false,
    options: [{value: 0, label: 'All Parking Lots'}].concat(parkingLots),
    placeholder: 'Choose a Lot',
    customInputClass,
    customLabelClass,
    showCount: false,
  },
  {
    name: 'permit_type[category]',
    label: 'Permit Category:',
    type: FieldType.SELECT_FIELD,
    placeholder: 'Choose a Category',
    customLabelClass,
    customInputClass,
    options: permitTypeCategory
  },
  ]
}

export default searchFields;
