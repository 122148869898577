import { FieldType } from 'components/helpers/form_fields';

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate}) => [
  { 
    name: 'amount["from"]', 
    label: 'Amount',
    type: FieldType.NUMBER_FIELD,
    placeholder: 'Min.',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'created_at["from"]', 
    label: 'Generation Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'amount["to"]', 
    type: FieldType.NUMBER_FIELD,
    placeholder: 'Max.',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'created_at["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'coupon_type',
    label: 'Is Used?',
    placeholder: 'Please select',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: [{ value: 'used', label: 'Used' }, { value: 'unused', label: 'Unused' }],
    customInputClass,
    customLabelClass,
  },
  {
    name: 'is_expired',
    label: 'Expiration',
    placeholder: 'Choose a status',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: [{ value: 'true', label: 'True' }, { value: 'false', label: 'False' }],
    customInputClass,
    customLabelClass,
  },

];

export {filterFields}