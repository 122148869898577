import { PermitWaitingListActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(PermitWaitingListActions);

const PermitwaitinglistReducers = combineReducers({
  index,
  records,
  filters
});

export default PermitwaitinglistReducers;
