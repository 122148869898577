import env from '.env';
import fetchApi from 'components/modules/fetch_api';

const createDispute = (params = {}) => {
  let url = new URL(env.backend_url);
  url = `v1/disputes`;
  return fetchApi(url, { method: 'POST', data: { dispute: params } });
};

export {
  createDispute
};
