import React, { useState, createRef } from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.sass'

/**
 * @description returns Form for OTP code
 * @returns {React.ReactElement}
 */
export default function OtpForm({ value, setValue }) {
  const [focused, setFocused] = useState(false)
  const input = createRef()

  const values = value.split('')

  const CODE_LENGTH = new Array(6).fill(0)

  const handleClick = () => {
    input.current.focus()
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(true)
  }

  const handleChange = (e) => {
    const currValue = e.target.value

    setValue(value.length <= CODE_LENGTH.length && (value + currValue).slice(0, CODE_LENGTH.length))
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      setValue(value.slice(0, value.length - 1))
    }
  }

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.form}`} onClick={handleClick}>
        <input
          value=""
          ref={input}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          type="number"
          style={{
            opacity: 0,
            position: 'absolute',
            width: '100%',
          }}
        />
        {CODE_LENGTH.map((_, index) => {
          const selected = values.length === index
          const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1
          return <div className={`${styles.cell}`} key={index}>
            {values[index]}
            {(selected || filled) && focused && <div className={`${styles.shadows}`}/>}
          </div>
        })}
      </div>
    </div>
  )
}

OtpForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}
