import React from 'react'
import { Col, Container } from 'reactstrap'
import styles from './index.module.sass'
import Button from 'components/base/button'

const Delete = ({closeModal, deleteMeasure, isDeleting}) => {

  return (
    <Container>
      <Col className={styles.content} xs='12'>
        <span className='d-block'>
          Are you sure that you want to delete this
        </span>
        <span className={`d-block ${styles.boldText}`}>
          Automatic Measure
        </span>
      </Col>
      <Col className='d-flex justify-content-center align-items-center mt-4 mb-2'>
        <Button 
          className={`${styles.button} ${styles['button-bg-light']} mr-3`}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button 
          className={`${styles.button} ${styles['button-bg-danger']}`}
          onClick={() => { deleteMeasure()}}
          disabled={isDeleting}
        >
          Delete
        </Button>
      </Col>
    </Container>
  )
}

export default Delete;
