import fetchApi from 'components/modules/fetch_api';
import resourceApi from "components/modules/resource_api";

const { index } = resourceApi("parking_rules");

const update = (params = {}) => {
  const { page, perPage, parking_lot_id, agencyId, query, data, parkingRules } = params;
  return fetchApi(`dashboard/parking_rules?parking_lot_id=${parking_lot_id}`, 
    { method: 'PUT',  
      data: { page, perPage, ...query, agency_id: agencyId, parking_rules: parkingRules ? parkingRules : [{id: data?.parking_rules?.id,  status: data?.parking_rules?.status, admin_id: data?.parking_rules?.admin_id}] } });
};

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, query = {}, id } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      parking_lot_id: id
    }
  });
};

export { index, update, filterFetcher };
