import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import { map } from 'underscore';
import { capitalize } from 'components/helpers';

const Changes = (props) => {
  const { record, closeModal, listType, logName } = props;

  return (
    <Col className={styles.body}>
      <p className={`${styles.title} m-0 p-0`}>{`${logName} ${listType === 'user_activity' ? 'Activity' : 'Email'} Logs` }</p>
      <div className={styles.content}>
        <Col className='mt-4'>
          {listType === 'user_activity' && map(record.changes, (item) => (
            <div className={styles.bulletContent}>
              <Col className='pr-0' xs={1}>{`\u25CF`}</Col>
              <Col className='p-0' style={{overflowWrap: 'anywhere'}} xs={11}>
                <span>Attribute</span>
                <span className={`${styles.boldText} ml-1 mr-1`}>{item?.attribute}</span>
                <span className='mr-1 mr-1'>changed</span>
                <span className={item?.attribute === 'trackers' ? 'd-none' : ''}>from</span>
                <span className={item?.attribute === 'trackers' ? 'd-none' : `${styles.valueText} ml-1 mr-1`}>{item?.old_value === null ? 'null' : item?.old_value}</span>
                <span className={item?.attribute === 'trackers' ? 'd-none' : ''}>to</span>
                <span className={item?.attribute === 'trackers' ? 'd-none' : `${styles.valueText} ml-1 mr-1`}>{item?.new_value}</span>
              </Col>
            </div>
          ))}
          {listType === 'email' && 
            <div className={styles.bulletContent1}>
              <div className='d-flex'>
                <Col className='pr-0' xs={1}>{`\u25CF`}</Col>
                <Col className='p-0' xs={11}>
                  <span className={styles.boldText}>Status: </span>
                  <span className={`${record?.message?.status === 'success' ? styles.greenText : styles.redText}`}>{capitalize(record?.message?.status)}</span>
                </Col>
              </div>
              <div className='d-flex'>
                <Col className='pr-0' xs={1}>{`\u25CF`}</Col>
                <Col className='p-0' xs={11}>
                  <span className={styles.boldText}>Subject: </span>
                  <span>{record?.message?.subject}</span>
                </Col>
              </div>
              <div className='d-flex'>
                <Col className='pr-0' xs={1}>{`\u25CF`}</Col>
                <Col className='p-0' xs={11}>
                  <span className={`${styles.boldText} mr-1`}>To: </span>
                  <span>{record?.message?.to.join(', ')}</span>
                </Col>
              </div>
            </div>
          }
        </Col>
      </div>
      <Col className="d-flex justify-content-center mt-4">
        <Button onClick={() => closeModal()} type='button' className={styles.cancelButton}>
          Cancel
        </Button>
      </Col>
    </Col>
  )
}

export default Changes;