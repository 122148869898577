import React from "react";
import { map } from "underscore";
import styles from "./index.module.sass";
import Action from "./action";
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';

const TableRow = ({record, selectedRecord, openModal, modalType, setSelectedRecord}) => {

  const actions = [
    { 
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => openModal('edit'),
      text: 'View',
      isActive: modalType === 'edit' && selectedRecord === record.id,
      width: '50px'
    },
  ];

  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td>{record?.id}</td>
      <td>{record?.name}</td>
      <td>{record?.manager?.name}</td>
      <td>{record?.agency_type?.name}</td>
      <td>{record?.status.charAt(0).toUpperCase() + record?.status.slice(1)}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;