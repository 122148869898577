import React, { useCallback, useReducer, useState, useRef, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Details from '../event_details/index';
/* Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import LocationForm from '../event_details/location'
import Purchase from '../new'
import { create } from 'api/events'
import Confirmation from '../new/confirmation';
import GetPass from '../get_pass/index'
import { show } from 'api/webapp/city_events'
import { getParkingPass, passPreview } from 'api/webapp/city_events';
import Preview from '../get_pass/preview'
import env from ".env";
import qs from 'qs';
import { isEmpty } from 'underscore';

const initState = { errors: {}, isSaving: false, parkingLots: {}, selectedParkingLots:[], totalSelectedSpaces: 0, disableCheckboxes: false}


const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    case 'selectParkingLot':
      return {
        ...state,
        selectedParkingLots: action.payload.selectedParkingLots,
        totalSelectedSpaces: action.payload.totalSelectedSpaces,
        disableCheckboxes: action.payload.disableCheckboxes,
      };
  
    default:
      return { ...state };
  }
}

const EventModal = (props) => {
  const { openModal, modalType, event, contextHolder ,openNotificationWithIcon, t } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const [errors, setErrors] = useState({})
  const [fetchedEvent, setFetchedEvent] = useState()
  const [selectedLot, setSelectedLot] = useState()
  const [whichLocation, setWhichLocation] = useState('event')
  const [passEvent, setPassEvent] = useState()
  const [passBase64, setPassBase64] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const closeModal = useCallback(() => {
    if(state.innerModal === 'get_pass' || state.innerModal==='preview'){
      setState('innerModal', null)
    }else{
      openModal();
    }
    setPassBase64('')
    setFileUrl('')
    setPassEvent({})
    setState('selectParkingLot', { selectedParkingLots:[], totalSelectedSpaces: 0, disableCheckboxes: false })
  }, [openModal, setState, state.innerModal, setFileUrl, setPassBase64])

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'details':
        return {maxWidth : '600px'}
      case 'location':
          return {title: t("events.city_events.event_location"),maxWidth : '600px'}
      case 'purchase_event_parking':
        return {maxWidth : '750px'}
      case 'get_pass':
        return {title: '', maxWidth : '600px'}
      default:
        break;
    }
    return { width }
  },[modalType, t])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null)}}
    />
  },[setState]);

  const openLocationModal = (data) => {
    setState('innerModal', 'location')
  }

  const openGetPassModal = (data) => {
    setState('innerModal', 'get_pass')
  }

  const openPreviewModal = (data) => {
    setState('innerModal', 'preview')
  }

  useEffect(() => {
    const fetchEventApplication = async () => {
      try {
        const response = await show({id: event?.id})
          setFetchedEvent(response.data);
      } catch(error) {
        console.log(error)
      } finally {
      }
    };
    if(event?.id){
      fetchEventApplication();
    }

  }, [event])


  const purchaseEventParking = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({ data: { event_parking_application: data , allow_save: 1} })
      if(response.status === 200 || response.status === 201 ){
        closeModal()
        openModal('confirmation')
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
    } finally{
      setState('isSaving', false)
      setState('selectParkingLot', { selectedParkingLots:[], totalSelectedSpaces: 0, disableCheckboxes: false })
    }

  },[setErrors, closeModal, openModal, setState])

  const getEventParkingPass = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await getParkingPass(data)
      if(response.status === 200 || response.status === 201 ){
        if(modalType==='details'){openPreviewModal();}else{openModal('preview')}
        setPassEvent(response.data);
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
      openNotificationWithIcon('error', error.response?.data?.errors?.vehicle)
    } finally{
      setState('isSaving', false)
      setState('selectParkingLot', { selectedParkingLots:[], totalSelectedSpaces: 0, disableCheckboxes: false })
    }
  // eslint-disable-next-line
  },[setErrors, modalType, openModal, setState])


  useEffect(() => {
    const previewPass = async () => {
      setState('isSaving', true)
      try {
        const response = await passPreview({event_id: event?.event_id, pass_code: passEvent?.pass_code})
        setPassBase64(response?.data?.pdf_data)
      } catch (error) {
        setErrors(error.response?.data?.errors)
      } finally{
        setState('isSaving', false)
      }
    // eslint-disable-next-line
    }
    if(!isEmpty(passEvent)){previewPass();}
  }, [passEvent, event, setState, passBase64, setPassBase64])


  const downloadEventParkingPass = () => {
    const query = qs.stringify({ event_id: event?.event_id, pass_code: passEvent?.pass_code }, { arrayFormat: 'brackets' })
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/city_events/download_passes?${query}`;
    window.open(url, '_blank');
    window.focus();
    setPassBase64('');
    setFileUrl('')
    setPassEvent({})
    closeModal();
  }

  return (
    <Modal 
      isOpen={!!modalType}  
      centered 
      backdropClassName={styles.backdropC}
      contentClassName={styles.appModal}
      modalClassName={styles.modal}
      style={{maxWidth: modalProps().maxWidth, width: '98%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'details' && 
          <Details
            t={t}
            state={state}
            setState={setState}
            closeModal={closeModal}
            record={event}
            openModal={openModal}
            isSaving={state.isSaving}
            errors={errors}
            openInnerLocationModal={openLocationModal}
            setSelectedLot={setSelectedLot}
            selectedLot={selectedLot}
            setFetchedEvent={setFetchedEvent}
            fetchedEvent={fetchedEvent}
            setWhichLocation={setWhichLocation}
            whichLocation={whichLocation}
            openGetPassModal={openGetPassModal}
          />
        }
        {
          modalType === 'purchase_event_parking' && 
          <Purchase
            t={t}
            closeModal={closeModal} 
            formApiRef={formApiRef} 
            setFormApi={setFormApi} 
            modalType={modalType} 
            purchaseEventParking={purchaseEventParking} 
            setErrors={setErrors} 
            errors={errors} 
            state={state} 
            setState={setState}
            isSaving={state?.isSaving}
          />
        }
        {
          modalType === 'confirmation' && 
          <Confirmation
            t={t}
            closeModal={closeModal}
          />
        }
        {
          modalType === 'get_pass' && 
          <GetPass
            t={t}
            state={state}
            setState={setState}
            closeModal={closeModal}
            record={event}
            openModal={openModal}
            isSaving={state.isSaving}
            errors={errors}
            openInnerLocationModal={openLocationModal}
            setSelectedLot={setSelectedLot}
            selectedLot={selectedLot}
            setFetchedEvent={setFetchedEvent}
            fetchedEvent={fetchedEvent}
            setWhichLocation={setWhichLocation}
            whichLocation={whichLocation}
            openGetPassModal={openGetPassModal}
            setErrors={setErrors} 
            formApiRef={formApiRef} 
            setFormApi={setFormApi} 
            getEventParkingPass={getEventParkingPass}
            event={event}
            modalType={modalType}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
            contextHolder={contextHolder}
          />
        }
        {
          modalType === 'preview' && 
          <Preview
            t={t}
            state={state}
            setState={setState}
            closeModal={closeModal}
            record={event}
            openModal={openModal}
            isSaving={state.isSaving}
            errors={errors}
            openInnerLocationModal={openLocationModal}
            setSelectedLot={setSelectedLot}
            selectedLot={selectedLot}
            setFetchedEvent={setFetchedEvent}
            fetchedEvent={fetchedEvent}
            setWhichLocation={setWhichLocation}
            whichLocation={whichLocation}
            openGetPassModal={openGetPassModal}
            setErrors={setErrors} 
            formApiRef={formApiRef} 
            setFormApi={setFormApi} 
            getEventParkingPass={getEventParkingPass}
            event={event}
            downloadEventParkingPass={downloadEventParkingPass}
            passBase64={passBase64}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
          />
        }
        
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            contentClassName={styles.appModal}
            modalClassName={styles.modal}
            style={{maxWidth: '550px', width: '100%', maxHeight: '40%'}}
            backdropClassName={styles.backdropC}
          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody>
              {state.innerModal==='location' &&
                <LocationForm
                  t={t}
                  event={fetchedEvent}
                  parkingLotLocation={event?.location}
                  formApiRef={formApiRef}
                  setFormApi={setFormApi}
                  setSelectedLot={setSelectedLot}
                  selectedLot={selectedLot?.location}
                  lotName={selectedLot?.name}
                  setWhichLocation={setWhichLocation}
                  whichLocation={whichLocation}
                />
              }
              {state.innerModal==='get_pass' &&
                <GetPass
                  t={t}
                  state={state}
                  setState={setState}
                  closeModal={closeModal}
                  record={event}
                  openModal={openModal}
                  isSaving={state.isSaving}
                  errors={errors}
                  openInnerLocationModal={openLocationModal}
                  setSelectedLot={setSelectedLot}
                  selectedLot={selectedLot}
                  setFetchedEvent={setFetchedEvent}
                  fetchedEvent={fetchedEvent}
                  setWhichLocation={setWhichLocation}
                  whichLocation={whichLocation}
                  openGetPassModal={openGetPassModal}
                  setErrors={setErrors}
                  formApiRef={formApiRef} 
                  setFormApi={setFormApi}  
                  getEventParkingPass={getEventParkingPass}
                  event={event}
                  contextHolder={contextHolder}
                />
              }
              {state.innerModal==='preview' &&
                <Preview
                  t={t}
                  state={state}
                  setState={setState}
                  closeModal={closeModal}
                  record={event}
                  openModal={openModal}
                  isSaving={state.isSaving}
                  errors={errors}
                  openInnerLocationModal={openLocationModal}
                  setSelectedLot={setSelectedLot}
                  selectedLot={selectedLot}
                  setFetchedEvent={setFetchedEvent}
                  fetchedEvent={fetchedEvent}
                  setWhichLocation={setWhichLocation}
                  whichLocation={whichLocation}
                  openGetPassModal={openGetPassModal}
                  setErrors={setErrors}
                  formApiRef={formApiRef} 
                  setFormApi={setFormApi}  
                  getEventParkingPass={getEventParkingPass}
                  event={event}
                  downloadEventParkingPass={downloadEventParkingPass}
                  passBase64={passBase64}
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default EventModal;
