import resourceApi from "components/modules/resource_api";
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const { index, create, destroy } = resourceApi("select_lists/disabilities");

const filterFetcher = (params = {}) => {
    const { page, perPage = 10, filters = {}, query } = params;

    return index({
        page,
        perPage,
        query: {
            ...query,
            ...filters,
        },
    });
};

const update = (params = {}) => {
    const { id, data = {} } = params;
    return fetchApi(generatePath(`dashboard/select_lists/disabilities/${id}`), {
        method: 'PUT',
        params: data,
    })
};

export { create, filterFetcher, destroy, update };