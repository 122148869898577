import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/button';
import { FieldType } from 'components/helpers/form_fields';
import { ArrayField, Form  } from 'informed';
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import styles from './index.module.sass'
import { renderField } from '../../../base/forms/common_form'
import { isEmpty } from 'underscore';


const GenerateReportModal = ({modal, closeModal, generateReport, isGenerating}) => {
  const NEW_FIELD = 'new_field'
  const formApiRef = useRef(null)
  const [validateEmail, setValidateEmail] = useState(false);
  const [errors, setErrors] = useState({});

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  

  const sendEmailField = useMemo(() => ({
    label: 'Send Report Via Email?',
    name: 'send_email',
    type: FieldType.RADIO_BUTTON_FIELD,
    mandatory: true,
    customInputClass: 'my-2 shadow-none border-dark',
    list: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  }),[])

  const fieldProps = useMemo(() => ({
    events: {
      onChange: (value) => {
        if(value === 'Yes') {
          setValidateEmail(true)
        }else {
          setValidateEmail(false)
        }
        setErrors({})
      }
    }
  }),[setErrors, setValidateEmail])

  const emailField = useCallback((key) => ({
    fieldType: 'email',
    name: `emails[${key}]`,
    placeholder: 'Enter email address *',
    mandatory: validateEmail,
    customInputClass: 'my-2 shadow-none border-dark',
    className: 'm-0 flex-grow-1'
  }),[validateEmail])

  const handleSubmit = useCallback((data) => {
    setErrors({})
    if((!validateEmail || (validateEmail && !isEmpty(data.emails))) && !isEmpty(data)) {
      generateReport(data)
      formApiRef.current.reset()
      setValidateEmail(false)
    } else if (validateEmail) {
      setErrors({"emails[new_field]": ['Email can\t be blank']})
    } else if(isEmpty(data)){
      setErrors({"send_email": ['Please Select Yes or No !']})
    }
  },[setValidateEmail, validateEmail, setErrors, generateReport])

  return (
    <Modal centered={true} isOpen={modal} toggle={closeModal} >
      <ModalBody>
        { modal && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
        <Container className="py-4">
          <h5 className={`${styles.title} text-center`}>Generate Citations Report</h5>
          <Row className="w-100 m-0 flex-column align-items-center">
            <Form getApi={setFormApi} className='my-2 w-100 text-center pt-2' onSubmit={handleSubmit}>
            {({formState}) => {
              const { send_email } = formState.values
              return <React.Fragment>
                <Col className='p-0 mx-auto col-10 my-0 text-left'>
                  {renderField(sendEmailField, {...fieldProps, lSize: 5, iSize: 7, errors})}
                </Col> 
                {
                  send_email === 'Yes' && <React.Fragment>
                    <div className='w-75 d-flex justify-content-center m-auto flex-wrap'>
                      <ArrayField field="emails">
                        {({ add, fields }) => (
                          <React.Fragment>
                            <Col className='p-0 m-0 d-flex align-items-center' xs={12}>
                            { renderField({...emailField(NEW_FIELD), label: null}, {errors, events:{onChange:(_e) => setErrors({})}}) }
                              <Button className={`${styles.btn} ${styles['btn-primary']} ml-2`} type='button'   onClick={add}>
                                <FontAwesomeIcon 
                                  color='primary'
                                  icon={faPlus} 
                                />
                              </Button>
                            </Col>
                            {fields.map(({ _field, key, remove }) => (
                              <Col className='p-0 m-0 d-flex align-items-center' xs={12} key={key}>
                                  { renderField({...emailField(key), label: null}, {}) }
                                <Button className={`${styles.btn} ${styles['btn-disabled']} ml-2`} type='button'  onClick={remove}>
                                  <FontAwesomeIcon 
                                    icon={faMinus}
                                  />
                                </Button>
                              </Col>
                            ))}
                          </React.Fragment>
                        )}
                      </ArrayField>
                    </div>
                  </React.Fragment>
                }
               
                <Col className='my-4 d-flex justify-content-center' xs={12}>
                  <Button 
                    type='button'
                    onClick={() => closeModal()}
                    className={`${styles.button} ${styles['button-disabled']} mr-5`}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={`${styles.button}`} 
                    isLoading={isGenerating}                    
                    type='submit'
                  >
                    Generate
                  </Button>
                </Col>
              </React.Fragment>
            }}
            </Form>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default GenerateReportModal;