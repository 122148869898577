import { SystemLogsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(SystemLogsActions);

const SystemLogsReducers = combineReducers({
  index,
  records,
  filters
});

export default SystemLogsReducers;