import { FieldType } from "components/helpers/form_fields";
import searchFields from "./search";
import showFields from "./showFields";
import approveFields from "./approve";

const filterFields = () => ([
  {name: 'submitted_on', label: 'Submitted on'},
  {name: 'status', label: 'Status'},
  {name: 'permit_type', label: 'Permit Type'},
  {name: 'application type', label: 'Application Type'},
  {name: 'applicant_name', label: 'Applicant Name'},
  {name: 'vehicle_lpn', label: 'Vehicle LPN'},
])

const rejectFields = (customLabelClass='', customInputClass='', rejectionReasons) => ([
  {
    name: 'rejection_reason',
    label: 'Reason',
    type: FieldType.SELECT_FIELD,
    options: rejectionReasons,
    customLabelClass,
    customInputClass,
  },
  {
    name: 'rejection_note',
    placeholder: 'Elaborate here',
    type: FieldType.TEXT_AREA,
    rows: 5,
    customInputClass,
  }
])

export { approveFields, searchFields, filterFields, rejectFields, showFields, };