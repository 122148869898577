import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';


const  index = (params = {}) => {
  const { page, perPage = 10, query } = params;
  return fetchApi('v1/parking/citation_tickets', { method: 'GET', params: { page, per_page: perPage, ...query } })
};

const show = (params = {}) => {
  const { id, nestedParams = {} } = params;
  return fetchApi(generatePath(`v1/parking/citation_tickets/${id}`, nestedParams), { method: 'GET' });
};

const downloadPdf = (params = {}) => {
  const { id, paymentLink, nestedParams = {} } = params;
  return fetchApi(generatePath(`v1/parking/citation_tickets/${id}/citation_ticket_pdf?payment_link=${paymentLink}`, nestedParams), { method: 'GET' });
};

const filterFetcher = (params = {}) => {
  const { page, perPage, query, status, search } = params;
  
  return index({
    page,
    perPage,
    query: {
      ...query,
      status: status==='all' ? '' : status,
      search: search
    }
  });
};

const pay = (params) => {
  const { id, data } = params
  return fetchApi(`v1/parking/citation_tickets/${id}/pay`, { method: 'POST', data })
};

const createDispute = (params = {}) => {
  return fetchApi(`v1/disputes`, { method: 'POST', data: { dispute: params } })
};

export {
  filterFetcher,
  show,
  downloadPdf,
  pay,
  createDispute
};
