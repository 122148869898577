import React from 'react'
import { displayUnixTimestamp } from 'components/helpers';

const TableRow = ({record, setRecord}) => {
  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record.id}</td>
      <td>{displayUnixTimestamp(record.check_in)}</td>
      <td>{displayUnixTimestamp(record.check_out)}</td>
      <td className='text-uppercase'>{record.status}</td>
      <td className='text-uppercase'>{record?.vehicle?.plate_number}</td>
    </tr>
  )
}

export default TableRow;
