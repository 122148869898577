import React, { useCallback } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "./index.module.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

import RewardHistory from "../history";

const RewardsModal = (props) => {
  const { record, openModal, modalType, setActiveTab } = props;

  const closeModal = useCallback(() => {
    openModal();
    setActiveTab();
  }, [openModal, setActiveTab]);

  const modalProps = useCallback(() => {
    const maxWidth = 480;
    switch (modalType) {
      case "history":
        return { title: "Patrons Rewards Program", maxWidth: 600 };
      default:
        return { maxWidth };
    }
  }, [modalType]);

  const closeBtn = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.closeIcon}
        onClick={closeModal}
      />
    );
  }, [closeModal]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.modal}
      style={{ maxWidth: modalProps().maxWidth, width: "100%" }}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {modalType === "history" && (
          <RewardHistory {...props} record={record} closeModal={closeModal} />
        )}
      </ModalBody>
    </Modal>
  );
};

const mapState = (state) => {
  return {
    record: state.user_reward.records.record,
  };
};
export default connect(mapState, null)(RewardsModal);