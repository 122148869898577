const SET_LIST = 'SET_VEHICLES_LIST';
const SET_RECORD = 'SET_VEHICLES_RECORD';
const SET_LIST_ELEMENT = 'SET_VEHICLES_ELEMENT';
const POP_LIST_ELEMENT = 'POP_LIST_ELEMENT';
const SET_FILTERS = 'SET_VEHICLES_FILTERS';
const SET_SINGLE_RECORD = 'SET_SINGLE_VEHICLES_RECORD';

export {
  SET_LIST,
  SET_LIST_ELEMENT,
  SET_RECORD,
  POP_LIST_ELEMENT,
  SET_FILTERS,
  SET_SINGLE_RECORD
};
