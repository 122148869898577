import { FieldType } from 'components/helpers/form_fields';

const fieldsNew = (attrs, disabled) => {
  const { mute=true, customLabelClass='', customInputClass='', customCouponCodeInputClass = '', icon, customIconInput} = attrs
  return ([
    [
      {
        name: 'beneficiary_type',
        label: 'Beneficiary',
        type: FieldType.RADIO_BUTTON_FIELD,
        mandatory: true,
        list: [
          { label: 'Subscriber', value: 'subscriber' },
          { label: 'Bulk / Event Parking', value: 'bulk_coupons' }
        ],
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
    ],
    [
      {
        name: 'user_email',
        label: 'Email Address',
        placeholder:"User Email Address",
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled,
      }
    ],
    [
      {
        name: 'amount',
        label: 'Amount',
        type: FieldType.NUMBER_FIELD,
        placeholder:" Coupon Amount",
        disabled ,
        customInputClass: customIconInput,
        customLabelClass,
        mandatory: true,
        min: 1,
        icon
      },
    ],
    [
      {
        name: 'validity_period',
        label: 'Validity Period',
        type: FieldType.NUMBER_FIELD,
        placeholder:"Number of Days",
        tooltip: '',
        min: 1,
        customInputClass,
        customLabelClass,
        disabled,
        mandatory: true,
      }
    ],
    [
      {
        name: 'reason',
        label:"Reason",
        placeholder: 'Coupon Reason',
        type: FieldType.TEXT_AREA,
        rows: 3,
        customInputClass,
        customLabelClass,
        disabled,
        mandatory: true,
      }
    ],
    [
      {
        name: 'coupon_code',
        label: 'Coupon Code',
        placeholder:"Coupon Code",
        customInputClass: customCouponCodeInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute,
      },
    ],
  ]);
} 

export { fieldsNew };