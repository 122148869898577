import { FieldType } from 'components/helpers/form_fields';

const fieldsEdit = (attrs, disabled) => {
  const { customLabelClass='', customInputClass='' } = attrs
  return ([
    [
      {
        name: 'violation_type',
        label: 'Violation Type',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: true,
      }
    ],
    [
      {
        name: 'repetitions_allowed',
        label: 'Maximum Repetitions',
        type: FieldType.NUMBER_FIELD,
        min: 1,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
    ],
    [
      {
        name: 'measure_type',
        label:"Measure",
        type: FieldType.SELECT_FIELD,
        options: [{ value: 'place_on_hold', label: 'Place On Hold' }, { value: 'deactivate_account', label: 'Deactivate Account' }],
        customInputClass,
        customLabelClass,
        disabled,
        mandatory: true,

      },
    ],
    [
      {
        name: 'status',
        label:"Status",
        type: FieldType.SELECT_FIELD,
        options: [{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }],
        customInputClass,
        customLabelClass,
        disabled,
        mandatory: true,

      },
    ],
  ]);
} 

export { fieldsEdit };