import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";
import fetchApi from 'components/modules/fetch_api';

const { index } = resourceApi("user_rewards");
const search = searchApi("user_rewards");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, id, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      user_id: id,
      ...filters,
    },
  });
};

const rewardHistoryFetcher = (params) => {
  const {user_id, reward_id} = params
  return fetchApi(`dashboard/user_rewards/reward_history_lists?user_id=${user_id}&reward_id=${reward_id}`, { method: 'GET' })
}

export { filterFetcher, index, search, rewardHistoryFetcher };
