import { FieldType } from "components/helpers/form_fields";

const createFields = (attrs) => {
  const { customLabelClass = "", customInputClass = "" } = attrs;
  return [
    [
      {
        name: "name",
        label: "Name",
        mandatory: true,
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: "status",
        label: "Status",
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: [
          { value: true, label: "Activate" },
          { value: false, label: "Deactivate" },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
    ],
  ];
};

export { createFields };