import fetchApi from 'components/modules/fetch_api';
import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";


const  index = (data) => {
  const {status, filters} = data
  if(filters !== null){
    const { search, start_date={}, cost_type} = filters
    return fetchApi(
      `v1/city_events?status=${status}&online_portal=false${
        search ? `&search=${search}` : ""
      }${
        start_date?.from !== undefined
          ? `&start_date[from]=${start_date?.from}`
          : ""
      }&${
        start_date?.to !== undefined ? `start_date[to]=${start_date?.to}` : ""
      }${cost_type ? `&cost_type=${cost_type}` : ""}`,
      { method: "GET" }
    );
  }else {
    return fetchApi(`v1/city_events?status=${status}&online_portal=false`, { method: 'GET'})
  }
};


const show = (params) => {
  const { id } = params
  return fetchApi(`v1/city_events/${id}`, { method: 'GET' })
};


const getParkingPass = (data) => {
  const token = localStorage.getItem("SUB_TOKEN")
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/city_events/get_pass?online_portal=false`;
  const options = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    data: data
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const passPreview = (data) => {
  const {event_id, pass_code} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/city_events/download_passes?preview=true&event_id=${event_id}&pass_code=${pass_code}`;
  const options = {
    method: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const downloadPass = (data) => {
  const {event_id} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/city_events/download_passes?event_id=${event_id}`;
  const options = {
    method: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const verify = (data) => {
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/city_events/send_verification_code`;
    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }
  
  const verifyAndGetEvent = (data) => {
    const {verificationCode, eventID} = data
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/city_events/private_event_details?event_id=${eventID}&verification_code=${verificationCode}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }


export { index , show, getParkingPass, passPreview, downloadPass, verify, verifyAndGetEvent};
