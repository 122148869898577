import React, { useCallback, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { Form } from "informed";
import { newFields } from "components/helpers/fields/user_charging_sessions";
import { show } from "api/user_charging_sessions";
import { renderFields } from "components/base/forms/common_form";
import styles from "./index.module.sass";
import Loader from "components/helpers/loader";
import SlideButton from "components/base/slide_button";
import { ReactComponent as SwitchOffIcon } from "assets/power_icon.svg";
import { ReactComponent as ChargingSessionIcon } from "assets/user_charging_session.svg";
import BatteryGauge from "react-battery-gauge";

const Show = ({ record }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [chargingSession, setChargingSession] = useState({});

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };
  const formApiRef = useRef();

  const applicationData = useCallback(
    (data) => {
      const {
        id,
        energy_transferred,
        total_cost,
        vehicle_owner_name,
        level,
        voltage_rating,
        charger_type,
        charging_percentage,
        vehicle = {},
        slot_name,
        start_time,
        end_time,
        charger = {},
      } = data;

      var diffTime =
        Date.parse(end_time) / 1000 - Date.parse(start_time) / 1000;
      const hours = Math.floor(diffTime / 3600);
      const remainingSeconds = diffTime % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      var chargingTime = `${hours} Hour ${minutes} Minutes`;

      return {
        id: id,
        slot_name: slot_name,
        energy_transferred: energy_transferred,
        voltage_rating: voltage_rating,
        total_cost: total_cost,
        level: level,
        charger_name: charger?.name,
        charger_type: charger_type,
        cost_per_kwh: record?.charger?.cost_per_kwh,
        vehicle_owner_name: vehicle_owner_name,
        charging_percentage: `${charging_percentage} %`,
        chargingTime: chargingTime,
        vehicle: {
          model: vehicle.model,
          plate_number: vehicle.plate_number,
        },
      };
    },
    [record]
  );

  useEffect(() => {
    setIsFetching(true);
    const fetchChargingSession = async () => {
      if (!record?.charger) return;
      setIsFetching(true);
      try {
        const response = await show({ id: record?.id });
        if (response.status === 200 || response.status === 201) {
          setChargingSession(applicationData(response?.data));
        }
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      } finally {
        setIsFetching(false);
      }
    };
    fetchChargingSession();
  }, [record, applicationData]);

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customSingleInputClass: styles.inputSingle,
    customDropdownClass: styles.formDropdownInput,
  };

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
  };

  const doubleFieldProps = {
    iSize: 7,
    lSize: 5,
  };

  const fields = (category) => {
    return newFields({ category, ...fieldAttrs });
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Container className="p-0">
      <p className={`${styles.title} mx-0 mb-3 p-0`}>
        Charging Session Details
      </p>
      <Form
        getApi={setFormApi}
        initialValues={chargingSession}
        className={styles.form}
      >
        {({ formState }) => {
          const { category } = formState.values;
          const newFields = fields(category);
          return (
            <Col>
              <Row className={styles.image}>
                <ChargingSessionIcon />
                <div className="mt-3">
                  <BatteryGauge
                    value={"50"}
                    customization={{
                      batteryBody: {
                        cornerRadius: 5,
                        strokeColor: "#333",
                        strokeWidth: 2,
                      },
                      batteryCap: {
                        capToBodyRatio: 0.3,
                        cornerRadius: 1,
                        strokeColor: "#333",
                        strokeWidth: 2,
                      },
                      batteryMeter: {
                        noOfCells: 10,
                        outerGap: 1,
                      },
                      readingText: {
                        style: {
                          display: "none",
                        },
                      },
                    }}
                    size={90}
                    orientation="vertical"
                  >
                    <defs>
                      <filter id="shadow">
                        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                      </filter>
                    </defs>
                  </BatteryGauge>
                </div>
              </Row>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Session Details</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
              <div style={{ margin: "10px" }}>
                <Row>
                  <div className={styles.fieldset}>
                    {renderFields(newFields.slice(0, 1), {
                      ...doubleFieldProps,
                    })}
                  </div>
                  <div
                    className={`${styles.fieldset} ${styles["fieldset-right-label"]}`}
                  >
                    {renderFields(newFields.slice(1, 2), {
                      ...doubleFieldProps,
                    })}
                  </div>
                </Row>
                {renderFields(newFields.slice(10, 11), { ...singleFieldProps })}
                {renderFields(newFields.slice(2, 4), { ...singleFieldProps })}
                <Row>
                  <div className={styles.fieldset}>
                    {renderFields(newFields.slice(4, 5), {
                      ...doubleFieldProps,
                    })}
                  </div>
                  <div
                    className={`${styles.fieldset} ${styles["fieldset-right-label"]}`}
                  >
                    {renderFields(newFields.slice(5, 6), {
                      ...doubleFieldProps,
                    })}
                  </div>
                </Row>
              </div>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Parking Details</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
              <div style={{ margin: "10px" }}>
                <Row>
                  <div className={styles.fieldset}>
                    {renderFields(newFields.slice(6, 8), {
                      ...doubleFieldProps,
                    })}
                  </div>
                  <div
                    className={`${styles.fieldset} ${styles["fieldset-right-label"]}`}
                  >
                    {renderFields(newFields.slice(8, 10), {
                      ...doubleFieldProps,
                    })}
                  </div>
                </Row>
              </div>
              <Col className="d-flex justify-content-center">
                <SlideButton
                  isActive={true}
                  icon={<SwitchOffIcon />}
                  className={styles.powerOff}
                >
                  <span
                    className="mr-3 d-inline-block"
                    style={{ width: "100px", fontWeight: "bolder" }}
                  >
                    Stop Session
                  </span>
                </SlideButton>
              </Col>
            </Col>
          );
        }}
      </Form>
    </Container>
  );
};

export default Show;