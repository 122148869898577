import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS } from 'actions/permits';
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/* Modules */
import connectList from 'components/modules/connect_list';
import resourceFetcher from 'components/modules/resource_fetcher';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* API */
import { filterFetcher } from 'api/permits';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
/* Components */
import ToolBar from './toolbar';
import PermitModal from '../permit_modal';
import TableData from './tableData';

const Index = (props) => {
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()
  const [listType, setListType] = useState('permitted')

  const openModal = useCallback((modalType, status) => {
    let val
    // Evaluate modalType
    switch (true) {
      case !['revoked', 'expired', 'cancelled'].includes(status) && modalType === 'revoke':
        val = 'revoke'
        break;
      case ['import_permit_types', 'import_issued_permits', 'import', 'show', 'edit'].includes(modalType):
        val = modalType
        break
      default:
        break;
    }
    setModalType(val)
  },[setModalType])

  const openCollapse = ['filters'].includes(activeTab)

  return (
    <AccessControl
      currentRole={props.currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              openModal={openModal} 
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              modalType={modalType}
              openCollapse={openCollapse}
              listType={listType}
              setListType={setListType}
              {...props}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} modalType={modalType} openCollapse={openCollapse} />
          </Col>
        </Row>
        <PermitModal 
          modalType={modalType} 
          openModal={openModal} 
        />
      </Container>
    </AccessControl>
  )
}

const resource = 'permit';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setFilters: invoke(SET_FILTERS), 
      setRecord: invoke(SET_SINGLE_RECORD) 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.permit.filters,
    selectedRecord: state.permit.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);