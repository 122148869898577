import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Resend = (props) => {

  const { resendMessage, closeModal } = props;
  
  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Resend Message</p>
      <div className={styles.content}>
        <Col className='mt-3 mb-5'>
        This will resend the broadcast message. Are you sure?
        </Col>
      </div>
      <Col className="d-flex justify-content-center mt-3 mb-2">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={()=>  resendMessage()} className={styles.submitButton}>
          Send
        </Button>
      </Col>
    </Col>
  )
}

export default Resend;