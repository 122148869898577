//modified:   src/actions/monitor_vehicles.js
const SET_LIST = 'SET_MONITOR_VEHICLE_LIST';
const SET_LIST_ELEMENT = 'SET_MONITOR_VEHICLE_LIST_ELEMENT';
const SET_RECORD = 'SET_MONITOR_VEHICLE_RECORD';
const ADD_LIST_ELEMENT = 'ADD_MONITOR_VEHICLE_LIST_ELEMENT'
const SET_SINGLE_RECORD = 'SET_SINGLE_MONITOR_VEHICLE_RECORD'
const SET_FILTERS = 'SET_MONITOR_VEHICLE_FILTERS'




export { SET_LIST, SET_RECORD, SET_LIST_ELEMENT, ADD_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS };

