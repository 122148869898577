import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
/* Styles/Assets */
import styles from './index.module.sass';

class Delete extends React.Component {

  componentDidMount = () => {
    const {setState} = this.props
    setState('errors', {})
  }

  render () {
    const { deletePermitType, state, closeModal, record } = this.props
    return (
      <Col className={styles.body}>
        <div className={styles.content}>
          <Col className='my-3'>
            This will delete the
            {' '}
            <span className={styles.boldText}>{record.name}</span>
            {' '}
            permit type from the system. Any subscribers who may have paid for the permit type will be entitled to a refund, and their permits will be revoked. Instead, you may use the <span className={styles.boldText}>"deactivate"</span> feature to prevent subscribers from applying for this permit type and delete it when all issued permits of this type have expired.
          </Col>
          <Col className='mb-2'>
            Do you want to proceed with deletion?
          </Col>
        </div>
        <Col className="d-flex justify-content-center my-3">
          <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
            Cancel
          </Button>
          <Button type='button' onClick={deletePermitType} className={`${styles.submitButton}`}   disabled={state.isDeleting}>
            Delete
          </Button>
        </Col>
      </Col>
    )
  }
}

export default Delete;