import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Button from 'components/base/button'
import styles from './index.module.sass'

const Delete = ({deleteNotification, record, closeModal}) => {

  return (
    <Container className='p-0 mw-100 m-0'>
      <p className={`${styles.modalTitle}  mx-0 mb-2 p-0 text-capitalize text-center`}>Delete Notification</p>
      <Row className='flex-wrap w-100 m-0 justify-content-center'>
        <Col xs={10} className={`${styles.content} mt-4`}>
          Are you sure that you want to delete the notification
        </Col>
        <Col className='my-4 d-flex justify-content-center' xs={12}>
          <Button 
            onClick={() => closeModal()}
            className={styles.cancelButton}
          >
            No
          </Button>
          <Button
            onClick={() => deleteNotification({id: record.id})}
            className={styles.submitButton}
          >
            Yes
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default Delete;
