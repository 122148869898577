import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Input,  Row, Col } from 'reactstrap';
import { isEmpty } from 'underscore';
/* Actions */
import { setSubscriberToken, setCurrentSubData } from 'actions/users';
/* API */
import { auth, handleSocialSignIn } from 'api/webapp/subscriber';
/* Base */
import CardLayout from 'components/base/layout/card';
import AuthLayout from 'components/base/layout/auth';
/* Helpers */
import { btnSpinner } from 'components/helpers';
import { setErrorsMessages } from 'components/helpers/messages';
import Password from 'components/helpers/form_fields/password'
import styles from './index.module.sass'
/* Modules */
import RedirectIfAuthorized from 'components/modules/redirect_if_authorized';
import AppModal from '../app/app_modal';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { message, Divider, ConfigProvider } from 'antd';
import axios from 'axios';
import WebAppHeader from 'components/base/webapp_header';
import { camelize } from 'components/helpers';
import env from '.env'
import { Button as AntdButton } from 'antd'
import {ReactComponent as FacebookIcon} from 'assets/app/facebook-icon.svg'
import ThemeProvider from 'subscriberThemeProvider';

const Login =(props) => {
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [messages, setMessages] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [modalType, setModalType] = useState('')
  const [messageApi, contextHolder] = message.useMessage();
  const [messagePop, setMessagePop] = useState(false)
  const {history} = props
  const { t } = useTranslation()
  
  const openModal = (modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'apply_permit':
        val = 'apply_permit'
        break;
      case modalType === 'find_parking':
        val = 'find_parking'
        break;
      case modalType === 'confirmation': 
        val = 'confirmation'
        break;
      default:
        break;
    }
    setModalType(val)
  }

  const success = () => {
    messageApi.open({
      type: 'success',
      duration: 2,
      content: 'Verification Successful! Please login to access your account',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
    setMessagePop(true)
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: env.google_client_id,
      callback: googleSignInCallback,
      cancel_on_tap_outside: false,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      shape: "rectangle",
      logo_alignment: "left",
      size: "large",
      width: "390px",
      type: 'icon'
      
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googleSignInCallback = (result) => {
    if (result.credential) {
      const params = { token: result.credential, provider: "google" };
      axios
        .post(`${env.backend_url}/v1/users/check_socially_registered`, params)
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.message === "Proceed with sign up"
          ) {
            history.push("/complete_registration", {
              token: result.credential,
              email: res?.data?.email,
              provider: "google",
            });
          }
          if (res.data.message === "Proceed with sign in") {
            handleSocialSignIn(result.credential, "google")
              .then((res) => {
                setSubscriberToken(res.data);
                localStorage.removeItem("TOKEN");
                localStorage.setItem("SUB_TOKEN", res.data.token);
              })
              .catch((error) => {
                const errorMessage = !error.request?.status
                  ? "Could not connect to the server now. Please try again later."
                  : error;
                setIsFetching(false);
                setMessages(setErrorsMessages(errorMessage));
              });
          }
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = !error.request?.status
            ? "Could not connect to the server now. Please try again later."
            : error;
          setIsFetching(false);
          setMessages(setErrorsMessages(errorMessage));
        });
    }
  };

  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3790025374561829", // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v12.0", // Use the latest version available
      });

      // Check the initial login status
      window.FB.getLoginStatus((response) => {
        console.log("FB login status:", response);
      });
    };

    // Load the SDK script dynamically if not already loaded
    if (!document.getElementById("facebook-jssdk")) {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      document.body.appendChild(script);
    }
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log("Welcome! Fetching your information....");
          const { accessToken } = response.authResponse;
          handleServerLogin(accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile" }
    );
  };

  const handleServerLogin = async (accessToken) => {
    try {
      const res = await axios.post(
        `${env.backend_url}/v1/users/check_socially_registered`,
        { token: accessToken, provider: "facebook" }
      );

      if (res.status === 200 && res.data.message === "Proceed with sign up") {
        history.push("/complete_registration", {
          token: accessToken,
          email: res?.data?.email,
          provider: "facebook",
        });
      }
      if (res.data.message === "Proceed with sign in") {
        handleSocialSignIn(accessToken, "facebook")
          .then((res) => {
            setSubscriberToken(res.data);
            localStorage.removeItem("TOKEN");
            localStorage.setItem("SUB_TOKEN", res.data.token);
          })
          .catch((error) => {
            const errorMessage = !error.request?.status
              ? "Could not connect to the server now. Please try again later."
              : error;
            setIsFetching(false);
            setMessages(setErrorsMessages(errorMessage));
          });
      }
    } catch (error) {
      console.error(
        "Server login failed:",
        error.response?.data || error.message
      );
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    setIsFetching(true)
    auth(username, password)
      .then(res => { setSubscriberToken(res.data)
        localStorage.removeItem('TOKEN')
        localStorage.setItem('SUB_TOKEN', res.data.token)})
      .catch(error => {
        const errorMessage = !error.request?.status ? 'Could not connect to the server now. Please try again later.' : error
        setIsFetching(false)
        setMessages(setErrorsMessages(errorMessage))
      });
  };

  const setCurrentSubData = () => {
    props.setCurrentSubData()
      .catch(error => {
        console.log(error)
        setIsFetching(false)
        setMessages(setErrorsMessages(error))
      });
  }


  const setSubscriberToken = (data) => {
    localStorage.setItem('SUB_TOKEN', data)
    setIsFetching(false)
    setMessages([])
    props.setSubscriberToken(data.token);
    setCurrentSubData();
    props.history.push('/subscriber');
  }

  const validInputs = () => {
    return username && password
  }
  
  useEffect(() => {
    if(localStorage.getItem('firstLoadDone') === null && !messagePop && props?.location?.state?.showMessage){
      localStorage.setItem('firstLoadDone', 1);
      success();
      setMessagePop(true);
    }
    // eslint-disable-next-line
  }, [messagePop, props])

  

  return (
    <ThemeProvider>
      <WebAppHeader />
      <AuthLayout>
        {contextHolder}
        <CardLayout isFetching={isFetching} messages={messages}>
          <form onSubmit={submitForm}>
            <h1 className="h1-title-primary mb-4 text-center">{camelize(t("login_page.login_camelized"))}</h1>
            
            <div className="form-label-group">
              <label className="general-text-3" htmlFor="email">{t("signup.Email")}</label>
              <Input
                id="email"
                type="text"
                value={username}
                name="username"
                onChange={e => setUserName(e.target.value)}
                className={`form-control-lg ${!isEmpty(messages) ? 'input-error' : ''}`}
                placeholder={t("login_page.email_placeholder")}
                required
                autoFocus
              />
            </div>
  
            <div className="form-label-group mt-2">
              <label className="general-text-3" htmlFor="password">{t("signup.Password")}</label>
              <Password
                field={{ name: "password", filled: false }}
                customAttr={{
                  onChange: e => setPassword(e.target.value),
                  className: `position-relative form-control-lg form-control ${styles.passwordField} ${!isEmpty(messages) ? 'input-error' : ''}`,
                  placeholder: t("login_page.password_placeholder")
                }}
                className
              />
            </div>
  
            <Link to='/forgot_password' className="mr-1 mt-2 mb-5 d-block general-text-1">{t("forgot_password")}</Link>
            <Button color={validInputs() ? 'primary-lg' : 'disabled-lg' } className={`${validInputs() ? styles.button : styles.disabledBg} mt-4 p-3 text-uppercase btn-lg btn-block`} type="submit" disabled={isFetching}>
              {isFetching ? btnSpinner({ className: 'spinner-border' }) : t("login_page.login")}
            </Button>
            <div className={styles.registerSection}>
              <span className={`${styles.registerQ} mt-2 mb-2 d-block general-text-1`}>Don't have an account?</span>
              <Link to='/signup' className={`${styles.registerLink} text-uppercase mr-1 ml-2 mt-2 mb-2 d-block general-text-1`}>{t("register")}</Link>
            </div>
          </form>
          <ConfigProvider
            theme={{
              token: {
                colorSplit: '#000000',
              },
            }}
          >
          <Divider>
            {t("login_page.or")}
          </Divider>
          </ConfigProvider>
          <Row className={styles.socialBtns}>
            <Col className={styles.googleSignInDiv} xs={4}>
              <div id="signInDiv" className={styles.googleSignInBtn}/>
            </Col>
            <Col className={styles.fbBtnSection} xs={4}>
              <AntdButton type="button" className={styles.fbBtn} shape="circle" onClick={handleFacebookLogin} icon={<FacebookIcon className={styles.fbIcon}/>}/>
            </Col>
            </Row>
          <div className={styles.horizontalLine1}/>
          <Row style={{marginTop: '2rem', marginLeft: '0.1rem'}}>
            <span className={styles.name1}>
              {t("account_confirmation")}
            </span>
            <a href='/account_confirmation' className={styles.name2} style={{color: '#3A9CED', marginLeft: '0.5rem'}}>{t('login_page.click_here')}</a>
          </Row>
        </CardLayout>
        <AppModal  openModal={openModal} modalType={modalType}   />
        <ToastContainer position="top-center" autoClose={1500}/>
      </AuthLayout>
    </ThemeProvider>

  );
}

function mapDispatch (dispatch) {
  return { ...bindActionCreators({ setSubscriberToken, setCurrentSubData }, dispatch) };
}

Login.propTypes = {
  setSubscriberToken: PropTypes.func.isRequired,
  setCurrentSubData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatch
)(RedirectIfAuthorized(Login));