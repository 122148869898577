import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { isEmpty, map, pluck, reject } from 'underscore'
/* Helpers */
import { Password } from 'components/helpers/form_fields'
import { AlertMessagesContext } from 'components/helpers/alert_messages'
/* Module */
import { Form } from 'informed'
/* Base */
import { renderInput } from 'components/base/forms/common_form'
import Button from 'components/base/button'
import ErrorWrapper from 'components/base/forms/common_form/error'
/* Styles */
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
import { ReactComponent as BlindLineIcon } from 'assets/blind-line.svg'
/* API */
import { createMta } from 'api/mtas'

const userField = {
  name: 'user_name',
  customInputClass: `${styles.formInput} form-control-lg w-100`,
  placeholder: 'Username'
}

const apiField = {
  name: 'key',
  customInputClass: `${styles.formInput} form-control-lg w-100`,
  placeholder: 'API Key'
}

const Credentials = ({list, title='Saved credentials'}) => {
  if(isEmpty(reject(pluck(list, 'value'), item => item === null))) return null
  return <section className={styles.credentials}>
    <p >{title}</p>
    <dl>
      { map(list, credential => <React.Fragment>
        <dt>{credential.name}</dt>
        <dd>{credential.value}</dd>
      </React.Fragment>) }
    </dl>
  </section>
}

const MtaIntegration = ({requestType, errors, isSaving, setState, adminDetails}) => {

  const formApiRef = useRef();
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const save = useCallback(async () => {
    setState('errors', {})
    const { values } = formApiRef.current.getState()

    try {
      const res = await createMta(Object.assign(values, { authentication_type: requestType }))
      addAlertMessages([{
        type: 'primary',
        text: 'Saved',
        onlyMessage: true,
      }], 'center')
      formApiRef.current.reset();
      setState('adminDetails', res.data);
    } catch (error) {
      setState('errors', error.response?.data?.errors)
    }
  },[addAlertMessages, requestType, setState])

  const credentialList = useMemo(() => {
    let list = [];
    let title = ''
    if(requestType ==='http') {
      list = [
        { name: 'Username:', value: adminDetails.user_name },
        { name: 'Password:', value: adminDetails.password }
      ]
      title = 'Saved credentials'
    }else {
      list = [
        { name: 'API Key:', value: adminDetails.key },
      ]
      title = 'Saved credential'
    }
    return {
      title,
      list
    }
  },[requestType, adminDetails])

  return (
    <>
      <h2 className={`${styles.header}`}>MTA API Authentication</h2>
      <Form
        getApi={setFormApi}
        className={`${styles.form} text-center mt-4`} 
        onSubmit={save}
      >
        { requestType === 'http' &&
          <>
            <ErrorWrapper field={userField} errors={errors} className='mb-3'>
              { renderInput(userField) }
            </ErrorWrapper>
            <ErrorWrapper field={{name: 'password'}} errors={errors} className='mb-3'>
              <Password
                defaultIcon={<BlindLineIcon />}
                revealIcon={<EyeIcon className={styles['eye-icon']}/>}
                field={{ name: "password", filled: false }}
                customAttr={{
                  className: `position-relative form-control form-control-lg w-100 ${styles.formInput}`,
                  placeholder: "Password"
                }}
              />
            </ErrorWrapper>
            <Button className={`${styles.submitBtn} mt-1 w-100`} type='submit' disabled={isSaving}>
              Save
            </Button>
          </>
        }
        { requestType === 'api_key' &&
          <>
            <ErrorWrapper field={apiField} errors={errors}>
              { renderInput(apiField) }
            </ErrorWrapper>
            <Button  className={`${styles.submitBtn} mt-1 mx-auto`} type='submit' disabled={isSaving}>
              Apply
            </Button>
          </>
        }
      </Form>
      <Credentials
        {...credentialList}
      />
    </>
  )
}

export default MtaIntegration;
