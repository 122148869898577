import resourceApi from "components/modules/resource_api";
import fetchApi from 'components/modules/fetch_api';

const { index, show, update } = resourceApi('parking/citation_tickets');

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const download = (params = {}) => {
  const { id, documentId } = params;
  return fetchApi(`dashboard/parking/citation_tickets/${id}/download_documents?document_id=${documentId}`, { method: 'PUT' });
};

const deleteDocument = (params = {}) => {
  const { id, documentId } = params;
  return fetchApi(`dashboard/parking/citation_tickets/${id}/delete_documents?document_id=${documentId}`, { method: 'DELETE' });
};

const showSetting = () => {
  return fetchApi(`dashboard/citation_settings/show_setting`, { method: 'GET' });
};

const updateSetting = (data) => {
  return fetchApi(`dashboard/citation_settings/update_setting`, { method: 'PUT', data });
};

const escalationSettings = () => {
  return fetchApi(`dashboard/parking/citation_tickets/show_escalation_settings`, { method: 'GET' });
};

const updateCitationEscalactionSettings = (data) => {
  return fetchApi(`dashboard/parking/citation_tickets/citation_escalation_settings`, { method: 'PUT', data });
};

export { filterFetcher, show, update, download, deleteDocument, showSetting, updateSetting, updateCitationEscalactionSettings, escalationSettings };
