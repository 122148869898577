import React, { useCallback } from "react";
/* Styles/Assets */
import { ReactComponent as RewardsIcon } from "assets/rewards-icon.svg";
import styles from "./index.module.sass";
/* Base */
import SlideButton from "components/base/slide_button";
import NavigationToolbar from "components/base/navigationToolbar";
import { Row, Col } from "reactstrap";
import DetailsFrom from "./details";

const CollapseContent = ({
  openModal,
  modalType,
  selectedRecord,
  activeTab,
  startFetching,
  setList,
  match,
  resource,
}) => {
  return (
    <Row
      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`}
      sm="12"
    >
      {activeTab === "view" && (
        <DetailsFrom
          startFetching={startFetching}
          setList={setList}
          match={match}
          resource={resource}
          record={selectedRecord}
          modalType={modalType}
          openModal={openModal}
        />
      )}
    </Row>
  );
};

const ToolBar = (props) => {
  const {
    activeTab,
    setActiveTab,
    isResourceFetching,
    selectedRecord,
    modalType,
    openModal,
  } = props;
  const handleToggleCollapse = useCallback(
    (tab) => {
      if (isResourceFetching) return;
      setActiveTab(activeTab === tab ? null : tab);
    },
    [activeTab, setActiveTab, isResourceFetching]
  );

  return (
    <NavigationToolbar
      backTab="/dashboard/reward_types"
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          selectedRecord={selectedRecord}
          modalType={modalType}
          openModal={openModal}
          {...props}
        />
      }
    >
      <Col className="d-flex justify-content-start align-items-center">
        <span>{`Users Enrolled (${props.total})`}</span>
      </Col>
      <Col className="d-flex justify-content-end pr-0">
        <SlideButton
          isActive={true}
          className={`${styles.button} ${
            activeTab === "view" ? styles.activeTab : ""
          }`}
          icon={
            <RewardsIcon
              className={`${activeTab === "view" ? styles.activeIcon : ""}`}
            />
          }
          onClick={() => handleToggleCollapse("view")}
        >
          <span className="mr-3 d-inline-block" style={{ marginBottom:'0.2rem', width: "fit-content", textTransform: 'capitalize' }}>
            {selectedRecord?.name}
          </span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  );
};

export default ToolBar;