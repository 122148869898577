import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { Card, Col, Container, Row, Button } from 'reactstrap';
import { isEmpty, map } from 'underscore';
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/subscriber/notifications';
import { invoke } from 'actions';
import Paggy from "react-js-pagination";
import getRangeOfPage from 'components/modules/get_range_of_page';
import { filterFetcher, mark_as_read, mark_all_as_read, read, unread } from 'api/webapp/notifications';
import Tabs from 'components/base/tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { displayUnixTimestamp } from 'components/helpers';
import styles from './index.module.sass';
import connectList from 'components/modules/connect_list';
import resourceFetcher from 'components/modules/resource_fetcher';
import withFetching from 'components/modules/with_fetching';
import withCurrentSubscriber from 'components/modules/with_current_subscriber';
import NotificationModal from '../notification_modal';
import { Input, Space, ConfigProvider } from 'antd';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'

const initState = { errors: {}, isSaving: false, selectedCheckboxes: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const Index = (props) => {
  const { setRecord } = props
  const [modalType, setModalType] = useState()
  const [formType, setFormType] = useState('all');
  const [search, setSearch] = useState('');
  const [list, setList ] = useState();
  const [buttonColor, setButtonColor] = useState('');
  const [count, setCount] = useState();
  const pageRange = getRangeOfPage(count, props.page, props.perPage).join(' - ');
  const [currentPage, setCurrentPage] = useState(props.page);
  const { Search } = Input;
  const [state, dispatch] = useReducer(reducer, initState);
  
  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const onChange = label => {
    const selectedCheckboxes = state.selectedCheckboxes;
    const findIdx = selectedCheckboxes.indexOf(label);
    if (findIdx > -1) {
      selectedCheckboxes.splice(findIdx, 1);
    } else {
      selectedCheckboxes.push(label);
    }
    setButtonColor('#3A9CED')
    if(isEmpty(state.selectedCheckboxes)){
      setButtonColor('#6c757d')
    }
  };

  const tabs = [
    {label: 'All', value: 'all'},
    {label: 'Read', value: 'read'},
    {label: 'Unread', value: 'unread'}
  ]

  const fetchList = async () => {
    try{
      const response = await filterFetcher();
      if(response.status === 200){
        setList(response.data)
        setCount(response?.headers['x-total'])
        setState("selectedCheckboxes", [])
      }
    }catch(error){
      console.log(error)
    }
  }

  const markAsRead = async () => {
    try{
      await mark_as_read({notification_ids: state.selectedCheckboxes })
      fetchList()
    }catch(error){
      console.log(error)
    }
  }

  const markAllAsRead = async () => {
    try{
      await mark_all_as_read()
      fetchList()
    }
    catch(error){
      console.log(error)
    }
  }

  const searchNotification = async (value) => {
    setSearch(value)
    const response = await filterFetcher({ query: value });
    if(response.status === 200){
      setList(response.data)
      setCount(response?.headers['x-total'])
    }
  }

  const fetchReadNotifications = async () => {
    const response = await read();
    if(response.status === 200){
      setList(response.data)
    }
  }

  
  const fetchUnreadNotifications = async () => {
    const response = await unread();
    if(response.status === 200){
      setList(response.data)
    }
  }

  useEffect(() => {
    if (formType === 'all'){ 
      fetchList() 
    }else if(formType === 'read'){
      fetchReadNotifications()
    }
    else if(formType === 'unread'){
      fetchUnreadNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formType])

  const color= item => {
    if (item.read)
      return '#e9ecef'
  }

  const weight= item => {
    if (!item.read)
      return 'bold'
  }

  const open= async (page) => {
    const response = await filterFetcher({page: page, query: search});
    if(response.status === 200){
      setCurrentPage(page)
      setList(response.data)
      setCount(response?.headers['x-total'])
    }
  }
  
  return (
      <Container fluid className='p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0' style={{fontSize: '1rem'}}>
            Notifications
          </Col>
          <Col xs={12} className='p-0'>
            <Card className={styles.cardfilter}>
              <Row className="h-100 d-flex p-4">
              <Col>
                <Row>
                  <Tabs className={styles.tab} list={tabs} defaultTab='all' onClick={setFormType} />
                </Row>
                <Row style={{marginTop: '1rem'}}>
                  <Col className='pl-0' >
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#3A9CED',
                      },
                    }}
                  >
                    <Space direction="vertical" >
                      <Search
                        placeholder="input search text"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={searchNotification}
                      />
                    </Space>
                  </ConfigProvider>
                  </Col>
                </Row>
                </Col>
                <Col className={styles.readButton1}>
                  <span>
                    <Button style={{borderRadius: '8px', backgroundColor: buttonColor}} className={styles.markButton} onClick={() => markAsRead()}>
                      Mark as read
                    </Button>
                  </span>
                  <span style={{paddingLeft: '1rem'}}>
                    <Button className={styles.markallButton} onClick={() => markAllAsRead()}>
                      Mark all as read
                    </Button>
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} className='p-0'>
            {map(list, item => ( 
              <Card style={{backgroundColor: color(item)}} className={styles.card} >
                <Row className="h-100 d-flex ">
                  <Col className={styles.checkOuter}>
                    <input
                      type="checkbox"
                      defaultChecked={state.selectedCheckboxes.includes(item.id)}
                      value={item.id}
                      onChange={() => onChange(item.id)}
                      selected={state.selectedCheckboxes.includes(item.id)}
                      className={styles.customCheckStyle}
                    />
                  </Col>
                  <Col >
                    <Row className={styles.text2}style={{fontWeight: weight(item)}}>
                      {item.title}
                    </Row>
                    <Row className={styles.text1}>
                        {item.text}
                    </Row>
                  </Col>
                  <Col className={styles.dateTime}>
                    <Row style={{marginTop: '9px' }}>
                      {displayUnixTimestamp(item.created_at)}
                    </Row>
                  </Col>
                  <Col className={styles.iconOne}>
                    <EyeIcon className={styles.eyeIcon} onClick={() => {openModal('view'); setRecord(item); onChange(item.id); markAsRead()}} />
                  </Col>
                  <Col className={styles.iconTwo}>
                    <TrashIcon className={styles.trashIcon} onClick={() =>  {openModal('delete'); setRecord(item)}} />
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
          <Col xs={12} className='p-0'>
          </Col>
        </Row>
        <NotificationModal 
          {...props}
          modalType={modalType} 
          openModal={openModal}
          setModalType={setModalType}
          setList={setList}
          setCount={setCount}
        />
        <div className="paginationWrapper mx-auto bg-white">
          { (
            <span className="general-text-3">
              {`Displaying ${pageRange} of ${count} Notifications`}
            </span>
          )}
          <Paggy
            activePage={currentPage}
            itemsCountPerPage={props.perPage}
            totalItemsCount={count}
            pageRangeDisplayed={10}
            onChange={open}
            innerClass={`${styles.pagination} m-0`}
            itemClass={styles.paginationItem}
            linkClass={`general-text-1 ${styles.paginationLink}`}
            activeClass={styles.active}
            disabledClass={styles.disable}
            itemClassNext={styles.itemNext}
            itemClassPrev={styles.itemPrev}
          />
        </div>
      </Container>
  )
  
}

const resource = 'subscriber_notifications';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD)
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.subscriber_notifications.filters,
    record: state.subscriber_notifications.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentSubscriber(Index))),
  {},
  SET_FILTERS
);