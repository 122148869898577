import React from "react";
/* Styles/Assets */
import { ReactComponent as PenToSquareSvg } from "assets/pen-to-square.svg";
import styles from "./index.module.sass";
/* Base */
import NavigationToolbar from "components/base/navigationToolbar";
import { Col } from "reactstrap";
import SlideButton from "components/base/slide_button";
import Tabs from "components/base/tabs";
/* Component */

const tabs = [
  { label: "Admin Dashboard Themes", value: "dashboard" },
  { label: "Subscriber Website Themes", value: "subscriber" },
];

const ToolBar = (props) => {
  const {
    activeTab,
    setActiveTab,
    setListType,
    history,
    listType
  } = props;

  const handleTabsChange = (tab) => {
    setActiveTab("");
    setListType(tab);
  };

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
    >
      <Col className="d-flex justify-content-start m-0 p-0">
        <Tabs className={styles.tabs} list={tabs} defaultTab="dashboard" onClick={handleTabsChange} />
      </Col>

      <Col className="d-flex justify-content-end px-0">
      <SlideButton
          isActive={true}
          className={`${styles.button} ${
            activeTab === "create" ? styles.activeTab : ""
          }`}
          icon={<PenToSquareSvg />}
          onClick={() => history.push("themes/new", { listType})}
        >
          <span className="mr-3 d-inline-block" style={{ width: "120px" }}>
            Add New Theme
          </span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  );
};

export default ToolBar;