import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from "react-router";
import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";

const { index, create } = resourceApi("event_parking_applications");
const search = searchApi("event_parking_applications");


const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params

  return index({
    page,
    perPage,
    query: {
       ...query,
       ...filters,
    },
  });
};

const show = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/event_parking_applications/${id}`, { method: 'GET', data })
};

const approveEventApplication = (params) => {
  const { id } = params;
  return fetchApi(`dashboard/event_parking_applications/${id}/approve`, { method: 'PUT' })
};


const rejectEventApplication = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/event_parking_applications/${id}/reject`, { method: 'PUT', data })
};

const putOnHold = (params) => {
  const { id } = params;
  return fetchApi(`dashboard/event_parking_applications/${id}/hold`, { method: 'PUT' })
};

const eventApplicationsSetting = (event_parking_setting) => {
  return fetchApi(
    generatePath('dashboard/event_parking_applications/setting'),
    {method: 'PUT', params: {event_parking_setting}}
  )
}

const getParkingLots = (data) => {
  const {ltd, lng} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1//parking_lots?location_radius[ltd]=${ltd}&location_radius[lng]=${lng}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const getEventApplicationsSetting = () => {
  return fetchApi(
    generatePath('dashboard/event_parking_applications/show_settings'),
    {method: 'GET'}
  )
}

export { filterFetcher, index, search, show, approveEventApplication, rejectEventApplication, putOnHold, eventApplicationsSetting, getParkingLots, create, getEventApplicationsSetting};
