import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from 'informed';
import { Col, Row } from 'reactstrap';
/* API */
import { update } from 'api/violations';
/* Base */
import { renderFields } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Helpers */
import { fieldsShow } from 'components/helpers/fields/violations/index';
import { displayUnixTimestamp } from 'components/helpers';
/* Styles/Assets */
import styles from '../index.module.sass';

const fieldProps = { lSize: 5, iSize: 7 }

const ViolationForm = (props) => {
  const { currentUserPermissions, state, record, setState, setRecord, filterActivityLogs } = props;
  const formApiRef = useRef()
  // const filters = useSelector(state => state.activity_log.filters) 
  
  const { errors, isSaving, filters, dropdowns } = state;

  const attrs = useMemo(() => {
    return { 
      currentUserPermissions, 
      ...dropdowns,
      customInputClass: styles.formInput, 
      customLabelClass: styles.formLabel 
    }
  }, [currentUserPermissions, dropdowns])
  
  const setFormApi = formApi => {
    formApiRef.current = formApi;
  };

  const values = useMemo(() => {
    const status = state.dropdowns.statuses.find((status) => status.label === record.status);
    const values = Object.assign({}, record);
    const lastLog = record.history_logs.length > 0 ? record.history_logs[record.history_logs.length - 1] : null;
    values.created_at = displayUnixTimestamp(record.created_at);
    values.parking_lot = record.parking_lot?.name || '';
    values.agency = record.agency?.name || '';
    values.admin_id = record.officer ? record.officer.id : null;
    values.updated_at = lastLog ? displayUnixTimestamp(lastLog.created_at) : '';
    values.updated_by = lastLog?.user || '';
    values.status = status?.value;
    values.violation_type = `${record.violation_type.id}`;

    return values;
  },[state, record]);
  
  const save = useCallback(async (values) => {
    const parkingViolation = {
      status: values.status,
      admin_id: values.admin_id === 'Unassigned' ? null : values.admin_id,
      violation_type: values.violation_type
    };

    try {
      setState('isSaving', true)
      const response = await update({ data: { parking_violation: parkingViolation }, id: record.id })
      setRecord(response.data)
      filterActivityLogs(filters);
      if(response.data.status === 'Accepted') setState('modalType', 'generate_pdf')
    } catch (error) {
      formApiRef.current.reset()
      setState('errors', error.response?.data?.errors)
    }finally {
      setState('isSaving', false)
    }
  },[setState, record.id, setRecord, filterActivityLogs, filters])

  const fields = useMemo(() => fieldsShow(attrs), [attrs])

  return (
    <>
    {
      values.status !== '' && values.violation_type !== '' && (
        <Form
          getApi={setFormApi}
          initialValues={values}
          onSubmit={save}
          className={`${styles.form}`}
        >
          <Row className='m-auto align-items-center'>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(0, 3), {...fieldProps, errors})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(3, 6), {...fieldProps, errors})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(6, 9), {...fieldProps, errors})}
            </Col>
            <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
              <Button
                className={`${styles.btn} ${styles['background-save']}`}
                type='submit'
                isLoading={isSaving}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }
    </>
  );
}

export default ViolationForm