import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map,times } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { search as dropdownsSearch } from 'api/dropdowns';
/* Helpers */
/* Styles/Assets */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';
import { fieldsNew } from '../../../helpers/fields/monitor_vehicles/fieldsNew';
import styles from './index.module.sass'
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg'


const initialValues = { 
  monitor_type: 'individual', 
}

const attrs = {
  customLabelClass: `${styles.formLabel}`,
  customInputClass: `${styles.formInput}`,
}

const Create = (props) => {
  const  { addVehicle , closeModal, errors, setErrors,isSaving } = props;
  const formApiRef = useRef();
  const [parkingLotsDropdown, setParkingLotsDropdown] = useState([])
  const [numVehicles, setNumVehicles] = useState(2); // Initial number of vehicles
  const [vehicleSections, setVehicleSections] = useState([1, 2]);
  const [updateVS, setUpdateVS] = useState(false) // Keep track of vehicle sections
  const [vSection, setVSection] = useState()
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const handleAddVehicle = () => {
    setNumVehicles((prevNum) => prevNum + 1);
    setVehicleSections((prevSections) => [...prevSections, prevSections.length + 1]);
  };

  const handleRemoveVehicle = (section) => {
    setNumVehicles((prevNum) => prevNum - 1);
    setVehicleSections((prevSections) => prevSections.filter((s) => s !== section));
    setVSection(section);
    setUpdateVS(true);
       
  };
  useEffect(() => {
    setNumVehicles(0);
    setUpdateVS(false);
  }, [closeModal, updateVS]);

  useEffect(() => {
    if(updateVS){
      setVehicleSections((prevSections) => prevSections.map((s) => (s > vSection ? s - 1 : s)));
    }
  }, [closeModal, updateVS, vSection]);

  const vehicleFields = (id) => {
    return [
      { 
        label: 'Vehicle Name',
        name: `vehicles[vehicle-${id}][owner_name]`,
        disabled: false,
        customInputClass: styles.formInput,
        customLabelClass: styles.formLabel
      },
      {
        label: 'Vehicle LPN',
        name: `vehicles[vehicle-${id}][plate_number]`,
        disabled: false,
        customInputClass: styles.formInput,
        customLabelClass: styles.formLabel
      },
      
    ]
  }

  const validateGroupAttrs = (attrs={}, type) => {
    const errors = {}
    const {monitor_type} = formApiRef.current.getValues()
    const { owner_name, plate_number } = attrs
    if(!owner_name && monitor_type === 'group') { errors[`${type}[owner_name]`] = ['Vehicle Name can\'t be blank']}
    if(!plate_number && monitor_type === 'group') { errors[`${type}[plate_number]`] = ['Plate Number can\'t be blank']}
    return errors
  }

  const validateVehicleAttrs = (vehicleAttrs={}, numVehicles) => {
    let errors = {}
    const {monitor_type} = formApiRef.current.getValues()
    if(monitor_type==='group'){
    times(numVehicles, idx => {
      const fieldErrors = validateGroupAttrs(vehicleAttrs[`vehicle-${idx + 1}`], `vehicles[vehicle-${idx + 1}]`)
      errors = {...errors, ...fieldErrors}
    })
    return errors}
  }


  
  const validateValues = (formState) => {
    const errors = {}
    const {monitor_type,plate_number, owner_name , expected_arrival_time, monitor_days ,parking_lot_ids } = formState;   
     if(!plate_number && monitor_type==='individual') { errors['plate_number'] = ['Plate Number Amount can\'t be blank']}
     if(!owner_name && monitor_type==='individual') { errors['owner_name'] = ['Vehicle Name Amount can\'t be blank']}
     if(!expected_arrival_time) { errors['expected_arrival_time'] = ['Expected Arrival Time can\'t be blank']}
     if(!monitor_days) { errors['monitor_days'] = ['Monitor Days can\'t be blank']}
     if(!parking_lot_ids) { errors['parking_lot_ids'] = ['Please Select atleast 1 Parking Lot']}
     return Object.assign(
      errors, 
      validateVehicleAttrs(formState?.vehicles, numVehicles),
    )
   }


   useEffect(() => {
    const get_parking_lots = async() => { await dropdownsSearch("parking_lot_list").then((response) => setParkingLotsDropdown(response.data))}
    get_parking_lots()
  }, [])

   
  const doubleFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => setErrors({})
    }
  }
  
  const save = useCallback(() => {
    const { addVehicle } = props
    const {values} = formApiRef.current.getState();
    
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){ 
      setErrors(inputErrors)
      return
    };
    
    const data = { 
      monitor_days: values.monitor_days, expected_arrival_time: values.expected_arrival_time,
    }
    data.parking_lot_ids = values.parking_lot_ids
    if(values.monitor_type === 'individual'){
     data.vehicles_attributes = [{owner_name: values.owner_name, plate_number:values.plate_number}];
    }else if(values.monitor_type === 'group'){
      const arrayOfVehicles = Object.entries(values?.vehicles).map(([vehicleId, vehicleData]) => ({
        [vehicleId]: vehicleData,
      }))
      data.vehicles_attributes = arrayOfVehicles.map((v,idx) => {return v[`vehicle-${idx+1}`]})
    }
    addVehicle(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, addVehicle]);

  const getFields = (parkingLotsDropdown) => {
    return fieldsNew({mute: false, parking_lots:parkingLotsDropdown, ...attrs})
  }

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const sortedVehicleSections = vehicleSections.sort((a, b) => a - b);
  
  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >
          {({ formState }) => {
          
            const { monitor_type} = formState.values;
            const newFields = getFields(parkingLotsDropdown)
            return (
              <Row className='mx-auto w-100'>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 pl-1`} xs={12}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                {monitor_type === 'individual' && <React.Fragment>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[1], { ...doubleFieldProps, errors} )}
                  </Col>
                </React.Fragment>}
                
                {monitor_type === 'group' && <React.Fragment>
                  {sortedVehicleSections.map((section) => (
                    <Col key={section} className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                      <div className="d-flex align-items-center my-3">
                            <span className={styles.formGroupLabel}>{`Vehicle ${section}`}</span>
                            <span className="border border-2 flex-grow-1 ml-1"></span>
                      </div>
                      {renderFields(vehicleFields(section), { ...doubleFieldProps, errors })}
                      {sortedVehicleSections.length > 2 &&
                        <span><TrashIcon className={styles.trashIcon} onClick={() =>  handleRemoveVehicle(section)} /></span>}
                    </Col>
                  ))}
                    <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                      <Button type='button' onClick={handleAddVehicle} className={`${styles.addButton} mr-4`}>
                        <FontAwesomeIcon icon={faPlus} className={`${styles.plusIcon}`} />
                        <span>  Add New Vehicle</span>
                      </Button>
                    </Col>
                </React.Fragment>
                }

                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1 mt-3`} xs={12}>
                  { renderFields(newFields[2], { ...doubleFieldProps,errors} )}
                </Col>

                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[3], { ...doubleFieldProps, errors} )}
                </Col>

                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[4], { ...doubleFieldProps, errors} )}
                </Col>
        
                <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={`${styles.button}`}  isLoading={isSaving}>
                    Create
                  </Button>
                </Col>
              </Row>
            )
          }}
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);