import React from 'react'

const TableRow = ({record, setRecord}) => {

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record.time_of_entrance}</td>
      <td>{record.vehicle_details.plate_number}</td>
      <td>{record.vehicle_details.manufacturer?.name || ''}</td>
      <td>{record.vehicle_details.model}</td>
      <td>{record.vehicle_details.color}</td>
      <td>{record.violation_count}</td>
    </tr>
  )
}

export default TableRow;
