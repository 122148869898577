import { generatePath } from "react-router";
import fetchApi from "components/modules/fetch_api";
import resourceApi from "components/modules/resource_api";

const { index } = resourceApi("permit_applications");

const filterFetcher = (params = {}) => {
  const { page = 1, perPage = 20, query } = params;

  return index({
    page,
    perPage,
    query
  });
};

const showSetting = () => {
  return fetchApi(
    generatePath('dashboard/permit_settings/show_setting'), {}
  )
}

const updateSetting = (permit_setting) => {
  return fetchApi(
    generatePath('dashboard/permit_settings/update_setting'),
    {method: 'PUT', params: {permit_setting}}
  )
}

const rejectApplication = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permit_applications/${id}/reject`, { method: 'PUT', data })
};

const approveApplication = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permit_applications/${id}/approve`, { method: 'PUT', data })
};

const putUnderReview = (params) => {
  const { id } = params;
  return fetchApi(`dashboard/permit_applications/${id}/under_review`, { method: 'PUT' })
};

const show = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permit_applications/${id}`, { method: 'GET', data })
};

export {approveApplication, filterFetcher, putUnderReview, rejectApplication, show, showSetting, updateSetting };
