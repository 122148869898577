import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const show = (params) => {
  const { id, ...data } = params
  return fetchApi(`v1/notifications/${id}`, { method: 'GET', data })
};

const  index = (params = {}) => {
  const { id, page, perPage, query, nestedParams = {} } = params;
  return fetchApi(generatePath(`v1/notifications`, nestedParams), { method: 'GET', params: { id, page, per_page: perPage, ...query } })
};

const  mark_as_read = (data) => {
  return fetchApi('v1/notifications/read', { method: 'PUT', data })
};

const  mark_all_as_read = () => {
  return fetchApi('v1/notifications/mark_all', { method: 'PUT' })
};

const  read = () => {
  return fetchApi('v1/notifications/read', { method: 'GET' })
};

const  unread = () => {
  return fetchApi('v1/notifications/unread', { method: 'GET' })
};

const  show_session = (params) => {
  const { id, ...data } = params
  return fetchApi(`v1/parking_sessions/${id}`, { method: 'GET', data })
};

const  delete_notification = (params) => {
  const { id } = params
  return fetchApi(`v1/notifications/${id}`, { method: 'DELETE' })
};

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params
  let title = {}
  if(query){
    Object.assign(title, {title: query})
  }
  return index({
    page,
    perPage,
    query: {
       ...filters,
       ...title,
    },
  });
};

export { index, mark_as_read, mark_all_as_read, read, unread, show, show_session, delete_notification, filterFetcher };
