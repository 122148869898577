import React, { useCallback } from 'react'
import TableRow from './tableRow';
import { filterFetcher } from 'api/account_types';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { list, setRecord, filters, isResourceFetching, openModal, modalType, activeTab } = props
  const renderRecords = useCallback(() => {
    return list?.map((record) => 
      <TableRow 
        key={record.id} 
        record={record} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    );
  },[list, modalType, setRecord, openModal])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <React.Fragment>
          <th attr="name" style={{width: '35%', minWidth: '210px' }} >Account Type</th>
          <th attr="created_at" style={{ width: '35%', minWidth: '210px' }} >Created On</th>
          <th attr="subscriber_count" style={{ width: '27%', minWidth: '210px' }} >Subscribers</th>
          <th style={{ width: '30%', minWidth: '275px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="Account Types"
    />
  )
}

export default TableData;