import React, { useCallback, useRef } from "react";
import { displayDate } from "components/helpers";
import { filterFields } from "components/helpers/fields/user_rewards";
import { renderFields } from "components/base/forms/common_form";
import Button from "components/base/button";
import { filterFetcher } from "api/user_rewards";
import { Form } from "informed";
import { Col, Row } from "reactstrap";
import styles from "./index.module.sass";
import { list as selectList } from "selectors/list";

const FilterForm = (props) => {
  const { filters, setFilters, setList, userId } = props;

  const attrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
  };

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const filterRewards = useCallback(
    async (filters = {}) => {
      const { enrollment_date } = filters;
      if (enrollment_date && !enrollment_date?.to) {
        delete enrollment_date.to;
      }
      if (enrollment_date && !enrollment_date?.from) {
        delete enrollment_date.from;
      }

      const response = await filterFetcher({
        filters: { ...filters, user_id: userId },
      });
      try {
        if (response?.status === 200) {
          setList(selectList(response));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setList, userId]
  );

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const updatedFilters = {
      ...filterValues,
      ...filters,
      enrollment_date: {
        from: displayDate(filterValues.enrollment_date?.from, "yyyy-mm-dd"),
        to: displayDate(filterValues.enrollment_date?.to, "yyyy-mm-dd"),
      },
    };
    setFilters(updatedFilters);
    filterRewards(updatedFilters);
  };

  const resetFilters = () => {
    formApiRef.current.setValues({
      points_earned: { min: "", max: "" },
      points_to_dollar: { min: "", max: "" },
      enrollment_date: { from: "", to: "" },
    });
    setFilters({});
    filterRewards({});
  };

  const getFields = (fromDate, toDate) =>
    filterFields({ ...attrs, fromDate, toDate });

  return (
    <Form getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({ formState }) => {
        const dateFrom = formState.values.enrollment_date?.from || "";
        const dateTo = formState.values.enrollment_date?.to || "";

        const fields = getFields(dateFrom, dateTo);
        return (
          <Row className="m-auto">
            <Col className={`m-0 d-flex ${styles.fieldset}`} xs={2}>
              {renderFields(fields.slice(0, 1), { lSize: 6, iSize: 6 })}
              <div
                className={`${styles["fieldset-dateStroke"]} position-relative`}
              />
            </Col>
            <Col className={`m-0 ${styles.fieldset2}`} xs={2}>
              {renderFields(fields.slice(1, 2), { lSize: 0, iSize: 6 })}
            </Col>
            <Col className={`m-0 d-flex ${styles.fieldset}`} xs={2}>
              {renderFields(fields.slice(2, 3), { lSize: 6, iSize: 6 })}
              <div
                className={`${styles["fieldset-dateStroke"]} position-relative`}
              />
            </Col>
            <Col className={`m-0 ${styles.fieldset2}`} xs={2}>
              {renderFields(fields.slice(3, 4), { lSize: 0, iSize: 6 })}
            </Col>

            <Col className={`m-0 d-flex ${styles.fieldset}`} xs={2}>
              {renderFields(fields.slice(4, 5), { lSize: 6, iSize: 6 })}
              <div
                className={`${styles["fieldset-dateStroke"]} position-relative`}
              />
            </Col>
            <Col className={`m-0 ${styles.fieldset2}`} xs={2}>
              {renderFields(fields.slice(5, 6), { lSize: 0, iSize: 6 })}
            </Col>
            <Col className="d-flex justify-content-center pt-3 m-0" xs={12}>
              <Button
                className={`${styles.btn} ${styles["background-primary"]} mr-5`}
                type="submit"
              >
                Filter
              </Button>
              <Button
                type="button"
                onClick={resetFilters}
                className={`${styles.btn} ${styles["btn-secondary"]}`}
              >
                Reset
              </Button>
            </Col>
          </Row>
        );
      }}
    </Form>
  );
};

export default FilterForm;
