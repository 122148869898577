import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Boot = (props) => {

  const { bootVehicle, closeModal, selectedRecord } = props;
  
  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Boot Confirmation</p>
      <div className={styles.content}>
        <Col className='mt-3'>
          Please confirm that you have placed a boot device on the following vehicle: 
        </Col>
        <Col className={`${styles.boldText} mb-4 mt-1`}>
          LPN: {selectedRecord?.plate_number}
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={()=>  bootVehicle()} className={styles.submitButton}>
          Confirm
        </Button>
      </Col>
    </Col>
  )
}

export default Boot;