import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ROLES } from "config/permissions";
/* Actions */
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/violations';
/* API */
import { filterFetcher, showSetting } from 'api/violations';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from "components/helpers/access-control";
/* Components */
import TableData from "./tableData";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import ViolationModal from '../violation_modal';
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Index = props => {
  const [modalType, setModalType] = useState(null)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [activeTab, setActiveTab] = useState(null);
  const [autoAccept, setAutoAccept] = useState(null);

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  const { isResourceFetching, currentUser } = props;

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await showSetting()
        setAutoAccept(response?.data?.violation_auto_accept)
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  },[setAutoAccept])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            {...props}
            resource={resource}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFetching={isResourceFetching}
            autoAccept={autoAccept}
            setAutoAccept={setAutoAccept}
            openModal={openModal}
          />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              selectedRecordId={selectedRecordId}
              setSelectedRecordId={setSelectedRecordId}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
            />
          </Col>
        </Row>
        <ViolationModal
          {...props}
          modalType={modalType} 
          openModal={openModal}
          setActiveTab={setActiveTab}
          setAutoAccept={setAutoAccept}
          autoAccept={autoAccept}
          selectedRecordId={selectedRecordId}
        />
      </Container>
    </AccessControl>
  )
  
};

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'violation';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.violation.filters,
    record: state.violation.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);


