import React, { useEffect, useRef, useState } from "react";
import { Form } from "informed";
import { Col, Container, Row, Spinner } from "reactstrap";
import { isEmpty, map } from "underscore";
/* Base */
import { renderField } from "components/base/forms/common_form";
import Button from "components/base/button";
/* Helpers */
import { topUpFields } from "components/helpers/fields/app/my_wallet/index";
/* Styles/Assets */
import styles from "./index.module.sass";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import GooglePayButton from "@google-pay/button-react";
import GooglePayDetails from "components/modules/google_pay.js";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import ErrorWrapper from "components/base/forms/common_form/error";
import "./card.css";

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};

const Edit = (props) => {
  const { closeModal, errors, setErrors, isSaving, topUp } = props;
  const [amount, setAmount] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [cvc, setCvc] = useState("");
  const [focus, setFocus] = useState("");
  const [expiryM, setExpiryM] = useState("");
  const [expiryY, setExpiryY] = useState("");
  const creditCardNum = useRef("");
  const holder_name = useRef("");
  const cvv = useRef("");
  const [showGooglePay, setGooglePayShow] = useState(false);
  const [showCreditCardFields, setShowCreditCardFields] = useState(false);
  const [months, setMonths] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]);
  const { t } = useTranslation();

  const years = Array.from(
    { length: 20 },
    (_, index) => new Date().getFullYear() + index
  );

  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInput: styles.formDropdownInput,
    customExpiryDropdownInputClass: styles.expiryDropdown,
    customExpiryLabel: styles.expiryDropdownLabel,
    customExpiryLabel2: styles.cardLabels2,
    months: months,
    years: years,
    t: t
  };

  const handleExpiryYearChange = (e) => {
    setExpiryY(e);
    if (e > new Date().getFullYear()) {
      setMonths([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]);
    } else {
      setMonths(
        months.filter((m) => {
          return m > new Date().getMonth() + 1;
        })
      );
    }
  };

  const handleTagClick = (value) => {
    setAmount((prevAmount) => (parseInt(prevAmount) || 0) + value);
  };

  useEffect(() => {
    formApiRef.current.setValue("amount", amount);
  }, [amount]);

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const save = async (paymentToken) => {
    setErrors({});
    const { values } = formApiRef.current.getState();
    const gatewayParams = {};
    const inputErrors = validateValues(values);

    if (!isEmpty(inputErrors)) {
      setErrors(Object.assign(inputErrors));
      return;
    }
    gatewayParams.amount = values.amount;
    if (showCreditCardFields) {
      gatewayParams.credit_card_attributes = {
        number: creditCardNum?.current?.value,
        cvv: cvv?.current?.value,
        holder_name: holder_name?.current?.value,
        expiration_month: values?.expiration_month,
        expiration_year: values?.expiration_year,
      };
    } else {
      gatewayParams.digital_wallet_attributes = {
        encryptionhandler: "EC_GOOGLE_PAY",
        devicedata: paymentToken?.paymentMethodData?.tokenizationData?.token,
      };
    }
    topUp(gatewayParams);
  };

  useEffect(() => {
    setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateValues = (formState) => {
    const errors = {};
    const { amount, expiration_month, expiration_year } =
      formState;
    if (!amount) {
      errors["amount"] = [t("subscriber.profile.top_up_modal.amount.required"),];
    }
    if (showCreditCardFields && !creditCardNum?.current?.value) {
      errors["credit_card_number"] = [
        t(
          "events.city_events.get_pass_form.payment.credit_card.card_number.validation"
        ),
      ];
    }
    if (showCreditCardFields && !holder_name?.current?.value) {
      errors["holder_name"] = [
        t(
          "events.city_events.get_pass_form.payment.credit_card.holder_name.validation"
        ),
      ];
    }
    if (showCreditCardFields && !cvv?.current?.value) {
      errors["cvc"] = [
        t(
          "events.city_events.get_pass_form.payment.credit_card.cvc.validation"
        ),
      ];
    }
    if (showCreditCardFields && !expiration_month) {
      errors["expiration_month"] = [
        t(
          "events.city_events.get_pass_form.payment.credit_card.expiration_month.validation"
        ),
      ];
    }
    if (showCreditCardFields && !expiration_year) {
      errors["expiration_year"] = [
        t(
          "events.city_events.get_pass_form.payment.credit_card.expiration_year.validation"
        ),
      ];
    }
    return errors;
  };

  const getFields = () => {
    return topUpFields({ mute: false, ...attrs });
  };

  const singleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => handleAmountChange(e),
    },
  };

  const cardMFieldProps = {
    iSize: 12,
    lSize: 12,
    events: {
      onChange: (e) => handleCardFieldsChange(e, "expiration_month"),
    },
  };
  const cardYFieldProps = {
    iSize: 12,
    lSize: 12,
    events: {
      onChange: (e) => handleCardFieldsChange(e, "expiration_year"),
    },
  };

  const showPaymentOption = () => {
    if (formApiRef.current?.getValue("payment_methods") === 0) {
      setShowCreditCardFields(true);
      setGooglePayShow(false);
    } else if (formApiRef.current?.getValue("payment_methods") === 1) {
      setGooglePayShow(true);
      setShowCreditCardFields(false);
    }
  };

  const handleAmountChange = (e) => {
    if (e?.target?.name === "amount") {
      setAmount(e.target.value);
    } else {
      showPaymentOption();
    }
  };

  const handleCardFieldsChange = (value, field) => {
    const updatedErrors = Object.keys(errors)
      .filter((objKey) => objKey !== field)
      .reduce((newObj, key) => {
        newObj[key] = errors[key];
        return newObj;
      }, {});
    setErrors(updatedErrors);
    if (field === "credit_card_number") {
      setCardNumber(value);
    } else if (field === "holder_name") {
      setName(value);
    } else if (field === "expiration_month") {
      setExpiryM(value);
      setFocus("expiry");
    } else if (field === "expiration_year") {
      handleExpiryYearChange(value);
    } else if (field === "cvc") {
      setCvc(value);
    }
  };

  return (
    <Container className="p-0 pt-2">
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={styles.form} onSubmit={save}>
          {({ formState }) => {
            const { amount } = formState.values;
            const newFields = getFields();
            return (
              <Row className="mx-auto w-100">
                {map(newFields[0], (field, idx) => {
                  return (
                    <Col
                      key={idx}
                      className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-single"]}`}
                      xs={12}
                    >
                      {renderField(field, { ...singleFieldProps, errors })}
                    </Col>
                  );
                })}
                <Col className={`${styles.tagContainer} pr-4`} xs={12}>
                  {[10, 50, 100].map((value) => (
                    <Tag
                      key={value}
                      onClick={() => handleTagClick(value)}
                      className={styles.tag}
                    >
                      + {value}$
                    </Tag>
                  ))}
                </Col>
                {map(newFields[1], (field, idx) => {
                  return (
                    <Col
                      key={idx}
                      className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-single"]}`}
                      xs={12}
                    >
                      {renderField(field, { ...singleFieldProps, errors })}
                    </Col>
                  );
                })}

                <Col>
                  <Row className="flex-column w-100 text-center p-1">
                    {showCreditCardFields && !showGooglePay && (
                      <Col>
                        <div className={styles.cardContainer}>
                          <div className={styles.cardPreview}>
                            <Cards
                              number={cardNumber}
                              name={name}
                              expiry={`${expiryM}${expiryY}`}
                              cvc={cvc}
                              focused={focus}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={styles.cardInputs2}>
                            <label className={styles.cardLabels}>
                              {t(
                                "events.city_events.get_pass_form.payment.credit_card.card_number.label"
                              )}
                            </label>
                            <ErrorWrapper
                              className={styles.ccNumField}
                              errors={errors}
                              field={{ name: "credit_card_number" }}
                            >
                              <input
                                type="tel"
                                id="credit_card_number"
                                name="credit_card_number"
                                placeholder={t(
                                  "events.city_events.get_pass_form.payment.credit_card.card_number.label"
                                )}
                                maxLength={16}
                                value={cardNumber}
                                onChange={(e) =>
                                  handleCardFieldsChange(
                                    e.target.value,
                                    "credit_card_number"
                                  )
                                }
                                onFocus={() => setFocus("number")}
                                className={styles.cardInput}
                                ref={creditCardNum}
                              />
                            </ErrorWrapper>
                            <label className={styles.cardLabels}>
                              {t(
                                "events.city_events.get_pass_form.payment.credit_card.holder_name.label"
                              )}
                            </label>
                            <ErrorWrapper
                              className={styles.ccNumField}
                              errors={errors}
                              field={{ name: "holder_name" }}
                            >
                              <input
                                type="text"
                                id="holder_name"
                                name="holder_name"
                                placeholder={t(
                                  "events.city_events.get_pass_form.payment.credit_card.holder_name.label"
                                )}
                                value={name}
                                onChange={(e) =>
                                  handleCardFieldsChange(
                                    e.target.value,
                                    "holder_name"
                                  )
                                }
                                onFocus={() => setFocus("name")}
                                className={styles.cardInput}
                                ref={holder_name}
                              />
                            </ErrorWrapper>
                            <Row className={styles.expirySection}>
                              {map(newFields[2], (field, idx) => {
                                return (
                                  idx === 0 && (
                                    <Col xs={6}>
                                      {renderField(field, {
                                        ...cardYFieldProps,
                                        errors,
                                      })}
                                    </Col>
                                  )
                                );
                              })}
                              {map(newFields[2], (field, idx) => {
                                return (
                                  idx === 1 && (
                                    <Col xs={6}>
                                      {renderField(field, {
                                        ...cardMFieldProps,
                                        errors,
                                      })}
                                    </Col>
                                  )
                                );
                              })}
                            </Row>
                            <label htmlFor="cvc" className={styles.cvcLabel}>
                              {t(
                                "events.city_events.get_pass_form.payment.credit_card.cvc.label"
                              )}
                            </label>
                            <ErrorWrapper
                              className={styles.ccNumField}
                              errors={errors}
                              field={{ name: "cvc" }}
                            >
                              <input
                                type="tel"
                                id="cvc"
                                name="cvc"
                                placeholder={t(
                                  "events.city_events.get_pass_form.payment.credit_card.cvc.label"
                                )}
                                value={cvc}
                                onChange={(e) =>
                                  handleCardFieldsChange(e.target.value, "cvc")
                                }
                                onFocus={() => setFocus("cvc")}
                                className={styles.cardInput}
                                maxLength={3}
                                ref={cvv}
                              />
                            </ErrorWrapper>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col
                  className="d-flex justify-content-center mb-3 mt-3 pt-2 pr-4"
                  xs={12}
                >
                  <div centered>
                    {showGooglePay && (
                      <GooglePayButton
                        environment="TEST"
                        paymentRequest={GooglePayDetails(amount)}
                        onLoadPaymentData={(paymentRequest) => {
                          save(paymentRequest);
                        }}
                        buttonType="pay"
                        className={styles.gpayBtn}
                      />
                    )}
                  </div>
                  {!showGooglePay && (
                    <Button
                      onClick={closeModal}
                      type="button"
                      className={`${styles.button} ${styles["button-bg-secondary"]} mr-4`}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  )}
                  {showCreditCardFields && (
                    <Button
                      type="submit"
                      className={styles.button}
                    >
                      {isSaving ? btnSpinner({ className: 'spinner-border' }) : t("buttons.pay")}
                    </Button>
                  )}
                </Col>
              </Row>
            );
          }}
        </Form>
      </fieldset>
    </Container>
  );
};

export default Edit;