
import { FieldType } from "components/helpers/form_fields";
import reportsField from "./reports";
import searchFields from "./search";

const filterFields = () => ([
  { name: 'permit_number', label: 'Permit Number'},
  { name: 'permit_type', label: 'Permit Type'},
  { name: 'issued_date', label: 'Issued Date'},
  { name: 'vehicle_plate_number', label: 'Vehicle Plate Number'},
  { name: 'vehicle_owner_name', label: 'Vehicle Owner name'},
  { name: 'vehicle_model', label: 'Vehicle Model'},
  { name: 'permit_expired', label: 'Permit Expired'},
  { name: 'permit_expiry_date', label: 'Expiry Date'},
  { name: 'action', label: 'Action'},
]);

const revokeField = (customLabelClass, customInputClass='') => ({
    name: 'revoked_reason',
    type: FieldType.TEXT_AREA,
    rows: 5,
    placeholder: 'Enter reason *',
    customLabelClass,
    customInputClass,
    mandatory: true,
});



export { searchFields, filterFields, reportsField, revokeField }