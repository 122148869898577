import React from "react";
import { map } from "underscore";
/* Styles and Assets */
import styles from "./index.module.sass";
import Action from "./action";
import { ReactComponent as CloneIcon } from 'assets/clone-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';

const TableRow = ({record, selectedRecord, openModal, modalType, setSelectedRecord}) => {

  const actions = [
    { 
      icon: <EditIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      onClick: () => {openModal('edit')},
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecord?.predefined_role,
      disableHighlight: record?.predefined_role ,
      width: '50px'
    },
    { 
      icon: <CloneIcon className='mx-3'/>,
      highlightedClass: `${styles['background-ternary']} mx-3 text-white`,
      onClick: () => {openModal('clone')},
      text: 'Clone',
      isActive: modalType === 'clone' && selectedRecord === record.id,
      width: '60px'
    },
    { 
      icon: <TrashIcon className='mx-3'/>,
      highlightedClass: `${styles['background-danger']} mx-3 text-white`,
      onClick: () => {openModal('delete')},
      text: 'Delete',
      isActive: modalType === 'delete' && selectedRecord === record.id,
      disableHighlight: !record?.deletable,
      width: '50px'
    },
  ];

  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td>{record?.id}</td>
      <td>{record?.name}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;