import React, { useState, useCallback } from 'react';
import { ROLES } from "config/permissions";
import { SET_LIST, SET_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/parking_regulations';
import { index as filterFetcher } from 'api/parking_regulations';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import { bindActionCreators } from 'redux';
import { Col, Container } from "reactstrap";
import { connect } from 'react-redux';
import styles from './index.module.sass'
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { invoke } from 'actions';
import ParkingRegulationModal from '../parking_regulation_modal';
import ToolBar from "./toolbar";
import Create from '../create';
import Edit from '../edit';

const Index = props => {
  const { currentUser, list } = props;

  const [modalType, setModalType] = useState(null)
  const [createActive, setCreateActive] = useState(false)
  const [editActive, setEditActive] = useState(false)

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])
  
  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Col className='m-auto'>
        <ToolBar
          {...props}
          openModal={openModal}
          setEditActive={setEditActive}
        />
          {(isEmpty(list) && !createActive && !editActive) && <section className={`${styles.subSection} mt-3`} style={{ minHeight: 140 }}>
            <p className={styles.documentHeader}>You haven’t created parking regulations yet. Select an option below to begin</p>
            <Col className='d-flex justify-content-center'>
              <Button 
                className={`${styles.uploadButton} mb-5`}
                onClick={() => openModal("upload")}
              >
                Upload Document
              </Button>
              <Button className={styles.createButton} onClick={() => setCreateActive(true)} >
                Create New
              </Button>
            </Col>
          </section>
          }{
          ( !isEmpty(list) && !createActive && !editActive ) && <div>
            <div className={styles.document}>
              <div dangerouslySetInnerHTML={{ __html: list[0]?.document}}></div>
            </div>
          </div>
          }
        </Col>
        {createActive && !editActive && <Create
          {...props}
          modalType={modalType} 
          openModal={openModal}
          setCreateActive={setCreateActive}
          createActive={createActive}
        />}
        {editActive && <Edit
          {...props}
          modalType={modalType} 
          openModal={openModal}
          setCreateActive={setCreateActive}
          createActive={createActive}
        />}
        <ParkingRegulationModal 
          {...props}
          modalType={modalType} 
          openModal={openModal} 
        />
      </Container>
    </AccessControl>
  )
};

const resource = 'parking_regulations';

const  mapState = (state) => {
  return { 
    filters: state.parking_regulations.filters,
    record: state.parking_regulations.records.record
  }
}

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch
  );
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);


