import React, { useEffect} from 'react'
import { isEmpty } from 'underscore';
import {Table } from 'reactstrap';
import Button from 'components/base/button';
import { displayDate } from 'components/helpers';
import Loader from 'components/helpers/loader';
import { show } from 'api/webapp/citations.js';
import styles from './index.module.sass';
import { ReactComponent as MarkerIcon } from 'assets/marker.svg'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

const Details = ({citation, openInnerModal, openDisputeModal, citationTicketStatus}) => {

  const QrCode = ({values}) => {
    return (
      <div className='px-3 w-100'>
        <img src={values} alt="" className={`${styles.imgThumb} ${styles.image}`} />
      </div>
    )
  }

  const handleDisputeShow = () =>{
    if (citationTicketStatus !== 'disputed'){
      openDisputeModal();
    }
  }

  const handlePaymentShow = () =>{
    if (citationTicketStatus !== 'settled'){
      openInnerModal();
    }
  }
  
  if(isEmpty(citation)) return <span>Not Found</span>

  return (
    <div className="d-flex flex-column align-items-center w-100 p-3">
      <div className={`${styles.textTop} d-flex justify-content-center align-items-center mb-4`}>
        Citation Ticket
      </div>
      <Button className={`${styles.btn} ${styles['btn-id-number']} d-flex justify-content-center align-items-center mb-2`}>
        <span className='mr-2' style={{opacity: 0.75}}>
          Citation Ticket ID:
        </span>
        <span>
          {citation.id}
        </span>
      </Button>
      <Button className={`${styles.btn} ${styles['btn-plate-number']} d-flex justify-content-center align-items-center mb-4`}>
        <span className='mr-2' style={{opacity: 0.75}}>
          Vehicle License Plate Number:
        </span>
        <span>
          {citation.plate_number}
        </span>
      </Button>
      <p className={`${styles['heading-2']} my-3`}>Citation Details</p>
      <Table bordered className={styles.table}>
        <thead>
          <tr className='text-center'>
            <th>
              <span className='border'>Citation Date</span>
            </th>
            <th>
              <span className='border'>Parking Lot</span>
            </th>
            <th>
              <span className='border'>Slot Number</span>
            </th>
            <th>
              <span className='border'>Status</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='text-primary text-center'>
            <td>
              {displayDate(citation.citation_details.citation_date, 'MM/DD/YYYY')}
            </td>
            <td>
              {citation.violation_details.parking_lot}
            </td>
            <td>
            {citation.citation_details.slot_number}
            </td>
            <td >
              <span className={styles.status}>{citation.citation_details.status}</span>
            </td>
          </tr>
        </tbody>
      </Table>
      <span className='w-100'><hr /></span>
      <p className={`${styles['heading-2']} my-3`}>Violation Details</p>
      <Table bordered className={styles.table}>
        <thead>
          <tr className='text-center'>
            <th>
              <span>Violation Date</span>
            </th>
            <th>
              <span>Violation Time</span>
            </th>
            <th>
              <span>Violation Type</span>
            </th>
            <th>
              <span>Parking Lot</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='text-primary text-center'>
            <td>
              {displayDate(citation.violation_details.violation_date, 'MM/DD/YYYY')}
            </td>
            <td>
              {citation.violation_details.violation_time}
            </td>
            <td>
              {citation.violation_details.violation_type}
            </td>
            <td>
            {citation.violation_details.parking_lot}
            </td>
          </tr>
        </tbody>
      </Table>
      <span className='w-100'><hr /></span>
      <p className='d-flex align-items-center'>
        <span className='mr-2'>Parking Lot Address:</span>
        <span className='mr-2  text-primary'>{citation.parking_lot_full_address}</span>
        {citation.parking_lot_full_address && <MarkerIcon style={{width: 30, height: 'auto'}} />}
      </p>
      <span className='w-100'><hr /></span>
      <Button className={`${styles.btn} ${styles['btn-fine']} d-flex justify-content-center align-items-center my-4`}>
        <span className='mr-1' style={{opacity: 0.75}}>
          FINE:
        </span>
        <span>
          {`$${citation.fine}`}
        </span>
      </Button>
      <p className={styles.text}>
        <span className='mr-1'>You may pay the fine amount to settle the citation.</span> 
        <span className='mr-1'>To pay online, click on the <em>Pay Now</em> button below, or scan the <em>QR code</em>.</span>
        <span>To pay in cash or via cheque, head to the parking office associated with <em>{citation.violation_details.parking_lot}</em>.</span>
      </p>
      <p className='my-3 d-flex flex-column'>
        <QrCode values={citation.qr_code_for_payment} />
          <span className='text-primary my-3' style={{fontWeight: 500, fontSize: 17, textAlign: 'center'}}>Scan to pay</span>
        <Button className={`${styles.btn}  ${styles['btn-pay']}`} onClick={handlePaymentShow}>
          PAY NOW
        </Button>
      </p>
      <span className='w-100'><hr /></span>
      <p className={`${styles.text} mt-5`}>
        <span className='mr-1'>If you would like to dispute the violation, click on the <em>Dispute</em> button below.</span> 
        <span >Please note that if your dispute is rejected, this citation will be sent to court after <em>25 days</em>.</span>
      </p>
      <Button className={`${styles.btn} ${styles['btn-dispute']} my-4`} onClick={handleDisputeShow}>
        DISPUTE
      </Button>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  )
}

const Show = (props) => {
  const {fetching, record, openInnerModal, citation, setCitation, openDisputeModal, citationTicketStatus} = props

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await show({id: record.id})
        setCitation(response.data)
      } catch (_error) {
      } finally {
      }
    }
    if(isEmpty(citation)) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[citation])
  return (
    <div className={`${styles['section-citation-details']} mt-4 mb-5 w-100`}>
      <section className={`${styles.section} h-100 d-flex align-items-center justify-content-center`}>
        { 
          fetching 
            ? <Loader />
            : <Details
                citation={citation}
                setCitation={setCitation}
                openInnerModal={openInnerModal}
                openDisputeModal={openDisputeModal}
                citationTicketStatus={citationTicketStatus}
              />
        }
      </section>
    </div>
  )
}
Details.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object
};


export default Show