import { FieldType } from 'components/helpers/form_fields';

const fieldsNew = (attrs, disabled) => {
  const { customLabelClass='', customInputClass='' } = attrs
  return ([
    [
      {
        name: 'code',
        label: 'Code Name',
        placeholder:"Permit Type Code Name",
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled,
      }
    ],
    [
      {
        name: 'color',
        label: 'Color',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder:"Choose a color",
        mandatory: true,
        options: [
          { value: '#FFFFFF', label: 'White' },
          { value: '#000000', label: 'Black' },
          { value: '#28a745', label: 'Green' },
          { value: '#ff0000', label: 'Red' },
          { value: '#007bff', label: 'Blue' },
          { value: '#fd7e14', label: 'Orange' },
          { value: '#ffc107', label: 'Yellow' },
        ],
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: 'description',
        label:"Description",
        placeholder: 'Description',
        type: FieldType.TEXT_AREA,
        rows: 3,
        customInputClass,
        customLabelClass,
        disabled,
        mandatory: true,

      },
    ],
  ]);
} 

const fieldsAdd = (attrs, disabled) => {
  const { customLabelClass='', customInputClass='', permit_type_code_list=[], permit_type_list=[]} = attrs
  return ([
    [
      {
        name: 'id',
        label: 'Permit Type Code',
        placeholder:"Choose a Permit Type Code",
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: permit_type_code_list,
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: true,
      }
    ],
    [
      {
        name: 'permit_type_ids',
        label: 'Permit Type',
        placeholder:"Choose permit types",
        type: FieldType.MULTISELECT_FIELD,
        options: permit_type_list,
        mandatory: true,
        customInputClass,
        customLabelClass,
        disabled,
        showToolTip: false, 
      },
    ]
  ]);
} 


export { fieldsNew, fieldsAdd };
