import React, { useState } from "react";
import { ReactComponent as FilterIcon } from "assets/filter_icon.svg";
import styles from "./index.module.sass";
import Button from "components/base/button";
import NavigationToolbar from "components/base/navigationToolbar";
import { Col, Row } from "reactstrap";
import Tabs from "components/base/tabs";
import CouponsFilterForm from "./coupons/index/filterForm";
import RewardsCouponForm from "./rewards/index/filterForm";

const tabs = [
  { value: "parking_sessions", label: "Parking Sessions" },
  { value: "charging_sessions", label: "Charging Sessions" },
  { value: "permits", label: "Permits" },
  { value: "transactions", label: "Transactions" },
  { value: "waiting_lists", label: "Waiting Lists" },
  { value: "coupons", label: "Coupons" },
  { value: "rewards", label: "Rewards" },
];

const CollapseContent = ({
  activeTab,
  filters,
  setFilters,
  startFetching,
  setList,
  match,
  resource,
  userId,
  listType,
}) => {
  return (
    <Row
      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`}
      sm="12"
    >
      {activeTab === "filters" && listType === "coupons" && (
        <CouponsFilterForm
          startFetching={startFetching}
          setList={setList}
          match={match}
          resource={resource}
          filters={filters}
          setFilters={setFilters}
          userId={userId}
        />
      )}
      {activeTab === "filters" && listType === "rewards" && (
        <RewardsCouponForm
          startFetching={startFetching}
          setList={setList}
          match={match}
          resource={resource}
          filters={filters}
          setFilters={setFilters}
          userId={userId}
        />
      )}
    </Row>
  );
};

const ToolBar = (props) => {
  const {
    isResourceFetching,
    history,
    userId,
    pageType,
    activeTab,
    setActiveTab,
  } = props;

  const [listType, setListType] = useState(pageType ? pageType : "coupons");

  const handleToggleCollapse = (tab) => {
    if (isResourceFetching) return;
    setActiveTab((activeTab) => (activeTab === tab ? null : tab));
  };

  const handleTabsChange = (tab) => {
    setListType(tab);
    history.push(`/dashboard/subscribers/${userId}/${tab}`, {
      listType: listType,
    });
  };

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent activeTab={activeTab} listType={pageType} {...props} />
      }
      backTab="/dashboard/subscribers"
      className="d-flex justify-content-space-between"
    >
      <Col
        className="d-flex justify-content-start pl-0"
        style={{ minWidth: "77%" }}
      >
        <Tabs
          list={tabs}
          defaultTab={pageType ? pageType : listType}
          onClick={handleTabsChange}
        />
      </Col>

      <Col className="d-flex justify-content-end px-0">
        <Button
          onClick={() => handleToggleCollapse("filters")}
          className={`${styles.btnFilter} ${
            activeTab === "filters" ? styles.activeTab : ""
          }`}
          icon={
            <FilterIcon
              className={activeTab === "filters" ? styles.activeIcon : ""}
            />
          }
          size="md"
        />
      </Col>
    </NavigationToolbar>
  );
};

export default ToolBar;