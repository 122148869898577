import React, { useCallback, useEffect, useState, useRef } from 'react'
import {  Col, Row } from 'reactstrap';
import styles from './index.module.sass';
import { ReactComponent as MessageIcon } from 'assets/envelope-add.svg';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import Tabs from 'components/base/tabs';
import FilterForm from './filterForm';
import { search as dropdownsSearch } from "api/dropdowns";
import { filterFetcher } from 'api/broadcast_messagse';
import ScheduledFilterForm from './filterFormScheduled';

const tabs = [
  { label: 'Broadcasted Messages', value: 'sent' },
  { label: 'Scheduled Messages', value: 'scheduled' }
]

const CollapseContent = ({startFetching, setList, match, resource, setFilters, filters, list, listType, setCount, setFormApi, formApiRef}) => {

  const [dropdowns, setDropdowns] = useState({
    adminsList: [],
    categories: [],
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("broadcast_admins_list")).then((response) =>
          setDropdowns(state => ({...state, adminsList: response.data }))),
        startFetching(
          dropdownsSearch("broadcast_categories_list")
        ).then((response) => setDropdowns(state => ({...state, categories: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0 mb-2`} sm="12">
      {listType === 'sent' && <FilterForm
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        filters={filters}
        list={list}
        listType={listType}
        setCount={setCount}
        setFormApi={setFormApi}
        formApiRef={formApiRef}
      />}

      {listType === 'scheduled' && <ScheduledFilterForm
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        filters={filters}
        list={list}
        listType={listType}
        setCount={setCount}
        setFormApi={setFormApi}
        formApiRef={formApiRef}
      />}
    </Row>
  )
}

const ToolBar = (props) => {

  const {isFetching, activeTab, setActiveTab, openModal, setListType, startFetching, setFilters, match, setList, listType, setCount} = props;

  const formApiRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterBroadcasts = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters })))
      .then((res) => {
        setList(res.data);
        setCount(res?.headers['x-total'])
      })
      .catch(error => console.log(error))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params])


  const handleTabsFilter = () => {
    const filters = {status: listType}
    if(listType === 'sent'){
      Object.assign(filters, {...filters, status: ['resent', 'sent']})
    }
    setFilters(filters)
    filterBroadcasts(filters)
    if(formApiRef?.current?.getState()?.values){
      formApiRef.current.setValues({
        sender: '', 
        recipient: '',
        category_ids: '', 
      })
    }
  }

  useEffect(() => {
    if (listType) {
      handleTabsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters, setListType, listType]);

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          setFormApi={setFormApi}
          formApiRef={formApiRef}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span>Broadcast Messages</span>
      </Col>
      <Col className='d-flex justify-content-start pl-1 pr-4' style={{marginLeft: '-32%'}}>
        <Tabs className={styles.tabs} list={tabs} defaultTab='sent' onClick={setListType} />
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        <Button
          className={styles.btnMessage}
          icon={<MessageIcon />}
          onClick={() =>  {openModal('send')}}
        />
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;