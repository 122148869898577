import React, { useCallback, useState } from "react";
import { Container, Row } from "reactstrap";
import IssuedPermits from "./issued_permits";
import PropTypes from 'prop-types';
/* Modules */
import withCurrentSubscriber from "components/modules/with_current_subscriber";
/* Helpers */
import ToolBar from "./toolbar";
import styles from './index.module.sass'
import { useTranslation } from "react-i18next";

const Index = (props) => {
  const [listType, setListType] = useState("issued_permits");
  const [showFilters, setShowFilters] = useState(false);
  const [updatedProps, setUpdatedProps] = useState();
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0)
  const { t } = useTranslation()

  const openModal = useCallback(
    (modalType, status) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "apply_permit":
          val = "apply_permit";
          break;
        case status === 'active' && modalType === 'cancel':
          val = 'cancel'
          break;
        case modalType === 'show':
          val = 'show'
          break;
        case status === 'expired' &&  modalType === 'renew':
          val = 'renew'
          break;
        case status !== 'expired' && modalType === 'extend': 
          val = 'extend'
          break;
        case modalType === "approve":
          val = "approve";
          break;
        case modalType === "reject":
          val = "reject";
          break;
        case modalType === "on_hold":
          val = "on_hold";
          break;
        case modalType === "setting":
          val = "setting";
          break;
        case modalType === "create":
          val = "create";
          break;
        case modalType === "confirmation":
          val = "confirmation";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  const toggleShowFilters = useCallback(() => {
    setShowFilters((val) => !val);
  }, [setShowFilters]);

  const commmonProps = {
    ...props,
    openModal,
    modalType,
    activeTab,
    setActiveTab,
    search,
    setSearch,
    total,
    setTotal,
    t
  };

  return (
      <Container className={`mw-100 p-0 ${styles.container}`}>
        <ToolBar
          listType={listType}
          setListType={setListType}
          showFilters={showFilters}
          toggleShowFilters={toggleShowFilters}
          setFilters={updatedProps?.setFilters}
          filters={updatedProps?.filters}
          startFetching={updatedProps?.startFetching}
          setList={updatedProps?.setList}
          list={updatedProps?.list}
          match={updatedProps?.match}
          total={total}
          {...commmonProps}
          openModal={openModal}
        />
        <Row className="m-0 p-0">
          {listType === "issued_permits" && (
            <IssuedPermits {...commmonProps} setUpdatedProps={setUpdatedProps} t={t}/>
          )}
        </Row>
      </Container>
  );
};

Index.propTypes = {
  startFetching: PropTypes.func.isRequired,
};


export default withCurrentSubscriber(Index);