import React, { useCallback, useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateSetting, update } from 'api/violations';
import SettingConfirmation from '../setting_confirmation';
import View from '../view';

export const ViolationModal = (props) => {
  const { addAlertMessages } = useContext(AlertMessagesContext);

  const { modalType, openModal, autoAccept, setAutoAccept, selectedRecordId, setListElement } = props
  const [errors, setErrors] = useState()

  const closeModal = useCallback(() => {
    openModal()
    setErrors({})
  }, [openModal, setErrors])

  const updateViolationSetting = useCallback(async () => {
    try {
      const response = await updateSetting({data: {violation_auto_accept: autoAccept ? 'false' : 'true'}})
      if(response.status === 200 || response.status === 201){
        setAutoAccept(response?.data?.violation_auto_accept)
        addAlertMessages([{
          type: 'primary',
          text: `Changes on Violation Auto Accept are successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      console.log(error)
      addAlertMessages([{
        type: 'danger',
        text: error?.response?.data?.errors,
        onlyMessage: true,
      }], 'center')
    } finally {
      closeModal()
    }
  },[closeModal, addAlertMessages, setAutoAccept, autoAccept])

  const updateRecord = useCallback(async (data) => {
    try {
      const response = await update({id: selectedRecordId, data: {parking_violation: data}})
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text: `Changes for violation report with ID ${selectedRecordId} were successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    }
  },[addAlertMessages, closeModal, setListElement, selectedRecordId])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'show':
        return { width: '680px' };
      default:
        return { width };
    }
  },[modalType])
  
  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.citationModal} 
      style={{maxWidth: modalProps().width, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'setting_confirmation' && 
          <SettingConfirmation
            closeModal={closeModal}
            updateViolationSetting={updateViolationSetting}
            autoAccept={autoAccept}
          />
        }

        { modalType === 'show' && 
          <View
          {...props}
          closeModal={closeModal}
          updateRecord={updateRecord}
          setErrors={setErrors}
          errors={errors}
          />
        }
      </ModalBody>
    </Modal>
  )
}


export default ViolationModal;
