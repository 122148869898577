import React from 'react';
import PropTypes from 'prop-types';
import Index from 'components/pages/permit_waiting_lists/index';
import Show from 'components/pages/permit_waiting_lists/show';
import Users from 'components/pages/permit_waiting_lists/waitlist_users/index';
import renderWithBackPath from 'components/modules/render_with_back_path';
import { Route, Switch, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={Index} />
    <Route exact path={`${match.path}/waitlist_users`} component={renderWithBackPath(Users, `${match.url}/`)} />
    <Route path={match.path} render={() => (
      <React.Fragment>
        <Route exact path={`${match.path}/:id`} component={renderWithBackPath(Show, `${match.url}/`)} />
      </React.Fragment>
    )}/>
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);