import React, { useCallback, useRef } from 'react'
/* Helpers */
import { displayDate } from 'components/helpers'
import { activityFilterFields } from 'components/helpers/fields/activity_logs/index'
/* Base */
import { renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button'
/* Components */
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
/* Assets */
import styles from '../index.module.sass'

const FilterForm = (props) => {
  const { statuses, officers, types, filterActivityLogs, filters={}, setState  } = props;

  const attrs = { statuses, officers, types, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const setFilters = useCallback((values) => {
    setState('filters', values)
  }, [setState])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      range: {
        from: displayDate(filterValues.range?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.range?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(filters);
    filterActivityLogs(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      range: { from: '', to: ''}, 
      performed_by: '', 
      type: '', 
      old_value: '',
      new_value: ''
    })
    setFilters({})
    filterActivityLogs({})
  }

  const initialValues = {
    ...filters,
    range: {
      from: filters.range?.from ? new Date(filters.range.from) : '' ,
      to: filters.range?.to ? new Date(filters.range.to) : '' ,
    }
  }

  const getFields = (dateFrom, dateTo) => activityFilterFields(Object.assign(attrs, {dateFrom, dateTo}))

  return (
    <section className={styles.subSection}>
      <Form initialValues={initialValues} getApi={setFormApi} className={`${styles.form}`} onSubmit={save}>
        {({formState}) => {
          const dateFrom = formState.values.range?.from || ''
          const dateTo = formState.values.range?.to || ''
          
          const fields = getFields(dateFrom, dateTo)
          return <Row className='m-auto align-items-center'>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(0, 2), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(2, 4), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(4, 6), {lSize: 5, iSize: 7})}
            </Col>
            <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
              <Button
                className={`${styles.btn} ${styles['background-primary']} mr-5`}
                type='submit'
              >
                Filter
              </Button>
              <Button
                type='button'
                onClick={resetFilters}
                className={`${styles.btn} ${styles['background-secondary']}`}
              >
                Reset
              </Button>
            </Col>
          </Row>
        }}
      </Form>
    </section>
  )
}

export default FilterForm;
