import { FieldType } from "components/helpers/form_fields";

const getPassFields = (attrs) => {
  const {
    customLabelClass = "",
    customInputClass = "",
    customDropdownInputClass,
    customExpiryDropdownInputClass,
    customExpiryLabel,
    years,
    customExpiryLabel2,
    months,
    vehicles
  } = attrs;

  return [
    [
      {
        name: "full_name",
        label: "Full Name",
        mandatory: true,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
    ],
    [
      {
        name: "vehicle_lpn",
        label: "Vehicle LPN",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: vehicles,
        mandatory: true,
        disabled: false,
        customInputClass: customDropdownInputClass,
        customLabelClass,
      },
    ],

    [
      {
        name: "email",
        label: "Email Address",
        mandatory: true,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
    ],
    [
      {
        name: "payment_amount",
        label: "Total Payable Amount",
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: "event_name",
        label: "Event Name",
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: "payment_methods",
        label: "Payment Method",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [
          {
            value: 0,
            label: "Google Pay",
          },
          {
            value: 1,
            label: "Credit Card",
          },
        ],
        customInputClass: customDropdownInputClass,
        customLabelClass,
        mandatory: true,
        placeholder: "Select Payment Method",
      },
    ],
    [
      {
        name: "expiration_year",
        label: "Expiration Year",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: "Select a Year (YYYY)",
        options: years
          ? years.map((y) => {
              return { value: y, label: y };
            })
          : [],
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel2,
        disabled: false,
      },
      {
        name: "expiration_month",
        label: "Expiration Month",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: "Select a Month (MM)",
        options: months.map((m) => {
          return { value: m < 10 ? `0${m}` : m, label: m < 10 ? `0${m}` : m };
        }),
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel,
        disabled: false,
      },
    ],
  ];
};

export { getPassFields };