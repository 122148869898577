import React from 'react'
/* Styles/Assets */
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';

import styles from "./index.module.sass";
/* Base */
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import {  Col, Row } from 'reactstrap';
/* Component */
import FilterForm from './filterForm';
import SlideButton from 'components/base/slide_button';

const CollapseContent = ({ activeTab, filters, startFetching, setList, setFilters, match, resource, currentUser, parkingAdmins, townManagers}) => {
 
  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm 
          parkingAdmins={parkingAdmins}
          townManagers={townManagers}
          startFetching={startFetching} 
          setList={setList} 
          match={match} 
          resource={resource} 
          filters={filters}
          setFilters={setFilters}
        /> }
    </Row>
  )
}

const ToolBar = ( props) => {

  const {activeTab, setActiveTab, isResourceFetching, currentUser, parkingAdmins, townManagers, openModal} = props;

  const handleToggleCollapse = (tab) => {
    if(isResourceFetching) return
    setActiveTab(activeTab => activeTab === tab ? null : tab)
  }
  
  return (
    <NavigationToolbar
     openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          currentUser={currentUser}
          parkingAdmins={parkingAdmins}
          townManagers={townManagers}
          {...props}
        />
      }
    >
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span>{`Parking Lots (${props.total})`}</span>
      </Col>
       

      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
          onClick={() => openModal('create')}
        >
          <span className='mr-3 d-inline-block' style={{width: '140px', minHeight: '17px'}}>Create Parking Lot</span>
        </SlideButton>

        <Button
          isActive={true}
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={activeTab === 'filters' ? styles.activeIcon : ''}/>}
          size="md"
          />
        </Col>

    </NavigationToolbar>
  )
}

export default ToolBar;