import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
/* Styles/Assets */
import styles from './index.module.sass';

const Delete = (props) => {
    const { deleteEventCategory, closeModal, record } = props
    return (
        <Col className={styles.body}>
            <div className={styles.content}>
                <Col className='my-3'>
                    This will delete the
                    <span className={styles.boldText}>{record.category_name}</span>
                    event category from the select list.
                </Col>
                <Col className='mb-2 general-text'>
                    Do you want to proceed with deletion?
                </Col>
            </div>
            <Col className="d-flex justify-content-center my-3">
                <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
                    Cancel
                </Button>
                <Button type='button' onClick={deleteEventCategory} className={styles.submitButton}>
                    Delete
                </Button>
            </Col>
        </Col>
    )
}

export default Delete;