import React, { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'informed';
import { createDispute } from 'api/webapp/disputes';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { capitalize } from 'components/helpers';
import Loader from 'components/helpers/loader';
import { subscriberRaiseFields } from 'components/helpers/fields/disputes/index';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { isEmpty } from 'underscore'
import { renderFields } from 'components/base/forms/common_form';
import {searchV1 as dropdownsSearch } from 'api/dropdowns';

const RaiseDispute = ({ startFetching, toast, record, closeModals}) => {
  // eslint-disable-next-line
  const [isFetching, setIsFetching] = useState()
  const [errors, setErrors] = useState({})
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const [dropdowns, setDropdowns] = useState({
    types: [],
  })

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    disputeTypes: dropdowns.types
  }
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const formApiRef = useRef();

  const fieldProps = {
    iSize: 10,
    lSize: 2
  }

  const field1Props = {
    iSize: 12,
    lSize: 8
  }

  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
  }
  
  const fields = subscriberRaiseFields(fieldAttrs)

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await dropdownsSearch('dispute_types_field')
        setDropdowns(state => ({...state, types: response.data }))
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateValues = (formState) => {
    let errors = {}
     const { dispute_type, entry_time, exit_time, text } = formState;

     if(!text)  {errors['text'] = ['Text can\'t be blank']}
     if(text && text.length > 500)  {errors['text'] = ['Text can have max 500 characters']}
     if(!dispute_type)  {errors['dispute_type'] = ['Dispute Type can\'t be blank']}
     if(dispute_type === 'time' && (!entry_time || entry_time === "NaN:NaN"))  {errors['entry_time'] = ['Entry Time can\'t be blank']}
     if(dispute_type === 'time' && (!exit_time || exit_time === "NaN:NaN"))  {errors['exit_time'] = ['Exit Time can\'t be blank']}
     if(entry_time > exit_time) {errors['exit_time'] = ['Exit Time should be greater than Entry Time']}
     if(!text && dispute_type)  {errors['detailed_reason'] = ['Text can\'t be blank']}

     return errors
   }

  const createCitationDispute = useCallback(async (data) => {
    try {
      data.parking_session_id = record.parking_session_id
      const response = await createDispute(data)
      if(response.status === 201){
        addAlertMessages([{
          type: 'notice',
          text:  `Dispute Type ${capitalize(response?.data?.dispute_type)} was successfully raised`,
          onlyMessage: true,
        }], 'center')
        closeModals()
    }
      toast.success(`Dispute Type "${capitalize(response?.data?.dispute_type)}" was successfully raised`);
    } catch(error) {
      if(error?.response?.data?.errors) { 
        setErrors(error.response?.data?.errors)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors])

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(inputErrors)
      return
    };
    createCitationDispute(values);
  },[setErrors, createCitationDispute]);

  if(isFetching) { return <Loader /> }

  return (
    <Container className=' my-3'>
      <div className='mb-1'>
        <p className={`${styles.title} mx-0 mb-2 p-0`}>Raise a dispute</p>
      </div>
      <Form getApi={setFormApi} className={styles.form} onSubmit={save}>
        <div>
          {renderFields(fields?.slice(0,1), { ...fieldProps, errors })}
        </div>
        <Row className='d-flex flex-wrap px-0'>
          <Col className={styles.fieldset}>
            {renderFields(fields.slice(2,3), {...doubleFieldProps, errors})}
          </Col>
          <Col className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
            {renderFields(fields.slice(3), {...doubleFieldProps, errors})}
          </Col>
        </Row>
        <div>
          {renderFields(fields?.slice(1,2), { ...field1Props, errors })}
        </div>
        <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
          <Button onClick={closeModals} type='button' className={`${styles.btn}  ${styles['btn-cancel']} mr-4`}>
            Cancel
          </Button>
          <Button type='submit' className={styles.btn} >
            Submit
          </Button>
        </Col>
      </Form>
    </Container>
  )
}

export default RaiseDispute;