import React, { useCallback, useRef } from 'react'
import { displayDate } from 'components/helpers'
import { filterFields } from 'components/helpers/fields/parking_lot_voi';
import Button from 'components/base/button'
import { filterFetcher } from "api/parking_lot_voi";
import { Form } from 'informed'
import { Col } from 'reactstrap'
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'
import { renderFieldsWithGrid } from 'components/base/forms/common_form';

const FilterForm = (props) => {
  const {parkingLots,filters, statuses, types, setFilters, match, setList, startFetching } = props;

  const attrs = {parkingLots, disputeStatuses: statuses, disputeTypes: types, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterDisputes = useCallback(async (filters={}) => {
    const { range } = filters
    if(range && !range?.to) { delete range.to}
    if(range && !range?.from) { delete range.from}

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      range: {
        from: displayDate(filterValues.range?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.range?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(filters);
    filterDisputes(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      range: { from: '', to: ''}, 
      plate_number: ''
    })
    setFilters({})
    filterDisputes({})
  }


  const initialValues = {
    ...filters,
    range: {
      from: filters?.range?.from ? new Date(filters?.range?.from) : '' ,
      to: filters?.range?.to ? new Date(filters?.range?.to) : '' ,
    }
  }

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (

      <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save}>
        {({formState}) => {
          const dateFrom = formState.values.range?.from || ''
          const dateTo = formState.values.range?.to || ''
          
          const fields = getFields(dateFrom, dateTo)
          return <React.Fragment>
          <div lg={12} className=''>
            {renderFieldsWithGrid(
            fields, 
            3, 4, 
            { iSize: 7, lSize: 5 }
          )}
          </div>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
          </React.Fragment>
        }}
      </Form>

  )
}

export default FilterForm;
