import React from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/reward_types';
/* Styles */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {
  const {isResourceFetching, setRecord, list, openModal, activeTab, modalType, match } = props

  const renderRecords = () => {
    return list.map((record) => {
      return ( <TableRow 
        key={record.id} 
        record={record} 
        openModal={openModal} 
        setRecord={setRecord} 
        modalType={modalType}
        match={match}
      />);
    });
  }
  
  return (
    <IndexTable
      {...props}
      className={`${ activeTab === 'filters' ? styles.tableActive : styles.table}`}
      isFetching={() => isResourceFetching }
      filterFetcher={filterFetcher}
      columns={
        <React.Fragment>
          <th attr="name">Reward Program Name</th>
          <th attr="total_points_earned" style={{ width: '40%', minWidth: '200px' }}>Points Earned</th>
          <th attr="total_points_redeemed" style={{ width: '40%', minWidth: '200px' }}> Points Redeemed </th>
          <th attr="users_enrolled" style={{ width: '40%', minWidth: '200px' }}> Users Enrolled </th>
          <th attr="created_at_parsed" style={{ width: '45%', minWidth: '250px' }}> Created On </th>
          <th attr="status" style={{ width: '40%', minWidth: '100px' }}> Status </th>
          <th attr="reward_types.actions" style={{ width: '30%', minWidth: '210px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="Parking Rewards"
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData