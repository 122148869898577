import React, { useCallback, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'informed';
import { renderField } from 'components/base/forms/common_form'
import { movePositionFields } from 'components/helpers/fields/permit_waiting_lists';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { isEmpty, map } from 'underscore'

const Shift = ({ closeModal , userWaitingListRecord, moveWaitlistPosition, errors, setErrors}) => {
  const formApiRef = useRef();
  const attrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel
  }

  const initialValues = {
    waiting_list_number: userWaitingListRecord?.waiting_list_number,
  }
  
  const singleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const validateValues = (formState) => {
    const errors = {}
     const { new_position } = formState;
     if(!new_position) { errors['new_position'] = ['New Position is required']}
     return errors
   }

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    moveWaitlistPosition(values);
  },[setErrors, moveWaitlistPosition]);

  const getFields = () => {
    return movePositionFields({mute: false, ...attrs})
  }

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}>Move the user up or down the list</p>
        <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save} >
          {({ formState }) => {
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100' style={{padding: '10px'}}>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className='m-0 p-0 pl-2' xs={12}>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                { map(newFields[1], (field, idx) => {
                  return (
                    <Col key={idx} className='m-0 p-0 pl-2' xs={12}>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                }) }
        
                <Col className='d-flex justify-content-center mb-3 mt-3' xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={styles.button}>
                    Update
                  </Button>
                </Col>
              </Row>
            )
          }}
        </Form>
    </Container>
  )

}

export default Shift;