import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { isEmpty } from 'underscore'
import { Form } from 'informed'
import env from '.env'
import qs from 'qs';
import { ROLES } from 'config/permissions'
/* Api */
import { search as dropdownSearch } from 'api/dropdowns' 
/* Helpers */
import { fieldsNew, dateFields } from 'components/helpers/fields/citation_reports'
import AccessControl from 'components/helpers/access-control';
import { displayDate } from 'components/helpers'
/* Base */
import Button from 'components/base/button'
import NavigationToolbar from 'components/base/navigationToolbar'
/* Modules */
import withCurrentUser from 'components/modules/with_current_user'
import { camelize } from 'components/helpers';
/* Styles and Assets */
import styles from './index.module.sass'
import { renderFieldsWithGrid,renderField } from "components/base/forms/common_form";
import GenerateReportModal from './generateReportModal'


const exportFile = (data) => {
  const query = qs.stringify({
    token: localStorage.TOKEN,
    ...data,
  }, { arrayFormat: 'brackets' })

  const url = `${env.backend_url}/dashboard/parking/citation_tickets/report.xlsx?${query}`
  window.open(url, '_blank');
  window.focus();
}

const GenerateCitationReports = ({currentUser}) => {

  const formApiRef = useRef(null)
  const filtersRef = useRef({})

  const [parkingLotsDropdown, setParkingLotsDropdown] = useState([])
  const [violationTypesDropdown, setViolationTypesDropdown] = useState([])

  const fieldProps = { lSize: 3, iSize: 8 }

  const [errors, setErrors] = useState({})
  const [openGenModal, setOpenGenModal] = useState(false)
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  
  useEffect(() => {
    const get_violation_types = async() => { await dropdownSearch("violation_type_field").then((response) => setViolationTypesDropdown(response.data))}
    const get_parking_lots = async() => { await dropdownSearch("parking_lot_list").then((response) => setParkingLotsDropdown(response.data))}
    get_violation_types()
    get_parking_lots()
  }, [])

  const generateReport = useCallback((values={}) => {
    const formFilters = Object.assign(filtersRef.current)
    const { updated_at={}, created_at={} } = formFilters
    const filters = {
     citation_ticket: {...formFilters,
      send_report: values.send_email,
      emails: !isEmpty(values.emails) ? Object.values(values.emails) : [],
      created_at: {
        from: displayDate(created_at.from, 'yyyy-mm-dd'),
        to: displayDate(created_at.to, 'yyyy-mm-dd')
      },
      updated_at: {
        from: displayDate(updated_at.from, 'yyyy-mm-dd'),
        to: displayDate(updated_at.to, 'yyyy-mm-dd')
      },},
      emails: !isEmpty(values.emails) ? Object.values(values.emails) : [],

    }
    exportFile(filters)
    formApiRef.current.reset();
    setOpenGenModal(false)
  },[setOpenGenModal])

  const handleSubmit = useCallback((values) => {
    filtersRef.current = values
    setErrors({})    
    if(!isEmpty(errors)) {
      setErrors(errors)
      return
    }
    setOpenGenModal(true)
  },[setErrors, setOpenGenModal, errors])

  const backgroundColor = (status) => {
    switch (status) {
      case 'settled':
        return 'success'
      case 'unsettled':
        return 'orange'
      case 'sent_to_court':
        return 'danger'
      default:
        return 'default';
    }
  }

  const statusValue = (value) => {
    return (
      <React.Fragment>
        <span className={`${styles.icon} ${styles[`icon-${backgroundColor(value)}`]}`}></span>
        <span>{camelize(value)}</span>
      </React.Fragment>
    )
  }

  const status = [
    { value: 'all_statuses', label: 'All statuses' },
    { value: 'settled', label: statusValue('settled') }, 
    { value: 'pending', label: statusValue('unsettled') }, 
    { value: 'sent_to_court', label: statusValue('sent_to_court') }
  ]

 
  return (
    
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className={`mw-100 m-0 p-3`} style={{minHeight:"600px"}}>
        <NavigationToolbar
          backTab='/dashboard/parking/citation_tickets'
          className=''
        >
          <Col className='d-flex justify-content-start align-items-center'>
            <span className={styles.title}>Generate Citation Reports</span>
          </Col>
        </NavigationToolbar>
        
        <Row className={`${styles.main} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
          <p className={`${styles.heading} pl-4`}>
            Citation Report Criteria
          </p>
          <Form getApi={setFormApi} onSubmit={handleSubmit} className="w-100 m-10">
          { ({formState}) => {
              const { created_at={}, updated_at={} } = formState.values;
              const allFields = dateFields(
                styles.formLabel,
                styles.formInput,
                { startDateCreated: created_at.from },
                { endDateCreated: created_at.to },
                { startDateUpdated: updated_at.from },
                { endDateUpdated: updated_at.to }
              );
            
            return (
              <React.Fragment>
                <Row
                  className={styles.fields}
                  style={{ margin: "10px", marginTop: "30px" }}
                >
                  <Col className={styles.fieldset}>
                    {renderFieldsWithGrid(
                      fieldsNew(
                        status,
                        violationTypesDropdown,
                        parkingLotsDropdown,
                        `${styles.formLabel}`,
                        `${styles.formInput}`,
                        { startDateCreated: created_at.from },
                        { endDateCreated: created_at.to },
                        { startDateUpdated: updated_at.from },
                        { endDateUpdated: updated_at.to }
                      ),
                      5,
                      4,
                      fieldProps
                    )}
                  </Col>
                </Row>
                <Col className={styles.fields2} xs={12}>
                  <div className={`${styles["fieldset-double"]}`}>
                    <div className="general-text-2">
                      <span className={styles.doubleLabel}>
                        Created Date
                      </span>
                    </div>
                    <div className={styles.createAtFields}>
                      {renderField(allFields[0][0], { iSize: 8, errors })}
                      <span
                        className={`${styles["fieldset-stroke"]} position-relative`}
                      ></span>
                      {renderField(allFields[0][1], { iSize: 8, errors })}
                    </div>
                  </div>
                  <div className={`${styles["fieldset-double1"]}`}>
                    <div className="general-text-2">
                      <span className={styles.doubleLabel2}>
                        Updated Date
                      </span>
                    </div>
                    <div className={styles.updatedAtFields}>
                      {renderField(allFields[0][2], { iSize: 8, errors })}
                      <span
                        className={`${styles["fieldset-stroke2"]} position-relative`}
                      ></span>
                      {renderField(allFields[0][3], { iSize: 8, errors })}
                    </div>
                  </div>
                </Col>

                <Col className="d-flex justify-content-center my-3" xs={12}>
                  <Button
                    className={`${styles.button} ${styles["button-disabled"]} mr-5`}
                    type="button"
                    onClick={() => formApiRef.current.reset()}
                  >
                    Reset
                  </Button>
                  <Button className={`${styles.button}`} type="submit">
                    Generate
                  </Button>
                </Col>
              </React.Fragment>
            );
          }}
          </Form>
        </Row>
        <GenerateReportModal 
          modal={openGenModal}
          generateReport={generateReport}
          closeModal={() => setOpenGenModal(false)}
        />
      </Container>
    </AccessControl>
  )
}
export default withCurrentUser(GenerateCitationReports)