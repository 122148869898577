import React from "react";
import { filterFetcher } from "api/agencies";
import IndexTable from "components/base/table";
import TableRow from "./table_row";
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, selectedRecord, openModal, modalType, setSelectedRecord, activeTab, filters } = props;

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          {...props}
          key={record.id} 
          record={record} 
          history={history}
          selectedRecord={selectedRecord}
          match={match}
          openModal={openModal}
          modalType={modalType}
          setSelectedRecord={setSelectedRecord}
        />
      );
    });
  }

  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="agencies.id" style={{width: '25%', minWidth: '210px'}}>Law Enforcement Agency ID</th>
          <th attr="agencies.name" style={{width: '20%', minWidth: '210px'}}>Law Enforcement Agency Name</th>
          <th attr="admins.name" style={{width: '20%', minWidth: '210px'}}>Law Enforcement Manager</th>
          <th attr="agency_type.name" style={{width: '20%', minWidth: '210px'}}>Law Enforcement Agency Type</th>
          <th attr="agencies.status" style={{width: '10%', minWidth: '210px'}}>Status</th>
          <th 
            attr="actions"
            style={{ width: '10%', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Law Enforcement Agencies Management'
    />
  )
}

export default TableData;