import React from 'react'
/* Styles/Assets */
import styles from './index.module.sass'
/* Base */
import NavigationToolbar from 'components/base/navigationToolbar';
import { Col } from 'reactstrap';
/* Component */
import Tabs from 'components/base/tabs';

const tabs = [
  { label: 'Manufacturer', value: 'manufacturers' },
  { label: 'Event Categories', value: 'event_categories' },
  { label: 'Disabilities', value: 'disabilities' },
  { label: 'Residential Types', value: 'residential_types' }
]

const ToolBar = (props) => {
  const { setListType, listType } = props;
  return (
    <NavigationToolbar>
      <Col className='d-flex justify-content-start pt-2 pl-1 pr-4'>
        <Tabs className={styles.tabs} list={tabs} defaultTab={listType} onClick={setListType} />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;