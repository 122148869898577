import { FieldType } from "../form_fields";

const reportFields = (attrs) => {
  const { customInputClass='', customLabelClass='', customDropDownClass='', incidentTypes, parkingLots } = attrs;
  return[
    {
      name: 'incident_type',
      label: 'Incident Type',
      mandatory: true,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      placeholder: 'Please Select...',
      options: incidentTypes,
      customInputClass: customDropDownClass,
      customLabelClass,
    },
    {
      name: 'parking_lot_id',
      label: 'Parking Lot',
      mandatory: true,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      placeholder: 'Please Select...',
      options: parkingLots,
      customInputClass: customDropDownClass,
      customLabelClass,
    },
    {
      name: 'user_email',
      label: 'Email Address',
      placeholder: 'Enter Your Email Address...',
      mandatory: true,
      customInputClass,
      customLabelClass,
    },
    {
      label: 'Additional Details',
      name: 'additional_details',
      type: FieldType.TEXT_AREA,
      rows: 3,
      customLabelClass,
      customInputClass
    },
  ]
}

export {reportFields};
