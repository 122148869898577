
// PaymentPage.js
import React, { useState, useEffect } from 'react';
import styles from './index.module.sass'; // Adjust the path as needed
import { Button, Col, Container, Navbar } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { show } from 'api/events';
import { isEmpty } from 'underscore';
import navStyles from '../new/index.module.sass'
import PaymentGateway from 'components/pages/app/payment_gateway/index'

const PaymentPage = ({ match }) => {
  const [event, setEvent] = useState({})
  const [organizerEmail, setOrganizerEmail] = useState('')
  const history = useHistory()


  const redirect = () =>{ 
    let path = `/login`; 
    history.push(path);
  }

  
  useEffect(() => {
    if (!match.params?.id) return;
    const fetchEvent = async () => {
      try {
        const res = await show(match.params);
        setEvent(res.data);
        setOrganizerEmail(res.data.organizer_email)
      } catch (_error) {
      } finally {
      }
    };
    if(isEmpty(event)) fetchEvent()
  }, [event, match.params]);

  return (<>
      {!isEmpty(event) && (event.paid || event.status==='expired') &&
        <div>
           <Container className={`${navStyles.default} w-100 mw-100 p-0 m-0`}>
              <Navbar sticky="top" color="primary" light expand className="shadow-sm justify-content-center">
                <span className={navStyles.navbarHead}>
                  {"Purchase Event Parking"}
                </span>
              </Navbar>           
      </Container>
        <p className={styles.paymentStatus}>{event.paid? "The Payment For this Event Parking Application Is Already Completed Successfully !" : event?.status==='expired' ? "This Event Parking Application is Expired !" : ""}</p>
        <Col className="d-flex justify-content-center my-5">
        {
            <Button 
                type='button' 
                onClick={redirect} 
                className={`${styles.goBtn}`}
              >
               Go to Login Page {">>"}
            </Button>
        }  
      </Col>
        </div>
      }
    {!isEmpty(event) && (!event.paid && event.status !== 'expired') && <PaymentGateway root={'eventApplication'} match={match} event={event} organizerEmail={organizerEmail} qrCode={event?.qr_code_for_payment} amount={!event.organizer_cost ? event?.total_cost : event?.organizer_cost} />}
  </>
  );
};



export default PaymentPage;

	
