import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Container, Col, Row } from 'reactstrap';
import Button from 'components/base/button'
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'informed'
import { renderFields } from 'components/base/forms/common_form'
import { reportFields } from 'components/helpers/fields/report_fields'
import { isEmpty } from 'underscore'
import { searchV1 as dropdownsSearch } from 'api/dropdowns';
import { create, create_by_unregistered_user } from 'api/webapp/incident_report'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';

const ReportIncidentModal = (props) => {
  const { currentSubscriber, isOpen, setReportModal } = props
  const [showInnerModal, setInnerShow] = useState(false);
  const [errors, setErrors] = useState({})
  const [apiResponse, setApiResponse] = useState()
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
    incidentTypes: [],
  })

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={()=> setReportModal(false)}
    />
  },[setReportModal]);

  const handleInnerClose = () => {
    setInnerShow(false)
    setReportModal(false);
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const incidentType = await dropdownsSearch('incident_type_list')
        setDropdowns(state => ({...state, incidentTypes: incidentType?.data }))
        const parkingLot = await dropdownsSearch('parking_lot_list')
        setDropdowns(state => ({...state, parkingLots: parkingLot?.data }))
      } catch (error) {
        console.log(error)
      }
    }
    if(isEmpty(dropdowns.parkingLots) || isEmpty(dropdowns.incidentTypes)) fetch()
  },[dropdowns])


  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customDropDownClass: styles.formDropdownInput,
    parkingLots: dropdowns.parkingLots,
    incidentTypes: dropdowns.incidentTypes
  }

  const singleFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const fields = () => {
    return reportFields({...fieldAttrs})
  }
  
  const validateValues = (formState) => {
    let errors = {}
    const { incident_type, parking_lot_id, user_email } = formState;
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if(!currentSubscriber && !user_email) {
      errors['user_email'] = ['Email can\'t be blank']
    }
    if(!currentSubscriber && user_email && !(user_email?.match(isValidEmail))) {
      errors[`user_email`] = ['Invalid email address !']
    }
    if(!parking_lot_id) {
      errors['parking_lot_id'] = ['Detailed Reason can\'t be blank']
    }
    if(!incident_type) {
      errors['incident_type'] = ['Incident Type can\'t be blank']
    }
    return errors
   }

  const createReport = useCallback(async (data) => {
    try {
      const response = await create({data: {incident: data} })
      if(response.status === 200 || response.status === 201 )
      {
        setApiResponse(response.data)
        setInnerShow(true)
        setErrors({})
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    }
  },[setErrors, setInnerShow, setApiResponse])

  const createReportOnlinePortal = useCallback(async (data) => {
    try {
      const response = await create_by_unregistered_user({data: {incident: data} })
      if(response.status === 200 || response.status === 201 )
      {
        setApiResponse(response.data)
        setErrors({})
        setInnerShow(true)
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    }
  },[setErrors, setInnerShow, setApiResponse])


  const save = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    if(currentSubscriber){
      createReport(values);
    }else{
      createReportOnlinePortal(values)
    }
  }

  return (
    <Modal
      isOpen={isOpen} 
      centered 
      contentClassName={styles.appModal} 
      modalClassName={styles.modal}
      backdropClassName={styles.backdropC}
      style={{width: '98%'}}
    >
      <ModalHeader close={closeBtn()} className='border-0 pb-0' />
      <ModalBody>
        <Container className='p-0'>
          <p className={`${styles.title} m-0 p-0`}>Report An Incident</p>
          <p className={`${styles.title1} m-0 p-0`}>Please fill out the form below</p>
          <Form getApi={setFormApi} className='m-auto' onSubmit={save}>
            {({ formState }) => {
              const newFields = fields()
              return (
                <Row className='mx-auto w-100 p-0 pt-3'>
                  <Col className='px-4'>
                    {renderFields(newFields.slice(0, 2), {...singleFieldProps, errors})}
                    {!currentSubscriber && renderFields(newFields.slice(2, 3), {...singleFieldProps, errors})}
                    {renderFields(newFields.slice(3, 4), {...singleFieldProps, errors})}
                  </Col>
                  <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                    <Button onClick={()=>setReportModal(false)} type='button' className={`${styles.cancelButton} mr-4`}>
                      Cancel
                    </Button>
                    <Button type='submit' className={styles.submitButton}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              )
            }}
          </Form>
        </Container>
        <Modal 
          isOpen={showInnerModal} 
          centered 
          contentClassName={styles.appModal}
          backdropClassName={styles.backdropC}
          modalClassName={styles.modal}
          style={{maxWidth : '480px', width: '98%'}} 
        >
          <ModalHeader className="border-0 pb-0" close={closeBtn()} />
          <ModalBody>
            <Container className='p-0' style={{marginTop: '-1rem'}}>
              <Col className="d-flex justify-content-center my-2 pb-2">
                <SuccessIcon/>
              </Col>
              <Container className='mb-4' >
                <Row className="d-flex justify-content-center my-2">
                  <span className={`${styles.content1} mr-1`}>{apiResponse?.incident_type.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}</span>
                  <span className={styles.content}>incident type was successfully reported</span>
                </Row>
                <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                  <Button onClick={()=>handleInnerClose()} type='button' className={styles.cancelButton1}>
                    Ok
                  </Button>
                </Col>
              </Container>
            </Container>
          </ModalBody>
        </Modal>
      </ModalBody>
    </Modal>
  )
}

export default ReportIncidentModal;