import fetchApi from 'components/modules/fetch_api';

const getCurrentParkingSessions = () => {
  return fetchApi(`v1/parking_sessions/current`, { method: 'GET', params: { } })
}

const getParkingSessionsHistory = () => {
  return fetchApi(`v1/parking_sessions/recent`, { method: 'GET', params: { } })
}


export { getCurrentParkingSessions, getParkingSessionsHistory };