import React from 'react'
import styles from './index.module.sass'

const Preview = ({close, image}) => {

  return (
    <div
      className={styles.modalBackdrop}
      onClick={() => {close()}}
    >
      <div
        className={styles.modalContent}
        onClick={e => {e.stopPropagation()}}
      >
      <img className={styles.img} alt="" src={image}/>
      </div>
    </div>
  )
}

export default Preview;
