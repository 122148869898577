const applicationDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'application[issue_on]',
      label: 'Application Date',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[type]',
      label: 'Application Type',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[permit_type_name]',
      label: 'Permit type',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[parking_lot]',
      label: 'Valid Parking Lot',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[status]',
      label: 'Status',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export default applicationDetails;