import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/event_categories';
/* Helpers */
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {

    const { list, setRecord, filters, isResourceFetching, openModal, modalType, selectedRecord, activeTab } = props

    const renderRecords = useCallback(() => {
        return list.map((record) => (
            <TableRow
               key={record?.id}
               record={record}
               selectedRecordId={selectedRecord?.id}
               openModal={openModal}
               modalType={modalType}
               setRecord={setRecord}
            />
        ));
    }, [list, modalType, setRecord, openModal, selectedRecord])

    return (
        <IndexTable
            {...props}
            className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
            isFetching={() => isResourceFetching}
            filterFetcher={filterFetcher}
            paginationQuery={filters}
            columns={
                <React.Fragment>
                    <th attr="category_name" style={{ width: '50%', minWidth: '210px' }}>Event Category</th>
                    <th attr="status" style={{ width: '50%', minWidth: '210px' }}> Status </th>
                    <th className='d-flex justify-content-center' attr="actions" style={{ width: '20%', minWidth: '210px', }} disableSort> Action </th>
                </React.Fragment>
            }
            renderRecords={renderRecords}
            entityName='Event Categories'
        />
    )
}

TableData.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object.isRequired
};

export default TableData