import React, { useRef } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { parkingLotFields } from 'components/helpers/fields/booting_and_towing';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'

const FilterParkingLot = (props) => {
  const { selectedParkingLot, parkingLots, setSelectedParkingLot, setFilters} = props

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const initialValues = {
    parking_lot_id: selectedParkingLot
  }

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    parkingLots: parkingLots
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (e) => {
        setSelectedParkingLot(e.target.value)
        setFilters((prevState) => ({
          ...prevState,
          parking_lot_id: e?.target?.value
        }));
      }
    }
  }

  const fields = () => {
    return parkingLotFields({...fieldAttrs})
  }

  if(!selectedParkingLot) return null

  return (
    <Container className='p-0'>
      <Form getApi={setFormApi} initialValues={initialValues} className={styles.form}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
                <div >
                  {renderFields(newFields.slice(0, 1), {...singleFieldProps})}
                </div>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (FilterParkingLot);