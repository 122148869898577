import React, { useState } from 'react'
import { Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';
import ActiveSessions from '../active_sessions';
import PastSessions from '../past_sessions';
import styles from './index.module.sass'

const Index = (props) => {
  const { currentUser } = props;
  const [listType, setListType] = useState('active');
  const [activeTab, setActiveTab] = useState()

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0 pt-3'>
        <ToolBar
          listType={listType}
          setListType={setListType}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          {...props}
        />
        <Row className={styles.sessionTable}>
        {listType === "active" && (
            <ActiveSessions {...props} />
          )}
        {listType === "past" && (
          <PastSessions {...props} />
        )}
        </Row>
      </Container>
    </AccessControl>
  )
}

export default withCurrentUser(Index)