import React,  { useEffect, useState } from 'react'
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import styles from "./index.module.sass";
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { Col, Row } from 'reactstrap';
import FilterForm from './filterForm';
import { search as dropdownsSearch } from "api/dropdowns";

const CollapseContent = (props) => {
  const { activeTab, filters, startFetching, setList, setFilters, match, resource, parkingAdmins, townManagers} = props
  const [dropdowns, setDropdowns] = useState({
    paymentMethods: [],
    parkingSessionStatuses: [],
    parkingSessionsKioskIds: []
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("payment_methods_list"))
        .then((response) => setDropdowns(state => ({...state, paymentMethods: response.data }))),
        startFetching(dropdownsSearch("parking_session_statuses_list"))
        .then((response) => setDropdowns(state => ({...state, parkingSessionStatuses: response.data }))),
        startFetching(dropdownsSearch("parking_session_kiosk_ids_list", { parking_lot_id: match.params.id }))
        .then((response) => setDropdowns(state => ({...state, parkingSessionsKioskIds: response.data }))),
      ])
    }

    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm 
          parkingAdmins={parkingAdmins}
          townManagers={townManagers}
          startFetching={startFetching} 
          setList={setList} 
          match={match} 
          resource={resource} 
          filters={filters}
          setFilters={setFilters}
          {...dropdowns}
        /> }
    </Row>
  )
}

const ToolBar = ( props) => {

  const {activeTab, setActiveTab, isResourceFetching, currentUser, parkingAdmins, townManagers, match} = props;

  const handleToggleCollapse = (tab) => {
    if(isResourceFetching) return
    setActiveTab(activeTab => activeTab === tab ? null : tab)
  }
  return (
    <NavigationToolbar
     openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          currentUser={currentUser}
          parkingAdmins={parkingAdmins}
          townManagers={townManagers}
          {...props}
        />
      }
      backTab={`/dashboard/parking_lots/${match?.params?.id}/spaces`}
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span>{`Parking Sessions (${props.total})`}</span>
      </Col>
       
      <Col className='d-flex justify-content-end pr-0'>
        <Button
          isActive={true}
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
          />
        </Col>

    </NavigationToolbar>
  )
}

export default ToolBar;