import { FieldType } from 'components/helpers/form_fields';

const searchFields = ({parkingLots, disputeStatuses, disputeTypes, customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'dispute_type',
    label: 'Dispute Type',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a type',
    options: disputeTypes,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'parking_lot_id',
    label: 'Parking Lot',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a parking lot',
    options: parkingLots,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a Status',
    options: disputeStatuses,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'user_name', 
    label:"Subscriber Username",
    placeholder: 'Enter Username',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'id', 
    label:"Dispute Id",
    placeholder: 'Enter Dispute Id',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'email', 
    label:"Email",
    placeholder: 'Enter Email Address',
    customInputClass,
    customLabelClass,
  }
];

export default searchFields;
