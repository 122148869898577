import React, { useCallback, useEffect, useReducer } from 'react'
import { ROLES } from 'config/permissions'
import MtaIntegration from './mtaIntegration'
import { isEmpty } from 'underscore'
/* Helpers */
import AccessControl from 'components/helpers/access-control'
import { Container, Row } from 'reactstrap'
import Loader from 'components/helpers/loader'
/* Base */
import Tabs from 'components/base/tabs'
/* Module */
import withCurrentUser from 'components/modules/with_current_user'
/* Styles */
import styles from './index.module.sass'
/* API */
import { showAdminDetails } from 'api/mtas'

const requestTypes = [
  { label: 'HTTP', value: 'http' },
  { label: 'API Key', value: 'api_key' }
]

const integrationTypesSub = [
  { label: 'MDOT', value: 'mdot' },
  { label: 'SubTab', value: 'sub_tab' }
]

const integrationTypes = [
  { label: 'MTA', value: 'mta' },
  { label: 'EV Charging', value: 'ev_charging' }
]

const initState = { errors: {}, isSaving: false, adminDetails: {}, requestType: '', integrationType: 'mta', isFetching: true, integrationTypeSub: 'mdot' }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'adminDetails':
      return {...state, adminDetails: payload }
    case 'isFetching':
      return {...state, isFetching: payload }
    case 'requestType':
      return {...state, requestType: payload }
    case 'integrationType':
      return {...state, integrationType: payload }
    case 'integrationTypeSub':
      return {...state, integrationTypeSub: payload }
    default:
      return { ...state };
  }
}

const Index = ({currentUser}) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { integrationType, requestType, adminDetails, isFetching, integrationTypeSub } = state;

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const res = await showAdminDetails();
        setState('adminDetails', res.data)
        setState('requestType', res.data.authentication_type)
      } catch (_error) {} finally { setState('isFetching', false)}
    }

    if (isEmpty(adminDetails)) fetchAdminDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(isFetching) { return <Loader /> }

  return (
    <AccessControl
    currentRole={currentUser?.role_type}
    allowedRoles={[ROLES.SUPER_ADMIN]}
  >
    <Container className='w-100 p-0 m-0 mw-100'>
      <div className={`${styles.tabs} mb-3`}>
      <Tabs 
          list={integrationTypes} 
          defaultTab={integrationType} 
          onClick={(val) => setState('integrationType', val)} 
        />
      </div>
      <div className={`${styles.tabs} mb-3`}>
      <Tabs 
          list={integrationTypesSub} 
          defaultTab={integrationTypeSub} 
          onClick={(val) => setState('integrationTypeSub', val)} 
        />
      </div>
      <div className={`${styles.tabs} mb-3`}>
        <Tabs 
          list={requestTypes} 
          defaultTab={requestType} 
          onClick={(val) => setState('requestType', val)} 
        />
      </div>
      <Row className={`${styles.mainContent} w-100 m-auto py-5 justify-content-center flex-column align-items-center`}>
        { integrationType === 'mta' && integrationTypeSub === 'mdot' &&
          <MtaIntegration
            setState={setState}
            {...state}
          />
        }
      </Row>
    </Container>
  </AccessControl>
  )
}

export default withCurrentUser(Index)