import { FieldType } from 'components/helpers/form_fields';

const topUpFields = (attrs) => {
    const { customLabelClass='', customInputClass='', customDropdownInput, customExpiryDropdownInputClass, customExpiryLabel2, customExpiryLabel, months, years, t} = attrs
    return ([
        [
          {
            name: 'amount',
            label: t("subscriber.profile.top_up_modal.amount.label"),
            type: FieldType.NUMBER_FIELD,
            placeholder: t("subscriber.profile.top_up_modal.amount.placeholder"),
            min: 1,
            mandatory: true,
            customInputClass,
            customLabelClass,
          },
        ],
        [
          {
            name: 'payment_methods',
            label: t("events.city_events.get_pass_form.payment.payment_method.label"),
            placeholder: t("events.city_events.get_pass_form.payment.payment_method.placeholder"),
            type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
            options: [{value: 0, label:'Credit/Debit Card'}, {value: 1, label:'Google Pay'}],
            customInputClass: customDropdownInput,
            customLabelClass,
            mandatory: true,
          },
        ],
        [
          {
            name: "expiration_year",
            label: t("events.city_events.get_pass_form.payment.credit_card.expiration_year.label"),
            type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
            placeholder: t("events.city_events.get_pass_form.payment.credit_card.expiration_year.placeholder"),
            options: years
              ? years.map((y) => {
                  return { value: y, label: y };
                })
              : [],
            customInputClass: customExpiryDropdownInputClass,
            customLabelClass: customExpiryLabel2,
            disabled: false,
          },
          {
            name: "expiration_month",
            label: t("events.city_events.get_pass_form.payment.credit_card.expiration_month.label"),
            type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
            placeholder: t("events.city_events.get_pass_form.payment.credit_card.expiration_month.placeholder"),
            options: months.map((m) => {
              return { value: m < 10 ? `0${m}` : m, label: m < 10 ? `0${m}` : m };
            }),
            customInputClass: customExpiryDropdownInputClass,
            customLabelClass: customExpiryLabel,
            disabled: false,
          },
        ],
      ]);
    } 

export { topUpFields };