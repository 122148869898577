import React, { useCallback } from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import SlideButton from 'components/base/slide_button';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';

const ToolBar = (props) => {
  const { activeTab, setActiveTab, list, isFetching, openModal} = props
  
  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      backTab='subscribers'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span className={styles.title}>{`Account Types (${list?.length})`}</span>
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
          onClick={() => {openModal('create')}}
        >
          <span className='mr-3 d-inline-block' style={{width: '140px'}}>Create New Type</span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
        
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
