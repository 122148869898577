import React, {useCallback} from 'react'
import PropTypes from 'prop-types';
/* Helpers */
import { filterFields } from 'components/helpers/fields/event_parking_applications/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';
/* API */
import { filterFetcher } from 'api/parking_slots';

const TableData = (props) => {
  
  const { list, setRecord, filters, isResourceFetching, openModal, modalType, selectedRecord, activeTab } = props

  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        {...props}
        key={record.id} 
        record={record} 
        selectedRecordId={selectedRecord.id} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    });
  },[list, modalType, setRecord, openModal,selectedRecord.id, props])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      total={list.length}
      columns={
        <React.Fragment>
          <th attr="id" style={{ width: '25%', minWidth: 250 }}>Parking Slot ID</th>
          <th attr="name" style={{ width: '25%', minWidth: 250 }}>Parking Slot Name</th>
          <th attr="status" style={{ width: '25%', minWidth: 250 }}>Status</th>
          <th attr="archived" style={{ width: '25%', minWidth: 250 }}>Archived</th>
          <th className='d-flex justify-content-center' attr="actions" style={{ width: '25%', minWidth: 250, }} disableSort> Actions </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Parking Slots'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData