import { CarpoolPermitActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(CarpoolPermitActions);

const CarpoolPermitReducers = combineReducers({
  index,
  records,
});

export default CarpoolPermitReducers;
