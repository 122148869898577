import { FieldType } from 'components/helpers/form_fields';

const settingFields = (attrs, disabled) => {
    const { customLabelClass='', customInputClass=''} = attrs
    return ([
        [
          {
            name: 'allow_cancellations',
            label: 'Allow Cancellations ?',
            type: FieldType.RADIO_BUTTON_FIELD,
            mandatory: true,
            list: [
              { label: 'Yes', value: "true" },
              { label: 'No', value: "false" }
            ],
            customInputClass,
            customLabelClass,
          },
        ],
        [
          {
            name: 'cancellations_grace_period',
            label: 'Cancellation Grace Period (Hours)',
            type: FieldType.NUMBER_FIELD,
            placeholder:"Enter Number of Hours",
            disabled ,
            customInputClass,
            customLabelClass,
            mandatory: true,
            min: 1,
          },
        ],

      ]);
    } 


export { settingFields };