import { me } from 'api/users';
import { checkMe } from 'api/webapp/subscriber';
const SET_TOKEN = 'SET_TOKEN';
const SET_SUB_TOKEN = 'SET_SUB_TOKEN';
const CLEAR_TOKEN = 'CLEAR_TOKEN';
const CLEAR_SUB_TOKEN = 'CLEAR_SUB_TOKEN';
const SET_CURRENT_USER_DATA = 'SET_CURRENT_USER_DATA';
const SET_CURRENT_SUB_DATA = 'SET_CURRENT_SUB_DATA';
const LOG_OUT = 'LOG_OUT';
const SUB_LOG_OUT = 'SUB_LOG_OUT';

const setDashboardToken = payload => {
  return {
    type: SET_TOKEN,
    payload: payload
  };
};

const setSubscriberToken = payload => {
  return {
    type: SET_SUB_TOKEN,
    payload: payload
  };
};

const clearToken = {
  type: CLEAR_TOKEN
};

const clearSubToken = {
  type: CLEAR_SUB_TOKEN
};

const subLogOut = () => ({
  type: SUB_LOG_OUT
});

const logOut = () => ({
  type: LOG_OUT
});

const setCurrentUserData = () => dispatch => (
  me()
    .then(res => {
      res && dispatch({
        type: SET_CURRENT_USER_DATA,
        payload: res.data
      });
    })
);

const setCurrentSubData = () => dispatch => (
  checkMe()
    .then(res => {
      res && dispatch({
        type: SET_CURRENT_SUB_DATA,
        payload: res.data
      });
    })
);

export {
  LOG_OUT,
  SET_TOKEN,
  SET_SUB_TOKEN,
  CLEAR_SUB_TOKEN,
  CLEAR_TOKEN,
  SET_CURRENT_USER_DATA,
  SET_CURRENT_SUB_DATA,
  SUB_LOG_OUT,
  logOut,
  setDashboardToken,
  setSubscriberToken,
  clearToken,
  setCurrentUserData,
  setCurrentSubData,
  subLogOut,
  clearSubToken
};
