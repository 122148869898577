import React, { useCallback, useEffect, useReducer } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty } from 'underscore';
import { ROLES } from "config/permissions";
/* API */
import { filterFetcher as permitTypeFetcher } from 'api/permit_types';
/* Base */
import Button from 'components/base/button';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import Loader from 'components/helpers/loader';
/* Components */
import ToolBar from './toolbar';
import PermitModal from '../permitModal';
import TableData from './tableData';
/* Styles and Assets */
import styles from './index.module.sass'
import { ReactComponent as PenToSquareIcon } from 'assets/pen-to-square.svg'

const initialState = {
  modalType: null,
  permitType: {},
  isFetching: true,
}

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'modalType':
      return {...state, modalType: payload }
    case 'permitType':
      return {...state, permitType: payload, isFetching: false } 
    default:
      return state
  }
}

const Index = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const openModal = useCallback((value) => {
    setState('modalType', value)
  },[setState])

  const setPermitType = useCallback((data) => setState('permitType', data), [setState]);

  useEffect(() => {
    const fetchPermitType = async () => {
      const response = await permitTypeFetcher({query: {name: 'Carpool Permit'}})
      setState('permitType', response.data[0])
    }
    fetchPermitType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(state.isFetching) return <Loader />

  return (
    <AccessControl
      currentRole={props.currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar showTotal={!isEmpty(state.permitType)} />
          </Col>
          <Col xs={12} className={`p-0 d-flex justify-content-center flex-column`}>
            {
              isEmpty(state.permitType)
                ? (
                  <div className={`${styles.section} d-flex align-items-center flex-column pt-3`}>
                    <p className='mt-4'>
                      {'You haven’t created a carpool permit type yet. Click on the button below to begin.'}
                    </p>
                    <p className='mt-5'>
                      <Button
                        icon={<PenToSquareIcon />}
                        onClick={() => openModal('new_permit_type')}
                        className={`${styles.button}`}
                      >
                        <span>Create Permit type</span>
                      </Button>
                    </p>
                  </div>)
                : (
                  <TableData {...props} openModal={openModal} modalType={state.modalType} />
                )
            }
          </Col>
        </Row>
        <PermitModal 
          modalType={state.modalType} 
          openModal={openModal} 
          setPermitType={setPermitType}
        />
      </Container>
    </AccessControl>
  )
}

export default withCurrentUser(Index)
