import { UsersEnrolledActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(UsersEnrolledActions);

const UserEnrolledReducers = combineReducers({
  index,
  records,
  filters
});

export default UserEnrolledReducers;