import React, { useCallback } from "react";
import styles from "./index.module.sass";
import { IndexTable } from "components/base/table";
import TableRow from "./tableRow";

const TableData = (props) => {
  const { list, setSelectedModule, selectedModule } = props;

  const renderRecords = useCallback(() => {
    return list.map((record) => <TableRow key={record} record={record} selectedModule={selectedModule} setSelectedModule={setSelectedModule}/>);
  }, [list, selectedModule, setSelectedModule]);

  return (
    <IndexTable
      {...props}
      className={styles.table}
      isFetching={() => false}
      total={list.length}
      perPage={20}
      page={1}
      columns={
        <React.Fragment>
          <th attr="module_name" style={{ width: "100%", minWidth: "210px" }}>
            Module Name
          </th>
          <th
            className="d-flex justify-content-center"
            attr="actions"
            style={{ width: "20%", minWidth: "210px" }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="Audit Logs Modules"
    />
  );
};

export default TableData;