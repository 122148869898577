import { FieldType } from "components/helpers/form_fields";

const sendFields = (attrs) => {
  const { customInputClass='', customLabelClass='', permitTypes=[], broadcastCategories=[], newSelected } = attrs;
  return [
    [
      {
        name: 'recipient',
        label: 'Recipients',
        customInputClass,
        customLabelClass,
        type: FieldType.SELECT_FIELD,
        options: [
          { value: "permit_holders", label: "Permit Holders" },
          { value: "specific_users", label: "Specific Users" },
          { value: "all_subscribers", label: "All Subscribers" }
        ],
      },
      {
        name: 'permit_type_ids',
        label: "Permit Type(s)",
        customInputClass,
        customLabelClass,
        type: FieldType.MULTISELECT_FIELD,
        options: permitTypes,
        showToolTip: false
      },
      {
        name: 'user_emails',
        label: 'User Emails',
        customInputClass,
        customLabelClass,
      },
      {
        name: 'category_id',
        label: "Category",
        customInputClass,
        customLabelClass,
        type: FieldType.SELECT_FIELD,
        options: newSelected ? broadcastCategories : [...broadcastCategories, {label: 'Add New', value: 'add_new'}]
      },
      {
        name: 'title',
        label: "Title",
        customInputClass,
        customLabelClass,
        type: FieldType.TEXT_AREA,
        rows: 3
      },
      {
        name: 'message',
        label: "Message",
        customInputClass,
        customLabelClass,
        type: FieldType.TEXT_AREA,
        rows: 3
      },
      {
        name: 'schedule_date_and_time[date]',
        label: "Schedule Date",
        min: new Date(),
        customInputClass,
        customLabelClass,
        type: FieldType.SINGLE_DATE_FIELD,
      },
      {
        name: 'schedule_date_and_time[time]',
        label: "Schedule Time",
        customInputClass,
        customLabelClass,
        type: FieldType.SINGLE_HOUR_FIELD,
      }
    ]
  ]
}

export {sendFields};