import React from 'react'
import PropTypes from 'prop-types'
import { Col, FormGroup, Row } from 'reactstrap'
import { Form } from 'informed'
/* Helpers */
import { FieldType } from 'components/helpers/form_fields'
/* Base */
import { renderInput } from 'components/base/forms/common_form'
/* Assets */
import styles from './index.module.sass'

class SettingForm extends React.Component {

  fieldProps = {
    events: {
      onChange: () => this.props.save()
    }
  }

  renewalField = { 
    name: 'auto_approve_renewal',
    type: FieldType.SWITCH_FIELD,
    positiveText: '',
    negativeText: '',
    customToggleClass: styles.toggle,
    customToggleOnClass: styles.toggleOn,
  }

  extensionField = { 
    name: 'auto_approve_extension',
    type: FieldType.SWITCH_FIELD,
    positiveText: '',
    negativeText: '',
    customToggleClass: styles.toggle,
    customToggleOnClass: styles.toggleOn,
  }

  fields = [
    { name: 'open_violation_for_renewal', label: 'No open Violations', type: FieldType.CHECKBOX_FIELD, className: ''},
    { name: 'prior_violation_for_renewal', label: 'No prior Violations', type: FieldType.CHECKBOX_FIELD, className: ''},
    { name: 'open_violation_for_extension', label: 'No open Violations', type: FieldType.CHECKBOX_FIELD, className: ''},
    { name: 'prior_violation_for_extension', label: 'No prior Violations', type: FieldType.CHECKBOX_FIELD, className: ''},
  ]

  renderRenewalFields = () => {
    return this.fields.slice(0,2).map(field => this.renderCheckBoxField(field, this.fieldProps))
  }

  renderExtensionFields = () => {
    return this.fields.slice(2,4).map(field => this.renderCheckBoxField(field, this.fieldProps))
  }

  renderCheckBoxField = (field, props) => {
    return (
      <FormGroup key={field.name.concat(field.label)} className='d-flex align-items-center mb-0'>
        {renderInput(field, {...props})}
        <label className={`${styles.formLabel}`} htmlFor={field.name}>{field.label}</label>
      </FormGroup>
    )
  };

  render() {
    const initState = this.props.record;

    return (
      <Form 
        initialValues={{...initState}} 
        className='w-100 d-flex'
        getApi={this.props.setFormApi}
      >
        <Row className='w-100 d-flex m-0'>
          <Col className='col-6'>
            <div className={`${styles.switchWrapper}`}>
              <span className='mr-2'>Automatically approve permit renewal applications</span>
              {
                renderInput(
                  this.renewalField, 
                  {...this.fieldProps, 
                  positiveText: '',
                  negativeText: '',
                  customToggleClass: styles.toggle,
                  customToggleOnClass: styles.toggleOn
                })
              }
            </div>
            <p className={`${styles.conditionTitle} mb-0`}>Conditions</p>
            {this.renderRenewalFields()}
          </Col>
          <Col className='col-6'>
            <div className={`${styles.switchWrapper}`}>
              <span className='mr-2'>Automatically approve permit extension applications</span>
              {
                renderInput(
                  this.extensionField, 
                  {...this.fieldProps, 
                  positiveText: '',
                  negativeText: '',
                  customToggleClass: styles.toggle,
                  customToggleOnClass: styles.toggleOn
                })
              }
            </div>
            <p className={`${styles.conditionTitle} mb-0`}>Conditions</p>
            {this.renderExtensionFields()}
          </Col>
        </Row>
      </Form>
    )
  }
}

SettingForm.propTypes = {
  record: PropTypes.object,
  save: PropTypes.func,
  setFormApi: PropTypes.func,
};

export default SettingForm
