import { BootingsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(BootingsActions);

const BootingsReducers = combineReducers({
  index,
  records,
});

export default BootingsReducers;
