import { EmailsActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records } = reduceEntity(EmailsActions);

const EmailsReducers = combineReducers({
  index,
  records
});

export default EmailsReducers;
