import React, { useRef } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { Input } from 'reactstrap';
import SearchBox from 'components/modules/google_maps/search_box'
import extractGeocodeInfo from 'components/modules/google_maps/extract_geocode_info'
import env from '.env';
import styles from './google_maps.module.sass'
import { isEmpty } from 'underscore';

var marker = null

const getCustomMarkerIconUrl = (spaces) => `https://mt.google.com/vt/icon/text=${spaces? spaces : ''}&psize=9&font=fonts/arialuni_t.ttf&color=FF800000&name=icons/spotlight/spotlight-waypoint-b.png&ax=44&ay=48&scale=1.2`;

const createMarker = (google, map, position, markerName) => {
  deleteMarker()
  marker = new google.maps.Marker({ position, map, title: markerName, icon: getCustomMarkerIconUrl('blue')});
}

const deleteMarker = () => {
  if(marker) {
    marker.setMap(null)
  }
}

const GoogleMapsContainer = (props) => {
  const { lat, lng, markerName, google, setGoogle, search, selectedLocation, parkingLots } = props
  const googleLocation =  { lat, lng }
  const geocoder = new google.maps.Geocoder();
  let inputRef = useRef(null)
  var points = []
  
  if(!isEmpty(parkingLots)){
     // eslint-disable-next-line
    parkingLots.map((lot) => {
     points.push({lat: lot.location.ltd, lng: lot.location.lng})
    })
  }
  
  var bounds = new google.maps.LatLngBounds();
  if(!isEmpty(points)){for (var i = 0; i < points?.length; i++) {
    bounds.extend(points[i]);
  }}

  setGoogle(google)
  const onReady = (map) => {
    if (map || search) {
      createMarker(google, map, googleLocation, markerName)
    }
  }

  const mapClicked = (map, clickEvent) => {
    const { lat, lng } = clickEvent.latLng
    createMarker(google, map, {lat: lat(), lng: lng()}, markerName)
    geocoder.geocode({
      'latLng': clickEvent.latLng
    },(results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        props.events.onMapClick(Object.assign({}, extractGeocodeInfo(results), {
          ltd: lat(),
          lng: lng()
        }), results[0].formatted_address)
      }
    });
  }

const searchReff = {current: {value: search} } 

  return (
    <div className={styles.google_maps_container} >
      <Input innerRef={inputRef} className="form-control" id={styles.searchBox} type="text" placeholder="Search location" onChange={onReady || mapClicked}/>
      <Map
          google={google}
          initialCenter={googleLocation}
          onClick={mapClicked}
          center={selectedLocation || { lat: 37.7749, lng: -122.4194 }} // Center on selectedLocation if available
          onReady={onReady}
          bounds={bounds}
        >
          {selectedLocation && parkingLots && 
           parkingLots?.map((lot) => {
            return <Marker title={lot?.name}
              name={lot?.name} icon={{
              url: getCustomMarkerIconUrl('orange', `${lot.available}/${lot.capacity}`), // Change the color here
            }}  position={{lat: lot?.location?.ltd, lng: lot?.location?.lng}}>
              </Marker>
           })
          
          }
          <SearchBox  searchInputRef={inputRef? inputRef : searchReff} />
      </Map>

    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: env.google_cloud_api_key
})(GoogleMapsContainer)
