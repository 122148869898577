import React from 'react'
import { Col, Container } from 'reactstrap';
import Button from 'components/base/button';
import styles from './index.module.sass'

const ActivateDeactivate = ({toggleStatus, closeModal, record, isSaving}) => {

  const message = () => <React.Fragment>
    <span className='mr-1'>The</span>
    <span className={styles.boldText}>{record.name}</span>
    <span className='mx-1'>parking rule will be</span>
    <span>{`${record.status === false ? 'activated' : 'deactivated' }`}</span>
    <span className='mx-1'>immediately.</span>
  </React.Fragment>

  return (
    <Container className='p-0'>
      <Container className={styles.content} >
        <Col className='mb-4'>
          { message() }
        </Col>
        <Col className="d-flex justify-content-center my-2">
          <Button onClick={() => closeModal()} type='button' className={`${styles.button} ${styles['button-bg-light']} mr-4`}>
            Cancel
          </Button>
          {
            record.status === false && 
            <Button 
              type='button' 
              onClick={() => {toggleStatus({status: true}); closeModal()}} 
              className={`${styles.button} ${styles['button-bg-primary']}`}
              disabled={isSaving}
            >
              Activate
            </Button>
          }
          {
            record.status === true &&
            <Button 
              type='button' 
              onClick={() => {toggleStatus({status: false}); closeModal()}} 
              className={`${styles.button} ${styles['button-bg-danger']}`} 
              disabled={isSaving}
            >
              Deactivate
            </Button>
          }
        </Col>
      </Container>
    </Container>
  )
}

export default ActivateDeactivate;
