import React, {useCallback} from 'react'
import PropTypes from 'prop-types';
import { filterFields } from 'components/helpers/fields/event_parking_applications/index';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';
import { filterFetcher } from 'api/parking_sessions';

const TableData = (props) => {
  
  const { list, setRecord, filters, isResourceFetching, openModal, modalType, selectedRecord, activeTab } = props


  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        {...props}
        key={record.id} 
        record={record} 
        selectedRecordId={selectedRecord.id} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    });
  },[list, modalType, setRecord, openModal,selectedRecord.id, props])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <React.Fragment>
          <th attr="id" style={{ width: '10%', minWidth: 100 }}>Session ID</th>
          <th attr="check_in" style={{ width: '15%', minWidth: 120 }}>Check-In Date</th>
          <th attr="check_out" style={{ width: '15%', minWidth: 250 }}>Check-Out Date</th>
          <th attr="status" style={{ width: '15%', minWidth: 100 }}>Status</th>
          <th attr="plate_number" style={{ width: '15%', minWidth: 100 }}>Vehicle LPN</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Parking Sessions'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData