import React, { useCallback, useContext, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
/* API */
import { update } from 'api/disputes';
/* Actions */
import { SET_LIST_ELEMENT } from 'actions/disputes';
/* Components */
import View from '../view';
/* Styles/Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, statuses: [] }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'statuses':
      return { ...state, statuses: payload }
    default:
      return { ...state };
  }
}

const DisputeModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const { record, openModal, modalType } = props
  const dispatchAction = useDispatch();

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'view':
        return { width: '680px' };
      default:
        return { width };
    }
  },[modalType])
      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
    setState('innerModal', null)
  }, [openModal, setState])
        
  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const updateStatus = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({id: record, data: { dispute: data}})
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text:  response.data.status ==="solved" ? (response.data.dispute_type === "billing"  ? `Dispute Solved. Refund Amount $${response.data?.refund_amount} refunded to ${response.data.username}`: "Dispute Solved.") : "Changes Saved",
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, closeModal, setListElement, record])
 
  return (
    <Modal 
      isOpen={!!modalType} 
      centered contentClassName={styles.modal} 
      style={{maxWidth: modalProps().width, width: '100%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'view' &&  
          <View 
            record={record} 
            {...state}
            closeModal={closeModal}
            updateStatus={updateStatus}
            setState={setState}
          /> 
        }
      </ModalBody>
    </Modal>
  )
}

export default DisputeModal
