import React, { useState } from "react";
import styles from "./shared.module.sass";
import Button from 'components/base/button';
import { Col, Spinner } from 'reactstrap';
import { Select } from "antd";
import {ReactComponent as RemoveIcon} from 'assets/close_icon.svg'
import { useTranslation } from "react-i18next";

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};

const SharePermit = ({applyPermitApplication, formApiRef, isSaving, setCloseAllModal, base64IdProof}) => {
  const [input, setInput] = useState("");
  const [emails, setEmails] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [error, setError] = useState("");
  const [usersCount, setUsersCount] = useState()
  const { t } = useTranslation()

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    setError("");
  };


  const submitEmailValues = () => {  
    const {values} = formApiRef.current.getState()
    const data = {
      category: values?.category,
      permit_type_id: values?.permit_type_id,
      validity_period: values?.period?.toString(),
      total_cost: values?.total_cost?.toString(),
      start_date: values?.applied_from_date,
      shared : 'true',
      shared_user_emails: emails,
      primary_user_attributes: {
        vehicle_lpn: values?.plate_number,
        full_name: values?.full_name,
        id_type:  values?.id_type,
        id_number: values?.id_number,
        home_phone: values?.home_phone,
        work_phone: values?.work_phone,
        email: values?.email,
        home_address: values?.address1,
        birthday: values?.birthday,
        id_proof: base64IdProof
      },
    }
    setCloseAllModal(true)
    applyPermitApplication(data)
  }

  const postSharedPermit = () => {  
    const {values} = formApiRef.current.getState()
    const data = {
      category: values?.category,
      permit_type_id: values?.permit_type_id,
      validity_period:values?.period?.toString(),
      total_cost: values?.total_cost?.toString(),
      start_date: values?.applied_from_date,
      shared : 'true',
      no_of_invited_users: usersCount,
      primary_user_attributes: {
        vehicle_lpn: values?.plate_number,
        full_name: values?.full_name,
        id_type:  values?.id_type,
        id_number: values?.id_number,
        home_phone: values?.home_phone,
        work_phone: values?.work_phone,
        email: values?.email,
        home_address: values?.address1,
        birthday: values?.birthday,
        id_proof: base64IdProof
      },
    }
    setCloseAllModal(true)
    applyPermitApplication(data)
  }
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === "," || key === "Enter") && trimmedInput.length) {
      e.preventDefault();

      if (validateEmail(trimmedInput)) {
        if (!emails.includes(trimmedInput)) {
          if (emails.length < 5) {
            setEmails((prevState) => [...prevState, trimmedInput]);
            setInput("");
            setError("");
          } else {
            setError(t("permit_application.apply_for_permit.share_permit.only_five_allowed"));
          }
        } else {
          setError(t("permit_application.apply_for_permit.share_permit.already_exists"));
        }
      } else {
        setError(t("permit_application.apply_for_permit.share_permit.invalid_email"));
      }
    }

    if (key === "Backspace" && !input.length && emails.length && isKeyReleased) {
      const emailsCopy = [...emails];
      const poppedTag = emailsCopy.pop();
      e.preventDefault();
      setEmails(emailsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setEmails((prevState) => prevState.filter((tag, i) => i !== index));
  };

  return (
    <div className={styles.App}>
      <p className={styles.title}>{t("permit_application.apply_for_permit.share_permit.title")}</p>
      <p className={styles.subText}>{t("permit_application.apply_for_permit.share_permit.instruction1")}</p>
      <p className={styles.subText1}>{t("permit_application.apply_for_permit.share_permit.instruction2")}</p>
      <div className={error? `${styles["tag-container"]} ${styles.invalidTagContainer}` : emails.length === 5 ? `${styles["tag-container"]} ${styles.validTagContainer}`  : `${styles["tag-container"]}`}>
        {emails.map((tag, index) => (
          <div key={index} className={styles.tag}>
            {tag} <span className={styles.remove} onClick={() => deleteTag(index)}><RemoveIcon className={styles.removeicon}/></span>
          </div>
        ))}
        <input
          className={styles["tag-container__input"]}
          value={input}
          placeholder={t("permit_application.apply_for_permit.share_permit.email_placeholder")}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={onChange}
        />
      </div>
      <p className={styles.note}>{t("permit_application.apply_for_permit.share_permit.note")}</p>
      {error && <p className={styles.error}>{error}</p>}
      <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
        <Button type='button' className={styles.button} onClick={() => submitEmailValues()}>{isSaving ? btnSpinner({ className: 'spinner-border' }) : t("buttons.submit")}</Button>
      </Col>
      <span className={styles.line} style={{position:"relative"}}><hr style={{backgroundColor:"grey"}} /></span>
      <p className={styles.note2}>{t("permit_application.apply_for_permit.share_permit.note2")}</p>
      <p className={styles.note3}>{t("permit_application.apply_for_permit.share_permit.note3")}</p>
      <Col className={`m-0 p-0 ${styles['fieldset-double']} pb-4 pt-1`} xs={12}>
        <Select
          showSearch
          placeholder={t("permit_application.apply_for_permit.share_permit.select_placeholder")}
          optionFilterProp="children"
          onChange={(e) => setUsersCount(e)}
          value={usersCount}
          className={styles.numInput}
          options={[1,2,3,4,5].map((value) => {return {value: value, label: value}})}
        />
        <Button type='button' className={styles.postBtn} onClick={() => postSharedPermit()} >{isSaving ? btnSpinner({ className: 'spinner-border' }) : t("permit_application.apply_for_permit.share_permit.post_btn")}</Button>
      </Col>
    </div>
  );
};

export default SharePermit;


