import React, { useCallback, useState } from 'react';
import { ROLES } from "config/permissions";
/* Actions */
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD} from 'actions/disputes';
/* API */
import { filterFetcher } from 'api/disputes';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from "components/helpers/access-control";
/* Components */
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DisputeModal from '../dispute_modal';


const Index = props => {
  const [modalType, setModalType] = useState(null)
  const [activeTab, setActiveTab] = useState(null);

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  const { isResourceFetching, currentUser } = props;

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            {...props}
            resource={resource}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFetching={isResourceFetching}
          />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
            />
          </Col>
        </Row>
        <DisputeModal 
          {...props}
          modalType={modalType} 
          openModal={openModal} 
        />
      </Container>
    </AccessControl>
  )
  
};

const resource = 'dispute';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.dispute.filters,
    record: state.dispute.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);


