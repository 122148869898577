import React, { useEffect, useState, useMemo } from 'react'
import { map } from 'underscore';
import styles from './index.module.sass'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, disableHighlight, idx, width } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx !== 0 ? 'border-left' : ''}`}
      style={{cursor: `${disableHighlight && 'not-allowed'}`, width: width}}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = ({record, openModal, modalType, setSelectedRecord, selectedRecord}) => {

  const actions = [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => {openModal('show')},
      text: 'View',
      isActive: modalType === 'show' && selectedRecord?.id === record.id,
      width: '60px'
    },
    { 
      icon: <EditIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      onClick: () => {openModal('edit_staff')},
      text: 'Edit',
      isActive: modalType === 'edit_staff' && selectedRecord?.id === record.id,
      width: '50px'
    },
    {
      icon: <TrashIcon className='mx-3'/>,
      highlightedClass: `${styles['background-danger']} mx-3 text-white`,
      onClick: () => {openModal('delete_staff')},
      text: 'Delete',
      isActive: modalType === 'delete_staff' && selectedRecord?.id === record.id,
      width: '65px'
    },
  ]


  const statusColor = useMemo(() => {
    if(!record?.status) return;
    const colorMapping = {
      active: 'success',
      suspended: 'danger',
    }
    return colorMapping[record?.status?.toLowerCase()] || 'default'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record?.status])
  
  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td>{record?.name}</td>
      <td>{record?.username}</td>
      <td>{record?.email}</td>
      <td>{record?.role?.name}</td>
      <td>
      <td>
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`} style={{marginTop: '2px'}}/>
          <span >{record?.status.toUpperCase() }</span>
        </div>
      </td>
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;
