

import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";

const token = localStorage.getItem('SUB_TOKEN');

const getParkingLots = (data) => {
  const {ltd, lng, permit_type_id, zipcode} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/permit_applications/nearby_parkings?permit_type_id=${permit_type_id}&location_radius[ltd]=${ltd ? ltd : 38.76986616134441 }&location_radius[lng]=${lng ? lng : -76.07369071960447}&zipcode=${zipcode}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}


  const checkParkingLot = (data) => {
    const {id, name} = data
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_applications/${id}/check_parking_lot?permit_type_name=${name}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  const getPermitType = (id) => {
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_types/${id}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  const getVehicleDetails = (plate_number) => {
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/mtas/fetch_details?plate_number=${plate_number}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  const getTotalCost= (data) => {
    const {permit_type_id, validity_period} = data
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_applications/total_cost?permit_type_id=${permit_type_id}&validity_period=${validity_period? validity_period : ''}`;
    const options = {
      method: "POST",
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  const getOwnerDetails = (data) => {
    const {id_type, id_number} = data

    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/users/fetch_details?id_type=${id_type}&id_number=${id_number}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }
  const applyForPermitApplication = (params = {}) => {
    const { data = {} } = params;
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_applications/apply`;
    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  const createPermitApplication = (params = {}) => {
    const { data = {} } = params;
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_applications`;
    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      data: data
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }
  

  const fetchVehicleData = (plate_number) => {
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/vehicles?vehicle[plate_number]=${plate_number}&vehicle[user_associated]=1`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }
  const fetchStatesList = (country) => {
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/dropdowns/states_list?country_code=${country}`;
    const options = {
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  const validateStep2 = (data) => {
    const { plate_number, email} = data
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_applications/validate_step_2?email=${email}&plate_number=${plate_number}`;
    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }


  const applyCoupon = (data) => {
    let url = new URL(env.backend_url);
    url = `${url.protocol}//${url.host}/api/v1/permit_applications/apply_coupon`;
    const options = {
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    }
    return withApiCatch(
      axios(Object.assign(options))
    )
  }

  

export { getParkingLots,checkParkingLot, getPermitType,getVehicleDetails, getTotalCost,getOwnerDetails,applyForPermitApplication , fetchVehicleData, fetchStatesList, validateStep2, createPermitApplication, applyCoupon};
