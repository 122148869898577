import React, { useCallback, useEffect, useMemo, useState } from 'react'
/* Actions */
import { SET_SINGLE_RECORD } from 'actions/violation_types';
/* Base */
import { map } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
/* Styles/Assets */
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ReactComponent as ParkingIcon } from 'assets/parking-icon.svg';
import styles from './index.module.sass';
import { capitalize } from 'components/helpers';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])
  
  return (
    <div
      onMouseEnter={() => { if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx !== 0 ? 'border-left' : ''}`}
    >
      <span className={`${highlightedClass} ${showText ? 'd-inline-block' : 'd-none'}`}>{text}</span>
      <span className={`${showText ? 'd-none' : 'd-inline-block'}`}>{icon}</span>
    </div>
  )
}

const TableRow = (props) => {
  const { record, openModal, modalType } = props
  const selectedRecordId = useSelector(state => state.violation_type.records.record?.id)
  const dispatch = useDispatch()
  const setRecord = useCallback((data) => {
    dispatch({type: SET_SINGLE_RECORD, payload: data})
  },[dispatch])
  const parkingLots = record?.parking_lots
  const actions = useMemo(() => {
    return [
      { 
        icon: <ParkingIcon className='mx-3' style={{width: 16, height: 16}}/>,
        highlightedClass: `${styles['background-primary']} mx-3 text-white`,
        onClick: () => {openModal('lots')},
        text: 'Lots',
        isActive: modalType === 'lots' && selectedRecordId === record.id,
        disableHighlight: parkingLots?.length < 1
      },
      { 
        icon: <EditIcon className='mx-3'/>,
        highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
        text: 'Edit',
        onClick: () => {openModal('edit')},
        isActive: modalType === 'edit' && selectedRecordId === record.id
      },
      { 
        icon: <TrashIcon className='mx-3'/>,
        highlightedClass: `${styles['background-danger']} mx-3 text-white`,
        onClick: () => {if(record.status === 'inactive') openModal('delete')},
        text: 'Delete',
        isActive: modalType === 'delete' && selectedRecordId === record.id,
        disableHighlight: record.status === 'active'
      }
    ]
  },[record.id, record.status, openModal, modalType, selectedRecordId, parkingLots])

  return (
    <tr onClick={() => setRecord(record)}>
      <td className=''>{record.display_name}</td>
      <td>
        <div className='d-flex align-items-center'>
          <span className={`${styles.icon} ${styles[`icon-${record.status === 'active' ? 'success' : 'danger'}`]} mr-2`}> </span>
          <span >{record.status && capitalize(record.status)}</span>
        </div>
      </td>
      <td>{record.parking_lots?.length || 0}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow;
