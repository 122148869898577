import fetchApi from 'components/modules/fetch_api';

const createMta = (data) => {
  return fetchApi('dashboard/mtas', { method: 'POST', data })  
}

const getDetails = (params) => {
  return fetchApi('dashboard/mtas/fetch_details', { method: 'GET', params });
};

const showAdminDetails = () => {
  return fetchApi('dashboard/mtas/show_admin_details', {});
}
export { createMta, getDetails, showAdminDetails };
