import { capitalize } from 'components/helpers';
import React, { useEffect, useMemo, useState } from 'react'
import { map } from 'underscore';
/* Styles and Assets */
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
import { useSelector } from 'react-redux';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className='d-flex align-items-center justify-content-center'
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = ({record, openModal, modalType, setRecord}) => {
  const { status } = record;

  const statusColor = useMemo(() => {
    if(!status) return;
    const colorMapping = {
      rejected: 'rejected',
      active: 'active',
      pending: 'pending',
      inactive: 'inactive'
    }
    return colorMapping[status?.toLowerCase()] || 'default'
  },[status])
  const selectedRecordId = useSelector(state => state.vehicles.records.record?.id)

  const actions = [
    { 
      icon: <EyeIcon className={`${styles['icon-eye']} mr-3`}/>,
      highlightedClass: `${styles['background-primary']} mr-3 text-white`,
      onClick: () => {openModal('show')},
      text: 'View',
      isActive: modalType === 'show' && selectedRecordId === record.id,
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record.created_at_parsed}</td>
      <td>{record.plate_number}</td>
      <td>{`${capitalize(record.category)} Vehicle`}</td>
      <td>{capitalize(record.vehicle_type)}</td>
      <td>{record.manufacturer?.name}</td>
      <td>{record.user?.email}</td>
      <td>{record.user?.first_name}</td>
      <td>{record.user?.last_name}</td>
      <td>
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`}> </span>
          <span >{status && capitalize(status)}</span>
        </div>
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;
