import React from "react";
import { Col, Container, Row } from "reactstrap";
import {
  SET_LIST,
  SET_FILTERS,
  SET_LIST_ELEMENT,
  SET_SINGLE_RECORD,
} from "actions/charging_sessions";
import { active as filterFetcher } from "api/charging_sessions";
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
import { connect } from "react-redux";
import TableData from "./table_data";
import { invoke } from "actions";
import { bindActionCreators } from "redux";

const ActiveSessions = (props) => {

  return (
    <Container className="mw-100 p-0">
      <Row className="m-auto">
        <Col xs={12} className="p-0">
          <TableData
            {...props}
          />
        </Col>
      </Row>
    </Container>
  );
};

const resource = "charging_sessions";

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setFilters: invoke(SET_FILTERS), 
      setRecord: invoke(SET_SINGLE_RECORD) 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.charging_sessions.filters,
    selectedRecord: state.charging_sessions.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(ActiveSessions)))
);