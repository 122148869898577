import React, { useState, useEffect } from "react";

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) setShowText(false)}}
      className='d-flex align-items-center justify-content-center'
      style={{cursor: disableHighlight && 'not-allowed'}}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}


export default Action;