import { FieldType } from "components/helpers/form_fields";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass='', parkingLots=[], customInput1Class=''} = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      suspended: 'red',
      active: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'name',
      label: 'Full Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'username',
      label: 'User Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: "parking_lot_id",
      label: "Assigned Parking Lot",
      customInputClass: customInput1Class,
      customLabelClass,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: parkingLots.map((parkingLot) => {
        return { value: parkingLot.value, label: parkingLot.label }
      }),
      showToolTip: false,
      mandatory: true,
    },
    {
      name: 'role_type',
      label: 'Role',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: "valet_attendant", label: "Valet Attendant" },
        { value: "valet_manager", label: "Valet Manager" },
      ],
      customInputClass: customInput1Class,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'status',
      label: 'Current Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      mandatory: true,
      options: [{ value: 'active', label: 'Active' },
                { value: 'suspended', label: 'Suspended' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass: customInput1Class,
      customLabelClass,
    },
    {
      name: 'password',
      label: 'Password',
      type: FieldType.PASSWORD_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
  ]
}

const filterFields = (attrs) => {
  const {customInputClass, customLabelClass, parkingLots} = attrs
  return [
    {
      name: "role_type",
      label: "Role Names",
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: "Choose a role",
      options: [
        { value: "valet_attendant", label: "Valet Attendant" },
        { value: "valet_manager", label: "Valet Manager" },
      ],
      customInputClass,
      customLabelClass,
    },
    {
      name: "status",
      label: "Status",
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: "Choose a status",
      options: [
        { value: "active", label: "Active" },
        { value: "suspended", label: "Suspended" },
      ],
      customInputClass,
      customLabelClass,
    },
    {
      name: "parking_lot_ids",
      label: "Assigned Lot",
      type: FieldType.MULTISELECT_FIELD,
      options: parkingLots,
      showToolTip: false,
      customInputClass,
      customLabelClass,
    },
  ];
};

export { newFields, filterFields };