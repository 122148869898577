import React, { useCallback, useRef } from "react";
/* Helpers */
import { filterFields } from "components/helpers/fields/agencies";
/* Base */
import { renderFieldsWithGrid } from "components/base/forms/common_form";
import Button from "components/base/button";
/* API */
import { filterFetcher } from "api/agencies";
/* Components */
import { Form } from "informed";
import { Col } from "reactstrap";
/* Assets */
import styles from "./index.module.sass";
import { useSelector } from "react-redux";
import { list as selectList } from "selectors/list";

const FilterForm = (props) => {
  const { agency_type, agency_manager, setFilters, match, setList, startFetching } = props;

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const filters = useSelector((state) => state.admin.filters);

  const attrs = {
    agency_type,
    agency_manager,
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
  };

  const filterAdmins = useCallback(
    async (filters = {}) => {
      startFetching(
        filterFetcher(Object.assign({ ...match.params }, { filters }))
      )
        .then((res) => {
          setList(selectList(res));
        })
        .catch((error) => console.log(error));
    },
    [setList, startFetching, match.params]
  );

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
    };
    setFilters(filters);
    filterAdmins(filters);
  };

  const resetFilters = () => {
    formApiRef.current.setValues({
      name: '',
      id: '',
      status: '',
      agency_type: '',
      manager_id: ''
    });
    setFilters({});
    filterAdmins({});
  };

  const initialValues = {
    ...filters,
  };

  return (
    <Form
      initialValues={initialValues}
      getApi={setFormApi}
      className={styles.form}
      onSubmit={save}
    >
      {({ formState }) => {
        return (
          <React.Fragment>
            <div lg={12}>
              {renderFieldsWithGrid(filterFields({ ...attrs }), 3, 4, {
                iSize: 6,
                lSize: 5,
              })}
            </div>
            <Col className="d-flex justify-content-center pt-3 m-0" xs={12}>
              <Button
                className={`${styles.btn} ${styles["background-primary"]} mr-5`}
                type="submit"
              >
                Filter
              </Button>
              <Button
                type="button"
                onClick={resetFilters}
                className={`${styles.btn} ${styles["background-secondary"]}`}
              >
                Reset
              </Button>
            </Col>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

export default FilterForm;