import React, { useMemo } from 'react'
import { isEmpty, sortBy } from 'underscore'
/* Base */
import { renderField } from 'components/base/forms/common_form'
import ErrorWrapper from 'components/base/forms/common_form/error'
/* Helpers */
import { FieldType } from 'components/helpers/form_fields'
/* Styles */
import styles from './index.module.sass'


const ParkingLot = ({lot, resetSelectedSlot}) => {
  const fieldProps = {
    lSize: 3, iSize: 9,
    events: {
      onChange: () => { resetSelectedSlot(lot.id) }
    }
  }

  const field = (slots, lot) => ({
    label: lot.name,
    name: `lot-${lot.id}`, 
    list: slots.map(slot => ({ label: slot.name, value: slot.id})),
    type: FieldType.RADIO_BUTTON_FIELD,
    customLabelClass: styles.inputLabel,
    customInputClass: 'pt-3 text-left'
  })

  const slots = useMemo(() => {
    return sortBy(lot.slots, 'name')
  },[lot])

  if(isEmpty(slots)) return null
  return <>
    { renderField(field(slots, lot), { ...fieldProps }) }
  </>
}

const ParkingLots = ({parkingLots, resetSelectedSlot, errors}) => {
  return (
    <ErrorWrapper field={{name: 'parkingSlot'}} errors={errors} >
      {
        !isEmpty(parkingLots)
          && parkingLots.map(lot => (
              <ParkingLot
                key={lot.id}
                lot={lot}
                resetSelectedSlot={resetSelectedSlot}
              />
            ))
      }
    </ErrorWrapper>
  )
}
export default ParkingLots;
