import { DisabilitiesActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(DisabilitiesActions);

const DisabilitiesReducers = combineReducers({
  index,
  records,
  filters
});

export default DisabilitiesReducers;