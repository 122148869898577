import React from "react";
import { Col, Container } from "reactstrap";
/* Base */
import Button from "components/base/button";
/* Assets */
import styles from "./index.module.sass";

const Delete = ({
  state,
  deleteRewardProgram,
  record,
  closeModal,
}) => {
  const message = () => (
    <React.Fragment>
      <span className="mr-1">Deleting the</span>
      <span className={styles.boldText}>{record.name}</span>
      <span className="mx-1">
        Rewards Program will permanently remove it from the system. However,
        points already earned by users will still be redeemable. This operation
        cannot be undone.
      </span>
    </React.Fragment>
  );

  return (
    <Container className="p-0">
      <Container className={styles.content}>
        <Col className="mb-4">{message()}</Col>
        <Col className="d-flex justify-content-center my-2">
          <Button
            onClick={() => closeModal()}
            type="button"
            className={`${styles.button} ${styles['button-bg-light']} mr-4`}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={deleteRewardProgram}
            className={`${styles.button} ${styles['button-bg-danger']}`} 
            disabled={state.isDeleting}
          >
            Delete
          </Button>
        </Col>
      </Container>
    </Container>
  );
};

export default Delete;