import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";
import { generatePath } from 'react-router';
import fetchApi from 'components/modules/fetch_api';

const { index } = resourceApi("system_logs");
const search = searchApi("system_logs");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, module_name, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      module_name: module_name,
      ...filters,
    },
  });
};

const updateSettings = (params = {}) => {
  const { data = {} } = params;
  const { nestedParams } = data
  
  return fetchApi(generatePath(`dashboard/system_logs/update_settings`, nestedParams), {
    method: 'PUT',
    data,
  })
};

const logs = (params = {}) => {
  const { page, perPage, query, nestedParams = {}, attribute } = params;
  return fetchApi(
    generatePath(`dashboard/${attribute}/logs`, nestedParams),
    { method: 'GET', params: { page, per_page: perPage, ...query } }
  );
};

const filterLogs = (params = {}) => {
  const { page, perPage = 10, filters = {}, query, attribute } = params;

  if(attribute===undefined) return

  return logs({
    page,
    perPage,
    query: {
      ...query,
    },
    filters,
    attribute
  });
};

const showSettings = (params = {}) => {
  const { data = {} } = params;
  const { nestedParams } = data
  return fetchApi(generatePath(`dashboard/system_logs/show_settings?module_name=${data?.module_name}`, nestedParams), {
    method: 'GET',
    data,
  })
};

export { filterFetcher, index, search, updateSettings, filterLogs, showSettings };