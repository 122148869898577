import React, { useCallback, useContext, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { filterFetcher as emailIndex } from "api/emails";
import { change_status } from 'api/notifications';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import View from '../view';
import ActivateDeactivate from '../activate_deactivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'underscore';

const NotificationModal = (props) => {
  const { record, openModal, modalType,setListElement, setActiveTab, list, setList, formPage, setErrors } = props
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
    setErrors({})
  }, [openModal, setActiveTab, setErrors])

  const toggleStatus = useCallback(async (attrs) => {
    Object.assign(record, attrs)
    try {
      const response  = await change_status({id : record.id})
      closeModal()
      const message = `${response.data.event_name.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))} notification event is successfully ${response.data.status === 'active' ? 'activated' : 'deactivated'}`
      addAlertMessages([{
        type: response.data.status === 'active' ? 'primary' : 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record, addAlertMessages, setListElement, closeModal])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'view':
        return {title: `${record.title}`, maxWidth: '715px'}
      case 'activate_deactivate':
        return { title: `Notification Configuration ${record.status === 'active' ? 'Deactivation' : 'Activation' }`, maxWidth}
      default:
        return {maxWidth}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalType, record.status]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await emailIndex({perPage: 10});
      if(response?.status === 200){
        setList(response?.data)
      }
    }
    if (formPage === 'email_addresses' && isEmpty(list)) fetchStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list])

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>

        {
          modalType === 'view' && 
          <View
            record={record}
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={record.status}
          />
        }
        {
          modalType === 'activate_deactivate' && 
          <ActivateDeactivate
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={record.status}
            record={record}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default NotificationModal;
