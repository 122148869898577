const showFields = (attrs) => {
    const {customInputClass, customLabelClass} = attrs
    return [
      {
        name: "created_at",
        label: "Created on Date",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: "points_earned",
        label: "Points Earned",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: "points_redeemed",
        label: "Points Redeemed",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: "users_enrolled",
        label: "Users Enrolled",
        customInputClass,
        customLabelClass,
        disabled: true
      },
    ];
  };

export {showFields}