import React, { useCallback, useEffect, useMemo, useState } from 'react'
/* Base */
import SlideButton from 'components/base/slide_button';
/* Helpers */
import { camelize, displayUnixTimestamp } from 'components/helpers';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as ShareIcon } from 'assets/share.svg';
import { ReactComponent as StarIcon } from 'assets/star-icon.svg';
import { ReactComponent as ApproveIcon } from 'assets/approve-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import { ReactComponent as BarIcon } from 'assets/bar-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';

const Action = ({status, activeClass, allowActive, className, icon, onClick}) => {
  const [isActive, setIsActive] = useState(false)
  
  const handleColorChange = (val) => {
    if(status || !allowActive) return
    setIsActive(val)
  }

  useEffect(() => {
    setIsActive(status)
  }, [status])

  return <span 
    role='button'
    onMouseEnter={() => handleColorChange(true)}
    onMouseLeave={() => handleColorChange(false)}
    onClick={() => onClick && onClick()}
    className={`${isActive ? `${className} ${activeClass}` : `${className}`} d-flex justify-content-center align-items-center`}
  >
    {icon}
  </span>
}

const TableRow = (props) => {
  const {record, setRecord, selectedRecordId, openModal, modalType} = props

  const isRejected = record.status === 'rejected';
  const highlightAction = useCallback((actionType) => {
    return actionType === modalType && record.id === selectedRecordId
  }
  ,[modalType, selectedRecordId, record.id])

  const actions = useMemo(() => {
    return [
      {
        icon: <EyeIcon />,
        className: `${styles['icon-eye']}`,
        onClick: () => !record.shared && openModal('show'),
        activeClass: styles['icon-eye-active'],
        status: modalType === 'show' && highlightAction('show'),
        allowActive: !record.shared,
      },
      {
        icon: <ApproveIcon />,
        className: styles.icon,
        onClick: () => openModal('approve', record.status),
        activeClass: styles['icon-success'],
        status: modalType === 'approve' && highlightAction('approve'),
        allowActive: ['pending', 'under_review'].includes(record.status)
      },
      {
        icon: <CloseIcon style={{ width: '8px'}} />,
        className: styles.icon,
        onClick: () => openModal('reject', record.status),
        activeClass: styles['icon-danger'],
        status: modalType === 'reject' && highlightAction('reject'),
        allowActive: ['pending', 'under_review'].includes(record.status)
      },
      {
        icon: <><BarIcon style={{ marginRight: '2px' }}/><BarIcon /></>,
        className: styles.icon,
        onClick: () => openModal('under_review', record.status),
        activeClass: styles['icon-alert'],
        status: modalType === 'under_review' && highlightAction('under_review'),
        allowActive: record.status === 'pending'
      },
    ]
  },[openModal, modalType, highlightAction, record.status, record.shared])

  return (
    <tr onClick={() => setRecord(record)}>
          <td>{ displayUnixTimestamp(Date.parse(record.submitted_on) / 1000, 'ddd, MMM Do YYYY, h:mm:ss A')}</td>
          <td>
            <div className={`${isRejected ? styles.dangerColor : ''} text-capitalize d-flex align-items-center`}>
              { camelize(record.status) }
              { isRejected 
                && <EyeIcon
                    className={styles.rejectEyeIcon} 
                    onClick={() => openModal('reject_reason', record.status)}
                  />
              }
            </div>
          </td>
          <td className='text-capitalize'>
            <div className='d-flex no-wrap align-items-center'>
              <span>{record?.permit_type}</span>
              <span className='mt-1'>
                {
                  record.special &&  (
                    <SlideButton icon={<StarIcon />} className={` ${styles.badge} ml-2`} status='primary'>
                      <span className='mr-3 d-inline-block' style={{width: '35px'}}>Special</span>
                    </SlideButton>)
                }
              </span>
            </div>
            
          </td>
          <td>
            <div className='d-flex no-wrap align-items-center'>
              <span>{camelize(record.application_type)}</span>
              { record.shared && (
                <SlideButton icon={<ShareIcon />} className={` ${styles.badge} ml-2`} status='orange-light'>
                  <span className='mr-3 d-inline-block' style={{width: '34px'}}>Shared</span>
                </SlideButton>
              )}
            </div>
          </td>
          <td>{record.applicant_name}</td>
          <td className='text-uppercase'>{record.vehicle_lpn}</td>
          <td>
            <div className='d-flex justify-content-between align-items-center'>
                { actions.map((action, idx) => <Action key={idx} {...action} />)}
            </div>
          </td>
        </tr>
  )
}

export default TableRow