import React, { useCallback, useRef } from 'react'
import { citationFilterFields } from 'components/helpers/fields/vehicles'
import { renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button'
import { citationFilterFetcher } from "api/vehicles";
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'

const FilterForm = (props) => {
  const { disputeTypes, violationType, citationStatuses, setFilters, match, setList, startFetching } = props;

  const attrs = { disputeTypes, violationType, citationStatuses, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterViolations = useCallback(async (filters={}) => {

    startFetching(citationFilterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {...filterValues}
    setFilters(filters);
    filterViolations(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      created_at: '', 
      updated_at: '', 
      status: '',
      dispute_type: '',
      violation_type: '',
    })
    setFilters({})
    filterViolations({})
  }

  const getFields = () => citationFilterFields({ ...attrs})

  return (
    <Form  getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        const fields = getFields()
        return <Row className='m-auto'>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(0, 2), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(2, 4), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(4, 6), {lSize: 5, iSize: 7})}
            </Col>
          </Col>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['btn-secondary']}`}
            >
              Reset
            </Button>
          </Col>
        </Row>
      }}
    </Form>
  )
}

export default FilterForm;
