import React, { useState, useEffect } from "react";
import { isEmpty } from "underscore";
import { Card } from "reactstrap";
import DetailedReportApi from "api/detailed_reports";
import { ReactComponent as BlueCarIcon } from 'assets/blue-car-svgrepo-com.svg';
import style from "./data_card.module.sass";

const CurrentlyParkedCard = () => {
  const [data, setData] = useState();

  const fetchData = async () => {
    try{
      DetailedReportApi.index({
        resource: 'vehicles_currently_parked',
      }).then(({ data }) => {
          setData(data)
        });
    }catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isEmpty(data)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return(
    <React.Fragment>
      <Card className={`${style.cardTemplate} h-100`}>
        <div style={{marginTop: '-11px'}}>
        <BlueCarIcon className={style.icon}/>
        </div>
          <div className={style.dataContainer}>
          <div className={style.userContainer}>Currently Parked</div>
          <div className={style.numberContainer}>{data?.pie_chart_total['Vehicles Currently Parked']}</div>
          <div className={style.activeContainer}>Cars parked now</div>
          <div className={style.data1Container}>
            <span className={style.percentageContainer}>{data?.percentage_from_yesterday}</span>
            <span className={`${style.userContainer1} ml-1`}>from yesterday</span>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default CurrentlyParkedCard;
