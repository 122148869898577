import React from 'react'
import styles from './index.module.sass'
import Loader from 'components/helpers/loader';
import {Col} from 'reactstrap'

const TableRow = (props) => {
  const {record, isFetching, setSelectedRecord} = props
  const {color} = record
  const renderLoader = () => {
    return (
      <Col className={styles.loader}>
        <Loader />
      </Col>
    );
  }
  return (
    isFetching? renderLoader() :
      <tr onClick={() => setSelectedRecord(record)}>
        <td className='text-uppercase'>{record?.plate_number}</td>
        <td className='text-capitalize'>{record?.boot_details?.booted_on}</td>     
        <td className='text-capitalize'>{record?.boot_details?.booted_by?.name}</td>     
        <td className='text-capitalize'>{record?.boot_details?.parking_lot?.address}</td>  
        <td className='text-capitalize'>{record?.impound_details?.impounded_on}</td>     
        <td className='text-capitalize'>{record?.impound_details?.impounded_by?.name}</td>   
        <td className='text-capitalize'>{record?.manufacturer?.name}</td>  
        <td className='text-capitalize'>{record?.model}</td>  
        <td className='text-capitalize'>
          <div className='d-flex'>
            <span style={{backgroundColor: record.color}} className={`${styles.dotIcon} mr-2`}> </span>
            <span style={{marginTop: '-0.1rem'}}>{color && color}</span>
          </div>
        </td>
      </tr>
  );
};

export default TableRow