import React, { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as DownloadIcon } from 'assets/download-minimalistic-svgrepo-com.svg';
import { PDFReader } from 'reactjs-pdf-reader';

const Confirmation = ({closeModal, downloadEventParkingPass, passBase64, fileUrl, setFileUrl}) => {

  useEffect(()=> {
    const base64toBlob = () => {
      const bytes = atob(passBase64);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }
      const url = URL.createObjectURL(new Blob([out], { type: 'application/pdf' }));
      setFileUrl(url) 
    }
    if(passBase64){base64toBlob();}
  },[passBase64, setFileUrl])
  
  const message = () => 
    <React.Fragment>
      <br/>
      <span>Payment Successful, We have email you a copy of the parking pass. You may also download your pass below.</span>
    </React.Fragment>

  return (
    <Container className='p-0 m-0'>
      <Row className={`${styles.spacesNote} d-flex justify-content-center mb-3 mt-1`}>
         <p className={styles.totalSpacesText}>{ message() }</p>
      </Row>
      {passBase64 && fileUrl && (
        <div className={styles.previewSection}>
          <center> 
           <PDFReader url={fileUrl}  width='400'/>
          </center> 
        </div>
      )}
      <Container className='mb-4' >
        <br/>
        <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
          <Button onClick={closeModal} type='button' className={`${styles.prevButtons} ${styles['prevButtons-bg-secondary']} mr-4`}>
            Cancel
          </Button>
          <Button type='button' onClick={() => downloadEventParkingPass()} className={`${styles.prevButtons}`}>
            <DownloadIcon className={`${styles.downloadIcon}`}/> <span className={styles.download}>Download</span>
          </Button>
        </Col>
      </Container>
  </Container>
  )
}

export default Confirmation;