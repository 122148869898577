import React, { useEffect, useMemo } from 'react'

import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg'
import { each, filter, find, isEmpty, map } from 'underscore'
import { FieldType } from 'components/helpers/form_fields';
import { renderField, renderInput } from 'components/base/forms/common_form';
import { Col, Row } from 'reactstrap';
import styles from './index.module.sass'

const Index = (props) => {
  const {parkingLots, permitParkingLots, formApiRef, selectedLots, formState} = props

  const lots = useMemo(() => (
    filter(parkingLots, lot => selectedLots?.includes(lot.id))
  ),[selectedLots, parkingLots])

  // Set reserved spaces values
  useEffect(() => {
    if(!isEmpty(selectedLots) && formApiRef.current) {
      each(lots, lot => {
        const ppl = find(permitParkingLots, (pl) => pl.parking_lot?.id === lot.id)
        formApiRef.current.setValue(`lots[lot-${lot.id}][allocated_spaces]`, ppl?.reserved_spaces || 1)
        formApiRef.current.setValue(`lots[lot-${lot.id}][reserved_spaces]`, lot.reserved_spaces)
        formApiRef.current.setValue(`lots[lot-${lot.id}][id]`, lot.id)
      })
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLots])


  const getFields = (id, reserved_spaces) => {
    return [
      { 
        name: `lots[lot-${id}][reserved_spaces]`,
        disabled: true,
        customInputClass: styles.formInput
      },
      {
        name: `lots[lot-${id}][allocated_spaces]`,
        type: FieldType.INCREASER_FIELD,
        step: 1,
        min: 1,
        max: reserved_spaces,
        tooltip: '',
        renderValue: value => value,
        customInputClass: styles.formInput
      },
      { 
        name: `lots[lot-${id}][id]`,
      },
    ]
  }

  const removeLot = (lotId) => {
    formApiRef.current.setValue('parking_lot_ids', filter(selectedLots, id => id !== lotId))
  }
  
  return (
    <>
      { map(lots, (lot, idx) => {
        const fields = getFields(lot.id, lot.reserved_spaces)
        return (
          <Row key={lot.id} className=' mx-auto p-0' xs={12}>
            <Col className= {`px-0 pt-3 d-flex ${idx === 0 && 'border-top'}`} xs={12}>
              <Col className= {`ml-auto p-0 ${styles.fieldset} ${styles['fieldset-title']}`} xs={10}>
                <div>
                  <div className='d-flex justify-content-center'>
                    <span className='text-center mb-3'>Reserved Spaces</span>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <span className='text-center mb-3'>Allocate Spaces</span>
                  </div>
                  <span style={{width: 15}}></span>
                </div>
              </Col>
            </Col>
            <Col className= {`p-0 d-flex border-bottom pb-2`} xs={12}>
              <Col className={`px-0 ${styles.label} general-text-2`} xs={2}>
                <span>{lot.name}</span>
              </Col>
              <Col className= {`p-0 ml-auto ${styles.fieldset}`} xs={10}>
                <div>
                  <div>
                    {renderField(fields[0])}
                  </div>
                  <div>
                    {renderField(fields[1], { formState, iSize: 12, errors: {} })}
                  </div>
                  <div className='d-none'>{renderInput(fields[2])}</div>
                  <TrashIcon className={styles.trashIcon} onClick={() => removeLot(lot.id)} />
                </div>
              </Col>
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default Index;
