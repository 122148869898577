import React, { useState, useEffect, useMemo } from 'react'
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/download-minimalistic-svgrepo-com.svg';
import { map } from 'underscore';
import env from ".env";
import qs from 'qs';
import Button from 'components/base/button';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, width, idx } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) setShowText(false)}}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
      style={{width: width}}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}


const TableRow = (props) => {
  const {record, openModal, setSelectedRecord, setFileUrl, setPassBase64, selectedRecord, modalType} = props
  const {color} = record

  const downloadPdf = (data) => {
    const query = qs.stringify({
      token: localStorage.TOKEN,
      ...data,
    }, { arrayFormat: 'brackets' })
  
    const url = `${env.backend_url}/dashboard/booting_and_towings/boot_violation_pdf?${query}`
    window.open(url, '_blank');
    window.focus();
    setFileUrl('')
    setPassBase64('')
  }

  const actions = useMemo(() => {
    return [
      {
        icon: <EyeIcon className={`${styles['icon-eye']} mr-3`}/>,
        highlightedClass: `${styles['background-primary']} mr-3 text-white`,
        onClick: () => {openModal('preview')},
        text: 'Preview',
        width: '60px',
        isActive: modalType === 'preview' && selectedRecord === record.id,
      },
      {
        icon: <DownloadIcon className={`${styles['icon-download']} ml-3`}/>,
        highlightedClass: `${styles['background-secondary']} ml-3 text-white`,
        onClick: () => {downloadPdf({vehicle_id: record?.id})},
        text: 'Download',
        width: '65px',
        isActive: modalType === 'show' && selectedRecord === record.id,
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td className='text-uppercase'>{record?.plate_number}</td>
      <td className='text-capitalize'>{record?.boot_details?.booted_on}</td>     
      <td className='text-capitalize'>{record?.boot_details?.booted_by?.name}</td>     
      <td className='text-capitalize'>{record?.boot_details?.parking_lot?.address}</td>  
      <td className='text-capitalize'>{record?.manufacturer?.name}</td>  
      <td className='text-capitalize'>{record?.model}</td>  
      <td className='text-capitalize'>
        <div className='d-flex'>
          <span style={{backgroundColor: record.color}} className={`${styles.dotIcon} mr-2`}> </span>
          <span style={{marginTop: '-0.1rem'}}>{color && color}</span>
        </div>
      </td>
      <td>
      <div className={`${styles.actions} d-flex justify-content-center `} >
        { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        <span>
          <Button
            className={`${styles.bootButton} mx-3`} 
            onClick={() => openModal('removeBoot')}
          >
            Remove
          </Button>
        </span>
      </div>
    </td>
    </tr>
  );
};

export default TableRow