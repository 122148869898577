import { MonitorVehiclesActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(MonitorVehiclesActions);

const MonitorVehiclesReducers = combineReducers({
  index,
  records,
});

export default MonitorVehiclesReducers;
