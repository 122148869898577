import React, { useCallback, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { updateSettings } from 'api/system_logs';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Confirmation from '../confirmation';
import Changes from '../changes';

const VehicleModal = (props) => {
  const { openModal, modalType, status, updateLogName, logName, setCurrentStatus } = props
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const closeModal = useCallback( () => {
    openModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  const updateLogSettings = useCallback(async () => {
    try {
      const response = await updateSettings({data: {module_name: updateLogName, status: status}});
      if(response.status === 200 || response.status === 201 )
      { 
        setCurrentStatus(response.data.status)
        closeModal()
        addAlertMessages([{
          type: `${status === 'pause' ? 'danger' : 'primary'}`,
          text: `${logName} Logs have been successfully ${status === 'pause' ? 'paused' : 'resumed'}`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      console.log(error)
    }
  },[addAlertMessages, closeModal, status, updateLogName, logName, setCurrentStatus])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'confirm':
        return {maxWidth : '400px'}
      case 'changes':
        return {maxWidth : '550px'}
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered
      contentClassName={styles.vehicleModal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'confirm' && 
          <Confirmation
            {...props}
            status={status}
            closeModal={closeModal}
            updateLogSettings={updateLogSettings}
          />
        }
        {
          modalType === 'changes' && 
          <Changes
            {...props}
            status={status}
            closeModal={closeModal}
            updateLogSettings={updateLogSettings}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default VehicleModal;
