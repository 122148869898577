/* Api */
import React, { useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import {  isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { eventApplicationsSetting, getEventApplicationsSetting } from 'api/event_parking_applications';
/* Helpers */
import { settingFields } from 'components/helpers/fields/event_parking_applications/settingFields'
/* Styles/Assets */
import styles from './index.module.sass';

const initialValues = { 
  cancellations_grace_period: " " ,
}


const attrs = {
customLabelClass: `${styles.formLabel}`,
customInputClass: `${styles.formInput}`,
}

const Setting = (props) => {
  const  {setSettingRecord,addAlertMessages ,closeModal, errors, setErrors ,isSaving } = props;
  
  const formApiRef = useRef()

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  };

  const save = async () => {

    setErrors({})
    const { values } = formApiRef.current.getState()
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    eventApplicationsSetting(values)
      .then((res) => {
        if(res.status === 200){
          addAlertMessages([{
            type: 'primary',
            text: `Saved Changes Successfully`,
            onlyMessage: true,
          }], 'center')
          closeModal()
          setSettingRecord(res.data)
        }
      })
  };

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const { values } = formApiRef.current.getState()
    const fetchEventSettings = async () => {
      try {
        const response = await getEventApplicationsSetting()
        formApiRef.current.setValue('allow_cancellations', `${response.data.allow_cancellations}`)
        if(values.allow_cancellations === 'true'){formApiRef.current.setValue('cancellations_grace_period', response.data.cancellations_grace_period)}
      } catch(error) {
        console.log(error)
      } finally {        
      }
    };
    fetchEventSettings();
  }, [formApiRef])

  const validateValues = (formState) => {
    const errors = {}
     const { allow_cancellations, cancellations_grace_period} = formState;
     if(!allow_cancellations) { errors['allow_cancellations'] = ['Allow cancellations can\'t be blank']}
     if(allow_cancellations === "true" && !cancellations_grace_period) { errors['cancellations_grace_period'] = ['Grace Period can\'t be blank']}
     if (allow_cancellations === "true" && !(/^\d+$/.test(cancellations_grace_period))) { errors['cancellations_grace_period'] = ['Grace Period is not a valid type']}
     return errors
  }


  const getFields = () => {
    return settingFields({mute: false, ...attrs})
  }

  const doubleFieldProps = {
    lSize: 5,
    iSize: 7,
  }


  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}>Event Parking Cancellation</p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >
          {({ formState }) => {
              const { allow_cancellations} = formState.values;
              const newFields = getFields()
              return (
                <Row className='mx-auto w-100'>
                  { map(newFields[0], (field, idx) => {
                    return (
                      <Col key={idx} className={`m-0 p-0 pl-2`} xs={12}>
                        { renderField(field, { ...doubleFieldProps, errors} )}
                      </Col>
                    )
                  }) }
                  {allow_cancellations === "true" && <React.Fragment>
                    <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                      { renderFields(newFields[1], { ...doubleFieldProps, errors} )}
                    </Col>
                  </React.Fragment>}
                  <Col className="d-flex justify-content-center mb-3 mt-3 pt-2" xs={12}>
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      Cancel
                    </Button>
                    <Button type='submit' className={`${styles.button} mr-5 pr-2`}  isLoading={isSaving}>
                      Save
                    </Button>
                  </Col>
                </Row>
              )
            }
          }
        </Form>
      </fieldset>
    </Container>
  )
}


export default Setting