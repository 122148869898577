import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* Module */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
/* API */
import { show } from "api/disputes";
/* Helpers */
import Loader from 'components/helpers/loader';
import { camelize } from 'components/helpers';
import { viewFields } from 'components/helpers/fields/disputes/viewFields';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { isEmpty } from 'underscore'
import { renderFields } from 'components/base/forms/common_form';
import {search as dropdownsSearch } from 'api/dropdowns';

const Show = ({ startFetching, closeModal , record, updateStatus, statuses, setState, isSaving}) => {
  const [isFetching, setIsFetching] = useState()
  const [dispute, setDispute] = useState({status:""}) 
 
  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel
  }

  const singleFieldProps = {
    iSize: 7,
    lSize: 3
  }
  const disputeData = useCallback((data) => {
    const { created_at_parsed="", username="", email="", parking_lot="",dispute_type="", status=""} = data    

    return ({  
        date:created_at_parsed,
        user_name:camelize(username),
        email:email,
        parking_lot:parking_lot.name,
        dispute_type:camelize(dispute_type) ,
        status:status       
    })
  },[])
  
  const defaultStatus = dispute.status

  const renderDisputeFields = useCallback((defaultStatus) => {
    const fields = viewFields({...fieldAttrs, statuses,defaultStatus})
    return (
      <Col >
        <div >
          {renderFields(fields, {...singleFieldProps})}
        </div>
      </Col>
    )
    // eslint-disable-next-line
  },[statuses])


useEffect(() => {
    setIsFetching(true)
    const fetchDispute = async () => {
      if(!record)  return;
      try {
        const response = await startFetching(show({id: record}))
        if(response.status === 200) {
          const { data } = response;
          setDispute(disputeData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        
        setIsFetching(false)
      }
    };
    fetchDispute();

  }, [record, disputeData, startFetching])

  
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const disputeStatuses = await startFetching(dropdownsSearch('dispute_statuses_field'))
        setState('statuses', disputeStatuses.data)
      } catch (_error) {
      }
    }
    if(isEmpty(statuses)) fetchStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const submitValues = useCallback((values) => {
    const data = { 
      status: values.status,    
    }
    updateStatus(data)
  },[updateStatus])
  

  if(isFetching) { return <Loader /> }

  return (

    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Dispute Details</p>
      <Form initialValues={dispute} className={styles.form} onSubmit={submitValues}>
        { renderDisputeFields(defaultStatus) }
        <Col className='d-flex justify-content-center'>
          <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            Cancel
          </Button>
          <Button type='submit' className={`${styles.button}`}   isLoading={isSaving}>
            Save
          </Button>
        </Col>

      </Form>
    </Container> 
  )
}

export default withFetching(withCurrentUser(Show));