import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Row, Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { sendFields } from 'components/helpers/fields/broadcast_messages/sendFields';
import Button from 'components/base/button';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { isEmpty } from 'underscore'
import { search as dropdownsSearch, searchV1 } from "api/dropdowns";
import {ReactComponent as RemoveIcon} from 'assets/close_icon.svg'
import {ReactComponent as ApproveIcon} from 'assets/approve-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import { addCategory } from 'api/broadcast_messagse';
import moment from 'moment';

const Send = ({ startFetching , record, sendCategory, errors, setErrors, closeModal}) => {
  const [step , setStep] = useState(1)
  const [input, setInput] = useState("");
  const [emails, setEmails] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [newCategory, setNewCategory] = useState()
  const [showNewCategory, setshowNewCategory] = useState(false)
  const [emailError, setEmailError] = useState([])
  const [newSelected, setNewSelected] = useState(false)
  const formApiRef = useRef();
  const [dropdowns, setDropdowns] = useState({
    permitTypes: [],
    broadcastCategories: [],
    agencies: []
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(searchV1('permit_type_list')
        ).then((response) => setDropdowns(state => ({...state, permitTypes: response.data }))),
        startFetching(dropdownsSearch(`broadcast_categories_list`)
        ).then((response) => setDropdowns(state => ({...state, broadcastCategories: response.data }))),
        startFetching(dropdownsSearch(`agency_list`)
        ).then((response) => setDropdowns(state => ({...state, agencies: response.data })))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(errors?.user_invalid_email){
      setEmailError(errors?.user_invalid_email[0].split('[')[1].split(']').join(' ').split('"').join('').split(' ').join('').split(','))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors,setEmailError])

  const fetchVehicleSubclasses = async () => {
    const {values} = formApiRef.current.getState();
    try {
      const subclasses = await dropdownsSearch("broadcast_categories_list", {
        categories: values.categories,
      });
      setDropdowns(state => ({...state, broadcastCategories: subclasses.data }))
      formApiRef.current.setValue('category_id', subclasses.data[subclasses.data.length-1].value)
    } catch (_error) {}
  };

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    setErrors("");
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === "," || key === "Enter") && trimmedInput.length) {
      e.preventDefault();

      if (validateEmail(trimmedInput)) {
        if (!emails.includes(trimmedInput)) {
          if (emails.length < 5) {
            setEmails((prevState) => [...prevState, trimmedInput]);
            setInput("");
            setErrors("");
          }
        } 
      }
    }

    if (key === "Backspace" && !input.length && emails.length && isKeyReleased) {
      const emailsCopy = [...emails];
      const poppedTag = emailsCopy.pop();
      e.preventDefault();
      setEmails(emailsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setEmails((prevState) => prevState.filter((tag,i) => i !== index));
    setErrors()
  };


  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    permitTypes: dropdowns.permitTypes,
    broadcastCategories: dropdowns.broadcastCategories,
    newSelected: newSelected
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => {
        setErrors({})
        setEmailError()
      }
    }
  }

  const addBroadcastCategory = useCallback(async (data) => {
    try {
      const response = await addCategory({data})
      if(response.status === 200 || response.status === 201){
        fetchVehicleSubclasses()
        setshowNewCategory(false)
        setNewSelected(true)
      }
    } catch (error) {
      console.log(error)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ record])

  const fields = sendFields(fieldAttrs)

  const convert=(str) =>{
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  }

  const validateValues = (formState) => {
    const errors = {}
    
    const { category_id, message, recipient, title, permit_type_ids, schedule_date_and_time } = formState;
    if(!category_id) { errors['category_id'] = ['Category is required']}
    if(!message) { errors['message'] = ['Message is required']}
    if(!recipient) { errors['recipient'] = ['Recipient is required']}
    if(!title) { errors['title'] = ['Title is required']}
    if(recipient && recipient==='permit_holders' && isEmpty(permit_type_ids)) { errors['permit_type_ids'] = ['Permit Type is required']}
    if(recipient && recipient==='specific_users' && isEmpty(emails)) { errors['emails'] = ['Atleast one Email ID is required']}
    if(title && title.length>70) { errors['title'] = ['Maximum of 70 characters']}
    if(message && message.length>1300) { errors['message'] = ['Maximum of 1300 characters']}
    if(step===2 && !schedule_date_and_time.date) { errors['schedule_date_and_time[date]'] = ['Date is required']}
    if(step===2 && (!schedule_date_and_time.time || schedule_date_and_time.time==="NaN:NaN")) { errors['schedule_date_and_time[time]'] = ['Time is required']}

    return errors
   }

  const submitValues = useCallback(() => {
    setErrors({})
    const {values} = formApiRef?.current?.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const data = { 
      category_id: `${values?.category_id}`,
      message: values?.message,
      recipient: values?.recipient,
      title: values?.title,
    }
    if(values.recipient === "specific_users"){
      Object.assign(data, {user_emails: emails})
    }else if(values.recipient === "permit_holders"){
      Object.assign(data, {permit_type_ids: values?.permit_type_ids})
    }
    if(step===2){
      Object.assign(data, {schedule_date_and_time: { date: convert(values.schedule_date_and_time.date), 
        time: moment(values.schedule_date_and_time.time, ["HH.mm"]).format("hh:mm A")}})
    }
    sendCategory(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sendCategory, emails, step])

  const handleNext = () => {
    if(step===1){
      setErrors({})
      const {values} = formApiRef?.current?.getState();
      const inputErrors = validateValues(values)
      if(!isEmpty(inputErrors)){
        setErrors(Object.assign(inputErrors))
        return
      };
      setStep(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return (

    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>New Broadcast Message</p>
      <Form getApi={setFormApi} className={styles.form} >
      {({ formState }) => {
        const {recipient, category_id} = formState.values
        if(category_id === 'add_new'){setshowNewCategory(true)}
        return (
          <Row className='mx-auto w-100'>
            <Row className='mx-auto w-100'>
              <Col>
              {<div style={{ margin: '10px', display: step === 2 ? 'none' : ''}}>
              {renderFields(fields[0].slice(0, 1), {...singleFieldProps, errors})}
              {recipient === 'permit_holders' && renderFields(fields[0].slice(1, 2), {...singleFieldProps, errors})}
              {recipient === 'specific_users' && 
                <Row className='m-0 pb-3'>
                  <Col xs={3}>
                    <label className={styles.inputLabel1}>User Emails</label>
                  </Col>
                  <Col xs={9} className={styles["tag-container"]}>
                    {emails.map((tag, index) => (
                      <div key={index} className={emailError?.includes(tag) ? styles.tag1 : styles.tag}>
                        {tag} <span className={styles.remove} onClick={() => deleteTag(index)}><RemoveIcon className={styles.removeicon}/></span>
                      </div>
                    ))}
                    <input
                      className={styles["tag-container__input"]}
                      value={input}
                      onKeyDown={onKeyDown}
                      onKeyUp={onKeyUp}
                      onChange={onChange}
                    />
                  </Col>
                  {errors?.emails && <p className={`text-left general-error general-text-1 pt-1 pb-3 ${styles.errorMessage}`}>{errors?.emails}</p>}
                  {errors?.user_invalid_email && 
                    <p className={`text-left general-error general-text-1 pt-1 pb-3 ${styles.errorMessage}`}>
                      The addresses highlighted above do not belong to a registered user
                    </p>
                  }
                </Row>
              }
              {!showNewCategory && renderFields(fields[0].slice(3, 4), {...singleFieldProps, errors})}
              {showNewCategory &&
                <Row className='m-0 pb-3'>
                <Col xs={3}>
                  <label className={styles.inputLabel1}>New category</label>
                </Col>
                <Col xs={7} className={styles["tag-container"]}>
                  <input
                    type="newCategory"
                    id="newCategory"
                    name="newCategory"
                    placeholder="New Category"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    className={styles.emailField}
                  />
                </Col>
                <Col xs={1}>
                  <ApproveIcon
                    className={`${styles.icon} ${styles['icon-success']}`} 
                    onClick={() => {addBroadcastCategory({name: newCategory})}}
                  />
                </Col>
                <Col xs={1}>
                  <CloseIcon
                    className={`${styles.icon} ${styles['icon-danger']}`} 
                    onClick={() => setshowNewCategory(false)}
                  />
                </Col>
              </Row>}
              {renderFields(fields[0].slice(4, 6), {...singleFieldProps, errors})}
              </div>}
              {<div style={{ margin: '10px', display: step === 1 ? 'none' : ''}}>
              {renderFields(fields[0].slice(6, 8), {...singleFieldProps, errors})}
              </div>}
            </Col>
            </Row>
            <Col className='d-flex justify-content-center'>
              <Button onClick={()=>  step === 1 ? closeModal() : setStep(1)} className={`${styles.cancelButton} mb-3`}>Cancel</Button>
              {step ===1 && <Button type='button' onClick={handleNext} className={`${styles.scheduleButton} mb-3`}>Schedule</Button>}
              {step ===1 && <Button type='button' onClick={submitValues} className={styles.submitButton} >Send</Button>}
              {step ===2 && <Button type='button' onClick={submitValues} className={styles.scheduleButton} >Schedule</Button>}
              </Col>
            </Row>
        )}}
      </Form>
    </Container> 
  )
}

export default (Send);