import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'informed';
import Toggle from 'components/base/toggle';

export const SwitchField = ({ onChange, field, className='', events={}, ...props }) => {
  const { fieldState, fieldApi } = useField({ field: field });
  const { setValue } = fieldApi;

  const onSwitchChange = (value) => {
    setValue(value);
    onChange && onChange();
  };

  return <Toggle
    className={className || ''}
    label=""
    onChange={onSwitchChange}
    value={!!fieldState.value}
    {...props}
  />;
};

SwitchField.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default SwitchField;
