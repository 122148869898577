import { UserCouponsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(UserCouponsActions);

const UserCouponsReducers = combineReducers({
  index,
  records,
  filters
});

export default UserCouponsReducers;