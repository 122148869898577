import React, { useCallback } from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass'
import Button from 'components/base/button';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';

const ToolBar = (props) => {
  const {isFetching, activeTab, setActiveTab, openModal} = props;

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])
  
  return (
    <NavigationToolbar
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center p-0'>
        <span className={`${styles.title} general-text-1`}>
          {`Law Enforcement Agency Types (${props.total})`}
        </span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          className={styles.createButton}
          onClick={ () => openModal('create')}
          isActive={true}
          icon={<PenToSquareSvg />}>  <span className='mr-3 d-inline-block' style={{width: '90px'}}>Create Type</span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={activeTab === 'filters' ? styles.activeIcon : ''}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;