import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { create } from 'api/parking_regulations';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { Container } from 'reactstrap';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import Dropdown from "components/base/dropdown";
import { ReactComponent as SaveIcon } from "assets/save-svgrepo-com.svg";

const LineSpacingOption = ({ editorState, onChange, body, setCreateActive, setbody}) => {
  
  const save = useCallback(async () => {
    setbody(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    // eslint-disable-next-line
  },[setCreateActive, body, editorState])
  
  const complete = useCallback(async () => {
    try {
      const response = await create({data: {document: body}})
      if(response.status === 200 || response.status === 201){
        window.location.reload()
        setCreateActive(false);
      }
    } catch (error) {
      console.log(error)
    }
  },[setCreateActive, body])

  const applyLineSpacing = (value) => {
    
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockWithNewLineSpacing = Modifier.setBlockData(
      contentState,
      selection,
      { 'line-height': `${value}` }
    );
    const newContentState = Modifier.mergeBlockData(
      blockWithNewLineSpacing,
      selection
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-block-data'
    );
    onChange(newEditorState);
  };

  const handleLineSpacingChange = (e) => {
    const newLineSpacing = parseFloat(e.value);
    applyLineSpacing(newLineSpacing);
  };

  const options = [
    { value: 1, label:"1"},
    { value: 1.5, label: '1.5'},
    { value: 2, label: '2'},
    { value: 2.5, label: '2.5'},
    { value: 3, label: '3'},
  ]

  const coveringText = () => {
    return `Line Spacing`;
  };

  return (
    <div className='d-flex'>

      <Dropdown
        coveringText={() => coveringText()}
        options={options}
        onChange={handleLineSpacingChange}
        selectedOptionClassName={styles.layoutSelectedOption}
        customInputClass={styles.dropdown}
        value={1}
        width="110px"
        height="10px"
        marginTop="3px"
      />

      <SaveIcon className={styles.button} onClick={save}/>
      <Button type='submit' className={styles.buttonComplete} onClick={complete}>
        Complete
      </Button>

    </div>
  );
};

LineSpacingOption.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Show = (props) => {
  const { list, setCreateActive } = props
  const [body, setbody] = useState(list[0]?.document)
  const [bodyEditorState, setbodyEditorState] = useState(() => EditorState.createEmpty())

  const handlebodyChange = (state) => {
    setbodyEditorState(state)
  }

  return (
    <Container className='px-0 pb-4 mw-100'>
    <Editor
      editorState={bodyEditorState}
      toolbarClassName={styles.toolbarClassName}
      wrapperClassName={styles.wrapperClassName}
      editorClassName={styles.editorClassName}
      onEditorStateChange={handlebodyChange}
      toolbarCustomButtons={[<LineSpacingOption editorState={bodyEditorState} onChange={setbodyEditorState} list={list} body={body} setCreateActive={setCreateActive} setbody={setbody}/>]}
    />
    </Container>
  );
}

export default (Show);