import { ChargingSessionActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(ChargingSessionActions);

const ChargingSessionReducers = combineReducers({
  index,
  records,
  filters
});

export default ChargingSessionReducers;