import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const { index, update, create } = resourceApi('parking/value_added_services');

const disable = (params = {}) => {
  const { id, data, nestedParams = {} } = params;
  return fetchApi(generatePath(`dashboard/parking/value_added_services/${id}/disable`, nestedParams), {
    method: 'PUT',
    data
  })
};

export { index, update, disable, create };
