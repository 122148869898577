import applicantDetails from "./applicantDetails";
import applicationDetails from "./applicationDetails/index";
import vehicleDetails from "./vehicleDetails";


const showFields = (attrs) => {
  return [
    vehicleDetails(attrs),
    applicantDetails(attrs),
    applicationDetails(attrs),
  ]
}

export { showFields };