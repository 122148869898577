import React, { useEffect, useState, useRef, useCallback } from "react";
import { Table, Button, Card, ConfigProvider } from "antd";
import { Row } from "reactstrap";
import styles from "./wallet.module.sass";
import "./antd.css";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { ReactComponent as TopUpIcon } from "assets/app/top-up-icon.svg";
import { subscriberWalletTransactions } from "api/webapp/subscriber";
import { displayUnixTimestamp } from "components/helpers";
import WalletModal from "./wallet_modal";

const Wallet = (props) => {
  const { currentSubscriber, t } = props;
  const [transactions, setTransactions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const tblRef = useRef(null);
  const [modalType, setModalType] = useState();
  const [walletBalance, setWalletBalance] = useState(currentSubscriber?.wallet_balance)
  const openModal = useCallback(
    (modalType) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "top_up":
          val = "top_up";
          break;
        case modalType === "confirmation":
          val = "confirmation";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  const [visibleRecords, setVisibleRecords] = useState(5);

  const fetchWalletTransactions = async () => {
    setIsFetching(true);
    try {
      const list = await subscriberWalletTransactions();
      setTransactions(list.data);
    } catch (_error) {
        console.log(_error)
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchWalletTransactions();
  }, []);

  const columns = [
    {
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span className={styles.dateData}>
          {displayUnixTimestamp(date, "MM-DD-YYYY hh:mm A")}
        </span>
      ),
    },
    {
      dataIndex: "reason",
      key: "reason",
    },
    {
      dataIndex: "type",
      key: "type",
    },
    {
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <span
          style={
            text.split(" ").includes("+")
              ? { color: "green" }
              : { color: "red" }
          }
        >
          {text}
        </span>
      ),
    },
  ];

  const loadMore = () => {
    setVisibleRecords((prev) => prev + 5);
    tblRef.current.scrollTo({
      index: transactions.length - 1,
    });
  };

  return (
    <div className={styles.tableContainer}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A9CED",
          },
          components: {
            Card: {
              headerBg: "#3A9CED",
            },
          },
        }}
      >
        <Card
          extra={
            <Row className={styles.topUpSection}>
              <Button
                className={styles.topUpBtn}
                icon={<TopUpIcon className={styles.topUpIcon} />}
                onClick={() => openModal('top_up')}
              />
              <p className={styles.topUpTxt}>{t("subscriber.profile.top_up")}</p>
            </Row>
          }
          title={
            <Row className={styles.balanceSection}>
              <p className={styles.balanceLabel}>{t("subscriber.profile.balance")}</p>
              <p className={styles.balanceTxt}>{walletBalance}</p>
            </Row>
          }
          bordered={true}
          className={styles.card}
        >
          <Table
            columns={columns}
            dataSource={transactions.slice(0, visibleRecords)}
            pagination={false}
            className={styles.table}
            showHeader={false}
            virtual
            loading={isFetching}
            scroll={{
              x: "none",
              y: "calc(100vh - 43vh)",
            }}
            ref={tblRef}
          />
          {visibleRecords < transactions.length && (
            <Button className={styles.loadMoreButton} onClick={loadMore}>
              <span className={styles.loadMore}>{t("subscriber.profile.load_more")}</span>
            </Button>
          )}
        </Card>
      </ConfigProvider>
      <WalletModal t={t} openModal={openModal} modalType={modalType} setWalletBalance={setWalletBalance} fetchWalletTransactions={fetchWalletTransactions}/>
    </div>
  );
};

export default withCurrentSubscriber(Wallet);