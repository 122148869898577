import  React, { useState, useCallback } from "react";
import { filterFetcher } from "api/emails";
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS, ADD_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/emails';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import { ROLES } from "config/permissions";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ToolBar from "components/pages/notifications/index/toolbar";
import EmailModal from "../emailModal";

const Index = (props) => {
  const { currentUser, isResourceFetching } = props;
  const [errors, setErrors] = useState({})
  const [modalType, setModalType] = useState(null)
  const [activeTab, setActiveTab] = useState(null);
  const [formPage, setFormPage] = useState('email_addresses');
  const [emailList, setEmailList] = useState([])
  const [emailRecord, setEmailRecord] = useState({})

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              pageType="bootings"
              activeTab={activeTab}  
              setActiveTab={setActiveTab}
              openModal={openModal} 
              modalType={modalType}
              setFormPage={setFormPage}
              formPage={formPage}
              isFetching={isFetching}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              resource={resource}
              openModal={openModal}
              isFetching={isFetching}
              setEmailRecord={setEmailRecord}
            />
          </Col>
        </Row>
        <EmailModal
          {...props}
          errors={errors}
          setErrors={setErrors}
          setActiveTab={setActiveTab}
          modalType={modalType} 
          openModal={openModal} 
          emailList={emailList}
          setEmailList={setEmailList}
          emailRecord={emailRecord}
          setEmailRecord={setEmailRecord}
          formPage={formPage}
        />
      </Container>
    </AccessControl>
  )
}

const resource = 'emails';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
      addListElement: invoke(ADD_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.emails.filters,
    record: state.emails.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);