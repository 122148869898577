import { FieldType } from 'components/helpers/form_fields';

const searchFields = ({categories, adminsList, customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'sender',
    label: 'Sender',
    type: FieldType.MULTISELECT_FIELD,
    placeholder: 'Please select',
    options: adminsList,
    customInputClass,
    customLabelClass,
    showToolTip: false,
  },
  {
    name: 'recipient',
    label: 'Recipients',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: 
      [{ label: 'Permit Holders', value: 'permit_holders' },
      { label: 'Subscribers', value: 'all_subscribers' }],
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'category_ids',
    label: 'Category',
    type: FieldType.MULTISELECT_FIELD,
    placeholder: 'Please select',
    options: categories,
    customInputClass,
    customLabelClass,
    showToolTip: false,
  },
  { 
    name: 'sent_date["from"]', 
    label: 'Sent Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'sent_date["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
];

const scheduledSearchFields = ({categories, adminsList, customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'sender',
    label: 'Sender',
    type: FieldType.MULTISELECT_FIELD,
    placeholder: 'Please select',
    options: adminsList,
    customInputClass,
    customLabelClass,
    showToolTip: false,
  },
  {
    name: 'recipient',
    label: 'Recipients',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: 
      [{ label: 'Permit Holders', value: 'permit_holders' },
      { label: 'Subscribers', value: 'all_subscribers' }],
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'category_ids',
    label: 'Category',
    type: FieldType.MULTISELECT_FIELD,
    placeholder: 'Please select',
    options: categories,
    customInputClass,
    customLabelClass,
    showToolTip: false,
  },
  { 
    name: 'scheduled_date["from"]', 
    label: 'Scheduled Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'scheduled_date["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'scheduled_time["from"]', 
    label: 'Scheduled Time',
    type: FieldType.SINGLE_HOUR_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'scheduled_time["to"]', 
    type: FieldType.SINGLE_HOUR_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
];

export {searchFields, scheduledSearchFields};
