import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
/* Actions */
import { SET_LIST, SET_LIST_ELEMENT , ADD_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS} from "actions/monitor_vehicles";
/* API */
import { filterFetcher } from "api/monitor_vehicles";
/* Base */
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import { connect } from 'react-redux';
/* Components */
import ToolBar from './toolbar';
import TableData from './table_data';
import VehicleModal from '../vehicle_modal';
import { bindActionCreators } from 'redux';
import { invoke } from 'actions';


const MonitorVehicles = (props) => {
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  return (
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              resource={resource}
              openModal={openModal} 
              activeTab={activeTab} 
              setActiveTab={setActiveTab}
              total={props.total}
              modalType={modalType}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData 
            {...props}
            activeTab={activeTab}
             />
          </Col>
        </Row>
        <VehicleModal {...props} openModal={openModal} modalType={modalType} setActiveTab={setActiveTab} />

      </Container>
  )
}

MonitorVehicles.propTypes = {
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'monitor_vehicles';
const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT),
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD) 
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.monitor_vehicles.filters,
    record: state.monitor_vehicles.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(MonitorVehicles))),
  SET_FILTERS,
);