import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';

const Confirmation = ({closeModal, permitName, sharedType}) => {

  const message = () => <React.Fragment>
    <br/>
    {!sharedType && <span className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>
      Thank you for applying for the {permitName} Permit. Your Permit application is under process. You will be notified as soon as there is an update on your application 
    </span>}
    {sharedType ==='emails' && <span className={`${styles.content2} d-flex justify-content-center my-2 'mx-1`}> 
      The Secondary Permit Holders are notified about the permit application. Your Application will remain in incomplete state until the secondary holders join.
    </span>}
    {sharedType ==='post' && <span className={`${styles.content3} d-flex justify-content-center my-2 'mx-1`}>
       Your shared permit invite has been posted publicly 
    </span>}
  </React.Fragment>

  return (
    
    <Container className='p-0 m-0'>
      <Col className="d-flex justify-content-center my-2 pb-2">
        <SuccessIcon/>
      </Col>
      <span className='d-flex justify-content-center my-2' style={{fontWeight:"", marginTop:"10%"}}>{sharedType==='emails' ? "Invitation Sent" : sharedType==='post' ? "Shared Permit Posted" : "Permit Application Successful"}</span>
      <Container className='mb-4' >
        <Col className="d-flex justify-content-center my-2">
          { message() }
        </Col>
        {sharedType &&  
          <Col className="d-flex justify-content-center my-2">
            <p className={styles.content}>You can check your application status from <a href='/subscriber/permit_applications'>Permit Applications</a></p>
          </Col>
        }
        <br/>
        <Col className="d-flex justify-content-center my-2">
          {
            <Button 
                type='button' 
                onClick={() => {closeModal()}} 
                className={`${styles.confirmBtn}`}
              >
                Ok
            </Button>
          }  
        </Col>
      </Container>
    </Container>
  )
}

export default Confirmation;
