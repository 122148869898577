import React from 'react'
import { Col, Container } from 'reactstrap'
/* Assets */
import styles from '../violationTypeModal/index.module.sass'
import Button from 'components/base/button'

const Delete = ({closeModal, deleteViolationType, violationType, isDeleting}) => {

  return (
    <Container>
      <Col className={`${styles.modalTitle} my-4 mb text-center`}>Delete Violation Type</Col>
      <Col className={styles.modalContent} xs={11}>
        <span className='d-block'>
          This will delete<em className={styles.boldText}>{violationType}</em>Are you sure?
        </span>
      </Col>
      <Col className='d-flex justify-content-center align-items-center mt-4 mb-2'>
        <Button 
          className={`${styles.btn} ${styles['btn-light']} mr-3`}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button 
          className={`${styles.btn} ${styles['btn-danger']}`}
          onClick={() => { deleteViolationType()}}
          disabled={isDeleting}
        >
          Delete
        </Button>
      </Col>
    </Container>
  )
}

export default Delete;
