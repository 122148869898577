import React from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
/* Base */
import {renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import withFetching from 'components/modules/with_fetching';
import  sessionDetails  from 'components/helpers/fields/app/my_parkings/sessionDetailsFields';
import { displayUnixTimestamp } from 'components/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'

const Details = (props) => {
  const  {closeModal, isSaving, record, secondsToHours} = props;

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customStatusInputClass: record?.paid ? styles.paidInputClass : styles.unpaidInputClass,
    customAddressInputClass: styles.addressInput.concat(" ", styles.iconInput),
    icon: dollarIcon(),
    locationIcon: <LocationIcon className={styles.locationIcon}/> ,
    customIconInputClass: styles.formInput.concat(" ", styles.iconInput)
  }

  const vehicleFieldProps = {
    lSize: 2,
    iSize: 10,
  }
  
  const fields = sessionDetails(attrs)
  const values = {
    session_id: record?.id,
    parking_lot: record?.lot?.name,
    date: displayUnixTimestamp(record?.check_in, 'MMM Do, YYYY'),
    rate: `${record?.lot?.car_rate} /Hour`,
    address: record?.lot?.location?.full_address,
    entry_time: displayUnixTimestamp(record?.check_in, 'hh:mm A'),
    exit_time: displayUnixTimestamp(record?.check_out, 'hh:mm A'),
    status: record?.paid ? "PAID": "UNPAID",
    vehicle_lpn: record?.vehicle?.plate_number,
    total_cost: record?.total_price
  }

  const fieldProps = { lSize: 6, iSize: 6}

  return (
    <Container className='p-0'>
      <p className={styles.subHeading}>Parking Duration</p>
      <p className={styles.durationText}>{secondsToHours(record?.check_out - record?.check_in)}</p>
      <fieldset disabled={isSaving}>
        <Form initialValues={values} className={`${styles.form} pt-4`}>
        {({ formState }) => {
            return (
              <Row className='mx-auto w-100'>
                <Col className={`m-0 ${styles.fieldset} pl-5 pr-3`} xs={6}>
                  {renderFields(fields.slice(0, 2), { ...fieldProps })}
                </Col>
                <Col className={`m-0 ${styles.fieldset}  pl-5 pr-5`} xs={6}>
                  {renderFields(fields.slice(2, 4), { ...fieldProps })}
                </Col>
                <Col className={`m-0 ${styles.fieldset} ml-4  mr-4 pl-4 pr-5`} xs={11}>
                  {renderFields(fields.slice(4, 5), { ...vehicleFieldProps })}
                </Col>
                <Col className={`m-0 ${styles.fieldset}  pl-5 pr-3`} xs={6}>
                  {renderFields(fields.slice(5, 7), { ...fieldProps })}
                </Col>
                <Col className={`m-0 ${styles.fieldset}  pl-5 pr-5`} xs={6}>
                  {renderFields(fields.slice(7, 9), { ...fieldProps })}
                </Col>
                <Col className={`m-0 ${styles.fieldset}  pl-5 pr-3`} xs={6}>
                  {renderFields(fields.slice(9, 10), { ...fieldProps})}
                </Col>
                <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
                  <Button type='button' onClick={closeModal} style={{marginLeft: record?.status === 'finished' ? '26px' : ''}} className={`${styles.button} ${styles['button-bg-secondary']} mr-4 pl-2`}>
                    Cancel
                  </Button>
                 {record?.status !== 'finished' &&  <Button  type='button' className={styles.button}>
                    Set Time
                  </Button> }
                  {record?.status !== 'finished' &&<Button  type='button' className={`${styles.button} ${styles['button-bg-raise']}`}>
                    Raise a Dispute
                  </Button>  }
                </Col>
            </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}


export default withFetching(Details);