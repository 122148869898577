import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { update, disable, create } from 'api/value_added_services';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Enable from '../enable';
import Disable from '../disable';
import Create from '../create';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const ValetModal = (props) => {
  const { openModal, modalType, setActiveTab, selectedRecord, setListElement, addListElement } = props
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    setErrors({})
    openModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const enableService = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({data: {value_added_service: data}, id: selectedRecord.id })
      if(response.status === 200 || response.status === 201 )
      {
        setListElement(response.data)
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: modalType === 'enable_service' ? 'Service enabled' : 'Service updated',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setErrors, addAlertMessages, closeModal, modalType, setListElement])

  const disableService = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await disable({id: selectedRecord.id })
      if(response.status === 200 || response.status === 201 )
      {
        setListElement(response.data)
        closeModal()
        addAlertMessages([{
          type: 'danger',
          text: 'Service disabled',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setErrors, addAlertMessages, closeModal, modalType, setListElement])

  const createService = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: {value_added_service: data}})
      if(response.status === 200 || response.status === 201 )
      {
        addListElement({setList: setList, data: response.data})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Service created',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setErrors, addAlertMessages, closeModal, addListElement])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'enable_service':
        return {title: `Enable ${selectedRecord.name} Service`}
      case 'disable_service':
        return {title: `Disable ${selectedRecord.name} Service`}
      case 'edit_service':
        return {title: `Edit ${selectedRecord.name} Service`}
      case 'create_service':
        return {title: 'Create Custom Value-added Service'}
      default:
        break;
    }
    return { width }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.vehicleModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          (modalType === 'enable_service' || modalType === 'edit_service') && 
          <Enable
            closeModal={closeModal}
            enableService={enableService}
            setErrors={setErrors}
            errors={errors}
            modalType={modalType}
            selectedRecord={selectedRecord}
          />
        }
        {
          modalType === 'disable_service' && 
          <Disable
            closeModal={closeModal}
            disableService={disableService}
            selectedRecord={selectedRecord}
          />
        }
        {
          modalType === 'create_service' && 
          <Create
            closeModal={closeModal}
            createService={createService}
            setErrors={setErrors}
            errors={errors}
            modalType={modalType}
            selectedRecord={selectedRecord}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default ValetModal;
