import React, { useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import styles from './index.module.sass'
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import { Upload } from "antd";

const UploadDocument = ({ closeModal, isSaving, uploadFile }) => {
  const [file, setFile] = useState()

  const submitValues = () => {
    const formData = new FormData()
    formData.append('file', file)
    uploadFile(formData)
  }

  const uploadProps = {
    name: "file",
    accept: "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html",
    listType: "picture",
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFile(file);
      };
      return false;
    },
    onRemove: () => {
      setFile(null);
    },
  };

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Upload Documents</p>
      <Form className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          return (
            <Col>
              <Col>
                <Upload
                  {...uploadProps}
                >
                  {isEmpty(file) ? <div className={`${styles.dragDropLabel2} d-flex flex-column h-95  text-center p-3`}>
                    <span className='mb-2'>
                      <CloudDownloadIcon />
                    </span>
                    <span>
                      <span className={styles.boldText1}>
                        Add documents
                      </span>
                      <span className='ml-1'>
                        or drag it here
                      </span>
                    </span>
                    <p className={styles.formatsText}>DOC, DOCX, PDF and HTM (200x200)</p>
                    </div> : ''}
                </Upload>
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-2`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                  Save
                </Button>
              </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (UploadDocument);