const lotFilterFields = () => ([
  { name: 'id', label: 'Lot ID'},
  { name: 'name', label: 'Name'},
  { name: 'issued_date', label: 'Location'},
  { name: 'status', label: 'Status'},
  { name: 'total_spaces', label: 'Total Spaces'},
  { name: 'reseved_spaces', label: 'reserved Spaces'},
]);

const filterFields = () => ([
  { name: 'permit_number', label: 'Permit Number'},
  { name: 'permit_type', label: 'Permit Type'},
  { name: 'issued_date', label: 'Issued Date'},
  { name: 'vehicle_plate_number', label: 'Vehicle Plate Number'},
  { name: 'vehicle_owner_name', label: 'Vehicle Owner name'},
  { name: 'vehicle_model', label: 'Vehicle Model'},
  { name: 'permit_expired', label: 'Permit Expired'},
  { name: 'permit_expiry_date', label: 'Expiry Date'},
  { name: 'action', label: 'Action'},
]);


export { filterFields, lotFilterFields }