import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "reactstrap";
/* Actions */
import {
  ADD_LIST_ELEMENT,
  SET_LIST,
  SET_FILTERS,
  SET_LIST_ELEMENT,
  SET_SINGLE_RECORD,
  POP_LIST_ELEMENT,
} from "actions/disabilities";
/* API */
import { filterFetcher } from "api/disabilities";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import { connect } from "react-redux";
/* Components */
import TableData from "./table_data";
import { invoke } from "actions";
import { bindActionCreators } from "redux";
import ToolBar from "./toolbar";
import DisabilityModal from "./disability_modal";

const Disabilities = (props) => {
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState();

  const openModal = useCallback(
    (modalType) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "show":
          val = "show";
          break;
        case modalType === "edit":
          val = "edit";
          break;
        case modalType === "delete":
          val = "delete";
          break;
        case modalType === "create":
          val = "create";
          break;
        case modalType === "activate_deactivate":
          val = "activate_deactivate";
          break;
        default:
          break;
      }
      setModalType(val);
      setActiveTab(activeTab => activeTab === val ? null : val);
    },
    [setModalType]
  );

  return (
    <Container className="mw-100 p-0">
      <Row className="m-auto">
        <Col xs={12} className="p-0">
          <ToolBar
            {...props}
            resource={resource}
            openModal={openModal}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            total={props.total}
            modalType={modalType}
          />
        </Col>
        <Col xs={12} className="p-0">
          <TableData
            {...props}
            modalType={modalType}
            openModal={openModal}
            activeTab={activeTab}
          />
        </Col>
      </Row>
      <DisabilityModal
        {...props}
        openModal={openModal}
        modalType={modalType}
        setActiveTab={setActiveTab}
      />
    </Container>
  );
};

const resource = "disabilities";

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
      popListElement: invoke(POP_LIST_ELEMENT),
    },
    dispatch
  );
};

const mapState = (state) => {
  return {
    filters: state.disabilities.filters,
    selectedRecord: state.disabilities.records.record,
    record: state.disabilities.records.record,
  };
};

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Disabilities)))
);