const { FieldType } = require("components/helpers/form_fields");

const vehicleFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, t } = attrs;
  return [
    {
      name: 'vehicle[plate_number]',
      label: t("vehicle_attributes.plate_number.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[color]',
      label: t("vehicle_attributes.color.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[make]',
      label: t("vehicle_attributes.vehicle_make.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[registration_state]',
      label: t("vehicle_attributes.state.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[manufacture_year]',
      label: t("vehicle_attributes.year.label"),
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[model]',
      label: t("vehicle_attributes.model.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

const ownerFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, t } = attrs;
  return [
    {
      name: 'owner[full_name]',
      label: t("permit_application.apply_for_permit.labels.step2.full_name.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[home_phone]',
      label: t("permit_application.apply_for_permit.labels.step2.home_phone.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[work_phone]',
      label: t("permit_application.apply_for_permit.labels.step2.work_phone.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[home_address]',
      label: t("subscriber.my_permits.show.home_address"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[work_address]',
      label: t("subscriber.my_permits.show.work_address"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

const permitFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, t } = attrs;
  return [
    {
      name: 'permit[number]',
      label: t("subscriber.my_permits.show.number"),
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[type]',
      label: t("subscriber.my_permits.show.type"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[issued_on]',
      label: t("subscriber.my_permits.show.issue_date"),
      type: FieldType.DATE_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
      showIcon: false
    },
    {
      name: 'permit[expires_on]',
      label: t("signup.Expiration Date"),
      type: FieldType.SINGLE_DATE_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
      showIcon: false,
    },
    {
      name: 'permit[expired]',
      label: `${t("subscriber.my_permits.show.expired")}?`,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[cost]',
      label: t("events.private_events.cost"),
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

const cancelledFields = (attrs, showDescription) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  const defaultFields = [
    {
      name: 'cancelled_details[status]',
      label: 'Permit Status',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'cancelled_details[cancelled_on]',
      label: 'Cancelled on',
      customInputClass,
      customLabelClass,
      disabled: mute,
      showIcon: false,
    },
  ]
  if(showDescription) {
    defaultFields.push(    {
      name: 'cancelled_details[description]',
      label: 'Description',
      customInputClass,
      customLabelClass,
      disabled: mute,
    })
  }
  return defaultFields
}

const revokedFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'revoked_details[status]',
      label: 'Permit Status',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'revoked_details[revoked_on]',
      label: 'Revoked on',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'revoked_details[revoked_by]',
      label: 'Revoked by',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'revoked_details[revoked_reason]',
      label: 'Description',
      customInputClass,
      customLabelClass,
      type: FieldType.TEXT_AREA,
      rows: 2,
      disabled: mute,
    },
  ]
}

const extendFields = (attrs) => {
    const { customInputClass, customLabelClass, t, customIconClass} = attrs
    return [
      {
        name: 'renewal_details[validity]',
        label: t("subscriber.my_permits.show.permit_validity"),
        disabled: true,
        customInputClass,
        customLabelClass,
      },
      {
        name: 'renewal_details[parking_hour_from]',
        label: t("subscriber.my_permits.show.parking_hour_from"),
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      
      {
        name: 'renewal_details[start_date]',
        label: t("events.private_events.purchase_event_parking.from"),
        type: FieldType.SINGLE_DATE_FIELD,
        customInputClass,
        customLabelClass,
        customIconClass,
        disabled: true,
      },
      {
        name: 'renewal_details[cost]',
        label: t("events.private_events.cost"),
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'renewal_details[hourly_rate]',
        label: t("permit_application.apply_for_permit.labels.step1.hourly_rate"),
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'renewal_details[parking_hour_to]',
        label: t("subscriber.my_permits.show.parking_hour_to"),
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'renewal_details[expiry_date]',
        label: t("events.private_events.purchase_event_parking.to"),
        type: FieldType.SINGLE_DATE_FIELD,
        customInputClass,
        customLabelClass,
        customIconClass,
        disabled: true,
      },
      
    ]
  }

  const parkingLotDetails = (attrs) => {
    const { customInputClass='', customLabelClass='', mute=true, id, t } = attrs;
    return [
      {
        name: `lot[lot-${id}][name]`,
        label: t("events.city_events.name"),
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      
    ]
  }




const paymentFields = (attrs) => {
  const {customDropdownInputClass='', customLabelClass='', customInputClass='',years,customExpiryDropdownInputClass, customExpiryLabel, cutomExpiryLabel2, t} = attrs
  return [
    
    [
      {
        name: 'payment_amount',
        label: t? t('payment_page.payment_amount'): "Payment Amount",
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'payment_methods',
        label: t? t('payment_page.payment_method'): "Payment Method",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [{
          "value": 0,
          "label": "Google Pay"
        },
        {
          "value": 1,
          "label": "Credit Card"
        }].map(({ value, label }) => {
          return { value, label };
        }),
        customInputClass: customDropdownInputClass,
        customLabelClass,
        mandatory: true,
      }
    ],
    [      
      {
        name: 'expiration_month',
        label: t? t('payment_page.credit_card.expiration_month.label'): "Expiration Month",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder:t? t('payment_page.credit_card.expiration_month.placeholder'):"Select expiration month",
        options: ["01","02","03","04","05","06","07","08","09","10","11","12"].map(val => ({value: val , label: val})),
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: cutomExpiryLabel2,
        disabled: false,
      },
      {
        name: 'expiration_year',
        label: t? t('payment_page.credit_card.expiration_year.label'): "Expiration Year",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder:t? t('payment_page.credit_card.expiration_year.placeholder'):"Select expiration year",
        options: years? years.map((y) => { return {value: y , label: y}}): [],
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel,
        disabled: false,
      },
    ],
   
  ];
};


export { cancelledFields, extendFields, ownerFields, permitFields, revokedFields, vehicleFields, parkingLotDetails, paymentFields };
