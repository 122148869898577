import React, { useEffect, useState, useRef } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { newFields } from 'components/helpers/fields/booting_and_towing';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { fetch_configuration } from 'api/booting_and_towings';

const Configure = (props) => {
  const { startFetching, closeModal, isSaving, createConfigurations, errors, setErrors, selectedParkingLot }= props
  const [ configurations, setConfigurations ] = useState()
  const formApiRef = useRef();
  const [ dropdowns, setDropdowns ] = useState({
    parkingLots: []
  })

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch('parking_lot_list')
        ).then((response) => setDropdowns(state => ({...state, parkingLots: response.data })))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await fetch_configuration({parking_lot_id: selectedParkingLot})
        if(response.status === 200 || response.status === 201) {
          setConfigurations(response.data);
          formApiRef.current.setValues(response.data)
        }
      } catch(error) {
        console.log(error)
      }
    };
    fetchConfigurations()
  }, [selectedParkingLot])

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    icon: dollarIcon(),
    iconInputClass: styles.input.concat(' ', styles.iconInput),
    parkingLots: dropdowns.parkingLots,
  }

  const singleFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { unpaid_citations_for_boot_violation, fine, grace_period_before_impounding, unpaid_citations_to_send_email, days_to_resend_email, compose_email } = formState;
    if(!unpaid_citations_for_boot_violation) {
      errors['unpaid_citations_for_boot_violation'] = ['Unpaid Citation Count is required']
    }
    if(!fine) {
      errors['fine'] = ['Fine is required']
    }
    if(!grace_period_before_impounding) {
      errors['grace_period_before_impounding'] = ['Grace Period is required']
    }
    if(!unpaid_citations_to_send_email) {
      errors['unpaid_citations_to_send_email'] = ['Unpaid Citation Count is required']
    }
    if(!days_to_resend_email) {
      errors['days_to_resend_email'] = ['Days count is required']
    }
    if(!compose_email) {
      errors['compose_email'] = ['Warning Email is required']
    }
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash={
      ...values,
      parking_lot_id: selectedParkingLot
    }
    createConfigurations(updatedHash)
  }

  const fields = () => {
    return newFields({...fieldAttrs})
  }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Configurations</p>
      <Form getApi={setFormApi} initialValues={configurations} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col className='mt-5'>
              <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Booting and Towing</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                <div style={{ margin: '10px'}}>
                  {renderFields(newFields.slice(1, 4), {...singleFieldProps, errors})}
                </div>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Warning Email</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                <div style={{ margin: '10px'}}>
                  {renderFields(newFields.slice(4, 7), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-3`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                  Save
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default (Configure);