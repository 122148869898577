import resourceApi from 'components/modules/resource_api';

const { index, update, create, show, destroy } = resourceApi('parking/valet_staffs');

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params
  
  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

export { index, update, create, show, destroy, filterFetcher };
