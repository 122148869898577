import { FieldType } from 'components/helpers/form_fields';

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate}) => [
  { 
    name: 'points_earned["min"]', 
    label: 'Points Earned',
    type: FieldType.NUMBER_FIELD,
    placeholder: 'Min.',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'points_earned["max"]', 
    type: FieldType.NUMBER_FIELD,
    placeholder: 'Max.',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'points_to_dollar["min"]', 
    label: 'Points Value in $',
    type: FieldType.NUMBER_FIELD,
    placeholder: 'Min.',
    customInputClass,
    customLabelClass,
  },
  
  { 
    name: 'points_to_dollar["max"]', 
    type: FieldType.NUMBER_FIELD,
    placeholder: 'Max.',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'enrollment_date["from"]', 
    label: 'Enrollment Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'enrollment_date["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
  

];

export {filterFields}