import React, { useState, useEffect } from "react";
import { isEmpty } from "underscore";
import { Col, Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, Popover, PopoverBody} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "components/base/dropdown";
import Chart from 'react-apexcharts'
import DetailedReportApi from "api/detailed_reports";
import Button from 'components/base/button';
import Loader from "components/helpers/loader";
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";
import style from "./data_card.module.sass";
import moment from "moment";
import DateModal from "components/base/date_modal";

const BarGraph = (props) => {

  const { data, selectedPermitTypes } = props
  let pastData = []
  let presentData = []
  let labelData = []

  // eslint-disable-next-line
  {Object.values(selectedPermitTypes).forEach((type) => {
    presentData.push(data?.[type.label]?.todays_permits)
    pastData.push(data?.[type.label]?.yesterdays_permits)
    labelData.push(type.label.slice(0, type.label.lastIndexOf(" ")))
  })}

  let state = {
    series: [
      {
        color: '#E8EBED',
        name: 'Past',
        data: [4,5,6,3,8,5]
      }, 
      {
        color: '#3A9CED',
        name: 'Present',
        data: [4,6,2,9,7,5]
      }
    ],
    options: {
      chart: {
        toolbar: {
          show: false
        },      
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          borderRadiusApplication: 'end',
          marginTop: 30,
          columnWidth: '55%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          offsetY: 10
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        style: {
          fontSize: '14px',
          colors: ["#B6B6B6"],
          fontWeight: 500,
        },
        offsetY: -20
      },
      toolbar: {
        show: false,
      },
      grid: {
        show: false
      },      
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: labelData,
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 550,
            colors: ['#242E42D9'], // Use an array for different colors for each label
          },
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      
      },
      fill: {
        opacity: 1,
        colors: ['#E8EBED', '#3A9CED']
      },
      legend: {
        show: false,
      },
    },

  }

  return (
    <Chart options={state.options} series={state.series} type="bar" width='100%' height='80%' />
  )

}


const PermitTypeCard = (props) => {
  const { parkingLots, defaultParkingLot, type, maxDate } = props

  const state = {
    state_data: {},
    from: null,
    to: null,
    datesToFilter: [
      {
        from: moment(),
        to: null,
        label: "Today",
        name: 'yesterday',
        text: `Today (${moment().format("L")})`,
        since: "since yesterday",
      },
      {
        from: moment().startOf("isoWeek"),
        to: moment().endOf("isoWeek"),
        label: "This week",
        name: 'last week',
        text: `This week (${moment()
          .startOf("isoWeek")
          .format("MM/DD")}-${moment().endOf("isoWeek").format("MM/DD")})`,
        since: "since last week",
      },
      {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        label: "This month",
        name: 'last month',
        text: `This month (${moment().startOf("month").format("MMM")})`,
        since: "since last month",
      },
      {
        from: moment().add(1,'days'),
        to: moment().add(1,'days'),
        label: "All time",
        name: 'all time',
        text: 'All time',
      },
    ],
    currentSinceText: "last week",
    tileInfoOpen: false,
  };
  const [data, setData] = useState();
  const [currentLabel, setCurrentLabel] = useState('Today')
  const [permitInfoOpen, setPermitInfoOpen] = useState(false);
  const [selectedParkingLot, setSelectedParkingLot] = useState([defaultParkingLot])
  const [permitTypes, setPermitTypes] = useState([])
  const [selectedPermitTypes, setSelectedPermitTypes] = useState([])
  const [modal, setModal] = useState(false);
  
  useEffect(() => {
    if((!isEmpty(parkingLots) && selectedParkingLot?.length ===1 && selectedParkingLot[0]?.label === "All Parking Lots")){
      setSelectedParkingLot(parkingLots);
    }
    
    if(data){
      // eslint-disable-next-line
      {Object.keys(data?.permits_data).forEach((type, idx) => {
        if(!permitTypes.some(person => person.label === type)){
          setPermitTypes((prevState) => [...prevState, {label: type, value: idx}])
          if(idx < 6) setSelectedPermitTypes((prevState) => [...prevState, {label: type, value: idx}])
        }
      })}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedParkingLot, parkingLots, setSelectedParkingLot, data, selectedPermitTypes])

  const fetchData = async (from, to, value) => {
    try {
      DetailedReportApi.index({
        configs: {pie_chart: { range: { from: from, to: to}}},
        resource: 'permit_types',
      }).then(({ data }) => {
        setData(data)
        setCurrentLabel(value === undefined ? 'Custom' : value?.label)
        setModal(false)
      });
    } catch (error) {
      console.log(error)
    }
  };

  const fetchInitialData = () => {
    try{
      if(data === undefined){setData()}
      DetailedReportApi.index({
        resource: 'permit_types',
      }).then(({ data }) => {
          setData(data)
        });
    }catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(isEmpty(data)){
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  const onChanges = (selectedPermitType) => {
    if(selectedPermitType.length < 7){
      setSelectedPermitTypes(selectedPermitType)
    }
  };

  const updateData = (selectedParkingLots, selectAllChecked) => {
    const isUserCheckedParkingLot = selectedParkingLots?.some((parkingLot) => parkingLot.value === 0);
    const filteredSelectedParkingLots = selectedParkingLots.filter(
      (parkingLot) => parkingLot.value !== 0
    );
    const selectedParkingLotsValues = filteredSelectedParkingLots.map(
      (selectedParkingLot) => selectedParkingLot.value
    );
    setSelectedParkingLot(selectAllChecked ? (isUserCheckedParkingLot ? parkingLots : []) : filteredSelectedParkingLots);
    DetailedReportApi.index({
      configs: {parking_lot_ids: selectedParkingLotsValues},
      resource: 'permit_types',
    }).then(({ data }) => {
      setData(data)
    });
  };

  const toggleTileInfo = () => {
    setPermitInfoOpen(!permitInfoOpen);
  };

  return(
    <React.Fragment>
      <Card className={style.borderPrimary}>
        {isEmpty(data) ? (
              <Loader />
            ) : (
              <React.Fragment>
                <CardTitle className={`${style.cardTitle} row`}>
                <Col className={`${style.title} pr-0`}>
                  Permit Types
                  {
                    <React.Fragment>
                      <button
                        className="ml-2 border-0 bg-white text-primary"
                        id={type}
                      >
                        <FontAwesomeIcon
                          color="#C7CED9"
                          icon={faQuestionCircle}
                        />
                      </button>
                      <Popover
                        placement="bottom"
                        isOpen={permitInfoOpen}
                        target={type}
                        toggle={toggleTileInfo}
                        trigger="click hover focus"
                      >
                        <PopoverBody>{data?.title}</PopoverBody>
                      </Popover>
                    </React.Fragment>
                  }
                </Col>
                <Col xs="auto" className="d-flex align-items-center pl-0">
                  <span className={style.secondaryText}>
                    {currentLabel}
                  </span>
                  {!data?.disable_date_range && 
                    <UncontrolledDropdown>
                      <DropdownToggle tag="span" className="pointer">
                        <ChevronDown width="18" height="18" />
                      </DropdownToggle>
                      <DropdownMenu right className={style.dateDropdown}>
                        {state.datesToFilter &&
                          state.datesToFilter.map((data) => (
                            <DropdownItem
                            className={style.dateDropdown1}
                              onClick={() => fetchData(
                                data?.from?.format("YYYY-M-D"),
                                data?.to ? data?.to?.format("YYYY-M-D") : null,
                                data
                              )}
                            >
                              {data.text}
                            </DropdownItem>
                          ))}
                          <DropdownItem
                            onClick={() => setModal(true)}
                          >
                            <span className="general-text-1">
                              Select custom...
                            </span>
                          </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                </Col>
                <Col xs="auto" className="pl-0">
                  <Dropdown
                    multiple
                    coveringText={() => coveringText(selectedPermitTypes)}
                    options={permitTypes}
                    onChange={onChanges}
                    value={selectedPermitTypes}
                    width="150px"
                    size="sm"
                    showToolTip={false}
                    customInputClass={style.className}
                  />
                </Col>
                <Col xs="auto" className="pl-0">
                  <Dropdown
                    multiple
                    coveringText={() => coveringText(selectedParkingLot)}
                    options={parkingLots}
                    onChange={updateData}
                    value={selectedParkingLot}
                    width="150px"
                    size="sm"
                    showToolTip={false}
                    customInputClass={style.className}
                  />
                </Col>
              </CardTitle>
                <BarGraph 
                  {...props}
                  data={data?.permits_data}
                  selectedPermitTypes={selectedPermitTypes}
                />
              {currentLabel !== 'Custom' && <div className={style.bottomButton}>
                {selectedPermitTypes?.map(type => (
                  <span key={type}>
                    <Button className={style.button}>{data?.permits_data?.[type?.label]?.percentage}</Button>
                  </span>
                ))}
              </div>}
            </React.Fragment>
          )
        }
      </Card>
      <DateModal
        maxDate={maxDate && maxDate.toDate()}
        isOpen={modal}
        apply={fetchData}
        toggleModal={() => setModal(false)}
        title='Permit Types'
      />
    </React.Fragment>
  );
}

const coveringText = (value) => {
  return( 
    <div className={style.lotContainer}>
      <span className={style.lotContainer1}>Selected</span>
      <span className={style.lotContainer2}>{value ? value.length : "0"}</span>
    </div>
  )
};

export default PermitTypeCard;