import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { update } from 'api/permit_waiting_lists';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Configure from '../configure';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const PermitWaitingListModal = (props) => {
  const { openModal, modalType, setActiveTab, record, setListElement } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
    setErrors({})
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const updateWaitlist = useCallback(async (data) => {
    // setState('isSaving', true)
    try {
      const response = await update({data: data, id: record?.id })
      setListElement(response.data)
      closeModal()
      addAlertMessages([{
        type: 'primary',
        text: `Changes on ID ${response?.data?.id} was successfully saved`,
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setErrors(error.response.data.errors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState,setErrors, setListElement, addAlertMessages, closeModal, record])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'configure':
        return {title: 'Configure Waitlist', maxWidth : '590px'}
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.vehicleModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'configure' && 
          <Configure
            {...props}
            state={state}
            setState={setState}
            closeModal={closeModal}
            updateWaitlist={updateWaitlist}
            setErrors={setErrors}
            errors={errors}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitWaitingListModal;
