import { ParkingCitationTicketAction } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(ParkingCitationTicketAction);

const ParkingCitationTicketReducers = combineReducers({
  index,
  records,
  filters
});

export default ParkingCitationTicketReducers;
