import React from 'react'
import { Col, Container, Row } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';
import { ReactComponent as FailIcon } from 'assets/app/fail-icon.svg';

const Confirmation = ({closeModal, disputeStatus}) => {

  const successMessage = () => {
    return (
      <React.Fragment>
        <Col style={{marginTop:"-1rem", marginBottom: '1rem'}}>
          <Row className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>Your dispute is under review, we will get back to</Row>
          <Row className={`${styles.content} d-flex justify-content-center`} style={{marginTop:"-1.5rem"}}>
            you as soon as we have an update.
          </Row>
        </Col>
      </React.Fragment>
      )
  }

  const failureMessage = () => {
    return (
      <React.Fragment>
        <Col style={{marginTop:"-1rem", marginBottom: '1rem'}}>
          <Row className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>Your dispute of this citation ticket is already present.</Row>
        </Col>
      </React.Fragment>
      )
  }

  return (
    
    <Container className='p-0'>
      <Col className="d-flex justify-content-center my-2 pb-2">
        {disputeStatus === 'success' && <SuccessIcon/>}
        {disputeStatus === 'failure' && <FailIcon/>}
      </Col>
      {disputeStatus === 'success' && <span className='mx-1 d-flex justify-content-center' style={{fontWeight:"16px"}}>Disputed</span>}
      {disputeStatus === 'failure' && <span className='mx-1 d-flex justify-content-center' style={{fontWeight:"16px"}}>Dispute Failed</span>}
    <Container className='mb-4' >
      <Row className="d-flex justify-content-center my-2">
        { disputeStatus === 'success' && successMessage() }
        { disputeStatus === 'failure' && failureMessage() }
      </Row>

      <Col className="d-flex justify-content-center my-2">
        {
          <Button 
              type='button' 
              onClick={() => {closeModal()}} 
              className={styles.confirmBtn}
            >
              OK
          </Button>
        }  
      </Col>
    </Container>
  </Container>
  )
}

export default Confirmation;
