import React, {useState, useEffect} from "react";
import { Select } from "antd";
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'
import { GoogleApiWrapper } from 'google-maps-react';
import env from '.env';
import GoogleMapsContainer from 'components/pages/homepage/google_maps/index'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getParkingLots, getParkingLot } from "api/webapp/parking_lots";
import { isEmpty } from "underscore";
import { Tabs, Card, Button, Input, Col , Row} from 'antd';
import styles from './homepage.module.sass'; // Import SASS styles
import NearbySpaces from './google_maps/nearbySpaces'
import withCurrentSubscriber from 'components/modules/with_current_subscriber';
import {Table} from 'reactstrap';
import ThemeProvider from "subscriberThemeProvider";
const exampleData = () => process.env.NODE_ENV === 'development' ? {

  lat: '38.771665',
  lng: '-76.0762605'
} : {};

const { TabPane } = Tabs;

const App = () => {
  const [vehicleType, setVehicleType] = useState('')
  // eslint-disable-next-line
  const [search, setSearch] = useState()
  const [location, setLocation] = useState(exampleData)
  const [selectedTab, setSelectedTab] = useState('map')
  const [showDetails, setShowDetails] = useState(false)
  // eslint-disable-next-line
  const [google, setGoogle] = useState()
  const [searchLocation, setSearchLocation] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [parkingLots, setParkingLots] = useState([])
  const [selectedParkingLot, setSelectedParkingLot] = useState(null)
  const [parkingLot, setParkingLot] = useState()

  const handleSelect = async (address) => {
    setSearchLocation(address);
  
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLocation(latLng);
      setSelectedLocation(latLng);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };
  
  const googleMapEvents = {
    onMapClick: (locationRequest) => {
      setLocation(locationRequest)
    }
  }

  useEffect(() => {
    const fetchParkingLots = async () => {
      try {
        const response = await getParkingLots({ltd: location?.lat? location.lat : location.ltd, lng:location?.lng, vehicle_type: vehicleType});
        if(response.status === 200){
            setParkingLots(response.data);
        }
      } catch (error) {
      }
    };
    if(vehicleType && !isEmpty(location)){
      fetchParkingLots();
    }
  }, [location, vehicleType]);

  useEffect(()=> {
    const fetchParkingLot = async () => {
      try {
        const response = await getParkingLot({id: selectedParkingLot?.id});
        if(response.status === 200){
          setParkingLot(response.data);
        }
      } catch (error) {
        console.log("Error");
      }
    };
    if(!isEmpty(selectedParkingLot)){
      fetchParkingLot();
    }
  }, [selectedParkingLot])


  const handleParkingLotSelection = (lot) => {
    setSelectedParkingLot(selectedParkingLot?.id === lot?.id ? null : lot);
    setShowDetails(true);
  };

  const hideDetails = () => {
    setShowDetails(false);
    setSelectedTab("list");
  }

  return (
    <ThemeProvider>
    <div className={styles.appContainer}>
      <Row className="m-0 p-0 d-flex justify-content-center text-center pt-2">
        <p className={styles.header}>Find Parking</p>
      </Row>
      <div className={styles.inputFields}>  
        <Select
          showSearch
          placeholder="Select Vehicle Type"
          optionFilterProp="label"
          onChange={(e) => setVehicleType(e)}
          value={vehicleType? vehicleType : undefined}
          className={styles.inputField}
          options={[{value:'gasoline', label:"Gasoline"}, {value: 'electric', label: "Electric"}]}
        />      
        <PlacesAutocomplete
          value={searchLocation}
          onChange={setSearchLocation}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: '🔍  Where would you like to Park ?',
                  className: `${styles.placesAutocompleteInput}`,
                  suffix: <LocationIcon/>
                })}
              />
              <div className={styles.placesAutocompleteDropdown}>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: styles.placesAutocompleteItem,
                    })}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      {!showDetails && <Tabs  defaultActiveKey={selectedTab} style={{cardBg:'green'}} className={showDetails? 'd-none' : styles.customTabs}>
        <TabPane tab="Map View" key="map">
          <div className={styles.borderContainer}>
            <div className={styles.borderContainerInner}>
              <GoogleMapsContainer events={googleMapEvents} lat={location?.lat? location.lat : location.ltd} lng={location?.lng} markerName={"Your Location"} autoFocus={true} setGoogle={setGoogle} search={search} selectedLocation={selectedLocation} parkingLots={parkingLots}  />
             </div>
          </div>
        </TabPane>
        <TabPane tab="List View" key="list">
          <div className={styles.listContainer}>
            {parkingLots?.map((item, index) => (
              <Card key={item?.id} onClick={() => handleParkingLotSelection(item)} className={styles.listCard} bordered={false}>
                <Row className={styles.cardContent} gutter={[16, 16]} align="middle">
                  <Col className="d-flex" span={4}>
                    <div className={styles.listItemDetails}>
                      <div className={vehicleType==='electric'? `${styles.squareWithCount} ${styles['squareWithCount-bg-secondary']}` :`${styles.squareWithCount} `}>
                        {vehicleType==='gasoline' ? `${item?.available_spaces?.gasoline}/${item?.total_spaces?.gasoline}` 
                        :vehicleType==='electric' ? `${item.available_spaces?.ev}/${item.total_spaces?.ev}` : ''}
                      </div>
                    </div>
                    <div className={styles.listItemDetails2}>
                      <div className={styles.itemTitle}>{item?.name}</div>
                    </div>
                  </Col>
                  {vehicleType==='gasoline' && <Col className={`${styles.nameCont}`} span={8}>
                    <div className={styles.listItemDetails3}>
                      <div className={styles.itemTitle}>{"Address"}</div>
                      <div className={styles.itemDescription}>{item?.location?.full_address}</div>
                    </div>
                  </Col>}
                  {vehicleType === 'electric' && (
                    <Col className={`${styles.nameCont}`} span={8}>
                      <div className={styles.listItemDetails6}>
                        {item?.chargers.map((charger, idx) => (
                          <React.Fragment key={idx}>
                            <div className={styles.additionalDetails2}>{charger.name} {`(${charger.kw_rating} kW)`}</div>
                            {idx < item.chargers.length - 1  && <div className={styles.line}></div>}
                          </React.Fragment>
                        ))}
                        
                      </div>
                    </Col>
                    )}
                  
                  {vehicleType==='gasoline' && <Col className={styles.rightContent} span={4}>
                  {vehicleType==='gasoline' && <p className={styles.paid}>{`$${item?.car_rate ? item.car_rate : 0.0}`}</p>}
                  {vehicleType==='gasoline' && <p className={styles.unpaid}>{"Per Hour"}</p>}
                  <Button onClick={() => setShowDetails(!showDetails)} className={styles.button} type="primary">More Details</Button>
                </Col>}
                {vehicleType==='electric' &&  <Col className={`${styles.nameCont2}`} span={4}>
                  <div className={styles.listItemDetails7}>
                    {item.chargers.map((charger,idx) => 
                      <React.Fragment key={idx}>
                        <div className={styles.additionalDetails3}>${charger?.cost_per_kwh}</div>
                        <div className={styles.perHourEl}>Per Hour</div>
                      </React.Fragment>
                    )}
                    
                  </div>
                  </Col>}
                </Row>
                {vehicleType==='electric' && <Row className={styles.listItemDetails4}>
                    <div className={styles.itemTitle}>{"Address"}</div>
                    <div className={styles.itemDescription}>{item?.location?.full_address}</div>
                    <Col className={styles.buttonCol}>
                      <Button className={styles.button} type="primary" onClick={()=> setShowDetails(!showDetails)}>More Details</Button>
                    </Col>
                </Row>}
              </Card>
            ))}
          </div>
        </TabPane>
      </Tabs>}
      {showDetails && <div className={styles.detailedRecordContainer}>
        <Row><Button onClick={hideDetails}>{"<< Go Back to the List"}</Button></Row>
        <div className={styles.topRow}>
          <div className={vehicleType==='electric'? `${styles.squareWithCountDetails} ${styles['squareWithCount-bg-secondary']}` :`${styles.squareWithCountDetails} `}>{vehicleType==='gasoline' ? `${parkingLot?.available_spaces?.gasoline}/${parkingLot?.total_spaces?.gasoline}` 
            :vehicleType==='electric' ? `${parkingLot?.available_spaces?.ev}/${parkingLot?.total_spaces?.ev}` : ''}
          </div>
          <div className={styles.textContainer}>
            <p className={styles.itemTitle}>{parkingLot?.name}</p>
            <p>{parkingLot?.location?.full_address}</p>
          </div>
          <Row className={styles.squareContainerRight}>
            <Col className={`${styles.parkingSpaces}`}>
              <p>Parking Spaces: </p>
            </Col>
            <Col className={`${styles.parkingSpacesCount}`}>
              <p className={styles.redTxt}>
                {vehicleType==='gasoline'? parkingLot?.occupied_spaces?.gasoline : vehicleType ==='electric' ? parkingLot?.occupied_spaces?.ev : ''} <span>Occupied</span>
              </p>
              <p className={styles.greenTxt}> 
                {vehicleType==='gasoline'? parkingLot?.available_spaces?.gasoline : vehicleType ==='electric' ? parkingLot?.available_spaces?.ev : ''} <span>Available</span>
              </p>
              <p className={styles.greenTxt}> 
                {vehicleType==='gasoline'? parkingLot?.total_spaces?.gasoline : vehicleType ==='electric' ? parkingLot?.total_spaces?.ev : ''} <span>Total</span>
              </p>
            </Col>
          </Row>
        </div>
        {vehicleType==='gasoline' && 
          <Row className={styles.bottomRowGasoline}>
            <Col>Hourly Rate: $ {parkingLot?.car_rate} / Hour</Col>
          </Row>
        }
        {vehicleType==='electric' && <Row className={styles.bottomRow}>
          <div className={styles.line}></div>
          <Table className={styles.table}>
            <thead>
              <tr>
                <th>Available Chargers</th>
                <th>Rates</th>
                <th>Slot Number</th>
                <th>Compatible Connectors</th>
              </tr>
            </thead>
            <tbody>
              {parkingLot?.ev_slots?.map((slot, idx) => (
                <tr key={idx}>
                  <td>{slot?.charger?.name}</td>
                  <td>$ {slot.charger.cost_per_kwh} Per KwH</td>
                  <td>{slot?.name}</td>
                  <td>
                    {slot.charger.connectors?.map((c, idx) =>{return `${c}${idx + 1 < slot?.charger?.connectors?.length ? ' , ' : ''}`} )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className={styles.line}></div>
        </Row>}
        <NearbySpaces google={google} parkingLotLocation={{lat: parkingLot?.location?.ltd, lng: parkingLot?.location?.lng, name:parkingLot?.name}} places={parkingLot?.nearby_places[0]}/>
      </div>}
    </div>
    </ThemeProvider>
  );
};

export default GoogleApiWrapper({
  apiKey: env.google_cloud_api_key
})(withCurrentSubscriber(App))