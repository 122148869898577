import { FieldType } from "components/helpers/form_fields";

const contactFields = (attrs) => {
  const { customInputClass='', customLabelClass='', t } = attrs;
  return [
    {
      name: 'event_parking_application[contact_person_name]',
      label: t("events.city_events.name"),
      customInputClass,
      customLabelClass,
      disabled: false,
    },
    {
      name: 'event_parking_application[organizer_email]',
      label:  t("events.private_events.email"),
      customInputClass,
      customLabelClass,
      disabled: false,
    },
    {
      name: 'event_parking_application[organizer_phone_number]',
      label:  t("events.private_events.phone"),
      type: FieldType.PHONE_NUMBER_FIELD,
      placeholder: t("events.private_events.edit_validations.enter_phone_number"),
      customInputClass,
      customLabelClass,
      disabled: false,
    },     
  ]
};

export { contactFields }