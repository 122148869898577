import React, { useCallback} from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
/* Assets */
import styles from './confiramtion.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col } from 'reactstrap';

const ConfirmationModal = (props) => {

  const { title, text, accept, cancel, isOpen, toggleModal } = props


  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={cancel}
    />
  },[cancel]);

  return (
    <Modal 
      isOpen={isOpen} 
      centered 
      contentClassName={styles.modal} 
      toggle={toggleModal}
      style={{maxWidth: 480, width: '100%'}} 
     >
      <ModalHeader close={closeBtn()} className='border-0 pb-0' /> 
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{title ? title : "Confirmation" }</p>
        <br/>
        <Col className={`${styles.body} justify-content-center my-3`}>
        <div className={`${styles.content} justify-content-center my-3`}>
          {text}
        </div>
        <br/>
        <Col className="d-flex justify-content-center text-align-center my-3">
          <Button onClick={() => cancel} type='button' className={`${styles.cancelButton} mr-4`}>
            Cancel
          </Button>
          <Button type='button' onClick={accept} className={`${styles.submitButton}`} >
            Delete
          </Button>
        </Col>
      </Col>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal;