import React from 'react';
/* Styles/Assets */
import styles from '../index.module.sass';
import DisputeForm from './disputeForm';

const DisputeDetails = (props) => {
  return (
    <React.Fragment>
      <section className={styles.subSection}>
        <DisputeForm {...props} />
      </section>
    </React.Fragment>
  )
}
export default DisputeDetails;
