import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { ROLES } from "config/permissions";
import { SET_LIST, SET_SINGLE_RECORD, SET_FILTERS, ADD_LIST_ELEMENT, SET_LIST_ELEMENT } from 'actions/violators';
import { filterFetcher } from 'api/violators';
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
import AccessControl from "components/helpers/access-control";
import TableData from "./tableData";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import ViolatorModal from "../violatorModal";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Index = (props) => {
  const { isResourceFetching, currentUser } = props;
  const [modalType, setModalType] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState(null)

  const openModal = useCallback((val) => {
    setModalType(val)
  },[setModalType])

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            total={props.total}
            modalType={modalType}
            openModal={openModal}
            isFetching={isFetching}
          />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
            />
          </Col>
        </Row>
      </Container>
      <ViolatorModal
        {...props}
        modalType={modalType}
        openModal={openModal}
        selectedRecord={selectedRecord}
      />
    </AccessControl>
  )
};

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  record: PropTypes.object,
};

const resource = "violators";

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS),
      addListElement: invoke(ADD_LIST_ELEMENT), 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.violators.filters,
    record: state.violators.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
);
