import React, { useCallback } from "react";
import TableRow from "./table_row";
/* Api */
import { filterFetcher } from "api/user_coupons";
/* Styles */
import styles from "./index.module.sass";
/* Base */
import { IndexTable } from "components/base/table";

const TableData = (props) => {
  const {
    list,
    setRecord,
    filters,
    isResourceFetching,
    openModal,
    modalType,
    selectedRecord,
    activeTab,
  } = props;
  const renderRecords = useCallback(() => {
    return list?.map((record) => {
      return (
        <TableRow
          key={record.id}
          record={record}
          selectedRecordId={selectedRecord.id}
          openModal={openModal}
          modalType={modalType}
          setRecord={setRecord}
        />
      );
    });
  }, [list, modalType, setRecord, openModal, selectedRecord.id]);

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ""}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcher}
      resource='coupons'
      paginationQuery={{...filters}}
      columns={
        <React.Fragment>
          <th attr="coupon_code" style={{ width: '20%', minWidth: '210px', }} >Coupon Code</th>
          <th attr="created_at" style={{ width: '45%', minWidth: '210px' }}> Generated On </th>
          <th attr="reason" style={{ width: '45%', minWidth: '210px' }}> Reason </th>
          <th attr="amount" style={{ width: '45%', minWidth: '210px' }}> Coupon Amount </th>
          <th attr="status" style={{ width: '45%', minWidth: '210px' }}> Used? </th>
          <th attr="expiry_date" style={{ width: '45%', minWidth: '210px' }}> Expiry Date </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="User Coupons"
    />
  );
};

export default TableData;