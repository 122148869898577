import { FieldType } from 'components/helpers/form_fields';

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate}) => [
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },

];

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass='', parkingLots=[], iconInputClass='', icon} = attrs;

  return [
    {
      name: "parking_lot_id",
      label: "Parking Lot",
      customInputClass,
      customLabelClass,
      type: FieldType.SELECT_FIELD,
      options: parkingLots.map((parkingLot) => {
        return { value: parkingLot.value, label: parkingLot.label }
      }),
      showToolTip: false,
      mandatory: true,
    },
    {
      name: 'unpaid_citations_for_boot_violation',
      label: 'How many unpaid citations constitute a boot violation?',
      customInputClass,
      customLabelClass,
      mandatory: true,
      type: FieldType.NUMBER_FIELD,
      min: 1,
    },
    {
      name: 'fine',
      label: 'What is the boot removal fee, if any?',
      placeholder: 'Boot Removal Fee',
      icon: icon,
      customLabelClass,
      customInputClass: iconInputClass,
      mandatory: true,
    },
    {
      name: 'grace_period_before_impounding',
      label: 'What is the grace period after booting before a vehicle is impounded? (days)',
      customInputClass,
      customLabelClass,
      mandatory: true,
      type: FieldType.NUMBER_FIELD,
      min: 1,
    },
    {
      name: 'unpaid_citations_to_send_email',
      label: 'How many unpaid citation tickets to send a warning email?',
      customInputClass,
      customLabelClass,
      mandatory: true,
      type: FieldType.NUMBER_FIELD,
      min: 1,
    },
    {
      name: "days_to_resend_email",
      label: "Resend warning email after how many days?",
      customInputClass,
      customLabelClass,
      showToolTip: false,
      type: FieldType.NUMBER_FIELD,
      min: 1,
      mandatory: true,
    },
    {
      name: "compose_email",
      label: "Compose warning email",
      placeholder: 'Type your message here...',
      customInputClass,
      customLabelClass,
      type: FieldType.TEXT_AREA,
      mandatory: true,
    },
  ]
}

const parkingLotFields = (attrs) => {
  const { customInputClass='', customLabelClass='', parkingLots=[]} = attrs;
  
  return [
    {
      name: "parking_lot_id",
      customInputClass,
      customLabelClass,
      type: FieldType.SELECT_FIELD,
      options: parkingLots
    },
  ]
}

export { filterFields, newFields, parkingLotFields };
