import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { ROLES } from "config/permissions";
import { withRouter } from "react-router";
/* API */
import { usersEnrolledFilterFetcher } from "api/reward_types";
/* Modules */
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";
import connectList from "components/modules/connect_list";
import resourceFetcher from "components/modules/resource_fetcher";
/* Helpers */
import AccessControl from "components/helpers/access-control";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/* Actions */
import {
  ADD_LIST_ELEMENT,
  POP_LIST_ELEMENT,
  SET_LIST,
  SET_LIST_ELEMENT,
  SET_SINGLE_RECORD,
  SET_FILTERS,
} from "actions/users_enrolled";
import { invoke } from "actions";
/* Components */
import ToolBar from "./toolbar";
import TableData from "./table_data";
import RewardModal from "../reward_modal";

const Index = (props) => {
  const { currentUser, location } = props;
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState();
  const [selectedRecord, setSelectedRecord] = useState()
  const { rewardsRecord } = location.state;

  const openModal = useCallback(
    (modalType) => {
      setModalType(modalType);
    },
    [setModalType]
  );

  useEffect(() => {
    setSelectedRecord(rewardsRecord)
  }, [rewardsRecord])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className="mw-100 p-0">
        <Row className="m-auto">
          <Col xs={12} className="p-0">
            <ToolBar
              {...props}
              selectedRecord={selectedRecord}
              openModal={openModal}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Col>
          <Col xs={12} className="p-0">
            <TableData
              {...props}
              openModal={openModal}
              activeTab={activeTab}
              modalType={modalType}
            />
          </Col>
          <RewardModal
            selectedRecord={selectedRecord}
            setSelectedRecord={setSelectedRecord}
            source="details"
            modalType={modalType}
            openModal={openModal}
            {...props}
            setActiveTab={setActiveTab}
          />
        </Row>
      </Container>
    </AccessControl>
  );
};

const resource = "user_enrolled";

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT),
      setListElement: invoke(SET_LIST_ELEMENT),
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS),
    },
    dispatch
  );
};

const mapState = (state) => {
  return {
    filters: state.user_enrolled.filters,
    record: state.user_enrolled.records.record,
  };
};

export default withRouter(
  connectList(
    resource,
    SET_LIST,
    resourceFetcher(usersEnrolledFilterFetcher, resource),
    connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
    { fetchCondition: () => true }
  )
);