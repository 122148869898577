import { generatePath } from "react-router";
import fetchApi from "components/modules/fetch_api";
import resourceApi from "components/modules/resource_api";

const { index, create, destroy } = resourceApi("themes");

const filterFetcherDashboard = (params = {}) => {
  const { page = 1, perPage = 10, query={theme_category: 'dashboard'} } = params;

  return index({
    page,
    perPage,
    query  
  });
};

const update = (params = {}) => {
  const { id, data = {} } = params;
  return fetchApi(generatePath(`dashboard/themes/${id}`), {
    method: 'PUT',
    data: data,
  })
};

const applyTheme = (id) => {
  return fetchApi(generatePath(`dashboard/themes/${id}/apply_theme`), {
    method: 'PUT',
  })
};

export { filterFetcherDashboard, create, update, destroy, applyTheme }