import React, { useEffect, useRef } from 'react'
import { Col, Container } from 'reactstrap';
import { isEmpty,} from 'underscore';
import { renderField } from 'components/base/forms/common_form';
import { revokeField } from 'components/helpers/fields/permits/index';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { Form } from 'informed';

const RevokePermit = ({revokePermit, isSaving, errors, setState, record, closeModal}) => {

  const formApiRef = useRef();
  const isSavingRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  
  const save = () => {
    const {values: formValues} = formApiRef.current.getState();
    revokePermit(Object.assign(formValues, {id: record?.id}))
  }
  
  useEffect(() => {
    setState('errors', {})
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(isSavingRef.current === true && isSaving === false){
      if(isEmpty(errors)) { closeModal() }
    }
    isSavingRef.current = isSaving;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isSaving])

  return (
    <Container>
      <p className={`${styles.title} m-0 p-0`}>Are you sure?</p>
      <Col className={styles.body}>
        <div className={styles.content}>
          <Col className='my-3 p-0'>
            <span>This will revoke the</span>
            {' '}
            <span className={styles.boldText}>{record.permit_type}</span>
            {' '}
            <span> permit from</span>
            {' '}
            <span>{`${record.vehicle?.owner_name}'s`}</span>
            {' '}
            <span>vehicle with plate number</span>
            {' '}
            <span className={`${styles.boldText} text-uppercase`}>{`${record.vehicle?.plate_number}.`}</span>
            {' '}
            <span>This action cannot be undone.</span>
          </Col>
        </div>
        <Col className="mb-3 p-0">
          <fieldset disabled={isSaving}>
            <Form getApi={setFormApi} onSubmit={save} >
              {renderField(revokeField(styles.formTextLabel, styles.formTextInput), { errors })}
              <Col className="d-flex justify-content-center mb-3 mt-4">
                <Button onClick={closeModal} type='button' className={`${styles.cancelButton} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={`${styles.submitButton}`}  disabled={isSaving}>
                  Revoke
                </Button>
              </Col>
            </Form>
          </fieldset>
        </Col>
      </Col>
    </Container>
  )
}

export default RevokePermit;
