import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";

const { index } = resourceApi("coupons");
const search = searchApi("coupons");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, id, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      user_id: id,
      ...filters,
    },
  });
};

export { filterFetcher, index, search };