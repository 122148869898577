import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Create from '../create';
/* Assets */
import styles from './index.module.sass'
/* API */
import { ADD_LIST_ELEMENT } from 'actions/monitor_vehicles';

import { create } from 'api/monitor_vehicles';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const VehicleModal = (props) => {
  const { openModal, modalType, setActiveTab } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const addListElement = useCallback((payload) => {
    dispatch({type: ADD_LIST_ELEMENT, payload});
  }, [dispatch]);

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
  }, [openModal, setActiveTab])

  

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const addVehicle = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: {vehicle_monitor: data}})
      addListElement({setList: setList, data: response.data})
      closeModal()
      addAlertMessages([{
        type: 'primary',
        text: `Added Vehicles to Be Monitored Successfully`,
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState,setErrors, addListElement, addAlertMessages, closeModal])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'create':
        return {title: 'Add Vehicles to Monitor', maxWidth : '550px'}
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.vehicleModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'create' && 
          <Create
            state={state}
            setState={setState}
            closeModal={closeModal}
            addVehicle={addVehicle}
            setErrors={setErrors}
            errors={errors}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default VehicleModal;
