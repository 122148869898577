import React, { useCallback, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { list as selectList } from 'selectors/list';
/* API */
import { filterFetcher } from 'api/permit_types';
import { filterFetcher as statusFetcher } from 'api/permit_type_codes';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import connectList from 'components/modules/connect_list';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/* Actions */
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/permit_types';
import { ADD_LIST_ELEMENT as ADD_PERMIT_TYPE_CODE_LIST_ELEMENT } from 'actions/permit_type_codes';
import{ POP_LIST_ELEMENT as POP_PERMIT_TYPE_CODE_ELEMENT } from 'actions/permit_type_codes';
import{ SET_LIST_ELEMENT as SET_PERMIT_TYPE_CODE_ELEMENT } from 'actions/permit_type_codes';
import{ SET_SINGLE_RECORD as SET_SINGLE_PERMIT_TYPE_CODE_RECORD } from 'actions/permit_type_codes';
import { invoke } from 'actions';
/* Components */
import { isEmpty } from 'underscore';
import ToolBar from './toolBar';
import PermitTypeCodeModal from '../permit_type_codes_modal';
import TableData from './tableData';
import resourceFetcher from 'components/modules/resource_fetcher';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [code, setCode] = useState()
  const [initialList, setInitialList] = useState([])
  const [permitTypeCodeList, setpermitTypeCodeList] = useState([])

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  const initialFilterPermitType = useCallback(async (data) => {
    const response = await filterFetcher({perPage: 10, query: {permit_type_code_id: data?.id}});
      setInitialList(selectList(response));
  }, [setInitialList])

  useEffect(() => {
    const fetchStatus = async () => {
      setInitialList([])
      const response = await statusFetcher({perPage: 10});
      if(response?.status === 200){
        setCode(response?.data[response?.data?.length-1])
        initialFilterPermitType(response?.data[response?.data?.length-1])
      }
    }
    if (isEmpty(code)) { fetchStatus() };
  },[code, initialFilterPermitType])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
              <ToolBar 
                {...props}
                openModal={openModal}
                modalType={modalType}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                code={code}
                setCode={setCode}
                setInitialList={setInitialList}
                permitTypeCodeList={permitTypeCodeList}
                setpermitTypeCodeList={setpermitTypeCodeList}
              />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              openModal={openModal}
              activeTab={activeTab}
              modalType={modalType}
              code={code}
              initialList={initialList}
            />
          </Col>
        </Row>
        <PermitTypeCodeModal
          {...props}
          code={code}
          setCode={setCode}
          openModal={openModal}
          modalType={modalType}
          setActiveTab={setActiveTab}
          setpermitTypeCodeList={setpermitTypeCodeList}
        />
      </Container>
    </AccessControl>
  )
  
}

const resource = 'permit_type';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD),
      addPermitTypeCodeListElement: invoke(ADD_PERMIT_TYPE_CODE_LIST_ELEMENT), 
      setPermitTypeCodeListElement: invoke(SET_PERMIT_TYPE_CODE_ELEMENT), 
      popPermitTypeCodeListElement: invoke(POP_PERMIT_TYPE_CODE_ELEMENT),
      setPermitTypeCodeRecord: invoke(SET_SINGLE_PERMIT_TYPE_CODE_RECORD), 
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.permit_type.filters,
    record: state.permit_type.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {},
  SET_FILTERS
);
