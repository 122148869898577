import React, { useCallback, useState, useEffect } from 'react';
import { ROLES } from "config/permissions";
import { Card, Col, Container, Row } from 'reactstrap';
import { map } from 'underscore';
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/broadcast_messages';
import { invoke } from 'actions';
import AccessControl from "components/helpers/access-control";
import Paggy from "react-js-pagination";
import getRangeOfPage from 'components/modules/get_range_of_page';
import { filterFetcher } from 'api/broadcast_messagse';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './index.module.sass';
import connectList from 'components/modules/connect_list';
import resourceFetcher from 'components/modules/resource_fetcher';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from "components/modules/with_current_user";
import { ReactComponent as SendIcon } from 'assets/send-icon.svg'
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import ToolBar from "./toolbar";
import BroadCastModal from "../broadcast_modal";
import { dateFormatter } from "components/helpers";

const Index = (props) => {
  const { setRecord, isResourceFetching, currentUser, match, filters, setFilters } = props

  const [activeTab, setActiveTab] = useState(null);
  const [modalType, setModalType] = useState()
  const [list, setList ] = useState();
  const [count, setCount] = useState();
  const pageRange = getRangeOfPage(count, props.page, props.perPage).join(' - ');
  const [currentPage, setCurrentPage] = useState(props.page);
  const [listType, setListType] = useState('sent');

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  useEffect(() => {
    if(!Object.entries(filters).length){
      const initialFilters = {...filters, status: listType}
      if(listType === 'sent'){
        Object.assign(initialFilters, {...initialFilters, status: ['resent', 'sent']})
      }
      setFilters(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters, listType, filters]);

  const open= async (page) => {
    const response = await filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters }, {page: page}));
    if(response.status === 200){
      setCurrentPage(page)
      setList(response.data)
      setCount(response?.headers['x-total'])
      setFilters(filters)
    }
  }
  
  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0 pb-4'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            {...props}
            resource={resource}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFetching={isResourceFetching}
            setListType={setListType}
            listType={listType}
            modalType={modalType}
            setList={setList}
            openModal={openModal}
            setCount={setCount}
          />
          </Col>
          <Col xs={12} className={styles.tableContainer}>
            {map(list, item => ( 
              <Card className={styles.card} >
                <Row className="h-100 d-flex ">
                  <Col xs={11} onClick={() =>  {openModal('show'); setRecord(item)}}>
                    <Row className={styles.text2}>
                      {
                        listType=== 'scheduled' &&
                        <div>
                          <span className={styles.scheduleName}>Scheduled for: </span>
                          <span>{item?.schedule_date_and_time !== null && dateFormatter(`${item?.schedule_date_and_time?.[0].date} ${item?.schedule_date_and_time?.[0].time}`)}</span>
                        </div>
                      }
                      {listType=== 'sent' && item.status === 'sent' && item.created_at_parsed}
                      {listType=== 'sent' && item.status === 'resent' && 
                      <div>
                      <span className={styles.scheduleName}>Resent on: </span>
                      <span>{item?.resent_on}</span>
                    </div>}
                    </Row>
                    <Row className={styles.text1}>
                      {item.message}
                    </Row>
                  </Col>
                  <Col xs={1} className={styles.iconTwo}>
                    {listType=== 'sent' && <SendIcon onClick={() =>  {openModal('resend'); setRecord(item)}} className={styles.sendIcon}/>}
                    {
                        listType=== 'scheduled' &&
                        <div className='d-flex'>
                          <span className={styles.scheduleName}><EditIcon className={styles.editIcon} onClick={() =>  {openModal('edit'); setRecord(item)}}/> </span>
                          <span><TrashIcon onClick={() =>  {openModal('delete'); setRecord(item)}} className={styles.trashIcon}/></span>
                        </div>
                      }
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
        </Row>
        <BroadCastModal
          {...props}
          modalType={modalType} 
          openModal={openModal} 
          setList={setList}
          setCount={setCount}
         />
        <div className="paginationWrapper mx-auto bg-white">
           { (
             <span className="general-text-3">
               {`Displaying ${pageRange} of ${count} Boradcast Messages`}
             </span>
           )}
           <Paggy
             activePage={currentPage}
             itemsCountPerPage={props.perPage}
             totalItemsCount={count}
             pageRangeDisplayed={10}
             onChange={open}
             innerClass={`${styles.pagination} m-0`}
             itemClass={styles.paginationItem}
             linkClass={`general-text-1 ${styles.paginationLink}`}
             activeClass={styles.active}
             disabledClass={styles.disable}
             itemClassNext={styles.itemNext}
             itemClassPrev={styles.itemPrev}
           />
         </div>
      </Container>
    </AccessControl>
  )
}

const resource = 'broadcast_messages';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.broadcast_messages.filters,
    record: state.broadcast_messages.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {},
  SET_FILTERS
);