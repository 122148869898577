import resourceApi from "components/modules/resource_api";

const { index, create, update, destroy } = resourceApi("account_types");

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

export { filterFetcher, create, update, destroy };
