import fetchApi from 'components/modules/fetch_api';

const  index = (params = {}) => {
  const { query } = params;
  return fetchApi('v1/vehicles?per_page=100000', { method: 'GET', params: { ...query } })
};

const filterFetcher = (params = {}) => {
  const { page, perPage, query, status, plate_number } = params;
  return index({
    page,
    perPage,
    query: {
      ...query,
      vehicle: {
        status: status!== 'all' ? status : null,
        plate_number: plate_number,
      },
    }
  });
};

const create = (params) => {
  const { data } = params
  return fetchApi('v1/vehicles', { method: 'POST', data })
};

const update = (params) => {
  const { data, id } = params
  return fetchApi(`v1/vehicles/${id}`, { method: 'PUT', data })
};

export {
  filterFetcher,
  create,
  update
};
