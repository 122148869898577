import React, { useState} from 'react'
/* API */
import { sendConfirmationInstructions } from 'api/webapp/subscriber';
/* Base */
import CardLayout from 'components/base/layout/card';
import AuthLayout from 'components/base/layout/auth';
import { isEmpty } from 'underscore';
import {
  Button,
  Input,
} from "reactstrap";
/* Modules */
import RedirectIfAuthorized from 'components/modules/redirect_if_authorized';
import styles from './index.module.sass'
import ThemeProvider from 'subscriberThemeProvider';
import WebAppHeader from 'components/base/webapp_header';
import { useTranslation, Trans } from 'react-i18next'

const AccountConfirmation = (props) => {
  const {  history } = props
  const [registeredEmail, setRegisteredEmail] = useState('')
  const [error, setError] = useState({})
  const { t } = useTranslation()

  const resendToken = async ()  => {
    try {
      const response = await sendConfirmationInstructions(registeredEmail)
      if(response.status === 200 ){
        window.sessionStorage.setItem("userEmail", registeredEmail);
        window.sessionStorage.setItem("userName", response?.data?.first_name);
        localStorage.removeItem('countdownEndTime');
        localStorage.setItem("otpTime", new Date(new Date(Date.now() + (20 * 1000))).toISOString()); 
        history.push("/confirm_user_account", { doLater: true });
      }
    } catch (error) {
      setError(error.response?.data?.error)
    }
  };
    
  return (
    <ThemeProvider>
      <WebAppHeader/>
      <AuthLayout>
        <h1 className={ `${styles.title} h1-title-primary mb-8 text-center`}>{t("account_confirmation_page.title")}</h1>
        <br/>
        <p className={ `${styles.text}`}> {t("account_confirmation_page.instruction")}</p>
        <CardLayout title="Confirm otp">
          <form >
            <div className="form-label-group">
              <label className="general-text-3" htmlFor="password">{t("account_confirmation_page.email.label")}</label>
              <Input
                id="email"
                type="text"
                value={registeredEmail}
                name="registeredEmail"
                onChange={e => setRegisteredEmail(e.target.value)}
                className={`form-control-lg ${!isEmpty('') ? 'input-error' : ''}`}
                placeholder={t("account_confirmation_page.email.placeholder")}
                required
                autoFocus
              />
            </div>
            <Button className={`${styles.button} mt-4 p-3 text-uppercase btn-lg btn-block`} onClick={resendToken}>{t("account_confirmation_page.send_confirmation_btn")}</Button>
          </form>
          <br/>
        </CardLayout>
          {error === 'Email Address invalid. Try again'  && 
            <p style={{color: 'red', textAlign: 'center'}}> 
              <Trans
                i18nKey="account_confirmation_page.not_found"
                components={[<span key="0" onClick={() => history.push("/signup")} style={{cursor:`pointer`, color: `#3A9CED`, fontWeight: 'bold'}}/>]}
              />
            </p>
          }
  
          {error === 'Account is already confirmed' && 
            <p style={{color: 'red', textAlign: 'center'}}>
              <Trans
                i18nKey="account_confirmation_page.already_exists"
                components={[<span key='0' onClick={() => history.push("/login")} style={{cursor:`pointer`, color: `#3A9CED`, fontWeight: 'bold'}} />, <em key="1" />]}
              />
            </p>
          }
  
          {error === 'Please provide an email address' &&
            <p style={{color: 'red', textAlign: 'center'}}>{t("account_confirmation_page.required")}</p>
          }
      </AuthLayout>
    </ThemeProvider>
  );
}

export default RedirectIfAuthorized(AccountConfirmation);
