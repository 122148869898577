import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'informed';
import moment from 'moment';
import { show_session } from 'api/webapp/notifications';
import Loader from 'components/helpers/loader';
import { detailFields } from 'components/helpers/fields/parking_sessions';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { renderFields } from 'components/base/forms/common_form';

const ParkingSession = ({record, openInnerModal}) => {
  const [isFetching, setIsFetching] = useState()
  const [session, setSession] = useState() 
  
  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel
  }
  
  const doubleFieldProps = {
    lSize: 5,
    iSize: 7
  }

  const locationFieldProps = {
    lSize: 2,
    iSize: 10
  }

  const sessionData = (data) => {
    const { id, created_at, total_price, lot={}, entered_at, exit_at, vehicle={}, status, duration } = data
    return ({
      id: id,
      created_at: new Date(created_at * 1000).toDateString(),
      total_price: total_price,
      entered_at: new Date(entered_at * 1000).toDateString(),
      exit_at: new Date(exit_at * 1000).toDateString(),
      rate: lot?.car_rate,
      status: status.toUpperCase(),
      lot: {
        name: lot?.name,
        full_address: lot?.location?.full_address,
      },
      vehicle: {
        plate_number: vehicle.plate_number?.toUpperCase(),
      },
      duration: moment(duration).format("h:mm"),
    })
  }

  
  useEffect(() => {
    setIsFetching(true)
    const fetchNotification = async () => {
      try {
        const response = await show_session({id: record.parking_session_id})
        const showData = sessionData(response.data);
        setSession(showData)
      } catch (error) {
        console.log(error)
      }
      finally {
        setIsFetching(false)
      }
    }
    fetchNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  

  if(isFetching) { return <Loader /> }
  const fields = detailFields(fieldAttrs)
  
  return (
    <Container className=' my-3'>
      <div className='mb-1'>
        <p className={`${styles.title} mx-0 mb-2 p-0`}>Parking Session Details</p>
        <p className={`${styles.category} text-capitalize m-0 pt-2 pb-2`}> Parking Duration </p>
        <p className={`${styles.category1} text-capitalize m-0 p-0 pb-4`}> {session?.duration} Hour </p>
      </div>
      <Form initialValues={session} className={styles.form}>
        <Row className='d-flex flex-wrap px-0'>
          <Col className={styles.fieldset}>
            {renderFields(fields.slice(0,2), {...doubleFieldProps})}
          </Col>
          <Col className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
            {renderFields(fields.slice(2,4), {...doubleFieldProps})}
          </Col>
        </Row>
        <Col className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']} pl-2`} xs={12}>
            {renderFields(fields.slice(4,5), {...locationFieldProps})}
        </Col>
        <Row className='d-flex flex-wrap px-0'>
          <Col className={styles.fieldset}>
            {renderFields(fields.slice(5,8), {...doubleFieldProps})}
          </Col>
          <Col className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
            {renderFields(fields.slice(8,10), {...doubleFieldProps})}
          </Col>
        </Row>
        <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
          <Button type='button' className={`${styles.btn}  ${styles['btn-cancel']} mr-4`}>
            Set Time
          </Button>
          <Button onClick={openInnerModal} type='submit' className={`${styles.btn} ${styles['btn-dispute']}`}  >
            Raise a dispute
          </Button>
        </Col>
      </Form>
    </Container> 
  )
}

export default ParkingSession;