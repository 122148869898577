import faker from 'faker'
import { FieldType } from 'components/helpers/form_fields'
import permissions from 'config/permissions';
import fieldsWithPermission from './fields_with_permission';
import { capitalize } from '..';

const fieldsNew = (managers = [], admins = [], renderLocationModal, mute=false) => (
  [
    { name: 'name', label: 'Parking Lot Title', mandatory: true, autoFocus: true, disabled: mute },
    { name: 'parking_admin_id', label: 'Parking Operator', type: FieldType.SELECT_FIELD, options: admins.map(admin => { return { value: admin.value, label: admin.label } }), disabled: mute },
    {
      name: 'location',
      label: 'Location',
      mandatory: true,
      render: renderLocationModal,
      disabled: mute
    },
    { name: 'town_manager_id', label: 'Town Manager', mandatory: true, type: FieldType.SELECT_FIELD, options: managers.map(manager => { return { value: manager.value, label: manager.label } }), disabled: mute },
    { name: 'phone', label: 'Contact', disabled: mute },
    { name: 'status', label: 'Status', mandatory: true, type: FieldType.SELECT_FIELD, options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }], defaultValue: 'active', disabled: mute },
    { name: 'email', label: 'Email', disabled: mute },
  ]
);

const fieldsShow = (attrs) => {
  const {customLabelClass='', customInputClass='', admins, managers, mute=false,renderLocationModal, agencies} = attrs
  return [
    { name: 'id', label: 'Parking Lot ID', mandatory: true, autoFocus: true, disabled: true, customInputClass, customLabelClass,  },

    { name: 'name', label: 'Parking Lot Name', mandatory: true, autoFocus: true, disabled: mute,customInputClass, customLabelClass },
    {
      name: 'location',
      label: 'Location',
      mandatory: true,
      render: renderLocationModal,
      disabled: mute,
      customInputClass, customLabelClass
    },
    { name: 'phone', label: 'Contact Number',type: FieldType.PHONE_NUMBER_FIELD, disabled: mute, customInputClass, customLabelClass },
   
    { name: 'email', label: 'Email', disabled: mute, customInputClass, customLabelClass },
    { name: 'parking_admin_id', label: 'Parking Operator', customInputClass, customLabelClass,type: FieldType.DROPDOWN_SELECT_FIELD, options: admins?.map(admin => { return { value: admin.value, label: admin.label } }), disabled: mute },
    
    { name: 'town_manager_id', label: 'Town Manager', mandatory: true,customInputClass, customLabelClass, type: FieldType.DROPDOWN_SELECT_FIELD, options: managers?.map(manager => { return { value: manager.value, label: manager.label } }), disabled: mute },
    { name: 'status', label: 'Parking Lot Status', mandatory: true,customInputClass, customLabelClass, type: FieldType.DROPDOWN_SELECT_FIELD, options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }], defaultValue: 'active', disabled: mute },
    { name: 'agency_id', label: 'Agency', mandatory: true, type: FieldType.DROPDOWN_SELECT_FIELD, options: agencies, disabled: mute, customInputClass, customLabelClass },
    { name: 'parking_lot_type', label: 'Parking Lot Type', mandatory: true, type: FieldType.DROPDOWN_SELECT_FIELD, options: [{ value: 'gated', label: 'Gated' }, { value: 'non_gated', label: 'Non Gated' }], disabled: mute, customInputClass, customLabelClass },
    {name: 'available_spaces', label:"Available Spaces",disabled:'mute', customInputClass, customLabelClass}
] 
    
};

const fieldsDetailedShow = (attrs) => {
  const {customLabelClass='', customInputClass='', admins, managers, mute=false,renderLocationModal, agencies, rule_id, rule_name} = attrs
  return [
    { name: 'name', label: 'Lot Name', mandatory: true, autoFocus: true, disabled: mute,customInputClass, customLabelClass },
    { name: 'parking_admin_id', label: 'Parking Operator', customInputClass, customLabelClass,type: FieldType.DROPDOWN_SELECT_FIELD, options: admins?.map(admin => { return { value: admin.value, label: admin.label } }), disabled: mute },
    {
      name: 'location',
      label: 'Location',
      mandatory: true,
      render: renderLocationModal,
      disabled: mute,
      customInputClass, customLabelClass
    },
    { name: 'town_manager_id', label: 'Town Manager', mandatory: true,customInputClass, customLabelClass, type: FieldType.DROPDOWN_SELECT_FIELD, options: managers?.map(manager => { return { value: manager.value, label: manager.label } }), disabled: mute },
    { name: 'phone', label: 'Contact Number', disabled: mute, customInputClass, customLabelClass },
    { name: 'status', label: 'Lot Status', mandatory: true,customInputClass, customLabelClass, type: FieldType.DROPDOWN_SELECT_FIELD, options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }], defaultValue: 'active', disabled: mute },
    { name: 'email', label: 'Email Address', disabled: mute, customInputClass, customLabelClass },
    { name: 'agency_id', label: 'Agency', mandatory: true, type: FieldType.DROPDOWN_SELECT_FIELD, options: agencies, disabled: mute, customInputClass, customLabelClass },

    {name: 'available_spaces', label:"Occupied Spaces",disabled:'mute', customInputClass, customLabelClass},
    { 
      name: `parkingRulesFine[parkingRulesFine-${rule_id}][fine]`,
      label: `${rule_name} $`,
      type: FieldType.NUMBER_FIELD,
      min: 0,
      tooltip: rule_name==="Unpaid" ? 'This amount will be added to the parking session charges': '',
      renderValue: value => value,
    },
  ] 
    
};

const fieldsSettings = (attrs) => {
  const {customLabelClass='', customInputClass='', mute=false} = attrs
  return [
    {
      name: 'parking_days',
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: mute,
      list: [
        { label: 'Global', value: 'global' },
        { label: 'Custom', value: 'custom' }
      ],
      
    },
  
] 
    
};
const fieldsParkingHourSettings = (attrs) => {
  const {customLabelClass='', customInputClass='', mute=false} = attrs
  return [
    {
      name: 'parking_hours',
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: mute,
      list: [
        { label: 'Hourly Static', value: 'hourly_static' },
        { label: 'Hourly Dynamic', value: 'hourly_dynamic' },
        { label: 'Time Blocks', value: 'time_blocks' },
        { label: 'Custom', value: 'custom' }
      ],
    },
  
] 
    
};

const lotSettingsFields = (attrs) => {
  const {customLabelClass='', customInputClass='', customToolTipClass} = attrs
  return [
    {
      name: 'vehicle_class',
      label: 'Vehicle Class',
      placeholder:'Choose Vehicle Class',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        {value: "car", label: "Car"}, 
        {value: "motorcycle", label: "Motorcycle"}, 
        {value: "micro_mobility_vehicle", label: "Micro Mobility Vehicle"}, 
        {value: "other", label: "Other"}
      ],
      customInputClass, 
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'Vehicle Class',
      customLabelClass
    },
    {
      name: 'rate',
      label: 'Hourly Rate $',
      type: FieldType.NUMBER_FIELD,
      min: 0,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'Per hour rate of the parking lot',
      renderValue: value => value,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'disabled_parking_rate',
      label: 'Disabled Parking Rate $',
      type: FieldType.NUMBER_FIELD,
      min: 0,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'Disabled per hour rate of the parking lot',
      renderValue: value => value,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'incremental',
      label: 'Incremental rate',
      type: FieldType.INCREASER_FIELD,
      step: 60,
      min: 60,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'Incremental rate of the parking lot',
      renderValue: value => value,
      customInputClass,
      customLabelClass,
    },
   
    { name: 'period',
      label: 'Minimum Chargeable Time in minutes',
      type: FieldType.INCREASER_FIELD,
      step: 1800,
      max: 3600,
      min: 1800,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'This is the minimum time that is multiplied to the hourly rate. Ex. The driver is charged 30 minutes even if he only stayed for 20 minutes.',
      renderValue: value => (value / 60),
      customInputClass,
      customLabelClass,
    },
    { name: 'parked',
      label: 'Grace Period to Park',
      type: FieldType.INCREASER_FIELD,
      step: 120,
      max: 600,
      min: 120,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'Minutes before a car is considered as parked automatically.',
      renderValue: value => (value / 60),
      customInputClass,
      customLabelClass,
    },
    { name: 'overtime',
      label: 'Grace Period to Exit',
      type: FieldType.INCREASER_FIELD,
      step: 120,
      max: 600,
      min: 120,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'This is the time given for the user to vacate the parking space after parking expiry.',
      renderValue: value => (value / 60),
      customInputClass,
      customLabelClass,
    },

    
] 
    
};


const fieldsParkingHourTypeSettingsWeekdays = (attrs) => {
  const {customLabelClass='', customInputClass='', mute=false} = attrs
  return [
    {
      name: 'parking_hours_type',
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: mute,
      list: [
        { label: 'Hourly Static', value: 'hourly_static' },
        { label: 'Hourly Dynamic', value: 'hourly_dynamic' },
        { label: 'Time Blocks', value: 'time_blocks' },
        { label: 'Custom', value: 'custom' }
      ],
    },
  
] 
    
};

const fieldsParkingHoursFormatSettingsWeekdays = (attrs) => {
  const {customLabelClass='', customInputClass='', mute=false} = attrs
  return [
    {
      name: 'parking_hours_format',
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: mute,
      list: [
        { label: '24 Hours', value: '24_hours' },
        { label: 'Custom', value: 'custom' }
      ],
    },
  
] 
    
};

const fieldsParkingHoursFormatDynamicWeekdays = (attrs) => {
  const {customLabelClass='', customInputClass='', name, mute=false} = attrs
  return [
    {
      name: `hourFormat[${name}]`,
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: mute,
      list: [
        { label: '24 Hours', value: '24_hours' },
        { label: 'Custom', value: 'custom' }
      ],
    }
  
] 
    
};


const fieldsHour = (attrs) => {
  const {customLabelClass='', customInputClass='', name} = attrs
  return [
    {
      name: `parkingHours[${name}][from]`,
      label: ``,
      placeholder: 'From',
      customInputClass,
      customLabelClass,
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `parkingHours[${name}][to]`,
      label: ``,
      placeholder: 'To',
      customInputClass,
      customLabelClass,
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    }
]     
};


const fieldsNoChargePeriod = (attrs) => {
  const {customLabelClass='', customInputClass='', name} = attrs
  return [
    {
      name: `noChargePeriod[${name}][from]`,
      label: ``,
      placeholder: 'From',
      customInputClass,
      customLabelClass,
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `noChargePeriod[${name}][to]`,
      label: ``,
      placeholder: 'To',
      customInputClass,
      customLabelClass,
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    }
]     
};

const fieldsNoChargePeriodDynamic = (attrs) => {
  const {customLabelClass='', customInputClass='', name} = attrs
  return [
    {
      name: `noChargePeriodDynamic[${name}][from]`,
      label: ``,
      placeholder: 'From',
      customInputClass,
      customLabelClass,
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `noChargePeriodDynamic[${name}][to]`,
      label: ``,
      placeholder: 'To',
      customInputClass,
      customLabelClass,
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    }
]     
};

const fieldsNoChargeDay = (attrs) => {
  const {customLabelClass='', customInputClass='', name, days} = attrs
  return [
    {
      name: `noChargePeriodDynamic[${name}][day]`,
      label: `No Charge Day`,
      placeholder: 'Choose No Charge Day',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: days.map((d) => {return {value: d, label: capitalize(d)}}),
      customInputClass,
      customLabelClass,
      mandatory: true,
      disabled: false,
    },
]     
};

const fieldsShowMute = (managers = [], admins = [], agencies = [], renderLocationModal, userPermissions = []) => fieldsWithPermission(
  [
    ...fieldsNew(managers, admins, agencies, renderLocationModal, true)
  ],
  userPermissions,
  permissions.READ_PARKINGLOT
);

const liveFootageFilterFields = () => [
  { name: 'name', label: 'Parking Lot Name' },
  { name: 'id', label: 'Parking Lot ID' },
  { name: 'full_address', label: 'Location' },
  { name: 'available_cameras', label: 'Available Cameras' },
]

const filterFields = (attrs) => {
  const {customLabelClass='', customInputClass='', parkingAdmins, townManagers} = attrs
  return [
  { name: 'id', label: 'Lot ID', customInputClass, customLabelClass, placeholder:'Parking Lot ID' },
  { name: 'phone', label: 'Contact Number ',  customInputClass, customLabelClass, placeholder:'Contact Number' },
  {
    name: 'parking_admins',
    label: 'Parking Operator',
    placeholder:'Choose a parking operator',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: parkingAdmins.map(({ value, label }) => {
      return { value, label };
    }),
    customInputClass, 
    customLabelClass
  },
  { name: 'name', label: 'Lot Name',  customInputClass, customLabelClass, placeholder:'Parking Lot Name' },

  { name: 'email', label: 'Email Address',  customInputClass, customLabelClass, placeholder:'Email Address' },
 
  {
    name: 'town_managers',
    label: 'Town Manager',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a town manager',
    options: townManagers.map(({ value, label }) => {
      return { value, label };
    }),
    customInputClass, 
    customLabelClass
  },
  { name: 'full_address', label: 'Location',  customInputClass, customLabelClass, placeholder:'Parking Lot Location' },

  {
    name: 'status',
    label: 'Lot Status',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder:'Choose a status',
    options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }],
    customInputClass, 
    customLabelClass
  }
] 
    
};

const fieldsCustomHour = (attrs) => {
  const {customLabelClass='', customInputClass='', day, id, isHourlyRateDisabled, isDisabledRateDisabled } = attrs
  return [
    {
      name: `customDays[${day}][${day}-${id}][from]`,
      customInputClass,
      customLabelClass,
      placeholder:'From',
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `customDays[${day}][${day}-${id}][to]`,
      customInputClass,
      customLabelClass,
      placeholder:'To',
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `customDays[${day}][${day}-${id}][rate]`,
      customInputClass,
      customLabelClass,
      placeholder: isHourlyRateDisabled ? 'N/A': 'Rate $',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      disabled: isHourlyRateDisabled,
    },
    {
      name: `customDays[${day}][${day}-${id}][disabled]`,
      customInputClass,
      customLabelClass,
      placeholder: isDisabledRateDisabled ? 'N/A': 'Rate $',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      disabled: isDisabledRateDisabled,
    },

  ]    
};



const exampleData = (roles) => process.env.NODE_ENV !== 'production' ? {
  name: 'Parking Lot test',
  phone: '+13583767678',
  email: faker.internet.email(),
  status: 'active'
} : {
    status: 'active'
  } // These are defaults values for each field


  const fieldsCreate = (attrs) => {
    const {customLabelClass='', customInputClass='', admins, managers, mute=false,renderLocationModal, agencies} = attrs
    return [
      { name: 'name', label: 'Parking Lot Name', mandatory: true, autoFocus: true, disabled: mute,customInputClass, customLabelClass },
      {
        name: 'location',
        label: 'Location',
        mandatory: true,
        render: renderLocationModal,
        disabled: mute,
        customInputClass, customLabelClass
      },
      { name: 'phone', label: 'Contact Number',type: FieldType.PHONE_NUMBER_FIELD, disabled: mute, customInputClass, customLabelClass },
     
      { name: 'email', label: 'Email', disabled: mute, customInputClass, customLabelClass },
      { name: 'parking_admin_id', label: 'Parking Operator', customInputClass, customLabelClass,type: FieldType.DROPDOWN_SELECT_FIELD, options: admins?.map(admin => { return { value: admin.value, label: admin.label } }), disabled: mute },
      
      { name: 'town_manager_id', label: 'Town Manager', mandatory: true,customInputClass, customLabelClass, type: FieldType.DROPDOWN_SELECT_FIELD, options: managers?.map(manager => { return { value: manager.value, label: manager.label } }), disabled: mute },
      { name: 'status', label: 'Parking Lot Status', mandatory: true,customInputClass, customLabelClass, type: FieldType.DROPDOWN_SELECT_FIELD, options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }], defaultValue: 'active', disabled: mute },
      { name: 'agency_id', label: 'Agency', mandatory: true, type: FieldType.DROPDOWN_SELECT_FIELD, options: agencies, disabled: mute, customInputClass, customLabelClass },
      { name: 'parking_lot_type', label: 'Parking Lot Type', mandatory: true, type: FieldType.DROPDOWN_SELECT_FIELD, options: [{ value: 'gated', label: 'Gated' }, { value: 'non_gated', label: 'Non Gated' }], disabled: mute, customInputClass, customLabelClass },
      {name: 'available_spaces', label:"Available Spaces",disabled:'mute', customInputClass, customLabelClass}
  ] 
      
  };

  const closeLotFields = (attrs) => {
    const {customLabelClass='', customInputClass=''} = attrs
    return [
      {
        name: 'closed_till_date',
        placeholder: 'Choose a date',
        customInputClass,
        customLabelClass,
        type: FieldType.SINGLE_DATE_FIELD,
        min: new Date(),
      },
      {
        name: `closed_till_time`,
        placeholder: 'Choose a time',
        customInputClass,
        customLabelClass,
        type: FieldType.SINGLE_HOUR_FIELD,
      }
  ]     
  };

export { fieldsNew, fieldsShow,fieldsHour, fieldsShowMute, filterFields, exampleData, liveFootageFilterFields, fieldsSettings, fieldsDetailedShow, fieldsParkingHourSettings, lotSettingsFields, fieldsParkingHourTypeSettingsWeekdays, fieldsParkingHoursFormatSettingsWeekdays, fieldsParkingHoursFormatDynamicWeekdays, fieldsCustomHour, fieldsNoChargePeriod, fieldsNoChargePeriodDynamic, fieldsNoChargeDay, fieldsCreate, closeLotFields };