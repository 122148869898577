import React, { useCallback, useReducer, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { upload, destroy } from 'api/parking_regulations';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Upload from '../upload';
import Delete from '../delete';
import { AlertMessagesContext } from 'components/helpers/alert_messages';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const ParkingRegulationModal = (props) => {
  const { openModal, modalType, list, popListElement } = props

  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'upload':
        return { width: '480px' };
      default:
        return { width };
    }
  },[modalType])
  
  const closeModal = useCallback(() => {
    openModal(); 
  }, [openModal])
        
  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const uploadFile = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await upload(data)
      if(response.status === 200){
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    } finally{
      setState('isSaving', false)
    }
  },[setState])

  const deleteDocument = useCallback(async () => {
    setState('isDeleting', true)
    try {
      const response = await destroy({id: list[0]?.id })
      if (response.status === 200 || response.status === 201){
        popListElement({id: list[0]?.id})
        closeModal()
        addAlertMessages([{
          type: 'danger',
          text: 'Document deleted',
          onlyMessage: true
          }],
          'center'
        )
    }
    } catch (error) {
      console.log(error)
    } finally {
      setState('isDeleting', false)
    }
  },[setState, addAlertMessages, closeModal, popListElement, list])

  return (
    <Modal 
      isOpen={!!modalType} 
      centered
      style={{maxWidth: modalProps().width, width: '100%'}}
      contentClassName={styles.modal}
      backdropClassName={styles.backdropC}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'upload' &&  
          <Upload
            {...props}
            closeModal={closeModal}
            uploadFile={uploadFile}
          /> 
        }
        { modalType === 'delete' &&  
          <Delete
            {...props}
            state={state}
            setState={setState}
            closeModal={closeModal}
            deleteDocument={deleteDocument}
          /> 
        }
      </ModalBody>
    </Modal>
  )
}

export default ParkingRegulationModal
