import { ViolationActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records, filters } = reduceEntity(ViolationActions);

const ViolationReducers = combineReducers({
  index,
  records,
  filters
});

export default ViolationReducers;
