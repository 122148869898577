import React, { useCallback, useRef } from 'react'
/* Helpers */
import { displayDate } from 'components/helpers'
import { filterFields } from 'components/helpers/fields/monitor_vehicles/filterFields'
/* Base */
import { renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button'
/* API */
import { filterFetcher } from "api/monitor_vehicles";
/* Components */
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
/* Assets */
import styles from './index.module.sass'
import { useSelector } from 'react-redux'
import { list as selectList } from 'selectors/list'

const FilterForm = (props) => {
  const { parkingLots, verdictList, setFilters, match, setList, startFetching } = props;

  const filters = useSelector(state => state.monitor_vehicles.filters)

  const attrs = { parkingLots, verdictList, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterMonitorVehicles = useCallback(async (filters={}) => {
    const { range } = filters
    if(range && !range?.to) { delete range.to}
    if(range && !range?.from) { delete range.from}

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      range: {
        from: displayDate(filterValues.range?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.range?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(filters);
    filterMonitorVehicles(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      range: { from: '', to: ''}, 
      verdict: '', 
      parking_lot_ids: '',
      plate_number: ''
    })
    setFilters({})
    filterMonitorVehicles({})
  }

  const resetDates = () => {
    setFilters({...filters, range: { from: '', to: ''}})
    formApiRef.current.setValues({
      ...filters,
      range: { from: '', to: ''},
    })
  }

  // const initialValues = {
  //   ...filters,
  //   range: {
  //     from: filters.range?.from ? new Date(filters.range.from) : '' ,
  //     to: filters.range?.to ? new Date(filters.range.to) : '' ,
  //   }
  // }

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (

      <Form initialValues={'initialValues'} getApi={setFormApi} className={`${styles.form}`} onSubmit={save}>
        {({formState}) => {
          const dateFrom = formState.values.range?.from || ''
          const dateTo = formState.values.range?.to || ''
          
          const fields = getFields(dateFrom, dateTo)
          return <Row className='m-auto'>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(0, 2), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(2, 3), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(3, 4), {lSize: 5, iSize: 7})}
            </Col>
            <Col className={`m-0`} xs={3}>
              <span className={`${styles['fieldset-dateStroke']} position-absolute`}></span>
              {renderFields(fields.slice(4, 6), {lSize: 5, iSize: 7})}
              <span 
                role='button' 
                className={`${styles['fieldset-dateClear']} position-absolute`}
                onClick={resetDates}
              >
                Clear Date
              </span>
            </Col>
            <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
              <Button
                className={`${styles.btn} ${styles['background-primary']} mr-5`}
                type='submit'
              >
                Filter
              </Button>
              <Button
                type='button'
                onClick={resetFilters}
                className={`${styles.btn} ${styles['background-secondary']}`}
              >
                Reset
              </Button>
            </Col>
          </Row>
        }}
      </Form>

  )
}

export default FilterForm;
