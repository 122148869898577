const { FieldType } = require("components/helpers/form_fields");
const { filter } = require("underscore");


const filters = ({officers=[], types=[], statuses=[], customInputClass, customLabelClass, oldStatus, newStatus, dateFrom, dateTo}) => {
  return [
    {
      name: 'type',
      label: 'Change Type',
      type: FieldType.SELECT_FIELD,
      options: types,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'performed_by',
      label: 'Performed By',
      type: FieldType.SELECT_FIELD,
      options: officers,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'old_value',
      label: 'Old Value',
      type: FieldType.SELECT_FIELD,
      options: filter(statuses, status => status !== newStatus),
      placeholder: 'Choose an old value',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'new_value',
      label: 'New Value',
      type: FieldType.SELECT_FIELD,
      options: filter(statuses, status => status !== oldStatus), 
      placeholder: 'Choose a new value',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'range[from]',
      label: 'Date Occurred',
      type: FieldType.SINGLE_DATE_FIELD,
      placeholder: 'Choose a date (from)',
      customInputClass,
      customLabelClass,
      max: dateTo
    },
    {
      name: 'range[to]',
      label: 'Date Occurred',
      type: FieldType.SINGLE_DATE_FIELD,
      placeholder: 'Choose a date (to)',
      customInputClass,
      customLabelClass,
      max: new Date(),
      min: dateFrom,
    },
  ]
} 

export default filters;
