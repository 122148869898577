import Loader from 'components/helpers/loader';
import React, {  useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'informed';
/* Helpers */
import { contactFields } from 'components/helpers/fields/event_parking_applications/contactFields';
/* API */
import { getDetails } from 'api/events';
/* Base */
import { renderFields } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Modules */
import withFetching from 'components/modules/with_fetching';
/* Styles */
import styles from './index.module.sass'
import 'react-phone-input-2/lib/style.css'
import { isEmpty } from 'underscore';

const  Edit = ({event, closeModal, updateDetails, isSaving, t})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [eventDet, setEventDet] = useState({})
  const formApiRef = useRef();
  const [errors, setErrors] = useState({})

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    t: t,
    mute: false,
  }
  
  const doubleFieldProps = {
    lSize: 5,
    iSize: 7,
  }
  

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const renderContactFields = () => {
    const  fields = contactFields(fieldAttrs);
    return (
      <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
        {renderFields(fields, {...doubleFieldProps, errors})}
      </Col>
    )
  }

  const eventData = (data) => {
    const {  contact_person_name, organizer_email, organizer_phone_number  } = data
    return ({
      event_parking_application: { 
        contact_person_name: contact_person_name, 
        organizer_email: organizer_email,
        organizer_phone_number: organizer_phone_number
      },
    })
  }

  const validateInput = (values) => {
    let errors = {}
    if(values){
     const {organizer_email, contact_person_name, organizer_phone_number} = values
     if(!organizer_email){errors['event_parking_application[organizer_email]'] = [t("events.private_events.edit_validations.email")]}
     if(!organizer_phone_number){errors['event_parking_application[organizer_phone_number]'] = [t("events.private_events.edit_validations.phone")]}
     if(!contact_person_name){errors['event_parking_application[contact_person_name]'] = [t("events.private_events.edit_validations.name")]}
    } else {
      errors['event_parking_application[organizer_email]'] = [t("events.private_events.edit_validations.email")]
      errors['event_parking_application[organizer_phone_number]'] = [t("events.private_events.edit_validations.phone")]
      errors['event_parking_application[contact_person_name]'] = [t("events.private_events.edit_validations.name")]
    }
    return errors

  }

  const submitValues = (values) => {
    const inputErrors = validateInput(values?.event_parking_application)
    if(!isEmpty(inputErrors)){
      setErrors(inputErrors)
      return
    }
    const data = { 
      contact_person_name: values?.event_parking_application.contact_person_name,
      organizer_email: values?.event_parking_application?.organizer_email,
      organizer_phone_number: values?.event_parking_application?.organizer_phone_number,
    }
    updateDetails(data)
  }

  
  useEffect(() => {
    const fetchDetails = async () => {
      setIsFetching(true)
      try {
        const response = await getDetails({event_id: event.event_id})
        const newEvent = eventData(response.data);
        setEventDet(newEvent)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(event.event_id) { fetchDetails() }
  },[event.event_id])

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  if(isFetching) { return <Loader /> }

  
  
  return (
    <Container className='p-0'>
      <Form getApi={setFormApi} initialValues={eventDet} className={styles.form} onSubmit={submitValues}>
        <Row className='mx-auto w-100'>
          { renderContactFields() }
        </Row>
        <Col className="d-flex justify-content-center mb-3 mt-4 pr-2" xs={12}>
          <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            {t("buttons.cancel")}
          </Button>
          <Button type='submit' className={styles.button} isLoading={isSaving}>
            {t("buttons.save")}
          </Button>
        </Col>
      </Form>
    </Container>
  )
}

export default withFetching(Edit);