import React, { useCallback } from 'react'
import TableRow from './table_row';
/* Api */
import { filterFetcher } from 'api/city_events';
/* Helpers */
import { filterFields } from 'components/helpers/fields/event_parking_applications/city_events/filterFields';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { list, setRecord, filters, search,  isResourceFetching, openModal, modalType, selectedRecord, activeTab, total } = props
  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        key={record.id} 
        record={record} 
        selectedRecordId={selectedRecord.id} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    });
  },[list, modalType, setRecord, openModal, selectedRecord.id])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      total={search ? list?.length : total}
      columns={
        <React.Fragment>
            <th attr="event_name">Event Name</th>
            <th attr="organizer_email" style={{ width: '45%', minWidth: '210px' }}>Organizer's Email</th>
            <th attr="event_days" style={{ width: '45%', minWidth: '210px' }}> Event Days </th>
            <th attr="total_spaces" style={{ width: '45%', minWidth: '210px' }}> Parking Spaces </th>
            <th attr="attendees_cost" style={{ width: '45%', minWidth: '210px' }}> Cost per Pass </th>
            <th attr="passes_sold" style={{ width: '45%', minWidth: '220px' }}> Number of Passess Sold </th>
            <th className='d-flex justify-content-center' attr="actions" style={{ width: '20%', minWidth: '210px', }} disableSort> Action </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='City Events'
    />
  )
}

export default TableData;