import React from "react";
import { filterFetcher } from "api/violators";
import IndexTable from "components/base/table";
import TableRow from "./tableRow";
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, openModal, modalType, setSelectedRecord, selectedRecord } = props;

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          history={history}
          match={match}
          openModal={openModal}
          modalType={modalType}
          setSelectedRecord={setSelectedRecord}
          selectedRecord={selectedRecord}
        />
      );
    });
  }

  return (
    <IndexTable
      className={styles.table}
      {...props}
      filterFetcher={filterFetcher}
      showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="name" style={{width: '35%', minWidth: 210}}>Violation Type</th>
          <th attr="repetitions_allowed" style={{width: '35%', minWidth: 210}}>Maximum Repetitions</th>
          <th attr="measure_type" style={{width: '45%', minWidth: 210}}>Measure</th>
          <th style={{width: '45%', minWidth: 210}} disableSort>Status</th>
          <th attr="measures.actions" style={{ width: '30%', minWidth: '210px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='violators'
    />
  )
}

export default TableData;
