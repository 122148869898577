import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Col, Container, Label } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/vehicles";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/vehicles';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import Toggle from 'components/base/toggle';
import { Link } from 'react-router-dom';
import { ReactComponent as TrashIcon } from 'assets/trash_icon.svg';
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { displayUnixTimestamp } from 'components/helpers';

const Show = (props) => {
  const {startFetching, closeModal , record, isSaving, updateRecord, openInnerModal, validate, openPreviewModal, image, setImage, errors, setErrors, location} = props
  const [isFetching, setIsFetching] = useState()
  const [vehicle, setVehicle] = useState({})
  const [dropdowns, setDropdowns] = useState()
  const [fileName, setFileName] = useState();
  const fileRef = useRef()
  const [file, setFile] = useState()

  useEffect(() => {
    const fetch = () => {
      Promise.all([
    
        startFetching(
          dropdownsSearch("vehicle_statuses_field")
        ).then((response) => setDropdowns(response.data)),

      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
    statuses: dropdowns
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }

  const doubleFieldProps = {
    iSize: 9,
    lSize: 3
  }
  const applicationData = useCallback((data) => {
    const { id, created_at, updated_at, plate_number, registration_state, manufacturer, model, color, status, category, institution_name, vehicle_type, user, validated } = data
    const images = data.registration_card ? data.registration_card : 'https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-15.png';
    
    return ({  
      id: id,
      registration_date: displayUnixTimestamp(created_at),
      updated_date: displayUnixTimestamp(updated_at),
      plate_number: plate_number,
      registration_state: registration_state,
      manufacturer: manufacturer?.name,
      model: model,
      color: color,
      status: status,
      category: category,
      vehicle_type: vehicle_type,
      institution_name: institution_name,
      validated: validated,
      owner_details: {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email
      },
      image: images
  })
  },[])

  useEffect(() => {
    if(!isEmpty(record?.registration_card)){
      setFile(record.registration_card)
      setFileName('')
      setImage(record.registration_card)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, setFile, setFileName, setImage])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!record?.id && !location?.state?.record?.id)  return;
      try {
        const response = await show({id: record?.id ? record?.id : location?.state?.record?.id})
        if(response.status === 200) {
          const { data } = response;
          setVehicle(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();

  }, [record, applicationData, startFetching, location])

  const validateValues = (formState) => {
    const errors = {}
    const { institution_name, category } = formState;
    if(category === 'government' && !institution_name) {
      errors['institution_name'] = ['Government Institution is required']
    }
    if(category === 'agency' && !institution_name) {
      errors['institution_name'] = ['Agency is required']
    }
    return errors
  }


  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };

    const updatedHash = {
      ...values,
      validated: validate ? 'true' : 'false',
      registration_card: file,
    };
    updateRecord(updatedHash)
  }


  const getBase64= file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        setFile(reader.result);
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    }else {
      setFileName(e.target.files[0]?.name)
      setImage(URL.createObjectURL(e.target.files[0]));
      getBase64(e.target.files[0])
    }
    
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      if (validFileTypes.includes(e.dataTransfer.files[0].type)) {
        setFileName(e.dataTransfer.files[0].name)
        setImage(URL.createObjectURL(e.dataTransfer.files[0]));
        getBase64(e.dataTransfer.files[0])
      }
    }
  };

  const fields = (category) => {
    return showFields({category, ...fieldAttrs})
  }

  const renderParkingLotFields = (fields) => {
    fields[15] = {
      ...fields[15], 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput),
      icon: <Link className={styles.checkDetailsLink}>Check the List</Link>
    }

    return(
      <Col style={{marginLeft: '-4px'}}>
        {renderFields(fields.slice(15,16), {...doubleFieldProps})}
      </Col>
    )
  }

  const renderCitationLogFields = (fields) => {
    fields[16] = {
      ...fields[16], 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput),
      icon: <Link className={styles.checkDetailsLink} to={{ pathname: `/dashboard/vehicles/${record?.id}/citation_tickets`, state: { record } }}>Check the List</Link>,
    }

    return(
      <Col style={{marginLeft: '-4px'}}>
        {renderFields(fields.slice(16,17), {...doubleFieldProps})}
      </Col>
    )
  }

  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Vehicle Details</p>
      <Form initialValues={vehicle} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const {category } = formState.values;
          const newFields = fields(category)
          return (
            <Col>
              <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Vehicle Details</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
              <div style={{ margin: '10px'}}>
                {renderFields(newFields.slice(0, 10), {...singleFieldProps, errors})}
                {category !== 'personal' && renderFields(newFields.slice(10, 11), {...singleFieldProps, errors})}
                {renderFields(newFields.slice(11, 12), {...singleFieldProps, errors})}
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} className={styles.uploadField}>
                  <span className={styles.inputLabel} style={{marginLeft: '-600px'}}>
                    Registration Card
                  </span>
                  <span  style={{display: "flex", marginLeft: '-3.5%', width: '33rem', marginTop: '10px'}} onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                    <input ref={fileRef} name='id_proof' className='d-none' id='id-proof-file-upload' type='file' accept='.jpg, .png, .jpeg' onChange={showFileName}/>
                    {!image && <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '0.5rem', width: '80%'}}>
                      <span className='mb-2'>
                        {image ? <img className={styles.img} alt="" src={image}/>
                          : <CloudDownloadIcon />}
                      </span>
                      <span>
                        <span className={styles.boldText1}>
                          Add Picture
                        </span>
                        <span className='ml-1'>
                          or drag it here
                        </span>
                      </span>
                      <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
                    </Label>
                    }
                    {image && (<div className='d-flex'>
                    <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel1} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '0.5rem'}}>
                      <span className='mb-2 mt-2'>
                        <img className={styles.img} alt="" src={image}/>
                      </span>
                      <span className={styles.uploadText}>
                        <span className={styles.boldText2}>
                        {fileName}
                        </span>
                      </span>
                    </Label>  
                    <div className={styles.deleteField}>
                      <TrashIcon className={styles.icon} onClick={() =>  {setFileName(); setImage(); setFile()}}/>
                      <EyeIcon className= {styles["icon-eye"]} onClick={() => {openPreviewModal()}}/>
                    </div>
                    </div>)
                    }
                  </span>
                </div>
              </div>
              <div className={styles.uploadField}>
                <span className={styles.toggleLable} style={{ marginLeft: '-600px'}}>
                  Vehicle Validation
                  <Toggle
                    className={styles.toggleIcon}
                    onChange={() => { openInnerModal()}}
                    customToggleClass= {styles.toggle}
                    customToggleOnClass= {styles.toggleOn}
                    positiveText=''
                    negativeText=''
                    value={validate}
                    offHighlightClass={styles.toggleHighlight}
                    onHighlightClass={styles.toggleOnHighlight}
                  />
                  {
                    !validate 
                      ? <div className={styles.toggleStatus}style={{color: 'red'}}>* Vehicle is not validated</div>
                      : <div className={styles.toggleStatus} style={{color: 'green'}}>* Vehicle is validated</div>
                  }
                </span>
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Vehicle Owner Details</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
              <div style={{ margin: '10px'}}>
                {renderFields(newFields.slice(12, 15), {...singleFieldProps, errors})}
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Parking History Logs</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
                {renderParkingLotFields(newFields)}
            </Col>
            <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Citation Logs</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
                {renderCitationLogFields(newFields)}
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                onClick={()=>  closeModal()}
                className={`${styles.cancelButton} mb-5`}
              >
                Cancel
              </Button>
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Show);