import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";

const { index } = resourceApi("city_events");
const search = searchApi("city_events");


const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params

  return index({
    page,
    perPage,
    query: {
       ...query,
       ...filters,
    },
  });
};

export { filterFetcher, index, search};