import { generatePath } from "react-router";
import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";
import fetchApi from 'components/modules/fetch_api';

const { index, create, destroy  } = resourceApi("select_lists/residential_types");
const search = searchApi("select_lists/residential_types");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      type_of: filters?.type_of? filters?.type_of : 'residence',
      ...filters,
    },
  });
};

const update = (params = {}) => {
    const { id, data = {} } = params;
    return fetchApi(generatePath(`dashboard/select_lists/residential_types/${id}`), {
        method: 'PUT',
        params: data,
    })
};

export { filterFetcher, index, search, create, destroy, update };