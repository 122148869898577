import React, { useCallback, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Details from '../details/index';
import { createDispute } from 'api/webapp/citations';
/* Assets */
import styles from './index.module.sass'
/* API */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Dispute from '../dispute_modal';
import DisputeStatus from '../dispute_modal/dispute_status_model';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const PermitModal = (props) => {
  const { record, openModal, modalType, secondsToHours } = props
  const [state, dispatch] = useReducer(reducer, initState);
  // eslint-disable-next-line
  const [closeAllModal, setCloseAllModal] = useState()
  const [disputeStatus, setDisputeStatus] = useState();

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    if(state.innerModal === 'set_time' || state.innerModal === 'raise_dispute'){
      setState('innerModal', null)
      if(closeAllModal){openModal();}
    }else {
      openModal();
    }
  }, [openModal, setState , state.innerModal, closeAllModal])

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null); }}
    />
    // eslint-disable-next-line
  },[closeModal, setState]);

  const raiseDispute = useCallback(async (data) => {
    try {
      data.parking_session_id = record.id
      const response = await createDispute(data)
      if (response.status === 201){
        setState('innerModal', 'disputeStatus')
        setDisputeStatus('success')
      }
    } catch(error) {
      setState('errors', error?.response?.data?.errors)
      setState('innerModal', 'disputeStatus')
      setDisputeStatus('failure')
    }
  },[ record, setState])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'details':
        return {title: 'Parking Session Details', maxWidth: '650px'}
      case 'raise_a_dispute':
        return {maxWidth: '550px'}
      default:
        return {maxWidth}
    }
  },[modalType]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
      modalClassName={styles.modal}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle}  mx-0 mb-2 p-0 text-capitalize text-center`}>{modalProps().title}</p>
        { modalType === 'details'
          && <Details
              secondsToHours={secondsToHours}
              record={record}
              closeModal={closeModal}
              openDisputeModal={() => setState('innerModal', 'dispute')}
              />
        }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            style={{maxWidth: 550, width: '98%'}}
            contentClassName={styles.appModal}
            backdropClassName={styles.backdropC}
            modalClassName={styles.modal}

          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody className={`${state.innerModal === 'payment' ? '' : styles.modalBody}`}>
              {state.innerModal === 'dispute' && 
                <Dispute
                  raiseDispute={raiseDispute}
                  closeModals={() => {setState('innerModal', null)}}
                  record={record}
                  isSaving={state.isSaving}
                  errors={state.errors}
                  setState={setState}
                />
              }
              {
                state.innerModal === 'disputeStatus' && 
                <DisputeStatus
                  closeModal={() => {setState('innerModal', null)}}
                  record={record}
                  disputeStatus={disputeStatus}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal;