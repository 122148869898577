import React, { useCallback } from 'react'
import TableRow from './tableRow';
import { filterFetcherUser } from 'api/permit_waiting_lists';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { userList, setUserList, filters, isResourceFetching, openModal, modalType, activeTab, setuserWaitingListRecord, userWaitingListRecord } = props
  if(userList?.list!==undefined){
    setUserList(userList?.list)
  }
  
  const renderRecords = useCallback(() => {
    return userList?.map((record) => {
      return <TableRow 
        {...props}
        key={record.id} 
        record={record}
        openModal={openModal} 
        modalType={modalType} 
        setuserWaitingListRecord={setuserWaitingListRecord}
        userWaitingListRecord={userWaitingListRecord}
      />
    });
  },[userList, modalType, setuserWaitingListRecord, openModal, props, userWaitingListRecord])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcherUser}
      paginationQuery={filters}
      total={userList?.length}
      setList={setUserList}
      columns={
        <React.Fragment>
          <th attr="created_at" style={{ width: '15%', minWidth: '100px', }} >User ID</th>
          <th attr="full_name" style={{ width: '25%', minWidth: '210px', }} >Full Name</th>
          <th attr="permit_types.name" style={{ width: '25%', minWidth: '210px', }} >Waitlist Position</th>
          <th attr="email" style={{ width: '25%', minWidth: '210px', }} >Email</th>
          <th attr="waiting_list_number" style={{ width: '25%', minWidth: '210px', }} >Enrollment Date</th>
          <th className='d-flex justify-content-center' style={{ width: '25%', minWidth: '210px', }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
    />
  )
}

export default TableData;
