import { FieldType } from 'components/helpers/form_fields';
import permissions from 'config/permissions';
import fieldsWithPermission from './fields_with_permission';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const fields = [
  { name: 'email', label: 'Email', disabled: true },
  { name: 'first_name', label: 'First Name', disabled: true },
  { name: 'last_name', label: 'Last Name', disabled: true },
  { name: 'phone', label: 'Phone Number', disabled: true },
  { name: 'confirmed_at', label: 'Confirmed at Date & Time', disabled: true },
  { name: 'created_at', label: 'Created at Date & Time', disabled: true },
  { name: 'updated_at', label: 'Updated at Date & Time', disabled: true }
];

const fieldsShow = (userPermissions) => ([
  ...fields,
  ...fieldsWithPermission(
    [{ name: 'is_dev', label: 'Testing Account', type: FieldType.CHECKBOX_FIELD, className: 'mt-3' }],
    userPermissions,
    permissions.UPDATE_USER
  )
]);

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'users[first_name]',
    label: 'First Name',
    placeholder: 'Enter First Name',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'users[last_name]',
    label: 'Last Name',
    placeholder: 'Enter Last Name',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'users[phone]',
    label: 'Phone Number',
    placeholder: 'Enter Phone Number',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Enter Address',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'permit_number',
    label: 'Permit Number',
    placeholder: 'Enter Permit Number',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'plate_number',
    label: 'License Plate Number',
    placeholder: 'Enter Plate Number',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'status',
    label: 'Status',
    placeholder: 'Choose a status',
    type: FieldType.SELECT_FIELD,
    options: [
      {label: 'Active', value: 'active'},
      {label: 'Deactivated', value: 'deactivated'},
      {label: 'On Hold', value: 'on_hold'}
    ], 
    customInputClass,
    customLabelClass,
  },
  {
    name: 'users[email]',
    label: 'Email Address',
    placeholder: 'Enter Email Address',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
];

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='', customLabelClass1='', account_types=[], countryList=[], stateList=[], customInput1Class='', customInput2Class='' } = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      deactivated: 'red',
      active: 'green',
      on_hold: 'orange'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'id',
      label: 'User ID',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'first_name',
      label: 'First Name',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'confirmed_at',
      label: 'Confirmed at',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'created_at',
      label: 'Created at',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'updated_at',
      label: 'Updated at',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'account_type_id',
      label: 'Account Type',
      customInputClass: customInput1Class,
      customLabelClass,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: account_types
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Choose a status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{label: 'Active', value: 'active'},{label: 'Deactivated', value: 'deactivated'},{label: 'On Hold', value: 'on_hold'}].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(value),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass: customInput2Class,
      customLabelClass,
    },
  
    {
      name: 'home_address_attributes[address1]',
      label: 'Address Line 1',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'home_address_attributes[address2]',
      label: 'Address Line 2',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'home_address_attributes[city]',
      label: 'City',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'home_address_attributes[country]',
      label: 'Country',
      customInputClass: customInput1Class,
      customLabelClass,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: countryList
    },
    {
      name: 'home_address_attributes[state_code]',
      label: 'State',
      customInputClass: customInput1Class,
      customLabelClass: customLabelClass1,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: stateList
    },
    {
      name: 'home_address_attributes[zip_code]',
      label: 'Zip Code',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'permits_issued',
      label: 'Permits Issued',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'vehicles_owned',
      label: 'Owned Vehicles',
      customInputClass,
      customLabelClass,
      disabled: true,
    }
  ]
}

export { fieldsShow, filterFields, showFields };
