/* Api */
import React, { useRef } from "react";
import { Form } from "informed";
import { Col, Container, Row } from "reactstrap";
import { map } from "underscore";
/* Base */
import { renderField } from "components/base/forms/common_form";
import Button from "components/base/button";
/* Helpers */
import { fields } from "components/helpers/fields/disabilities/index";
/* Styles/Assets */
import styles from "./index.module.sass";

const attrs = {
  customLabelClass: styles.formLabel,
  customInputClass: styles.formInput,
};

const Show = (props) => {
  const { closeModal, isSaving, record } =
    props;

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };



  const getFields = () => {
    return fields({ mute: true, ...attrs });
  };

  const singleFieldProps = {
    lSize: 4,
    iSize: 8,
  };

  const initialValues = {
    name: record?.name,
    description: record?.description,
  };

  return (
    <Container className="p-0 pt-2">
      <fieldset disabled={isSaving}>
        <Form
          getApi={setFormApi}
          className={styles.form}
          initialValues={initialValues}
        >
          {({ formState }) => {
            const newFields = getFields();
            return (
              <Row className="mx-auto w-100">
                {map(newFields[0], (field, idx) => {
                  return (
                    <Col
                      key={idx}
                      className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-single"]}`}
                      xs={12}
                    >
                      {renderField(field, { ...singleFieldProps })}
                    </Col>
                  );
                })}
                {map(newFields[1], (field, idx) => {
                  return (
                    <Col
                      key={idx}
                      className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-single"]}`}
                      xs={12}
                    >
                      {renderField(field, { ...singleFieldProps })}
                    </Col>
                  );
                })}
                <Col
                  className="d-flex justify-content-center mb-3 mt-3 pt-2 pl-4"
                  xs={12}
                >
                  <Button
                    onClick={closeModal}
                    type="button"
                    className={`${styles.button} ${styles["button-bg-secondary"]} mr-4`}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            );
          }}
        </Form>
      </fieldset>
    </Container>
  );
};

export default Show;