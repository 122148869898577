
const fieldsName = [
  'zip',
  'street',
  'state',
  'country',
  'building',
  'city',
  'ltd',
  'lng'
];

const fields = [
  { name: 'location.country', label: 'Country', mandatory: true, disabled: false },
  { name: 'location.state', label: 'State', mandatory: true, disabled: false },
  { name: 'location.city', label: 'City', mandatory: true, disabled: false },
  { name: 'location.zip', label: 'Zip', mandatory: true, disabled: false },
  { name: 'location.street', label: 'Street', mandatory: true, disabled: false },
  { name: 'location.ltd', label: 'Latitude', mandatory: true},
  { name: 'location.lng', label: 'Longitude', mandatory: true}
];

const exampleData = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? {
  country: "USA",
  city: "Easton",
  state: "MD",
  zip:  21601,
  street: "E Earle Ave",
  ltd: '38.768148',
  lng: '-76.075782'
} : {};

export { fields, exampleData, fieldsName };
