import React, { useCallback, useState, useEffect} from 'react'
import { Col, Row } from 'reactstrap';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import Button from 'components/base/button';
import styles from './index.module.sass';
import { search as dropdownsSearch } from "api/dropdowns";
import FilterForm from './filterForm';
import { ReactComponent as VehiclesIcon } from 'assets/vehicle-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';

const CollapseContent = ({ startFetching,filters, setList, setFilters, match, resource}) => {
  const [dropdowns, setDropdowns] = useState({
    citationStatuses: [],
    violationType: [],
    disputeTypes: [],
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("citation_ticket_statuses_field")).then((response) =>
          setDropdowns(state => ({...state, citationStatuses: response.data }))),
        startFetching(
          dropdownsSearch("tickets_types_field")
        ).then((response) => setDropdowns(state => ({...state, violationType: response.data }))),
        startFetching(dropdownsSearch("dispute_types_field")).then((response) =>
          setDropdowns(state => ({...state, disputeTypes: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} xs={12}>
      <FilterForm
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        filters={filters}
      />
    </Row>
  )
}

const ToolBar = (props) => {
  const {openModal, total, activeTab, setActiveTab, isFetching, location} = props

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      backTab='/dashboard/vehicles'
      className='d-flex justify-content-space-between'
    >
       <Col className='d-flex justify-content-start align-items-center'>
        <span className={styles.title}>{`Parking / Citation Tickets (${total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={activeTab === 'filters' ? styles.activeIcon : ''}/>}
          size="md"
        />
        <SlideButton
          isActive={true}
          className={styles.btnCar}
          icon={<VehiclesIcon className={styles.activeCarIcon}/>}
          onClick={() => {openModal('show', {record: location?.state?.record})}}
        >
          <span className={`mr-3 d-inline-block ${styles.idButton}`} >ID {location?.state?.record?.id}</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;