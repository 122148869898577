import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'informed';
import { InputGroup } from 'reactstrap';
/* Styles */
import styles from './password.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

const Password = ({ field, customAttr = {}, defaultIcon, revealIcon }) => {
  const [type, setType] = useState('password');
  // const [touched, setTouched] = useState(false);

  const handleClick = () => setType(val => val === 'text' ? 'password' : 'text')

  const renderIcon = () => {
    if(defaultIcon && type === 'password') return defaultIcon
    if(revealIcon && type === 'text') return revealIcon
    if(!revealIcon && type === 'password') return <FontAwesomeIcon icon={faEyeSlash}/>
    if(!defaultIcon && type === 'text') return <FontAwesomeIcon icon={faEye}/> 
  }

  return (
    <InputGroup >
      <Text disabled={field.disabled} className={`form-control position-relative ${field?.customInputClass}`}  {...customAttr}  field={field.name} type={type}/>
      <span className={`position-absolute ${styles.eyePassword}`} onClick={handleClick} >
        {renderIcon()}
      </span>
    </InputGroup>
  )
}

Password.propTypes = {
  field:  PropTypes.shape({
    name: PropTypes.string.isRequired,
    filled: PropTypes.bool.isRequired
  })
}

export default Password