import fetchApi from 'components/modules/fetch_api';

const  index = (filters) => {
  return fetchApi(`v1/payments?${filters}`, { method: 'GET'})
};

const show = (params) => {
  const { id } = params
  return fetchApi(`v1/payments/${id}`, { method: 'GET' })
};

export { index, show }