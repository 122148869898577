import { CouponsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(CouponsActions);

const CouponsReducers = combineReducers({
  index,
  records,
  filters
});

export default CouponsReducers;
