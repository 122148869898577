import React from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/parking/citation_tickets';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {
  const { isResourceFetching, setRecord, list, openModal, modalType, activeTab } = props

  const renderRecords = () => {
    return list.map((record) => {
      return ( <TableRow 
        key={record.id} 
        record={record} 
        setRecord={setRecord} 
        openModal={openModal} 
        modalType={modalType} 
      />);
    });
  }
  
  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      isFetching={() => isResourceFetching }
      filterFetcher={filterFetcher}
      columns={
        <>
          <th attr="id" style={{ width: '15%', minWidth: '150px' }} >ID</th>
          <th attr="violation_type" style={{ width: '20%', minWidth: '210px' }} >Violation</th>
          <th attr="status" style={{width: '15%', minWidth: '150px' }} >Status</th>
          <th attr="created_at" style={{ width: '25%', minWidth: '210px' }} >Created At</th>
          <th attr="updated_at" style={{ width: '25%', minWidth: '210px' }} >Updated At</th>
          <th attr="dispute_type" style={{ width: '20%', minWidth: '210px' }} >Dispute Type</th>
          <th className='d-flex justify-content-center' attr="actions" style={{ width: '20%', minWidth: '210px', }} disableSort> Action </th>
        </>
      }
      renderRecords={renderRecords}
      entityName='Parking / Citation Tickets'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData
