import React, { useCallback, useReducer, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { isEmpty } from 'underscore';
import TicketDetails from './ticketDetails';
/* Styles */
import styles from './index.module.sass';
import { Form } from 'informed';
/* API */
import { getDetails } from 'api/app/citation_details';
/* Base */
import { renderField } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Helpers */
import { FieldType } from 'components/helpers/form_fields';
import ThemeProvider from 'subscriberThemeProvider';
import WebAppHeader from 'components/base/webapp_header';
import { useTranslation } from 'react-i18next';

const initState = {
  errors: {},
  fetching: false,
  show_details: false,
  citation: {}
}

const reducer = (state, action) => {
  const { citation } = state
  switch (action.type) {
    case 'errors':
      return {...state, errors: action.payload}
    case 'citation':
      return {...state, citation: action.payload}
    case 'fetching':
      return {...state, fetching: action.payload, citation: action.payload ? {} : citation}
    case 'show_details':
      return {...state, show_details: action.payload}
    default:
      return { ...state };
  }
}

const CitationTicket = () => {
  const [state, dispatch] = useReducer(reducer, initState)
  const [citationTicketStatus, setCitationTicketStatus] = useState()
  const [paymentStatus, setPaymentStatus] = useState()
  const { t } = useTranslation()
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])

  const field = { 
    name: 'ticket_id', 
    placeholder: t("citation_management.placeholder"),
    customInputClass: styles.formEntryInput,
    type: FieldType.NUMBER_FIELD,
    min: 1
  }

  const fetchCitationDetails = useCallback(async (params) => {
    setState('fetching', true)
    try {
      const response = await getDetails(params)
      setState('citation', response.data)
      setCitationTicketStatus(response?.data?.citation_details?.status)
    } catch (error) {
      console.log(error)
    } finally {
      setState('fetching', false)
    }
  },[setState, setCitationTicketStatus])

  const searchTicket = useCallback((values) => {
    setState('errors', {})
    if(isEmpty(values) || !values?.ticket_id) {
      setState('errors', { ticket_id: [`${t("citation_management.blank_validation")}`]})
      return
    }
    if(!state.show_details) setState('show_details', true)
    fetchCitationDetails(values)
  },[setState, fetchCitationDetails, state.show_details, t])

  return (
    <ThemeProvider>
      <Container className={`${styles.default} w-100 mw-100 p-0 m-0`}>
        <WebAppHeader/>
        <p className={`${styles.title} mx-0 mb-3 p-0`}>{t("citation_management.title")}</p>
        <Container className='pt-4 mw-100 px-4'>
          <Row className='mx-auto flex-wrap '>
            <section className={styles.section}>
              <p className={`${styles['heading-2']} my-4`}>{t("citation_management.please_enter_number")}</p>
              <Form className={`${styles.form} d-flex flex-column m-auto`} onSubmit={searchTicket}>
                { renderField(field, {errors: state.errors}) }
                <Button
                  className={`${styles.btn} ${styles['btn-process']} my-2`}
                  type='submit'
                >
                  {t("citation_management.process_btn")}
                </Button>
              </Form>
            </section>
            {state.show_details && <TicketDetails t={t} fetching={state.fetching} citation={state.citation} citationTicketStatus={citationTicketStatus} setCitationTicketStatus={setCitationTicketStatus} setState={setState} setPaymentStatus={setPaymentStatus} paymentStatus={paymentStatus}/>}
          </Row>
        </Container>  
      </Container>
    </ThemeProvider>
  )
}

export default CitationTicket;