import React, { useEffect, useState, useMemo } from 'react'
/* Styles/Assets */
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ReactComponent as ResumeIcon } from 'assets/polygon-icon.svg';
import { ReactComponent as PauseIcon } from 'assets/union-icon.svg';

import styles from './index.module.sass';
/* Base */
import Toggle from 'components/base/toggle';
import { map } from 'underscore';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Action from './action'

const TableRow = (props) => {
  const { record, openModal, setRecord, modalType, match } = props
  const selectedRecordId = useSelector(state => state.permit_type.records.record?.id)
  const history = useHistory();

  const [highlightEnabled, setHighlightEnabled] = useState(false)

  useEffect(() => {
    setHighlightEnabled(modalType ==='')
  },[modalType])

  const actions = useMemo(() => {
    return [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick:() => history.push(`${match.path}/${record.id}`, {rewardsRecord: record}),
      text: 'View',
      isActive: modalType === 'show' && selectedRecordId === record.id,
      disableHighlight: record.imported_permit
    },
    {
      icon: <TrashIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      onClick: () => {openModal('delete')},
      text: 'Delete',
      isActive: modalType === 'delete' && selectedRecordId === record.id
    }
  ]
  },[openModal, modalType, record, history, match, selectedRecordId])

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{ record.name }</td>
       <td>{ record.total_points_earned }</td>
       <td>{ record.total_points_redeemed } </td>
       <td>{ record.users_enrolled }</td>
       <td>{ record.created_at_parsed }</td>
       <td>
         <Toggle
            onClick={() => {
              setHighlightEnabled(true);
              openModal("activate_deactivate");
            }}            
            customToggleClass= {styles.toggle}
            customToggleOnClass= {styles.toggleOn}
            positiveText=''
            negativeText={record.status === 'active' ? <PauseIcon className={styles.barIcon}/> : <ResumeIcon className={styles.polygonIcon}/>}
            value={record.status === 'active' ? true : false}
            offHighlightClass={styles.toggleHighlight}
            onHighlightClass={styles.toggleOnHighlight}
            highlightEnabled={highlightEnabled}
          />
       </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow
