import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { Link } from 'react-router-dom';
import ParkingLots from './parkingLots';
import Permits from './permits/permits';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
/* Base */
import NavigationToolbar from 'components/base/navigationToolbar';
import Tabs from 'components/base/tabs';
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as DoubleSquareIcon } from 'assets/doubble-square-icon.svg'
import { ReactComponent as StarIcon } from 'assets/star-icon.svg'
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg'

const tabs = [
  {label: 'Issued Special Permits', value: 'permits'},
  {label: 'Reserved Parking Spaces', value: 'parkingLots'}
]

const ToolBar = ({showFilters, toggleShowFilters, setListType}) => {
  
  return (
    <NavigationToolbar
      backTab='permits'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start'>
        <Tabs list={tabs} defaultTab='permits' onClick={setListType} />
      </Col>
      <Col className='d-flex justify-content-end px-0'>
        <Link to='special_permit_types' className={`${styles.navButton} btn position-relative mr-3`}>
          <DoubleSquareIcon />
          <StarIcon  style={{ position: 'absolute', top: '41%', left: 18, width: 10, height: 10 }}/>
          <span className="ml-2 text-white">Special Permit Types</span>
        </Link>
        <Button
          className={`${styles.btnFilter} ${showFilters ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${showFilters ? styles.activeIcon : ''}`}/>}
          onClick={toggleShowFilters}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}


const Index = (props) => {
  const { currentUser } = props;
  const [listType, setListType] = useState('permits');
  const [showFilters, setShowFilters] = useState(false)

  const toggleShowFilters = useCallback(() => {
    setShowFilters(val => !val)
  },[setShowFilters])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <ToolBar 
          listType={listType} 
          setListType={setListType} 
          showFilters={showFilters}
          toggleShowFilters={toggleShowFilters}
        />
        <Row className='m-auto p-1'>
          { listType === 'parkingLots' && <ParkingLots {...props} /> }
          { listType === 'permits' && <Permits {...props}/> }
        </Row>
      </Container>
    </AccessControl>
  )
}

export default withCurrentUser(Index)
