import React, { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { adminFields } from 'components/helpers/fields/parking_rules';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";
import { Link } from 'react-router-dom';
import { update, filterFetcher } from 'api/parking_rules';
import { AlertMessagesContext } from 'components/helpers/alert_messages';

const UpdateAdmin = ({ startFetching, selectedRecordId, updateAdmin, openInnerModal, setUpdateData, errors, setErrors, selectedParkingLot}) => {
  const [isFetching, setIsFetching] = useState()
  const [parkingRules, setParkingRules] = useState()

  const [dropdowns, setDropdowns] = useState({
    agencies: [],
    selectedAgency: [],
    officers: []
  })

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const initialValues = {
    admin_id: selectedParkingLot?.agency_id
  }


  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(
          dropdownsSearch('agency_list')
        ).then((response) => setDropdowns(state => ({...state, agencies: response.data })))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { addAlertMessages } = useContext(AlertMessagesContext)

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
    agencies: dropdowns.agencies
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => {
        updateAdminRecord()
      }
    }
  }

  const applicationData = useCallback((data) => {
    const { id, name, username, phone, email, password, role={}, status,  } = data
    
    return ({  
      id: id,
      name: name,
      username: username,
      phone: phone,
      email: email,
      password: password,
      role_id: role?.id,
      status: status
    })
  },[])

  const updateAdminRecord = useCallback(async () => {
    try {
      const agencyId = formApiRef?.current?.getValue('admin_id')
      await update({parkingRules: parkingRules, agencyId: agencyId, parking_lot_id: selectedParkingLot?.id, })
      addAlertMessages([{
        type: 'primary',
        text: 'Parking Rules were succesfully edited',
        onlyMessage: true
      }],
      'center'
    )
    } catch (error) {
      console.log(error)
    }
  },[addAlertMessages, selectedParkingLot, parkingRules])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!selectedParkingLot)  return;
      try {
        const response = await filterFetcher({id: selectedParkingLot?.id})
        if(response.status === 200) {
          const { data } = response;
          setParkingRules(data);
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();
  }, [selectedRecordId, applicationData, startFetching,selectedParkingLot])

  const validateValues = (formState) => {
    const errors = {}
    const { name, username, email, role_id, status } = formState;
    if(!name) { errors['name'] = ['Name is required']}
    if(!username) { errors['username'] = ['User Name is required']}
    if(!email) { errors['email'] = ['Email is required']}
    if(!role_id) { errors['role_id'] = ['Role is required']}
    if(!status) { errors['status'] = ['Status is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = {
      ...values,
    };
    setUpdateData(updatedHash)
    if(values?.password !== undefined){
      openInnerModal()
    }
    else{
      updateAdmin(updatedHash)
    }
  }


  const fields = () => {
    return adminFields({...fieldAttrs})
  }

  if(isFetching) return null

  return (
    <Container className='p-0'>
      <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
              <div >
                {renderFields(newFields.slice(0, 8), {...singleFieldProps, errors})}
              </div>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (UpdateAdmin);