import React from 'react'
/* Styles/Assets */
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import styles from './index.module.sass';
/* Base */
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { Col } from 'reactstrap';

const ToolBar = (props) => {
  const {  openModal, activeTab } = props
  return (
    <NavigationToolbar>
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={styles.title}>{`Parking Reward Programs (${props.total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
      <SlideButton
          isActive={true}
          className={`${styles.button} ${activeTab === 'create' ? styles.activeTab : '' }`}
          icon={<PenToSquareSvg />}
          onClick={() => {
            openModal('create')
          }}
        >
          <span className='mr-3 d-inline-block' style={{width: '160px'}}>Create New Program</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;