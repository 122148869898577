import React from 'react';
import { asField } from 'informed';
import CustomSearchDropdown from 'components/base/dropdown_search';

const DropdownSelect = asField(
  ({ fieldApi, fieldState, options, disabled, events = {}, placeholder, customInputClass, mode }) => {
    const { value } = fieldState;
    const { setValue } = fieldApi;

    return (
      <CustomSearchDropdown
        value={value}
        mode={mode}
        onChange={(selectedValue) => {
          setValue(selectedValue);
          events.onChange && events.onChange(selectedValue);
        }}
        options={options}
        disabled={disabled}
        customInputClass={customInputClass}
        placeholder={placeholder}
      />
    );
  }
);

export default DropdownSelect;