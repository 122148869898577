import React, { useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
/* Actions */
import { SET_RECORD } from 'actions/parking_lots'
/* API */
import { show } from 'api/parking_lots';
import { search as dropdownsSearch } from 'api/dropdowns';
/* Helpers */
import Loader from 'components/helpers/loader';
/* Module */
import connectRecord from 'components/modules/connect_record';
import resourceFetcher from 'components/modules/resource_fetcher';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
/* Components */
import ToolBar from './toolbar';
import { Container, Col } from 'reactstrap';
import styles from './index.module.sass'
import Button from 'components/base/button';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';

const initState = {
  isDropdownFetching: false,
  isSaving: false,
  inputChanged: false,
  dropdowns: {
    statuses: [],
  },
  errors: {},
  activeTab: '',
}

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state
  switch (type) {
    case 'isDropdownFetching':
      return { ...state, isDropdownFetching: payload }
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'inputChanged':
      return { ...state, inputChanged: payload }
    case 'dropdowns':
      return { ...state, dropdowns: {...state.dropdowns, ...payload} }
    case 'errors':
      return { ...state, errors: payload }
    case 'activeTab':
      return { ...state, activeTab: payload }
    case 'filters':
      return { ...state, filters: payload }
    default:
      return { ...state };
  }
};

const Show = (props) => {
  const { isResourceFetching, startFetching } = props
  const [state, dispatch] = useReducer(reducer, initState)

  const [selectedTab, setSelectedTab] = useState('ev')

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])
  
  const setState = useCallback((type, payload) => {
    dispatch({ type, payload })
  },[dispatch])

  const setDropdowns = useCallback((key, data) => {
    setState('dropdowns', { [key]: data } )
  },[setState])

  useEffect(() => {
    Promise.all([
      startFetching(dropdownsSearch('dispute_statuses_field'))
        .then(response => setDropdowns('statuses', response.data)),
    ])
      .finally(() => setState({ isDropdownFetching: false }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (isFetching()) {
    return <Loader />;
  }

  return (
    <Container className='px-0 pb-4 mw-100 '>
      <ToolBar
        state={state}
        setState={setState}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        {...props}
      />
      <section className={`${styles.subSection} mt-3`} style={{ minHeight: 140 }}>
        <p className={styles.cStationHeader}>No Charging Session Configured. Click the button below to begin</p>
        <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
          <Button
            isActive={true}
            icon={<PlusIcon className='svg-white' />}
            className={styles.btn}
          >
            <span className='d-inline-block mr-2' style={{width: '190px'}}>Add a Charging Station</span>
          </Button>
        </Col>
      </section>
    </Container>
  );
}


Show.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object
};

export default connectRecord(
  'parking_lot',
  SET_RECORD,
  resourceFetcher(show),
  withFetching(withCurrentUser(Show))
);
