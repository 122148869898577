import React, { useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { Input } from 'reactstrap';
import SearchBox from 'components/modules/google_maps/search_box'
import extractGeocodeInfo from 'components/modules/google_maps/extract_geocode_info'
import env from '.env';
import styles from './google_maps.module.sass'
import { isEmpty } from 'underscore';
import markerIcon from 'assets/marker.svg';
import markerIcon2 from 'assets/marker2.svg'


var marker = null

const createMarker = (google, map, position, markerName) => {
  deleteMarker()
  marker = new google.maps.Marker({ position, map, title: markerName, icon:{
    url: markerIcon,
    anchor: new google.maps.Point(32, 64),
    scaledSize: new google.maps.Size(64, 64)
  }});
}

const deleteMarker = () => {
  if(marker) {
    marker.setMap(null)
  }
}

const GoogleMapsContainer = (props) => {
  const { lat, lng, markerName, google, setGoogle, search, selectedLocation, parkingLots } = props
  const googleLocation =  { lat, lng }
  const geocoder = new google.maps.Geocoder();
  let inputRef = useRef(null)
  var points = []
  
  if(!isEmpty(parkingLots)){
    // eslint-disable-next-line array-callback-return
    parkingLots.map((lot) => {
     points.push({lat: lot.location.ltd, lng: lot.location.lng})
    })
  }

  
  var bounds = new google.maps.LatLngBounds();
  if(!isEmpty(points)){for (var i = 0; i < points?.length; i++) {
    bounds.extend(points[i]);
  }}

  setGoogle(google)
  const onReady = (map) => {
    if (map || search) {
      createMarker(google, map, googleLocation, markerName)
    }
  }

  const mapClicked = (map, clickEvent) => {
    const { lat, lng } = clickEvent.latLng
    createMarker(google, map, {lat: lat(), lng: lng()}, markerName)
    geocoder.geocode({
      'latLng': clickEvent.latLng
    },(results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        props.events.onMapClick(Object.assign({}, extractGeocodeInfo(results), {
          ltd: lat(),
          lng: lng()
        }), results[0].formatted_address)
      }
    });
  }

const [{ currentPlace, currentMarker }, setState] = useState({
  currentPlace: selectedLocation,
  currentMarker: null
});

const onMarkerClick = (place) => (props, marker) => {
  setState(prevState => ({
    ...prevState,
    currentPlace: place,
    currentMarker: marker
  }));
};

const searchReff = {current: {value: search} } 

  return (
    <div className={styles.google_maps_container} >
      <Input innerRef={inputRef} className="form-control" id={styles.searchBox} type="text" placeholder="Search location" onChange={onReady || mapClicked}/>
      <Map
          google={google}
          initialCenter={googleLocation}
          onClick={mapClicked}
          center={selectedLocation || { lat: 37.7749, lng: -122.4194 }} // Center on selectedLocation if available
          onReady={onReady}
          bounds={bounds}
        >
          {selectedLocation && parkingLots && 
           parkingLots?.map((lot) => {
            return <Marker title={lot?.name}
              name={lot?.name} icon={{
                url: markerIcon2,
                anchor: new google.maps.Point(32, 64),
                scaledSize: new google.maps.Size(64, 64)
              }}  position={{lat: lot?.location?.ltd, lng: lot?.location?.lng}} onClick={onMarkerClick({lat: lot?.location?.ltd, lng: lot?.location?.lng, name: lot?.name})}
              >
              </Marker>
           })
          
          }
          <Marker title={"Current Location"}
              name={'Current Location'} icon={{
                url: markerIcon,
                anchor: new google.maps.Point(32, 64),
                scaledSize: new google.maps.Size(64, 64)
              }}  position={{lat: selectedLocation?.lat, lng: selectedLocation?.lng}}>
          </Marker>
          <InfoWindow
            marker={currentMarker}
            visible={!!currentMarker}
          >
            <div>
              <h6>{currentPlace ? currentPlace.name : 'Current Location'}</h6>
            </div>
          </InfoWindow>
          <SearchBox  searchInputRef={inputRef? inputRef : searchReff} />
      </Map>

    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: env.google_cloud_api_key
})(GoogleMapsContainer)
