import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';

const { index, show, update, destroy, create } = resourceApi('admins');

const search = (query) => {
  return fetchApi(`dashboard/admins/search`, { method: 'GET', params: { ...query, status: 'active' } });
};

const checkAdminPassword = (password) => {
  return fetchApi(`dashboard/admins/check_password`, { method: 'POST', data: { password } });
};

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, query, filters = {} } = params;
  return (
    index({
      page,
      perPage,
      query: {
        ...query,
        ...filters,
      },
    })
  );
};

const importAdmin = (data) => {
  return fetchApi(`dashboard/admins/import`, { method: 'PUT', data })  
}

export { filterFetcher, show, update, destroy, create, search, checkAdminPassword, importAdmin };
