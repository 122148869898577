import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Col, Container } from 'reactstrap';
import { each, isArray, isEmpty, map, reject, values } from 'underscore';
import { Form } from 'informed';
import ParkingLots from './parkingLots';
/* Module */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
/* API */
import { show } from 'api/permit_applications';
/* Helpers */
import Loader from 'components/helpers/loader';
import { capitalize, dateDiffInDays, displayUnixTimestamp } from 'components/helpers';
import { approveFields } from 'components/helpers/fields/permit_applications';
import { FieldType } from 'components/helpers/form_fields';
/* Base */
import { renderFields, renderFieldsWithGrid, renderInput } from 'components/base/forms/common_form';
import Button from 'components/base/button';
import Tabs from 'components/base/tabs';
/* Assets/Styles */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as StarIcon } from 'assets/star-icon.svg';
import { ReactComponent as ShareIcon } from 'assets/share.svg';


const ApplicationBadge = ({permitType, shared}) => {
  return (
    <>
      { permitType === 'special' && (
        <Badge className={`${styles.badge} ${styles['badge-primary-background']} px-2 py-1 mr-3 `}>
          <StarIcon className='mr-1'/>
          <span>Special</span>
        </Badge>
      )}
      { shared && (
        <Badge className={`${styles.badge} ${styles['badge-orange-background']} px-2 py-1 `}>
          <ShareIcon className='mr-1'/>
          <span>Shared</span>
        </Badge>
      )}
    </>
  )
}

const fieldAttrs = {
  customInputClass: styles.input,
  customLabelClass: styles.inputLabel,
  customInput1Class: styles.input1,
  customInput2Class: styles.input2,
  mute: true,
}

const validDaysData = (days) => {
  const data = {}
  each(days, (day, idx) => {
    data[`day-${idx + 1}`] = day
  })
  return data
};

const doubleFieldProps = {
  iSize: 7,
  lSize: 5
}

const singleFieldProps = {
  iSize: 9,
  lSize: 3
}
const fields = approveFields(fieldAttrs)

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const dotIcon = (color) => <FontAwesomeIcon icon={faCircle} className={`${styles.dotIcon}`} color={color}/>

const list = (userCount) => ([
  { label: <span style={{opacity: '0.65'}}>Primary Permit Holder</span>,  value: 'primary'},
  { label: <React.Fragment><span className='mr-2' style={{opacity: '0.65'}}>Secondary Permit Holders</span><span className={styles.tabBadge}>{userCount}</span></React.Fragment>,  value: 'secondary'},
])

const listCommuter = (userCount) => ([
  { label: <span style={{opacity: '0.65'}}>Applicant Details</span>,  value: 'primary'},
  { label: <React.Fragment><span className='mr-2' style={{opacity: '0.65'}}>Commuter Details</span><span className={styles.tabBadge}>{userCount}</span></React.Fragment>,  value: 'secondary'},
])

const permitUserField = (users) => {
  return {
    name: 'permit_user',
    type: FieldType.SELECT_FIELD,
    customInputClass: `${styles.input} mt-4 mb-2`,
    options: map(users.filter((u) => u.id !== 1), (user, idx) => ({label: user.full_name, value: `applicant-${idx + 2}`}))
  }
}

const formatUserAndVehicle = (vehicle, id) => {
  return {
      id: id,
      full_name: `${vehicle.user?.first_name} ${vehicle.user?.last_name}`, 
      home_address: vehicle.user.home_address?.address1,
      home_phone: vehicle.user.home_address?.phone,
      work_address: vehicle.user.work_address?.address1,
      work_phone: vehicle.user.work_address?.phone,
      vehicle: {
        plate_number: vehicle.plate_number?.toUpperCase(),
        make: capitalize(vehicle.manufacturer?.name),
        color: capitalize(vehicle.color),
        model: capitalize(vehicle.model),
        registration_state: capitalize(vehicle.registration_state),
        year: vehicle.manufacture_year
      },
  }
}

const formatUserAndVehicleOfCommuter = (data, id) => {
  return {
      id: id,
      full_name: data?.full_name, 
      home_address: data?.home_address,
      home_phone: data?.home_phone,
      work_address: data?.work_address,
      work_phone: data?.work_phone,
      vehicle: {
        plate_number: data?.vehicle?.[0]?.plate_number?.toUpperCase(),
        make: capitalize(data?.vehicle?.[0]?.manufacturer),
        color: capitalize(data?.vehicle?.[0].color),
        model: capitalize(data?.vehicle?.[0]?.model),
        registration_state: capitalize(data?.vehicle?.[0]?.registration_state),
        year: data?.vehicle?.[0]?.manufacture_year
      },
  }
}

const ApproveApplication = (props) => {
  const { approvePermitApplication, startFetching, record, closeModal, state, setState} = props;
  const [isFetching, setIsFetching] = useState(true)
  const [selectedTab, setSelectedTab] = useState('primary')
  const [applicant, setApplicant] = useState(1)
  const [permitApplication, setPermitApplication] = useState({});

  const { errors, isSaving } = state
  const isSavingRef = useRef();
  const formApiRef = useRef();
  const selectedLotIdRef = useRef()
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  // Reset parking lot selected slot except for the current selected lot
  const resetSelectedSlot = useCallback((lotId) => {
    selectedLotIdRef.current = lotId
    each(permitApplication.parkingLots, lot => {
      if(lotId === lot.id || isEmpty(lot.slots)) return
      formApiRef.current.resetField(`lot-${lot.id}`)
    })
  },[permitApplication])

  const applicationData = useCallback((data) => {
    const { vehicle={},
            permit_type={},
            permit_number,
            total_cost,
            day_pass_dates,
            current_permit_type,
            permit_type_applying_for,
            validity_period,
            no_of_invited_users,
            secondary_vehicles,
            shared,
            cost_per_user,
            commuters,
            commuter_details,
            purpose_of_visit,
            visitor_address } = data

    const applicants = {
      'applicant-1': formatUserAndVehicle(vehicle, 1)
    }
    each(secondary_vehicles, (vehicle, idx) => {
      applicants[`applicant-${idx+2}`] = formatUserAndVehicle(vehicle, idx+2)
    })
    each(commuter_details, (data, idx) => {
      applicants[`applicant-${idx+2}`] = formatUserAndVehicleOfCommuter(data, idx+2)
    })

    return ({
      shared,
      reserved: permit_type?.reserved,
      no_of_invited_users: no_of_invited_users,
      commuters: commuters,
      applicants,
      purpose_of_visit: purpose_of_visit,
      parkingLots: map(permit_type?.permit_parking_lots, (ppl) => ({
        id: ppl.parking_lot?.id,
        name: ppl.parking_lot?.name,
        slots: ppl.available_parking_slots
      })),
      application: {
        permit_number,
        issue_on: displayUnixTimestamp(new Date(record.submitted_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
        type_applying_for: permit_type_applying_for,
        type_currently_held: current_permit_type,
        cost: total_cost,
        period: isArray(validity_period) ? validity_period?.join(', ') : validity_period,
        day_pass_dates: map(day_pass_dates, date => displayUnixTimestamp(new Date(date).getTime()/ 1000, 'dddd, MMM Do, YYYY')),
        cost_per_user: cost_per_user
      },
      visitor_address
    })
  }, [record]) 

  const save = useCallback((values) => {
    const data = { id: record.id, note: values?.note }
    const lotId = selectedLotIdRef.current
    if(!lotId &&  permitApplication.reserved) { 
      setState('errors', {parkingSlot: ['Please assign a parrking slot']})
      return
    }

    data.parking_slot_id =  values[`lot-${lotId}`]
    approvePermitApplication(data)
  },[permitApplication, approvePermitApplication, setState, record.id])

  const hasPastDate = useMemo(() => {
    const days = permitApplication.application?.day_pass_dates
    if(isEmpty(days)) return

    const dayDiffs = days.map(day => {
      return dateDiffInDays(new Date() , new Date(day))
    })

    return !isEmpty(dayDiffs.filter(diff => diff < 0))
  },[permitApplication])

  const renderApplicationDetails = useCallback((fields) => {
    if(!isEmpty(permitApplication.application?.day_pass_dates)) {
      fields = reject(fields, field => (field.name === 'application[period]'))}
    fields[3] = Object.assign(
      fields[3], 
      { 
        icon: dollarIcon(), 
        customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
      }
    )
    if(!isEmpty(permitApplication.application?.day_pass_dates)){
      fields[4] = Object.assign(
        fields[4], 
        { 
          icon: dollarIcon(), 
          customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
        }
      )
    }
    
    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Permit Application Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 1, 12, { ...singleFieldProps })}
        </div>
      </Col>
    )
  },[permitApplication])

  const renderVisitorApplicationDetails = useCallback((fields) => {
    
    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Residential Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 2, 6, { ...doubleFieldProps })}
        </div>
      </Col>
    )
  },[])

  const renderVehicleDetails = useCallback((fields) => {

    fields[2] = Object.assign(
      fields[2], 
      { 
        icon: dotIcon(permitApplication.applicants[`applicant-${applicant}`].vehicle.color), 
        customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
      }
    )
    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Vehicle Information</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 2, 6, { ...doubleFieldProps })}
        </div>
      </Col>
    )
  },[permitApplication, applicant])

  const permitType = record.special ? 'special' : ''

  const validDateFields = useMemo(() => (
    map(permitApplication.application?.day_pass_dates, (_date, idx) => (
      {
        name: `day-${idx + 1}`, 
        label: `Applied day ${idx + 1}`,
        disabled: true,
        ...fieldAttrs
      }
    ))
  ), [permitApplication])

  const updateApplicantData = useCallback((e) => {
    const applicantIdx = e.target.value?.split('-')[1]
    const applicantValues = permitApplication.applicants[`applicant-${applicantIdx}`]
    const values = formApiRef.current.getValues()
    updateApplicationData(values, applicantValues )

    setApplicant(applicantIdx)
  },[setApplicant, permitApplication])

  const updateApplicationData =(values, applicantValues ) =>{
    formApiRef.current.setValues({...values, applicant: {
      ...applicantValues,
      home_address: applicantValues.home_address || '',
      home_phone:  applicantValues.home_phone || '',
      work_address:  applicantValues.work_address || '',
      work_phone:  applicantValues.work_phone || '',

    }, vehicle: applicantValues.vehicle,})
  }

  useEffect(() => {
    if(isSavingRef.current === true && isSaving === false ){
      if(isEmpty(errors)) { 
        closeModal()
      }
    }
    isSavingRef.current = isSaving
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isSaving])

  useEffect(() => {
    setState('errors', {});
    const fetchPermitApplication = async () => {
      setIsFetching(true)
      try {
        const response = await startFetching(show({id: record.id}))
        if(response.status === 200) {
          setPermitApplication(applicationData(response.data))
        }
      }
      catch(error) {
        console.log(error)
      }
      finally {
        setIsFetching(false)
      }
    };
    fetchPermitApplication()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(isFetching) return <Loader />

  const updateTab = (e) => {
    setSelectedTab(e)
    if (e === 'primary') {
      setApplicant(1)
      const applicantValues = permitApplication.applicants[`applicant-1`]
      const values = formApiRef.current.getValues()
      updateApplicationData(values, applicantValues )
    } else {
      setApplicant(2)
      const applicantValues = permitApplication.applicants[`applicant-2`]
      const values = formApiRef.current.getValues()
      updateApplicationData(values, applicantValues )
    }
  }

  const initialValues = {
    ...permitApplication, 
    permit_user: `applicant-${applicant}`, 
    applicant: permitApplication.applicants[`applicant-${applicant}`],
    vehicle: permitApplication.applicants[`applicant-${applicant}`]?.vehicle,
    ...validDaysData(permitApplication.application?.day_pass_dates)
  } 

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>
        <span className='d-block my-2'>Approve Permit Application</span>
        <ApplicationBadge permitType={permitType} shared={permitApplication.shared} />
      </p>
      <Container className='p-0'>

        <Form 
          initialValues={initialValues} 
          className={styles.form} 
          getApi={setFormApi} 
          onSubmit={save}
        >
          { permitApplication.shared && <Col xs={11} className='m-auto py-3'>
            <Tabs 
              list={list(permitApplication.no_of_invited_users)} 
              defaultTab={selectedTab} className={styles.tabs} 
              onClick={(val) => updateTab(val)} 
            />
            { selectedTab === 'secondary' && renderInput(
                permitUserField(values(permitApplication.applicants)),
                {events: {onChange: updateApplicantData}}
              )
            }
          </Col> }
          { permitApplication.commuters > 0 && <Col xs={11} className='m-auto py-3'>
            <Tabs 
              list={listCommuter(permitApplication.commuters)} 
              defaultTab={selectedTab} className={styles.tabs} 
              onClick={(val) => updateTab(val)} 
            />
            { selectedTab === 'secondary' && renderInput(
                permitUserField(values(permitApplication.applicants)),
                {events: {onChange: updateApplicantData}}
              )
            }
          </Col> }
          <Col>
            <div className="d-flex align-items-center my-3">
              <span className={styles.detailsLabel}>Applicant Information</span>
              <span className="border border-2 flex-grow-1 ml-2"></span>
            </div>
            <div>
              {renderFieldsWithGrid(fields[0], 2, 6, { ...doubleFieldProps })}
              {!isEmpty(permitApplication?.purpose_of_visit) && <Col className='px-2' style={{paddingBottom: '1px'}}>
                {renderFields(fields[3][0].slice(0,1), {...singleFieldProps})}
              </Col>}
            </div>
          </Col>
          {renderVehicleDetails(fields[1])}

          {!isEmpty(permitApplication?.visitor_address) && renderVisitorApplicationDetails(fields[3][1]) }
          { renderApplicationDetails(fields[2]) }

          { !isEmpty(permitApplication.application?.day_pass_dates) && (
            <Col className='mb-3'>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Days Applied For</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
              <div>
                {renderFieldsWithGrid(validDateFields, 1, 12, {...singleFieldProps})}
              </div>
            </Col>
          )}

          { permitApplication.reserved && (
            <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Assign Parking Slot/Space</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
              <div>
                <ParkingLots 
                  parkingLots={permitApplication.parkingLots} 
                  resetSelectedSlot={resetSelectedSlot} 
                  errors={errors}
                />
              </div>
            </Col>
          )}
          <Col className="d-flex justify-content-center mb-3 mt-4">
            <Button onClick={closeModal} type='button' className={`${styles.cancelButton} mr-4`}>
              Cancel
            </Button>
            <Button
              type='submit'

              className={`${styles.submitButton} ${hasPastDate && styles['badge-secondary-background']}`} 
              disabled={isSaving || hasPastDate} 
            >
              Approve
            </Button>
          </Col>
        </Form>
      </Container>
    </Container>
  )
};

export default withFetching(withCurrentUser(ApproveApplication));