import React, { useCallback } from 'react'
import { Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/* Component */
import TableRow from './tableRow';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
/* API */
import { carPoolPermitFetcher as filterFetcher } from 'api/permits';
import { invoke } from 'actions';
/* Actions */
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/carpool_pemits';
/* Helpers */
import { filterFields } from 'components/helpers/fields/carpool_permits/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { isResourceFetching, list, setRecord, openModal, modalType } = props

  const renderRecords = useCallback(() => {
    return list.map((record) => 
      <TableRow  
        key={record.id} 
        openModal={openModal} 
        record={record} 
        setRecord={setRecord}
        modalType={modalType}    
      />
    );
  },[list, openModal, setRecord, modalType])

  return (
    <Col xs={12} className='p-1'>
      <IndexTable
        {...props}
        className={`${styles.table}`}
        isFetching={() => isResourceFetching }
        filterFields={filterFields()}
        filterFetcher={filterFetcher}
        resource={resource}
        columns={
          <>
            <th attr="permit_number" style={{width: '15%', minWidth: '150px' }} >Permit Number</th>
            <th attr="commuters" style={{ width: '25%', minWidth: '210px' }} >Commuters</th>
            <th attr="issue_date" style={{ width: '25%', minWidth: '210px' }} >Issue Date</th>
            <th attr="vehicles.plate_number" style={{ width: '25%', minWidth: '210px' }} >Vehicle Plate Number</th>
            <th attr="user_name" style={{ width: '25%', minWidth: '210px' }} >
              Vehicle Owner Name
            </th>
            <th attr="expired_status" style={{ width: '15%', minWidth: '150px' }} >
              Permit Expired
            </th>
            <th attr="expiry_date" style={{ width: '25%', minWidth: '210px' }} >
              Expiry Date
            </th>
            <th attr="permits.actions" style={{ width: '30%', minWidth: '275px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
          </>
        }
        renderRecords={renderRecords}
        entityName="Car Pool Permits"
      >
      </IndexTable>
    </Col>
  )
}

const resource = 'carpool_permit';

const  mapDispatch = (dispatch) => {
  return bindActionCreators({ setListElement: invoke(SET_LIST_ELEMENT), setRecord: invoke(SET_SINGLE_RECORD) }, dispatch);
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(null, mapDispatch)(withFetching(TableData))
);
