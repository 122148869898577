const reportFields = (attrs) => {
  const {customInputBlueClass='', customLabelClass='', icon, iconInputClass=''} = attrs
  return [
    { 
      name: 'ev_parkings_slots', 
      label: 'EV Parking Slots',
      customInputClass: customInputBlueClass,
      customLabelClass,
      disabled: true
    },
    { 
      name: 'ev_chargers', 
      label: 'EV Chargers', 
      icon: icon,
      customInputClass: iconInputClass,
      customLabelClass,
      disabled: true
    },
    { 
      name: 'charging_session_completed', 
      label: 'Charging Sessions Completed', 
      customInputClass: customInputBlueClass,
      customLabelClass,
      disabled: true
    },
    { 
      name: 'total_charging_hours',
      label: 'Total Charging Hours',
      customInputClass: customInputBlueClass,
      customLabelClass,
      disabled: true
    },
    { 
      name: 'total_energy_consumed',
      label: 'Total Energy Consumed',
      customInputClass: customInputBlueClass,
      customLabelClass,
      disabled: true
    },
    { 
      name: 'active_sessions',
      label: 'Active Sessions',
      customInputClass: customInputBlueClass,
      customLabelClass,
      disabled: true
    },
  ];
};

export {reportFields};
