import React, {useEffect} from 'react'
/* Base */
import NavigationToolbar from 'components/base/subscriberNavigationBar';
/* Components */
import { Card, Col, Row, Button } from 'reactstrap';
import styles from './index.module.sass';
import Tabs from 'components/base/tabs';
import { filterFetcher  } from 'api/webapp/vehicles';
import { Input, Space, ConfigProvider } from 'antd';

const ToolBar = (props) => {
  const {setList, openModal, formType, setFormType} = props
  const { Search } = Input;
  const tabs = [
    {label: 'All', value: 'all'},
    {label: 'Active', value: 'active'},
    {label: 'Pending', value: 'pending'}
  ]

  useEffect(() => {
    const fetchData = () => {
      filterFetcher({ status: formType })
        .then(response => {
          if (response.status === 200) {
            setList(response.data);
          }
        })
        .catch(error => console.error(error));
    }
    fetchData();
  }, [formType, setList]);


  const searchVehicle = (value) => {
    filterFetcher({ plate_number: value, status: formType })
      .then(response => {
        if (response.status === 200) {
          setList(response.data);
        }
      })
      .catch(error => console.error(error));
  };

  return (
    <NavigationToolbar
      className='d-flex justify-content-space-between'
    >
      <Card className={styles.cardfilter}>
        <Row>
        <Col style={{width: '70%'}}>
        <Row>
          <Tabs className={styles.tab} list={tabs} defaultTab='all' onClick={setFormType} />
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#3A9CED',
              },
            }}
          >
            <Space direction="vertical" >
              <Search
                placeholder="Enter Plate Number"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={searchVehicle}
              />
            </Space>
          </ConfigProvider>
        </Row>
        </Col>
        <Col style={{width: '30%'}}>
          <Button  className={styles.markButton} onClick={() => {openModal('create');}}>
            Add a new Vehicle
          </Button>
        </Col>
      </Row>
      </Card>
    </NavigationToolbar>
  )
}

export default ToolBar;
