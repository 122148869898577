import React, { useCallback, useReducer } from 'react';
import { Container, Row, Navbar } from 'reactstrap';
import { isEmpty } from 'underscore';
/* Styles */
import styles from './index.module.sass';
import { Form } from 'informed';
/* API */
import { accessEventParking } from 'api/events';
/* Base */
import { renderField } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Helpers */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { notification } from 'antd';



const initState = {
  errors: {},
  fetching: false,
  show_details: false,
  event: {}
}

const reducer = (state, action) => {
  const { event } = state
  switch (action.type) {
    case 'errors':
      return {...state, errors: action.payload}
    case 'event':
      return {...state, event: action.payload}
    case 'fetching':
      return {...state, fetching: action.payload, event: action.payload ? {} : event}
    case 'show_details':
      return {...state, show_details: action.payload}
    default:
      return { ...state };
  }
}

const field = { 
  name: 'event_id', 
  placeholder: 'Event ID',
  customInputClass: styles.formInput,
}

const Event = (props) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const {match, history} = props
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, width) => {
    api[type]({
      message: message,
      style: {
        width: width? width : 400,
      },
    });
  };

  const fetchEventDetails = useCallback(async (params) => {
    setState('fetching', true)
    try {
      const response = await accessEventParking(params)
      setState('event', response.data)
      if (response.data.event_date){
        openNotificationWithIcon('error',response.data?.event_date?.toString())
      } else if (response.data.event) {
        openNotificationWithIcon('error',response.data?.event?.toString(), 200)
      } else if(response.data.event_time){
        openNotificationWithIcon('error',response.data?.event_time?.toString())
      } else {
        history.push({
          pathname: `${match.url}/parkingLotDetails`,
          state: { event: response.data, additionalProp: 'someValue' },
        });
      }
    } catch (_error) {

    } finally {
      setState('fetching', false)
    }
  // eslint-disable-next-line
  },[setState, history, match])
  
  const searchEvent = useCallback((values) => {
    setState('errors', {})
    if(isEmpty(values) || !values?.event_id) {
      setState('errors', { event_id: ["Can't be blank"]})
      return
    }
    if(!state.show_details) setState('show_details', true)
    fetchEventDetails(values)

  },[setState, fetchEventDetails, state.show_details])

  return (
    <Container className={`pt-1 mw-100 px-1 w-100  p-0 m-0 ${styles.default}`}>
      {contextHolder}
      <Navbar sticky="top" color="primary" light expand className="shadow-sm justify-content-center">
        <span className={styles.heading}>
          Access Event Parking
        </span>
      </Navbar>
      <Row className='mx-auto flex-wrap '>
        <section className={styles.section}>
          <p className={`${styles['heading-2']} my-4`}>Welcome. To access a parking lot, please enter the event ID below.</p>
          <Form className={`${styles.form} d-flex flex-column m-auto`} style={{marginRight:"20%"}} onSubmit={searchEvent}>
            { renderField(field, {errors: state.errors}) }
            <Button
              className={`${styles.btn} ${styles['btn-process']} my-2`}
              type='submit'
            >
              Search
            </Button>
          </Form>
        </section>
      </Row>
      <ToastContainer/>
    </Container>  
  )
}

Event.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClickFilter: PropTypes.func,
  label: PropTypes.string,
};

export default Event;