import React, { useCallback } from 'react'
import {  Col, Row } from 'reactstrap';
import FilterForm from './filterForm';
import styles from './index.module.sass';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';

const CollapseContent = ( props ) => {
  const { activeTab, filters, startFetching, setList, setFilters, match, resource, setUserList } = props
  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm 
          {...props}
          startFetching={startFetching} 
          setList={setList} 
          match={match} 
          resource={resource} 
          filters={filters}
          setFilters={setFilters}
          setUserList={setUserList}
        /> }
    </Row>
  )
}

const ToolBar = (props) => {
  const {activeTab, setActiveTab, isResourceFetching, userWaitingListRecord, setuserWaitingListRecord, location} = props;
  const handleToggleCollapse = useCallback((tab) => {
     if(isResourceFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isResourceFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
          userWaitingListRecord={userWaitingListRecord}
          setuserWaitingListRecord={setuserWaitingListRecord}
        />
      }
      backTab='/dashboard/permit_waiting_lists'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        {location?.state?.permit_type_data?.permit_type?.name}
      </Col>
      <Col className='d-flex justify-content-end px-0'>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;