import React, { useCallback, useEffect, useRef, useState, useContext } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import { ROLES } from 'config/permissions'
import { escalationFields } from 'components/helpers/fields/parking_citation_tickets/showFields'
import AccessControl from 'components/helpers/access-control';
import Button from 'components/base/button'
import NavigationToolbar from 'components/base/navigationToolbar'
import withCurrentUser from 'components/modules/with_current_user'
import styles from './index.module.sass'
import { renderFields} from "components/base/forms/common_form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { updateCitationEscalactionSettings, escalationSettings } from 'api/parking/citation_tickets';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import Loader from 'components/helpers/loader';

const CitationEscalations = ({currentUser}) => {
  const [sentNumber, setSentNumber] = useState()
  const [lateNumber, setLateNumber] = useState()
  const [errors, setErrors] = useState({})
  const [setting, setSetting] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [resetValues, setResetValues] = useState(true)
  const formApiRef = useRef(null)
  
  const { addAlertMessages } = useContext(AlertMessagesContext);
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customInputDayClass: styles.formInputDay,
    icon: dollarIcon(),
    iconInputClass: styles.formInputCost.concat(' ', styles.iconInput),
  }

  const singleFieldProps = {
    iSize: 12,
    lSize: 0,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const applicationData = useCallback((data) => {
    const { late_fees, late_fees_period, sent_to_court_period  } = data
    setLateNumber(late_fees_period.split(" ")[0])
    setSentNumber(sent_to_court_period.split(" ")[0])
    return ({  
      late_fees: late_fees,
      sent_period: sent_to_court_period.split(" ")[1],
      late_period: late_fees_period.split(" ")[1],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const fetchCitationTicket = async () => {
      try {
        setIsFetching(true);
        const response = await escalationSettings()
        if(response.status === 200) {
          const { data } = response;
          setSetting(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally{
        setIsFetching(false)
        setResetValues(false)
      }
    };
    if(resetValues) fetchCitationTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  const citationEscalactionSettings = useCallback(async (data) => {
    try {
      const response = await updateCitationEscalactionSettings(data)
      if(response.status === 200 || response.status === 201){
        addAlertMessages([{
          type: 'primary',
          text: 'Citation Escalation settings are successfully saved',
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      addAlertMessages([{
        type: 'danger',
        text: error.response.data.errors.status,
        onlyMessage: true,
      }], 'center')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ addAlertMessages])

  const handleSubmit = useCallback((values) => {
    const updatedHash={
      sent_to_court_period: `${sentNumber} ${values.sent_period}`,
      late_fees: values.late_fees,
      late_fees_period: `${lateNumber} ${values.late_period}`,
    }
    citationEscalactionSettings(updatedHash)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, errors, sentNumber, lateNumber])

  const fields = () => {
    return escalationFields({...fieldAttrs})
  }

  if (isFetching) return <Loader />
 
  return (
    
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 m-0 p-3' style={{minHeight:"600px"}}>
        <NavigationToolbar
          backTab='/dashboard/parking/citation_tickets'
        >
          <Col className='d-flex justify-content-start align-items-center'>
            <span className={styles.title}>Citation Escalations</span>
          </Col>
        </NavigationToolbar>
        
        <Row className={`${styles.main} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
          <Form getApi={setFormApi} initialValues={setting} onSubmit={handleSubmit} className="w-100 m-10">
            { ({formState}) => {
              const newFields = fields()
              return (
                <React.Fragment>
                  <Row>
                    <p className={`${styles.heading} pl-4`}>
                      Sent to court settings
                    </p>
                  </Row>
                  <Row
                    className={styles.fields}
                  >
                    <Col className={styles.fieldset}>
                      <Row className={styles.singleRow}>
                        <label className={styles.formLabel}>Mark a citation ticket as "Sent to court" after </label>
                        <input
                          type="number"
                          id="sentNumber"
                          name="sentNumber"
                          value={sentNumber}
                          onChange={(e) => setSentNumber(e.target.value)}
                          className={styles.inputField}
                        />  
                        <div style={{marginTop: '20px'}}>{renderFields(newFields.slice(0, 1), {...singleFieldProps, errors})}</div>
                        <label className={`${styles.formLabel} ml-3`}>of being Unsettled/Open </label>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <p className={`${styles.heading} pl-4`}>
                      Citation late fees settings
                    </p>
                  </Row>
                  <Row
                    className={styles.fields}
                  >
                    <Col className={styles.fieldset}>
                      <Row className={styles.singleRow}>
                        <label className={`${styles.formLabel} mr-3`}>Add late fees of </label>
                        <div style={{marginTop: '20px'}}>{renderFields(newFields.slice(1, 2), {...singleFieldProps, errors})}</div>
                        <label className={`${styles.formLabel} ml-3`}>after</label>
                        <input
                          type="number"
                          id="lateNumber"
                          name="lateNumber"
                          value={lateNumber}
                          onChange={(e) => setLateNumber(e.target.value)}
                          className={styles.inputField}
                          />  
                        <div style={{marginTop: '20px'}}>{renderFields(newFields.slice(2, 3), {...singleFieldProps, errors})}</div>
                        <label className={`${styles.formLabel} ml-3`}>of the citation being Unsettled/Open </label>
                      </Row>
                    </Col>
                  </Row>

                  <Col className="d-flex justify-content-center my-3" xs={12}>
                    <Button
                      className={`${styles.button} ${styles["button-disabled"]} mr-5`}
                      type="button"
                      onClick={() => setResetValues(true)}
                    >
                      Cancel
                    </Button>
                    <Button className={styles.button} type="submit">
                      Save
                    </Button>
                  </Col>
                </React.Fragment>
              );
            }}
          </Form>
        </Row>
      </Container>
    </AccessControl>
  )
}
export default withCurrentUser(CitationEscalations)