import React from "react";
import { FieldType } from 'components/helpers/form_fields';
import permissions from 'config/permissions';
import fieldsWithPermission from './fields_with_permission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'user_email',
    label: 'Owner Email',
    placeholder: 'Enter Email',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'plate_number',
    label: 'Vehicle Plate Number',
    placeholder: 'Enter Vehicle Plate Number',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'manufacturer_name',
    label: 'Vehicle Manufacturer',
    placeholder: 'Enter Vehicle manufacturer',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.SELECT_FIELD,
    options: [{ value: 'pending', label: 'Pending' }, { value: 'active', label: 'Active' }, { value: 'rejected', label: 'Rejected' }, { value: 'inactive', label: 'Inactive' }],
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'category',
    label: 'Category',
    type: FieldType.SELECT_FIELD,
    options: [{ value: 'personal', label: 'Personal' }, { value: 'government', label: 'Government' }, { value: 'agency', label: 'Agency' }],
    placeholder: 'Select one',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'vehicle_type',
    label: 'Type',
    type: FieldType.SELECT_FIELD,
    options: [{ value: 'gasoline', label: 'Gasoline' }, { value: 'electric', label: 'Electric' }],
    placeholder: 'Select one',
    customInputClass,
    customLabelClass,
  },

];

const notEditableFields = [
  { name: 'created_at', label: 'Date', disabled: true },
  { name: 'plate_number', label: 'Plate Number', disabled: true },
  { name: 'manufacturer', label: 'Manufacturer', disabled: true },
  { name: 'email', label: 'Owner Email', disabled: true },
  { name: 'first_name', label: 'Owner First Name', disabled: true },
  { name: 'last_name', label: 'Owner Last Name', disabled: true },
];

const editableFields = (vehicleStatuses, mute=false) => {
  return ([
    {
      name: 'status',
      label: 'Status',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: vehicleStatuses,
      disabled: mute === true ? true : false,
    }
  ]);
};

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='', statuses=[], defaultStatus, category, icon, customLabelClass1='', } = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      rejected: 'red',
      active: 'green',
      pending: '#fd7e14',
      inactive: '#8b9197',
      deleted: 'red'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'id',
      label: 'Vehicle ID',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'registration_date',
      label: 'Registration Date',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'updated_date',
      label: 'Updated Date',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'plate_number',
      label: 'Plate Number',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'registration_state',
      label: 'Registration State',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'manufacturer',
      label: 'Manufacturer',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'model',
      label: 'Model',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'color',
      label: 'Color',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: statuses.map(({value, label}) => ({
        value,
        label: <>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
           /> 
          {label}
        </>
      })),
      customInputClass,
      customLabelClass,
      disabled:defaultStatus === "rejected" || defaultStatus === "solved",
    },
    {
      name: 'category',
      label: 'Category',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'personal', label: 'Personal Vehicle' }, { value: 'government', label: 'Government Vehicle' }, { value: 'agency', label: 'Agency Vehicle' }],
      customInputClass,
      customLabelClass,
      disabled: false,
    },
    {
      name: 'institution_name',
      label: category === 'government' ? 'Government Institution' : 'Agency' ,
      customInputClass,
      customLabelClass,
      disabled: false,
    },
    {
      name: 'vehicle_type',
      label: 'Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'gasoline', label: 'Gasoline' }, { value: 'electric', label: 'Electric' }],
      customInputClass,
      customLabelClass,
      disabled: false,
    },
    {
      name: 'owner_details[first_name]',
      label: 'First Name',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'owner_details[last_name]',
      label: 'Last Name',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'owner_details[email]',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'logs_number',
      label: 'Logs Number',
      customInputClass,
      customLabelClass1,
      disabled: true,
      icon: icon
    },
    {
      name: 'citation_logs_number',
      label: 'Logs Number',
      customInputClass,
      customLabelClass1,
      disabled: true,
      icon: icon
    },
  ]
}

const fieldsShow = (vehicleStatuses, userPermissions) => [
  ...notEditableFields,
  ...fieldsWithPermission(
    editableFields(vehicleStatuses),
    userPermissions,
    permissions.UPDATE_VEHICLE
  )
];

const fieldsShowMuted = (vehicleStatuses, userPermissions) => [
  ...notEditableFields,
  ...fieldsWithPermission(
    editableFields(vehicleStatuses, true),
    userPermissions,
    permissions.READ_VEHICLE
  )
];

const fieldImages = {
  name: 'images',
  label: '',
  type: FieldType.MULTIPLE_FILE_FIELDS,
  disabled: true
};

const citationFilterFields = ({customInputClass, customLabelClass, disputeTypes, violationType, citationStatuses}) => [
  {
    name: 'created_at',
    label: 'Created at',
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    customInputClass,
    customLabelClass,
  },
  {
    name: 'updated_at',
    label: 'Updated at',
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    customInputClass,
    customLabelClass,
  },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: citationStatuses,
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'violation_type', 
    label: 'Violation Type',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: violationType,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'dispute_type',
    label: 'Dispute Type',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: disputeTypes,
    placeholder: 'Select one',
    customInputClass,
    customLabelClass,
  },
];

export { filterFields, fieldImages, fieldsShow, fieldsShowMuted, showFields, citationFilterFields };
