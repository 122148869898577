import React, { useEffect, useRef, useState } from 'react';
import SettingForm from './settingForm';
/* Api */
import { showSetting, updateSetting } from 'api/permit_applications';
/* Helpers */
import Loader from 'components/helpers/loader';
/* Assets */
import { isEmpty } from 'underscore';

const Index = ({settingRecord, setSettingRecord}) => {
  const [isFetching, setIsFetching] = useState(false)
  const formApiRef = useRef()

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  };

  const save = async () => {
    const { values } = formApiRef.current.getState()
    updateSetting(values)
      .then((res) => {
        if(res.status === 200){
          setSettingRecord(res.data)
        }
      })
  };

  useEffect(() => {
    const fetchSetting = async () => {
      setIsFetching(true)
      try {
        const response = await showSetting();
        
        setSettingRecord(response.data)
      } catch (error) { 
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(isEmpty(settingRecord)) {fetchSetting()};
  })

  return (
    <>
      {
        isFetching 
          ? <Loader />
          : <SettingForm 
              record={settingRecord} 
              save={save} 
              setFormApi={setFormApi}
            />
      }
    </>
  )
}

export default Index
