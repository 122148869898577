import React, { useState, useEffect, useCallback } from "react";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Upload,
  message,
  Modal,
  Table,
  Col,
  Tag,
  DatePicker,
  Divider,
} from "antd";
import { PlusOutlined, CloudUploadOutlined } from "@ant-design/icons";
import styles from "./index.module.sass";
import PhoneInput from "react-phone-input-2";
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import { setSubscriberToken, setCurrentSubData } from "actions/users";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/* Modules */
import { isEmpty } from "underscore";
import { ReactComponent as EditIcon } from "assets/edit_icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withFetching from "components/modules/with_fetching";
/* Modules */
import RedirectIfAuthorized from "components/modules/redirect_if_authorized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { Spinner, Container } from "reactstrap";
import AddVehicle from "components/pages/signup/add_vehicle";
import { debounce } from "lodash";
import WebAppHeader from "components/base/webapp_header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Col as ReactstrapCol, Row as ReactstrapRow } from "reactstrap";
import { handleSocialSignIn } from "api/webapp/subscriber";
import env from ".env";

const { Dragger } = Upload;

const SignUpPage = (props) => {
  const { history, location } = props;
  const { token, provider } = location?.state;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [vehicles, setVehicles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [manufacturers, setManufacturers] = useState();
  const [idProofFileName, setIdProofFileName] = useState("");
  const [base64IdProof, setBase64IdProof] = useState(null);
  // eslint-disable-next-line
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isNewVehicle, setIsNewVehicle] = useState();
  const [dlnExpiryDate, setDlnExpiryDate] = useState("");
  // eslint-disable-next-line
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState();

  const btnSpinner = (props = {}) => {
    return (
      <span>
        <Spinner {...props} size="sm" color="default" />
      </span>
    );
  };
  const submit = async () => {
    try {
      await form.validateFields();
      submitForm(form.getFieldsValue());
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const uploadIDProps = {
    name: "file",
    accept: ".jpg,.jpeg,.png,.pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error(
          "Invalid file format. Please upload JPG, JPEG, PNG, or PDF files only."
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setIdProofFileName(file?.name);
      reader.onload = () => {
        setBase64IdProof(reader.result);
      };
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadProgress(0);
      }
    },
    customRequest: ({ file, onError, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const validateIDProof = async () => {
    if (!idProofFileName) {
      return Promise.reject(t("signup.validations.id_proof"));
    }
  };

  const removeIDProof = () => {
    setBase64IdProof(null);
    setIdProofFileName("");
  };

  const showModal = () => {
    setIsNewVehicle(true);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedVehicle(null);
  };

  const handleModalSave = (values) => {
    const existingVehicle = vehicles.find(
      (vehicle) => vehicle.plateNumber === values.plateNumber
    );
    const vehicleToBeUpdated = existingVehicle
      ? existingVehicle
      : selectedVehicle;
    if (vehicleToBeUpdated) {
      const updatedVehicles = vehicles.map((vehicle) =>
        vehicle === vehicleToBeUpdated ? { ...vehicle, ...values } : vehicle
      );
      setVehicles(updatedVehicles);
    } else {
      setVehicles([...vehicles, values]);
    }
    setModalVisible(false);
    setSelectedVehicle(null);
  };

  const handleRemove = (record) => {
    setVehicles(vehicles.filter((vehicle) => vehicle !== record));
    if (selectedVehicle === record) {
      setSelectedVehicle();
    }
  };

  const signInCallback = (data) => {
    setIsSaving(true);
    if (data.token) {
      axios
        .post(`${env.backend_url}/v1/users/social_sign_up`, data)
        .then((res) => {
          setIsSaving(false);
          if (res.status === 201) {
            handleSocialSignIn(token, provider)
              .then((res) => {
                localStorage.removeItem("TOKEN");
                localStorage.setItem("SUB_TOKEN", res.data.token);
                history.push("/subscriber");
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const submitForm = useCallback(
    (values) => {
      const formattedVehiclesData = vehicles?.map((v, idx) => {
        return {
          plate_number: v?.plateNumber,
          vehicle_type: v?.vehicle_type,
          color: v?.color,
          model: v?.model,
          manufacturer_id: v?.make,
          registration_card: base64IdProof,
          registration_state: v?.state,
          category: v?.category,
          vehicle_class: v?.vehicle_class,
          sub_class: v?.vehicle_sub_class,
        };
      });
      const data = {
        token: token,
        provider: provider,
        phone: values?.phoneNumber,
        id_number: values?.idNumber,
        dln_expiry_date: dlnExpiryDate,
        vehicles: formattedVehiclesData,
      };
      signInCallback(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicles, base64IdProof, dlnExpiryDate]
  );

  const handleEdit = (record) => {
    setIsNewVehicle(false);
    setSelectedVehicle(record);
    setModalVisible(true);
  };

  const columns = [
    {
      title: t("vehicle_attributes.plate_number.label"),
      dataIndex: "plateNumber",
      key: "plateNumber",
    },
    {
      title: t("vehicle_attributes.model.label"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: t("vehicle_attributes.state.label"),
      dataIndex: "state",
      key: "state",
    },
    {
      title: t("vehicle_attributes.color.label"),
      dataIndex: "color",
      key: "color",
    },
    {
      title: t("buttons.actions"),
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="text"
            icon={<EditIcon className="svg-dark position-relative" />}
            onClick={() => handleEdit(record)}
          />
          <Button
            type="text"
            icon={<TrashIcon />}
            onClick={() => handleRemove(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const manufacturerList = await dropdownsSearch("manufacturers_list");
        setManufacturers(manufacturerList.data);
      } catch (_error) {
        console.log(_error);
      }
    };
    if (isEmpty(manufacturers)) fetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledExpiryDates = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  const debouncedHandleFieldChange = debounce((_, allFields) => {
    const updatedErrors = { ...errors };
    allFields.forEach((field) => {
      updatedErrors[field.name[0]] = field.errors.length > 0;
    });
    setErrors(updatedErrors);
  }, 300);

  const handleFieldChange = (_, allFields) => {
    debouncedHandleFieldChange(_, allFields);
  };

  const getLabel = (fieldName, label, required) => {
    const isError = errors?.[fieldName] || false;
    return (
      <Col className={styles.labelCol}>
        <span className={styles.formLabel}>{t(`signup.${label}`)}</span>
        {required && (
          <Tag
            className={styles.requiredMark}
            style={{ color: isError ? "rgba(251, 116, 91, 0.6)" : "#3a9ced" }}
          >
            *
          </Tag>
        )}
      </Col>
    );
  };

  return (
    <Container className="m-0 p-0 mw-100">
      <WebAppHeader />
      <div className={styles.signUpPage}>
        <div className={styles.formContainer}>
          <h2 className={styles.registerText}>Complete Registration</h2>
          <ConfigProvider
            theme={{
              components: {
                Form: {
                  labelColor: "#242E42",
                  labelRequiredMarkColor: "#7ab7f3",
                  labelFontSize: "13px",
                  colorError: "rgba(251, 116, 91, 0.9)",
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "13px",
                },
                Radio: {
                  buttonBg: "#000000",
                },
              },
              token: {
                colorPrimary: "#1890ff",
                fontFamily: "Roboto, sans-serif",
              },
            }}
          >
            <Form
              name="signup"
              form={form}
              layout="vertical"
              colon={false}
              onFieldsChange={handleFieldChange}
              requiredMark={false}
              className={styles.form}
              labelAlign="left"
              onFinish={(values) => submitForm(values)}
            >
              <ReactstrapRow className={styles.formRow}>
                <ReactstrapCol className={styles.phoneCol} xs={4}>
                  <Form.Item
                    name="phoneNumber"
                    label={getLabel("phoneNumber", "Phone Number", true)}
                    rules={[
                      {
                        required: true,
                        message: t("signup.validations.phone_number.required"),
                      },
                      {
                        pattern: /^\d{11 || 12}$/,
                        message: t("signup.validations.phone_number.format"),
                      },
                    ]}
                    className={styles.formItem}
                  >
                    <PhoneInput
                      inputClass={styles.phoneInput}
                      dropdownClass={styles.dropdown}
                      searchClass={styles.search}
                      searchStyle={{
                        fontSize: "13px",
                        height: "30px",
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      disableSearchIcon
                      searchPlaceholder="Search for Countries"
                      searchNotFound="Not Found"
                      country="us"
                      enableSearch={true}
                      autocompleteSearch={true}
                    />
                  </Form.Item>
                </ReactstrapCol>
                <ReactstrapCol className={styles.idCol} xs={4}>
                  <Form.Item
                    name="idNumber"
                    label={getLabel("idNumber", "Driving License Number", true)}
                    rules={[
                      {
                        required: true,
                        message: t("signup.validations.dln"),
                      },
                    ]}
                    className={styles.formItem}
                  >
                    <Input className={styles.formInput} />
                  </Form.Item>
                </ReactstrapCol>

                <ReactstrapCol className={styles.expiryCol} xs={4}>
                  <Form.Item
                    name="expiryDate"
                    label={getLabel("expiryDate", "Expiration Date", true)}
                    rules={[
                      {
                        required: true,
                        message: t("signup.validations.dln_expiry.required"),
                      },
                    ]}
                    className={styles.formItem}
                  >
                    <DatePicker
                      disabledDate={disabledExpiryDates}
                      suffixIcon={
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          color="grey"
                          className={styles.calenderIcon}
                        />
                      }
                      className={styles.calenderInput}
                      format="DD/MM/YYYY"
                      placeholder={t(
                        "signup.validations.dln_expiry.placeholder"
                      )}
                      onChange={(date, dateString) =>
                        setDlnExpiryDate(dateString)
                      }
                    />
                  </Form.Item>
                </ReactstrapCol>
              </ReactstrapRow>

              <Col className="m-0 p-0 justify-content-center">
                <Divider className={styles.titleDivider} orientation="center">
                  Add Vehicle
                </Divider>
                <Col>
                  <div>
                    <Form.Item
                      name="id_proof"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => e && [e.file]}
                      rules={[
                        {
                          validator: validateIDProof,
                        },
                      ]}
                    >
                      <Dragger {...uploadIDProps}>
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined
                            style={{
                              color: idProofFileName ? "#3A9CED" : "grey",
                            }}
                          />
                        </p>
                        <p className="ant-upload-text">
                          {!idProofFileName
                            ? t("signup.uploadText")
                            : idProofFileName}
                        </p>
                        <p className="ant-upload-hint">
                          {t("signup.formatsText")}
                        </p>
                        {idProofFileName && (
                          <Button
                            type="text"
                            className={styles.deleteIdProofButton}
                            onClick={removeIDProof}
                            icon={<TrashIcon className={styles.removeIcon} />}
                          />
                        )}
                      </Dragger>
                    </Form.Item>
                    <p className={styles.regCardLabel}>
                      {"Registration Card Picture (.jpg, .png, .jpeg < 10MB)"}
                    </p>
                  </div>
                </Col>

                {isEmpty(vehicles) && (
                  <p className="text-center">{t("signup.vehiclesText")}</p>
                )}
                {!isEmpty(vehicles) && (
                  <Divider>{t("signup.addedVehicles")}</Divider>
                )}
                {vehicles.length > 0 && (
                  <Table
                    dataSource={vehicles}
                    columns={columns}
                    pagination={false}
                    className={styles.table}
                    sticky={true}
                    scroll={{
                      y: 550,
                    }}
                  />
                )}

                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className={styles.addButton}
                  onClick={showModal}
                >
                  {t("signup.Add Vehicle")}
                </Button>
                <Modal
                  centered
                  title={t("signup.Add Vehicle")}
                  open={modalVisible}
                  onCancel={handleModalCancel}
                  footer={null}
                  classNames={{ header: "text-center" }}
                >
                  <AddVehicle
                    initialValues={selectedVehicle}
                    onSave={handleModalSave}
                    closeModal={handleModalCancel}
                    manufacturers={manufacturers}
                    isNewVehicle={isNewVehicle}
                    t={t}
                  />
                </Modal>
              </Col>

              <Form.Item className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  type="primary"
                  onClick={submit}
                >
                  {isSaving
                    ? btnSpinner({ className: "spinner-border" })
                    : "Proceed"}
                </Button>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>
        <ToastContainer position="top-center" />
      </div>
    </Container>
  );
};

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ setSubscriberToken, setCurrentSubData }, dispatch),
  };
}

export default connect(
  null,
  mapDispatch
)(withFetching(RedirectIfAuthorized(SignUpPage)));
