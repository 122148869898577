import React from "react";
import { useSelector } from "react-redux";
import { filterFetcher } from "api/valet_staff_management";
import IndexTable from "components/base/table";
import TableRow from "./tableRow";
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, selectedRecord, openModal, modalType, setSelectedRecord, activeTab } = props;

  const filters = useSelector(state => state.valet_staff_management.filters)

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          history={history}
          selectedRecord={selectedRecord}
          match={match}
          openModal={openModal}
          modalType={modalType}
          setSelectedRecord={setSelectedRecord}
        />
      );
    });
  }

  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="name" style={{width: '25%', minWidth: 210}} >Full Name</th>
          <th attr="username" style={{width: '25%', minWidth: 210}} >User Name</th>
          <th attr="email" style={{width: '20%', minWidth: 210}} >Email Address</th>
          <th attr="role_id" style={{width: '20%', minWidth: 210}} >Role</th>
          <th attr="status" style={{width: '30%', minWidth: 210}} >Status</th>
          <th 
            attr="actions"
            style={{ width: '20%', minWidth: '210px', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Valet Staff Management'
    />
  )
}

export default TableData;
