import React, { useEffect, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
/* Base */
import Button from 'components/base/button'
/* Assets */
import styles from './index.module.sass'
import { useTranslation } from 'react-i18next'

const CancelApplication = ({cancelApplication, record, closeModal, state}) => {
  const { t } = useTranslation()
  const isSavingRef = useRef();
  useEffect(() => {
    if(isSavingRef.current === true && state.isSaving === false) {
      closeModal();
    }
    isSavingRef.current = state.isSaving
  },[state.isSaving, closeModal])
  return (
    <Container className='p-0 mw-100 m-0'>
      <Row className='flex-wrap w-100 m-0 justify-content-center'>
        <Col xs={10} className={`${styles.content} mt-4`}>
          {t("subscriber.my_permit_applications.cancel_permit_application")}
        </Col>
        <Col className='my-4 d-flex justify-content-center' xs={12}>
          <Button 
            onClick={() => closeModal()}
            className={styles.cancelButton}
          >
            {t("choices.no")}
          </Button>
          <Button
            onClick={() => cancelApplication({id: record.id})}
            className={styles.submitButton}
            disabled={state.isSaving}
          >
            {t("choices.yes")}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default CancelApplication;
