import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import withFetching from 'components/modules/with_fetching';
import  vehicleFields  from 'components/helpers/fields/app/vehicleFields';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import 'react-credit-cards/es/styles-compiled.css';
import { useTranslation } from 'react-i18next';
import { getVehicleDetails } from 'api/app/apply_permit';
import { ReactComponent as FetchIcon } from 'assets/refresh_icon.svg';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {validateStep2} from 'api/app/apply_permit'
import { searchV1 as dropdownsSearch } from 'api/dropdowns';

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};

const AddVehicle = (props) => {
  const  {commuterId, manufacturers ,userType , closeModals, errors, setErrors, isSaving, setCommuterVehicleAttributes} = props;
  // eslint-disable-next-line
  const [vc, setVc] = useState('')
  const { t } = useTranslation()
  const [plateNumber, setPlateNumber] = useState('')
  // eslint-disable-next-line
  const [vehicle, setVehicle] = useState()
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false)
  const [subclasses, setSubClasses] = useState([])
  const [plateNError,setPlateNError] = useState()
  const validateFields = (values) => {
    const {plate_number, manufacturer_id, manufacturer_name,year, model, color, registration_state ,vehicle_category, vehicle_class, vehicle_type, institution_name } = values;
    const errors = {};
    if(!plate_number) errors['plate_number'] = [t("permit_application.apply_for_permit.labels.step2.vehicle_lpn.required")]
    if(plateNError) errors['plate_number'] = [t("permit_application.apply_for_permit.labels.step2.vehicle_lpn.already_present")]
    if(!manufacturer_name && !manufacturer_id) errors['manufacturer_id'] = [t("permit_application.apply_for_permit.labels.step2.manufacturer.required")]
    if(!year) errors.year = [t("permit_application.apply_for_permit.labels.step2.year.required")]
    if(!model) errors.model = [t("permit_application.apply_for_permit.labels.step2.model.required")]
    if(!color) errors.color = [t("permit_application.apply_for_permit.labels.step2.color.required")]
    if(!registration_state) errors.registration_state = [t("permit_application.apply_for_permit.labels.step2.registration_state.required")]
    if(!vehicle_category) errors['vehicle_category'] = [t("permit_application.apply_for_permit.labels.step2.category.required")]
    if(vehicle_category==='government' && !institution_name) errors['institution_name'] = ['Institution Name is required !']
    if(!vehicle_class) errors['vehicle_class'] = [t("permit_application.apply_for_permit.labels.step2.class.required")]
    if(!vehicle_type) errors['vehicle_type'] = [t("permit_application.apply_for_permit.labels.step2.vehicle_type.required")]
    return errors
  }

  const attrs = {
    manufacturers:  manufacturers,
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInputClass: styles.formDropdownInput,
    customExpiryInputClass: styles.cardInput,
    userType,
    t,
    subclasses
  }
  
  
  useEffect(() => {
    const validatePlateAndEmail = async() => {
      try {
        // eslint-disable-next-line
        const response = await validateStep2({
          plate_number: plateNumber
        });
        setPlateNError(false);
        setErrors({})
      } catch (error) {
        if (!isEmpty(error?.response?.data?.errors?.plate_number)) {
          setPlateNError(true);
        } 
      }
    }
    if(plateNumber){
      validatePlateAndEmail()
    }
  }, [plateNumber, setErrors])
  
  const fetchVehicleDetails = useCallback(async() => {
    setIsFetchingVehicle(true)
    try {
      const response =  await getVehicleDetails(plateNumber)
      if(response.data){
        setVehicle(response.data.vehicle_details)
      }
      if(response.data?.vehicle?.toString() === 'Not found'){
        setVehicle([]);
        toast.error("Vehicle Not Found")
      }

    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingVehicle(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[plateNumber])

  const vehicleFieldProps = {
    lSize: 5,
    iSize: 7,
  }

  const vehicleClassProps = {
    lSize: 5,
    iSize: 7,
    events: {
      onChange: (e) => fetchVehicleSubclasses(e)
    }
  }

  const fetchVehicleSubclasses = async (e) => {
    try {
      const vehicleSubclassList = await dropdownsSearch("vehicle_sub_classes_list", {
        vehicle_class: e,
      });
      setSubClasses( vehicleSubclassList?.data)
    } catch (_error) {
      console.log(_error)
    }  
  }

  const storageKey = 'allCommutersData';

  const plateNumberField = useMemo(() => ({
    label: 'Plate Number',
    name: 'plate_number',
    mandatory: true,
    customInputClass: styles.formInputPlate,
    customLabelClass: styles.formLabelPlate,
  }),[])

  const initialFormValues = JSON.parse(localStorage.getItem(storageKey)) || {};
  
  const submitValues = useCallback(
    (values) => {
      setErrors({})
      const validationErrors = validateFields(values)
      if(!isEmpty(validationErrors)){  
        setErrors(validationErrors)
        return
      }
      setCommuterVehicleAttributes(values);
      // Retrieve the existing data from localStorage
      const allCommutersData = JSON.parse(localStorage.getItem(storageKey)) || {};

      // Update the data for the current commuter
      allCommutersData[`commuter-${commuterId}`] = values;

      // Save the updated data back to localStorage
      localStorage.setItem(storageKey, JSON.stringify(allCommutersData));

      closeModals();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModals, commuterId, setCommuterVehicleAttributes, storageKey]
  );

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fields = vehicleFields(attrs, {mute: false, userType: userType, subclasses: subclasses})
  return (
    <Container className='p-0' style={{marginRight: '-3%'}}>
      <p className={styles.commuterTitle}> {`${t("permit_application.apply_for_permit.commuter")} ${commuterId} - ${t("permit_application.apply_for_permit.add_a_vehicle")}`} </p>
      <fieldset disabled={isSaving}>
        <Form initialValues={initialFormValues[`commuter-${commuterId}`] || {}} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const {vehicle_category, plate_number, manufacturer_id} = formState.values
          setPlateNumber(plate_number)
          setVc(vehicle_category)
            return (
              <Row className='mx-auto w-100'>
                <Row className='mx-auto w-100'>
                  <div className="d-flex align-items-center my-2 w-100">
                    <span className={styles.detailsLabel}>{t("permit_application.apply_for_permit.labels.step2.vehicle_details")}</span>
                    <span className="border border-2  ml-2"></span>
                  </div>
                  <Row className='m-0 px-3' xs={12}>
                      <Col className='d-flex flex-wrap px-0' style={{marginLeft: '-6px'}} xs={12}>
                        <div className='p-0 mx-auto col-12 my-0 text-center pr-4 mr-3'>
                          {renderField(plateNumberField, {lSize: 3, iSize: 9, errors})}
                          <Button type='button' className={errors?.plate_number ? styles.okBtnErrorStyle : styles.okBtn}  onClick={fetchVehicleDetails}>{isFetchingVehicle? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}</Button>
                        </div> 
                      </Col>
                      <Col className='d-flex flex-wrap px-0' style={{marginLeft: '-30px'}} xs={12}>
                      { map(fields[1], (field, idx) => {
                        if(manufacturer_id === 0 && idx%2 !== 0) {
                          field = {
                            ...field,
                            customLabelClass: attrs.customLabelClass.concat(' ', styles.customLabelvehicle)
                          }
                        }
                        if(manufacturer_id !== 0 && idx%2 === 0 && idx !== 0) {
                          field = {
                            ...field,
                            customLabelClass: attrs.customLabelClass.concat(' ', styles.customLabelvehicle)
                          }
                        }
                      return (
                        <React.Fragment>
                          {(!manufacturer_id || manufacturer_id !== 0) && idx !== 1 && idx !== 10 &&
                            <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2`} xs={6}>
                              {idx !== 6 && renderField(field, { ...vehicleFieldProps, errors} )}
                              {idx === 6 &&  renderField(field, { ...vehicleClassProps, errors} )}
                            </Col>
                          }
                          {manufacturer_id === 0 && idx === 1 && 
                            <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2`} xs={6}>
                              {renderField(field, { ...vehicleFieldProps, errors} )}
                            </Col>
                          }
                          {vehicle_category === 'government' && idx === 10 && 
                            <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2`} xs={6}>
                              {renderField(field, { ...vehicleFieldProps, errors} )}
                            </Col>
                          }
                        </React.Fragment>
                      )
                    }) }
                    </Col>
                    </Row>
                  </Row>
                  <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
                    <Button onClick={closeModals} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      {t("buttons.cancel")}
                    </Button>
                    <Button  type='submit' className={styles.button}>
                      {isSaving ? btnSpinner({ className: 'spinner-border' }) : t("buttons.add")}
                    </Button>  
                  </Col>
            </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}


export default withFetching(AddVehicle);