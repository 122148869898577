import React, { useState } from 'react';
import { map } from 'underscore';
/* Helpers */
import { displayUnixTimestamp } from 'components/helpers';
import DragScroll from 'components/helpers/dragScroll';
/* Styles/Assets */
import styles from '../index.module.sass';
import { ReactComponent as PlayIcon } from 'assets/play-icon.svg';
import { ReactComponent as ImagePlaceholderIcon } from 'assets/image-placeholder-icon.svg';
import { ReactComponent as ClockIcon } from 'assets/clock-icon.svg';
import ViolationForm from './violationForm';
import VehicleOptions from './vehicleOptions';



const Image = ({ url }) => {
  const [preview, setPreview] = useState(false)
  return <>
    {
      preview 
        ? <img src={url} alt="" className={`${styles.imgThumb} ${styles.image}`} />
        : <div className={`${styles.imgThumb} d-flex justify-content-center align-items-center bg-white`}>
            <ImagePlaceholderIcon className='pointer' onClick={() => setPreview(true)} />
        </div>
    }
  </>
};

const Video = ({ url }) => {
  const [preview, setPreview] = useState(false)
  return (
    <>
      {
        preview 
          ? (
            <video controls  autoPlay className={styles.imgThumb}>
              <source type="video/webm" src={url} />
              <source type="video/mp4" src={url} />
            </video>
          )
          : <div 
              className={`${styles.imgThumb} d-flex justify-content-center align-items-center bg-white `} 
              style={{borderRadius: 8}}

            >
              <div 
                className={`${styles.playWrapper} d-flex justify-content-center align-items-center pointer`}
                onClick={() => setPreview(true)} 
              >
                <PlayIcon />
              </div>
          </div>
      }
    </>
  );
}

const VideoImage = ({values, type}) => {
  return (
    <div className='px-3 w-100'>
      <p className='highlight-text-1'>{`${type} (${values.length})`}</p>
      <DragScroll>
        { map(values, ({url, date}, idx) => (
              <div className='mr-3' key={idx}>
                {type === 'Images' 
                  ? <Image url={url} />
                  : <Video url={url} />
                }
                {
                  date && (
                    <p className='d-flex align-items-center pt-3 m-0' style={{borderRadius: 8}}> 
                      <ClockIcon className='ml-1 mr-2'/>
                      <span className='general-text-1'>{ displayUnixTimestamp(Date.parse(date) / 1000, 'ddd, MMM Do YYYY, h:mm:ss A')}</span>
                    </p>
                  )
                }
              </div>
            ))
          }
      </DragScroll>
    </div>
  )
}

const ViolationDetails = (props) => {
  const { setState, state, record } = props;

  const images = record.violation_photos ? record.violation_photos.map(image => ({ url: image.url })) : [];
  const videos = record.videos ? record.videos.map(video => ({ url: video.url })) : [];

  return (
    <>
      <section className={styles.subSection}>
        <ViolationForm {...props} />
      </section>

      <section className={`${styles.subSection} mt-3`} style={{ minHeight: 140 }}>
        <VehicleOptions state={state} setState={setState} record={record} />
      </section>

      <section className={`${styles.subSection} mt-3 hide-scroll`}>
        <VideoImage values={videos} type='Videos' />
      </section>

      <section className={`${styles.subSection} mt-3 hide-scroll`}>
        <VideoImage values={images} type='Images' />
      </section>
    </>
  )
}

export default ViolationDetails;
