import { AccountTypeActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records } = reduceEntity(AccountTypeActions);

const AccountTypeReducers = combineReducers({
  index,
  records
});

export default AccountTypeReducers;
