import { FieldType } from 'components/helpers/form_fields';
import fieldsDate from './fieldsDate';
import fieldsNew from './fieldsNew';
import fieldsIdentification from './fieldsIdentification';
import fieldsIssue from './fieldsIssue';

const filterFields = () => [
  { name: 'name', label: 'Name' },
  {
    name: 'status',
    label: 'Status',
    type: FieldType.SELECT_FIELD,
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ],
  },
  { name: 'payment_type', label: 'Paid/Free' },
  { name: 'hourly_rate', label: 'Cost Per Hour' },
  { name: 'parking_hour_from', label: 'Time(From - To)' },
];

const statusField = (status, customInputClass) => (
  {
    name: 'status',
    label: null,
    type: FieldType.SELECT_FIELD,
    value: status || 'inactive',
    customInputClass,
    defaultValue: status,
    options: [
      { value: 'active', label: 'Active', customClass: 'text-success' },
      { value: 'inactive', label: 'Inactive', customClass: 'text-danger' },
    ],

    // type: FieldType.SWITCH_FIELD,
    // positiveText: '',
    // negativeText: '',
  }
)

const sortFields = (admins=[], customLabelClass='', customInputClass='') => ([
  {
    name: 'status',
    label: 'Status:',
    placeholder: 'Please select',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ],
    type: FieldType.DROPDOWN_SELECT_FIELD,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'payment_type',
    label: 'Paid/Free:',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: [
      { value: 'free', label: 'Free' },
      { value: 'paid', label: 'Paid' },
    ],
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'category',
    label: 'Category:',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: [
      { value: 'permanent', label: 'Permanent' },
      { value: 'temporary', label: 'Temporary' },
    ],
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'created_by',
    label: 'Created by:',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: admins?.map(admin => ({value: admin?.label, label: admin?.value })),
    placeholder: 'Please select',
    customInputClass,
    customLabelClass,
  },
]);
const exampleData = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? {
  country: "USA",
  city: "Easton",
  state: "MD",
  zip:  21601,
  street: "E Earle Ave",
  ltd: '38.768148',
  lng: '-76.075782'
} : {};
export { fieldsDate, fieldsNew, fieldsIdentification, fieldsIssue, filterFields, sortFields, statusField, exampleData }
