import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { update, create, destroy } from 'api/valet_staff_management';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Edit from '../edit';
import Delete from '../delete';
import Create from '../create';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const ValetModal = (props) => {
  const { openModal, modalType, setActiveTab, selectedRecord, setListElement, addListElement, popListElement } = props
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    setErrors({})
    openModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const editStaff = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({data: {valet_staff: data}, id: selectedRecord.id })
      if(response.status === 200 || response.status === 201 )
      {
        setListElement(response.data)
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Changes saved',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setErrors, addAlertMessages, closeModal, modalType, setListElement])

  const deleteStaff = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await destroy({id: selectedRecord.id })
      if(response.status === 200 || response.status === 201 )
      {
        popListElement({id: selectedRecord.id})
        closeModal()
        addAlertMessages([{
          type: 'danger',
          text: 'Account Deleted',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setErrors, addAlertMessages, closeModal, modalType, setListElement])

  const createStaff = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: {valet_staff: data}})
      if(response.status === 200 || response.status === 201 )
      {
        addListElement({setList: setList, data: response.data})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Valet staff account created',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addListElement, addAlertMessages, closeModal])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'delete_staff':
        return {title: 'Delete Staff Account'}
      case 'edit_staff':
        return {maxWidth: '600px', title: 'Edit Staff Account'}
      case 'create_staff':
        return {maxWidth: '600px', title: 'Create Valet Staff Account'}
      default:
        break;
    }
    return { width }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);
  
  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.vehicleModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'edit_staff' && 
          <Edit
            {...props}
            closeModal={closeModal}
            editStaff={editStaff}
            setErrors={setErrors}
            errors={errors}
            modalType={modalType}
            selectedRecord={selectedRecord}
          />
        }
        {
          modalType === 'delete_staff' && 
          <Delete
            closeModal={closeModal}
            deleteStaff={deleteStaff}
            selectedRecord={selectedRecord}
          />
        }
        {
          modalType === 'create_staff' && 
          <Create
            {...props}
            closeModal={closeModal}
            createStaff={createStaff}
            setErrors={setErrors}
            errors={errors}
            modalType={modalType}
            selectedRecord={selectedRecord}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default ValetModal;
