import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron_up.svg';
import style from './card.module.sass'

const CommutersCard = props => {
  const { className,headerClassName,  header, defaultState = false, onAdd, svgColor='svg-black', index, openCardIndex, setOpenCardIndex,  } = props;
  const [show, toggle] = useState(defaultState);
  const isOpen = openCardIndex === index;
  const onAddClick = (e) => {
    e.stopPropagation();
    if (!show) {
      toggle(true);
    }
    onAdd();
  };


  const onCardClick = () => {
    setOpenCardIndex(isOpen ? null : index);
  };

  return (
    <Card className={`${style.Card}${className ? ` ${className}` : ''}`}>
      <CardHeader className={`${style.CardHeader} ${headerClassName} shadow-sm`} onClick={onCardClick}>
        <span className={style.formGroupLabel}>{header}</span><span className="border border-2 flex-grow-1 ml-1"></span>  
        <div className="d-flex align-items-center">
          {onAdd && (
            <div className={`${style.addButton}`} onClick={onAddClick}>
              <PlusIcon />
            </div>
          )}
          {isOpen
            ? <ChevronUp width="16" height="16" className={svgColor} />
            : <ChevronDown width="16" height="16" className={svgColor} />
          }
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          {props.children}
        </CardBody>
      </Collapse>
    </Card>
  );
};

CommutersCard.propTypes = {
  className: PropTypes.string,
  header: PropTypes.any.isRequired,
  defaultState: PropTypes.bool,
  onAdd: PropTypes.func,
  children: PropTypes.any
};

export default CommutersCard;
