import React, { useState, useEffect } from "react";
import { isEmpty } from "underscore";
import { Row, Col, Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import moment from "moment";
import DetailedReportApi from "api/detailed_reports";
import { ReactComponent as YellowCarIcon } from 'assets/yellow-car-svgrepo-com.svg';
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";
import DateModal from "components/base/date_modal";
import style from "./data_card.module.sass";

const PreviouslyParkedCard = (props) => {
  const { maxDate } = props
  const state = {
    state_data: {},
    from: null,
    to: null,
    datesToFilter: [
      {
        from: moment(),
        to: null,
        label: "Today",
        name: 'today',
        text: `Today (${moment().format("L")})`,
        since: "since yesterday",
      },
      {
        from: moment().startOf("isoWeek"),
        to: moment().endOf("isoWeek"),
        label: "This week",
        name: 'week',
        text: `This week (${moment()
          .startOf("isoWeek")
          .format("MM/DD")}-${moment().endOf("isoWeek").format("MM/DD")})`,
        since: "since last week",
      },
      {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        label: "This month",
        name: 'month',
        text: `This month (${moment().startOf("month").format("MMM")})`,
        since: "since last month",
      },
      {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        label: "This month",
        name: 'all',
        text: 'All time',
        since: "since last month",
      },
    ],
    currentSinceText: "last week",
    tileInfoOpen: false,
  };
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [currentLabel, setCurrentLabel] = useState('Today')
  const [currentComp, setCurrentComp] = useState('yesterday')

  const fetchData = async (from, to, value) => {
    try {
      DetailedReportApi.index({
        configs: {filter: value?.name,pie_chart: { range: { from: from, to: to}}},
        resource: 'vehicles_parked',
      }).then(({ data }) => {
        setData(data)
        setModal(false)
        setCurrentLabel(value === undefined ? 'Custom' : value?.label)
        setCurrentComp(value?.name)
      });
    } catch (error) {
      console.log(error)
    }
  };

  const fetchInitialData = async () => {
    try {
      DetailedReportApi.index({
        resource: 'vehicles_parked'
      }).then(({ data }) => {
        setData(data);
      });
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (isEmpty(data)) {
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return(
    <React.Fragment>
      <Card className={`${style.cardTemplate} h-100`}>
        <Row style={{marginTop: '-11px'}}>
          <Col>
            <YellowCarIcon className={style.icon}/>
          </Col>
          <Col xs="auto" className="d-flex pl-0">
            <span className={style.secondaryText}>
              {currentLabel}
            </span>
            {!data?.disable_date_range && 
              <UncontrolledDropdown>
                <DropdownToggle tag="span" className="pointer">
                  <ChevronDown width="18" height="18" />
                </DropdownToggle>
                <DropdownMenu right className={style.dateDropdown}>
                  {state.datesToFilter &&
                    state.datesToFilter.map((data) => (
                      <DropdownItem
                        className='general-text-1'
                        key={data.from.format("YYYY-M-D")}
                        onClick={() =>
                          fetchData(
                            data.from.format("YYYY-M-D"),
                            data.to ? data.to.format("YYYY-M-D") : null,
                            data
                          )
                        }
                      >
                        {data.text}
                      </DropdownItem>
                    ))}
                  <DropdownItem
                    onClick={() => setModal(true)}
                  >
                    <span className="general-text-1">
                      Select custom...
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            }
          </Col>
        </Row>
        <div className={style.dataContainer}>
          <div className={style.userContainer}>Cars Parked</div>
          <div className={style.numberContainer}>{data?.pie_chart_total?.['Vehicles Previously Parked']}</div>
          <div className={style.activeContainer}>
            <span className="mr-1">Cars parked</span>
            <span className={(currentComp === 'all' || currentComp === undefined) ? 'd-none' : ''}>
              {`${['yesterday', 'today'].includes(currentComp) ? 'today' : `this ${currentComp}`}`}
            </span>
          </div>
          <div className={`${style.data1Container} ${(currentComp === 'all' || currentComp === undefined) ? 'd-none' : ''}` }>
            <span className={style.percentageContainer}>{data?.percentage_from_yesterday}</span>
            <span className={`${style.userContainer1} ml-1`}>
              {`from ${['yesterday', 'today'].includes(currentComp) ? 'yesterday' : `last ${currentComp}`}`}
            </span>
          </div>
        </div>
      </Card>
      <DateModal
        maxDate={maxDate && maxDate.toDate()}
        isOpen={modal}
        apply={fetchData}
        toggleModal={() => setModal(false)}
        title={data?.title}
      />
    </React.Fragment>
  );
}

export default PreviouslyParkedCard;
