import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const SettingConfirmation = (props) => {

  const { updateViolationSetting, closeModal, autoAccept } = props;

  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Violation Auto Accept Confirmation</p>
      <div className={styles.content}>
        <Col className='mt-4'>
          This will 
          <span className={`${styles.boldText} ml-1 mr-1`}>{autoAccept ? 'disable' : 'enable'}</span>
          violation auto-accept. Are you sure?
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={updateViolationSetting} className={autoAccept ? styles.disableButton : styles.enableButton}>
          {autoAccept ? 'Disable' : 'Enable'}
        </Button>
      </Col>
    </Col>
  )
}

export default SettingConfirmation;