import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorBoundary from "components/base/errors/error_boundary";
import styles from "./homepage.module.sass";
import SubscriberContent from "../subscriber_contents";
import SubscriberSideNavigation from './side_navigation'
import ThemeProvider from "subscriberThemeProvider";

const SubscriberLayout = (props) => {
  const { serverError } = props;
  const params = new URLSearchParams(props.location.search || "");

  if (params.get("frame") === "headless") {
    return (
      <ErrorBoundary serverError={serverError}>
        <SubscriberContent />
      </ErrorBoundary>
    );
  }
 
  return (
    <div className="d-flex">
      <ThemeProvider>
        <SubscriberSideNavigation />
        <div className={`${styles.contentContainer} frame-container`}>
          <ErrorBoundary serverError={serverError}>
          <SubscriberContent />
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </div>
  );
};

const mapState = (state) => {
  const { server } = state;
  const { error = {} } = server;
  return { serverError: error.payload };
};

SubscriberLayout.propTypes = {
  serverError: PropTypes.object,
};

export default connect(mapState)(SubscriberLayout);