import React, { useCallback } from 'react'
/* Base */
import NavigationToolbar from 'components/base/navigationToolbar';
import Button from 'components/base/button';
/* Components */
import { Col, Row } from 'reactstrap';
import DisputeDetails from './disputeDetails';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as DisputesIcon } from 'assets/menu_icons/disputes_icon.svg';

const CollapseContent = (props) => {
  const { state } = props

  return (
    <Row className='m-auto bg-grey-light' style={{minHeight: 180}}>
      { state.activeTab === 'dispute' && <DisputeDetails {...props} /> }
    </Row>
  )
}

const ToolBar = (props) => {
  const {state, setState, record } = props
  const { activeTab } = state;
  
  const handleToggleCollapse = useCallback((tab) => {
    setState('activeTab', activeTab === tab ? null : tab)
  },[activeTab, setState])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent {...props} />
      }
      backTab='/dashboard/disputes'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center ml-2'>
        <span>{`Dispute Details`}</span>
      </Col>
      <Col className='d-flex justify-content-end px-0'>
        <Button
          onClick={() => handleToggleCollapse('dispute')}
          className={`${styles.disputeButton} ${activeTab === 'dispute' ? styles.activeTab : '' }`}
          size="md"
          icon={<DisputesIcon className={`${activeTab === 'dispute' ? '' : styles.icon} ml-n4 ${styles['icon-fade']}`} />}
        >
          {`ID ${record.id}`}
        </Button>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
