import resourceApi from "components/modules/resource_api";
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const { index, create } = resourceApi("booting_and_towings");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const fetch_configuration = (params = {}) => {
  const { parking_lot_id } = params;
  return fetchApi(generatePath(`dashboard/booting_and_towings/fetch_configuration?parking_lot_id=${parking_lot_id}`), {method: 'GET'})
};

const pdfPreview = (data) => {
  const { vehicle_id } = data;
  return fetchApi(generatePath(`dashboard/booting_and_towings/boot_violation_pdf?preview=true&vehicle_id=${vehicle_id}`), {method: 'GET'})
}

const statistics = (data) => {
  const { parking_lot_id } = data;
  return fetchApi(generatePath(`dashboard/booting_and_towings/statistics?parking_lot_id=${parking_lot_id}`), {method: 'PUT'})
}

const boot = (params = {}) => {
  const { data, nestedParams = {} } = params;
  return fetchApi(generatePath(`dashboard/booting_and_towings/boot`, nestedParams), {
    method: 'PUT',
    data
  })
};

const remove_boot = (data) => {
  const { vehicle_id } = data;
  return fetchApi(generatePath(`dashboard/booting_and_towings/remove_boot?vehicle_id=${vehicle_id}`), {method: 'PUT'})
}

export { filterFetcher, index, create, fetch_configuration, pdfPreview, statistics, boot, remove_boot};