import React, { useEffect, useState } from 'react';
import { nearestParkingLot } from 'api/events';
import { Container, Navbar } from 'reactstrap';
import styles from './index.module.sass'
import { capitalize } from 'components/helpers';
import { renderField } from 'components/base/forms/common_form';
import { Form } from 'informed';
import Button from 'components/base/button';

const ParkingLotDetails = (props) => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [nearestParkingLots, setNearestParkingLots] = useState(null)

  const { event } = props.location.state

  useEffect(() => {
    // Checking for Geolocation support
    if ('geolocation' in navigator) {
      // Requesting location permission
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          setError(`${error.message}`);
        }
      );
    } else {
      setError('Geolocation is not supported by your browser.');
    }
  }, []);
 
  useEffect(() => {
    const fetchNearestParkingLot = async () => {
      const latitude = location?.latitude
      const longitude = location?.longitude
      try{
        const response = await nearestParkingLot({event_id:event.event_id,location:{ltd: latitude ,lng: longitude}})
        setNearestParkingLots(response.data);
      } catch (error) {
        console.log("Error")
      }
    };
    if (location)  fetchNearestParkingLot();
  },[event.event_id ,location])
   
  const field = { 
    name: 'plate_number', 
    customInputClass: styles.formInput,
  }

  return (
    <Container className={`pt-1 mw-100 px-1 w-100  p-0 m-0 ${styles.default}`}>
      <Navbar sticky="top" color="primary" light expand className="shadow-sm justify-content-center">
        <span className={styles.heading}>
          Access Event Parking
        </span>
      </Navbar>
      <p className={`${styles.title}  p-0 pt-3`}>Event - {capitalize(event.event_name)}</p>
      <p className={`${styles['heading-2']} my-2 pt-3`}>Please Allow Location Access to View the Closest Parking Lot</p>
      <p className={`${styles.title}  p-0 pt-3`}  style={{marginTop:"-1x"}}>Closest Parking Lot</p>
      <section className={`${styles.section} pt-4`}>
        <p className={`${styles.content}  my-2`}>{location && nearestParkingLots ? 
        (
        <div>
          <p className='text-left' style={{marginLeft: "15px"}}>{nearestParkingLots?.name}</p>
          <p className='text-left' style={{marginLeft: "15px"}}>{nearestParkingLots?.location.full_address}</p>
        </div>
      ) 
      : 
      (
        <p>{error || 'Fetching current location...'}</p>
      )}</p>
      </section>
      <section className='pt-3'>
      {location && nearestParkingLots && nearestParkingLots?.available_spaces_for_event > 0 ? (
        <section className='d-flex flex-column  m-auto justify-content-center align-items-center'>
            <p className={`${styles['heading-2']} my-4`} style={{marginTop:"20px"}}>Drive to the parking lot entrance and then enter your vehicle's plate number below</p>
            <p className={`${styles.title}  p-0`}>Enter Plate Number</p>
              <Form className={`${styles.form} d-flex flex-column  m-auto justify-content-center align-items-center`}>
                { renderField(field)}
                <Button
                  className={`${styles.btn} ${styles['btn-process']}`}
                  type='submit'
                >
                  Open Gate
                </Button>
              </Form>
            </section>
      ) : (
        ""
      )}
      </section>
      {nearestParkingLots?.available_spaces_for_event === 0 &&
        <section >
          <p className={`${styles['heading-2']} my-4`} style={{marginTop:"20px"}}>No parking space found.</p>
        </section>
      }      
    </Container>  
  );
};

export default ParkingLotDetails;