import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Delete = (props) => {

  const { deleteFromWaitlist, closeModal, userWaitingListRecord } = props;

  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='my-3'>
          This will remove
          <span className={`${styles.boldText} ml-1 mr-1`}>{userWaitingListRecord?.full_name}</span>
          from the waitlist. The user will not be able to enroll again.
        </Col>
        <Col className='mb-2'>
          Are you sure?
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={deleteFromWaitlist} className={styles.submitButton}>
          Delete
        </Button>
      </Col>
    </Col>
  )
}

export default Delete;
