import React, { useEffect, useState } from 'react';
import { withFormApi } from 'informed';
import { Row, Col } from 'reactstrap';
import styles from './index.module.sass'
import { Form } from 'informed';
/* Base */
import { renderFieldsWithGrid, renderFields } from 'components/base/forms/common_form';
/* Helpers */
import { fields } from 'components/helpers/fields/app/city_events/details';
/* Modules */
import PropTypes from 'prop-types';
import {Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import env from '.env';

const LocationForm = (props) => {
  const { parkingLotLocation , formApiRef, google, setFormApi, event, selectedLot, whichLocation, lotName, t} = props
  const [{ currentPlace, currentMarker }, setState] = useState({
    currentPlace: whichLocation=== 'event' ?  {lat: parkingLotLocation.ltd, lng: parkingLotLocation.lng} : {lat: selectedLot?.ltd, lng: selectedLot?.lng} ,
    currentMarker: null
  });

  const onMarkerClick = (place) => (props, marker) => {
    setState(prevState => ({
      ...prevState,
      currentPlace: place,
      currentMarker: marker
    }));
  };

  const onMapClicked = () => {
    if (!currentMarker) return;
    setState(prevState => ({
      ...prevState,
      currentMarker: null
    }));
  };

  useEffect(()=>{
    if(whichLocation === 'event'){
      formApiRef.current.setValue('location.full_address', parkingLotLocation?.full_address)
      formApiRef.current.setValue('location.ltd', parkingLotLocation?.ltd)
      formApiRef.current.setValue('location.lng', parkingLotLocation?.lng)
    } else {
      formApiRef.current.setValue('location.full_address', selectedLot?.full_address)
      formApiRef.current.setValue('location.ltd', selectedLot?.ltd)
      formApiRef.current.setValue('location.lng', selectedLot?.lng)
    }

  },[parkingLotLocation, selectedLot, formApiRef, whichLocation])
  
  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,  
  }

  const addressFieldProps = {
    lSize: 2,
    iSize: 10,  
  }

  return (
    <React.Fragment>
        <p className={`${styles.title} mx-0 mb-3 p-0`}>{whichLocation==="event" ? t("events.city_events.event_location") : `${lotName}`}</p>
      <Form
        getApi={setFormApi}
        className={styles.form}
      >
        <Row className='mx-auto w-100'>
          <Col className={`m-0 pt-2 ${styles['fieldset-single']} pl-2`} xs={12}>
            {renderFields(fields(t).slice(2,3),{...addressFieldProps})}
          </Col>
          {renderFieldsWithGrid(fields(t).slice(0,2),2,6, {...doubleFieldProps})}
        </Row>
        <div className={styles.container} >
          <Map
            google={google}
            initialCenter={currentPlace}
            center={currentPlace}
            zoom={15}
            onClick={onMapClicked}
          >
            <Marker
              title={event?.event_name}
              position={whichLocation==='event' ?  {lat: parkingLotLocation.ltd, lng: parkingLotLocation.lng} : {lat: selectedLot.ltd, lng: selectedLot.lng}}
              
              onClick={onMarkerClick(whichLocation==='event' ?  {lat: parkingLotLocation.ltd, lng: parkingLotLocation.lng} : {lat: selectedLot.ltd, lng: selectedLot.lng})}
            />
            
          </Map>
        </div>
      </Form>
    </React.Fragment>
  );
}

LocationForm.propTypes = {
  google: PropTypes.object,
  isLoading: PropTypes.bool,
  parkingLotLocation: PropTypes.object,
};
  
export default GoogleApiWrapper({ apiKey: env.google_cloud_api_key })(withFormApi(LocationForm));