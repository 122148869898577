import React, { useEffect } from 'react'
import { Col } from 'reactstrap';
import Tabs from 'components/base/tabs';
import NavigationToolbar from 'components/base/navigationToolbar';
import styles from "./index.module.sass";
import SlideButton from 'components/base/slide_button';
import { ReactComponent as ResumeIcon } from 'assets/polygon-icon.svg';
import { ReactComponent as PauseIcon } from 'assets/union-icon.svg';
import FilterLogs from './filter';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { showSettings } from 'api/system_logs';

const tabs = [
  { value: 'error', label: 'Errors'},
  { value: 'email', label: 'Email Events'},
  { value: 'user_activity', label: 'User Activity'},
  { value: 'api_integration', label: 'API Connections'},
]

const ToolBar = (props) => {
  const { listType, setListType, openModal, setStatus, logName, moduleName, setFilters, currentStatus, setCurrentStatus} = props;

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const response = await showSettings({data: {module_name: moduleName}});
        if(response.status === 200 || response.status === 201 )
        {
          setCurrentStatus(response?.data?.status)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchResource()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <NavigationToolbar
      className='d-flex justify-content-space-between'
    >
      <Link to={'/dashboard/system_logs'} onClick={()=> setFilters({})} className={styles.backIcon} >
        <FontAwesomeIcon size="sm" color="grey" icon={faChevronLeft}/>
      </Link>
      <span className={`${styles.title} general-text-1`}>
        {`Audit Logs - ${logName}`}
      </span>
      <Col className='d-flex justify-content-start'>
        <Tabs className={styles.tabs}  list={tabs} defaultTab={listType} onClick={setListType} classNameInnerTab={styles.innerTab}/>
        {listType === 'user_activity' && (["Coupon", "CitationTicket", "Disputes", "Permit"].includes(moduleName)) && <FilterLogs {...props}/>}
      </Col>

      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          className={`${styles.pauseButton} ${currentStatus === 'pause' && styles.disable}`}
          icon={<PauseIcon className={styles.barIcon}/>}
          onClick={() => {currentStatus === 'resume' && openModal('confirm'); setStatus('pause')}}
        >
          Pause
        </SlideButton>
        <SlideButton
          isActive={true}
          className={`${styles.resumeButton} ${currentStatus === 'resume' && styles.disable}`}
          onClick={() => {currentStatus === 'pause' && openModal('confirm'); setStatus('resume')}}
          icon={<ResumeIcon className={styles.barIcon}/>}
        >
          Resume
        </SlideButton>
      </Col>

    </NavigationToolbar>
  )
}

export default ToolBar;