import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/violations";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/violations/index';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";
import { Link } from 'react-router-dom';
import { displayUnixTimestamp } from 'components/helpers';

const View = ({ startFetching, closeModal, isSaving, updateRecord, errors, setErrors, selectedRecordId}) => {
  const [isFetching, setIsFetching] = useState()
  const [violation, setViolation] = useState({})
  const [dropdowns, setDropdowns] = useState({
    violationStatus: [],
    ticketStatus: [],
    agencies: []
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch('tickets_statuses_field')
        ).then((response) => setDropdowns(state => ({...state, ticketStatus: response.data }))),
        startFetching(dropdownsSearch(`tickets_types_field`)
        ).then((response) => setDropdowns(state => ({...state, violationStatus: response.data }))),
        startFetching(dropdownsSearch('agency_officers_list', { agency_id: violation?.agency_id })
        ).then((response) => setDropdowns(state => ({...state, agencies: response.data })))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [violation])

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
    ticketStatus: dropdowns.ticketStatus,
    agencies: dropdowns.agencies,
    violationStatus: dropdowns.violationStatus
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }

  const doubleFieldProps = {
    iSize: 9,
    lSize: 3
  }
  const applicationData = useCallback((data) => {
    const { id, created_at, updated_at, plate_number, parking_lot, agency, violation_type, color, status, officer, history_logs=[] } = data
    const lastLog = history_logs.length > 0 ? history_logs[history_logs.length - 1] : null;
    return ({  
      id: id,
      created_at: displayUnixTimestamp(created_at),
      updated_date: displayUnixTimestamp(updated_at),
      updated_by: lastLog?.user || '',
      plate_number: plate_number,
      parking_lot: parking_lot.name,
      agency: agency?.name,
      violation_type: `${violation_type?.id}`,
      color: color,
      status: status,
      agency_id: agency?.id,
      admin_id: officer.id,
      logs_number: history_logs.length
  })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchViolation = async () => {
      if(!selectedRecordId)  return;
      try {
        const response = await show({id: selectedRecordId})
        if(response.status === 200) {
          const { data } = response;
          setViolation(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchViolation();

  }, [selectedRecordId, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const { admin_id, violation_type, status } = formState;
    if(!admin_id) errors['admin_id'] = ['Assignee is required']
    if(!violation_type) errors['violation_type'] = ['Violation Type is required']
    if(!status) errors['status'] = ['Status is required']
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };

    const formatSelectedDates = () => {
      if(values.status === 'Open')  return 'opened'
      else if(values.status === 'Canceled') return 'rejected'
      else if(values.status === 'Accepted') return 'approved'
      else if(values.status === 'Closed') return 'closed'
      else if(values.status === 'Auto Accepted') return 'auto_accepted'
    }

    const data = {
      status: formatSelectedDates(),
      violation_type: `${values.violation_type}`,
      admin_id: values.admin_id,
    };
    updateRecord(data)
  }

  const fields = (category) => {
    return showFields({category, ...fieldAttrs})
  }

  const renderParkingLotFields = (fields) => {
    fields[10] = {
      ...fields[10], 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput),
      icon: <Link className={styles.checkDetailsLink} to={`violations/${selectedRecordId}`}>Check the List</Link>
    }

    return(
      <Col style={{marginLeft: '-4px'}}>
        {renderFields(fields.slice(10,11), {...doubleFieldProps})}
      </Col>
    )
  }

  const renderTicketIdFields = (fields) => {
    fields[0] = {
      ...fields[0], 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput),
      icon: <Link className={styles.checkDetailsLink} to={`violations/${selectedRecordId}`}>Check Details</Link>
    }

    return(
      <Col style={{marginLeft: '-4px'}}>
        {renderFields(fields.slice(0,1), {...doubleFieldProps})}
      </Col>
    )
  }

  if(isFetching) return <Loader />

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Violation Details</p>
      <Form initialValues={violation} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const {category } = formState.values;
          const newFields = fields(category)
          return (
            <Col>
              <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Violation Report Details</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
                {renderTicketIdFields(newFields)}
              <div style={{ margin: '10px'}}>
                {renderFields(newFields.slice(1, 10), {...singleFieldProps, errors})}
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Activity Logs</span>
                <span className="border border-2 flex-grow-1 ml-2"></span>
              </div>
                {renderParkingLotFields(newFields)}
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                onClick={()=>  closeModal()}
                className={`${styles.cancelButton} mb-3`}
              >
                Cancel
              </Button>
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (View);