import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'

const ActivateDeactivate = ({closeModals, record, isSaving, setValidate, validate}) => {
  const message = () => <React.Fragment>
    <span className='mr-1'>The vehicle with</span>
    <span className={styles.boldText}>{`ID ${record?.id}`}</span>
    <span className='mx-1'>owned by</span>
    <span className={styles.boldText}>{` ${record?.user?.first_name} ${record?.user?.last_name}`}</span>
    <span className='mx-1'>will be immediately</span>
    <span >{`${validate ? 'invalidated' : 'validated' }.`}</span>
  </React.Fragment>

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{`Vehicle ${validate ? 'Invalidation' : 'Validation' }`}</p>
    <Container className={styles.content} >
      <Col className='mb-4'>
        { message() }
      </Col>
      <Col className="d-flex justify-content-center my-2">
        <Button onClick={() => closeModals()} type='button' className={`${styles.button} ${styles['button-bg-light']} mr-4`}>
          Cancel
        </Button>
        {
          validate
            && <Button 
                type='button' 
                onClick={() => {setValidate(!validate); closeModals()}} 
                className={`${styles.button} ${styles['button-bg-danger']}`}
                disabled={isSaving}
              >
                Invalidate
              </Button>
        }
        {
          !validate
            && <Button 
                type='button' 
                onClick={() => {setValidate(!validate); closeModals()}} 
                className={`${styles.button} ${styles['button-bg-primary']}`} 
                disabled={isSaving}
              >
                Validate
              </Button>
        }
      </Col>
    </Container>
  </Container>
  )
}

export default ActivateDeactivate;
