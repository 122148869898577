import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { filterFetcher, create, update, destroy, resend } from 'api/broadcast_messagse';
import { SET_LIST_ELEMENT } from 'actions/disputes';
import Show from '../show';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Send from '../send';
import Edit from '../edit';
import Delete from '../delete';
import Resend from '../resend';
import { dateFormatter } from 'components/helpers';

const initState = { errors: {}, isSaving: false, statuses: [] }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'statuses':
      return { ...state, statuses: payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const BroadCastModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const { record, openModal, modalType, startFetching, match, setList, setCount, filters } = props
  const dispatchAction = useDispatch();
  const [errors, setErrors] = useState();

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'show':
        return { width: '560px' };
      case 'send':
        return { width: '560px' };
      case 'edit':
        return { width: '560px' };
      case 'delete':
        return { width: '480px' };
      default:
        return { width };
    }
  },[modalType])
      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
    setState('innerModal', null)
  }, [openModal, setState])
        
  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const updateBroadcasts = useCallback(async () => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters })))
      .then((res) => {
        setList(res.data);
        setCount(res?.headers['x-total'])
      })
      .catch(error => console.log(error))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params, filters])

  const sendCategory = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data})
      if(response.status === 200 || response.status === 201){
        const time = dateFormatter(`${response.data?.schedule_date_and_time?.[0].date} ${response.data?.schedule_date_and_time?.[0].time}`)
        const message = `Broadcast message scheduled to be sent on ${time}`
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text:  `${response.data.status === 'scheduled' ? message : 'Message sent'}`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      setErrors(error.response.data.errors)
      setState('errors', error.response.data.errors)
    } finally {
      updateBroadcasts()
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addAlertMessages, setState, closeModal, setListElement, record, setErrors])

  const updateCategory = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({data: data, id: record?.id})
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text:  "Changes Saved",
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      setErrors(error.response.data.errors)
      setState('errors', error.response.data.errors)
    } finally {
      updateBroadcasts()
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addAlertMessages, setState, closeModal, setListElement, record, setErrors])

  const deleteMessage = useCallback(async () => {
    setState('isDeleting', true)
    try {
      await destroy({id: record?.id })
      closeModal()
      addAlertMessages([{
        type: 'danger',
        text: 'Message deleted',
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      updateBroadcasts()
      setState('isDeleting', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState,setErrors, addAlertMessages, closeModal, record])

  const resendMessage = useCallback(async () => {
    try {
      await resend({id: record?.id })
      closeModal()
      addAlertMessages([{
        type: 'primary',
        text: 'Message sent',
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      updateBroadcasts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState,setErrors, addAlertMessages, closeModal, record])
 
  return (
    <Modal 
      isOpen={!!modalType} 
      centered contentClassName={styles.modal} 
      style={{maxWidth: modalProps().width, width: '100%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'show' &&  
          <Show 
            record={record} 
            {...state}
            closeModal={closeModal}
            setState={setState}
          /> 
        }
        { modalType === 'send' &&  
          <Send 
            {...props}
            record={record} 
            {...state}
            errors={errors}
            setErrors={setErrors}
            closeModal={closeModal}
            sendCategory={sendCategory}
            setState={setState}
          /> 
        }
        { modalType === 'edit' &&  
          <Edit 
            {...props}
            record={record} 
            {...state}
            errors={errors}
            setErrors={setErrors}
            closeModal={closeModal}
            updateCategory={updateCategory}
            setState={setState}
          /> 
        }
        { modalType === 'delete' &&  
          <Delete 
            {...props}
            closeModal={closeModal}
            deleteMessage={deleteMessage}
          /> 
        }
        { modalType === 'resend' &&  
          <Resend
            {...props}
            closeModal={closeModal}
            resendMessage={resendMessage}
          /> 
        }
      </ModalBody>
    </Modal>
  )
}

export default BroadCastModal
