import React, { useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { renderFields } from 'components/base/forms/common_form'
import { fineFields } from 'components/helpers/fields/parking_citation_tickets/showFields'
import { isEmpty } from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

const EditFine = ({ closeModals, updateFine, isSaving, errors, setErrors, record}) => {
  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const initialValues = {
    updated_fine: record?.fine
  }

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customInputTimeClass: styles.formInputTime,
    icon: dollarIcon(),
    iconInputClass: styles.formInput.concat(' ', styles.iconInput),
  }

  const singleFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const fields = () => {
    return fineFields({...fieldAttrs})
  }
  
  const validateValues = (formState) => {
    const errors = {}
    const { updated_fine, reason } = formState;
    if(!updated_fine) { errors['updated_fine'] = ['Fine is required']}
    if(!reason) { errors['reason'] = ['Reason is required']}
    return errors
  }

  const save = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    updateFine(values);
  }

  return (
    <Container>
      <p className={`${styles.title}  p-0`}>Edit Fine</p>
      <Form getApi={setFormApi} initialValues={initialValues} className='m-auto' onSubmit={save}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Row className='mx-auto w-100 p-0'>
              <Col className={styles.modalContent}>
                {renderFields(newFields.slice(0, 2), {...singleFieldProps, errors})}
              </Col>
              <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                <Button onClick={()=>  closeModals()} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={styles.button}  isLoading={isSaving}>
                  Save
                </Button>
              </Col>
            </Row>
          )
        }}
      </Form>
    </Container>
  )
}

export default EditFine;
