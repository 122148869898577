import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
/* Styles/Assets */
import styles from './index.module.sass';

class Delete extends React.Component {

  componentDidMount = () => {
    const {setState} = this.props
    setState('errors', {})
  }

  render () {
    const { deletePermitTypeCode, state, closeModal, code } = this.props
    return (
      <Col className={styles.body}>
        <div className={styles.content}>
          <Col className='my-3'>
            <span className={styles.content}>Deleting the <span className={styles.boldText}>{code?.code} </span> will remove all the permit types under it. Are you sure?</span>
          </Col>
        </div>
        <Col className="d-flex justify-content-center my-3">
          <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
            Cancel
          </Button>
          <Button type='button' onClick={deletePermitTypeCode} className={`${styles.submitButton}`}   disabled={state.isDeleting}>
            Delete
          </Button>
        </Col>
      </Col>
    )
  }
}

export default Delete;