import React, { useCallback, useEffect, useState } from 'react'
/* Styles/Assets */
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import { Row, Col } from 'reactstrap';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import styles from "./index.module.sass";
/* Component */
import FilterForm from './filterForm';
/* API */
import { search as dropdownsSearch } from "api/dropdowns";
/* Base */
import SlideButton from 'components/base/slide_button';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';


const ToolBar = ({openModal, total, modalType, ...props}) => {

  const {isFetching, activeTab, setActiveTab} = props;

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])


 

  return (
    <NavigationToolbar
    openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      >
       <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={styles.title}>{`Monitored Vehicles (${total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          icon={<PlusIcon />}
          onClick={() => {
            openModal('create')
          }}
        >
          <span className='mr-3 d-inline-block' style={{width: '150px', fontWeight: 500}}> Add New Vehicles</span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

const CollapseContent = ({ startFetching, setList, setFilters, match, resource }) => {
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
    verdictList: []
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
          setDropdowns(state => ({...state, parkingLots: response.data }))),
        startFetching(dropdownsSearch("vehicle_monitor_history_verdicts")).then((response) =>
          setDropdowns(state => ({...state, verdictList: response.data })))
      ])
    }

    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm 
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
      />
    </Row>
  )
}

export default ToolBar;