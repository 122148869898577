import React, { useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* API */
import { show } from 'api/webapp/permit_application';
/* Helpers */
import { showFields } from 'components/helpers/fields/app/permit_applications/showFields';
import { capitalize, camelize, displayUnixTimestamp } from 'components/helpers';
import Loader from 'components/helpers/loader';
/* Base */
import Button from 'components/base/button';
/* Styles */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
import { useTranslation } from 'react-i18next';

const  Show = ({record, isSaving, closeModal})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [permitApplication, setPermitApplication] = useState({})
  const { t } = useTranslation()
  
  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    t: t
  }

  const doubleFieldProps = {
    iSize: 7,
    lSize: 5
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }

  const dotIcon = (status, color='') => <FontAwesomeIcon icon={faCircle} className={`${styles[`${status}-color`]} ${styles.dotIcon}`} color={color}/>
  
  const permitApplicationData = (data) => {
    const { vehicle={}, permit_type={}, permit_number, status } = data
    const { user } = vehicle
    return ({
      vehicle: {
        plate_number: vehicle.plate_number?.toUpperCase(),
        make: capitalize(vehicle.manufacturer?.name),
        color: capitalize(vehicle.color),
        model: capitalize(vehicle.model),
        registration_state: capitalize(vehicle.registration_state),
        year: vehicle.manufacture_year
      },
      applicant: { 
        full_name: `${user?.first_name} ${user?.last_name}`, 
        home_address: user.home_address?.address1,
        home_phone: user.home_address?.phone,
        work_address: user.work_address?.address1,
        work_phone: user.work_address?.phone,
      },
      application: {
        permit_number,
        issue_on: displayUnixTimestamp(new Date(record.submitted_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
        type: camelize(record.application_type),
        permit_type_name: permit_type?.name,
        status: camelize(status)
      },
    })
  }

  const renderPermitAppFields = (fields) => {
    fields[3] = {
      ...fields[3], 
      icon: dotIcon(record.status), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }

    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>{t("subscriber.my_permit_applications.permit_application_details")}</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 1, 12, { ...singleFieldProps })}
        </div>
      </Col>
    )
  }

  const renderVehicleFields = (fields) => {
    fields[2] = {
      ...fields[2], 
      icon: dotIcon(null, permitApplication.vehicle?.color), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }

    return (
      <Col>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>{t("subscriber.my_permits.show.vehicle_details")}</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 2, 6, { ...doubleFieldProps })}
        </div>
      </Col>
    )
  }

  useEffect(() => {
    const fetchPermit = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: record.id})
        const newPermit = permitApplicationData(response.data);
        setPermitApplication(newPermit)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(record.id) { fetchPermit() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record.id])


  const fields = showFields(fieldAttrs)
  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0 my-3'>
      <div className='mb-1'>
        <p className={`${styles.title} mx-0 mb-2 p-0 text-capitalize text-center`}>{` Permit Application ${permitApplication?.id}`}</p>  
      </div>
      <Form initialValues={permitApplication} className={styles.form}>
        { renderVehicleFields(fields[0]) }
        <Col>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>{t("subscriber.my_permits.show.owner_information")}</span>
            <span className="border border-2 flex-grow-1 ml-2"></span>
          </div>
          <div>
            {renderFieldsWithGrid(fields[1], 2, 6, { ...doubleFieldProps })}
          </div>
        </Col>
        { renderPermitAppFields(fields[2]) }
      </Form>
      <Col className='d-flex justify-content-center align-items-center mt-4 mb-2'>
          <Button
            onClick={closeModal}
            className={styles.cancelButton}
            type='button'
            disabled={isSaving}
          >
            Cancel
          </Button>
        </Col>
    </Container>
  )
}

export default Show;
