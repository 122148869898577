import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import NavigationToolbar from 'components/base/navigationToolbar';

const ToolBar = (props) => {

  const {total} = props;

  return (
    <NavigationToolbar
      backTab='/dashboard/subscribers'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={`${styles.title} general-text-1`}>
          {`List of Vehicles Owned (${total})`}
        </span>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;