import React, { useState, useCallback, useContext } from "react";
import { Divider, Input, ColorPicker, Button, Modal } from "antd";
import styles from "./index.module.sass";
import { Row, Col, Container, Spinner } from "reactstrap";
import { update } from "api/themes";
import NavigationToolbar from "components/base/navigationToolbar";
import ErrorWrapper from "components/base/forms/common_form/error";
import BaseButton from "components/base/button";
import { isEmpty } from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as NavArrow } from "assets/arrow-up-right.svg";
import Description from "../new/description";
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { invoke } from "actions";
import { SET_SINGLE_RECORD, SET_LIST_ELEMENT, ADD_LIST_ELEMENT } from "actions/themes";
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default" />
    </span>
  );
};

const Edit = (props) => {
  const { backPath, history, location, setListElement } = props;
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const { listType, record } = location?.state? location?.state  : ''
  const initialColors = {
    basic_theme: record?.basic_theme,
    default_text: record?.default_text,
    navbar: record?.navbar,
    navbar_text: record?.navbar_text,
    table_header: record?.table_header,
    table_header_text: record?.table_header_text,
    table_items_bg_one: record?.table_items_bg_one,
    table_items_bg_two: record?.table_items_bg_two,
    table_item_text: record?.table_item_text,
    page_header_text: record?.page_header_text,
    primary_button_bg: record?.primary_button_bg,
    default_button_text: record?.default_button_text,
    danger_button_bg: record?.danger_button_bg,
    save_button_bg: record?.save_button_bg,
    cancel_button_bg: record?.cancel_button_bg,
  };
  const [themeName, setThemeName] = useState(record?.theme_name);
  const [errors, setErrors] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const [colors, setColors] = useState(initialColors);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedColor, setSelectedColor] = useState();
  const handleColorChange = (attribute, newColor) => {
    setColors({ ...colors, [attribute]: newColor.toHexString() });
  };

  const getLabel = (name) => {
    switch (name) {
      case "basic_theme":
        return "Basic theme color";
      case "default_text":
        return "Default text color";
      case "navbar":
        return "Navbar color";
      case "navbar_text":
        return "Navbar text color";
      case "table_header":
        return "Table header color";
      case "table_header_text":
        return "Table header text color";
      case "table_items_bg_one":
        return "Table items background color - 1";
      case "table_items_bg_two":
        return "Table items background color - 2";
      case "table_item_text":
        return "Table item text color";
      case "page_header_text":
        return "Page header text color";
      case "primary_button_bg":
        return "Background color for primary button";
      case "default_button_text":
        return "Text color for default button";
      case "danger_button_bg":
        return "Background color for Danger button";
      case "save_button_bg":
        return "Background color for Save button";
      case "cancel_button_bg":
        return "Background color for Cancel button";
      default:
        return "";
    }
  };

  const validateValues = () => {
    let errors = {};

    if (!themeName) {
      errors["theme_name"] = ["Theme Name is required !"];
    }

    return errors;
  };

  const getTextColor = (bgColor) => {
    // Converting hex color to RGB
    const rgb = hexToRgb(bgColor);
    // Calculating brightness using luminance formula
    const brightness = (rgb?.r * 299 + rgb?.g * 587 + rgb?.b * 114) / 1000;
    // Return black or white based on brightness
    return brightness > 125 ? "#000000" : "#ffffff";
  };

  //This is the Function to convert hex color to RGB
  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const fullHex = hex?.replace(
      shorthandRegex,
      (m, r, g, b) => r + r + g + g + b + b
    );
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const save = useCallback(
    async (values) => {
      setErrors({});
      let vals = {
        applied: record?.applied,
        theme_name: themeName,
        theme_category: record?.theme_category,
      };
      const finalValues = { ...vals, ...values };
      const inputErrors = validateValues();
      if (!isEmpty(inputErrors)) {
        setErrors(Object.assign(inputErrors));
        return;
      }
      setIsSaving(true);
      try {
        const response = await update({ data: finalValues, id: record.id });
        if (response.status === 200) {
          setListElement(response.data);
          addAlertMessages([{
            type: 'primary',
            text: `Saved Changes Successfully`,
            onlyMessage: true
            }],
            'center'
          )
          history.push(backPath);
          if(listType==='dashboard'){
            window.location.reload()
          }
        }
      } catch (error) {
        console.log(error);
        setErrors(error?.response?.data?.errors);
      } finally {
        setIsSaving(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setErrors, themeName, setIsSaving, listType, record]
  );
  
  const handleInfoClick = (selectedColor) => {
    setSelectedColor(selectedColor);
    setShowDescription(true);
  };

  return (
    <Container className="mw-100 p-0">
      <NavigationToolbar
        backTab={backPath}
        className="d-flex justify-content-space-between"
      />
      <Col xs={4}>
        <label className={styles.label}>
          Theme Name <span className={styles.required}>*</span>
        </label>
        <ErrorWrapper
          className={styles.themeErrorWrapper}
          errors={errors}
          field={{ name: "theme_name" }}
        >
          <Input
            name="theme_name"
            defaultValue={record.theme_name}
            maxLength={255}
            value={themeName}
            className={styles.input}
            onChange={(e) => setThemeName(e.target.value)}
          />
        </ErrorWrapper>
      </Col>
      <Col xs={12}>
        <Divider />
        <p className={styles.note}>
          Note: Colors can be defined as a Hexadecimal code or rgba value
        </p>
      </Col>
      <Col xs={12}>
        <p className={styles.title}> Colors </p>
      </Col>
      <Row className="m-0 p-0">
        {Object.entries(colors).map(([attribute, color]) => (
          <Col key={attribute} className={styles.colorStrip} xs={4}>
            <Col
              className="m-0 p-0"
              onClick={() => handleInfoClick(attribute)}
              xs={2}
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={styles.questionMark}
              />
              <NavArrow className={styles.nextArrow} />
            </Col>
            <ColorPicker
              value={color}
              onChange={(newColor) => handleColorChange(attribute, newColor)}
              placement="top"
            >
              <Button
                className={styles.pickerBtn}
                style={{
                  backgroundColor: color,
                  color: getTextColor(color),
                  transition: "background-color 0.1s ease",
                }}
              >
                {color.toUpperCase()}
              </Button>
            </ColorPicker>
            <span className={styles.label}>{getLabel(attribute)}</span>
          </Col>
        ))}
      </Row>
      <Col
        className="d-flex justify-content-center align-items-center pt-5"
        xs={12}
      >
        <BaseButton className={styles.button} onClick={() => save(colors)}>
          {isSaving ? btnSpinner({ className: "spinner-border" }) : "Save"}
        </BaseButton>
      </Col>
      <Modal
        centered
        open={showDescription}
        onCancel={() => setShowDescription(false)}
        footer={null}
        classNames={{ header: "text-center" }}
      >
        <Description
          selectedColor={selectedColor}
          title={getLabel(selectedColor)}
        />
      </Modal>
    </Container>
  );
};


const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

export default connect(null, mapDispatch)(withFetching(withCurrentUser(Edit)));