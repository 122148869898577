import { SubscriberPermitApplicaitonsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "../entities";

const { index, records } = reduceEntity(SubscriberPermitApplicaitonsActions);

const SubscriberPermitApplicationsReducers = combineReducers({
  index,
  records,
});

export default SubscriberPermitApplicationsReducers;
