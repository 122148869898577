import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { allKeys, each, isEmpty } from 'underscore'
import { Form } from 'informed'
import GenerateModal from './generateModal'
import env from '.env'
import qs from 'qs';
import { ROLES } from 'config/permissions'
/* Api */
import { search as dropdownSearch, searchV1 } from 'api/dropdowns' 
/* Helpers */
import { reportsField } from 'components/helpers/fields/permits/index'
import AccessControl from 'components/helpers/access-control';
import { displayDate } from 'components/helpers'
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button'
import NavigationToolbar from 'components/base/navigationToolbar'
/* Modules */
import withCurrentUser from 'components/modules/with_current_user'
/* Styles and Assets */
import styles from './index.module.sass'

const exportFile = (data) => {
  const query = qs.stringify({
    token: localStorage.TOKEN,
    ...data,
  }, { arrayFormat: 'brackets' })

  const url = `${env.backend_url}/dashboard/permits/permit_report.xlsx?${query}`
  window.open(url, '_blank');
  window.focus();
}

const verifyTotalCost = (values={}) => {
  const { total_cost={} } = values
  let errors = {}
  if(total_cost.maximum || total_cost.minimum) {
    if(!total_cost.maximum && total_cost.maximum !== 0) {
      errors["total_cost['maximum']"] = ['Maximum cost can\'t be blank']
    }
    if(!total_cost.minimum && total_cost.minimum !== 0) {
      errors["total_cost['minimum']"]  = ['Minimum cost can\'t be blank']
    }
  }
  
  if(total_cost.maximum && total_cost.minimum) {
    if(total_cost.maximum - total_cost.minimum < 1) {
      errors["total_cost['maximum']"] = ['Maximum cost must always be greater than the minimum cost by at least $1']
    }
  }

  return errors
}

const attrs ={
  customLabelClass: styles.formLabel,
  customInputClass: styles.input,
  checkboxClass: styles.checkbox,
  customInputDateClass: styles.rightDate
}

const Reports = ({currentUser}) => {

  const formApiRef = useRef(null)
  const filtersRef = useRef({})

  const [parkingLots, setparkingLots] = useState([]);
  const [permitTypes, setPermitTypes] = useState([]);
  const [errors, setErrors] = useState({})
  const [openGenModal, setOpenGenModal] = useState(false)
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const generateReport = useCallback((values={}) => {
    const formFilters = Object.assign(filtersRef.current)
    const { issue_date={}, application_date={}, expiry_date={} } = formFilters
    const filters = {
      ...formFilters,
      emails: !isEmpty(values.emails) ? Object.values(values.emails) : [],
      issue_date: {
        from: displayDate(issue_date.from, 'yyyy-mm-dd'),
        to: displayDate(issue_date.to, 'yyyy-mm-dd')
      },
      application_date: {
        from: displayDate(application_date.from, 'yyyy-mm-dd'),
        to: displayDate(application_date.to, 'yyyy-mm-dd')
      },
      expiry_date: {
        from: displayDate(expiry_date.from, 'yyyy-mm-dd'),
        to: displayDate(expiry_date.to, 'yyyy-mm-dd')
      }
    }
    
    if(filters.expired === 'both') delete filters.expired
    if(filters.day_pass_permits === 'both') delete filters.day_pass_permits
    if(filters.special_permits === 'both') delete filters.special_permits

    each(allKeys(filters.permit_types), (attr) => {
      if(filters.permit_types[attr] === 'both') delete filters.permit_types[attr]
    })

    exportFile(filters)
    formApiRef.current.reset();
    setOpenGenModal(false)
  },[setOpenGenModal])
  
  useEffect(() => {
    const fetchParkingLots = async () => {
      const response = await dropdownSearch('parking_lot_list');
      setparkingLots(response.data)
    }
    if(isEmpty(parkingLots)) { fetchParkingLots() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    const fetchPermitTypes = async () => {
      const response = await searchV1('permit_type_list');
      setPermitTypes(response.data)
    }
    if(isEmpty(permitTypes)) { fetchPermitTypes() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const handleSubmit = useCallback((values) => {
    filtersRef.current = values
    setErrors({})
    const errors = verifyTotalCost(values)
    
    if(!isEmpty(errors)) {
      
      setErrors(errors)
      return
    }
    setOpenGenModal(true)
  },[setErrors, setOpenGenModal])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className={`mw-100 m-0 p-3`} style={{minHeight:"600px"}}>
        <NavigationToolbar
          backTab='/dashboard/permits'
          className=''
        >
          <Col className='d-flex justify-content-start align-items-center'>
            <span className={styles.title}>Generate Permits Reports</span>
          </Col>
        </NavigationToolbar>
        <Row className={`${styles.main} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
          <p className={`${styles.header} pl-3`}>
            Report Criteria - Issued Permits
          </p>
          <Form getApi={setFormApi} onSubmit={handleSubmit} className="w-100">
          { ({formState}) => {

            const { issue_date={}, day_pass_permits } = formState.values;
            const allFields = reportsField({
              permitTypes, parkingLots,  startDate: issue_date.from, endeDate: issue_date.to, ...attrs
            });
            return <>
                <Col className={styles.fields}>
                  <div className={styles.fieldset}>
                    {renderFields(allFields[0].slice(0,2), { lSize: 4, iSize: 8, errors })}
                    <div className={styles['fieldset-double']}>
                      <div className='general-text-2'><span className={styles.doubleLabel}>Received Date</span></div>
                      <div className='d-flex'>
                        {renderField(allFields[0][2], {iSize: 11, errors })}
                        {renderField(allFields[0][3], {iSize: 10, errors })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.fieldset}>
                    {renderFields(allFields[0].slice(4,6), { lSize: 4, iSize: 8, errors })}
                    <div className={`${styles['fieldset-double']}`}>
                      <div className='general-text-2'><span className={styles.doubleLabel}>{'Expiration Date'}</span></div>
                      <div className='d-flex'>
                        {renderField(allFields[0][6], {iSize: 11, errors })}
                        {renderField(allFields[0][7], {iSize: 10, errors })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.fieldset}>
                    {renderFields(allFields[0].slice(8,10), { lSize: 4, iSize: 8, errors })}
                    {
                      day_pass_permits 
                        ? <span className={`${styles.checkboxText} text-primary`}>* Day Pass Permits are included</span>
                        : <span className={`${styles.checkboxText} text-danger`}>* Day Pass Permits are NOT included</span>
                    }
                  </div>
                </Col>

                <div className="d-flex align-items-center my-4 w-100 px-3">
                  <span className={styles.detailsLabel}>Filters</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                <Col className={styles.fields}>
                  <div className={styles.fieldset}>
                    {renderFields(allFields[1].slice(0,1), { lSize: 4, iSize: 8, errors })}
                  </div>
                  <div className={styles['fieldset-double']}>
                    <div className='general-text-2'><span className={styles.doubleLabel}>Permit cost ($)</span></div>
                    <div className='d-flex'>
                      {renderField(allFields[1][1], {iSize: 8, errors })}
                      {renderField(allFields[1][2], {iSize: 8, errors })}
                    </div>
                  </div>
                  <div className={styles['fieldset-double']}>
                    <div className='general-text-2'><span className={styles.doubleLabel}>Issued Date Range</span></div>
                    <div className='d-flex'>
                      {renderField(allFields[1][3], {iSize: 12, errors })}
                      {renderField(allFields[1][4], {iSize: 12, errors })}
                    </div>
                  </div>
                </Col>
                <Col className='d-flex justify-content-center my-3' xs={12}>
                  <Button 
                    className={`${styles.button} ${styles['button-disabled']} mr-5`}
                    type='button'
                    onClick={() => formApiRef.current.reset()}
                  >
                    Reset
                  </Button>
                    <Button 
                    className={styles.button} 
                    type='submit'
                  >
                    Generate
                  </Button>
                </Col>

            </>
          }}
          </Form>
        </Row>
        <GenerateModal 
          modal={openGenModal}
          generateReport={generateReport}
          closeModal={() => setOpenGenModal(false)}
        />
      </Container>
    </AccessControl>
  )
}

export default withCurrentUser(Reports)
