const applicationDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, t } = attrs;
  return [
    {
      name: 'application[issue_on]',
      label: t("subscriber.my_permit_applications.application_date"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[type]',
      label: t("subscriber.my_permit_applications.application_type"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[permit_type_name]',
      label: t("subscriber.my_permits.show.type"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[parking_lot]',
      label: t("subscriber.my_permit_applications.valid_parking_lot"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[status]',
      label: t("events.private_events.status"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export default applicationDetails;