import React, { useRef, useEffect } from 'react';

const DragScroll = ({ children }) => {
  const containerRef = useRef(null);
  const isDraggingRef = useRef(false);
  const lastClientXRef = useRef(0);

  useEffect(() => {
    const container = containerRef.current;

    const handleMouseDown = (event) => {
      isDraggingRef.current = true;
      lastClientXRef.current = event.clientX;
      container.style.cursor = 'grabbing';
    };

    const handleMouseUp = () => {
      isDraggingRef.current = false;
      container.style.cursor = 'grab';
    };

    const handleMouseMove = (event) => {
      if (!isDraggingRef.current) return;

      const delta = event.clientX - lastClientXRef.current;
      container.scrollLeft -= delta;
      lastClientXRef.current = event.clientX;
    };

    container.addEventListener('mouseleave', handleMouseUp);
    container.addEventListener('mousedown', handleMouseDown);
    container.addEventListener('mouseup', handleMouseUp);
    container.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mouseleave', handleMouseUp);
      container.removeEventListener('mousedown', handleMouseDown);
      container.removeEventListener('mouseup', handleMouseUp);
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className='d-flex align-content-center overflow-auto  w-100'
    >
      {children}
    </div>
  );
};

export default DragScroll;