import React, { useCallback, useRef, useEffect } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Helpers */
import  {fieldsNewSignup, fieldsNewRefer}  from 'components/helpers/fields/reward_types/fieldsNew'
/* Styles/Assets */
import styles from './index.module.sass';
import withFetching from 'components/modules/with_fetching';

const initialValues = { 
  category: "signup",
  reward_points: 1,
  points_to_dollar: 1

}

const attrs = {
  customLabelClass: `${styles.inputLabel}`,
  customInputClass: `${styles.input}`,
}


const validateValues = (formState) => {
  const errors = {}
  const {reward_points, category, points_to_dollar } = formState;
  if(!reward_points) { errors['reward_points'] = ['Reward Points can\'t be blank']}
  if(!category) { errors.category = ['Category can\'t be blank']}
  if(!points_to_dollar) { errors['points_to_dollar'] = ['Points to Dollar can\'t be blank']}
  return errors
}

const Create = (props) => {
  const  { setState, createRewardType, closeModal, errors, isSaving } = props;
  const formApiRef = useRef();
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  
  const updateHourlyRate = (e) => {
    
    if(e?.value === 'free' && e?.label === 'Free') {
      formApiRef.current.setValues({...formApiRef.current.getState(), 'hourly_rate': '0.0', 'cost': '0.0'})
    }
  }

  const singleFieldProps = {
    lSize: 2,
    iSize: 10,    
  }

  const doubleFieldProps = {
    lSize: 5,
    iSize: 6,
    events: {
      onChange: (e) => {updateHourlyRate(e)}
    }
  }

  const save = useCallback(() => {
    setState('errors', {})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setState('errors', inputErrors)
      return
    };
    createRewardType(values);
  },[setState, createRewardType]);

  
  const getSignupFields = (category, formState) => {
    return fieldsNewSignup({mute: false, category, ...attrs, formState})
  }

  const getReferFields = (category, formState) => {
    return fieldsNewRefer({mute: false, category, ...attrs, customTooltip: styles.tooltip, formState})
  }

  useEffect(() => {
    setState('errors',{})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}>Create Reward Program</p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >

        {({ formState }) => {
            const { category } = formState.values;
            const newSignupFields = getSignupFields(category, formState)
            const newReferFields = getReferFields(category, formState)
            return (
              <React.Fragment>
              <Row className='mx-auto w-100'>
                { map(newSignupFields[0], (field, idx) => {
                    return (
                      <Col key={idx} className={`m-0 ${styles.fieldset}`} xs={12}>
                        { renderField(field, { ...singleFieldProps, errors, formState} )}
                      </Col>
                    )
                }) }
                { category === 'signup' && <React.Fragment>
                  <Col className={`m-0 ${styles.fieldset}`}>
                    <div>
                    { renderFields(newSignupFields[1], {formState, ...doubleFieldProps, errors} )}
                    </div>    
                    <div>
                      { renderFields(newSignupFields[2], {formState, ...doubleFieldProps, errors} )}
                    </div>
                  </Col>
                  </React.Fragment>
                }
                {category === 'refer' && <React.Fragment>
                  <Col className={`m-0 ${styles.fieldset}`}>
                    <div>
                    { renderFields(newReferFields[0], {formState, ...doubleFieldProps, errors})}
                    </div>    
                    <div>
                      { renderFields(newReferFields[1], {formState, ...doubleFieldProps, errors})}
                    </div>
                  </Col>
                </React.Fragment>
                }
                <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={`${styles.button}`}  isLoading={isSaving}>
                    Create
                  </Button>
                </Col>
              </Row>
              </React.Fragment>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);