import React, { useEffect } from 'react'
import { Container } from 'reactstrap';
import styles from './index.module.sass'
import { PDFReader } from 'reactjs-pdf-reader';
import { isEmpty } from 'underscore';
import Loader from 'components/helpers/loader';

const Preview = ({ passBase64, fileUrl, setFileUrl}) => {

  useEffect(()=> {
    const base64toBlob = () => {
      const bytes = atob(passBase64);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }
      const url = URL.createObjectURL(new Blob([out], { type: 'application/pdf' }));
      setFileUrl(url) 
    }
    if(passBase64) base64toBlob()
  },[passBase64, setFileUrl])
  
  if (isEmpty(passBase64) && isEmpty(fileUrl)) {
    return <Loader />;
  }

  return (
    <Container className='p-0 m-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Boot Violation</p>
      {passBase64 && fileUrl && (
        <div className={styles.previewSection}>
          <center> 
           <PDFReader url={fileUrl}  width='450'/>
          </center> 
        </div>
      )}
  </Container>
  )
}

export default Preview;