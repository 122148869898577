import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const SettingConfirmation = (props) => {

  const { updateViolationSetting, closeModal, captureViolation } = props;

  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Are you sure?</p>
      <div className={styles.content}>
        <Col className='mt-4'>
          <span className={`${styles.boldText} ml-1 mr-1`}>{captureViolation === 'active' ? 'Disabling' : 'Enabling'}</span>
          this will 
          <span className='ml-1 mr-1'>{captureViolation === 'active' ? 'stop' : 'start'}</span>
          capturing violations committed by subscriber vehicles.
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={updateViolationSetting} className={`${captureViolation === 'active' ? styles.disableButton : styles.enableButton}`}>
          {captureViolation === 'active' ? 'Disable' : 'Enable'}
        </Button>
      </Col>
    </Col>
  )
}

export default SettingConfirmation;