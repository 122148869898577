import { AdminActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records, filters } = reduceEntity(AdminActions);

const AdminReducers = combineReducers({
  index,
  records,
  filters
});

export default AdminReducers;
