import React from 'react'
import {  Col, Row } from 'reactstrap';
import FilterForm from './filterForm';
import styles from './index.module.sass';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import Tabs from 'components/base/tabs';

const CollapseContent = ({ startFetching,filters, setList, setFilters, match, resource}) => {

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm 
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        filters={filters}
      />
    </Row>
  )
}

const ToolBar = (props) => {

  const {activeTab, history, setFormPage, formPage, openModal} = props;

  const tabs = [
    {label: 'Email Addresses', value: 'email_addresses'},
    {label: 'Notification Templates', value: 'notifications'},
    {label: 'Email Signature', value: 'email_signature'}
  ]


  const handleTabsChange = (tab) => {
    setFormPage(tab);
    history.push(`/dashboard/${tab}`);
  };


  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center p-0'>
        {formPage === 'notifications' && <span className={`${styles.title} general-text-1 mr-3`}>
          {`Notification Configurations (${props.total})`}
        </span>}
        {formPage === 'email_addresses' && <span className={`${styles.title} general-text-1 mr-3`}>
          {`Email Addresses (${props.total})`}
        </span>}
        <Tabs className={styles.tab} list={tabs} defaultTab={formPage} onClick={handleTabsChange} />
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        {formPage === 'email_addresses' && <Button
          onClick={() => openModal('add_email')}
          className={styles.addButton}
          size="md"
        >
          Add Email
        </Button>}
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;