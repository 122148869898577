import React, { useEffect } from 'react'
import { Col, Container } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { isEmpty } from 'underscore'
import { renderFields } from 'components/base/forms/common_form';
import { updateFields } from 'components/helpers/fields/value_added_services';

const Enable = ({closeModal, enableService, errors, setErrors, modalType, selectedRecord}) => {

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { cost } = formState;
    if(!cost) { errors['cost'] = ['Cost is required']}
    return errors
  }

  const save = (values) => {

    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    enableService(values);
  }

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fields = () => {
    return updateFields({...fieldAttrs})
  }

  return (
    <Container className='p-0'>
      <Form className={styles.form} initialValues={selectedRecord} onSubmit={save}>
        {({ formState }) => {
          const updateFields = fields()
          return (
            <Col className='mt-4'>
              <Col>
                <div className={modalType === 'enable_service' ? 'd-none' : ''} >
                  {( !['Car Wash', 'Exterior Detailing', 'Interior Detailing', 'Refueling', 'Oil Change', 'Mechanical Inspection'].includes(selectedRecord?.name)) 
                    && renderFields(updateFields.slice(0, 1), {...singleFieldProps, errors})}
                </div>
                <div >
                  {renderFields(updateFields.slice(1, 3), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-3`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton}>
                  Save
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default Enable;
