import React, { useState,useEffect, useCallback } from 'react';
import { Card, Row, Col, Container } from 'reactstrap';
import styles from './index.module.sass'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import Toggle from 'components/base/toggle';
import { index } from 'api/valet_parkings';
import ValetModal from '../valetModal';
import ValueAddedServiceModal from '../value_added_services/value_added_service_modal';
import ToolBar from './toolBar.jsx'

const Index = (props) => {
  const { history, isResourceFetching } = props
  const [valetStatus, setValetStatus] = useState(false)
  const [ modalType, setModalType ] = useState('')
  const [activeTab, setActiveTab] = useState()
  const [listType, setListType] = useState('value_added_services');
  const openModal = (modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'enable':
        val = 'enable'
        break;
      case modalType === 'disable':
        val = 'disable'
        break;
      case modalType === 'edit':
        val = 'edit'
        break;
      default:
        break;
    }
    setModalType(val)
  }

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  useEffect(() => {
    const showValetSetting = async () => {
      try {
        const response = await index()
        if(response.status===200){
          Object.values(response.data).forEach(function (key) {
            if(key.valet_enabled) { 
              setValetStatus(true) 
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    showValetSetting()
  },[])

return (
    <Container className='mw-100 p-0 pb-4'>
      <Row className='m-auto'>
        <Col xs={12} className='p-0'>
        <ToolBar
          openModal={openModal} 
          activeTab={activeTab} 
          setActiveTab={setActiveTab}
          valetStatus={valetStatus}
          setListType={setListType}
          listType={listType}
          setValetStatus={setValetStatus}
          isFetching={isFetching}
        />
        </Col>
        <Col xs={12} className={styles.tableContainer}>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Valet Parking Service
              </Col>
              <Col className={styles.iconTwo}>
                <Toggle
                  onClick={() => openModal(`${valetStatus ? 'disable' : 'enable'}`)}
                  customToggleClass= {styles.toggle}
                  customToggleOnClass= {styles.toggleOn}
                  positiveText=''
                  negativeText=''
                  value={valetStatus}
                  offHighlightClass={styles.toggleHighlight}
                  onHighlightClass={styles.toggleOnHighlight}
                />
              </Col>
              <Col className={styles.iconTwo}>
                {valetStatus && 
                  <EditIcon className={styles.sendIcon}/>
                }
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Pricing Models
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Staff Management
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Valet Sessions
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Bookings & Requests
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Vehicle Damage Claims
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} onClick={() => history.push(`value_added_services`)}>
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Value Added Services
              </Col>
            </Row>
          </Card>
          <Card className={styles.card} >
            <Row className="h-100 d-flex ">
              <Col className={styles.text1}>
                Analytics
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ValetModal
        {...props}
        modalType={modalType} 
        openModal={openModal} 
        setValetStatus={setValetStatus}
      />
      <ValueAddedServiceModal
        {...props}
        openModal={openModal}
        modalType={modalType}
      />
    </Container>
)

}

export default Index