const parkingLotDetails = (attrs) => {
  const { customInputClass, customLabelClass, mute=true, id } = attrs;
  return [
    {
      name: `lot[lot-${id}][name]`,
      label: 'Parking Lot Name',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: `lot[lot-${id}][full_address]`,
      label: "Address",
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: `lot[lot-${id}][available_spaces]`,
      label: 'Alloted Spaces',
      customInputClass,
      customLabelClass,
      disabled: mute,
    }
  ]
}

export default parkingLotDetails;