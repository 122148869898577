import React from 'react'
import { Col, Container } from 'reactstrap';
import Button from 'components/base/button';
import styles from './index.module.sass'

const ActivateDeactivate = ({toggleStatus, closeModal, status, isSaving, record}) => {

  const message = () => <React.Fragment>
    <span className={styles.boldText}>{record.event_name.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}</span>
    <span className='mx-1'> notification event will be</span>
    <span>{`${status === 'active' ? 'deactivated' : 'activated' }`}</span>
    <span className='mx-1'>immediately.</span>
  </React.Fragment>

  return (
    <Container className='p-0'>
      <Container className={styles.content} >
        <Col className='mb-4'>
          { message() }
        </Col>
        <Col className="d-flex justify-content-center mt-4 mb-1">
          <Button onClick={() => closeModal()} type='button' className={`${styles.button} ${styles['button-bg-light']} mr-4`}>
            Cancel
          </Button>
          {
            status === 'inactive' 
              && <Button 
                  type='button' 
                  onClick={() => {toggleStatus({status: 'active'}); closeModal()}} 
                  className={`${styles.button} ${styles['button-bg-primary']}`}
                  disabled={isSaving}
                >
                  Activate
                </Button>
          }
          {
            status === 'active' 
              && <Button 
                  type='button' 
                  onClick={() => {toggleStatus({status: 'inactive'}); closeModal()}} 
                  className={`${styles.button} ${styles['button-bg-danger']}`} 
                  disabled={isSaving}
                >
                  Deactivate
                </Button>
          }
        </Col>
      </Container>
    </Container>
  )
}

export default ActivateDeactivate;
