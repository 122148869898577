const SELECT_FIELD = 'select';
const MULTISELECT_FIELD = 'multiselect';
const FILE_FIELD = 'file';
const MULTIPLE_FILE_FIELDS = 'files';
const PASSWORD_FIELD = 'password';
const DATE_FIELD = 'date';
const TEXT_LINK_FIELD = 'text_link';
const TOGGLER_FIELD = 'toggle_link';
const INCREASER_FIELD = 'increaser';
const LABEL_TEXT_FIELD = 'label_text';
const GOOGLE_MAPS_FIELD = 'google_maps';
const NUMBER_FIELD = 'number';
const TEXT_AREA = 'text_area';
const CHECKBOX_FIELD = 'checkbox';
const HOUR_FIELD = 'hour';
const SINGLE_HOUR_FIELD = 'single_hour';
const SINGLE_DATE_FIELD = 'date'
const SWITCH_FIELD = 'switch';
const RADIO_BUTTON_FIELD = 'radio_buttons';
const DROPDOWN_SELECT_FIELD = 'dropdown_select';
const SUBSCRIBER_DATE_FIELD = 'subscriber_date'
const DROPDOWN_SEARCH_SELECT_FIELD = 'dropdown_search_select'
const PHONE_NUMBER_FIELD = 'phone_input'
const MULTIPLE_DATE_FIELD = 'multiple_date'

export {
  SELECT_FIELD,
  MULTISELECT_FIELD,
  FILE_FIELD,
  MULTIPLE_FILE_FIELDS,
  PASSWORD_FIELD,
  TEXT_LINK_FIELD,
  TOGGLER_FIELD,
  INCREASER_FIELD,
  DATE_FIELD,
  LABEL_TEXT_FIELD,
  NUMBER_FIELD,
  GOOGLE_MAPS_FIELD,
  TEXT_AREA,
  CHECKBOX_FIELD,
  HOUR_FIELD,
  SINGLE_HOUR_FIELD,
  SINGLE_DATE_FIELD,
  SWITCH_FIELD,
  RADIO_BUTTON_FIELD,
  DROPDOWN_SELECT_FIELD,
  SUBSCRIBER_DATE_FIELD,
  DROPDOWN_SEARCH_SELECT_FIELD,
  PHONE_NUMBER_FIELD,
  MULTIPLE_DATE_FIELD
};
