import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import Update from '../update';
import Show from '../show';
import Delete from '../delete';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/* Actions */
import { SET_LIST_ELEMENT } from 'actions/parking_citation_tickets';
/* API */
import { update, updateSetting, deleteDocument } from 'api/parking/citation_tickets';
import { create as createDispute } from 'api/disputes';
import { updateSetting as updatePermitSetting } from 'api/permit_applications';
import { create } from 'api/dispute_types';
import { map, pluck} from 'underscore';
import EditFine from '../edit_fine';
import SettingConfirmation from '../setting_confirmation';
import PermitSettingConfirmation from '../permit_setting_confirmation';
import Appeal from '../appeal_citation';

const initialValues = {
  isSaving: false,
  errors: {},
  disputeTypes: [],
}

const validDisputeTypes = [
  "other",
  "time",
  "not me",
  "fine already paid",
  "billing"
]

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'disputeTypes':
      return { ...state, disputeTypes: payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

export const TicketModal = (props) => {
  const [document, setDocument] = useState()
  const [errors, setErrors] = useState({})
  const [values, dispatch] = useReducer(reducer, initialValues)
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const dispatchAction = useDispatch();
  
  const state = useMemo(() => values,[values])
  
  const setState = useCallback((type, payload) => {
    dispatch({ type , payload })
  },[dispatch])

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const { modalType, openModal, record, startFetching, createCitation, setCreateCitation, setting, restrictApplication, setRestrictApplication, allowApplication, setAllowApplication } = props

  const closeModal = useCallback(() => {
    openModal()
    setErrors({})
    setState('errors', {})
    setState('innerModal', null)
  }, [openModal, setState])

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null)}}
    />
  },[setState]);

  const permitSettingData = useMemo(() => {
    if (setting === 'restrict_setting'){
      return {restrict_application_with_open_citation: restrictApplication ? 'false' : 'true'};
    }else{
      return {allow_to_permit_application_and_mark_under_review: allowApplication ? 'false' : 'true'};
    }
  }, [setting, restrictApplication, allowApplication]);

  const addDisputeType = useCallback(async (data) => {
    if(!validDisputeTypes.includes(data[0]?.toLowerCase())) {
      setState('errors', {name: ['Name is not a valid type']})
      return
    }
    setState('isSaving', true)
    try {
      const response  = await create({data: {dispute_type: {names: pluck(state.disputeTypes, 'label').concat(data)}} })
      setState('disputeTypes', map(response.data, type => ({ label: type.name, value: type.id }) ))
    } catch (_error) {
    }finally {
      setState('isSaving', false)
    }
  },[setState, state.disputeTypes])

  const updateStatus = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({id: record.id, data})
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text: `Changes on Citation Ticket ${record.id} were successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
        setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, closeModal, setListElement, record, addAlertMessages])

  const updateCitationSetting = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await updateSetting({citation_setting: {citation_ticket_creation: createCitation ? 'false' : 'true'}})
      if(response.status === 200){
        setCreateCitation(response?.data?.citation_ticket_creation)
        addAlertMessages([{
          type: 'primary',
          text: `Changes on Citation Ticket creation are successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
      addAlertMessages([{
        type: 'danger',
        text: error.response.data.errors.status,
        onlyMessage: true,
      }], 'center')
    } finally {
      closeModal()
      setState('isSaving', false)
    }
  },[setState, closeModal, addAlertMessages, setCreateCitation, createCitation])
  
  const deleteFile = useCallback(async () => {

    try {
      await deleteDocument({documentId: document.id, id: record.id })
      setState('innerModal', null)
      closeModal()
      openModal('show')
    } catch (error) {
      console.log(error)
    }finally {
    }
  },[record.id, openModal, closeModal, document, setState])

  const updateFine = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({id: record.id, data})
      if(response.status === 200){
        setState('innerModal', null)
        setListElement(response.data)
        closeModal()
        openModal('show')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, closeModal, setListElement, record, openModal])

  const appealCitation = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await createDispute({data: {dispute: data}})
      if(response.status === 200 || response.status === 201){
        setState('innerModal', null)
        setListElement(response.data)
        closeModal()
        openModal('show')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, closeModal, setListElement, record, openModal])
  
  const updatePermitSettings = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await updatePermitSetting(permitSettingData)
      if(response.status === 200){
        setRestrictApplication(response?.data?.restrict_application_with_open_citation)
        setAllowApplication(response?.data?.allow_to_permit_application_and_mark_under_review)
        addAlertMessages([{
          type: 'primary',
          text: `Changes on Citation Ticket creation are successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
      addAlertMessages([{
        type: 'danger',
        text: error.response.data.errors.status,
        onlyMessage: true,
      }], 'center')
    } finally {
      closeModal()
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, closeModal, addAlertMessages, setRestrictApplication, setAllowApplication, setting, permitSettingData])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'show':
        return { width: '680px' };
      case 'setting_confirmation':
        return { width: '400px' };
      default:
        return { width };
    }
  },[modalType])

  const innerModalProps = useCallback(() => {
    const width = '480px'
    switch (state.innerModal) {
      case 'editFine':
        return { width: '500px' };
      case 'appeal':
        return { width: '580px' };
    default:
      return { width };
    }
  },[state.innerModal])
  
  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.citationModal} 
      style={{maxWidth: modalProps().width, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'disputeTypes' && 
          <Update 
            addDisputeType={addDisputeType}
            closeModal={closeModal}
            setState={setState}
            {...state}
          /> }

        { modalType === 'show' && 
          <Show 
            record={record}
            closeModal={closeModal}
            modalType={modalType}
            startFetching={startFetching}
            openModal={openModal}
            openInnerModal={() => setState('innerModal', 'delete')}
            openFineInnerModal={() => setState('innerModal', 'editFine')}
            openAppealInnerModal={() => setState('innerModal', 'appeal')}
            updateStatus={updateStatus}
            errors={state.errors}
            setState={setState}
            setDocument={setDocument}
            {...state}
          /> }
        { modalType === 'setting_confirmation' && 
          <SettingConfirmation 
            closeModal={closeModal}
            updateCitationSetting={updateCitationSetting}
            createCitation={createCitation}
          /> }
        { modalType === 'permit_setting_confirmation' && 
          <PermitSettingConfirmation 
            updatePermitSettings={updatePermitSettings}
            closeModal={closeModal}
            setting={setting}
            restrictApplication={restrictApplication}
            allowApplication={allowApplication}
            setRestrictApplication={setRestrictApplication}
            setAllowApplication={setAllowApplication}
          /> }
      </ModalBody>
      {!!state.innerModal &&
        <Modal
          isOpen={!!state.innerModal}
          centered
          style={{maxWidth: innerModalProps().width, width: '100%'}}
        >
          { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
          <ModalBody>
            {state.innerModal === 'delete' && <p className={`${styles.modalTitle} m-0 p-0 `}>Delete Document</p>}
            { state.innerModal === 'delete' &&  
              <Delete 
                record={record}
                closeModals={() => {setState('innerModal', null)}}
                startFetching={startFetching}
                openModal={openModal}
                deleteFile={deleteFile}
                {...state}
              />
            }
            { state.innerModal === 'editFine' &&  
              <EditFine 
                setErrors={setErrors}
                errors={errors}
                record={record}
                closeModals={() => {setState('innerModal', null)}}
                startFetching={startFetching}
                openModal={openModal}
                deleteFile={deleteFile}
                updateFine={updateFine}
              />
            }
            { state.innerModal === 'appeal' &&  
              <Appeal 
                {...props}
                setErrors={setErrors}
                errors={errors}
                record={record}
                closeModals={() => {setState('innerModal', null)}}
                startFetching={startFetching}
                openModal={openModal}
                appealCitation={appealCitation}
              />
            }
          </ModalBody>
        </Modal>
      }
    </Modal>
  )
}

const  mapState = (state) => {
  return {
    record: state.parking_citation_ticket.records.record
  }
}

export default connect(mapState, null)(TicketModal);
