import React, { useContext, useRef, useState } from 'react'
import { Col, Container, Label, Row } from 'reactstrap';
import Button from 'components/base/button';
import { flatten, isEmpty, map, values } from 'underscore';
import { importAdmin } from 'api/admins';
import Loader from 'components/helpers/loader';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import styles from './index.module.sass'
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import env from '.env'
import { ReactComponent as DownloadIcon } from 'assets/download-minimalistic-svgrepo-com.svg';

const adminAttrs = [
  { name: 'Email',
    altTextArray: [
      { no: 'i', text: 'Email must be unique'},
      { no: 'ii', text: 'Email should be in valid format. e.g. username@domain.com'},
    ]
  },
  { name: 'Password',
    altTextArray: [
      { no: 'i', text: 'Password length must be between 7 to 50'},
      { no: 'ii', text: 'Password must contain atleast on uppercase letter and a special character'},
    ]
  },
  { name: 'Name'},
  { name: 'User Name',
    altTextArray: [
      { no: 'i', text: 'User Name length must be between 7 to 20'},
      {no: 'ii', text: 'User Name must be unique'},
    ]
  },
  { name: 'Phone'},
  { name: 'Role Type', altTextArray: [
      { no: 'i', text: 'If Role Type is blank then by default it is assigned as super_admin'},
    ]
  },
]

const Import = (props) => {
  const { closeModal } = props
  const [fileName, setFileName] = useState();
  const [isSaving, setIsSaving] = useState(false)
  const fileRef = useRef()
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const importData = async (e) => {
    e.preventDefault()
    e.stopPropagation();
    const formData = new FormData()
    formData.append('file', fileRef.current.files[0])
    try {
      setIsSaving(true)
      await importAdmin(formData);
      addAlertMessages([{
        type: 'primary',
        text:  `${capitalize(fileName.split('.')[0])} CSV file was successfully imported`,
        onlyMessage: true,
      }],
      'center'
      )
      window.location.reload();
    } catch (error) {
      addAlertMessages(
        map(flatten(values(error.response?.data?.errors)), (message) => {
          return (
            {
              type: 'danger',
              text:  message,
              onlyMessage: true,
            }
          )
        }),
        'center'
      )
    } finally {
      setIsSaving(false)
      closeModal()
    }
  }
  
  const showFileName = (e) => {
    setFileName(e.target.files[0]?.name)
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileName(e.dataTransfer.files[0].name)
      fileRef.current = { files: e.dataTransfer.files }
    }
  };

  const exportFile = (file) => {
    const url = `${env.backend_url}/dashboard/admins/download_sample_format?type=${file}`
    window.open(url, '_blank');
    window.focus();
  }

  return (
    <Container>
      <p className={`${styles.title} m-0 p-0`}>Import User Data</p>
      { 
        isSaving 
          ? <Loader />
          : (
            <Col className={`${styles.body} text-left`}>
              <Col className='my-4 p-0'>
                <span className='d-block'>
                  Please upload user data in CSV or Excel file. 
                  The file should contain the following 
                  columns in the same order as listed below.
                </span>
              </Col>
              <Row className='my-4 mx-0 p-0'>
                {adminAttrs.map((attr, idx) => (
                  <React.Fragment key={attr.name}>
                    <p className='p-0 d-flex align-items-center w-100 mb-1'>
                      <span className={`${styles.numberLabel} mr-2`}>
                        {`${idx + 1}.`}
                      </span>
                      <span className={styles.boldText}>
                        {attr.name}
                      </span>
                      { attr.altText && <span className='ml-1' dangerouslySetInnerHTML={{ __html: attr.altText}}></span>}
                    </p>
                    { attr.altTextArray && 
                        <p className='mb-1 p-0 d-flex flex-column'>
                          {map(attr.altTextArray, (value) => {
                            return <span key={value.no} className='d-flex' style={{marginLeft: 30}}>
                              <span className={`mr-2 ${!!value.no} && 'hidden'`}>{`${value.no}`}</span>
                              <span dangerouslySetInnerHTML={{ __html: value.text}}></span>
                            </span>
                          })}
                        </p>
                    }
                  </React.Fragment>
                ))}
              </Row>
              <span className='d-block'>
                Below you will find a selection of sample files for you to download
              </span>
              <Col className="d-flex justify-content-center mb-3 mt-3 p-0">
                <Button 
                  type='button' 
                  className={styles.downloadButton}
                  onClick={() => exportFile('csv')}
                >
                  <DownloadIcon className={styles.downloadIcon}/> <span className={styles.download}>CSV</span>
                </Button>
                <Button 
                  type='button' 
                  className={styles.downloadButton}
                  onClick={() => exportFile('xlsx')}
                >
                  <DownloadIcon className={styles.downloadIcon}/> <span className={styles.download}>EXCEL</span>
                </Button>
              </Col>
              <form onSubmit={importData}>
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                  <input ref={fileRef} className='d-none' id='csv-file-upload' type='file' accept='.csv' onChange={showFileName} />
                  <Label htmlFor='csv-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-100 w-100 text-center p-3`}>
                    <span className='mb-2'>
                      <CloudDownloadIcon />
                    </span>
                    { fileName && <span>{fileName}</span> }
                    <span>
                      <span className={styles.boldText}>
                        Choose a CSV file
                      </span>
                      <span className='ml-1'>
                        or drag it here
                      </span>
                    </span>
                  </Label>
                </div>
                <Col className="d-flex justify-content-center mb-3 mt-5 p-0">
                  <Button 
                    type='button' 
                    className={`${styles.greyButton} mr-4`}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isEmpty(fileName)} type='submit' className={styles.primaryButton}>
                    save
                  </Button>
                </Col>
              </form>
            </Col>
          )
      }
    </Container>
  )
}

export default Import;
