import React from "react";
import PropTypes from "prop-types";
/* API */
import { usersEnrolledFilterFetcher } from "api/reward_types";
/* Styles/Assets */
import styles from "./index.module.sass";
/* Base */
import { IndexTable } from "components/base/table";
import TableRow from "./table_row";

const TableData = (props) => {
  const {
    isResourceFetching,
    setRecord,
    list,
    openModal,
    activeTab,
    modalType,
    filters,
  } = props;

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id}
          record={record}
          openModal={openModal}
          setRecord={setRecord}
          modalType={modalType}
        />
      );
    });
  };

  return (
    <IndexTable
      {...props}
      className={`${
        activeTab === "filters" ? styles.tableActive : styles.table
      }`}
      isFetching={() => isResourceFetching}
      filterFetcher={usersEnrolledFilterFetcher}
      resource="user_enrolled"
      paginationQuery={{ ...filters }}
      columns={
        <React.Fragment>
          <th attr="user" style={{ width: "25%", minWidth: "210px" }}>
            Users Enrolled
          </th>
          <th attr="points_earned" style={{ width: "25%", minWidth: "210px" }}>
            Points Earned
          </th>
          <th
            attr="points_redeemed"
            style={{ width: "22%", minWidth: "210px" }}
          >
            Points Redeemed
          </th>
          <th
            attr="points_in_dollar"
            style={{ width: "25%", minWidth: "210px" }}
          >
            Points Redeemed in $
          </th>
          <th
            attr="last_redemption_date"
            style={{ width: "40%", minWidth: "210px" }}
          >
            Last Redemption Date
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="Users Enrolled"
    />
  );
};

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
};

export default TableData;