import React, { useState } from 'react';
import { asField } from 'informed';
import { Col, Row, Label} from 'reactstrap';
import styles from './hour_input.module.sass';

const HourRangeInput = asField(({ fieldState, fieldApi, events = {}, ...props }) => {
  const { setValue } = fieldApi;
  const { initialValues, field, fieldProps, errors } = props;

  const setInitialValues = (initialValues, field_name) => {
    if(!initialValues || !initialValues.from || !initialValues.to) {
      return ['', '']
    }
    let from = '', to = ''
  
    if(initialValues.from) {
      from = initialValues.from
    }
  
    if(initialValues.to) {
      to = initialValues.to
    }
    return [from, to]
  }

  const [initialStartTime, initialEndTime] = setInitialValues(initialValues, field)
  const [ startTime, setStartTime ] = useState(initialStartTime);
  const [ endTime, setEndTime ] = useState(initialEndTime);

  const handleStartChange = (e) => {
    setValue({
      from: e.target.value,
      to: endTime
    })
    setStartTime(e.target.value);
    setEndTime(endTime)
    events.onChange && events.onChange();
  }

  const handleEndChange = (e) => {
      setValue({
        from: startTime,
        to: e.target.value
      })
      setEndTime(e.target.value);
      setStartTime(startTime)
      events.onChange && events.onChange();
  }

  return (
    <Row className={`${fieldProps?.noGutters}`}>
      <Col sm={12} md={6} className={`${fieldProps?.wrapperClass || ''}`}>
        <Label xs={fieldProps?.lSize || 12} className={`${fieldProps?.customLabelClass || 'mr-1'}`}>
          { fieldProps?.labelNames?.to || 'From:'}
          <span className={`mr-1 ${errors?.from || errors.parking_hours ? 'general-error' : 'text-primary'}`}>{fieldProps?.mandatory ? '*' : ''}</span>
        </Label>
        <input
          className={`${styles.time} ${fieldProps?.customInputClass || ''}`} 
          onChange={handleStartChange}
          value={startTime}
          type='time'
        />
      </Col>



      <Col sm={12} md={6} className={`${fieldProps?.wrapperClass || ''}`}>
        <Label xs={fieldProps?.lSize || 12} className={`${fieldProps?.customLabelClass || 'mr-1'}`}>
          { fieldProps?.labelNames?.to || 'To:'}
          <span className={`${errors?.from ? 'general-error' : 'text-primary'}`}>{fieldProps?.mandatory ? '*' : ''}</span>
        </Label>
        <input
          className={`${styles.time} ${fieldProps?.customInputClass || ''}`} 
          onChange={handleEndChange}
          value={endTime}
          type='time'
        />
      </Col>
    </Row>
  );
});

export default HourRangeInput;