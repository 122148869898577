import React, { useCallback, useContext, useReducer, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { create, pdfPreview, boot, remove_boot } from 'api/booting_and_towings';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Configure from '../configure';
import Preview from '../preview';
import { isEmpty } from 'underscore';
import Boot from '../boot_vehicle';
import RemoveBoot from '../remove_boot';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const BootingAndTowingModal = (props) => {
  const { openModal, modalType, setActiveTab, startFetching, selectedRecord, passBase64, setPassBase64, fileUrl, setFileUrl, selectedParkingLot, popListElement } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    openModal()
    setPassBase64('')
    setFileUrl('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setActiveTab, setPassBase64, setFileUrl])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const createConfigurations = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: data})
      if(response.status === 200 || response.status === 201 )
      {
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Changes on Booting and Towing configurations were successfully saved',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addAlertMessages, closeModal])

  const bootVehicle = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await boot({data: {vehicle_id: selectedRecord?.id, parking_lot_id: selectedParkingLot }})
      if(response.status === 200 || response.status === 201 )
      {
        popListElement({id: selectedRecord.id})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Vehicle marked as booted',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addAlertMessages, closeModal, selectedParkingLot, selectedRecord, popListElement])

  const removeBoot = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await remove_boot({vehicle_id: selectedRecord?.id})
      if(response.status === 200 || response.status === 201 )
      {
        popListElement({id: selectedRecord.id})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Boot removed',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
      addAlertMessages([{
        type: 'danger',
        text: 'Sorry. Citation tickets not settled.',
        onlyMessage: true
        }],
        'center'
      )
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setErrors, addAlertMessages, closeModal, selectedParkingLot, selectedRecord, popListElement])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'configuration':
        return {maxWidth : '750px'}
      case 'preview':
        return {maxWidth : '490px'}
      case 'boot':
        return {maxWidth : '400px'}
      case 'removeBoot':
        return {maxWidth : '400px'}
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);
  
  useEffect(() => {
    const previewPass = async () => {
      setState('isSaving', true)
      try {
        const response = await pdfPreview({vehicle_id: selectedRecord?.id})
        setPassBase64(response?.data?.pdf_data)
      } catch (error) {
        setErrors(error.response?.data?.errors)
      } finally{
        setState('isSaving', false)
      }
    }
    if(!isEmpty(selectedRecord) && modalType==='preview') previewPass()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setState, passBase64, setPassBase64, selectedRecord, modalType])

  return (
    <Modal 
      isOpen={!!modalType}  
      centered
      contentClassName={styles.vehicleModal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'configuration' && 
          <Configure
            {...props}
            state={state}
            setState={setState}
            closeModal={closeModal}
            createConfigurations={createConfigurations}
            setErrors={setErrors}
            errors={errors}
            startFetching={startFetching}
            selectedParkingLot={selectedParkingLot}
          />
        }
        {
          modalType === 'preview' && 
          <Preview
            {...props}
            passBase64={passBase64}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
          />
        }
        {
          modalType === 'boot' && 
          <Boot
            {...props}
            state={state}
            setState={setState}
            closeModal={closeModal}
            selectedRecord={selectedRecord}
            bootVehicle={bootVehicle}
          />
        }
        {
          modalType === 'removeBoot' && 
          <RemoveBoot
            {...props}
            state={state}
            setState={setState}
            closeModal={closeModal}
            selectedRecord={selectedRecord}
            removeBoot={removeBoot}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default BootingAndTowingModal;
