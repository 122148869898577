import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Container } from 'reactstrap'
import { Form } from 'informed'
/* Api */
import { show } from 'api/permit_types';
/* Base */
import Button from 'components/base/button'
import { renderField, renderFields } from 'components/base/forms/common_form'
/* Helper */
import { extendFields } from 'components/helpers/fields/permits/show'
import Loader from 'components/helpers/loader'
/* Styles/Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, isUndefined, map } from 'underscore'
import moment from 'moment';

const fieldAttrs = {
  customInputClass: styles.input,
  customLabelClass: styles.inputLabel,
  className: 'mb-0'
}

const fieldProps = {
  iSize: 9,
  lSize: 3
}

const dollarIcon = (color) => <FontAwesomeIcon icon={faDollarSign} className={`${styles[`${color}-color`]} ${styles.dollarIcon}`} />

const permitCost = (record={}, period) => {
  const {parking_hour_from, parking_hour_to, hourly_rate, expiry_date} = record
  if(isUndefined(period)) return ''
  if(record.payment_type === 'free') return 'Free'
  let [number, option] = period.split(' ')
  if(isUndefined(number) || isUndefined(option)) return 'N/A'
  option = option.endsWith('s') ? option : option.concat('s')

  const days = Math.ceil((moment(new Date(expiry_date)).add(option, number) - new Date(expiry_date))/ (1000*60*60*24))
  const dailyParkingMinutes = (new Date(`2023-06-23 ${parking_hour_to}`) - new Date(`2023-06-23 ${parking_hour_from}`))/ (1000*60)

  return (hourly_rate * days * Math.floor(dailyParkingMinutes/60)) + (hourly_rate * (dailyParkingMinutes % 60))

}

const Extend = ({extendIssuedPermit, closeModals, record, isSaving, errors, setState}) => {
  const formApiRef = useRef();
  const [isFetching, setIsFetching] = useState(true)
  const [permitType, setPermitType] = useState([])

  const setFormApi = formApi => { formApiRef.current = formApi }
  
  const permitTypeData = useMemo(() => {
    return {
      ...permitType, 
      expiry_date: record.expiry_date, 
      cost: permitType.payment_type === 'free' ? 'Free' : permitCost({...permitType, expiry_date: record.expiry_date}),
      hourly_rate: permitType.payment_type === 'free' ? 'Zero' :permitType.hourly_rate,
      period: []
    }
  }, [record.expiry_date, permitType])
  
  const fields = useMemo(() => {
    return extendFields(Object.assign(fieldAttrs, {validity: permitType.validity}))
  }, [permitType.validity])

  const setFormValues = useCallback((period) => {
    let value = ''
    if(!period?.value) {
      value = 'N/A'
    }else {
      value = period.value
    }
    let [number, option] = period.value?.split(' ')
    formApiRef.current.setValues({
      ...formApiRef.current.getValues(),
      'cost': permitCost({...permitType, expiry_date: record.expiry_date}, value),
      expiry_date: moment(new Date(record.expiry_date)).add(number, option.endsWith('s') ? option : option.concat('s'))
    })
  }, [permitType, record.expiry_date])

  const extendPermit = useCallback((values) => {
    if(isEmpty(values.period)) { 
      setState('errors', {period: ['Period can\'t be blank']})
      return 
    }
    extendIssuedPermit({permit_type_period: values.period})
  },[setState, extendIssuedPermit])

  useEffect(() => {
    setState('errors', {})
    const fetchPermitType = async () => {
      try {
        const res = await show({id: record.permit_type_id})
        setPermitType(res.data)
      } catch (error) {
      } finally{
        setIsFetching(false)
      }
    }
    fetchPermitType()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0 my-3'>
      <p className={`${styles.title} mx-0 mb-2 p-0 text-center`}>Extend Permit Validity</p>
      <p className={`${styles.category} text-capitalize text-center mx-0 mb-3 p-0`}>
        {`${record.permit_category} Permit`}
      </p>
  
      <Form 
        initialValues={permitTypeData} 
        className={styles.form}
        getApi={setFormApi}
        onSubmit={extendPermit}
      >
        {map(fields.slice(0,2), field => {
          let inputProps = {}
          if(field.name === 'period') {
            inputProps = { events: {onChange: setFormValues} }
          }
          return (
            <React.Fragment key={field.name}>
              {renderField(field, {...fieldProps, ...inputProps, errors})}
            </React.Fragment>
          )
        })}
        <Col xs={12} className='p-0 d-flex'>
          <div className='col-3 d-flex align-items-center p-0 mb-3'>
            <span
              className={`${styles.inputLabel} general-text-2 `}
            >
              Parking Hours
            </span>
          </div>
          <div className='col-9 d-flex align-items-center flex-nowrap p-0 position-relative'>
            <div className='mr-4'>
              { renderFields(fields.slice(2,3), {})}
            </div>
            <span style={{width: 10, right: '48%', top: 20 }} className='border position-absolute'></span>
            <div>
              { renderFields(fields.slice(3,4), {})}
            </div>
          </div>
        </Col>
        {map(fields.slice(4), field => {
          if(field.name === 'hourly_rate') {
            field = {
              ...field, 
              icon: dollarIcon('primary'), 
              customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
            }
          }
          if(field.name === 'cost') {
            field = {
              ...field, 
              icon: dollarIcon('revoked'), 
              customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
            }
          }
          return (
            <React.Fragment key={field.name}>
              {renderField(field, fieldProps)}
            </React.Fragment>
          )
        })}
        <Col className='d-flex justify-content-center align-items-center mt-4 mb-2'>
          <Button
            onClick={closeModals}
            className={`${styles.button} ${styles['button-sm']} ${styles['button-bg-secondary']}`}
            type='button'
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button 
            className={`${styles.button} ${styles['button-sm']} ${styles['button-bg-orange']} ml-4`}
            disabled={isSaving}
            type='submit'
          >
            Extend
          </Button>
        </Col>
      </Form>
    </Container>
  )
}

export default Extend