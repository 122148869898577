import React from 'react'
/* Base */
import NavigationToolbar from 'components/base/subscriberNavigationBar';
/* Components */
import { Col } from 'reactstrap';
import styles from './index.module.sass'
import SlideButton from 'components/base/slide_button';
import { ReactComponent as FileIcon } from 'assets/file-icon.svg'
import { ReactComponent as ClockIcon } from 'assets/file-clock-icon.svg'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg'
import { useHistory } from 'react-router-dom';

const ToolBar = (props) => {
  const { listType, openModal, total, t } = props
  const history = useHistory();
  return (
    <NavigationToolbar
      className='d-flex justify-content-space-between'
      style={{fontFamily: 'Roboto, sans-serif'}}
    >
    <Col className={styles.headerCol}>
        <span className={styles.title}>{listType==="issued_permits"? `${t("subscriber.issued_permits")} (${total})` : listType==="permit_applications" ? `${t("subscriber.my_permit_applications_title")} (${total})` : listType==="waitlist" ? `Waiting Lists (${total})` : ""}</span>
    </Col>
    <Col className='d-flex justify-content-end pr-3'>
        <SlideButton
        isActive={listType==='issued_permits'}
          icon={<FileIcon className={styles.toolbarIcons} />}
          onClick={() => history.push("/subscriber/permits")}
          className={styles.toolBarBtns}
        >
          <span className='mr-3 d-inline-block text-decoration-none text-white'>{t("subscriber.issued_permits")}</span>
        </SlideButton>
        <SlideButton
          isActive={listType==='permit_applications'}
          icon={<EyeIcon className={styles.toolbarIcons} />}
          onClick={() => history.push("/subscriber/permit_applications")}
          className={styles.toolBarBtns}
        >
          <span className='mr-3 d-inline-block text-decoration-none text-white'>{t("subscriber.my_permit_applications_title")}</span>
        </SlideButton>
        <SlideButton
          isActive={listType==='waitlist'}
          className={styles.toolBarBtns}
          icon={<div className="m-0 p-0 btn position-relative">
          <FileIcon className={styles.toolbarIcons}/>
          <ClockIcon className={styles.toolbarIcons} style={{ position: 'absolute', top: '50%', left: '10px' }}/>
          </div>}
        >        
          <span className="ml-2 mr-3 text-white">{t("subscriber.waitlist")}</span>
        </SlideButton>
        <SlideButton
          isActive={true}
          className={styles.applyBtn}
          icon={<PenToSquareSvg className={styles.toolbarIcons}/>}
          onClick={() => {
            openModal('apply_permit')
          }}
        >
          <span className='mr-3 d-inline-block' style={{fontWeight: "bolder"}}>{t("subscriber.apply_permit")}</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;