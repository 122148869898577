import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";

const { index , create} = resourceApi("monitor_vehicles");
const search = searchApi("monitor_vehicles");


const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params;
  
  let from, to;

  if (filters.range) {
    from = filters.range.from;
    to = filters.range.to;
  }

  return index({
    page,
    perPage,
    query: {
      ...query,
      range: {
        from,
        to
      },
      ...filters,
    },
  });
};



export { filterFetcher, index, search, create};
