import { UserRewardsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(UserRewardsActions);

const UserRewardsReducers = combineReducers({
  index,
  records,
  filters
});

export default UserRewardsReducers;