import React from 'react';
import PropTypes from 'prop-types';
import Index from 'components/pages/system_logs/index';
import { Route, withRouter, Switch } from 'react-router';
import Show from 'components/pages/system_logs/audit_logs/index/index';
import renderWithBackPath from 'components/modules/render_with_back_path';

const Routing = ({ match }) => (
  <React.Fragment>
    <Route exact path={match.path} component={Index} />
      <Switch>
        <Route path={`${match.path}/:module_name`} render={(props) => (
          <React.Fragment>
            <Route exact path={`${props.match.path}`} component={renderWithBackPath(Show, `${match.url}/`)} />
          </React.Fragment>
        )} />
      </Switch>
  </React.Fragment>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);