import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input, Spinner } from 'reactstrap';

const momentUnix = timestamp => {
  return moment.unix(timestamp);
};

const displayUnixTimestamp = (timestamp, format = 'ddd, MMM Do YYYY, h:mm:ss a' ) => {
  return momentUnix(timestamp).format(format);
};

const displayUnixTimestampWithOffset = (timestamp, offset) => {
  const format = 'ddd, MMM Do YYYY, h:mm:ss a';

  if (offset) {
    return momentUnix(timestamp).utcOffset(offset).format(format);
  }

  return momentUnix(timestamp).format(format);
};

const displayDate = (date, format='MM/DD/YYYY') => {
  if (!date) {
    return '';
  }
  if(format === 'yyyy-mm-dd') {
    const offset = date.getTimezoneOffset()
    const newDate = new Date(date.getTime() - (offset*60*1000))
    return newDate.toISOString().split('T')[0];
  }
  return moment(date).format(format);
};

const displayDateRange = (from, to) => {
  const fromDate = displayDate(from);
  const toDate = displayDate(to);
  if (fromDate === toDate) {
    return fromDate;
  }
  const fromYear = moment(from).year();
  const toYear = moment(to).year();
  if (fromYear === toYear) {
    return `${moment(from).format('MM/DD')} - ${toDate}`;
  }
  return `${fromDate} - ${toDate}`;
};

const displayMonthAndDay = (date) => {
  if (!date) {
    return '';
  }
  return moment(date).format('MMM DD');
};

const dateDiffInDays = (date1, date2) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

const unixDatePicker = (timestamp, inputProps, pickerProps, readOnly = false) => {
  return (
    <DatePicker readOnly={readOnly} maxDate={moment().toDate()} selected={momentUnix(timestamp).toDate()} customInput={<Input {...inputProps}/>} {...pickerProps}/>
  )
};

const dateToUnix = date => {
  return date.getTime() / 1000;
};

const btnSpinner = (props = {}) => {
  return (
    <span>
        Loading...
        <Spinner {...props} size="sm" color="default"/>
      </span>
  )
};

const camelize = (text, separator = '_') => {
  if(!text) { return ''}
  return (
    text.split(separator)
      .map(w => w.replace(/./, m => m.toUpperCase()))
      .join(' ')
  );
}

const capitalize = (text) => {
  if(!text) { return ''}
  return (
    text.replace(/./, m => m.toUpperCase())
  )
} 

const dateFormatter = (dateStr) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const formatDate = (dateStr) => {
    const [datePart, timePart, period] = dateStr.split(' ');
    const [month, day, year] = datePart.split('/').map(Number);
    let [hours, minutes] = timePart.split(':').map(Number);

    // Convert 12-hour time to 24-hour time
    if (period === 'AM' && hours === 12) {
      hours = 0;
    } else if (period === 'PM' && hours !== 12) {
      hours += 12;
    }

    const date = new Date(year, month - 1, day, hours, minutes);
    const dayOfWeek = days[date.getDay()];
    const monthName = months[month - 1];
    const dayOfMonth = date.getDate();
    const yearFormatted = date.getFullYear();
    const hoursFormatted = String(hours % 12 || 12).padStart(2, '0');
    const minutesFormatted = String(minutes).padStart(2, '0');
    const secondsFormatted = '00';
    const periodFormatted = period;

    return `${dayOfWeek}, ${monthName} ${dayOfMonth}th ${yearFormatted}, ${hoursFormatted}:${minutesFormatted}:${secondsFormatted} ${periodFormatted}`;
  };

  const formattedDate = formatDate(dateStr);

  return (
      formattedDate
  );
};


export {
  momentUnix,
  camelize,
  capitalize,
  displayUnixTimestamp,
  displayDate,
  displayDateRange,
  displayMonthAndDay,
  unixDatePicker,
  dateToUnix,
  btnSpinner,
  displayUnixTimestampWithOffset,
  dateDiffInDays,
  dateFormatter
};
