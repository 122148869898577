import { FieldType } from "components/helpers/form_fields";

const filterFields = (customLabelClass = "", customInputClass = "",customDropdownClass, t, customIconClass) => [
  {
    name: "search",
    label: `${t("events.city_events.event_name")}:`,
    placeholder: t("events.enter_event_name"),
    customInputClass,
    customLabelClass,
  },
  {
    name: "cost_type",
    label: `${t("events.city_events.free_paid")}:`,
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: [
      { value: "free", label: "Free" },
      { value: "paid", label: "Paid" },
    ],
    placeholder: t("events.select_parking_lots"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
  {
    name: "start_date[from]",
    label: `${t("events.city_events.from_event_start_date")}`,
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("events.select_date"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "start_date[to]",
    label: `${t("events.city_events.to_event_end_date")}:`,
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("events.select_date"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
];

export default filterFields;
