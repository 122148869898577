import React, { useCallback, useContext, useMemo, useReducer } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DeleteTheme from "../delete";
import { connect } from "react-redux";
/* Assets */
import styles from "./index.module.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/* API */
import { destroy } from "api/themes";
/* Helpers */
import { AlertMessagesContext } from "components/helpers/alert_messages";
import { capitalize } from "components/helpers";

const initialValues = {
  isSaving: false,
  isDeleting: false,
  errors: {},
};

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state;
  switch (type) {
    case "isSaving":
      return { ...state, isSaving: payload, errors: payload ? {} : errors };
    case "errors":
      return { ...state, errors: payload };
    default:
      return { ...state };
  }
};

const ThemeModal = (props) => {
  const [values, dispatch] = useReducer(reducer, initialValues);

  const state = useMemo(() => values, [values]);

  const setState = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  const { addAlertMessages } = useContext(AlertMessagesContext);

  const { popListElement, modalType, openModal, record } = props;

  const closeModal = useCallback(() => {
    openModal();
  }, [openModal]);

  const deleteTheme = useCallback(async () => {
    setState("isDeleting", true);
    try {
      const response = await destroy({ id: record.id });
      popListElement({ id: record.id });
      closeModal();
      addAlertMessages(
        [
          {
            type: "danger",
            text: `${capitalize(
              response.data.theme_name
            )} Theme was succesfully deleted`,
            onlyMessage: true,
          },
        ],
        "center"
      );
    } catch (error) {
      setState("errors", error?.response?.data?.errors);
    } finally {
      setState("errors", {});
      setState("isDeleting", false);
    }
  }, [setState, popListElement, record.id, closeModal, addAlertMessages]);

  const closeBtn = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.closeIcon}
        onClick={closeModal}
      />
    );
  }, [closeModal]);

  const modalProps = useCallback(() => {
    const width = "480px";
    switch (modalType) {
      case "delete":
        return { title: "Are you sure?", Width: "490px" };
      default:
        return { width };
    }
  }, [modalType]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.themeModal}
      style={{ maxWidth: modalProps().width, width: "100%" }}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {modalType === "delete" && (
          <DeleteTheme
            record={record}
            deleteTheme={deleteTheme}
            closeModal={closeModal}
            state={state}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const mapState = (state) => {
  return {
    record: state.theme.records.record,
  };
};
export default connect(mapState, null)(ThemeModal);