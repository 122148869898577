import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { AlertMessages } from 'components/helpers/alert_messages';
import  SubscriberPermitRoute from 'routes/subscribers/permits';
import  SubscriberPermitApplicationRoute from 'routes/subscribers/permit_applications';
import  SubscriberNotificationsRoute from 'routes/subscribers/notifications';
import SubscriberCitationRoute from 'routes/subscribers/citations'
import  SubscriberVehicleRoute from 'routes/subscribers/vehicles';
import  Homepage from 'routes/subscribers/homepage';
import PrivateRoute from 'routes/private_route';
import  MyParkings from 'routes/subscribers/my_parkings';
import MyProfile from 'routes/subscribers/my_profile'
import EventParkingRoute from 'routes/subscribers/event_parking';
import  SubscriberTransactions from 'routes/subscribers/transaction_history';



function SubscriberContent(props) {
  const { match } = props;

  return (
    <div className='position-relative'>

      <AlertMessages>
      <PrivateRoute path={`${match.path}`} component={Homepage} />
        <PrivateRoute path={`${match.path}/permits`} component={SubscriberPermitRoute} />
        <PrivateRoute path={`${match.path}/permit_applications`} component={SubscriberPermitApplicationRoute} />
        <PrivateRoute path={`${match.path}/notifications`} component={SubscriberNotificationsRoute} />
        <PrivateRoute path={`${match.path}/citations`} component={SubscriberCitationRoute} />
        <PrivateRoute path={`${match.path}/vehicles`} component={SubscriberVehicleRoute} />
        <PrivateRoute path={`${match.path}/my_parkings`} component={MyParkings} />
        <PrivateRoute path={`${match.path}/my_profile`} component={MyProfile} />
        <PrivateRoute path={`${match.path}/event_parking`} component={EventParkingRoute} />
        <PrivateRoute path={`${match.path}/transaction_history`} component={SubscriberTransactions} />
      </AlertMessages>
    </div>
  );
}

SubscriberContent.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(SubscriberContent);
