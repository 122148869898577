import React, { useCallback, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { ROLES } from "config/permissions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { filterFetcher } from "api/system_logs";
import { filterLogs } from 'api/system_logs';
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
import { list as selectList } from 'selectors/list';
import {SET_FILTERS ,SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, } from "actions/system_logs";
import { invoke } from "actions";
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import ToolBar from "./toolbar";
import AuditLogModal from "../audit_log_modal";
import { isEmpty } from "underscore";

const resource = "system_logs";

const AuditLogs = (props) => {
  const { currentUser, match, filters, setList, setFilters, startFetching, location } = props;
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState();
  const moduleName = match?.params?.module_name;
  const [listType, setListType] = useState(filters?.log_type? filters?.log_type : 'error')
  const [status, setStatus] = useState('')
  const [routeName, setRouteName] = useState()
  const [logName, setLogName] = useState(location?.state?.logName)
  const [updateLogName, setUpdateLogName] = useState()
  const [currentStatus, setCurrentStatus] = useState()
  
  const route =[
    {id:"Vehicle", route: 'vehicles', name: "Vehicle Management", updateName: "Vehicle"},
    {id:"Event", route: 'event_parking_applications', name: "Event Parking", updateName: "EventParkingApplication"},
    {id:"mtas", route: 'mtas', name: "MTA Integration", updateName: "Mta"},
    {id:"Coupon", route: 'coupons', name: "Coupon", updateName: "Coupon"},
    {id:"CitationTicket", route: 'parking/citation_tickets', name: "Citation Ticket", updateName: "CitationTicket"},
    {id:"Disputes", route: 'disputes', name: "Dispute", updateName: "Dispute"},
    {id:"Violation", route: 'parking/violations', name: "Violation", updateName: "Violation"},
    {id:"ParkingLot", route: 'parking_lots', name: "Parking Lot", updateName: "ParkingLot"},
    {id:"Permit", route: 'permit_applications', name: "Permit Management", updateName: "Permit"},
    {id:"Payment", route: 'payments', name: "Payment", updateName: "Payment"},
    {id:"Admin", route: 'admins', name: "User", updateName: "Admin"},
    {id:"User", route: 'users', name: "Subscriber", updateName: "User"},
  ];

  useEffect(() => {
    if ( routeName === undefined || logName === undefined || isEmpty(updateLogName)) {
      Object.values(route).forEach(function (key) {
        if(key.id === moduleName) { 
          if(moduleName !== 'Permit' || isEmpty(routeName)) setRouteName(key.route) 
          setLogName(key.name)
          setUpdateLogName(key.updateName)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  const filterSystemLogs = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters }, {page: location?.search?.split('=')[1]})))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params])

  const filterDisputeLogs = useCallback(async (filters) => {
    startFetching(filterLogs(Object.assign({ ...match.params }, {attribute: (moduleName==='Permit' && filters?.type_of) ? filters?.type_of : routeName}, {filters: filters}, { query: filters }, {page: location?.search?.split('=')[1]})))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params, routeName, moduleName])


  const handleTabsFilter = () => {
    const initialFilters = { ...filters, log_type: listType };
    setFilters(initialFilters);

    if (listType === 'error' || listType === 'email' || listType === 'api_integration') {
      filterSystemLogs(initialFilters);
    }else if(listType === 'user_activity' && routeName !== undefined){
      filterDisputeLogs(initialFilters)
    }
  };
  
  useEffect(() => {
    if ( listType) {
      handleTabsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listType, moduleName, routeName]);

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className="mw-100 p-0">
        <Row className="m-auto">
          <Col xs={12} className="p-0">
            <ToolBar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              moduleName={moduleName}
              match={match}
              listType={listType}
              setListType={setListType}
              setStatus={setStatus}
              openModal={openModal}
              logName={logName}
              routeName={routeName}
              setRouteName={setRouteName}
              setCurrentStatus={setCurrentStatus}
              currentStatus={currentStatus}
              {...props}
            />
          </Col>
          <Col xs={12} className="p-0">
            <TableData
              {...props}
              moduleName={moduleName}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
              listType={listType}
              setListType={setListType}
              logName={logName}
              routeName={routeName}
            />
          </Col>
          <AuditLogModal
            {...props}
            openModal={openModal}
            modalType={modalType}
            setStatus={setStatus}
            status={status}
            listType={listType}
            logName={logName}
            updateLogName={updateLogName}
            setCurrentStatus={setCurrentStatus}
          />
        </Row>
      </Container>
    </AccessControl>
  );
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

const mapState = (state) => {
  return {
    filters: state.system_logs.filters,
    selectedRecord: state.system_logs.records.record,
    record: state.system_logs.records.record,
  };
};

export default withRouter(
  connectList(
    resource,
    SET_LIST,
    resourceFetcher(filterFetcher, resource),
    connect(mapState, mapDispatch)(withFetching(withCurrentUser(AuditLogs))),
    { fetchCondition: () => true }
  )
);