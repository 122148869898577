import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* API */
import { show } from 'api/permits';
/* Helpers */
import { cancelledFields, ownerFields, permitFields, revokedFields, vehicleFields } from 'components/helpers/fields/permits/show';
import { capitalize, displayDate, displayUnixTimestamp } from 'components/helpers';
import Loader from 'components/helpers/loader';
/* Base */
import { renderFields } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Styles */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { each, filter, isEmpty, map } from 'underscore';

const validDaysData = (days) => {
  const data = {}
  each(days, (day, idx) => {
    data[`day-${idx + 1}`] = day
  })
  return data
}

const fieldAttrs = {
  customInputClass: styles.input,
  customLabelClass: styles.inputLabel
}

const doubleFieldProps = {
  iSize: 7,
  lSize: 5
}

const singleFieldProps = {
  iSize: 10,
  lSize: 2
}

const refundMessage = (payment_type, refund_amount, refund_on, refund_completed) => {
  if (payment_type === 'paid' && refund_completed) {
    return `${refund_amount} refund issued on ${displayDate(refund_on, 'MMMM Do YYYY')}`
  }
  if(payment_type === 'paid' && !refund_completed){
    return 'Refund pending'
  }
}

const permitData = (data) => {
  const { cancelled_details={}, vehicle={}, revoked_details={}, owner_name='', home_address={}, work_address={}, permit_number, expiry_date, issue_date, permit_expired, permit_type, cost, status, refund_completed, payment_type, valid_days } = data
  return ({
    vehicle: {
      ...vehicle,
      plate_number: vehicle.plate_number?.toUpperCase(),
      make: capitalize(vehicle.make),
      color: capitalize(vehicle.color),
      model: capitalize(vehicle.model),
      registration_state: capitalize(vehicle.registration_state),
    },
    owner: { 
      full_name: owner_name, 
      home_address: home_address.address1,
      home_phone: home_address.phone,
      work_address: work_address.address1,
      work_phone: work_address.phone,
    },
    permit: {
      number: permit_number,
      expires_on: expiry_date,
      issued_on: issue_date,
      type: permit_type,
      expired: permit_expired ? 'YES' : 'NO',
      cost,
      payment_type,
      refund_completed,
      status,
      valid_days: map(valid_days, date => displayUnixTimestamp(new Date(date).getTime()/ 1000, 'ddd, MMM Do, YYYY'))
    },
    revoked_details: {
      ...revoked_details,
      status: capitalize(status),
      revoked_on: displayUnixTimestamp(new Date(revoked_details?.revoked_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
    },
    cancelled_details: {
      ...cancelled_details,
      status: capitalize(status),
      cancelled_on: displayUnixTimestamp(new Date(cancelled_details?.cancelled_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
      description: refundMessage(payment_type, cancelled_details?.refund_amount, cancelled_details?.refund_on, refund_completed)
     }
  })
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['primary-color']} ${styles.dollarIcon}`} />
const dotIcon = (status) => <FontAwesomeIcon icon={faCircle} className={`${styles[`${status}-color`]} ${styles.dotIcon}`} />

const renderVehicleFields = () => {
  const fields = vehicleFields(fieldAttrs)
  return(
    <Col className='d-flex flex-wrap px-0'>
      <div className="d-flex align-items-center my-3 w-100">
        <span className={styles.detailsLabel}>Vehicle Details</span>
        <span className="border border-2 flex-grow-1 ml-2"></span>
      </div>
      <div className={styles.fieldset}>
        {renderFields(fields.slice(0,3), {...doubleFieldProps})}
      </div>
      <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
        {renderFields(fields.slice(3,6), {...doubleFieldProps})}
      </div>
    </Col>
  )
}

const renderOwnerFields = () => {
  const  fields = ownerFields(fieldAttrs);
  return (
    <Col className='d-flex flex-wrap px-0'>
      <div className="d-flex align-items-center my-3 w-100">
        <span className={styles.detailsLabel}>Owner Information</span>
        <span className="border border-2 flex-grow-1 ml-2"></span>
      </div>
      <div className={styles.fieldset}>
        {renderFields(fields.slice(0,3), {...doubleFieldProps})}
      </div>
      <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
        {renderFields(fields.slice(3,6), {...doubleFieldProps})}
      </div>
    </Col>
  )
}

const categoryColor = (status) => {
  switch (true) {
    case ['cancelled', 'revoked',].includes(status):
      return status
    default:
      return 'primary';
  }
}

const  Show = ({record, isSaving, openInnerModal, renewIssuedPermit})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [permit, setPermit] = useState({})

  const renderPermitFields = useCallback(() => {
    let fields = permitFields(fieldAttrs)
    let slicePoint = 3
    const iconStatus = permit.permit?.expired === 'NO' ? 'success' : 'revoked'
    fields[4] = {...fields[4], icon: dotIcon(iconStatus), customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)}
    fields[5] = {
      ...fields[5], 
      icon: dollarIcon(), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }

    if(record.permit_type === 'Day Pass') {
      fields = filter(fields, (_field, idx) => [0, 2, 5].includes(idx))
      slicePoint = 2
    }

    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Permit Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
        {renderFields(fields.slice(0,slicePoint), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(slicePoint), {...doubleFieldProps})}
        </div>
      </Col>
    )
  },[permit, record])
  
  const renderCancelledFields = useCallback(() => {
    if(record.status !== 'cancelled') return
    const showDescription =  permit.permit?.payment_type === 'paid'
    const fields = cancelledFields(fieldAttrs, showDescription);
    fields[0] = {
      ...fields[0], 
      icon: dotIcon('cancelled'), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }
    if(showDescription && permit.permit?.refund_completed) {
      fields[2] = {
        ...fields[2], 
        icon: dollarIcon(), 
        customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
      } 
    }
    return (
      <Col className='px-0'>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Permit Status</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className='px-3'>
          {renderFields(fields, {...singleFieldProps})}
        </div>
      </Col>
    )
  },[permit, record.status])

  const renderRevokedFields = useCallback(() => {
    if(record.status !== 'revoked') return
    const fields = revokedFields(fieldAttrs)
    fields[0] = {...fields[0], icon: dotIcon('revoked'), customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)}
    return (
      <Col className='px-0'>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Permit Status</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className='px-3'>
          {renderFields(fields, {...singleFieldProps})}
        </div>
      </Col>
    )
  },[record.status])

  const validDateFields = useMemo(() => (
    map(permit.permit?.valid_days, (_date, idx) => (
      {
        name: `day-${idx + 1}`, 
        label: `Valid Day ${idx + 1}`,
        disabled: true,
        ...fieldAttrs
      }
    ))
  ), [permit])
    

  useEffect(() => {
    const fetchPermit = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: record.id})
        const newPermit = permitData(response.data);
        setPermit(newPermit)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(record.id) { fetchPermit() }
  },[record.id])

  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0 my-3'>
      <div className='mb-1'>
        <p className={`${styles.title} mx-0 mb-2 p-0`}>{`${record.permit_type} Permit`}</p>
          { 
            record.permit_type !== 'Day Pass' && 
              <p className={`${styles[`${categoryColor(permit.permit?.status)}-color`]} ${styles.category} text-capitalize m-0 p-0`}>
                {`${record.permit_category} Permit`}
              </p>
          }
          { 
            !['cancelled', 'revoked', 'expired'].includes(permit.permit?.status)
              &&  (
                <p 
                  className={`${permit.permit?.status === 'active' ? styles['success-color'] : styles['cancelled-color']} text-capitalize m-0 p-0`}
                  style={{fontSize: 14}}
                >
                  {`${permit.permit?.status}`}
                </p>
              )
             
          }    
      </div>
      <Form initialValues={{...permit, ...validDaysData(permit.permit?.valid_days)}} className={styles.form}>
        { renderCancelledFields() }
        { renderRevokedFields() }
        { renderVehicleFields() }
        { renderOwnerFields() }
        { renderPermitFields() }
        { !isEmpty(permit.permit?.valid_days) && (
          <Col xs={12} className='d-flex flex-wrap'>
            <div className="d-flex align-items-center my-3 w-100">
              <span className={styles.detailsLabel}>Valid Days</span>
              <span className="border border-2 flex-grow-1 ml-2"></span>
            </div>
            <div className='w-50 pr-3'>
              {renderFields(filter(validDateFields, (_f, idx) => idx % 2 === 0), {...doubleFieldProps})}
            </div>
            <div className='w-50'>
              {renderFields(filter(validDateFields, (_f, idx) => idx % 2 === 1), {...doubleFieldProps})}
            </div>
          </Col>
        )}
      </Form>
      { record.permit_category === 'temporary' &&
        <Col className='d-flex justify-content-center align-items-center mb-5'>
          <Button
            onClick={renewIssuedPermit}
            className={`${styles.button} ${permit.permit?.expired === 'NO' && styles['button-bg-secondary']}`}
            disabled={isSaving || permit.permit?.expired === 'NO'}
          >
            Renew
          </Button>
          <Button 
            onClick={openInnerModal}
            className={`${styles.button} ${styles['button-bg-orange']} ml-4`}
            disabled={isSaving}
          >
            Extend
          </Button>
        </Col>
      }
    </Container>
  )
}

export default Show;
