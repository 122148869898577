import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { invoke } from 'actions';
import { ROLES } from "config/permissions";
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, ADD_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/account_types';
import connectList from 'components/modules/connect_list';
import resourceFetcher from 'components/modules/resource_fetcher';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import { filterFetcher } from 'api/account_types';
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';
import TableData from './tableData';
import AccountTypeModal from '../account_type_modal';

const Index = (props) => {
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  const openCollapse = ['filters'].includes(activeTab)

  return (
    <AccessControl
      currentRole={props.currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              modalType={modalType}
              openCollapse={openCollapse}
              openModal={openModal}
              {...props}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} modalType={modalType} openCollapse={openCollapse} />
          </Col>
        </Row>
        <AccountTypeModal {...props} openModal={openModal} modalType={modalType} setActiveTab={setActiveTab} />
      </Container>
    </AccessControl>
  )
}

const resource = 'account_types';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setFilters: invoke(SET_FILTERS), 
      setRecord: invoke(SET_SINGLE_RECORD),
      addListElement: invoke(ADD_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.account_types.filters,
    record: state.account_types.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);
