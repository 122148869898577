import React, { useCallback, useRef } from 'react'
import { displayDate } from 'components/helpers'
import { filterFields } from 'components/helpers/fields/coupons/filterFields'
import { renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button'
import { filterFetcher as filterFetcherUser } from "api/user_coupons";
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'

const FilterForm = (props) => {
  const { filters, setFilters, setList, userId} = props;

  const attrs = { customInputClass: styles.formInput,
                  customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterViolations = useCallback(async (filters={}) => {
    const { created_at } = filters
    if(created_at && !created_at?.to) { delete created_at.to}
    if(created_at && !created_at?.from) { delete created_at.from}

    const response = await filterFetcherUser({filters: {...filters, user_id: userId}});
    try{
      if(response?.status === 200){
        setList(selectList(response))
      }
    }catch(error){
      console.log(error)
    }
  },[setList, userId])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const updatedFilters = {
      ...filterValues,
      ...filters,
      created_at: {
        from: displayDate(filterValues.created_at?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.created_at?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(updatedFilters);
    filterViolations(updatedFilters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      created_at: { from: '', to: ''},
      amount: { from: '', to: ''},
      coupon_type: '', 
      is_expired: '',
    })
    setFilters({})
    filterViolations({})
  }

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (
    <Form  getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        const dateFrom = formState.values.created_at?.from || ''
        const dateTo = formState.values.created_at?.to || ''
        
        const fields = getFields(dateFrom, dateTo)
        return <Row className='m-auto'>
          <Col className={`m-0 ${styles.fieldset}`} xs={3}>
            {renderFields(fields.slice(0, 2), {lSize: 5, iSize: 7})}
          </Col>
          <Col style={{maxWidth: '2%', marginLeft: '0.5rem'}}>
            <div className={`${styles['fieldset-dateStroke']} position-absolute`}/>
            <div className={`${styles['fieldset-dateStroke1']} position-absolute`}/>
          </Col>
          <Col style={{maxWidth: '17.5rem', marginLeft: '-1%'}} xs={3}>
            {renderFields(fields.slice(2, 4), {lSize: 5, iSize: 11})}
          </Col>
          <Col className={`m-0 ${styles.fieldset}`} xs={3}>
            {renderFields(fields.slice(4, 5), {lSize: 4, iSize: 7})}
          </Col>
          <Col className={`m-0 ${styles.fieldset}`} xs={3}>
            {renderFields(fields.slice(5, 6), {lSize: 4, iSize: 7})}
          </Col>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['btn-secondary']}`}
            >
              Reset
            </Button>
          </Col>
        </Row>
      }}
    </Form>
  )
}

export default FilterForm;
