import React,  { useEffect } from 'react'
/* Styles/Assets */
import { ReactComponent as UnionIcon } from 'assets/union_icon.svg';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import SlideButton from 'components/base/slide_button';
import styles from "./index.module.sass";
/* Base */
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { filterFetcher as statusFetcher } from 'api/permit_type_codes';
import {  Col, Row } from 'reactstrap';
import { isEmpty } from 'underscore';
/* Component */
import FilterForm from './filterForm';

const CollapseContent = ({ activeTab, filters, startFetching, setInitialList, setFilters, match, resource, openModal, modalType, code, setCode, permitTypeCodeList, setpermitTypeCodeList}) => {

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await statusFetcher({perPage: 10});
      if(response?.status === 200){
        setpermitTypeCodeList(response?.data?.reverse())
      }
    }
    if (activeTab && isEmpty(permitTypeCodeList)) { fetchStatus() };
  },[activeTab, permitTypeCodeList, setpermitTypeCodeList])

  useEffect(() => {
    setCode(code)
  },[code, setCode])
  
  return (
    <div>
    <Row className={`${styles.filters} w-100 h-100 align-items-center justify-content-between px- pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm 
          startFetching={startFetching} 
          setInitialList={setInitialList} 
          match={match} 
          resource={resource} 
          permitTypeCodeList={permitTypeCodeList}
          filters={filters}
          setFilters={setFilters}
          setpermitTypeCodeList={setpermitTypeCodeList}
          openModal={openModal}
          modalType={modalType}
          setCode={setCode}
        /> }
    </Row>
    <Row className={`${styles.filters1} h-100 align-items-center justify-content-between px-0 pt-5 pb-5 mx-0 mt-2`} sm="12">
      <div style={{marginLeft: '10px'}}>
        Description
      </div> 
      <div className={styles.descContainer}>
      {code?.description}
      </div>
    </Row>
    </div>
  )
}


const ToolBar = ( props) => {

  const {activeTab, setActiveTab, isResourceFetching, openModal, setInitialList, code} = props;
  const handleToggleCollapse = (tab) => {
    if(isResourceFetching) return
    setActiveTab(activeTab => activeTab === tab ? null : tab)
  }
  
  return (
    <NavigationToolbar
     openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          setInitialList={setInitialList}
          {...props}
        />
      }
      backTab='permits'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span className={styles.title}>{`${code?.code} (${code?.permit_type_details?.length})`}</span>
        <span>
          <Button
            isActive={true}
            className={styles.button}
            icon={<PlusIcon className='svg-white'/>}
            onClick={() => {
              openModal('add')
            }}
          >
          </Button>
        </span>
      </Col>
       
      <Col className='d-flex justify-content-end px-0'>
      <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
          className={styles.slidebtn}
          onClick={() => {
            openModal('create')
          }}
        >
          <span className='mr-1 d-inline-block' style={{width: '150px', fontWeight: 'bolder'}}>Create New Code</span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<UnionIcon className={activeTab === 'filters' ? styles.activeIcon : ''}/>}
          size="md"
        />
        </Col>
        
      
    </NavigationToolbar>
  )
}

export default ToolBar;
