import React, {useCallback} from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/coupons';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {

  const { list, filters, isResourceFetching, openModal, modalType } = props

  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        key={record.id} 
        record={record} 
      />
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list, modalType, openModal])

  
  return (
    <IndexTable
      {...props}
      className={`${styles.table}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <>
          <th attr="coupon_code" style={{ width: '20%', minWidth: '210px', }} >Coupon Code</th>
          <th attr="generated_for" style={{ width: '45%', minWidth: '210px' }}>Generated For</th>
          <th attr="created_at" style={{ width: '45%', minWidth: '210px' }}> Generated On </th>
          <th attr="reason" style={{ width: '45%', minWidth: '210px' }}> Reason </th>
          <th attr="amount" style={{ width: '45%', minWidth: '210px' }}> Coupon Amount </th>
          <th attr="expiry_date" style={{ width: '45%', minWidth: '210px' }}> Expiry Date </th>
          <th attr="status" style={{ width: '45%', minWidth: '210px' }}> Expiration </th>
        </>
      }
      renderRecords={renderRecords}
      entityName='Coupons'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData