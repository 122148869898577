import React from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { createFields } from 'components/helpers/fields/agency_type';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'

const Create = (props) => {
  const { closeModal, isSaving, createAgencyType, errors, setErrors } = props

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { name } = formState;
    if(!name) errors['name'] = ['Agency Type Name is required']
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    createAgencyType(values)
  }

  const fields = (category) => {
    return createFields({category, ...fieldAttrs})
  }

  return (
    <Container className='p-0'>
      <Form className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col className='pt-3'>
                <div>
                  {renderFields(newFields.slice(0, 8), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-3`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                  Create
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default (Create);