import { FieldType } from 'components/helpers/form_fields'

const vehicleDetails = (attrs) => {
 
  const { customInputClass='', customLabelClass='', customDropdownInputClass='', vehicleType, manufacturers, vehicleClass, vehicleSubClass } = attrs;

  const fields = [
    [
      {
        name: `plate_number`,
        label: 'Plate Number',
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      },
    ],
    [
      {
        name: `color`,
        label: 'Color',
        customInputClass,
        customLabelClass,
        disabled: false,
      },
      {
        name: `manufacturer_id`, 
        label: 'Vehicle Make',
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: false,
        customInputClass: customDropdownInputClass,
        options: manufacturers? manufacturers : [],
        customLabelClass,
        mandatory: true,
      },
    ],
    [
      {
        name: `vehicle_class`,
        label: 'Vehicle Class',
        customLabelClass,
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: false,
        customInputClass: customDropdownInputClass,
        options: vehicleClass? vehicleClass : [],
        mandatory: true,
      },
    ],
    [
      {
        name: `vehicle_type`,
        label: 'Vehicle Type',
        customLabelClass,
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: false,
        customInputClass: customDropdownInputClass,
        options: [{
          "value": "gasoline",
          "label": "Gasoline"
        },
        {
          "value": 'electric',
          "label": "Electric"
        }].map(({ value, label }) => {
          return { value, label };
        }),
        mandatory: true,
      }, 
    ],
    [
      {
        name: `registration_state`,
        label: 'State',
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      },
      {
        name: `manufacture_year`,
        label: 'Year',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      },
      {
        name: `model`,
        label: 'Model',
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      },
      {
        name: `sub_class`,
        label: 'Vehicle Sub Class',
        customLabelClass,
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: false,
        customInputClass: customDropdownInputClass,
        options: vehicleSubClass? vehicleSubClass : [],
        mandatory: true,
      },
        {
          name: `category`,
          label: 'Category',
          customLabelClass,
          type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
          disabled: false,
          customInputClass: customDropdownInputClass,
          options: [{
            "value": "personal",
            "label": "Personal"
          },
          {
            "value": 'government',
            "label": "Government"
          },{
            "value": 'agency',
            "label": "Agency"
          }].map(({ value, label }) => {
            return { value, label };
          }),
          mandatory: true,
        },
    ],
  ]
  const updatedList = vehicleType === 'electric' ? fields.concat([{
    name: `battery_capacity`,
    label: 'Battery Capacity',
    type: FieldType.NUMBER_FIELD,
    customInputClass,
    customLabelClass,
  }]) : fields
  
  return  updatedList 
}

export default vehicleDetails;