import React, { useState, useRef, useCallback} from "react";
import GooglePayDetails from 'components/modules/google_pay.js'
import { Form } from 'informed';
/* Base */
import { renderField } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass';
import GooglePayButton from '@google-pay/button-react';
import { map } from 'underscore';
import Cards from 'react-credit-cards';
import { paymentFields } from 'components/helpers/fields/app/permits/show';
import { Col, Row } from 'reactstrap';

const Payment =(props) =>{
  const { closeModals, renewPermit, record } = props;

  const [cardNumber, setCardNumber] = useState('');
  const [name, setName] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [expiryM, setExpiryM] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const creditCardNum = useRef('')
  const holder_name = useRef('')
  const cvv = useRef('')
  const [showCreditCardFields, setShowCreditCardFields] = useState(false)

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel
  }
   
  const [showGooglePay, setGooglePayShow] = useState(false);
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const formApiRef = useRef();

  const cardMFieldProps = {
    iSize: 4,
    lSize: 12,
    events:{
      onChange: (e) => setExpiryM(e) || setFocus('expiry'),
    }
  }
  const cardYFieldProps = {
    iSize: 4,
    lSize: 12,
    events:{
      onChange: (e) => setExpiryY(e) || setFocus('expiry'),
    }
  }

  const years = Array.from(
    { length: 20 },
    (_, index) => new Date().getFullYear() + index
  );

  const submitValues = useCallback((values) => {
    const data = { 
      gateway_params:{
        amount: record?.total_cost,
        credit_card_attributes:{
          number:  creditCardNum?.current?.value,
          cvv: cvv?.current?.value,
          holder_name: holder_name?.current?.value,
          expiration_month: values?.expiration_month,
          expiration_year: values?.expiration_year
        },
        
      }
    }
    renewPermit(data)
  },[record, renewPermit])

  const submitValuesGPay = useCallback((paymentToken) => {
    const data = { 
      gateway_params:{
        amount: record?.total_cost,
        digital_wallet_attributes: {
          encryptionhandler: 'EC_GOOGLE_PAY',
          devicedata: paymentToken?.paymentMethodData?.tokenizationData?.token
        }      
      }
    }
    renewPermit(data)
  },[record, renewPermit])

  
  const initialValues = {
    payment_amount: record?.total_cost
  }
  const getFields = () => {
    return paymentFields({...fieldAttrs,customExpiryDropdownInputClass:styles.expiryDropdown, customExpiryLabel: styles.expiryDropdownLabel, cutomExpiryLabel2: styles.cardLabels2,  customDropdownInputClass: styles.formDropdownInput, years:years})
  }
  
  
  const showPaymentOption = () =>{
    if (formApiRef.current?.getValue('payment_methods')===0){
      setGooglePayShow(true)
      setShowCreditCardFields(false)
  
    }else if (formApiRef.current?.getValue('payment_methods')===1) {
      setShowCreditCardFields(true)
      setGooglePayShow(false)
    }else{
      // setShowCreditCardFields(false)
      // setGooglePayShow(false)
    }
  }
  
  const newFields = getFields()

  const paymentFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (_e) => showPaymentOption()
    }
  }

  return (
    
    <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={submitValues}>
              <Row className='mx-auto w-100'>
                <Col>
                  <Row className={`flex-column w-100 text-center p-1`}>
                    <p className={`${styles.modalTitle}`}> Make Payment </p>
                      
                      { map(newFields[0], (field, idx) => {
                          
                          return (
                            <>
                              { idx === 0 && 
                                <Col className={`m-0 pt-3 ${styles['fieldset-double']} pl-1`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps } )}
                                </Col>
                              }
                              {idx === 1 &&
                                <Col className={`m-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps} )}
                                </Col>
                              }
                              
                            </>
                          )
                        })
                        }
                      {showCreditCardFields && !showGooglePay &&
                        <Col>
                        <div className={styles.cardContainer}>
                          <div className={styles.cardPreview}>
                            <Cards
                              number={cardNumber}
                              name={name}
                              expiry={`${expiryM}${expiryY}`}
                              cvc={cvc}
                              focused={focus}
                            />
                          </div>
                          </div>
                          <div>
                          <div className={styles.cardInputs}>
                            <label className={styles.cardLabels}>Card Number</label>
                            <input
                              type="tel"
                              id="credit_card_number"
                              name="credit_card_number"
                              placeholder="Card Number"
                              maxLength={16}
                              value={cardNumber}
                              onChange={(e) => setCardNumber(e.target.value)}
                              onFocus={() => setFocus('number')}
                              className={styles.cardInput}
                              ref={creditCardNum}
                              required
                            />
                    
                            <label className={styles.cardLabels}>Holder Name</label>
                            <input
                              type="text"
                              id="holder_name"
                              name="holder_name"
                              placeholder="Holder Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              onFocus={() => setFocus('name')}
                              className={styles.cardInput}
                              ref={holder_name}
                              required
                            />


                          { map(newFields[1], (field, idx) => {
                            return (
                              <>
                                {idx=== 0 &&
                                  renderField(field, { ...cardMFieldProps} )}
                                {idx===1 &&
                                  renderField(field, { ...cardYFieldProps} )
                                }
                              </>
                            )
                          }) }
                            <label htmlFor="cvc" className={styles.cvcLabel}>CVC</label>
                            <input
                              type="tel"
                              id="cvc"
                              name="cvc"
                              placeholder="CCV"
                              value={cvc}
                              onChange={(e) => setCvc(e.target.value)}
                              onFocus={() => setFocus('cvc')}
                              className={styles.cardInput}
                              maxLength={3}
                              ref={cvv}
                              required
                            />
                            </div>
                            
                          </div>
                          </Col>
                        
                      }
                    <div centered>
                      {showGooglePay  && <GooglePayButton
                      environment="TEST"
                      paymentRequest={GooglePayDetails(record?.total_cost)}
                      onLoadPaymentData={paymentRequest => {
                        submitValuesGPay(paymentRequest)
                      }}
                      buttonType='pay'
                      className={styles.gpayBtn}

                    />}
                    </div>
                  </Row>
                  </Col>
                  </Row>
        <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
          <Button onClick={closeModals} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            Cancel
          </Button>  
          {showCreditCardFields &&     
          <Button type='submit'  className={`${styles.button} ${styles['button-bg-primary']}`}>
            Pay
          </Button>
          }
      </Col>
    </Form>
  );
}

export default Payment;
