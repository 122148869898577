import React, { useContext , useState, useEffect} from 'react'
/* API */
import { confirmUser, sendConfirmationInstructions } from 'api/webapp/subscriber';
/* Base */
import CardLayout from 'components/base/layout/card';
import AuthLayout from 'components/base/layout/auth';
/* Helpers */
import { btnSpinner } from 'components/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { AlertMessagesContext } from 'components/helpers/alert_messages';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
/* Modules */
import OtpForm from 'components/modules/otp_form';
import RedirectIfAuthorized from 'components/modules/redirect_if_authorized';
import styles from './index.module.sass'
import { useTranslation } from "react-i18next";
import WebAppHeader from 'components/base/webapp_header';
import Countdown, { zeroPad } from 'react-countdown';

const ConfirmUserAccount = (props) => {
  const {  history, location } = props
  const { t } = useTranslation()
  const [verificationCode, setVerificationCode] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [modalContent , setModalContent] = useState('')
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const email = window.sessionStorage.getItem('userEmail')
  const [isCountingDown, setIsCountingDown] = useState(false);
  const COUNTDOWN_TIME = 2 * 60 * 1000;
  const getInitialEndTime = () => {
    const storedEndTime = localStorage.getItem('countdownEndTime');
    return storedEndTime ? parseInt(storedEndTime, 10) : null;
  };
  const [endTime, setEndTime] = useState(getInitialEndTime);

  useEffect(() => {
    if (endTime && endTime > Date.now()) {
      setIsCountingDown(true);
    } else {
      localStorage.removeItem('countdownEndTime');
    }
  }, [endTime]);

  const handleResendClick = () => {
    const newEndTime = Date.now() + COUNTDOWN_TIME;
    setEndTime(newEndTime);
    localStorage.setItem('countdownEndTime', newEndTime);
    setIsCountingDown(true);
  };

  const handleCountdownComplete = () => {
    setIsCountingDown(false);
    localStorage.removeItem('countdownEndTime');
  };

  const submitForm = async ()  => {
    setIsSaving(false)
    try {
      const response = await confirmUser(email, verificationCode )
      if(response.status === 200 ){
        redirectToLogin()
        localStorage.removeItem('countdownEndTime');
        window.sessionStorage.removeItem("userEmail");
      }
    } catch (error) {
      setIsSaving(false)
      setModalContent(t('confirm_user_account.incorrect_code'))
      setModalOpened(true)
    } finally {
      setIsSaving(false)
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return null
    } else {
      return <span>{zeroPad(minutes)} : {zeroPad(seconds)}</span>
    }
  }

  const resendToken = async ()  => {
    if(isCountingDown) return
    setIsSaving(false)
    try {
      const response = await sendConfirmationInstructions(email)
      if(response.status === 200 ){
        handleResendClick()
        localStorage.getItem("selectedLanguage") === 'en' && setModalContent(`Verification code is send to your email ${email} successfully.`)
        localStorage.getItem("selectedLanguage") === 'es' && setModalContent(`El código de verificación se envió exitosamente a su correo electrónico ${email}.`)
        localStorage.getItem("selectedLanguage") === 'hi' && setModalContent(`सत्यापन कोड आपके ईमेल ${email} पर सफलतापूर्वक भेज दिया गया है।`)
        setModalOpened(true)
      }
    } catch (error) {
      setIsSaving(false)
      setModalContent(t('confirm_user_account.incorrect_email'))
      setModalOpened(true)
    } finally {
      setIsSaving(false)
    }
  };

  const doitLetter = () => {
    addAlertMessages([
      {
        text: t('confirm_user_account.do_it_later_msg'),
        type: t("confirm_user_account.pending")
      }
    ])
    return history.push('/login')
  }

  const redirectToLogin = () => {
    localStorage.removeItem('firstLoadDone');
    addAlertMessages([
      {
        text: t('confirm_user_account.success_msg'),
        type: t('confirm_user_account.success')
      }
    ])
    return history.push('/login', { showMessage: true })
  }

  return (
    <React.Fragment>
      <WebAppHeader/>
      <AuthLayout>
        <h1 className={ `${styles.title} h1-title-primary mb-8 text-center sticky-top`}>{t("confirm_user_account.title")}</h1>
        <br/>
        <br/>
        <h1 className={ `${styles.subtitle} h1-title mb-4 text-center sticky-top`}>{t("confirm_user_account.greeting", {name: window.sessionStorage.getItem('userName')})}</h1>
        {localStorage.getItem("selectedLanguage") !== "hi" && 
          <p className={styles.text}> {t("confirm_user_account.instruction1")} 
            <span className='mx-1' style={{color: 'black', fontWeight: 500}}>{email}.</span>  
            {t("confirm_user_account.instruction2")} 
          </p>
        }
        {localStorage.getItem("selectedLanguage") === "hi" && 
          <p className={styles.text}> हमने <span style={{color: `black`, fontWeight: 500}}>{email}</span> पर 6 अंकों का सत्यापन कोड भेजा है। कृपया अपने खाते की पुष्टि करने के लिए नीचे कोड दर्ज करें। </p>}

        <CardLayout title="Confirm otp">
          <Modal 
            centered 
            isOpen={modalOpened} 
            backdropClassName={styles.backdropC}
            contentClassName={styles.appModal}
            modalClassName={styles.modal}
            style={{width: '98%'}}
          >
            <ModalHeader>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 mt-3" style={{color: 'red'}}/>
              {t("confirm_user_account.check_your_email")}
            </ModalHeader>
            <ModalBody><div style={{padding: '20px'}}>{modalContent}</div> </ModalBody>
            <ModalFooter>
              <Button
                className={styles.closeButton}
                onClick={() => setModalOpened(false)}
              >
                {t('confirm_user_account.close')}
              </Button>
            </ModalFooter>
          </Modal>
          <OtpForm value={verificationCode} setValue={setVerificationCode} />
          <br/>
          <Button 
            onClick={submitForm} 
            style={{fontSize: 15, backgroundColor: '#3a9ced', borderColor: 'white', borderRadius: 8}} 
            className="p-3 text-uppercase btn-lg btn-block" 
          >
            {isSaving ? btnSpinner({ className: 'spinner-border' }) : t("buttons.verify")}
          </Button>
          {!location?.state?.doLater && 
            <Button 
              onClick={()=> doitLetter()} 
              style={{fontSize: 15, backgroundColor: '#3a9ced', borderColor: 'white', borderRadius: 8}} 
              className="p-3 text-uppercase btn-lg btn-block" 
            >
              {t("buttons.do_it_later")}
            </Button>
          }
        </CardLayout>
        <p className={styles.text}>{t("confirm_user_account.resend_instruction")} 
          {isCountingDown && endTime && <span>{t("confirm_user_account.resend_instruction2")} <Countdown date={endTime} renderer={renderer} onComplete={handleCountdownComplete}/></span> }
          <span onClick={()=>resendToken()} style={{cursor:'pointer', color: '#3a9ced', fontWeight: 500}}> {t("confirm_user_account.resend_it")}</span> 
        </p>
      </AuthLayout>
    </React.Fragment>
  );
}

export default RedirectIfAuthorized(ConfirmUserAccount);