import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { InfoWindow, Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import env from '.env';
import styles from './nearby_places.module.sass';
import Loader from 'components/helpers/loader';
import markerIcon from 'assets/marker.svg';
import { Row, Col } from 'reactstrap';
import Toggle from 'components/base/toggle';
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
import { Form } from "informed";
import { isEmpty } from 'underscore';
import { ReactComponent as FileIcon } from 'assets/file-icon.svg'
import { ReactComponent as MapIcon } from 'assets/map_icon.svg'



const NearbyPlaces = ({ google, parkingLotLocation, isLoading, places }) => {

  const [{ currentPlace, currentMarker }, setState] = useState({
    currentPlace: parkingLotLocation,
    currentMarker: null
  });

  const formApiRef = useRef()

  const setFormApi = formApi => {
    formApiRef.current = formApi;
  };
  const [graph, setGraph] = useState(true)


  

  const onMarkerClick = (place) => (props, marker) => {
    setState(prevState => ({
      ...prevState,
      currentPlace: place,
      currentMarker: marker
    }));
  };

  const locationIcon = () => <LocationIcon className={styles.LocationIcon} />

  const onMapClicked = () => {
    if (!currentMarker) return;
    setState(prevState => ({
      ...prevState,
      currentMarker: null
    }));
  };

  const getFields = (p,name, id) => {
    return [
      { 
        name: `place_${name}`,
        label: `o.33km`,
        value: name,
        icon: locationIcon(),
        customLabelClass: styles.formLabel,
        customInputClass: styles.formInput,
        disabled: true
      },
    ]
  }

  const fieldProps = (lSize, iSize) => ({
    lSize: 3,
    iSize: 9,
   
  })

  const fields =()=>{
    const pl = places?.map((p,idx) => {
      return getFields(p,p.name, idx+1);
    });
    const fields = pl?.map((feild) => {
      return feild[0]
    });
    return fields
  }

  const renderListFields =  () => {
    return  <Row className='m-auto align-items-center'>
      <Col className={`m-0 ${styles.fieldset}`} xs={13}>
        {renderFieldsWithGrid(fields(places),3,4, { ...fieldProps(), errors: {} })}
      </Col>
     
    </Row>
  }

  useEffect(() => {
    const names = places?.map((rule) => {
      return "place_" + rule?.name  
    });
    const values = places?.map((rule) => {
      return rule.name ? rule.name : '';
    });
    const object = names?.reduce((acc, name, index) => {
        return {
            ...acc,
            [name]: values[index],
        };
    }, {});
    // return object
    if(!graph && !isEmpty(places)){formApiRef.current.setValues(object)}
  }, [places, formApiRef, graph])



  const renderForm =() => {
    return (
      <Form
        getApi={setFormApi}
        
        className={`mt-2 ${styles.form}`}
      >
      <div>
        {renderListFields(places)}
      </div>
    </Form>
    )
  }

  const renderList = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (places?.length === 0) {
      return <div>No nearby places found.</div>;
    }
    return (
      <React.Fragment>
        {renderForm()}
      </React.Fragment>
    );
  };

  const getTypeName = type => capitalize(type.replace(/_/g, ' '));

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (places?.length === 0) {
      return <div>No nearby places found.</div>;
    }
    return (
      <div className={styles.container} >
        <Map
          google={google}
          initialCenter={currentPlace}
          center={currentPlace}
          zoom={15}
          onClick={onMapClicked}
        >
          {places?.map((place, index) => (
            <Marker
              key={index}
              title={place.name}
              name={place.name}
              position={place}
              onClick={onMarkerClick(place)}
            />
          ))}
          <Marker
            title='Parking Lot'
            position={parkingLotLocation}
            icon={{
              url: markerIcon,
              anchor: new google.maps.Point(32, 64),
              scaledSize: new google.maps.Size(64, 64)
            }}
            onClick={onMarkerClick(parkingLotLocation)}
          />
          <InfoWindow
            marker={currentMarker}
            visible={!!currentMarker}
          >
            <div>
              <h5>{currentPlace ? currentPlace.name : ''}</h5>
              {currentPlace && currentPlace.types &&
                <span>{currentPlace.types.map(type => getTypeName(type)).join(', ')}</span>
              }
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  };

  return (
    <Row className='m-auto align-items-center pl-1'>
      <Col className="d-flex align-items-center mb-2" xs={12}>
        <span className={styles.title}>Nearby Places</span>
        <div className={styles.switchWrapper}>
            <span className={graph===false? styles.toggleGraphLabel :`${styles.toggleGraphLabel} ${styles['background-primary']}`}>Graph View</span>
            <Col>
              <Toggle
                onClick={() => {setGraph(!graph)}}
                customToggleClass= {styles.toggle}
                customToggleOnClass= {styles.toggleOn}
                positiveText=''
                negativeText={!graph? <FileIcon  className={styles.ListIcon}/> : <MapIcon  className={styles.MapIcon}/>}
                value={!graph}
                offHighlightClass={styles.toggleHighlight}
                onHighlightClass={styles.toggleOnHighlight}
              />
            </Col>
            <span className={graph===true? styles.toggleListLabel : `${styles.toggleListLabel} ${styles['background-primary']}` }>List View</span>
        </div>
      </Col>
      
      {graph && renderContent()}
      {!graph && renderList() }
    </Row>
  );
};

NearbyPlaces.propTypes = {
  google: PropTypes.object,
  isLoading: PropTypes.bool,
  parkingLotLocation: PropTypes.object,
  places: PropTypes.array.isRequired
};

export default GoogleApiWrapper({ apiKey: env.google_cloud_api_key })(NearbyPlaces);
