import React from 'react';
import { asField } from 'informed';
import CustomDropdown from 'components/base/dropdown';
import styles from './index.module.sass';
import { find, isUndefined } from 'underscore';

const DropdownSelect = asField(({ fieldApi, fieldState, options, disabled, events = {}, placeholder, customInputClass }) => {
  const { value } = fieldState;
  const { setValue } = fieldApi;
  const coveringText = () => {
    if(isUndefined(value) && placeholder) return placeholder
    return find(options, option => option.value === value)?.label
  }
  
  const handleChange = (selectedOption) => {
    setValue(selectedOption?.value);
  }
  
  return (
    <CustomDropdown
      value={{value: value}}
      onChange={(selectedOption) => {
        handleChange(selectedOption)
        events.onChange && events.onChange(selectedOption);
      }}
      options={options}
      disabled={disabled}
      coveringText={coveringText}
      className={styles.dropdown}
      customInputClass={customInputClass}
    />
  );
});

export default DropdownSelect;
