import React from 'react'
import { Col, Container } from 'reactstrap';
import Button from 'components/base/button';
import styles from './index.module.sass'

const ActivateDeactivate = ({toggleStatus, closeModal, selectedRecord, isSaving}) => {

  const message = () => <>
    <span className='mr-1'>The measure</span>
    <span className={styles.boldText}>{selectedRecord.measure_type.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}</span>
    <span className='mx-1'>for violation type</span>
    <span className={styles.boldText}>{selectedRecord?.violation_type?.display_name}</span>
    <span className='mx-1'>will be</span>
    <span>{`${selectedRecord.status === 'active' ? 'deactivated' : 'activated' }`}</span>
    <span className='mx-1'>immediately.</span>
  </>

  return (
    <Container className='p-0'>
    <Container className={styles.content} >
      <Col className='mb-4'>
        { message() }
      </Col>
      <Col className="d-flex justify-content-center my-2">
        <Button onClick={() => closeModal()} type='button' className={`${styles.button} ${styles['button-bg-light']} mr-4`}>
          Cancel
        </Button>
        {
          selectedRecord.status === 'inactive' 
            && <Button 
                type='button' 
                onClick={() => {toggleStatus({status: 'active'}); closeModal()}} 
                className={`${styles.button} ${styles['button-bg-primary']}`}
                disabled={isSaving}
              >
                Activate
              </Button>
        }
        {
          selectedRecord.status === 'active' 
            && <Button 
                type='button' 
                onClick={() => {toggleStatus({status: 'inactive'}); closeModal()}} 
                className={`${styles.button} ${styles['button-bg-danger']}`} 
                disabled={isSaving}
              >
                Deactivate
              </Button>
        }
      </Col>
    </Container>
  </Container>
  )
}

export default ActivateDeactivate;
