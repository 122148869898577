const SET_LIST = 'SET_SUBSCRIBER_LIST';
const SET_LIST_ELEMENT = 'SET_SUBSCRIBER_LIST_ELEMENT';
const SET_RECORD = 'SET_SUBSCRIBER_RECORD';
const SET_FILTERS = 'SET_SUBSCRIBER_FILTERS';
const SET_SINGLE_RECORD = 'SET_SINGLE_SUBSCRIBER_RECORD'
const ADD_LIST_ELEMENT = 'ADD_SUBSCRIBER_LIST_ELEMENT'
const POP_LIST_ELEMENT = 'POP_SUBSCRIBER_LIST_ELEME NT';

export {
  SET_LIST,
  SET_RECORD,
  SET_LIST_ELEMENT,
  SET_FILTERS,
  SET_SINGLE_RECORD,
  ADD_LIST_ELEMENT,
  POP_LIST_ELEMENT
};
