import React, { useRef } from "react";
/* Helpers */
import { showFields } from "components/helpers/fields/reward_types/showFields";
/* Base */
import { renderFieldsWithGrid } from "components/base/forms/common_form";
/* Components */
import { Form } from "informed";
/* Assets */
import styles from "./index.module.sass";
import Toggle from "components/base/toggle";
import { ReactComponent as ResumeIcon } from 'assets/polygon-icon.svg';
import { ReactComponent as PauseIcon } from 'assets/union-icon.svg';

const FilterForm = (props) => {
  const { record, openModal } = props;

  const attrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
  };

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const initialValues = {
    created_at: record?.created_at_parsed,
    points_earned: record?.total_points_earned,
    points_redeemed: record?.total_points_redeemed,
    users_enrolled: record?.users_enrolled,
  };

  return (
    <Form
      initialValues={initialValues}
      getApi={setFormApi}
      className={styles.form}
    >
      {({ formState }) => {
        return (
          <React.Fragment>
            <div lg={12}>
              {renderFieldsWithGrid(showFields({ ...attrs }), 3, 4, {
                iSize: 6,
                lSize: 5,
              })}
              <div className={styles.toggleDiv}>
                <label className={styles.toggleLabel}>Status</label>
                <Toggle
                  onClick={() => { openModal("activate_deactivate") }}
                  customToggleClass={styles.toggle}
                  customToggleOnClass={styles.toggleOn}
                  positiveText=""
									negativeText={record.status === 'active' ? <PauseIcon className={styles.barIcon}/> : <ResumeIcon className={styles.polygonIcon}/>}
                  value={record.status === "active" ? true : false}
                  offHighlightClass={styles.toggleHighlight}
                  onHighlightClass={styles.toggleOnHighlight}
                />
                <p
                  className={
                    record.status === "active"
                      ? styles.activeTxt
                      : styles.pauseTxt
                  }
                >
                  * {record.status === "active" ? "Resumed" : "Paused"}
                </p>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

export default FilterForm;