import React, { useCallback, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { filterFetcher } from 'api/parking_rules';
import { show } from 'api/parking_lots';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import connectList from 'components/modules/connect_list';
import { search as dropdownsSearch } from "api/dropdowns";
import AccessControl from 'components/helpers/access-control';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/parking_rules';
import { invoke } from 'actions';
import ToolBar from '../../toolbar';
import RulesModal from '../rulesModal';
import TableData from './tableData';
import resourceFetcher from 'components/modules/resource_fetcher';
import {exampleData} from 'components/helpers/fields/permit_types/index'
import { withRouter } from "react-router";

const Index = (props) => {
  const { currentUser, match, isResourceFetching, startFetching, record } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [currentLocation , setCurrentLocation] = useState(exampleData)
  const [userLocation, setUserLocation] = useState()
  const [dropdowns, setDropdowns] = useState({
    agencies: [],
    selectedAgency: [],
    officers: []
  })

  const [selectedParkingLot, setSelectedParkingLot] = useState();

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const response = await show({id: parkingLotId});
        setSelectedParkingLot(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchResource()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  const parkingLotId = match?.params?.id;

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  },[props])

  const fetchData = () => {
    if (!record) return;
    const promises = [];
    promises.push(
      startFetching(dropdownsSearch("agency_list")).then((response) => {
        setDropdowns(state => ({...state, agencies: response.data }))
        const selectedAgency = response.data.find(
          (agency) => agency.value === selectedParkingLot?.agency_id
        );
        setDropdowns(state => ({...state, selectedAgency: selectedAgency }))
      })
    );
    if (selectedParkingLot?.agency_id) {
      promises.push(
        startFetching(
          dropdownsSearch("agency_officers_list", {
            agency_id: selectedParkingLot?.agency_id,
          })
        ).then((response) => setDropdowns(state => ({...state, officers: response.data }))),
      );
    }
    Promise.all(promises);
  };


  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              resource={resource}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isFetching={isResourceFetching}
              pageType="rules"
              parkingLotId={parkingLotId}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} activeTab={activeTab} modalType={modalType} />
          </Col>
        </Row>
        <RulesModal
          {...props}
          openModal={openModal}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
          modalType={modalType}
          setActiveTab={setActiveTab}
          agencyOfficer={dropdowns.officers}
          parkingLotId={parkingLotId}
        />
      </Container>
    </AccessControl>
  )
}

const resource = 'parking_rules';

const  mapState = (state) => {
  return { 
    filters: state.parking_rules.filters,
    record: state.parking_rules.records.record
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

export default withRouter(
  connectList(
    resource,
    SET_LIST,
    resourceFetcher(filterFetcher, resource),
    connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
    { fetchCondition: () => true }
  )
)

