import React from 'react'
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { map } from 'underscore';
import Action from './action'

const TableRow = (props) => {
    const { record, setRecord, selectedRecordId, openModal, modalType } = props

    const actions = [
        {
            icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
            highlightedClass: `${styles['background-primary']} mx-3 text-white`,
            onClick: () => { openModal('show') },
            text: 'View',
            isActive: modalType === 'show' && selectedRecordId === record.id,
        },
        {
            icon: <EditIcon className='mx-3' />,
            highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
            onClick: () => { openModal('edit') },
            text: 'Edit',
            isActive: modalType === 'edit' && selectedRecordId === record.id,
            disableHighlight: record.shared || record.permit_type === "Day Pass"
        },
    ]

    return (
        <tr onClick={() => setRecord(record)}>
            <td>{record?.event_name}</td>
            <td>{record?.organizer_email ? record?.organizer_email : 'N/A'}</td>
            <td>{record?.event_days}</td>
            <td>{record?.total_spaces}</td>
            <td>{record?.cost_per_pass}</td>
            <td>{record?.passes_sold}</td>
            <td>
                <div className={`${styles.actions} d-flex justify-content-center `} >
                    {map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
                </div>
            </td>
        </tr>
    );

};

export default TableRow