import React, { useCallback } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
/* Assets */
import styles from './index.module.sass'
/* API */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import Confirmation from './confirmation/index'


const PaymentModal = (props) => {
  const { openModal, modalType } = props
  const closeModal = useCallback(() => {
      openModal();    
  }, [openModal])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.appModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'confirmation' && 
          <Confirmation
            closeModal={closeModal}
          />
        }
        
      </ModalBody>
    </Modal>
  )
}

export default PaymentModal;
