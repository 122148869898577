import { FieldType } from 'components/helpers/form_fields'

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate, parkingLots=[], verdictList}) => {
  return [
    {
      name: 'plate_number',
      label: 'Plate Number',
      placeholder: 'Enter Plate Number',
      iSize: '3',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'parking_lot_ids',
      label: 'Parking Lots',
      type: FieldType.MULTISELECT_FIELD,
      showToolTip: false,
      options: [{value: 0, label: 'All Parking Lots'}].concat(parkingLots),
      placeholder: 'Choose a Parking Lot',
      customInputClass,
      customLabelClass,
      showCount: false,
    },
    {
      name: 'verdict',
      label: 'Verdict',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a verdict',
      options: verdictList.map((verdict) => {
        return { value: verdict.label, label: verdict.label };
      }),
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'range["from"]', 
      label: 'Date',
      type: FieldType.SINGLE_DATE_FIELD,
      max: toDate || new Date(),
      placeholder: 'From',
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'range["to"]', 
      type: FieldType.SINGLE_DATE_FIELD,
      max: new Date(),
      min: fromDate,
      placeholder: 'To',
      customInputClass,
      customLabelClass,
    },
  ];
}

export { filterFields };