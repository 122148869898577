import React, { useState, useEffect } from "react";
import { isEmpty } from "underscore";
import { Row, Col, Card, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";
import moment from "moment";
import DateModal from "components/base/date_modal";
import Chart from 'react-apexcharts'
import DetailedReportApi from "api/detailed_reports";
import Loader from "components/helpers/loader";
import style from "./data_card.module.sass";

const BarChart = (props) => {
  const {data} = props

  const state = {
    optionsRadial: {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        },
      },
      colors: ['#3A9CED', '#E8EBED'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      grid: {
        show: true
      },
      xaxis: {
        categories: data?.interval_date_calculation?.actual_revenue_range.length === 7 ?
         ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] :
         (data?.interval_date_calculation?.actual_revenue_range.length === 8 ? [0,3,6,9,12,15,18,21,24] :
          ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6']),
        title: {
          text: data?.interval_date_calculation?.actual_revenue_range.length === 7 ? 'Days' : (data?.interval_date_calculation?.actual_revenue_range.length === 8 ? 'Hours' : 'Weeks'),
        }
      },
      yaxis: {
        title: {
          text: 'Revenue'
        },
      },
      legend: {
        show: false
      },
      
    },
    seriesRadial: [
      {
        name: "Revenue Earned",
        data: data?.interval_date_calculation?.actual_revenue_range
      },
      {
        name: "Revenue Predicted",
        data: data?.interval_date_calculation?.predicted_revenue_range
      }
    ]
  }

  return (
    <div className={style.app}>
        <div className="radial-chart">
          <Chart
            series={state.seriesRadial}
            options={state.optionsRadial}
            type="line"
            width="100%"
            height="180"
            minHeight="200px"
          />
        </div>
        <div className={`d-flex ${style.belowLabel}`} style={{marginLeft: '25%', color: '#A0A0A0'}}>
          <div style={{width: '15rem', fontSize: '11px', fontWeight: '550'}}>
            <FontAwesomeIcon 
              className='mr-2' 
              icon={faCircle}
              style={{ 
                color: '#3A9CED',
                width: 10
              }}
            /> 
            Revenue earned
          </div>
          <div className={style.vl}></div>
          <div style={{width: '25rem', fontSize: '11px', fontWeight: '550'}}>
            <FontAwesomeIcon 
              className='mr-2' 
              icon={faCircle}
              style={{ 
                color: '#E8EBED',
                width: 10
              }}
            /> 
            Revenue Predicted
          </div>
        </div>
      </div>
  );
}

const VolumeCard = (props) => {
  const { maxDate, type } = props
  const [data, setData] = useState();
  const [permitInfoOpen, setPermitInfoOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentLabel, setCurrentLabel] = useState('Today')
  const [currentComp, setCurrentComp] = useState('yesterday')

  const state = {
    state_data: {},
    from: null,
    to: null,
    datesToFilter: [
      {
        from: moment(),
        to: null,
        label: "Today",
        name: 'yesterday',
        text: `Today (${moment().format("L")})`,
        since: "since yesterday",
      },
      {
        from: moment().startOf("isoWeek"),
        to: moment().endOf("isoWeek"),
        label: "This week",
        name: 'week',
        text: `This week (${moment()
          .startOf("isoWeek")
          .format("MM/DD")}-${moment().endOf("isoWeek").format("MM/DD")})`,
        since: "since last week",
      },
      {
        from: moment().subtract(1, 'weeks').startOf('isoWeek'),
        to: moment().subtract(1, 'weeks').endOf('isoWeek'),
        label: "Last week",
        name: 'last week',
        text: `Last week (${moment().subtract(1, 'weeks').startOf('isoWeek').format("MM/DD")}-${moment().subtract(1, 'weeks').endOf('isoWeek').format("MM/DD")})`,
        since: "since last week",
      },
      {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        label: "This month",
        name: 'month',
        text: `This month (${moment().startOf("month").format("MMM")})`,
        since: "since last month",
      },
      {
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
        label: "Last month",
        name: 'last month',
        text: `Last month (${moment().subtract(1, 'month').startOf('month').format("MMM")})`,
        since: "since last month",
      },
      {
        from: moment("01/01/2000", "YYYY/MM/DD"),
        to: moment().add(1,'days'),
        label: "All time",
        name: 'all',
        text: 'All time',
      }
    ],
    currentSinceText: "last week",
    tileInfoOpen: false,
  };

  useEffect(() => {
    if (isEmpty(data)) {
      try {
        DetailedReportApi.index({
          resource: 'volumes'
        }).then(({ data }) => {
          setData(data)
        });
      } catch (error) {
        console.log(error)
      }
    }
  }, [data]);

  const fetchData = async (from, to, value) => {
    try {
      DetailedReportApi.index({
        configs: {pie_chart: { range: { from: from, to: to}}},
        resource: 'volumes'
      }).then(({ data }) => {
        setData(data)
        setModal(false)
        setCurrentLabel(value === undefined ? 'Custom' : value?.label)
        setCurrentComp(value?.name)
      });
    } catch (error) {
    }
  };

  const toggleTileInfo = () => {
    setPermitInfoOpen(!permitInfoOpen);
  };

  return(
    <React.Fragment>
      <Card className={`${style.borderPrimary} h-100`}>
        {isEmpty(data) ? (
            <Loader />
          ) :
            <React.Fragment>
              <CardTitle className={`${style.cardTitle} row`}>
                <Col className={`${style.title} pr-0`}>
                  Volume
                  {
                    <React.Fragment>
                      <button
                        className="ml-2 border-0 bg-white text-primary"
                        id={type}
                      >
                        <FontAwesomeIcon
                          color="#C7CED9"
                          icon={faQuestionCircle}
                        />
                      </button>
                      <Popover
                        placement="bottom"
                        isOpen={permitInfoOpen}
                        target={type}
                        toggle={toggleTileInfo}
                        trigger="click hover focus"
                      >
                        <PopoverBody>Revenue Earned</PopoverBody>
                      </Popover>
                    </React.Fragment>
                  }
                </Col>
                <Col xs="auto" className="d-flex align-items-center pl-0">
                  <span className={style.secondaryText}>
                    {currentLabel}
                  </span>
                  {
                    <UncontrolledDropdown>
                      <DropdownToggle tag="span" className="pointer">
                        <ChevronDown width="18" height="18" />
                      </DropdownToggle>
                      <DropdownMenu right className={style.dateDropdown}>
                        {state.datesToFilter &&
                          state.datesToFilter.map((data) => (
                            <DropdownItem
                            className={style.dateDropdown1}
                            onClick={() =>
                              fetchData(
                                data?.from?.format("YYYY-M-D"),
                                data?.to ? data?.to?.format("YYYY-M-D") : null,
                                data
                              )
                            }
                            >
                              {data.text}
                            </DropdownItem>
                          ))}
                        <DropdownItem
                          onClick={() => setModal(true)}
                        >
                          <span className="general-text-1">
                            Select custom...
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                </Col>
              </CardTitle>
            <Row>
              <Col xs={7}>
                <BarChart 
                  {...props}
                  data={data}
                />
              </Col>
              <div className={style.vline}></div>
              <Col className={style.rightSide} xs={4}>
                <div className={style.numberContainer}>
                  <span className={style.percentageContainer}>$</span>
                  <span className='ml-1'>{data?.calculate_actual_revenue}</span>
                </div>
                <div className={style.activeContainer}>
                  <span className="mr-1">Earned</span>
              <span className={(currentComp === 'all' || currentComp === undefined) ? 'd-none' : ''}>
                {`${['yesterday', 'today'].includes(currentComp) ? 'today' : ['last week', 'last month'].includes(currentComp) ? currentComp : `this ${currentComp}`}`}
              </span>
                </div>
                <div className={`${style.progressBarOuter} progress`} >
                  <div className={`${style.progressBarInner} progress-bar`} role='progressbar' aria-valuenow={data?.previous_percentage} aria-valuemin='0' aria-valuemax='100' style={{width: data?.previous_percentage}}/>
                </div>
                <div className={(['all', 'last week', 'last month'].includes(currentComp) || currentComp === undefined) ? 'd-none' : `${style.data1Container} ml-1`}>
                  <span className={style.percentageContainer}>{data?.previous_percentage}</span>
                    <span className={`${style.userContainer} ml-1`}>
                      {`${['yesterday', 'today'].includes(currentComp) ? 'yesterday' : `last ${currentComp}`}`}
                    </span>
                </div>
                <div className={`flex-grow-1 ml-2 ${style.borderDown}`}></div>
                <div className={style.number1Container}>
                  <span className={style.percentage1Container}>$</span>
                  <span className={`mx-1 ${style.percentage2Container}`}>{data?.calculate_predicted_revenue}</span>
                  <span className={style.active1Container}>Revenue Prediction</span>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }
      </Card>
      <DateModal
        maxDate={maxDate && maxDate.toDate()}
        isOpen={modal}
        apply={fetchData}
        toggleModal={() => setModal(false)}
        title='Volume'
      />
    </React.Fragment>
  );
}

export default VolumeCard;
