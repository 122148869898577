import React, { useCallback, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Show from '../show';
import styles from './index.module.sass'
import { pay, createDispute } from 'api/webapp/citations';
import PaymentModal from '../payment_model';
import Confirmation from 'components/pages/subscriber/citations/payment_model/payment_status_model.jsx'
import CitationStatus from '../dispute_modal/citation_status_model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Dispute from '../dispute_modal';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const CitationModal = (props) => {
  const { record, openModal, modalType,setListElement, setActiveTab, setModalType, paymentStatus, setPaymentStatus, disputeStatus, setDisputeStatus } = props
  const [citation, setCitation] = useState()
  const [citationTicketStatus, setCitationTicketStatus] = useState(record?.status)
  const [state, dispatch] = useReducer(reducer, initState);
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
    setState('innerModal', null)
  }, [openModal, setActiveTab, setState])

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null); }}
    />
    // eslint-disable-next-line
  },[closeModal, setState]);

  const payCitation = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await pay({data:  data, id: record.id })
      if(response.status===200 || response.status===201){
        setListElement(response.data)
        setState('innerModal', 'confirmation')
        setPaymentStatus('success')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
      setState('innerModal', 'confirmation')
      setPaymentStatus('failure')
    }finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line
  },[setState, setListElement, record.id, closeModal, setModalType, setPaymentStatus])

  const createCitationDispute = useCallback(async (data) => {
    try {
      data.citation_ticket_id = record.id
      const response = await createDispute(data)
      if (response.status === 201){
        setState('innerModal', 'citationStatus')
        setDisputeStatus('success')
        setCitationTicketStatus(response?.data?.citation_ticket?.status)
      }
    } catch(error) {
      setState('errors', error.response.data.errors)
      setState('innerModal', 'citationStatus')
      setDisputeStatus('failure')
    }
  },[ record, setDisputeStatus, setState])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'show':
        return {maxWidth: '800px'}
      default:
        return {maxWidth}
    }
  },[modalType]);

  const innerModalProps = useCallback(() => {
    const maxWidth = 480
    switch (state.innerModal) {
      case 'payment':
        return { maxWidth: '615px'}
      case 'dispute':
        return { maxWidth: '550px', width: '98%'}
      default:
        return {maxWidth}
    }
  },[state.innerModal]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0 text-center`}>{modalProps().title}</p>
        { modalType === 'show' 
          && <Show
              record={record}
              citation={citation}
              setCitation={setCitation}
              closeModal={closeModal}
              openInnerModal={() => setState('innerModal', 'payment')}
              openDisputeModal={() => setState('innerModal', 'dispute')}
              citationTicketStatus={citationTicketStatus}/>
        }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            style={{maxWidth: innerModalProps().maxWidth, width: '98%'}}
            contentClassName={styles.appModal}
            backdropClassName={styles.backdropC}
            modalClassName={styles.modal}

          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody className={`${state.innerModal === 'payment' ? '' : styles.modalBody}`}>
              {state.innerModal === 'payment' && 
                <PaymentModal
                  payCitation={payCitation}
                  closeModals={() => {setState('innerModal', null)}}
                  record={citation}
                  isSaving={state.isSaving}
                  errors={state.errors}
                  setState={setState}
                />
              }
              {
                state.innerModal === 'confirmation' && 
                <Confirmation
                  closeModal={() => {setState('innerModal', null)}}
                  record={record}
                  paymentStatus={paymentStatus}
                />
              }
              {state.innerModal === 'dispute' && 
                <Dispute
                  createCitationDispute={createCitationDispute}
                  closeModals={() => {setState('innerModal', null)}}
                  record={record}
                  isSaving={state.isSaving}
                  errors={state.errors}
                  setState={setState}
                />
              }
              {
                state.innerModal === 'citationStatus' && 
                <CitationStatus
                  closeModal={() => {setState('innerModal', null)}}
                  record={record}
                  disputeStatus={disputeStatus}
                />
              }
            </ModalBody>
          </Modal>
        } 
      </ModalBody>
    </Modal>
  )
}

export default CitationModal;