import React, { useState, useEffect } from 'react';
import {getSubscriberAppliedTheme} from 'api/webapp/themes'
export const ThemeContext = React.createContext();

const ThemeProvider = ({ children }) => {
  const [themeColors, setThemeColors] = useState({});

  useEffect(() => {
    const fetchThemeColors = async () => {
      try {
        const response = await getSubscriberAppliedTheme();
        setThemeColors(response.data);
        updateThemeColors(response.data);
      } catch (error) {
        console.error('Error fetching theme colors:', error);
      }
    };

    fetchThemeColors();
  }, []);

  const updateThemeColors = (colors) => {

    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}_subscriber`, value);
    })
  };

  return (
    <ThemeContext.Provider value={themeColors}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;