/* Api */
import React, { useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import {  isEmpty, map } from 'underscore';
/* Base */
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Helpers */
import { editFields } from 'components/helpers/fields/event_categories/editFields'
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const attrs = {
customLabelClass: styles.formLabel,
customInputClass: styles.formInput,
}

const Edit = (props) => {
  const  {closeModal, errors, setErrors ,isSaving, editEventCategory, record } = props;
  
  const formApiRef = useRef()

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  };

  const save = async () => {
    setErrors({})
    const { values } = formApiRef.current.getState()
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    editEventCategory(values)
  };

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const validateValues = (formState) => {
    const errors = {}
     const { category_name } = formState;
     if(!category_name) { errors['category_name'] = ['Name can\'t be blank !']}
     return errors
  }


  const getFields = () => {
    return editFields({mute: false, ...attrs})
  }

  const singleFieldProps = {
    lSize: 4,
    iSize: 8,
  }

  const initialValues = { 
    category_name: record?.category_name,
    status: record?.status
  }
  

  return (
    <Container className='p-0 pt-2'>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={styles.form} initialValues={initialValues} onSubmit={save} >
          {({ formState }) => {
            
              const newFields = getFields()
              return (
                <Row className='mx-auto w-100'>
                  { map(newFields[0], (field, idx) => {
                    return (
                      <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                        { renderField(field, { ...singleFieldProps, errors} )}
                      </Col>
                    )
                  }) 
                  }
                  { map(newFields[1], (field, idx) => {
                    if(idx === 0) {
                      field = {...field, options: map(field.options, ({value, label}) => ({
                        value,
                        label: <React.Fragment>
                          <FontAwesomeIcon 
                            className='mr-2' 
                            icon={faCircle}
                            style={{ 
                              color: `${value ? 'green' : 'red'}`,
                              width: 10
                            }}
                           /> 
                          {label}
                        </React.Fragment>
                      }))}
                    }
                    return (
                      <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                        { renderField(field, { ...singleFieldProps, errors} )}
                      </Col>
                    )
                  }) 
                  }                  
                  <Col className="d-flex justify-content-center mb-3 mt-3 pt-2 pl-4" xs={12}>
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      Cancel
                    </Button>
                    <Button type='submit' className={`${styles.button} mr-5 pr-2`}  isLoading={isSaving}>
                      Save
                    </Button>
                  </Col>
                </Row>
              )
            }
          }
        </Form>
      </fieldset>
    </Container>
  )
}


export default Edit