import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'

const ActivateDeactivate = ({pauseRewardProgram, resumeRewardProgram, closeModal, status, permitName, isSaving, record}) => {
  const resumeMessage = () => (
    <React.Fragment>
      <span className="mr-1">Resuming the</span>
      <span className={styles.boldText}>{record?.name} Reward Program</span>
      <span className="mx-1">will enable the earning of points</span>
    </React.Fragment>
  );

  const pauseMessage = () => (
    <React.Fragment>
      <span className="mr-1">Pausing the</span>
      <span className={styles.boldText}>{record?.name} Reward Program</span>
      <span className="mx-1">
        will halt the earning of points. For points already earned, users wll
        still be able to redeem them.
      </span>
    </React.Fragment>
  );

  return (
    <Container className='p-0'>
    <Container className={styles.content} >
      <Col className='mb-4'>
        {record?.status === 'inactive' ?  resumeMessage() : record?.status === 'active' ? pauseMessage() : ''}
      </Col>
      <Col className="d-flex justify-content-center my-2">
        <Button onClick={() => closeModal()} type='button' className={`${styles.button} ${styles['button-bg-light']} mr-4`}>
          Cancel 
        </Button>
        {
          record?.status === 'inactive' 
            && <Button 
                type='button' 
                onClick={() => {resumeRewardProgram(); closeModal()}} 
                className={`${styles.button} ${styles['button-bg-primary']}`}
                disabled={isSaving}
              >
                Resume
              </Button>
        }
        {
          record?.status === 'active' 
            && <Button 
                type='button' 
                onClick={() => {pauseRewardProgram(); closeModal()}} 
                className={`${styles.button} ${styles['button-bg-danger']}`} 
              >
                Pause
              </Button>
        }
      </Col>
    </Container>
  </Container>
  )
}

export default ActivateDeactivate;
