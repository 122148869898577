import React, { useCallback } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
/* Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/* Components */
import MtaFetch from './mtaFetch';
import CitationTicketPdf from './citationTicketPdf'

const ViolationModal = (props) => {
  const { setState, state, record, fetchVehicleInfo } = props
  const { modalType } = state;

  const closeModal = useCallback(() => {
    setState('modalType', null)
  },[setState])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered
      className={styles.violationModal}
      style={{maxWidth: 480, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody className='mb-4'>
        { modalType === 'mta_fetch'
            && <MtaFetch closeModal={closeModal} record={record} fetchVehicleInfo={fetchVehicleInfo} />}
        { modalType === 'generate_pdf'
        && <CitationTicketPdf closeModal={closeModal} recordId={record?.citation_ticket_id} />}
      </ModalBody>
    </Modal>
  )
}

export default ViolationModal;
