import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';

const Confirmation = ({closeModal, eventType}) => {
  return (
    <Container className='p-0 m-0'>
      <Col className="d-flex justify-content-center my-2 pb-2">
        <SuccessIcon/>
      </Col>
      <span className='mx-1' style={{fontWeight:"", marginTop:"10%"}}>{eventType === 'pvt' ? "Private Event" : eventType === 'city' ?  'City Event' : ''} Created Successfully !</span>
      <Container className='mb-4' >
        <br/>
        <Col className="d-flex justify-content-center my-2">
          {
              <Button 
                  type='button' 
                  onClick={() => {closeModal()}} 
                  className={`${styles.confirmBtn}`}
                >
                  Ok
              </Button>
          }  
        </Col>
      </Container>
  </Container>
  )
}

export default Confirmation;
