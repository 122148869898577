import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { show } from 'api/permit_waiting_lists';
import Loader from 'components/helpers/loader';
import { isEmpty } from 'underscore';
import ToolBar from './toolbar';
import TableData from './tableData';

const Show = (props) => {
  const { match, isResourceFetching, location } = props

  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [showList, setShowList] = useState()
  const [callStatus, setCallStatus] = useState(false);
  
  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])
  
  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await show({id: match.params.id});
      if(response?.status === 200){
        setShowList(response?.data)
      }
      setCallStatus(true)
    }
    if (!callStatus && isEmpty(showList)) { fetchStatus() };
  },[setShowList, showList, match, setCallStatus, callStatus])

  if (isFetching()) {
    return <Loader />;
  }

  return (
    <Container className='mw-100 p-0'>
      <Row className='m-auto'>
        <Col xs={12} className='p-0'>
          <ToolBar {...props} showList={showList} openModal={openModal} activeTab={activeTab} setActiveTab={setActiveTab} />
        </Col>
        <Col xs={12} className='p-0' style={{marginBottom: '1rem', opacity: '0.8'}}>
          {location?.state?.data?.permit_type?.name}
        </Col>
        <Col xs={12} className='p-0'>
          <TableData {...props} showList={showList} setShowList={setShowList} openModal={openModal} activeTab={activeTab} modalType={modalType} />
        </Col>
      </Row>
    </Container>
  )
  
}

Show.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Show;