import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.sass'

const SlideButton = ({isActive, status='primary', className, onClick, icon, hideIcon=false, disableAnimation=false, hideIconOnHover, children}) => {
  const [openToast, setOpenToast] = useState(isActive);
  const [showIcon, setShowIcon] = useState(!hideIcon);
  const isActiveRef = useRef();

  const handleToggleToast = (val) => {
    if(isActive) return
    if(hideIconOnHover) {
      // Delay icon to display beofre transition is completed
      setTimeout(() => {
        setShowIcon(true)
        setOpenToast(val);
      }, 450)
    }
    setOpenToast(val);
  }

  const handleFocusEvent = () => {
    if(disableAnimation) return
    setOpenToast(true)
    if(hideIconOnHover) { setShowIcon(false)}
  }

  useEffect(() => {
    isActiveRef.current = isActive
    if(isActive) return
    setOpenToast(false)
    if(hideIconOnHover) { setShowIcon(true)}

  },[isActive, hideIconOnHover])

  return (
    <div
      onClick={() => {onClick && onClick(); handleFocusEvent()}}
      onMouseEnter={handleFocusEvent}
      onMouseLeave={() => handleToggleToast(false)}
      className={`${styles.button} ${styles[`button-${status}`]} ${className || ''} ${openToast ? styles.activeButton : ''} position-relative`}
    >
      {!!icon && showIcon &&
        <div className={`${styles.icon} ${openToast ? styles.activeIcon : ''}`}>
          {icon}
        </div>
      }
      {!!children &&
        <div className={styles.wrapper}>
          {children}
        </div>
      }
    </div>
  )
}

export default SlideButton;