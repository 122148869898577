import faker from 'faker';
import permissions from 'config/permissions';
import fieldsWithPermission from './fields_with_permission';

const fieldsNew = () => [
  { name: 'name', label: 'Name', mandatory: true }
];

const fieldsShow = (userPermissions) => fieldsWithPermission(
  fieldsNew(),
  userPermissions,
  permissions.UPDATE_ROLE
);

const exampleData = () => process.env.NODE_ENV !== 'production' ? {
  name: faker.name.jobTitle()
} : {};

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass=''} = attrs;

  return [
    {
      name: 'display_name',
      label: 'Role Name',
      customInputClass,
      customLabelClass,
      mandatory: true
    },
    {
      name: 'id',
      label: 'Role Id',
      customInputClass,
      customLabelClass,
      disabled: true,
    }
  ]
}

const createFields = (attrs) => {
  const { customInputClass='', customLabelClass=''} = attrs;

  return [
    {
      name: 'display_name',
      label: 'Role Name',
      customInputClass,
      customLabelClass,
      mandatory: true
    },
  ]
}

export { fieldsNew, fieldsShow, exampleData, showFields, createFields };
