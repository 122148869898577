import React, { useEffect } from 'react'
import { Col, Container } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { isEmpty } from 'underscore'
import { renderFields } from 'components/base/forms/common_form';
import { updateFields } from 'components/helpers/fields/value_added_services';

const Create = ({closeModal, createService, errors, setErrors}) => {

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { cost, name, status } = formState;
    if(!cost) { errors['cost'] = ['Cost is required']}
    if(!name) { errors['name'] = ['Name is required']}
    if(!status) { errors['status'] = ['Status is required']}
    return errors
  }

  const save = (values) => {

    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    createService(values);
  }

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fields = () => {
    return updateFields({...fieldAttrs})
  }

  return (
    <Container className='p-0'>
      <Form className={styles.form} onSubmit={save}>
        {({ formState }) => {
          const updateFields = fields()
          return (
            <Col className='mt-4'>
              <Col>
                <div >
                  {renderFields(updateFields.slice(0, 4), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-3`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton}>
                  Create
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default Create;
