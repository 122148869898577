import React, { useCallback, useEffect, useState } from 'react'
import styles from './index.module.sass'
import NavigationToolbar from 'components/base/navigationToolbar';
import { Col, Row } from 'reactstrap';
import Tabs from 'components/base/tabs';
import { ReactComponent as FilterIcon } from 'assets/infoIcon.svg';
import FilterForm from './filterForm';
import { report } from 'api/charging_sessions';
import { isEmpty } from 'underscore';
import Button from 'components/base/button'

const tabs = [
  { label: 'Active Sessions', value: 'active' },
  { label: 'History', value: 'past' },
]

const CollapseContent = ({ activeTab }) => {
  const [sessionReport, setSessionReport] = useState([])

  useEffect(() => {
    const fetchReports = async () => {
      const response = await report({perPage: 100000});
      if(response?.status === 200){
        setSessionReport(response?.data)
      }
    }

    if (isEmpty(sessionReport)) { fetchReports() };
  },[sessionReport])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm
          sessionReport={sessionReport?.charging_sessions_report}
        /> }
    </Row>
  )
}

const ToolBar = (props) => {
  const { activeTab, setActiveTab, isResourceFetching, setListType} = props;

  const handleToggleCollapse = useCallback((tab) => {
    if(isResourceFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isResourceFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start pl-0 pr-4'>
        <span className={`${styles.title} general-text-1 pr-2`}>EV Charging</span>
        <Tabs className={styles.tabs} list={tabs} defaultTab='active' onClick={setListType} />
      </Col>
      <Col className='d-flex justify-content-end px-0'>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;