import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";
import fetchApi from 'components/modules/fetch_api';

const { index, create } = resourceApi("coupons");
const search = searchApi("coupons");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params
  return index({
    page,
    perPage,
    query: {
       ...query,
       ...filters,
    },
  });
};

const importBulkCoupons = (data) => {
  return fetchApi(`dashboard/coupons/import`, { method: 'PUT', data })  
}

const filterFetcherUser = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params
  return index({
    page,
    perPage,
    query: {
       ...query,
       ...filters,
    },
  });
};

export { filterFetcher, index, search, create, importBulkCoupons, filterFetcherUser};
