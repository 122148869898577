import React, { useCallback } from 'react'
import TableRow from './tableRow';
import { show } from 'api/permit_waiting_lists';
import { filterFields } from 'components/helpers/fields/permit_applications/index';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { showList, setShowList, setRecord, filters, isResourceFetching, openModal, modalType, activeTab } = props
  if(showList?.list!==undefined){
    setShowList(showList?.list)
  }
  const renderRecords = useCallback(() => {
    return showList?.map((record) => {
      return <TableRow 
        {...props}
        key={record.id} 
        record={record}
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    });
  },[showList, modalType, setRecord, openModal, props])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={show}
      paginationQuery={filters}
      total={showList?.length}
      setList={setShowList}
      columns={
        <React.Fragment>
          <th attr="id" style={{ width: '15%', minWidth: '100px', }} >Lot Id</th>
          <th attr="name" style={{ width: '25%', minWidth: '210px', }} >Parking Lot Name</th>
          <th attr="user_enrolled" style={{ width: '25%', minWidth: '210px', }} >Users Enrolled</th>
          <th attr="spaces_reserved_for_permit" style={{ width: '25%', minWidth: '210px', }} >Spaces Reserved for permits</th>
          <th attr="last_enrolled_on" style={{ width: '25%', minWidth: '210px', }} >Last Enrollment on</th>
          <th className='d-flex justify-content-center' attr="permit_applications.actions" style={{ width: '25%', minWidth: '210px', }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
    />
  )
}

export default TableData;
