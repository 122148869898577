import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
/* API */
import { filterFetcher } from 'api/reward_types';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import connectList from 'components/modules/connect_list';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/* Actions */
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/reward_types';
import { invoke } from 'actions';
/* Components */
import ToolBar from './toolbar';
import TableData from './table_data';
import resourceFetcher from 'components/modules/resource_fetcher';
import RewardModal from '../reward_modal';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
    setActiveTab(activeTab => activeTab === modalType ? null : modalType)
  },[setModalType])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar {...props} openModal={openModal} activeTab={activeTab} setActiveTab={setActiveTab} />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} activeTab={activeTab} modalType={modalType} />
          </Col>
          <RewardModal
          modalType={modalType}
          openModal={openModal}
          {...props}
          setActiveTab={setActiveTab}
        />
        </Row>
      </Container>
    </AccessControl>
  )
  
}

const resource = 'reward_types';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD)
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    record: state.reward_types.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {},
  
);
