import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './toggle.module.sass';

const Toggle = ({ 
  value, 
  onChange,
  onClick,
  label, 
  positiveText = 'YES', 
  negativeText = 'NO', 
  className = '', 
  customToggleClass='', 
  customToggleOnClass='',
  onHighlightClass='',
  offHighlightClass='',
  disabled,
  highlightEnabled=false,
}) => {

  const [isHighlighted, setIsHighlighted] = useState(false)

  const toggleClass = () => {
    let className = styles.toggle.concat(' ', customToggleClass)
    if(value === true){
      className = className.concat(' ', styles.toggleOn, ' ', customToggleOnClass)
    }
    if(disabled){
      className = className.concat(' ', styles.disabled)
    }
    if(isHighlighted && value === true) {
      className = className.concat(' ', onHighlightClass)
    }
    if(isHighlighted && value === false) {
      className = className.concat(' ', offHighlightClass)
    }
    return className
  };

  useEffect(() => {
    setIsHighlighted(highlightEnabled)
  },[highlightEnabled])

  return (
    <div className={`d-flex align-items-center ${className}`}>
      <div
        className={toggleClass()}
        onClick={(event) => {
          setIsHighlighted(true)
          onChange && onChange(!value, event)
          onClick && onClick()
        }}
        onMouseEnter={() => setIsHighlighted(true)}
        onMouseLeave={() => { 
          if(highlightEnabled) return
          setIsHighlighted(false)
        }}
      >
        <div>
          <span>
            <span>{positiveText}</span>
          </span>
          <span>
            {negativeText}
          </span>
        </div>
      </div>
      {!!label &&
        <span className="general-text-2">{label}</span>
      }
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  positiveText: PropTypes.string,
  negativeText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default Toggle;
