import React, { useCallback, useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/permit_type_codes/fieldsNew'
/* Styles/Assets */
import styles from '../update/index.module.sass';
import withFetching from 'components/modules/with_fetching';

const attrs = {
  customLabelClass: styles.formLabel,
  customInputClass: styles.formInput,
}

const Create = (props) => {
  const  {  updatePermitTypeCode, closeModal, errors, setErrors ,isSaving, code } = props;
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const validateValues = (formState) => {
    const errors = {}
    const { code, color, description } = formState;
    if(!code) { errors['code'] = ['Code is required']}
    if(!color) { errors['color'] = ['Color is required']}
    if(!description) { errors['description'] = ['Description is required']}     
    return errors
  }
   
  const doubleFieldProps = {
    lSize: 3,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
  }
  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    updatePermitTypeCode(values);
  },[setErrors, updatePermitTypeCode]);
  
  
  const getFields = () => {
    return fieldsNew({mute: false, ...attrs})
  }

  const refinedAttrs = () => ({
    code: code?.code,
    color: code?.color,
    description: code?.description
  })
  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={styles.form} initialValues={{ ...code, ...refinedAttrs() }} onSubmit={save} >

        {({ formState }) => {
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100'>                
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className='m-0 p-0 pl-2' xs={12}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[1], { ...doubleFieldProps, errors} )}
                </Col>
  
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[2], { ...doubleFieldProps,errors,})}
                </Col>
                <Col className='d-flex justify-content-center mb-3 mt-3' xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={styles.button}  isLoading={isSaving}>
                    Save
                  </Button>
                </Col>
              </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);
