import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { filterFetcherUser } from 'api/permit_waiting_lists';
import Loader from 'components/helpers/loader';
import { isEmpty } from 'underscore';
import ToolBar from './toolbar';
import TableData from './tableData';
import WaitlistModal from '../waitlist_modal';

const User = (props) => {
  const { match, isResourceFetching, location } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [userList, setUserList] = useState()
  const [callStatus, setCallStatus] = useState(false);
  const [filters, setFilters] = useState(null)
  const [userWaitingListRecord, setuserWaitingListRecord] = useState()
  
  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])
  
  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  useEffect(() => {
    const fetchStatus = async () => {
      const filterData = {permit_type_id: location?.state?.permit_type_data?.permit_type?.id, 
                          parking_lot_id: location?.state?.parking_lot_data?.id}
      const response = await filterFetcherUser(filterData);
      if(response?.status === 200){
        setFilters(filterData)
        setUserList(response?.data)
      }
      setCallStatus(true)
    }
    if (!callStatus && isEmpty(userList)) { fetchStatus() };
  },[setUserList, userList, match, callStatus, setCallStatus, location, setFilters])

  if (isFetching()) {
    return <Loader />;
  }

  return (
    <Container className='mw-100 p-0'>
      <Row className='m-auto'>
        <Col xs={12} className='p-0'>
          <ToolBar {...props} userList={userList} openModal={openModal} activeTab={activeTab} setActiveTab={setActiveTab} setUserList={setUserList} filters={filters} setFilters={setFilters}/>
        </Col>
        <Col xs={12} className='p-0' style={{marginBottom: '1rem', marginTop: '1rem', opacity: '0.8'}}>
          Parking Lot: {location?.state?.parking_lot_data?.name}
        </Col>
        <Col xs={12} className='p-0'>
          <TableData
            {...props}
            userList={userList}
            setUserList={setUserList}
            openModal={openModal}
            activeTab={activeTab}
            modalType={modalType}
            filters={filters}
            setFilters={setFilters}
            userWaitingListRecord={userWaitingListRecord}
            setuserWaitingListRecord={setuserWaitingListRecord}
          />
        </Col>
      </Row>
      <WaitlistModal
        {...props}
        setUserList={setUserList}
        setCallStatus={setCallStatus}
        openModal={openModal}
        modalType={modalType}
        setActiveTab={setActiveTab}
        userWaitingListRecord={userWaitingListRecord}
        setuserWaitingListRecord={setuserWaitingListRecord}
      />
    </Container>
  )
  
}

User.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default User;