import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* API */
import { show } from 'api/webapp/permits';
/* Helpers */
import { extendFields, permitFields, vehicleFields, parkingLotDetails } from 'components/helpers/fields/app/permits/show';
import { capitalize, displayUnixTimestamp } from 'components/helpers';
import Loader from 'components/helpers/loader';
/* Base */
import { renderFields, renderFieldsWithGrid } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Styles */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { each, filter, map } from 'underscore';
import { useTranslation } from 'react-i18next';

const validDaysData = (days) => {
  const data = {}
  each(days, (day, idx) => {
    data[`day-${idx + 1}`] = day
  })
  return data
}


const doubleFieldProps = {
  iSize: 7,
  lSize: 5
}

const singleFieldProps = {
  iSize: 10,
  lSize: 2
}

const calculateExpiryDate = (durationString) => {
  const durationRegex = /^(\d+)\s+(\w+)$/;
  const match = durationString.match(durationRegex);

  if (!match) {
    throw new Error('Invalid duration format. Use format like "2 days" or "3 weeks".');
  }

  const duration = parseInt(match[1], 10);
  const unit = match[2].toLowerCase();

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const currentDate = new Date();

  let millisecondsToAdd;

  switch (unit) {
    case 'minute':
    case 'minutes':
      millisecondsToAdd = duration * 60 * 1000;
      break;
    case 'hour':
    case 'hours':
      millisecondsToAdd = duration * 60 * 60 * 1000;
      break;
    case 'day':
    case 'days':
      millisecondsToAdd = duration * millisecondsPerDay;
      break;
    case 'week':
    case 'weeks':
      millisecondsToAdd = duration * 7 * millisecondsPerDay;
      break;
    case 'month':
    case 'months':
      currentDate.setMonth(currentDate.getMonth() + duration);
      return currentDate.toLocaleDateString(); // Adjust formatting as needed
    default:
      throw new Error('Invalid unit provided');
  }

  const expiryDate = new Date(currentDate.getTime() + millisecondsToAdd);
  return expiryDate.toLocaleDateString(); // Adjust formatting as needed
}

const permitData = (data) => {
  const {  permit_number, expiry_date, issue_date, permit_type, status, refund_completed, payment_type, valid_days, total_cost, permit_applications } = data
  const vehicle = data?.permit_applications[0]?.vehicle
  const lots = {}
  map(permit_applications[0]?.permit_type?.permit_parking_lots, (lot, idx) => (lots[`lot-${idx}`] = {name:`${lot?.parking_lot?.name}`, id:idx}))
   
  return ({
    vehicle: {
      ...vehicle,
      plate_number: vehicle.plate_number?.toUpperCase(),
      make: capitalize(vehicle.manufacturer?.name),
      color: capitalize(vehicle.color),
      model: capitalize(vehicle.model),
      registration_state: capitalize(vehicle.registration_state),
    },
    
    permit: {
      number: permit_number,
      expires_on: expiry_date,
      issued_on: issue_date,
      type: permit_type,
      expired: status === 'expired' ? 'YES' : 'NO',
      cost: total_cost,
      payment_type,
      refund_completed,
      status,
      valid_days: map(valid_days, date => displayUnixTimestamp(new Date(date).getTime()/ 1000, 'ddd, MMM Do, YYYY'))
    },
    renewal_details: {
      validity: permit_applications[0]?.validity_period,
      start_date: new Date(),
      expiry_date: calculateExpiryDate(permit_applications[0]?.validity_period),
      parking_hour_from: permit_applications[0]?.permit_type?.parking_hour_from,
      parking_hour_to: permit_applications[0]?.permit_type?.parking_hour_to,
      hourly_rate: permit_applications[0]?.permit_type?.hourly_rate === '0.0' ? "Zero" : permit_applications[0]?.permit_type?.hourly_rate,
      cost: permit_applications[0]?.permit_type?.hourly_rate === '0.0' ? "Free" : total_cost ,
    },
    lot: lots  ,
  })
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['primary-color']} ${styles.dollarIcon}`} />
const reddollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['revoked-color']} ${styles.dollarIcon}`} />

const dotIcon = (status) => <FontAwesomeIcon icon={faCircle} className={`${styles[`${status}-color`]} ${styles.dotIcon}`} />

const  Renew = ({record, isSaving, closeModal, openInnerModal})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [permit, setPermit] = useState({})
  const { t } = useTranslation()

  const attrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    t: t,
    customIconClass: styles.calendarIcon
  }

  const renderVehicleFields = () => {
    const fields = vehicleFields(attrs)
    return(
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>{t("subscriber.my_permits.show.vehicle_details")}</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,7), {...doubleFieldProps})}
        </div>
      </Col>
    )
  }
  
  const renderOwnerFields = () => {
    const  fields = extendFields(attrs);
    fields[3] = {
      ...fields[3], 
      icon: reddollarIcon(), 
      customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
    }
    fields[4] = {
      ...fields[4], 
      icon: dollarIcon(), 
      customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
    }
    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>{t("subscriber.my_permits.show.renewal_details")}</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,4), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(4,7), {...doubleFieldProps})}
        </div>
      </Col>
    )
  }

  const renderPermitFields = useCallback(() => {
    let fields = permitFields(attrs)
    let slicePoint = 3
    const iconStatus = permit.permit?.expired === 'NO' ? 'success' : 'revoked'
    fields[4] = {...fields[4], icon: dotIcon(iconStatus), customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)}
    fields[5] = {
      ...fields[5], 
      icon: dollarIcon(), 
      customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
    }

    if(record.permit_type === 'Day Pass') {
      fields = filter(fields, (_field, idx) => [0, 2, 5].includes(idx))
      slicePoint = 2
    }

    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>{t("subscriber.my_permits.show.permit_details")}</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
        {renderFields(fields.slice(0,slicePoint), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(slicePoint), {...doubleFieldProps})}
        </div>
      </Col>
    )
  },[permit, record, attrs, t])
  

 

  useEffect(() => {
    const fetchPermit = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: record.id})
        const newPermit = permitData(response.data);
        setPermit(newPermit)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(record.id) { fetchPermit() }
  },[record.id])

  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0 my-3'>
      <div className='mb-1'>
        <p className={`${styles.title} mx-0 mb-2 p-0 text-capitalize text-center`}>Renew Permit</p>
      </div>
      <Form initialValues={{...permit, ...validDaysData(permit.permit?.valid_days)}} className={styles.form}>
        { renderPermitFields() }
        { renderVehicleFields() }
        { renderOwnerFields() }
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>{t("subscriber.my_permits.show.parking_lot_details")}</span>
          <span className="border border-2 flex-grow-1 ml-1"></span>
        </div>        
        {     
          map(permit.lot, (_lot, idx) => (
            <div key={idx}>
                <div className="d-flex align-items-center my-3 pl-1">
                  <span className={styles.subdetailsLabel}>{`${t("citation_management.parking_lot")} ${_lot.id + 1}`}</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                    {
                     renderFieldsWithGrid(parkingLotDetails({id: _lot.id, t: t}), 1, 12 ,{ ...singleFieldProps })
                    }
            </div>
          )) 
        }
        
      </Form>
      <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
        <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
          {t("buttons.cancel")}
        </Button>       
        <Button type='button' onClick={openInnerModal} className={`${styles.button} ${styles['button-bg-orange']}`}>
          {t("buttons.renew")}
        </Button>
      </Col>
    </Container>
  )
}

export default Renew;
