import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* Module */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
/* API */
import { show } from "api/event_parking_applications";
/* Helpers */
import Loader from 'components/helpers/loader';
import { displayUnixTimestamp } from 'components/helpers';
import { showFields } from 'components/helpers/fields/event_parking_applications/index';
import parkingLotDetails  from 'components/helpers/fields/event_parking_applications/parkingLotDetails';
import dateTimeDetails from 'components/helpers/fields/event_parking_applications/dateTimeDetails';
/* Base */
import Button from 'components/base/button';
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
/* Assets */
import styles from './index.module.sass'
import { map } from 'underscore'

const Show = ({ startFetching, closeModal , record, modalType, approveApplication}) => {
  const [isFetching, setIsFetching] = useState()
  const [eventApplication, setEventApplication] = useState({})
  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel
  }
  
  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }

  const applicationData = useCallback((data) => {
    const { event_dates_and_times, event_name,organizer_email, organizer_phone_number,total_available_spaces,event_parking_lots, total_cost } = data
    const lots = {}
    map(event_parking_lots, (lot, idx) => (lots[`lot-${idx}`] = {name:`${lot?.parking_lot}` , full_address:`${lot.full_address}`, available_spaces: `${lot?.alloted_spaces}` ,id:idx}))
    const dateTime = {}
    map(event_dates_and_times, (datetime, idx) => (dateTime[`eventDateTime-${idx}`] = {date:`${displayUnixTimestamp(Date.parse(datetime.date) / 1000, 'dddd, MMM Do YYYY')}`, start_time:`${datetime?.start_time}`,end_time:`${datetime.end_time}`, id:idx}))

    return ({  
        event:{
          name: event_name ,
          organizer_email: organizer_email,
          organizer_phone_number: `+${organizer_phone_number}`,
          parking_spaces: total_available_spaces,
          total_cost: `$${total_cost}`
        },

        lot: lots  ,
        eventDateTime: dateTime ,       
    })
  },[])


  const renderEventAppFields = (fields) => {
    return (
      <Col className='m-0 p-0 pl-2 pr-1'>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Event Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className='m-0 p-0 pl-2'>
          {renderFieldsWithGrid(fields, 1, 12, { ...singleFieldProps })}
        </div>
      </Col>
    )
  }

useEffect(() => {
    setIsFetching(true)
    const fetchEventApplication = async () => {
      if(!record.id)  return;
      try {
        const response = await startFetching(show({id: record.id}))
        if(response.status === 200) {
          const { data } = response;
          setEventApplication(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        
        setIsFetching(false)
      }
    };
    fetchEventApplication();

  }, [record, applicationData, startFetching])

  const fields = showFields(fieldAttrs)

  if(isFetching) { return <Loader /> }

  return (

    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{modalType === "show" ? "Event Details" : "Approve Event Parking Application"}</p>
      <Form initialValues={eventApplication} className={styles.form}>
        { renderEventAppFields(fields[0]) }

        <Col className='m-0 p-0 pl-2 pr-1'>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>Parking Lot Details</span>
            <span className="border border-2 flex-grow-1 ml-1"></span>
          </div>        
          {     
            map(eventApplication.lot, (_lot, idx) => (
              <div key={idx}>
                  <div className="d-flex align-items-center my-3 pl-2">
                    <span className={styles.subdetailsLabel}>{`Parking Lot ${_lot.id + 1}`}</span>
                    <span className="border border-2 flex-grow-1 ml-2"></span>
                  </div>
                    <Col className='pl-2 m-0 p-0'>
                      {
                       renderFieldsWithGrid(parkingLotDetails({id: _lot.id, customInputClass:styles.input, customLabelClass: styles.inputLabel}), 1, 12 ,{ ...singleFieldProps })
                      }
                    </Col>
  
              </div>
            )) 
          }
        </Col>
        <Col className='m-0 p-0 pl-2 pr-1'>
          <div className="d-flex align-items-center my-3">
              <span className={styles.detailsLabel}>Event Days</span>
              <span className="border border-2 flex-grow-1 ml-1"></span>
          </div> 
          {     
            map(eventApplication.eventDateTime, (datetime, idx) => (
              <div key={idx}>
                  <div className="d-flex align-items-center my-3 pl-2">
                    <span className={styles.subdetailsLabel}>{`Day ${datetime.id + 1}`}</span>
                    <span className="border border-2 flex-grow-1 ml-2"></span>
                  </div>
                  <Col className='pl-2 m-0 p-0'>
                      {
                       renderFieldsWithGrid(dateTimeDetails({id: datetime.id, customInputClass:styles.input, customLabelClass: styles.inputLabel}), 1, 12 ,{ ...singleFieldProps })
                      }
                  </Col>
              </div>
            )) 
           }
        </Col>

        <Col className='d-flex justify-content-center'>
          
          <Button 
            onClick={()=>  closeModal()}
            className={`${styles.cancelButton} mb-5`}
          >
            Close
          </Button>
          {
            modalType === 'approve' &&
            <Button
              type='button'
              onClick={() => {approveApplication({status: 'approved'}); closeModal()}} 
              className={`${styles.submitButton}`} 
            >
              Approve
            </Button>
          }
  
        </Col>

      </Form>
    </Container> 
  )
}

export default withFetching(withCurrentUser(Show));