import React from 'react';
import PropTypes from 'prop-types';
import index from 'components/pages/permits/index';
import Reports from 'components/pages/permits/reports';
import { Route, Switch, withRouter } from 'react-router';
import CarpoolIndex from 'components/pages/carpool_permits/index';

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={index} />
    <Route exact path={`${match.path}/car_pool`} component={CarpoolIndex} />
    <Route exact path={`${match.path}/reports`}component={Reports} />
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);
