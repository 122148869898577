import React from "react";
import { useSelector } from "react-redux";
/* API */
import { filterFetcher } from "api/violations";
/* Base */
import IndexTable from "components/base/table";
/* Modules */
import TableRow from "./tableRow";
/* Styles and Assets */
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, selectedRecordId, openModal, modalType, setSelectedRecordId, activeTab } = props;

  const filters = useSelector(state => state.violation.filters)

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          history={history}
          selectedRecordId={selectedRecordId}
          match={match}
          openModal={openModal}
          modalType={modalType}
          setSelectedRecordId={setSelectedRecordId}
        />
      );
    });
  }

  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="parking_tickets.id" style={{width: '10%', minWidth: '110px'}}>Ticket ID</th>
          <th attr="parking_violations.created_at" style={{width: '20%', minWidth: 210}}>Date</th>
          <th attr="parking_violation_types.display_name" style={{width: '20%', minWidth: 210}}>Violation Type</th>
          <th attr="parking_lots.name" style={{width: '20%', minWidth: 210}}>Parking Lot</th>
          <th attr="agencies.name" style={{width: '20%', minWidth: 210 }}>Agency Name</th>
          <th attr="officers.name" style={{width: '20', minWidth: 210}}>Officer</th>
          <th attr="parking_tickets.status" style={{width: '15%', minWidth: '158px'}}>Status</th>
          <th 
            attr="actions"
            style={{ width: '20%', minWidth: '210px', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='violations'
    />
  )
}

export default TableData;
