import React, {useState, useCallback, useMemo, useRef, useContext } from 'react';
import { Form } from 'informed';
import { Col, Row } from 'reactstrap';
/* API */
import { update } from 'api/disputes';
/* Base */
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Helpers */
import { detailedViewFields} from 'components/helpers/fields/disputes/index';
/* Styles/Assets */
import styles from '../index.module.sass';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { capitalize } from 'components/helpers';

const DisputeForm = (props) => {
  const { currentUserPermissions, state, record, setState, setRecord } = props;
  const formApiRef = useRef()
  const [issueRefund, setIssueRefund] = useState(false)
  const [amount, setAmount] = useState()
  
  const { isSaving, dropdowns } = state;
  const { addAlertMessages } = useContext(AlertMessagesContext);


  const setFormApi = formApi => {
    formApiRef.current = formApi;
  };
  const values = useMemo(() => {
    const values = Object.assign({}, record);
    values.date = record.created_at_parsed;
    values.user_name = record.username;
    values.email = record.email ;
    values.parking_lot = record.parking_lot?.name || '';
    values.dispute_type = capitalize(record.dispute_type);
    values.status = record.status;
    return values;
  },[record]);
  
  const save = useCallback(async (values) => {
    const disputeData = {
      status: values.status,
      issue_refund: record.dispute_type==='other' ? `${issueRefund}` : '',
      amount: record.dispute_type==='other' ?  parseInt(amount) : ''
    };

    try {
      setState('isSaving', true)
      const response = await update({ data: { dispute: disputeData }, id: record.id })
      if(response.status === 200){
        setRecord(response.data)
        addAlertMessages([{
            type: 'primary',
            text:  response.data.status ==="solved" ? (response.data.dispute_type === "billing"  ? `Dispute Solved. Refund Amount $${response.data?.refund_amount} refunded to ${response.data.username}`: "Dispute Solved.") : "Changes Saved",
            onlyMessage: true,
          }], 'center')
      }
    } catch (error) {
      formApiRef.current.reset()
      setState('errors', error.response?.data?.errors)
    }finally {
      setState('isSaving', false)
    }
  },[setState, setRecord, issueRefund, amount, addAlertMessages, record])

  const defaultStatus = values.status
  // eslint-disable-next-line
  const statuses = defaultStatus === 'in_progress' ? dropdowns.statuses = [{value: "in_progress", label: "In Progress"},{value:"rejected", label:"Rejected"},{value: "solved", label: "Solved"}] : dropdowns.statuses=dropdowns.statuses

  const attrs = useMemo(() => {
    return { 
      currentUserPermissions, 
      statuses,
      customInputClass: styles.formInput, 
      customLabelClass: styles.formLabel,
      customEntryTimeInputClass: styles.formEntryTimeInput,
      customExitTimeInputClass: styles.formExitTimeInput

    }
  }, [currentUserPermissions, statuses])

  const fields = detailedViewFields({...attrs,defaultStatus, customTimeInputClass: styles.formTimeInput, dispute_type: record?.dispute_type})

  return (
    <>
    {
      values.status !== '' && (
        <Form
          getApi={setFormApi}
          initialValues={values}
          onSubmit={save}
          className={`${styles.form}`}
        >
          <Row className='m-auto align-items-center'>
            <Col className={`m-0 ${styles.fieldset}`} xs={12}>
            {renderFieldsWithGrid(
            fields, 
            3, 4, 
            { iSize: 7, lSize: 5 }
          )}
          </Col>
          {record?.dispute_type==='time' && <label className={styles.entryL}>Entry Time</label>}
          {record?.dispute_type==='time' && <label className={styles.exitL}>Exit Time</label>}
          {record?.dispute_type==='other' && <Row className={styles.refundFields}>
            <Col>
              <label className={`${styles.savecheckboxText}`}>
                <input
                  type="checkbox"
                  checked={issueRefund}
                  onChange={() =>  setIssueRefund(!issueRefund)}
                  className={`${styles.customCheckBox} ${styles['checkbox-active']}`}
                />
                <span className={styles.saveCheckText}>Issue Refund</span>
                  
              </label>
              
            </Col>
            <Col className='m-0 p-0'>
              <input
                type="text"
                value={`$ ${record?.wallet_amount}`}
                disabled={true}
                className={`${styles.cost} ${styles['checkbox-active']}`}
              />
                <label className={styles.costL}>Cost</label>
            </Col>
            <Col className='m-0 p-0'>
              <input
                type="text"
                value={amount}
                onChange={(e) =>  setAmount(e.target.value)}
                disabled={false}
                className={`${styles.amount} ${styles['checkbox-active']}`}
              />
              <label className={styles.refundL}>Refund</label>
            </Col>
            </Row>}
            {values.status !== 'solved' && values.status !== "rejected" &&
              <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
              <Button
                className={`${styles.btn} ${styles['background-primary']}`}
                type='submit'
                isLoading={isSaving}
              >
                Save Changes
              </Button>
          </Col>
            }
            
          </Row>
        </Form>
      )
    }
    </>
  );
}

export default DisputeForm