import { FieldType } from 'components/helpers/form_fields';

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass='', violationTypes=[]} = attrs;


  return [
    {
      name: 'violation_type_ids',
      label: 'Select Violation Types',
      type: FieldType.MULTISELECT_FIELD,
      options: violationTypes,
      customInputClass,
      customLabelClass,
      mandatory: true,
      showToolTip: false, 
    },
    {
      name: 'repetitions_allowed',
      label: 'Maximum Repetitions',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      tooltip: 'Maximum number of repetitions before measures are taken.'
    },
    {
      name: 'measure_type',
      label: 'Measure',
      type: FieldType.SELECT_FIELD,
      options: [
        { value: "place_on_hold", label: "Place On Hold" },
        { value: "deactivate_account", label: "Deactivate Account" },
      ],
      customInputClass,
      customLabelClass,
    }
  ]
}

export { newFields };
