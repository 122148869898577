import React, { useState } from 'react';
import { Toast as BootstrapToast, Col, Row, ToastBody, ToastHeader } from 'reactstrap';
import {ReactComponent as Done} from 'assets/done_image.svg'
import {ReactComponent as Error} from 'assets/error_image.svg'

import styles from './toast.module.sass'

const Toast = (props) => {
  const { type, text, onlyMessage=false, } = props
  const [ isOpen, setIsOpen ] = useState(true)
  const renderOnlyMessage = () => {
    return (
        <ToastBody 
          className={`position-relative px-4 py-3  ${styles[`toast-${type}`]}`}
        >
          <Col xs={12} className="px-0 d-flex flex-column justify-content-center">
            <span className="text-center">
              {text}
            </span>
          </Col>
        <Row>
        </Row>
      </ToastBody>
    )
  }

  const renderWithIcon = () => {
    return (
      <ToastHeader className={`position-relative p-2 ${styles.toastHeader}`} toggle={() => { setIsOpen(false)}}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center" xs={4}>
            {type.toLocaleLowerCase() === 'success' ? <Done/> : <Error/>}
          </Col>
          <Col xs={8} className="pl-0 d-flex flex-column justify-content-center">
            <p className="mb-1 menu-points">
              {type}
            </p>
            <p className="general-text-3">
              {text}
            </p>
          </Col>
        </Row>
      </ToastHeader>
    )
  }

  return (
    <BootstrapToast className={`${onlyMessage ? 'mw-100' : '' }`} isOpen={isOpen}>
      {
        onlyMessage
          ? renderOnlyMessage()
          : renderWithIcon()
      }
    </BootstrapToast>
  )
}

export default Toast;