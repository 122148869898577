import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Disable = (props) => {

  const { disableService, closeModal, selectedRecord } = props;
  
  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='mt-3'>
          {`This will disable the "${selectedRecord?.name}" service. Users will not be able to view or request the service.`}
        </Col>
        <Col className='mb-4 mt-1'>
          Are you sure?
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          No
        </Button>
        <Button type='button' onClick={()=>  disableService()} className={styles.submitButton}>
          Yes
        </Button>
      </Col>
    </Col>
  )
}

export default Disable;