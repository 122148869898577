// import applicantDetails from "./applicantDetails";
// import applicationDetails from "./applicationDetails/index";
import eventDetails from "./eventDetails";
import parkingLotDetails from "./parkingLotDetails";


const showFields = (attrs) => {
  return [
    eventDetails(attrs),
    parkingLotDetails(attrs),
  ]
}

export default showFields;