import React, { useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { newFields } from 'components/helpers/fields/violators';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";

const Create = (props) => {
  const { startFetching, closeModal , isSaving, createMeasure, errors, setErrors }= props
  const [dropdowns, setDropdowns] = useState({
    violationTypes: [],
  })
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch('violation_type_field')
        ).then((response) => setDropdowns(state => ({...state, violationTypes: response.data })))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    violationTypes: dropdowns.violationTypes,
  }

  const singleFieldProps = {
    iSize: 7,
    lSize: 5,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { violation_type_ids, repetitions_allowed, measure_type } = formState;
    if(!violation_type_ids) { errors['violation_type_ids'] = ['Violation Type is required']}
    if(!repetitions_allowed) { errors['repetitions_allowed'] = ['Repetition Count is required']}
    if(!measure_type) { errors['measure_type'] = ['Measure Type is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    createMeasure(values)
  }

  const fields = () => {
    return newFields({...fieldAttrs})
  }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0 mt-3`}>Use the fields below to setup automatic measures against violators.</p>
      <Form className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
                <div >
                  {renderFields(newFields.slice(0, 3), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={styles.cancelButton}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                  Create
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default (Create);
