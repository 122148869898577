import React, { useCallback } from "react";
import Show from "../show";
/* Assets */
import styles from "./index.module.sass";
import { Modal } from "antd";
/* Base */
import Button from "components/base/button";

const TransactionModal = (props) => {
  const { modalType, openModal, record, getLabel } = props;

  const closeModal = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <Modal
      open={!!modalType}
      centered={true}
      classNames={{
        header: "text-center",
        footer: "d-flex justify-content-center",
      }}
      title="Payment Transaction"
      width="550px"
      wrapClassName={styles.transactionModal}
      onCancel={() => closeModal()}
      footer={[
        <Button className={styles.button} onClick={() => closeModal()}>
          Close
        </Button>,
      ]}
    >
      {modalType === "show" && (
        <Show
          selectedRecord={record}
          closeModal={closeModal}
          getLabel={getLabel}
        />
      )}
    </Modal>
  );
};

export default TransactionModal;
