import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'informed';
import CheckBox from 'components/base/check_box';

export const CheckBoxField = ({ className='', onChange, field }) => {
  const { fieldState, fieldApi } = useField({ field });
  const { setValue } = fieldApi;
  const {value} = fieldState; 

  const onCheckBoxChange = (value) => {
    setValue(value);
    onChange && onChange();
  };

  return <CheckBox
    className={className}
    label=""
    onChange={onCheckBoxChange}
    value={!!value}
  />;
};

CheckBoxField.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  field: PropTypes.string.isRequired
};

export default CheckBoxField;
