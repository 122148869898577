import faker from "faker";
import { FieldType } from "components/helpers/form_fields";
import permissions from "config/permissions";
import fieldsWithPermission from "./fields_with_permission";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const fieldsNew = (
  roles = [],
  agencies = [],
  parkingLots = [],
  roleType = ""
) => {
  const fields = [
    { name: "name", label: "Full name", mandatory: true },
    { name: "email", label: "Email", mandatory: true },
    { name: "username", label: "User name", mandatory: true },
    {
      name: "role_id",
      label: "Role",
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: roles,
    },
    { name: "phone", label: "Phone" },
    {
      name: "status",
      label: "Current status",
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: [
        { value: "active", label: "Active" },
        { value: "suspended", label: "Suspended" },
      ],
    },
  ];
  if (roleType === "Parking Operator" || roleType === "Town Manager") {
    fields.push({
      name: "parking_lot_ids",
      label: "Parking Lots",
      mandatory: false,
      type: FieldType.MULTISELECT_FIELD,
      options: parkingLots,
      entityName: "lots",
    });
  } else if (
    roleType === "Law Enforcement Agency Manager" ||
    roleType === "Law Enforcement Agency Officer"
  ) {
    fields.push({
      name: "agency_id",
      label: "Agency",
      mandatory: false,
      type: FieldType.SELECT_FIELD,
      options: agencies,
    });
  }
  return fields;
};

const fieldsShow = (roles, agencies, parkingLots, roleType, userPermissions) =>
  fieldsWithPermission(
    fieldsNew(roles, agencies, parkingLots, roleType),
    userPermissions,
    permissions.UPDATE_ADMIN
  );

  const filterFields = (attrs) => {
    const {roles, customInputClass, customLabelClass} = attrs
    return [
      {
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        customInputClass,
        customLabelClass,
      },
      {
        name: "role_names",
        label: "Role Names",
        type: FieldType.SELECT_FIELD,
        placeholder: "Choose a role name",
        options: roles,
        customInputClass,
        customLabelClass,
      },
      {
        name: "status",
        label: "Status",
        type: FieldType.SELECT_FIELD,
        options: [
          { value: "active", label: "Active" },
          { value: "suspended", label: "Suspended" },
        ],
        customInputClass,
        customLabelClass,
      },
    ];
  };

const exampleData = () =>
  process.env.NODE_ENV !== "production"
    ? {
        email: faker.internet.email(),
        username: faker.internet.userName(),
        name: `${faker.name.firstName()} ${faker.name.lastName()}`,
        phone: "+13583767678",
        status: "suspended",
      }
    : {
        status: "suspended",
      }; // These are defaults values for each field

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='', roles=[] } = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      suspended: 'red',
      active: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'id',
      label: 'User ID',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'name',
      label: 'Full Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'username',
      label: 'User Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'role_id',
      label: 'Role',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: roles,
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'password',
      label: 'Password',
      type: FieldType.PASSWORD_FIELD,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'status',
      label: 'Current Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass,
      customLabelClass,
      mandatory: true,
    }
  ]
}

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass='', roles=[], parkingLots=[], agencies=[]} = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      suspended: 'red',
      active: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'name',
      label: 'Full Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'username',
      label: 'User Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'role_id',
      label: 'Role',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: roles,
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: "parking_lot_ids",
      label: "Parking Lots",
      customInputClass,
      customLabelClass,
      type: FieldType.MULTISELECT_FIELD,
      options: parkingLots.map((parkingLot) => {
        return { value: parkingLot.value, label: parkingLot.label }
      }),
      showToolTip: false
    },
    {
      name: "agency_id",
      label: "Agency",
      customInputClass,
      customLabelClass,
      type: FieldType.SELECT_FIELD,
      options: agencies
    },
    {
      name: 'status',
      label: 'Current Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      mandatory: true,
      options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass,
      customLabelClass,
    }
  ]
}

const confirmPasswordFields = (attrs) => {
  const { customInputClass='', customLabelClass='' } = attrs;

  return [
    {
      name: 'current_user_password',
      placeholder: 'Enter password of current user',
      type: FieldType.PASSWORD_FIELD,
      customInputClass,
      customLabelClass,
    },
  ]
}

export { fieldsNew, fieldsShow, exampleData, filterFields, showFields, confirmPasswordFields, newFields };
      