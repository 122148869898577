import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
/* Components */
import TableData from './table_data';
import PermitModal from '../permit_modal';

const Index = (props) => {
  const [modalType, setModalType] = useState()

  const openModal = useCallback((modalType, status) => {
    let val
    // Evaluate modalType
    switch (true) {
      case !['revoked', 'expired', 'cancelled'].includes(status) && modalType === 'revoke':
        val = 'revoke'
        break;
      case ['show', 'edit'].includes(modalType):
        val = modalType
        break
      default:
        break;
    }
    setModalType(val)
  },[setModalType])

  const openCollapse = ['filters'].includes('')

  return (
    <AccessControl
      currentRole={props.currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} modalType={modalType} openCollapse={openCollapse} />
          </Col>
        </Row>
        <PermitModal 
          modalType={modalType} 
          openModal={openModal} 
        />
      </Container>
    </AccessControl>
  )
}

export default withCurrentUser(Index)
