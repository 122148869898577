import React, { useCallback, useRef } from "react";
import { filterFields } from "components/helpers/fields/valet_staff_fields";
import { renderFields } from "components/base/forms/common_form";
import Button from "components/base/button";
import { filterFetcher } from "api/valet_staff_management";
import { Form } from "informed";
import { Col, Row } from "reactstrap";
import styles from "./index.module.sass";
import { useSelector } from "react-redux";
import { list as selectList } from "selectors/list";

const FilterForm = (props) => {
  const { setFilters, match, setList, startFetching, parkingLots } = props;

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const filters = useSelector((state) => state.valet_staff_management.filters);

  const attrs = {
    parkingLots,
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
  };

  const filterAdmins = useCallback(
    async (filters = {}) => {
      startFetching(
        filterFetcher(Object.assign({ ...match.params }, { filters }))
      )
        .then((res) => {
          setList(selectList(res));
        })
        .catch((error) => console.log(error));
    },
    [setList, startFetching, match.params]
  );

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
    };
    setFilters(filters);
    filterAdmins(filters);
  };

  const resetFilters = () => {
    formApiRef.current.setValues({
      parking_lot_ids: "",
      role_type: "",
      status: "",
    });
    setFilters({});
    filterAdmins({});
  };

  const initialValues = {
    ...filters,
  };

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (
    <Form
      initialValues={initialValues}
      getApi={setFormApi}
      className={styles.form}
      onSubmit={save}
    >
      {({ formState }) => {
        const fields = getFields()
        return (
          <Row className='m-auto'>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(0, 1), {lSize: 5, iSize: 7})}
            </Col>
            <Col style={{maxWidth: '12.6%'}}/>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(1, 2), {lSize: 5, iSize: 7})}
            </Col>
            <Col style={{maxWidth: '12.6%'}}/>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(2, 3), {lSize: 5, iSize: 7})}
            </Col>
            <Col className="d-flex justify-content-center pt-3 m-0" xs={12}>
              <Button
                className={`${styles.btn} ${styles["background-primary"]} mr-5`}
                type="submit"
              >
                Filter
              </Button>
              <Button
                type="button"
                onClick={resetFilters}
                className={`${styles.btn} ${styles["background-secondary"]}`}
              >
                Reset
              </Button>
            </Col>
          </Row>
        );
      }}
    </Form>
  );
};

export default FilterForm;