import React, { useCallback, useRef } from 'react'
/* Helpers */
import { filterFields } from 'components/helpers/fields/parking_lots' 
/* Base */
import Button from 'components/base/button'
/* API */
import { filterFetcher } from "api/parking_lots";
/* Components */
import { Form } from 'informed'
import { Col } from 'reactstrap'
/* Assets */
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'
import { renderFieldsWithGrid } from 'components/base/forms/common_form';


const FilterForm = (props) => {
  const {parkingAdmins, townManagers ,filters, setFilters, match, setList, startFetching } = props;

  const attrs = {parkingAdmins, townManagers, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterParkingLots = useCallback(async (filters={}) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
    }
    setFilters(filters);
    filterParkingLots(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      id: '', 
      name: '', 
      phone: '', 
      email: '', 
      parking_admins:'',
      town_managers:'',
      full_address:'',
      status:''
    })
    setFilters({})
    filterParkingLots({})
  }


  const initialValues = {
    ...filters,
  }

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (
    <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        const dateFrom = formState.values.range?.from || ''
        const dateTo = formState.values.range?.to || ''
        const fields = getFields(dateFrom, dateTo)
        return <React.Fragment>
          <div lg={12}>
            {renderFieldsWithGrid(
            fields, 
            3, 4, 
            { iSize: 7, lSize: 4 }
          )}
          </div>
          <Col className='d-flex justify-content-center pt-3 m-0 mb-2' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
        </React.Fragment>
      }}
    </Form>

  )
}

export default FilterForm;