import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const PermitSettingConfirmation = (props) => {

  const { updatePermitSettings, closeModal, restrictApplication, setting, allowApplication } = props;

  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Are you sure?</p>
      <div className={styles.content}>
        {setting === 'restrict_setting' && <Col className='mt-3 mb-3'>
          This will 
          <span className={`${styles.boldText} ml-1 mr-1`}>{restrictApplication ? 'Disable' : 'Enable'}</span>
          restricting applications from accounts with open citations.
        </Col>}
        {setting === 'review_setting' && <Col className='mt-3 mb-3'>
          This will 
          <span className={`${styles.boldText} ml-1 mr-1`}>{allowApplication ? 'Disable' : 'Enable'}</span>
          allowing applications but mark them for administrative review.
        </Col>}
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        {setting === 'restrict_setting' && <Button type='button' onClick={updatePermitSettings} className={restrictApplication ? styles.disableButton : styles.enableButton}>
          {restrictApplication ? 'Disable' : 'Enable'}
        </Button>}
        {setting === 'review_setting' && <Button type='button' onClick={updatePermitSettings} className={allowApplication ? styles.disableButton : styles.enableButton}>
          {allowApplication ? 'Disable' : 'Enable'}
        </Button>}
      </Col>
    </Col>
  )
}

export default PermitSettingConfirmation;