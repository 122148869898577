import React, {useState, useCallback} from "react";
import { Col, Container, Row } from 'reactstrap';
import { SET_LIST, SET_FILTERS, SET_LIST_ELEMENT, SET_SINGLE_RECORD, POP_LIST_ELEMENT } from "actions/towings";
import { filterFetcher } from "api/booting_and_towings";
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
import { connect } from 'react-redux';
import ToolBar from 'components/pages/booting_and_towings/bootings/index/toolbar.jsx';
import TableData from './table_data'
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import BootingAndTowingModal from "../../booting_and_towing_modal";

const BootingAndTowings = (props) => {
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState()
  const [passBase64, setPassBase64] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [selectedParkingLot, setSelectedParkingLot] = useState();

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  return (
    <Container className='mw-100 p-0'>
      <Row className='m-auto'>
        <Col xs={12} className='p-0'>
          <ToolBar
            {...props}
            pageType="towings"
            activeTab={activeTab}  
            setActiveTab={setActiveTab}
            openModal={openModal} 
            modalType={modalType}
            setIsFetching={setIsFetching}
            isFetching={isFetching}
            selectedParkingLot={selectedParkingLot}
            setSelectedParkingLot={setSelectedParkingLot}
          />
        </Col>
        <Col xs={12} className='p-0'>
          <TableData 
            {...props}
            isFetching={isFetching}
            modalType={modalType}
            openModal={openModal}
            activeTab={activeTab}
            setSelectedRecord={setSelectedRecord}
          />
        </Col>
      </Row>
      <BootingAndTowingModal
        {...props}
        openModal={openModal}
        modalType={modalType}
        setActiveTab={setActiveTab}
        selectedRecord={selectedRecord}
        passBase64={passBase64}
        setPassBase64={setPassBase64}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        selectedParkingLot={selectedParkingLot}
        setSelectedParkingLot={setSelectedParkingLot}
      />
    </Container>
  )
}

const resource = 'towings';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
  }
  
  const  mapState = (state) => {
    return { 
      filters: state.towings.filters,
      record: state.towings.records.record
    }
  }
  

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(BootingAndTowings)))
);