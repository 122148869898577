import React, { useCallback, useRef } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import { emailFields } from 'components/helpers/fields/emails.js';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { isEmpty } from 'underscore'
import { renderFields } from 'components/base/forms/common_form';

const Edit = ({ closeModal, editEmail, record, errors, setErrors}) => {

  const initialValues = {
    name: record.name,
    email: record.email
  }
  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel
  }
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const formApiRef = useRef();

  const fieldProps = {
    iSize: 8,
    lSize: 4
  }

  const validateValues = (formState) => {
    let errors = {}
     const { email } = formState;
     // eslint-disable-next-line
     const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
     if(!email)  {errors['email'] = ['Email can\'t be blank']}
     if(email && !(email?.match(isValidEmail))){errors[`email`] = ['Invalid email address !']}
     return errors
   }

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(inputErrors)
      return
    };
    const data = { name: values?.name, email: ""}
    if(values?.email !== record.email){
      Object.assign(data, {email: values?.email})
    }

    editEmail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editEmail]);

  const fields = emailFields(fieldAttrs)

  return (

    <Container className={`${styles.subject} pt-3 mb-3 p-0`} >
      <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={save}>
        <div>
          {renderFields(fields?.slice(0,1), { ...fieldProps })}
        </div>
        <div>
          {renderFields(fields?.slice(1,2), { ...fieldProps, errors })}
        </div>
        <Col className="d-flex justify-content-center mb-1 mt-4" xs={12}>
          <Button onClick={closeModal} type='button' className={`${styles.btn}  ${styles['btn-cancel']} mr-4`}>
            Cancel
          </Button>
          <Button type='submit' className={styles.btn}>
            Save
          </Button>
        </Col>
      </Form>
    </Container> 
  )
}

export default withFetching(withCurrentUser(Edit));