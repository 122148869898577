import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MultiDatePicker from 'react-multi-date-picker';
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { isDate } from 'underscore';
import { asField } from 'informed';

const MultiDateInput = asField(({ fieldState, fieldApi, events = {}, ...props }) => {
  const { setValue } = fieldApi || {};
  const { value } = fieldState;
  const { className, fieldProps = {} } = props;
  const { min, showIcon = true,customInputClass ,customIconClass = '', format, placeholder, popperPlacement = 'bottom-start' } = fieldProps;

  const [selected, setSelected] = useState(value);

  const { onChange } = events;
  const onInputChange = (e) => {
    setSelected(e);
    setValue && setValue(e);
    onChange && onChange(e);
  };

 
  useEffect(() => {
    if (value === undefined) {
      setSelected([]);
      return;
    }

    const dates = Array.isArray(value) ? value.map((date) => new Date(date)) : [];
    if (dates.every(isDate)) {
      setSelected(dates);
    }
  }, [value]);

  return (
    <div className="d-flex flex-no-wrap align-items-center">
      {showIcon && <FontAwesomeIcon icon={faCalendarAlt} color="grey" className={`${styles.calenderIcon} ${customIconClass}`} />}
      <MultiDatePicker
          arrow={true}
          value={selected}
          onChange={(e) => onInputChange(e)}
          format={format || 'DD/MM/YYYY'}
          placeholder={placeholder}
          calendarClassName={`${className || ''} ${styles.input} form-control shadow-none`}
          popperPlacement={popperPlacement}
          inputClass={customInputClass}
          minDate={min ? min : ''}
          multiple
        />
    </div>
  );
});

MultiDateInput.propTypes = {
  field: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MultiDateInput;

	
