import React from 'react'
import { Col } from 'reactstrap';
/* Base */
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
/* Styles/Assets */
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import styles from './index.module.sass'

const ToolBar = (props) => {
  const { openModal, total, isFetching } = props

  return (
    <NavigationToolbar
      backTab={'violations'}
      className='justify-content-end'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span className={styles.title}>{`Violation Types (${total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
          onClick={() => {
            if(isFetching()) return
            openModal('create')
          }}
        >
          <span className='mr-3 d-inline-block' style={{width: '125px'}}>Create New Type</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
