import React, {useState, useEffect} from "react";
import { Select } from "antd";
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'
import { GoogleApiWrapper } from 'google-maps-react';
import env from '.env';
import GoogleMapsContainer from 'components/pages/findparking/google_maps/index'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getParkingLots, getParkingLot } from "api/webapp/parking_lots";
import { isEmpty } from "underscore";
import { Tabs, Card, Button, Input, Col , Row} from 'antd';
import styles from './homepage.module.sass'; // Import SASS styles
import AuthLayout from "../app/layout/auth";

const exampleData = () => process.env.NODE_ENV === 'development' ? {

  lat: '38.771665',
  lng: '-76.0762605'
} : {};

const { TabPane } = Tabs;

const App = (props) => {
  const [vehicleType, setVehicleType] = useState('')
  // eslint-disable-next-line
  const [search, setSearch] = useState()
  const [location, setLocation] = useState(exampleData)
  // eslint-disable-next-line
  const [selectedTab, setSelectedTab] = useState('map')
  // eslint-disable-next-line
  const [google, setGoogle] = useState()
  const [searchLocation, setSearchLocation] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [parkingLots, setParkingLots] = useState([])
  const [selectedParkingLot, setSelectedParkingLot] = useState(null)
  // eslint-disable-next-line
  const [parkingLot, setParkingLot] = useState()

  const handleSelect = async (address) => {
    setSearchLocation(address);
  
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLocation(latLng);
      setSelectedLocation(latLng);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };
  
  const googleMapEvents = {
    onMapClick: (locationRequest) => {
      setLocation(locationRequest)
    }
  }

  useEffect(() => {
    const fetchParkingLots = async () => {
      try {
        const response = await getParkingLots({ltd: location?.lat? location.lat : location.ltd, lng:location?.lng, vehicle_type: vehicleType});
        if(response.status === 200){
            setParkingLots(response.data);
        }
      } catch (error) {
      }
    };
    if(vehicleType && !isEmpty(location)){
      fetchParkingLots();
    }
  }, [location, vehicleType]);

  useEffect(()=> {
    const fetchParkingLot = async () => {
      try {
        const response = await getParkingLot({id: selectedParkingLot?.id});
        if(response.status === 200){
          setParkingLot(response.data);
        }
      } catch (error) {
        console.log("Error");
      }
    };
    if(!isEmpty(selectedParkingLot)){
      fetchParkingLot();
    }
  }, [selectedParkingLot])


  const handleParkingLotSelection = (lot) => {
    setSelectedParkingLot(selectedParkingLot?.id === lot?.id ? null : lot);
  };

  return (
    <AuthLayout>
      <Row className="d-flex justify-content-center align-items-flex-start m-0 p-0 pt-3">
        <Col sm={12}><p className={styles.pagetitle}>Welcome To Easton</p> </Col>
      </Row>
      <Row className={` m-0 p-0 ${styles.headerBtns}`}>
        <Col className="m-0 p-0 pr-2">
          <Button className={`${styles.featureBtn} ${styles.inputField}`} onClick={() => props.history.push("/login")}>
            <span className='general-text-1' style={{textAlign: 'center', color: 'white'}}>LOG IN</span>
          </Button>
        </Col>
        <Col className="m-0 p-0 pr-4 pl-2">
          <Button className={`${styles.featureBtn} ${styles.inputField}`} onClick={() => props.history.push("/signup")}>
            <span className='general-text-1' style={{textAlign: 'center', color: 'white'}}>SIGN UP</span>
          </Button>
        </Col>    
      </Row>
    <div className={styles.appContainer}>
      <Row className="m-0 p-0 d-flex justify-content-left text-left pt-3 pl-2">
        <p className={styles.header}>Find Parking</p>
      </Row>
      <div className={styles.inputFields}>  
        <Select
          showSearch
          placeholder="Select Vehicle Type"
          optionFilterProp="label"
          onChange={(e) => setVehicleType(e)}
          value={vehicleType? vehicleType : undefined}
          className={styles.inputField}
          options={[{value:'gasoline', label:"Gasoline"}, {value: 'electric', label: "Electric"}]}
        />      
        <PlacesAutocomplete
          value={searchLocation}
          onChange={setSearchLocation}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: '🔍  Where would you like to Park ?',
                  className: `${styles.placesAutocompleteInput}`,
                  suffix: <LocationIcon/>
                })}
              />
              <div className={styles.placesAutocompleteDropdown}>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: styles.placesAutocompleteItem,
                    })}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <Tabs  defaultActiveKey={selectedTab} style={{cardBg:'green'}} className={styles.customTabs}>
        <TabPane tab="Map View" key="map">
          <div className={styles.borderContainer}>
            <div className={styles.borderContainerInner}>
              <GoogleMapsContainer events={googleMapEvents} lat={location?.lat? location.lat : location.ltd} lng={location?.lng} markerName={"Your Location"} autoFocus={true} setGoogle={setGoogle} search={search} selectedLocation={selectedLocation} parkingLots={parkingLots}  />
             </div>
          </div>
        </TabPane>
        <TabPane tab="List View" key="list">
          {!isEmpty(parkingLots) && location && vehicleType ? <div className={styles.listContainer}>
            {parkingLots?.map((item, index) => (
              <Card key={item?.id} onClick={() => handleParkingLotSelection(item)} className={styles.listCard} bordered={false}>
                <Row className={styles.cardContent} gutter={[16, 16]} align="middle">
                  <Col className="d-flex pr-2 pl-0" span={8}>
                    <div className={styles.listItemDetails}>
                      <div className={vehicleType==='electric'? `${styles.squareWithCount} ${styles['squareWithCount-bg-secondary']}` :`${styles.squareWithCount} `}>
                        {vehicleType==='gasoline' ? `${item?.available_spaces?.gasoline}/${item?.total_spaces?.gasoline}` 
                        :vehicleType==='electric' ? `${item.available_spaces?.ev}/${item.total_spaces?.ev}` : ''}
                      </div>
                    </div>
                    <div className={styles.listItemDetails2}>
                      <div className={styles.itemTitle}>{item?.name}</div>
                    </div>
                  </Col>
                  {vehicleType==='gasoline' && <Col className={`${styles.nameCont} pl-2 pr-2`} span={4}>
                    <div className={styles.listItemDetails3}>
                      <div className={styles.itemTitle2}>{"Address"}</div>
                      <div className={styles.itemDescription}>{item?.location?.full_address}</div>
                    </div>
                  </Col>}
                  {vehicleType === 'electric' && (
                    <Col className={`${styles.nameCont} pl-2 pr-3`} span={8}>
                      <div className={styles.listItemDetails6}>
                        {item?.chargers.map((charger, idx) => (
                          <React.Fragment key={idx}>
                            <div className={styles.additionalDetails2}>{charger.name} {`(${charger.kw_rating} kW)`}</div>
                            {idx < item.chargers.length - 1  && <div className={styles.line}></div>}
                          </React.Fragment>
                        ))}
                        
                      </div>
                    </Col>
                    )}
                  
                  {vehicleType==='gasoline' && <Col className={styles.rightContent} span={4}>
                  {vehicleType==='gasoline' && <p className={styles.paid}>{`$${item?.car_rate ? item.car_rate : 0.0}`}</p>}
                  {vehicleType==='gasoline' && <p className={styles.unpaid}>{"Per Hour"}</p>}
                </Col>}
                {vehicleType==='electric' &&  <Col className={`${styles.nameCont2}`} span={4}>
                  <div className={styles.listItemDetails7}>
                    {item.chargers.map((charger,idx) => 
                      <React.Fragment key={idx}>
                        <div className={styles.additionalDetails3}>${charger?.cost_per_kwh}</div>
                        <div className={styles.perHourEl}>Per Hour</div>
                      </React.Fragment>
                    )}
                    
                  </div>
                  </Col>}
                </Row>
                {vehicleType==='electric' && <Row className={styles.listItemDetails4}>
                    <div className={styles.itemTitle}>{"Address"}</div>
                    <div className={styles.itemDescription}>{item?.location?.full_address}</div>
                    
                </Row>}
              </Card>
            ))}
          </div> : 
          <Row className="d-flex justify-content-center align-items-center text-center">
            {!vehicleType && !location ? "Please Select Location and Vehicle Type to Find Parking Lots" : (location && !vehicleType) ? "Please Select Vehicle Type" : 'No Parking Lots Found'} !
          </Row>}
        </TabPane>
      </Tabs>
    </div>
    </AuthLayout>
  );
};

export default GoogleApiWrapper({
  apiKey: env.google_cloud_api_key
})(App)