import React from 'react'
/* Base */
import NavigationToolbar from 'components/base/navigationToolbar';
import SlideButton from 'components/base/slide_button';
import { Col } from 'reactstrap';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import styles from './index.module.sass'

const ToolBar = (props) => {
  const { openModal, activeTab } = props;
  
  return (
    <NavigationToolbar>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          icon={<PlusIcon className={`${styles.addSvg} svg-white`}/>}
          onClick={() => {
            openModal('create')
          }}
          className={`${styles.addBtn} ${activeTab === 'create' ? styles.activeTab : '' }`}
        >
          <span className={styles.btnText}>Add A Disability</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;