import React, { useCallback, useRef } from 'react'
import { displayDate } from 'components/helpers'
import Button from 'components/base/button'
import { filterFetcher } from "api/disputes";
import { Form } from 'informed'
import { Col } from 'reactstrap'
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'

const FilterForm = (props) => {
  const {filters, setFilters, match, setList, startFetching } = props;

  const formApiRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterDisputes = useCallback(async (filters={}) => {
    const { range } = filters
    if(range && !range?.to) { delete range.to}
    if(range && !range?.from) { delete range.from}

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      range: {
        from: displayDate(filterValues.range?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.range?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(filters);
    filterDisputes(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      range: { from: '', to: ''}, 
      dispute_type: '', 
      parking_lot_id: '', 
      dispute_status: '', 
      user_name:''
    })
    setFilters({})
    filterDisputes({})
  }

  const initialValues = {
    ...filters,
  }

  return (
    <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        return <React.Fragment>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
        </React.Fragment>
      }}
    </Form>
  )
}

export default FilterForm;
