import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import { capitalize } from 'components/helpers';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AllowOpenCitation = (props) => {

  const { issuePermitData, issueUserPermit,  closeModal, openCitationCount } = props;
  const permitData = Object.assign(issuePermitData, {allow_open_citation: true})
  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Allow Open Citation</p>
      <div className={styles.content}>
        <Col className='mt-4 mb-2'>
          Vehicle owner with plate number <span className={styles.boldText}>{capitalize(issuePermitData.primary_user_attributes.plate_number)}</span> has {openCitationCount} open citations.
        </Col>
        <Col className='mb-2'>
          Clicking on link will be redirected to the 
          <Link to='/dashboard/parking/citation_tickets'> Citation Management </Link>
        </Col>
        <Col className='mb-2'>
          Do you want to proceed with the application?
        </Col>
      </div>
      <Col className="d-flex justify-content-center mt-4">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={() =>  issueUserPermit(permitData)} className={styles.submitButton} >
          Continue
        </Button>
      </Col>
    </Col>
  )
}

export default AllowOpenCitation;