import React from 'react'
import { useSelector } from 'react-redux';
import { map } from 'underscore';
import styles from './index.module.sass';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import Action from './action';

const TableRow = ({openModal, record, setRecord, modalType}) => {
  
  const selectedRecordId = useSelector(state => state.account_types.records.record?.id)
  
  const actions = [
    { 
      icon: <EditIcon className='mr-3'/>,
      highlightedClass: `${styles['background-secondary']} mr-3 text-white`,
      onClick: () => {openModal('edit')},
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecordId === record.id
    },
    {
      icon: <TrashIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      onClick: () => {openModal('delete')},
      text: 'Delete',
      isActive: modalType === 'delete' && selectedRecordId === record.id
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.name}</td>
      <td>{record?.created_at_parsed}</td>
      <td>{record?.subscriber_count}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>

  )
}

export default TableRow;
