import { FieldType } from "components/helpers/form_fields";

const detailsFields = (attrs) => {
  const { customInputClass='', customLabelClass='', t } = attrs;
  return [
    [
      {
        name: 'location',
        label: t("events.city_events.location"),
        disabled: true,
        customInputClass,
        customLabelClass,            
      },
      {
        name: 'description',
        label: t("events.city_events.description"),
        type: FieldType.TEXT_AREA,
        customInputClass,
        customLabelClass,
        rows: 3,
        disabled: true,
      }
    ],
  ]
  
}


const timeFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, date } = attrs;
  return [
    {
      name: `eventDateTime[eventDateTime-${date}][date]`,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: `eventDateTime[eventDateTime-${date}][time]`,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
  
}

const lotFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, idx, icon} = attrs;
  return [
    {
      name: `lot[lot-${idx}][name]`,
      customInputClass,
      customLabelClass,
      disabled: mute,
      icon: icon
    },
    
  ]
  
}


const fieldsName = [
    'zip',
    'street',
    'state',
    'country',
    'building',
    'city',
    'ltd',
    'lng'
  ];
  
  const fields = (t) => {
   return  [
      { name: 'location.ltd', label: t("events.city_events.latitude"), disabled: true },
      { name: 'location.lng', label: t("events.city_events.longitude"), disabled: true },
      { name: 'location.full_address', label: t("events.city_events.address") , disabled: true }
    ];
  } 


export { detailsFields, fields, fieldsName, timeFields, lotFields };