import React from 'react'
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* API */
import { parkingLotFilterFetcher } from 'api/special_permits';
/* Actions */
import { SET_LIST } from 'actions/reserved_parking_lots';
/* Helpers */
import { lotFilterFields } from 'components/helpers/fields/special_permits';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';

class ParkingLots extends React.Component {
  isFetching = () => {
    const { isResourceFetching } = this.props
    return isResourceFetching
  }

  renderRecords = () => {
    const { list } = this.props;
    return list.map((record, idx) => {
      return (
        <tr key={idx} >
          <td className=''>{record.id}</td>
          <td className='text-capitalize'>{record.name}</td>
          <td className='text-capitalize'>{record.location.full_address}</td>
          <td className='text-capitalize'>{record.status}</td>
          <td className='text-capitalize'>{record.spaces_count.total}</td>
          <td className='text-capitalize'>{record.reserved_spaces_count.total}</td>
        </tr>
      );
    });
  };
  
  render() {
    return (
      <Col xs={12} className='p-1'>
        <IndexTable
          {...this.props}
          className={styles.table}
          isFetching={this.isFetching}
          filterFields={lotFilterFields()}
          filterFetcher={parkingLotFilterFetcher}
          resource={resource}
          columns={
            <>
              <th attr="id" style={{width: '14%', minWidth: '100px' }} >Lot ID</th>
              <th attr="name" style={{ width: '20%', minWidth: '210px' }} >Name</th>
              <th attr="location.full_address" style={{ width: '30%', minWidth: '210px' }} >Location</th>
              <th attr="status" style={{ width: '10%', minWidth: '150px' }} >Status</th>
              <th attr="spaces_count" style={{ width: '10%', minWidth: '100px' }} >
                Total Spaces
              </th>
              <th attr="reserved_spaces"  style={{ width: '10%', minWidth: '100px' }} >
                Reserved Spaces
              </th>
            </>
          }
          renderRecords={this.renderRecords}
          entityName="Parking Lots"
        >
        </IndexTable>
      </Col>
    )
  }
}

ParkingLots.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'reserved_parking_lot';

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(parkingLotFilterFetcher, resource),
  withFetching(withCurrentUser(ParkingLots))
);
