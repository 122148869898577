import React, {useCallback} from 'react'
import { filterFetcher } from 'api/booting_and_towings';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {
  
  const { list,setRecord, filters, isResourceFetching, openModal, modalType, activeTab, setSelectedRecord, setFileUrl, setPassBase64, selectedRecord } = props

  const renderRecords = useCallback(() => {
    return list?.map((record) => {
      return <TableRow 
        key={record.id} 
        record={record} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
        setSelectedRecord={setSelectedRecord}
        setFileUrl={setFileUrl}
        setPassBase64={setPassBase64}
        selectedRecord={selectedRecord}
      />
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list, modalType, setRecord, openModal, setSelectedRecord])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <React.Fragment>
          <th attr="plate_number" style={{ width: '10%', minWidth: '150px' }}>Vehicle LPN</th>
          <th attr="booted_on" style={{ width: '17%', minWidth: '210px' }}>Booted On</th>
          <th attr="booted_by" style={{ width: '7%', minWidth: '190px' }}>Booted By</th>
          <th attr="booted_in" style={{ width: '45%', minWidth: '210px' }}>Booted In</th>
          <th attr="vehicles.manufacturer_id" style={{ width: '45%', minWidth: '210px' }}>Vehicle Manufacturer</th>
          <th attr="vehicles.model" style={{ width: '10%', minWidth: '140px' }}>Vehicle Model</th>
          <th attr="vehicles.color" style={{ width: '45%', minWidth: '190px' }}>Vehicle Color</th>
          <th 
            attr="actions"
            style={{ width: '20%', minWidth: '210px', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Bootings'
    />
  )
}

export default TableData;