import React from 'react';
import { ButtonGroup } from 'reactstrap';
import { asField } from 'informed';
import { isEmpty } from 'underscore';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const  RadioButtons = asField(({ fieldState, fieldApi, events = {}, disabled, ...props }) => {

  const {onChange, list, activeClass='', defaultClass='', className=''} = props

  const { setValue } = fieldApi;
  const {value: selected} = fieldState;

  const handleClick = (val) => {
    if (!disabled) {
      setValue(val);
      onChange && onChange(val);
    }
  };

  const buttonClassList  = (val) => {
    let classes = defaultClass.concat(' ', styles.radioButton)
    return val === selected 
      ? classes.concat(' ', activeClass, ' ', styles.active)
      : classes
  }

  return (
    <>
      {
        !isEmpty(list) && <ButtonGroup className={`${styles.buttonGroup} flex-wrap w-100`}>
          {
            list.map((item) => {
              return (
                <div 
                  key={item.value} 
                  className={`${className} ${styles.buttonWrapper} pr-3 pb-3 mr-3 text-center d-flex justify-content-between align-items-center pointer`}
                  role='button' 
                  onClick={() => handleClick(item.value)}
                >
                  <div 
                    className={`${buttonClassList(item.value)} ${className} `} disabled={disabled} 
                  >
                    { selected === item.value && <FontAwesomeIcon icon={faCircle} />}
                  </div>
                  <span className='flex-grow-1 ml-3'>{item.label}</span>
                </div>
              )
            })
          }
        </ButtonGroup>
      }
    </>
  );
})

export default RadioButtons;
