import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import FormParkingLots from '../formparkingLots';
import { filter, isEmpty, isUndefined, map } from 'underscore';
/* Base */
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { index as parkingLotIndex } from 'api/parking_lots';
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/special_permit_types'
import Loader from 'components/helpers/loader';
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';

const Edit = (props) => {
  const { parkingLots, setState, updateRecord, record, errors, isSaving, closeModal } = props
  const [isFetching, setIsFetching] = useState(false)
  const isSavingRef = useRef()
  const formApiRef = useRef()


  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const singleFieldProps = {
    lSize: 2,
    iSize: 10
  }

  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {handleInutChange(e)}
    }
  }
  
  const handleInutChange = (e) => {
    if(e?.value === 'free' && e?.label === 'Free') {
      formApiRef.current.setValue('hourly_rate', '0.0')
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { parking_hour_from: from, parking_hour_to: to, name, parking_lot_ids, payment_type, hourly_rate } = formState;
    if(!name) { errors.name = ['Name can\'t be blank']}
    if(isEmpty(parking_lot_ids)) { errors.parking_lot_ids = ['Please select at least 1 parking lot']}
    if(!to) { errors.parking_hour_to = ["Time to can't be blank"] }
    if(!from) { errors.parking_hour_from = ["Time from can't be blank"] }
    if(to && !to.match(/^\d{1,2}:\d{1,2}$/)){errors.parking_hour_to = ['Time is invalid']}
    if(from && !from.match(/^\d{1,2}:\d{1,2}$/)){errors.parking_hour_from = ['Time is invalid']}
    if(!!to && !!from && to === from) { errors.parking_hour_to = ["Parking hours cannot have the same value"] }
    if(!!to && !!from && to < from) { errors.parking_hour_from = ["Starting Parking Hour cannot be greater than Final Parking Hour"] }
    if(payment_type === 'paid' && (parseFloat(hourly_rate) <= 0 || isUndefined(hourly_rate))) { 
      errors.hourly_rate = ['Hourly reate must be greater than 0'] 
    }

    return errors
  }

  const save = (e) => {
    const {values: formValues} = formApiRef.current.getState();
    const timeErrors = validateValues(formValues)

    if(!isEmpty(timeErrors)){
      setState('errors', Object.assign(timeErrors))
      return
    };

    const parking_lots_array = map(formValues.lots, lot => {
      const { id, allocated_spaces} = lot
      return { id, reserved_spaces: allocated_spaces }
    })
    
    formValues.parking_lots_array = parking_lots_array;
    formValues.hourly_rate = formValues.hourly_rate?.toString()
    delete formValues.lots
    updateRecord(formValues)
  }

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

  const attrs = {
    customInputClass: `${styles.formInput}`,
    customLabelClass: `${styles.formLabel}`,
  }

  const initialValues = () => {
    const values = {...record}
    values.parking_lot_ids = map(values.permit_parking_lots, 
      (ppl) => (ppl?.parking_lot?.id) 
    )
    const { parking_hour_to, parking_hour_from } = values
    values.parking_hour_to = parking_hour_to?.match(/^\d{1,2}:\d{1,2}$/) ? parking_hour_to : ''
    values.parking_hour_from = parking_hour_from?.match(/^\d{1,2}:\d{1,2}$/) ? parking_hour_from : ''

    return values
  }
  
  const getFields = (validity, payment_type) => {
    return fieldsNew({mute: true, parkingLots, validity, ...attrs, payment_type})
  }

  useEffect(() => {
    setState('errors', {})
    const fetchParkingLots = async () => {
      setIsFetching(true)
      try {
        const response = await parkingLotIndex({perPage: 1000});
        setState(
          'parkingLots', 
          map(
            filter(response.data, pl => pl.spaces_count.total >= 1), 
            ({id, spaces_count, name}) => ({id, reserved_spaces: spaces_count.total, name}))
        )
      } catch (_error) {
      }finally {
        setIsFetching(false)
      }
      
    }
    if(isEmpty(parkingLots)){
      fetchParkingLots()
    }
  },[parkingLots, setState])

  useEffect(() => {
    if(isSavingRef.current === true && isSaving === false){
      if(isEmpty(errors)) { closeModal() }
    }
    isSavingRef.current = isSaving;
  },[isSaving, closeModal, errors])

  return (
    <Container className='m-0 p-0'>
      <p className={`${styles.title} mx-0 my-2 text-center`}>Edit Special Permit Type</p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} initialValues={initialValues()}  className={`${styles.form}`} onSubmit={save} >
          {({ formState }) => {
            const { validity, payment_type, parking_lot_ids} = formState.values
            const newFields = getFields(validity, payment_type)
            if(errors.parking_lots_array) {errors.parking_lot_ids = errors.parking_lots_array}

            return (
              <Row className='mx-auto w-100'>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <div className='w-100'>
                  { map(newFields[1], (field, idx) => {
                    return (
                      <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                        { renderField(field, { ...doubleFieldProps, errors} )}
                      </Col>
                    )
                  }) }
                </div>
                { map(newFields[2], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                { map(newFields[3], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <Col className='mb-3 p-0' xs={12}>
                  {isFetching && !isEmpty(record.permit_parking_lots) 
                    ? <Loader />
                    : <FormParkingLots 
                        selectedLots={parking_lot_ids} 
                        parkingLots={parkingLots}
                        formState={formState}
                        formApiRef={formApiRef}
                        permitParkingLots={record.permit_parking_lots}
                      /> 
                  }
                </Col>

                { map(newFields[4], (field, idx) => {
                  if(idx === 3) {
                    field = {
                      ...field,
                      icon: dollarIcon(), 
                      customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                    }
                  }

                  if(idx === 4) {
                    field = {...field, options: map(field.options, ({value, label}) => ({
                      value,
                      label: <>
                        <FontAwesomeIcon 
                          className='mr-2' 
                          icon={faCircle}
                          style={{ 
                            color: `${value === 'active' ? 'green' : 'red'}`,
                            width: 10
                          }}
                         /> 
                        {label}
                      </>
                    }))}
                  }
                  return (
                    <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
              <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={`${styles.button}`}   isLoading={isSaving || isFetching}>
                  Save
                </Button>
              </Col>
            </Row>
          )}
        }
      </Form>
      </fieldset>
    </Container>
  )
}

export default Edit;
