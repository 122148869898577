import { FieldType } from "components/helpers/form_fields";

const periodOptions = (type, months) => {
  switch (type) {
    case 'days':
      return [1,2,3,4,5,6].map(val => ({value: `${val} ${val === 1 ? 'day' : 'days'}`, label: `${val} ${val === 1 ? 'day' : 'days'}`}))
    case 'weeks':
      return [1,2,3].map(val => ({value: `${val} ${val === 1 ? 'week' : 'weeks'}`, label: `${val} ${val === 1 ? 'week' : 'weeks'}`}))
    case 'months':
      return months.map(val => ({value: `${val} ${val === 1 ? 'month' : 'months'}`, label: `${val} ${val === 1 ? 'month' : 'months'}`}))
    default:
      return [];
  }
}

const fieldsNew = (attrs) => {
  const { 
    mute=true, parkingLots=[], codes=[], validity='', customLabelClass='', customInputClass='', payment_type
  } = attrs
  return ([
    [
      {
        name: 'expiring_permit',
        label: 'Expiration:',
        customInputClass,
        customLabelClass,
        type: FieldType.RADIO_BUTTON_FIELD,
        mandatory: true,
        list: [
          { label: 'Expiring Permit', value: true },
          { label: 'Non-Expiring Permit', value: false }
        ]
      },
    ],
    [
      {
        name: 'category',
        label: 'Category:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'permanent', label: 'Permanent' },
          { value: 'temporary', label: 'Temporary' },
        ],
        disabled: mute,
        mandatory: true,
        tooltipWidth: '25px',
        tooltipHeight: '25px',
        tooltipClass: '',
        tooltipEnd: 'Permanent permit types are renewable and extendable by subscribers. Temporary permits can only be renewed or extended by an admin.',
        customInputClass,
        customLabelClass,
      },
      {
        name: 'commuters_per_vehicle',
        label: 'Commuters Per Vehicle',
         type: FieldType.NUMBER_FIELD,
        disabled: mute,
        mandatory: true,
        customInputClass,
        customLabelClass,
      },
      {
        name: 'validity',
        label: 'Validity:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'days', label: 'Days' },
          { value: 'weeks', label: 'Weeks' },
          { value: 'months', label: 'Months' },
        ],
        mandatory: true,
        disabled: mute,
        customInputClass,
        customLabelClass,
      },
      {
        name: 'permit_type_name',
        label: 'Type:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Car Pool',
        options: [
          { value: 'car_pool', label: 'Car Pool' },
        ],
        mandatory: true,
        disabled: true,
        customInputClass,
        customLabelClass,
      },
      {
        name: 'permit_type_code_id',
        label: 'Code:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: codes,
        showToolTip: false,
        disabled: mute,
        customInputClass,
        customLabelClass,
        showCount: false,
      },
      {
        name: 'period',
        label: 'Period:',
        type: FieldType.MULTISELECT_FIELD,
        placeholder: 'Please select',
        options: periodOptions(validity, [1,3,6]),
        mandatory: true,
        showToolTip: false,
        disabled: mute,
        customInputClass,
        customLabelClass,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_lot_ids',
        label: 'Parking Lot:',
        type: FieldType.MULTISELECT_FIELD,
        showToolTip: false,
        options: [{value: 0, label: 'All Parking Lots'}].concat(parkingLots),
        placeholder: 'Choose a Parking Lot',
        customInputClass,
        customLabelClass,
        mandatory: true,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_hour_from',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        label: 'Time from:'
      },
      {
        name: 'payment_type',
        label: 'Paid/Free:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'free', label: 'Free' },
          { value: 'paid', label: 'Paid' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'allow_cancellation',
        label: 'Allow Cancellation:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute,
      },
      {
        name: 'parking_hour_to',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        label: 'Time To:'
      },
      {
        name: 'hourly_rate',
        label: 'Hourly Rate:',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0,
        disabled: payment_type === 'free',
      },
      {
        name: 'cost',
        label: 'Cost:',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0,
        disabled: payment_type === 'free',
      },
      {
        name: 'status',
        label: 'Status:',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true
      }
    ],
  ]);
} 

export default fieldsNew;