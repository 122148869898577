import resourceApi from 'components/modules/resource_api';

const { index, destroy, create, update } = resourceApi('emails');

const filterFetcher = (params = {}) => {
  const { page, perPage, query, filters = {} } = params;

  return index({
    page,
    perPage,
    query: Object.assign({}, query, {
      types: filters.type
    })
  });
};

export { filterFetcher, update, create, destroy };
