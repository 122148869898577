import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';

const { index, create } = resourceApi('valet_parkings');

const destroy = () => {
  return fetchApi('dashboard/valet_parkings/disable_all', {method: 'DELETE'})
};

export { index, create, destroy};
