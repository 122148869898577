import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* API */
import { show } from "api/parking/citation_tickets";
/* Helpers */
import Loader from 'components/helpers/loader';
import { displayUnixTimestamp } from 'components/helpers';
import { showFields } from 'components/helpers/fields/parking_citation_tickets/showFields';
/* Base */
import Button from 'components/base/button';
import { renderFields } from 'components/base/forms/common_form';
/* Assets */
import DragScroll from 'components/helpers/dragScroll';
import { ReactComponent as ClockIcon } from 'assets/clock-icon.svg';
import { ReactComponent as PdfIcon } from 'assets/pdf-svgrepo-com.svg';
import { ReactComponent as DownloadIcon } from 'assets/download-minimalistic-svgrepo-com.svg';
import { ReactComponent as TrashIcon } from 'assets/trash_icon.svg';
import styles from './index.module.sass'
import { map } from 'underscore'
import { search as dropdownsSearch } from "api/dropdowns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";
import { ReactComponent as ChevronUp } from "assets/chevron_up.svg";
import moment from 'moment';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { useHistory } from "react-router-dom";

const Show = ({ startFetching, closeModal, record, isSaving, updateStatus, openInnerModal, setDocument, setState, openFineInnerModal, openAppealInnerModal}) => {
  const [isFetching, setIsFetching] = useState()
  const [citationTicket, setCitationTicket] = useState({})
  const [dropdowns, setDropdowns] = useState()
  const [showLog, setShowLog] = useState(false)
  const history = useHistory();

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

  useEffect(() => {
    const fetch = () => {
      Promise.all([
    
        startFetching(
          dropdownsSearch("citation_ticket_statuses_field")
        ).then((response) => setDropdowns(response.data)),

      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Image = ({ url }) => {
  return <React.Fragment>
    <img src={url} alt="" className={`${styles.imgThumb} ${styles.image}`} />
  </React.Fragment>
  };

  const VideoImage = ({values}) => {
    return (
      <div className='px-3 w-100'>
        <DragScroll>
          { map(values, ({url, date}, idx) => (
                <div className='mr-3' key={idx}>
                  { <Image url={url} /> }
                  {
                    (
                      <p className='d-flex align-items-center pt-3 m-0' style={{borderRadius: 8}}> 
                        <ClockIcon className='ml-1 mr-2'/>
                        <span className='general-text-1'>{ displayUnixTimestamp(Date.parse(date) / 1000, 'ddd, MMM Do YYYY, h:mm:ss a')}</span>
                      </p>
                    )
                  }
                </div>
              ))
            }
        </DragScroll>
      </div>
    )
  }

  const Document = ({values}) => {
    return (
      <div className='w-100'>
        { map(values, (value, idx) => (
          <div className='' key={idx}>
            <Row className={`${styles.document}`}>
              <Col className={`m-0 ${styles.documentIcon}`} xs={1}>
                <PdfIcon />
              </Col>
              <Col className={`m-0 ${styles.documentContent}`} xs={2}>
                Document 
              </Col>
              <Col className={`m-0 ${styles.button_container}`} xs={6}>
              <div  className={styles.documentButton1}>
              <a
                href={value.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon type='button' className='mr-3'/>
                </a>
              </div>
              <div className={styles.documentButton2}>
                <TrashIcon type='button' onClick={()=>{openInnerModal('delete'); setDocument(value)}} className='mr-3'/>
              </div>
              </Col>
            </Row>
          </div>
          ))
          }
      </div>
    )
  }

  const HistoryLogs = ({values}) => {
    return (
      <div className='w-100'>
        { map(values, (value, idx) => (
          <div key={idx}>
            <Row className={styles.logs}>
              <Col className={`m-0 ${styles.logdateTime}`} xs={3}>
                <Row>
                  {moment(new Date(value.created_at * 1000)).format('M/D/Y')}
                </Row>
                <Row>
                  {moment(new Date(value.created_at * 1000)).format('hh:mm:ss A')}
                </Row>
              </Col>
              <Col className={`m-0 p-0 ${styles.logContent}`} xs={9}>
                {value.message}
              </Col>
            </Row>
            {values.length-1 !== idx && <div className="border border-2 flex-grow-1 ml-2 mr-4"/>}
          </div>
          ))
          }
      </div>
    )
  }

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    statuses: dropdowns,
    icon: dollarIcon(),
    iconInputClass: styles.input.concat(' ', styles.iconInput),
    citationTicket: citationTicket
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setState('errors', {})
    }
  }
  const applicationData = useCallback((data) => {
    const { id, violation_type, created_at, updated_at, status, dispute={}, plate_number, history_logs= {}, fine, violation_id  } = data
    const images = data.violation_photos ? data.violation_photos.map(image => ({ url: image.url, date: image.created_at })) : [];
    const date = new Date(dispute?.raised_on);
    const timestamp = date.getTime()/1000;
    const documents = data.violation_documents ? data.violation_documents : [];
    
    return ({  
      id: id  ,
      violation_type: violation_type,
      fine: fine,
      violation_id: violation_id,
      created_at: displayUnixTimestamp(created_at),
      updated_at: displayUnixTimestamp(updated_at),
      status: status.toLowerCase(),
      dispute:{
        type: dispute?.type ,
        raised_on: displayUnixTimestamp(timestamp),
        raised_by: dispute?.raised_by ,
        reason: dispute?.reason
      },
      image: images,
      document: documents,
      vehicle: plate_number?.toUpperCase(),
      history_logs: history_logs?.reverse()
    })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!record.id)  return;
      try {
        const response = await show({id: record.id})
        if(response.status === 200) {
          const { data } = response;
          setCitationTicket(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();
  }, [record, applicationData, startFetching])

  const fields = showFields(fieldAttrs)

  const submitValues = useCallback((values) => {
    const data = { 
      status: values?.status
    }
    const updatedCommuterAttributes = (citationTicket?.vehicle !== values?.vehicle) ? Object.keys(data)?.reduce(() => {
      const updatedHash = data;
      const updatedValuesHash = {
        ...updatedHash,
        vehicle: {plate_number: values?.vehicle},
      };
      return updatedValuesHash
    }, {}) : data

    updateStatus(updatedCommuterAttributes)
  },[updateStatus, citationTicket])

  if(isFetching) { return <Loader /> }

  return (

    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Citation Ticket Details</p>
      <Form initialValues={citationTicket} className={styles.form} onSubmit={submitValues}>
        <Col>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>Citation Details</span>
            <span className="border border-2 flex-grow-1 ml-2"></span>
          </div>
          <div style={{ margin: '10px'}}>
            {renderFields(fields[0].slice(0, 1), {...singleFieldProps})}
            {(renderFields(fields[0].slice(1, 2), {...singleFieldProps}))}
            <EyeIcon className={styles.editIcon1} onClick={() => history.push(`/dashboard/violations/${citationTicket.violation_id}`)}/>
            {renderFields(fields[0].slice(2, 3), {...singleFieldProps})}
            {(renderFields(fields[0].slice(3, 10), {...singleFieldProps}))}
            {((citationTicket?.status !== 'settled') && (citationTicket?.status !== 'closed') )&& <EditIcon className={styles.editIcon} onClick={()=>{openFineInnerModal('editFine')}}/>}
          </div>
          {citationTicket?.dispute?.type && <div style={{ margin: '10px'}}>
            {renderFields(fields[1].slice(0, 9), {...singleFieldProps})}
          </div>}
        </Col>
        <Col>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>{`Activity History (${citationTicket?.history_logs?.length})`}</span>
            <span className="border border-2 flex-grow-1 ml-2"></span>
            <span className="pl-1" onClick={() => setShowLog(!showLog)}>
              {showLog ? <ChevronUp width="12" height="12" /> : <ChevronDown width="12" height="12" />}
            </span>
          </div>
            {showLog ? <HistoryLogs values={citationTicket.history_logs} type='Documents'/> : ''}
        </Col>
        <Col>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>Citation Images</span>
            <span className="border border-2 flex-grow-1 ml-2"></span>
          </div>
        </Col>
        <div className='mt-3 hide-scroll' style={{ margin: '10px'}}>
          <VideoImage values={citationTicket.image} type='Images' />
        </div>
        <Col>
          <div className="d-flex align-items-center my-3">
            <span className={styles.detailsLabel}>Citation Documents</span>
            <span className="border border-2 flex-grow-1 ml-2"></span>
          </div>
        </Col>
        <div className='mt-3 hide-scroll' style={{ margin: '10px'}}>
          <Document values={citationTicket.document} type='Documents' />
        </div>
        <Col className='d-flex justify-content-center'>
          <Button onClick={()=>  closeModal()} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            Cancel
          </Button>
          {!citationTicket?.dispute?.type && <Button type='button' onClick={()=>  openAppealInnerModal()} className={`${styles.button} ${styles['button-bg-danger']} mr-4`} >
            Appeal
          </Button>}
          <Button type='submit' className={styles.button} isLoading={isSaving}>
            Save
          </Button>
        </Col>
      </Form>
    </Container> 
  )
}

export default (Show);