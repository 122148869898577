import React, { useCallback, useContext, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Renew from '../renew';
import Show from '../show';
import Cancel from '../cancel'
/* Assets */
import styles from './index.module.sass'
/* API */
import { renew,cancel } from 'api/webapp/permits';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import Payment from '../renew/payment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const PermitModal = (props) => {
  const { record, openModal, modalType,setListElement, setActiveTab } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
    setState('innerModal', null)
  }, [openModal, setActiveTab, setState])

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null); closeModal()}}
    />
  },[closeModal, setState]);

  const renewPermit = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await renew({data:  data, id: record?.id })
      setListElement(response.data)
      closeModal();
      addAlertMessages([{
        type: 'primary',
        text: `${capitalize(response?.data?.permit_type)} Application Renewed Successfully.`,
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setState('errors', error.response.data.errors)
      closeModal()
      addAlertMessages([{
        type: 'danger',
        text: error?.response?.data?.errors?.permit? error?.response?.data?.errors?.permit : '',
        onlyMessage: true
      }], 'center')
    }finally {
      setState('isSaving', false)
    }
  },[setState, addAlertMessages, setListElement, record, closeModal])


  const cancelPermit = useCallback(async (id) => {
    setState('isSaving', true)
    try {
      const response = await cancel(id)
      if(response.status === 200){
        setListElement({...record, status: response.data.status})
        addAlertMessages([{
          type: 'danger',
          text: 'Permit Cancelled Successfully',
          onlyMessage: true
        }], 'center')
      }
    } catch (_error) {
      addAlertMessages([{
        type: 'notice',
        text: _error?.response.data?.errors?.status,
        onlyMessage: true
      }], 'center')
    }finally {
      setState('isSaving', false)
    }
  },[setState, setListElement, addAlertMessages, record])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'show':
        return {maxWidth: '715px'}
      case 'cancel':
        return {title: 'Cancel Parking Permit', maxWidth:'515px' }
      case 'renew':
        return { maxWidth: '715px'}
      case 'issue_permit':
        return {title: 'Issue Permit', maxWidth: '715px'}
      case 'activate_deactivate':
        return { title: `Permit Type ${record.status === 'active' ? 'Deactivation' : 'Activation' }`, maxWidth}
      default:
        return {title: '', maxWidth}
    }
  },[modalType, record]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '98%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0 text-center`}>{modalProps().title}</p>

        { modalType === 'show' && <Show record={record} closeModal={closeModal} /> }
        { modalType === 'renew' && <Renew record={record} closeModal={closeModal} openInnerModal={() => setState('innerModal', 'payment')}/> }
        {
          modalType === 'cancel' && 
          <Cancel
            state={state}
            setState={setState}
            closeModal={closeModal}
            cancelPermit={cancelPermit}
            openModal={openModal}
            isSaving={state.isSaving}
            record={record}
          />
        }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            style={{maxWidth: '615px', width: '100%'}}
          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody>
              {state.innerModal === 'payment' && 
                <Payment
                  renewPermit={renewPermit}
                  closeModals={() => {closeModal(); setState('innerModal', null)}}
                  record={record}
                  isSaving={state.isSaving}
                  errors={state.errors}
                  setState={setState}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal;