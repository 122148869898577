import { FieldType } from 'components/helpers/form_fields'

const fieldsNew = (parking_lots = [], customLabelClass='', customInputClass='', customDropdownClass='') => {
  
  return ([     
    { 
      name: 'event_name', 
      label: 'Event Name', 
      placeholder: "Enter Event Name",
      customLabelClass, 
      customInputClass 
    },
    { 
      name: 'email', 
      label: 'Email', 
      placeholder: "Enter Email",
      customLabelClass, 
      customInputClass 
    },
     
    { 
      name: 'parking_lot_ids', 
      label: 'Parking Lots', 
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      mode: "multiple",
      showToolTip: false, 
      options: [{value: 0, label: 'All Parking Lots'}].concat(parking_lots),
      placeholder: "Choose a parking lot", 
      customLabelClass, 
      customInputClass: customDropdownClass,
      showCount: true  
    },
   
    
  ])
};

const dateFields = (customIconClassFrom, customIconClassToStartDate,customIconClassToEndDate,fromIcon, toIcon, customDateToInput,customCostFromInput, customCostToInput, customDateInput, customToDateInput, customLabelClass='', customInputClass='', customDaysInput='', startDateFrom='',  startDateTo, endDateFrom, popperPlacement='bottom-end') => {
  return ([     
    [
    { 
        name: 'event_days', 
        type: FieldType.NUMBER_FIELD,
        placeholder:'Enter Number of Event Days',
        min: 1,
        customLabelClass, 
        customInputClass: customDaysInput,
    },
    {
        name: "start_date[from]",
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass: customDateInput,
        customIconClass: customIconClassFrom,
        popperPlacement,
        placeholder: 'From'
      },
      {
        name: "start_date[to]",
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass: customToDateInput,
        customIconClass: customIconClassToStartDate,
        popperPlacement,
        placeholder: 'To',
        min: startDateFrom
      },
      {
        name: "end_date[from]",
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass: customDateInput,
        customIconClass: customIconClassFrom,
        popperPlacement,
        placeholder: 'From',
      },
      {
        name: "end_date[to]",
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass: customDateToInput,
        customIconClass: customIconClassToEndDate,
        popperPlacement,
        placeholder: 'To',
        min: endDateFrom
      },
      {
        name: "cost[from]",
        type: FieldType.NUMBER_FIELD,
        customLabelClass,
        customInputClass: customCostFromInput,
        popperPlacement,
        placeholder:'From',
        min: 1,
        icon: fromIcon
      },
      {
        name: "cost[to]",
        type: FieldType.NUMBER_FIELD,
        customLabelClass,
        customInputClass: customCostToInput,
        placeholder:'To',
        popperPlacement,
        min: 1,
        icon: toIcon
      }]

  ])
};

const filterFields = () => ([
  {name: 'event_name', label: 'Event Name'},
  {name: 'email', label: 'Email'},
  {name: 'parking_lot_ids', label: 'Parking Lot'},
  {name: 'event_days', label: ''},
  {name: 'start_date[from]', label: ''},
  {name: 'start_date[to]', label: ''},
  {name: 'end_date[from]', label: ''},
  {name: 'end_date[to]', label: ''},
  {name: "cost[from]",label: ''},
  {name: "cost[to]",label: ''}
])

export { fieldsNew ,dateFields, filterFields};