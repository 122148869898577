import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Select, Col, Tag, ConfigProvider } from "antd";
import styles from "../index.module.sass";
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import { getVehicleDetails } from "api/app/apply_permit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateStep2 } from "api/app/apply_permit";
import { debounce } from "lodash";

const AddVehicle = ({
  onSave,
  manufacturers,
  closeModal,
  initialValues,
  isNewVehicle,
  t
}) => {
  const [form] = Form.useForm();
  //eslint-disable-next-line
  const [vehicle, setVehicle] = useState(null);
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false);
  const [vehicleType, setVehicleType] = useState("");
  const [vehiclesSubclasses, setVehicleSubclasses] = useState(null);
  const [plateNumberExists, setPlateNumberExists] = useState(false);
  const [errors, setErrors] = useState();

  const validatePlateNumber = async (plateNumber) => {
    setPlateNumberExists(false);
    try {
      // eslint-disable-next-line
      const response = await validateStep2({ plate_number: plateNumber });
      setPlateNumberExists(false);
    } catch (_error) {
      if (_error?.response?.data?.errors?.plate_number) {
        setPlateNumberExists(true);
      }
    }
  };

  useEffect(() => {
    if (initialValues && !isNewVehicle) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form, isNewVehicle]);

  const fetchVehicleSubclasses = async (e) => {
    try {
      const subclasses = await dropdownsSearch("vehicle_sub_classes_list", {
        vehicle_class: e,
      });
      setVehicleSubclasses(subclasses.data);
    } catch (_error) {}
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values);
        form.resetFields();
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };

  const fetchVehicleDetails = useCallback(async () => {
    const plateNumber = form.getFieldValue("plateNumber");
    setIsFetchingVehicle(true);
    try {
      const response = await getVehicleDetails(plateNumber);
      if (response.data) {
        setVehicle(response.data.vehicle_details);
        form.setFieldsValue(response.data.vehicle_details);
      }
      if (response.data?.vehicle?.toString() === "Not found") {
        setVehicle([]);
        toast.error("Vehicle Not Found !");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingVehicle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVehicle]);

  const debouncedHandleFieldChange = debounce((_, allFields) => {
    const updatedErrors = { ...errors };
    allFields.forEach((field) => {
      updatedErrors[field.name[0]] = field.errors.length > 0;
    });
    setErrors(updatedErrors);
  }, 300);

  const handleFieldChange = (_, allFields) => {
    debouncedHandleFieldChange(_, allFields);
  };

  const getLabel = (fieldName, label, required) => {
    const isError = errors?.[fieldName] || false;
    return (
      <Col className={styles.labelCol}>
        <span className={styles.formLabel}>{t(`vehicle_attributes.${label}.label`)}</span>
        {required && (
          <Tag
            className={styles.requiredMark}
            style={{ color: isError ? "rgba(251, 116, 91, 0.6)" : "#3a9ced" }}
          >
            *
          </Tag>
        )}
      </Col>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "#242E42",
            labelRequiredMarkColor: "#7ab7f3",
            labelFontSize: "13px",
            colorError: "rgba(251, 116, 91, 0.9)",
            fontFamily: "Roboto, sans-serif",
            fontSize: "13px",
          },
          Radio: {
            buttonBg: "#000000",
          },
        },
        token: {
          colorPrimary: "#1890ff",
          fontFamily: "Roboto, sans-serif",
        },
      }}
    >
      <Form
        name="signup"
        form={form}
        layout="vertical"
        colon={false}
        onFieldsChange={handleFieldChange}
        requiredMark={false}
        className={styles.form}
        labelAlign="left"
      >
        <Form.Item
          name="plateNumber"
          label={getLabel("plateNumber", "plate_number", true)}
          rules={[
            { required: true, message: t(`vehicle_attributes.plate_number.validation.required`) },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !plateNumberExists) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t(`vehicle_attributes.plate_number.validation.already_exists`))
                );
              },
            }),
          ]}
        >
          <Input.Search
            size="large"
            className={styles.formInput2}
            loading={isFetchingVehicle}
            onSearch={fetchVehicleDetails}
            onChange={(e) => validatePlateNumber(e.target.value)}
          />
        </Form.Item>

        <div className={styles.formRow}>
          <Form.Item
            name="state"
            label={getLabel("state", "state", true)}
            rules={[{ required: true, message: t(`vehicle_attributes.state.validation.required`) }]}
            className={styles.formItem}
          >
            <Input className={styles.formInput} />
          </Form.Item>
          <Form.Item
            name="color"
            label={getLabel("color", "color", true)}
            rules={[{ required: true, message: t(`vehicle_attributes.color.validation.required`) }]}
            className={styles.formItem}
          >
            <Input className={styles.formInput} />
          </Form.Item>
        </div>

        <div className={styles.formRow}>
          <Form.Item
            name="make"
            label={getLabel("make", "vehicle_make", true)}
            rules={[{ required: true, message: t(`vehicle_attributes.vehicle_make.validation.required`) }]}
            className={styles.formItem}
          >
            <Select
              options={manufacturers}
              showSearch
              optionFilterProp="label"
              className={styles.formInput}
            />
          </Form.Item>
          <Form.Item
            name="year"
            label={getLabel("year", "year", true)}
            rules={[{ required: true, message: t(`vehicle_attributes.year.validation.required`) }]}
            className={styles.formItem}
          >
            <Input type="number" min={1769} className={styles.formInputNumber} />
          </Form.Item>
        </div>
        <div className={styles.formRow}>
          <Form.Item
            name="model"
            label={getLabel("model", "model", true)}
            rules={[{ required: true, message:t(`vehicle_attributes.model.validation.required`) }]}
            className={styles.formItem}
          >
            <Input className={styles.formInput} />
          </Form.Item>
          <Form.Item
            name="category"
            label={getLabel("category", "category", true)}
            rules={[{ required: true, message: t(`vehicle_attributes.category.validation.required`) }]}
            className={styles.formItem}
          >
            <Select
              options={[
                { value: "personal", label: "Personal" },
                { value: "government", label: "Government" },
                { value: "agency", label: "Agency" },
              ]}
              showSearch
              optionFilterProp="label"
              className={styles.formInput}
            />
          </Form.Item>
        </div>
        <div className={styles.formRow}>
          <Form.Item
            name="vehicle_class"
            label={getLabel("vehicle_class", "vehicle_class", true)}
            rules={[
              { required: true, message: t(`vehicle_attributes.vehicle_class.validation.required`) },
            ]}
            className={styles.formItem}
          >
            <Select
              options={[
                { value: "car", label: "Car" },
                { value: "motorcycle", label: "Motorcycle" },
                { value: "bicycle", label: "Bicycle" },
              ]}
              showSearch
              optionFilterProp="label"
              className={styles.formInput}
              onChange={(e) => fetchVehicleSubclasses(e)}
            />
          </Form.Item>
          <Form.Item
            name="vehicle_type"
            label={getLabel("vehicle_type", "vehicle_type", true)}
            rules={[{ required: true, message: t(`vehicle_attributes.vehicle_type.validation.required`) }]}
            className={styles.formItem}
          >
            <Select
              options={[
                { value: "gasoline", label: "Gasoline" },
                { value: "electric", label: "Electric" },
              ]}
              showSearch
              optionFilterProp="label"
              className={styles.formInput}
              onChange={(e) => setVehicleType(e)}
            />
          </Form.Item>
        </div>
        <div className={styles.formRow}>
          <Form.Item
            name="vehicle_sub_class"
            label={getLabel("vehicle_sub_class", "vehicle_sub_class", false)}
            rules={[{ required: false }]}
            className={styles.formItem}
          >
            <Select
              options={vehiclesSubclasses}
              showSearch
              optionFilterProp="label"
              className={styles.formInput}
            />
          </Form.Item>
          {vehicleType === "electric" && (
            <Form.Item
              name="battery_capacity"
              label={getLabel(
                "battery_capacity",
                "battery_capacity",
                vehicleType === "electric"
              )}
              rules={[
                {
                  required: true,
                  message: t(`vehicle_attributes.battery_capacity.validation.required`),
                },
              ]}
              className={styles.formItem}
            >
              <Input className={styles.formInput} />
            </Form.Item>
          )}
        </div>
        <Form.Item className={styles.buttonContainer}>
          <Button className={styles.prevButton} onClick={closeModal}>
            {t("buttons.cancel")}
          </Button>
          <Button type="primary" className={styles.button} onClick={handleSave}>
            {t("buttons.save")}
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default AddVehicle;
