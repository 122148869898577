import React, { useState,useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { filterFetcher } from 'api/valet_staff_management.js';
import ValueAddedServiceModal from '../valet_staff_management_modal/index.jsx';
import ToolBar from 'components/pages/valet_parkings/index/toolBar.jsx'
import TableData from "./tableData.jsx";
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, ADD_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/valet_staff_management.js';
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ValetModal from '../../valetModal/index.jsx';

const Index = (props) => {
  const { isResourceFetching } = props;
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [valetStatus, setValetStatus] = useState(false)
  const [ modalType, setModalType ] = useState('')
  const [activeTab, setActiveTab] = useState()
  const [listType, setListType] = useState('staff_management');
  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  return (
    <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            {...props}
            openModal={openModal} 
            activeTab={activeTab} 
            setActiveTab={setActiveTab}
            valetStatus={valetStatus}
            setListType={setListType}
            listType={listType}
            setValetStatus={setValetStatus}
            isFetching={isResourceFetching}
          />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
              isFetching={isFetching}
            />
          </Col>
        </Row>
        <ValueAddedServiceModal
          {...props}
          openModal={openModal}
          modalType={modalType}
          setValetStatus={setValetStatus}
          selectedRecord={selectedRecord}
        />
        <ValetModal
          {...props}
          modalType={modalType} 
          openModal={openModal} 
          setValetStatus={setValetStatus}
        />
      </Container>
  );
}

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'valet_staff_management';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS),
      addListElement: invoke(ADD_LIST_ELEMENT),
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.valet_staff_management.filters,
    record: state.valet_staff_management.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  SET_FILTERS
);