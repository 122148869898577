import React from 'react';
import PropTypes from 'prop-types';
import index from 'components/pages/special_permit_types/index';

import { Route, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <Route exact path={match.path} component={index} />
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);
