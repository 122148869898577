import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Col, Card, CardTitle, CardBody, Popover, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "components/base/dropdown";
import DetailedReportApi from "api/detailed_reports";
import Loader from "components/helpers/loader";
import style from "./data_card.module.sass";
import moment from "moment";
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";

const CitationAndAppealCard = (props) => {
  const { parkingLots, defaultParkingLot, type } = props

  const state = {
    state_data: {},
    from: null,
    to: null,
    datesToFilter: [
      {
        from: moment(),
        to: null,
        label: "Today",
        name: 'today',
        text: `Today (${moment().format("L")})`,
        since: "since yesterday",
      },
      {
        from: moment().startOf("isoWeek"),
        to: moment().endOf("isoWeek"),
        label: "This week",
        name: 'this week',
        text: `This week (${moment()
          .startOf("isoWeek")
          .format("MM/DD")}-${moment().endOf("isoWeek").format("MM/DD")})`,
        since: "since last week",
      },
      {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        label: "This month",
        name: 'this month',
        text: `This month (${moment().startOf("month").format("MMM")})`,
        since: "since last month",
      },
      {
        label: "All",
        name: 'all',
        text: 'All time',
      },
    ],
    currentSinceText: "last week",
    tileInfoOpen: false,
  };
  const [currentLabel, setCurrentLabel] = useState('Today')
  const [currentComp, setCurrentComp] = useState('today')
  const [data, setData] = useState();
  const [fileInfoOpen, setFileInfoOpen] = useState(false);
  const [selectedParkingLot, setSelectedParkingLot] = useState([defaultParkingLot])
  
  useEffect(() => {
    if (!isEmpty(parkingLots) && selectedParkingLot.length === 1 && selectedParkingLot[0]?.label === "All Parking Lots") {
      setSelectedParkingLot(parkingLots);
    }
  }, [selectedParkingLot, parkingLots]);

  useEffect(() => {
    if (isEmpty(data)) {
      try{
        DetailedReportApi.index({
          resource: 'citation_and_appeals',
        }).then(({ data }) => {
            setData(data)
          });
      }catch (error) {
        console.log(error);
      }
    }
  }, [data]);

  const fetchData = async (from, to, value) => {
    const selectedParkingLotsValues = selectedParkingLot.map(
      (selectedParkingLot) => selectedParkingLot.value
    );
    try {
      DetailedReportApi.index({
        configs: {parking_lot_ids: selectedParkingLotsValues, pie_chart: { range: { from: from, to: to}}},
        resource: 'citation_and_appeals',
      }).then(({ data }) => {
        setData(data)
        setCurrentLabel(value === undefined ? 'Custom' : value?.label)
        setCurrentComp(value?.name)
      });
    } catch (error) {
      console.log(error)
    }
  };

  const updateData = (selectedParkingLots, selectAllChecked) => {
    const isUserCheckedParkingLot = selectedParkingLots?.some((parkingLot) => parkingLot.value === 0);
    const filteredSelectedParkingLots = selectedParkingLots.filter(
      (parkingLot) => parkingLot.value !== 0
    );
    const selectedParkingLotsValues = filteredSelectedParkingLots.map(
      (selectedParkingLot) => selectedParkingLot.value
    );
    setSelectedParkingLot(selectAllChecked ? (isUserCheckedParkingLot ? parkingLots : []) : filteredSelectedParkingLots);
    DetailedReportApi.index({
      configs: {parking_lot_ids: selectedParkingLotsValues},
      resource: 'citation_and_appeals',
      parkingLotIds: selectedParkingLotsValues,
    }).then(({ data }) => {
      setData(data);
    });
  };

  const toggleTileInfo = () => {
    setFileInfoOpen(!fileInfoOpen);
  };

  return(
    <React.Fragment>
      <Card className={style.borderPrimary}>
        {isEmpty(data) ? (
              <Loader />
            ) : (
              <React.Fragment>
                <Row className={`${style.cardTitle} row`}>
                <Col className={`${style.title} pr-0`}>
                  Citation and Appeals
                  {
                    <React.Fragment>
                      <button
                        className="ml-2 border-0 bg-white text-primary"
                        id={type}
                      >
                        <FontAwesomeIcon
                          color="#C7CED9"
                          icon={faQuestionCircle}
                        />
                      </button>
                      <Popover
                        placement="bottom"
                        isOpen={fileInfoOpen}
                        target={type}
                        toggle={toggleTileInfo}
                        trigger="click hover focus"
                      >
                        <PopoverBody>An overview of citation tickets and appeals.</PopoverBody>
                      </Popover>
                    </React.Fragment>
                  }
                </Col>
                <Col xs="auto" className="d-flex align-items-center pl-0" style={{marginTop: '-22px'}}>
                  <span className={style.secondaryText}>
                    {currentLabel}
                  </span>
                  {!data?.disable_date_range && 
                    <UncontrolledDropdown>
                      <DropdownToggle tag="span" className="pointer">
                        <ChevronDown width="18" height="18" />
                      </DropdownToggle>
                      <DropdownMenu right className={style.dateDropdown}>
                        {state.datesToFilter &&
                          state.datesToFilter.map((data) => (
                            <DropdownItem
                            className={style.dateDropdown1}
                              onClick={() => fetchData(
                                data?.from?.format("YYYY-M-D"),
                                data?.to ? data?.to?.format("YYYY-M-D") : null,
                                data
                              )}
                            >
                              {data.text}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                </Col>
                <Col xs="auto" className="pl-0" style={{marginTop: '-24px'}}>
                  <Dropdown
                    multiple
                    coveringText={() => coveringText(selectedParkingLot)}
                    options={parkingLots}
                    onChange={updateData}
                    value={selectedParkingLot}
                    width="150px"
                    size="sm"
                    showToolTip={false}
                    customInputClass={style.className}
                  />
                </Col>
              </Row>
              <Col className="align-content-center">
              <div className="d-flex justify-content-between" style={{paddingTop: '14px'}}>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Open
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont}>
                      {data?.citations_and_appeals?.open[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#3A9CED', fontWeight: '550'}}>
                        {data?.citations_and_appeals?.open[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
                <div className={style.vl}></div>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Settled
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont} style={{color: '#28a745'}}>
                      {data?.citations_and_appeals?.settled[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#28a745', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.settled[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
                <div className={style.vl}></div>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Pending
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont} style={{color: '#ffc107'}}>
                      {data?.citations_and_appeals?.pending[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#ffc107', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.pending[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
                <div className={style.vl}></div>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Canceled
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont} style={{color: '#e74c3c'}}>
                      {data?.citations_and_appeals?.canceled[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#e74c3c', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.canceled[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className={style.borderDown}></div>
              <div className='d-flex justify-content-between' style={{paddingTop: '15px'}}>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Sent To Court
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont}>
                      {data?.citations_and_appeals?.send_to_court[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#3A9CED', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.send_to_court[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
                <div className={style.vl}></div>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Appeal Raised
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont} style={{color: '#28a745'}}>
                      {data?.citations_and_appeals?.appeal_raised[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#28a745', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.appeal_raised[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
                <div className={style.vl}></div>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Appeal Resolved
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont} style={{color: '#ffc107'}}>
                      {data?.citations_and_appeals?.appeal_resolved[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#ffc107', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.appeal_resolved[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
                <div className={style.vl}></div>
                <Card className={style.subCard}>
                  <CardTitle className={style.cardTitle}>
                    Appeal Pending
                  </CardTitle>
                  <CardBody className={style.subBody}>
                    <div className={style.upCont} style={{color: '#e74c3c'}}>
                      {data?.citations_and_appeals?.appeal_pending[0]}
                    </div>
                    <div className={currentComp === 'all' ? 'd-none' : style.lowCont}>
                      <span style={{color: '#e74c3c', fontWeight: '500'}}>
                        {data?.citations_and_appeals?.appeal_pending[1]}
                      </span>
                      <span className={`ml-1 ${style.opacity}`}>
                        tickets {currentComp}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </div>
              </Col>
            </React.Fragment>
          )
        }
      </Card>
    </React.Fragment>
  );
}

const coveringText = (value) => {
  return( 
    <div className={style.lotContainer}>
      <span className={style.lotContainer1}>Selected</span>
      <span className={style.lotContainer2}>{value ? value.length : "0"}</span>
    </div>
  )
};

export default CitationAndAppealCard;