import { FieldType } from 'components/helpers/form_fields';

const fieldsNew = (attrs) => (
  [
    {
      name: 'name',
      label: 'Account Type Name',
      placeholder: 'Name',
      mandatory: true,
      ...attrs
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      type: FieldType.TEXT_AREA,
      mandatory: true,
      rows: 3,
      ...attrs
    }
  ]
)

export { fieldsNew };
