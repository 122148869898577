import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const SettingConfirmation = (props) => {

  const { updateCitationSetting, closeModal, createCitation } = props;

  return (
    <Col className={styles.body}>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Are you sure?</p>
      <div className={styles.content}>
        <Col className='mt-3 mb-3'>
          This will 
          <span className={`${styles.boldText} ml-1 mr-1`}>{createCitation ? 'Disable' : 'Enable'}</span>
          Citation Ticket Creation.
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={updateCitationSetting} className={createCitation ? styles.disableButton : styles.enableButton}>
          {createCitation ? 'Disable' : 'Enable'}
        </Button>
      </Col>
    </Col>
  )
}

export default SettingConfirmation;