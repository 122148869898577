import React, { useCallback, useMemo, useRef } from 'react'
import { Form } from 'informed';
import { filterFetcherUser } from 'api/permit_waiting_lists';
import { searchFields } from 'components/helpers/fields/permit_waiting_lists';
import styles from './index.module.sass';
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
import Button from 'components/base/button';

const FilterForm = (props) => {
  const { filters, match, setUserList, location, setFilters } = props

  const formApiRef = useRef()
  const setFormApi = (formApi) => formApiRef.current = formApi;

  const filterPermitapplications = useCallback(async (filters) => {
    try {
      const response = await filterFetcherUser(Object.assign({ ...match.params },
                                                { filters },
                                                ({ 
                                                  permit_type_id: location?.state?.permit_type_data?.permit_type?.id, 
                                                  parking_lot_id: location?.state?.parking_lot_data?.id
                                                })))
      if(response.status === 200){
        setUserList(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  },[setUserList, match.params, location])

  const attrs = useMemo(() => (
    {
      customInputClass: styles.formInput, 
      customLabelClass: styles.formLabel
    }
  ),[])

  const initialValues = useMemo(() =>(
    {
      ...filters,
    }
  ),[filters])

  const resetFilters = useCallback(async () => {
    formApiRef.current.setValues({
      user_id: '', 
      full_name: '', 
      email: '', 
    })
    filterPermitapplications({});
  },[filterPermitapplications]);

  const handleSearchFilters = useCallback(() => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      permit_type_id: location?.state?.permit_type_data?.permit_type?.id,
      parking_lot_id: location?.state?.parking_lot_data?.id
    }
    setFilters(filters)
    filterPermitapplications(filters);
  },[filterPermitapplications, location, setFilters])

  return (
    <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={handleSearchFilters} >
      {() => {
        return <React.Fragment>
          <div lg={12}>
            {renderFieldsWithGrid(
              searchFields({...attrs}), 
              3, 4, 
              { iSize: 7, lSize: 5 }
            )}
          </div>
          <div className='d-flex justify-content-center pt-3 pb-2'>
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
          </Button>
          </div>
        </React.Fragment>
      }}
    </Form>
  )
}

export default FilterForm;
