import React, { useEffect, useState, useCallback } from "react";
import { Card, Row, Col, ConfigProvider, Input } from "antd";
import { Card as ReactStrapCard, CardBody, Collapse } from "reactstrap";
import styles from "./index.module.sass";
import { ReactComponent as FilterIcon } from "assets/filter_icon.svg";
import Button from "components/base/button";
import { displayUnixTimestamp } from "components/helpers";
import { isEmpty } from "underscore";
import "../../permits/index/issued_permits/card.css";
import PermitApplicationModal from "../permitApplicaitonModal";
import { myPermitApplicationFetcher } from "api/webapp/permit_application";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { ReactComponent as SearchIcon } from "assets/search_icon.svg";
import CollapseContent from "./toolbar";
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import Toolbar from "../../permits/index/toolbar";
import { useTranslation } from "react-i18next";

const PermitApplications = (props) => {
  const { currentSubscriber } = props;
  const [activeTab, setActiveTab] = useState("");
  // eslint-disable-next-line
  const [showFilter, setShowFilter] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // To store the selected transaction details
  const [modalType, setModalType] = useState();
  const [initialList, setInitialList] = useState([]);
  const [list, setList] = useState(initialList);
  const [filters, setFilters] = useState("");
  const [status, setStatus] = useState("all");
  const [permitTypes, setPermitTypes] = useState([]);
  const [search, setSearch] = useState("");
  const { t } = useTranslation()

  const openModal = useCallback(
    (modalType, status) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case !["rejected", "approved", "cancelled"].includes(status) &&
          modalType === "cancel":
          val = "cancel";
          break;
        case modalType === "show":
          val = "show";
          break;
        case modalType === "apply_permit":
          val = "apply_permit";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  const handleTabChange = (e) => {
    setStatus(e);
    fetchList(e);
  };

  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await dropdownsSearch("permit_list");
        setPermitTypes(permitTypesList.data);
      } catch (_error) {}
    };
    if (isEmpty(permitTypes)) fetchPermitTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleCollapse = (tab) => {
    if (isFetching) return;
    setShowFilter(!showFilter);
    setActiveTab((activeTab) => (activeTab === tab ? null : tab));
  };

  const fetchList = useCallback(
    async (statusFilter) => {
      setIsFetching(true);
      try {
        const list = await myPermitApplicationFetcher({
          filters: statusFilter === "all" ? "" : { status: statusFilter },
        });
        setInitialList(list.data);
        setList(list.data);
      } catch (_error) {
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  useEffect(() => {
    if (isEmpty(initialList) && status === "all") {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialList, setInitialList, status]);

  const handleSearch = (value) => {
    setSearch(value);
    if (!value) {
      fetchList(status);
      return;
    }
    const filteredPermits = list?.filter((permit) =>
      Object.values(permit).some((val) =>
        typeof val === "object" && val
          ? Object?.values(val)?.some((nestedVal) =>
              nestedVal
                ?.toString()
                ?.toLowerCase()
                ?.includes(value?.toLowerCase())
            )
          : val?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
    setList(filteredPermits);
  };

  useEffect(() => {
    handleSearch(search);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const tabsList = [
    {
      key: "all",
      label: t("subscriber.my_permits.tabs.all"),
    },
    {
      key: "approved",
      label: t("subscriber.my_permit_applications.tabs.approved"),
    },
    {
      key: "incomplete",
      label: t("subscriber.my_permit_applications.tabs.incomplete"),
    },
    {
      key: "pending",
      label: t("subscriber.my_permit_applications.tabs.pending"),
    },
  ];

  const handleCardClick = (record) => {
    setSelectedRecord(record);
    openModal("show");
  };

  const handleButtonClick = (event, modalType, record) => {
    event.stopPropagation();
    setSelectedRecord(record);
    openModal(modalType, record.status);
  };

  return (
    <React.Fragment>
      <Toolbar
        listType="permit_applications"
        openModal={openModal}
        showFilters={showFilter}
        setList={setList}
        list={list}
        total={list?.length}
        t={t}
      />

      <div className={styles.container}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#3A9CED",
            },
          }}
        >
          <Card
            style={{
              width: "100%",
            }}
            tabList={tabsList}
            activeTabKey={status}
            tabBarExtraContent={
              <Col className="d-flex justify-content-end pr-1">
                <Input
                  suffix={<SearchIcon />}
                  className={styles.filterInput}
                  placeholder={t("subscriber.search_placeholder")}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  onClick={() => handleToggleCollapse("filters")}
                  className={`${styles.btnFilter} ${
                    activeTab === "filters" ? styles.activeTab : ""
                  }`}
                  icon={
                    <FilterIcon
                      className={`${
                        activeTab === "filters" ? styles.activeIcon : ""
                      }`}
                    />
                  }
                  size="md"
                />
              </Col>
            }
            onTabChange={handleTabChange}
            tabProps={{
              size: "large",
            }}
            className={styles.mainCard}
          >
            {showFilter && (
              <Collapse isOpen={showFilter} className={styles.collapse}>
                <ReactStrapCard className="border-0">
                  <CardBody className="p-0 m-0">
                    <CollapseContent
                      activeTab={activeTab}
                      filters={filters}
                      setFilters={setFilters}
                      setList={setList}
                      setIsResourceFetching={setIsFetching}
                      permitTypes={permitTypes}
                      t={t}
                    />
                  </CardBody>
                </ReactStrapCard>
              </Collapse>
            )}
            {!isEmpty(list) &&
              list?.map((item, index) => (
                <Card
                  key={index}
                  className={styles.card}
                  loading={isFetching}
                  hoverable={true}
                  onClick={() => handleCardClick(item)}
                >
                  <Row className={styles.rowContent} gutter={[16, 16]}>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={3}
                      className={styles.hideOnMobile}
                    >
                      <div className={styles.label}>{t("subscriber.my_permit_applications.submitted_on")}</div>
                      <div className={styles.detail}>
                        {<span>{displayUnixTimestamp(item?.created_at)}</span>}
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                      <div className={styles.label}>{t("permit_application.apply_for_permit.labels.step3.permit_type")}</div>
                      <div className={styles.detail}>{item?.permit_type}</div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4}>
                      <div className={styles.label}>{t("subscriber.my_permit_applications.application_type")}</div>
                      <div className={styles.detail}>
                        {item?.application_type}
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4}>
                      <div className={styles.label}>{t("permit_application.apply_for_permit.labels.step2.vehicle_lpn.label")}</div>
                      <div className={styles.detail}>
                        {item?.primary_user?.plate_number}
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                      <div className={styles.label}>{t("events.private_events.status")}</div>
                      <div className={styles.detail}>{item?.status}</div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                      <div className={styles.label}>{t("subscriber.my_permit_applications.invited_users")}</div>
                      <div className={styles.detail}>
                        {item?.no_of_invited_users}
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={2}>
                      <div className={styles.label}>{t("events.private_events.cost")}</div>
                      <div className={styles.detail}>{item?.total_cost}</div>
                    </Col>
                    <Col className="text-center" xs={4} sm={4} md={4} lg={2}>
                      <div className={styles.label}>{t("subscriber.actions")}</div>
                      <div className={styles.actions}>
                        <Button
                          className={
                            item.status === "pending"
                              ? styles.cancel
                              : `${styles.cancel} ${styles["button-bg-secondary"]}`
                          }
                          onClick={(e) => handleButtonClick(e, "cancel", item)}
                        >
                          {t("buttons.cancel")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className={styles.dateTime}>
                  {t("subscriber.my_permit_applications.submitted_on")}
                    <span className="pl-1">
                      {displayUnixTimestamp(item?.created_at)}
                    </span>
                  </div>
                </Card>
              ))}
            {isEmpty(list) && !isFetching && (
              <Row className="d-flex justify-content-center text-center align-items-center pt-4 mt-3">
                <p>{t("no_results_found")}</p>
              </Row>
            )}
          </Card>
        </ConfigProvider>
        <PermitApplicationModal
          record={selectedRecord}
          openModal={openModal}
          modalType={modalType}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentSubscriber={currentSubscriber}
        />
      </div>
    </React.Fragment>
  );
};

export default withCurrentSubscriber(PermitApplications);