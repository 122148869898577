import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { list as selectList } from 'selectors/list';
/* Base */
import NavigationToolbar from 'components/base/navigationToolbar';
import Button from 'components/base/button';
/* Components */
import { Col, Row } from 'reactstrap';
import ViolationDetails from './violationDetails';
import FilterForm from './filterForm'
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { ReactComponent as ViolationIcon } from 'assets/menu_icons/violations_icon.svg';
/* Actions */
import { SET_LIST } from 'actions/activity_logs';
/* API */
import { filterFetcherActivityLogs } from 'api/violations';

const CollapseContent = (props) => {
  const { startFetching, match, state, setState } = props
  const dispatch = useDispatch()
  const setList = useCallback((list) => dispatch({type: SET_LIST, payload: list}), [dispatch])


  const filterActivityLogs = useCallback(async (filters={}) => {
    const { range } = filters
    if(range && !range?.to) { delete range.to}
    if(range && !range?.from) { delete range.from}

    startFetching(filterFetcherActivityLogs(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  return (
    <Row className='m-auto bg-grey-light' style={{minHeight: 180}}>
      { state.activeTab === 'filters' && <FilterForm {...state.dropdowns} filters={state.filters} setState={setState} filterActivityLogs={filterActivityLogs} />}
      { state.activeTab === 'violation' && <ViolationDetails {...props} filterActivityLogs={filterActivityLogs} /> }
    </Row>
  )
}

const ToolBar = (props) => {
  const {state, setState, record } = props
  const activityCount = useSelector(store => store.activity_log.index.total)
  const { activeTab } = state;
  
  const handleToggleCollapse = useCallback((tab) => {
    setState('activeTab', activeTab === tab ? null : tab)
  },[activeTab, setState])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent {...props} />
      }
      backTab='/dashboard/violations'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center ml-2'>
        <span>{`Activity Logs (${activityCount})`}</span>
      </Col>
      <Col className='d-flex justify-content-end px-0'>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
        <Button
          onClick={() => handleToggleCollapse('violation')}
          className={`${styles.violationButton} ${activeTab === 'violation' ? styles.activeTab : '' }`}
          size="md"
          icon={<ViolationIcon className={`${activeTab === 'violation' ? '' : styles.icon} ml-n4 ${styles['icon-fade']}`} />}
        >
          {`ID ${record.id}`}
        </Button>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
