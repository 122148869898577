import React from 'react';
import PropTypes from 'prop-types';
import Index from 'components/pages/themes/index';
import New from 'components/pages/themes/new';
import Edit from 'components/pages/themes/edit'
import renderWithBackPath from 'components/modules/render_with_back_path';

import { Route, Switch, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={Index} />
    <Route exact path={`${match.path}/new/`} render={renderWithBackPath(New, `${match.url}/`)} />
    <Route path={`${match.path}/:id`} render={(props) => (
      <React.Fragment>
        <Route exact path={props.match.path} component={renderWithBackPath(Edit, `${match.url}/`)} />
      </React.Fragment>
    )} />
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);