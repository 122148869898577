import { EventCategoriesActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(EventCategoriesActions);

const EventCategoriesReducers = combineReducers({
  index,
  records,
});

export default EventCategoriesReducers;
