import { PermitApplicationActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(PermitApplicationActions);

const PermitapplicationReducers = combineReducers({
  index,
  records,
  filters
});

export default PermitapplicationReducers;
