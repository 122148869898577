import { useEffect, useMemo, useReducer } from 'react'
import { isUndefined } from 'underscore'

const reducer  = (state, payload) => {
  return Object.assign(state, payload)
}
const useFetch = (fetcher, initialValue) => {

  const initState = useMemo(() => {
    return { errors: {}, isFetching: true, resource: initialValue }
  },[initialValue])
  
  const [state, dispatch] = useReducer(reducer, initState)

  useEffect(() => {
    if(isUndefined(fetcher)) return
    const fetchResource = async () => {
      try {
        const response = await fetcher();
        dispatch({resource: response.data, errors: {}, isFetching: false})
      } catch (error) {
        dispatch({resource: initialValue, errors: error.response?.data?.errors, isFetching: false})
      }
    }
    fetchResource()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  
  return [state.resource, state.errors, state.isFetching]
}

export default useFetch;
