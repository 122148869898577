import React, { useEffect, useRef } from 'react'
import styles from './index.module.sass';

const TimeInput = ({dispatch, type, list=[], selected}) => {
  const listRef = useRef(null);
  const itemClassNames = (item) => {
    let classNames = `${styles.defaultItem} list-group-item d-flex`
    if(selected === item) { return classNames.concat(' ', styles.activeItem) }
    return classNames
  }

  useEffect(() => {
    const node = listRef.current;
    const move = (e) => {
      const active = document.activeElement;
      if(e.keyCode === 40 && active.nextSibling) {
        active.nextSibling.focus();
      }
      if(e.keyCode === 38 && active.previousSibling) {
        active.previousSibling.focus();
      }
    }
    const moveFocus = () => {
      node.addEventListener('keydown', move);
    }
    moveFocus()
    return () => {
      node.removeEventListener('keydown', move)
    }
  },[]);
  return (
    <ul id={type} ref={listRef} className={`${styles.list} list-group text-center`}>
      {
        list.map((item, index) => (
          <ListItem 
            key={item}
            className={itemClassNames(item)} 
            item={item} dispatch={dispatch} 
            type={type}
            isActive={selected === item}
            index={index}
          />
        ))
      }
    </ul>
  )
}

const ListItem = ({item, dispatch, type, className, isActive, index}) => {
  const itemRef = useRef(null);
  
  const rectWidth = itemRef.current?.getBoundingClientRect()?.width
  useEffect(() => {
    if(isActive) {
      itemRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });  
    }
  },[isActive, rectWidth])

  return (
    <li
      ref={itemRef}
      tabIndex={index}
      onFocus={() => dispatch({type, payload: item})}
      onClick={() => dispatch({type, payload: item})}
      className={className}
    >
      <span className='m-auto'>{item}</span>
    </li>
  )
}


export default TimeInput;
