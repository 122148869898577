import fetchApi from 'components/modules/fetch_api';

const myPermitApplicationFetcher = (data={}) => {
  const { filters } = data;
  const { status = '', submitted_date = {}, application_type = '', permit_type_name='' } = filters || {};
  const queryParams = new URLSearchParams({
    ...(status && { status }),
    ...(submitted_date.from !== undefined && { 'submitted_date[from]': submitted_date.from }),
    ...(submitted_date.to !== undefined && { 'submitted_date[to]': submitted_date.to }),
    ...(application_type && { application_type }),
    ...(permit_type_name && { permit_type_name }),
  });

  const queryString = queryParams.toString();
  const url = `v1/permit_applications?per_page=100000&${queryString ? `${queryString}&` : '?'}`;

  return fetchApi(url, { method: 'GET' });
};


const show = (params) => {
  const { id } = params
  return fetchApi(`v1/permit_applications/${id}`, { method: 'GET' })
};

const cancel = (params) => {
  const { id, ...data } = params
  return fetchApi(`v1/permit_applications/${id}/cancel`, { method: 'PUT', data })
};

export {
  myPermitApplicationFetcher, cancel, show
};
