import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { ROLES } from "config/permissions";
/* Actions */
import { SET_LIST } from "actions/violation_types";
/* API */
import { filterFetcher } from "api/violation_types";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import AccessControl from "components/helpers/access-control";
/* Components */
import TableData from "./tableData";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import ViolationTypeModal from "../violationTypeModal";

const Index = props => {
  const [modalType, setModalType] = useState(null)
  const [selectedRecordId, setSelectedRecordId] = useState(null)

  const openModal = useCallback((val) => {
    setModalType(val)
  },[setModalType])

  const { isResourceFetching, currentUser } = props;

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >

      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            total={props.total}
            modalType={modalType}
            openModal={openModal}
            isFetching={isFetching}
          />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              selectedRecordId={selectedRecordId}
              setSelectedRecordId={setSelectedRecordId}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
            />
          </Col>
        </Row>
      </Container>
      <ViolationTypeModal
        modalType={modalType}
        openModal={openModal}

      />
    </AccessControl>
  )
  
};

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const resource = "violation_type";

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  withFetching(withCurrentUser(Index)),
);
