import React from 'react'
/* Styles/Assets */
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import styles from './index.module.sass';
/* Base */
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { Col } from 'reactstrap';
import Button from 'components/base/button';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';



const ToolBar =  ({openModal, modalType, total}, props) => {  
  return (
    <NavigationToolbar>
       <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={styles.pageHeader}>{`Coupons (${total})`}</span>
        <span>
          <Button
            isActive={modalType === 'add'}
            className={`${styles.button} ${modalType==='add' ? styles.activeTab : '' }`}
            icon={<PlusIcon className='svg-white'/>}
            onClick={() => {
              openModal('add')
            }}
          >
          </Button>
        </span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
          onClick={() => {
            openModal('create')
          }}
        >
          <span className='mr-3 d-inline-block' style={{width: '170px', fontWeight: "bolder"}}>Generate Coupon Code</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;