import { displayUnixTimestamp } from 'components/helpers';
import React, { useEffect, useMemo, useState } from 'react'
import { map } from 'underscore';
/* Styles and Assets */
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
import { ReactComponent as UnionDotsIcon } from 'assets/union-dots.svg'

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const DetailsIcon = () => {
  return <div 
    role='button'
    className={`${styles['icon-details']} d-flex justify-content-center align-items-center ml-3`}
  >
    <UnionDotsIcon style={{ width: 14, height: 10}} />
  </div>
}

const TableRow = ({history, record, match, openModal, setSelectedRecordId}) => {
  const { status } = record;

  const statusColor = useMemo(() => {
    if(!status) return;
    const colorMapping = {
      Open: 'success',
      Canceled: 'danger',
      Accepted: 'success',
      Closed: 'closed',
      'Auto Accepted': 'auto_accepted'
    }
    return colorMapping[status] || 'default'
  },[status])

  const actions = [
    { 
      icon: <EyeIcon className={`${styles['icon-eye']} mr-3`}/>,
      highlightedClass: `${styles['background-primary']} mr-3 text-white`,
      onClick: () => {openModal('show')},
      text: 'View',
      // isActive: modalType === 'show' && selectedRecordId === record.id
    },
    { 
      icon: <DetailsIcon/>,
      highlightedClass: `${styles['background-secondary']} ml-3 text-white`,
      text: 'Details',
      onClick: () => history.push(`${match.path}/${record.id}`)
    }
  ]

  return (
    <tr onClick={() => setSelectedRecordId(record.id)}>
      <td>{record.id}</td>
      <td>{displayUnixTimestamp(record.created_at)}</td>
      <td>{record.violation_type.name}</td>
      <td>{record.parking_lot?.name}</td>
      <td>{record.agency?.name}</td>
      <td>{record?.officer?.name}</td>
      <td>
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`}> </span>
          <span >{status.toUpperCase()}</span>
        </div>
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;
