import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Select, ConfigProvider } from "antd";
import styles from "./index.module.sass";
import "../language_context/index";
import { update } from "api/webapp/subscriber";

const { Option } = Select;

const LanguageSelector = (props) => {
  const { i18n } = useTranslation();
  const { currentSubscriber } = props
  const [selectedLanguage, setSelectedLanguage] = useState(currentSubscriber? currentSubscriber.language : localStorage.getItem("selectedLanguage"))

  const updateLanguagePreference = useCallback(async (user) => {
    try {
      const response = await update(user);
      if (response.status === 200 || response.status === 201) {
        setSelectedLanguage(response?.data?.language)
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    if(currentSubscriber){
      localStorage.setItem("selectedSubscriberLanguage", value);  
      localStorage.setItem("selectedLanguage", '');   
      updateLanguagePreference({language: value})
    }else{
      localStorage.setItem("selectedLanguage", value);
    }
    setSelectedLanguage(value)
  };

  useEffect(() => {
    if(currentSubscriber){
      localStorage.setItem("selectedSubscriberLanguage", currentSubscriber?.language)
      setSelectedLanguage(currentSubscriber?.language)
      i18n.changeLanguage(currentSubscriber?.language);
    }else{
      setSelectedLanguage(localStorage.getItem("selectedLanguage"))
      i18n.changeLanguage(localStorage.getItem("selectedLanguage"));
    }
  }, [setSelectedLanguage, currentSubscriber, i18n])

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            selectorBg: "#3A9CED",
            optionFontSize: "13px",
            colorBorder: "#3A9CED",
            colorBorderSecondary: "transparent",
            controlOutline: "transparent",
            colorPrimaryBorder: "#3A9CED",
            colorBorderBg: "#3A9CED",
          },
          token: {
            colorBorder: "#3A9CED",
            fontFamily: 'Roboto, sans-serif'
          },
        },
      }}
    >
      <Select
        className={styles.languageSelector}
        value={selectedLanguage? selectedLanguage : 'english' }
        onChange={changeLanguage}
        style={{ border: "none" }}
      >
        <Option value="english"><span role="img" aria-label="us">🇺🇸</span> EN</Option>
        <Option value="spanish"><span role="img" aria-label="es">🇪🇸</span> ES</Option>
        <Option value="hindi"><span role="img" aria-label="hi">🇮🇳</span> HI</Option>
      </Select>
    </ConfigProvider>
  );
};

export default LanguageSelector;
