import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Disable = (props) => {

  const { disableValet, closeModal } = props;
  
  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='mt-3'>
          This will disable the valet parking functionality. Users will not be able to request the service via the app or the parking kiosks. Any active valet parking sessions will not be affected
        </Col>
        <Col className='mb-4 mt-1'>
          Are you sure?
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          No
        </Button>
        <Button type='button' onClick={()=>  disableValet()} className={styles.submitButton}>
          Yes
        </Button>
      </Col>
    </Col>
  )
}

export default Disable;