import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap';
import { show } from 'api/webapp/notifications';
import Loader from 'components/helpers/loader';
import Button from 'components/base/button';
import styles from './index.module.sass'

const View = ({ record, openInnerModal, openModal}) => {
  const [isFetching] = useState()
  
  useEffect(() => {
    const fetchNotification = async () => {
      try {
        await show({id: record.id})
      } catch (_error) {
      }
    }
    fetchNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} p-0`}>{record.title}</p>
      <p className={`${styles.date} p-0`}>{new Date(record.created_at * 1000).toDateString()}</p>
      <div className="d-flex align-items-center my-3" style={{width: '98%'}}>
        <span className="border border-2 flex-grow-1 ml-2" ></span>
      </div>
      <p className={`${styles.text} p-0`}>{record.text}</p>
      { record.type === 'car_parked' &&
       <div className='d-flex justify-content-center' style={{paddingBottom: '10px'}}>
        <Button onClick={openInnerModal} type='button' className={`${styles.button1} ${styles['button-bg-secondary']}`}>
          Session Details
        </Button>
        </div>
      }
      <div className="d-flex align-items-center my-3" style={{width: '98%'}}>
        <span className="border border-2 flex-grow-1 ml-2" ></span>
      </div>
      <div className='d-flex justify-content-center' style={{paddingBottom: '10px'}}>
        <Button onClick={() => (openModal('delete'))} type='button' className={`${styles.button} ${styles['button-bg-danger']}`}>
          DELETE
        </Button>
      </div>
    </Container> 
  )
}

export default View;