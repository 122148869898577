import { SubscriberPermitsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "../entities";

const { index, records } = reduceEntity(SubscriberPermitsActions);

const SubscriberPermitReducers = combineReducers({
  index,
  records,
});

export default SubscriberPermitReducers;
