import React, { useCallback, useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Update from '../activate_deactivate';
import styles from './index.module.sass'
import { update } from 'api/subscribers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Show from '../view';
import SettingConfirmation from '../violation_confirmation';
import { updateSetting } from 'api/violations';

const SubscriberModal = (props) => {
  const { record, openModal, modalType, setListElement, captureViolation, setCaptureViolation, setCaptureNegative} = props
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState()

  const closeModal = useCallback( () => {
    openModal()
    setErrors({})
  }, [openModal, setErrors])

  const toggleStatus = useCallback(async (attrs) => {
    const data = Object.assign(attrs)
    try {
      const response  = await update({data, id : record.id})
      closeModal()
      const message = `The subscription for user ${record.first_name} ${record.last_name} with ID ${response.data.id} is successfully ${response.data.status === 'active' ? 'activated' : 'deactivated'}`
      addAlertMessages([{
        type: response.data.status === 'active' ? 'primary' : 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal])

  const updateRecord = useCallback(async (data) => {
    
    try {
      const response = await update({id: record.id, data: {user: data}})
      if(response.status === 200 || response.status === 201){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text: `Changes for user ${record.first_name} ${record.last_name} with ID ${record.id} were successfully saved`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      setErrors(error.response.data.errors)
      console.log(error)
    }
  },[addAlertMessages, closeModal, setListElement, record, setErrors])

  const updateViolationSetting = useCallback(async () => {
    
    try {
      const response = await updateSetting({data: {status: captureViolation === 'active' ? 'inactive' : 'active'}})
      if(response.status === 200 || response.status === 201){
        setCaptureViolation(response?.data?.status)
        setCaptureNegative(response?.data?.unpaid_violation_negative_balance)
        addAlertMessages([{
          type: `${captureViolation === 'inactive' ? 'primary' : 'danger'}`,
          text: `Capturing Subscriber Violations is successfully ${captureViolation === 'inactive' ? 'enabled' : 'disabled'}`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
      console.log(error)
    }
  },[addAlertMessages, closeModal, captureViolation, setCaptureViolation, setCaptureNegative])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'activate_deactivate':
        return { title: `Subscription ${record.status === 'active' ? 'Deactivation' : 'Activation' }`, maxWidth}
      case 'show':
        return { title: 'Subscriber Details', maxWidth: 680}
      default:
        return {maxWidth}
    }
  },[modalType, record]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);
  
  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>

        {
          modalType === 'activate_deactivate' && 
          <Update
            closeModal={closeModal}
            record={record}
            toggleStatus={toggleStatus}
            modalType={modalType}
            status={`${record.status === 'active' ? 'deactivate' : 'activate'}`}
          />
        }
        { modalType === 'show' &&  
          <Show 
            {...props}
            record={record} 
            closeModal={closeModal}
            updateRecord={updateRecord}
            errors={errors}
            setErrors={setErrors}
          /> 
        }
        { modalType === 'violation_confirmation' &&  
          <SettingConfirmation 
            {...props}
            closeModal={closeModal}
            updateViolationSetting={updateViolationSetting}
          /> 
        }

      </ModalBody>
    </Modal>
  )
}

export default SubscriberModal;