import React, { useCallback, useState, useEffect } from 'react';
import { ROLES } from "config/permissions";
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS } from 'actions/notifications';
import { ADD_LIST_ELEMENT as ADD_EMAIL_LIST_ELEMENT } from 'actions/emails';
import{ POP_LIST_ELEMENT as POP_EMAIL_ELEMENT } from 'actions/emails';
import{ SET_LIST_ELEMENT as SET_EMAIL_ELEMENT } from 'actions/emails';
import{ SET_SINGLE_RECORD as SET_SINGLE_EMAIL_RECORD } from 'actions/emails';
import { filterFetcher } from 'api/notifications';
import { filterFetcher as emailIndex } from "api/emails";
import Tabs from 'components/base/tabs';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styles from './index.module.sass'
import NotificationModal from '../notificationModal';
import { isEmpty } from 'underscore';
import ToolBar from './toolbar';

const Index = props => {
  const { isResourceFetching, currentUser } = props;
  const [errors, setErrors] = useState({})
  const [modalType, setModalType] = useState(null)
  const [activeTab, setActiveTab] = useState(null);
  const [formType, setFormType] = useState('all');
  const [formPage, setFormPage] = useState('notifications');
  const [emailList, setEmailList] = useState([])
  const [emailRecord, setEmailRecord] = useState({})

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])


  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  const tabs = [
    {label: 'All', value: 'all'},
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'}
  ]
  
  useEffect(() => {
    const fetchStatus = async () => {
      const response = await emailIndex({perPage: 10});
      if(response?.status === 200){
        setEmailList(response?.data)
      }
    }

    if (formPage === 'email_addresses' && isEmpty(emailList)) { fetchStatus() };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[emailList])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              pageType="bootings"
              activeTab={activeTab}  
              setActiveTab={setActiveTab}
              openModal={openModal} 
              modalType={modalType}
              setFormPage={setFormPage}
              formPage={formPage}
              isFetching={isFetching}
            />
          </Col>
          <Col xs={12} className='p-0 pb-4'>
            {formPage === 'notifications' && <Tabs className={styles.tab} list={tabs} defaultTab='all' onClick={setFormType} />}
          </Col>
          <Col xs={12} className='p-0'>
            {formPage === 'notifications' && <TableData
              {...props}
              isFetching={isFetching}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
              emailList={emailList}
              setEmailList={setEmailList}
              emailRecord={emailRecord}
              setEmailRecord={setEmailRecord}
              formType={formType}
            />}
          </Col>
        </Row>
        <NotificationModal 
          {...props}
          setActiveTab={setActiveTab}
          modalType={modalType} 
          openModal={openModal} 
          emailList={emailList}
          setEmailList={setEmailList}
          emailRecord={emailRecord}
          setEmailRecord={setEmailRecord}
          formPage={formPage}
          errors={errors}
          setErrors={setErrors}
        />
      </Container>
    </AccessControl>
  )
  
};

const resource = 'notification';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
      addEmailListElement: invoke(ADD_EMAIL_LIST_ELEMENT), 
      setEmailListElement: invoke(SET_EMAIL_ELEMENT), 
      popEmailListElement: invoke(POP_EMAIL_ELEMENT),
      setEmailRecord: invoke(SET_SINGLE_EMAIL_RECORD),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.notification.filters,
    record: state.notification.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);