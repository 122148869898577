const { FieldType } = require("components/helpers/form_fields");
const detailedViewFields = (attributes) => {
const { customInputClass='', customLabelClass='', customEntryTimeInputClass, customExitTimeInputClass, statuses=[], defaultStatus, dispute_type } = attributes;
let defaultFields = [
    {
      name: 'date',
      label: 'Date',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'user_name',
      label: 'Type',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'email',
      label: 'Subscriber Email',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'parking_lot',
      label: 'Parking Lot',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'dispute_type',
      label: 'Dispute Type',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: statuses,
      customInputClass,
      customLabelClass,
      disabled:defaultStatus === "rejected" || defaultStatus === "solved",
    },
    {
      name: 'text',
      label:"Message",
      type: FieldType.TEXT_AREA,
      rows: 3,
      disabled: true,
      customInputClass,
      customLabelClass
    },
    
]
  


const selectCommuterFields = [{ 
    name: 'entry_time', 
    label: 'Disputed Time',
    placeholder: 'Entry Time',
    customInputClass: customEntryTimeInputClass,
    customLabelClass
  },
  { 
    name: 'exit_time', 
    placeholder: 'Exit Time',
    customInputClass: customExitTimeInputClass,
    customLabelClass
  },]


if(dispute_type === 'time') { defaultFields = defaultFields.concat(selectCommuterFields)}

return defaultFields



}


export { detailedViewFields };
