import { FieldType } from "components/helpers/form_fields";

const vehicleDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, t } = attrs;
  return [
    {
      name: 'vehicle[plate_number]',
      label: t("vehicle_attributes.plate_number.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[registration_state]',
      label: t("vehicle_attributes.state.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[color]',
      label: t("vehicle_attributes.color.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[year]',
      label: t("vehicle_attributes.year.label"),
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[make]',
      label: t("vehicle_attributes.vehicle_make.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[model]',
      label: t("vehicle_attributes.model.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export default vehicleDetails;