import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { update, updateValidation } from 'api/vehicles';
import { SET_LIST_ELEMENT } from 'actions/vehicles';
import Show from '../show';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ActivateDeactivate from '../activate_deactivate';
import Preview from '../preview';

const initState = { errors: {}, isSaving: false, statuses: [] }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'statuses':
      return { ...state, statuses: payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const VehicleModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const { record, openModal, modalType } = props
  const[validate, setValidate] = useState(record?.validated)
  const dispatchAction = useDispatch();
  const [image, setImage] = useState(null)
  const [modalShown, setModalShown] = useState(false);
  const [errors, setErrors] = useState({})

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'show':
        return { width: '680px' };
      default:
        return { width };
    }
  },[modalType])

  const innerModalProps = useCallback(() => {
    const width = '480px'
    switch (state.innerModal) {
      case 'show_session':
        return { width: '680px' };
      case 'raise_dispute':
        return { width: '680px' };
      default:
        return { width };
    }
  },[state.innerModal])

      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
    setState('innerModal', null)
  }, [openModal, setState])
        
  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null)}}
    />
  },[setState]);

  const updateStatus = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({id: record.id, data})
      if(response.status === 200){
        setListElement(response.data)
      }
      closeModal()
    } catch (error) {
    } finally {
      setState('isSaving', false)
    }
  },[setState, closeModal, setListElement, record])

  const updateRecord = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await updateValidation({id: record.id, data})
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text: `Changes for vehicle with ID ${record.id} was successfully updated`,
          onlyMessage: true,
        }], 'center')
      }
      closeModal()
    } catch (error) {
    } finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, closeModal, setListElement, record])
 
  return (
    <Modal 
      isOpen={!!modalType} 
      centered
      style={{maxWidth: modalProps().width, width: '100%'}}
      contentClassName={styles.modal}
      backdropClassName={styles.backdropC}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'show' &&  
          <Show 
            {...props}
            record={record} 
            {...state}
            closeModal={closeModal}
            updateStatus={updateStatus}
            updateRecord={updateRecord}
            setState={setState}
            openInnerModal={() => setState('innerModal', 'activate_deactivate')}
            openPreviewModal={() => setModalShown(!modalShown)}
            validate={validate}
            setValidate={setValidate}
            image={image}
            setImage={setImage}
            setErrors={setErrors}
            errors={errors}
          /> 
        }
      </ModalBody>
      {!!state.innerModal &&
        <Modal
          isOpen={!!state.innerModal}
          centered
          style={{maxWidth: innerModalProps().width, width: '100%'}}
          contentClassName={styles.modal}
          backdropClassName={styles.backdropC}
        >
          { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
          <ModalBody>
          <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
            { state.innerModal === 'activate_deactivate' &&  
              <ActivateDeactivate 
                record={record}
                closeModals={() => {setState('innerModal', null)}}
                isSaving={state.isSaving}
                validate={validate}
                setValidate={setValidate}
              /> 
            }
          </ModalBody>
        </Modal>
      }
      {modalShown &&
        <Modal
          isOpen={modalShown}
          close={() => {setModalShown(false)}}
          centered
          style={{maxWidth: innerModalProps().width, width: '100%'}}
          contentClassName={styles.previewModal}
          backdropClassName={styles.backdropC}
        >
          <ModalBody>
            <Preview 
              close={() => {setModalShown(false)}}
              image={image}
            />
          </ModalBody>
        </Modal>
      }
    </Modal>
  )
}

export default VehicleModal
