import { SpecialPermitTypeActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, filters, records } = reduceEntity(SpecialPermitTypeActions);

const SpecialPermitTypeReducers = combineReducers({
  index,
  records,
  filters,
});

export default SpecialPermitTypeReducers;
