import React, { useEffect } from 'react'
import { Col, Container } from 'reactstrap'
import { Form } from 'informed'
/* Assets */
import styles from '../violationTypeModal/index.module.sass'
/* Base */
import Button from 'components/base/button'
import { renderField } from 'components/base/forms/common_form'
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/violations/violation_types'

const attrs = {
  customInputClass: styles.formInput,
}

const Create = ({closeModal, createViolationType, isSaving, errors, setErrors}) => {

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Container>
      <Col className={`${styles.modalTitle} my-4 mb text-center`}>Create Violation Type</Col>
      <Form className='m-auto' onSubmit={createViolationType}>
        <Col className={styles.modalContent} xs={11}>
          {renderField(fieldsNew(attrs), {errors, lSize: 3, iSize: 9})}
        </Col>
        <Col className='d-flex justify-content-center align-items-center mt-4 mb-2'>
          <Button 
            className={`${styles.btn} ${styles['btn-light']} mr-3`}
            onClick={closeModal}
            type='button'
          >
            Cancel
          </Button>
          <Button 
            className={`${styles.btn} ${styles['btn-primary']}`}
            disabled={isSaving}
            type='submit'
          >
            Create
          </Button>
        </Col>
      </Form>
    </Container>
  )
}

export default Create;
