import React from 'react';
import PropTypes from 'prop-types';
import ParkingCitationTickets from 'components/pages/parking_citation_tickets/index'
import GenerateCitationReport from 'components/pages/parking_citation_tickets/generate_citation_report'
import CitationEscalations from 'components/pages/parking_citation_tickets/escalations'

import { Route, Switch, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={ParkingCitationTickets} />
    <Route exact path={`${match.path}/generate_citation_report`} component={GenerateCitationReport} />
    <Route exact path={`${match.path}/citation_escalations`} component={CitationEscalations} />
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);
