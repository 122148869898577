import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import {Trans} from 'react-i18next'

const Cancel = ({cancelEvent, closeModal,setState, event, t}) => {

  const message = () => <React.Fragment>
    <span className='mx-1' style={{fontWeight:"bold"}}>
      {t('events.private_events.cancel.heading')}
    </span>
    <br/>
    <br/>
    <span className={styles.content}>
      <Trans 
        i18nKey="events.private_events.cancel.content" 
        components={{ '1': <span className={styles.boldText} /> }} 
        values={{ eventId: event.event_id, refund: event.paid && 
                 <React.Fragment> A Refund of <span className={styles.boldText}>${event.total_cost} 
                  </span> will be processed.
                 </React.Fragment> 
                }} 
      />
    </span>
  </React.Fragment>

  return (
    <Container className="p-0">
      <Container className="mb-4">
        <Col>{message()}</Col>
        <br />

        <Col className="d-flex justify-content-center my-2">
          <Button
            onClick={() => closeModal()}
            type="button"
            className={`${styles.button} ${styles["button-bg-secondary"]} mr-4`}
          >
            {t("buttons.cancel")}
          </Button>

          <Button
            type="button"
            onClick={() => {
              cancelEvent();
              closeModal();
            }}
            className={`${styles.button} mr-4`}
          >
            {t("buttons.proceed")}
          </Button>
        </Col>
      </Container>
    </Container>
  );
}

export default Cancel;