import React, { useContext, useRef, useState } from 'react'
import { Col, Container, Label, Row } from 'reactstrap';
import Button from 'components/base/button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { list } from 'selectors/list';
import { flatten, isEmpty, map, values } from 'underscore';
/* API */
import { importBulkCoupons, filterFetcher as couponsFetcher } from 'api/coupons';
/* Helpers */
import Loader from 'components/helpers/loader';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
/* Actions */
import { SET_LIST } from 'actions/coupons';
import { invoke } from 'actions';
/* styles/Assets */
import styles from './index.module.sass'
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'

const couponAttrs = [
  { name: 'Coupon Code'},
  { name: 'Coupon Amount'},
  { name: 'Validity Period'},
  { name: 'Reason'},
]

const BulkCoupons = ({closeModal, ...props}) => {
  const [fileName, setFileName] = useState();
  const [isSaving, setIsSaving] = useState(false)
  const fileRef = useRef()
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const refetchCoupons = async () => {
    const { page, perPage, setList } = props; 
    const response = await couponsFetcher({page, perPage})
    setList(list(response))
  }
  
  const importData = async (e) => {
    e.preventDefault()
    e.stopPropagation();


    const formData = new FormData()
    formData.append('file', fileRef.current.files[0])
    try {
      setIsSaving(true)
      await importBulkCoupons(formData);
      addAlertMessages([{
        type: 'primary',
        text:  `${capitalize(fileName.split('.')[0])} CSV file was successfully imported`,
        onlyMessage: true,
      }],
      'center'
      )
      refetchCoupons()
    } catch (error) {
      addAlertMessages(
        map(flatten(values(error.response?.data?.errors)), (message) => {
          return (
            {
              type: 'danger',
              text:  message,
              onlyMessage: true,
            }
          )
        }),
        'center'
      )
    } finally {
      setIsSaving(false)
      closeModal()
    }
  }
  
  const showFileName = (e) => {
    setFileName(e.target.files[0]?.name)
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileName(e.dataTransfer.files[0].name)
      fileRef.current = { files: e.dataTransfer.files }
    }
  };

  return (
    <Container>
      { 
        isSaving 
          ? <Loader />
          : (
            <Col className={`${styles.body} text-left`}>
              <Col className='my-4 p-0'>
                <span className='d-block'>
                  Please upload bulk coupons in CSV file. 
                  The file should contain the following 
                  columns in the same order as listed below.
                </span>
              </Col>
              <Row className='my-4 mx-0 p-0'>
                {couponAttrs.map((attr, idx) => (
                  <p className='p-0 d-flex align-items-center w-100 mb-1' key={attr.name}>
                    <span className={`${styles.numberLabel} mr-2`}>
                      {`${idx + 1}.`}
                    </span>
                    <span className={styles.boldText}>
                      {attr.name}
                    </span>
                    { attr.altText && <span className='ml-1'>{`(${attr.altText})`}</span>}
                  </p>
                ))}
              </Row>
              <form onSubmit={importData}>
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                  <input ref={fileRef} className='d-none' id='csv-file-upload' type='file' accept='.csv' onChange={showFileName} />
                  <Label htmlFor='csv-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-100 w-100 text-center p-3`}>
                    <span className='mb-2'>
                      <CloudDownloadIcon />
                    </span>
                    { fileName && <span>{fileName}</span> }
                    <span>
                      <span className={styles.boldText}>
                        Choose a CSV file
                      </span>
                      <span className='ml-1'>
                        or drag it here
                      </span>
                    </span>
                  </Label>
                </div>
                <Col className="d-flex justify-content-center mb-3 mt-5 p-0">
                  <Button 
                    type='button' 
                    className={`${styles.greyButton} mr-4`}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isEmpty(fileName)} type='submit' className={`${styles.primaryButton}`}>
                    Add
                  </Button>
                </Col>
              </form>
            </Col>
          )
      }
    </Container>
  )
  
}

const  mapDispatch = (dispatch) => {
  return bindActionCreators({ setList: invoke(SET_LIST) }, dispatch);
}

const mapState = (state) => {
  const { perPage, page } = state.permit.index
  return { perPage, page }
}
export default connect(mapState, mapDispatch)(BulkCoupons);
