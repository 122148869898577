import Loader from 'components/helpers/loader';
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { isEmpty, map } from 'underscore';
/* Helpers */
import { ownerFields, permitFields, vehicleFields } from 'components/helpers/fields/permits/edit';
/* API */
import { show } from 'api/permits';
import { searchV1 as dropdownsSearchV1, search as dropdownsSearch } from 'api/dropdowns';
/* Base */
import { renderFields } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Modules */
import withFetching from 'components/modules/with_fetching';
/* Styles */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const fieldAttrs = {
  customInputClass: styles.input,
  customLabelClass: styles.inputLabel,
  mute: false,
}

const doubleFieldProps = {
  iSize: 7,
  lSize: 5
}

const permitData = (data) => {
  const { vehicle={}, owner_name='', home_address={}, work_address={}, permit_number, expiry_date, issue_date, permit_expired, permit_type_id, cost, status, refund_completed, payment_type } = data
  return ({
    vehicle: {
      ...vehicle,
      plate_number: vehicle.plate_number?.toLowerCase(),
      make: vehicle.make,
      color: vehicle.color,
      model: vehicle.model,
      registration_state: vehicle.registration_state,
    },
    owner: { 
      full_name: owner_name, 
      home_address: home_address.address1,
      home_phone: home_address.phone,
      work_address: work_address.address1,
      work_phone: work_address.phone,
    },
    permit: {
      number: permit_number,
      expires_on: expiry_date,
      issued_on: issue_date,
      type: permit_type_id,
      expired: permit_expired ? 'YES' : 'NO',
      cost,
      payment_type,
      refund_completed,
      status
    },
  })
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['primary-color']} ${styles.dollarIcon}`} />

const renderOwnerFields = () => {
  const  fields = ownerFields(fieldAttrs);
  return (
    <Col className='d-flex flex-wrap px-0'>
      <div className="d-flex align-items-center my-3 w-100">
        <span className={styles.detailsLabel}>Owner Information</span>
        <span className="border border-2 flex-grow-1 ml-2"></span>
      </div>
      <div className={styles.fieldset}>
        {renderFields(fields.slice(0,3), {...doubleFieldProps})}
      </div>
      <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
        {renderFields(fields.slice(3,6), {...doubleFieldProps})}
      </div>
    </Col>
  )
}

const  Edit = ({record, startFetching, closeModal, updatePermit, isSaving, permitTypes, vehicleLpns, setState})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [permit, setPermit] = useState({})

  const renderVehicleFields = useCallback(() => {
    const fields = vehicleFields({...fieldAttrs, vehicleLpns})

    return(
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Vehicle Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
      </Col>
    )
  },[vehicleLpns])

  const renderPermitFields = useCallback(() => {
    const fields = permitFields({...fieldAttrs, permitTypes})
    fields[5] = {
      ...fields[5], 
      icon: dollarIcon(), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }
    
    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center mt-3 mb-2 w-100">
          <span className={styles.detailsLabel}>Permit Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <p className={`${styles.detailsLabel} text-capitalize mb-4 p-0 w-100`}>
          {`${record.permit_category} Permit`}
        </p>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
      </Col>
    )
  },[permitTypes, record.permit_category])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validId = (plate_number) => {
    let id = 0
    map(vehicleLpns, (v) => {
      if(v.label.toLowerCase() === plate_number.toLowerCase()){
        id = v.value
      }
    })
    return id
  };

  const submitValues = useCallback((values) => {
    const data = { 
      permit_attributes: {
        permit_type_id: values.permit.type,
        vehicle_id: validId(values.vehicle.plate_number)
      },
      vehicle_attributes: {
        registration_state: values.vehicle.registration_state,
        color: values.vehicle.color,
        vehicle_type: values.vehicle.make,
        plate_number: values.vehicle.plate_number,
        model: values.vehicle.model,
        manufacture_year: values.vehicle.manufacture_year,
        make: values.vehicle.make,
      },
      user_attributes: {
        first_name: values.owner.full_name.split(' ')[0],
        last_name: values.owner.full_name.split(' ').slice(1).join(' ')
      },
      home_address_attributes: {
        phone: values.owner.home_phone,
        address1: values.owner.home_address
      },
      work_address_attributes: {
        phone: values.owner.work_phone,
        address1: values.owner.work_address
      },
    }

    updatePermit(data)
  },[updatePermit, validId])
  
  useEffect(() => {
    const fetchPermit = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: record.id})
        const newPermit = permitData(response.data);
        setPermit(newPermit)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(record.id) { fetchPermit() }
  },[record.id])

  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownsSearchV1('permit_type_list'))
        setState('permitTypes', permitTypesList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(permitTypes)) fetchPermitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const fetchVehicleList = async () => {
      try {
        const vehiclesList = await startFetching(dropdownsSearch('vehicles_list', {id: record.id}))
        setState('vehicleLpns', vehiclesList.data)
      } catch (_error) {
      }
    }
    fetchVehicleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0 my-3'>
      <p className={`${styles.title} m-0 mb-3 p-0`}>Edit Permit</p>
      <Form initialValues={permit} className={styles.form} onSubmit={submitValues}>
        { renderVehicleFields() }
        { renderOwnerFields() }
        { renderPermitFields() }
        <Col className="d-flex justify-content-center mb-3 mt-4" xs={12}>
          <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            Cancel
          </Button>
          <Button type='submit' className={`${styles.button}`}   isLoading={isSaving}>
            Save
          </Button>
        </Col>
      </Form>
    </Container>
  )
}

export default withFetching(Edit);
