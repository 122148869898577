const applicantDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'applicant[full_name]',
      label: 'Full Name',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[home_address]',
      label: 'Home Address',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[home_phone]',
      label: 'Home Phone',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[work_address]',
      label: 'Work Address',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[work_phone]',
      label: 'Work Phone',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export default applicantDetails;
