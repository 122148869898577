import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';


const Delete = (props) => {

  const { deleteFile, closeModals } = props

  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='my-3'>
          citation Document will be deleted immediately. This operation cannot be undone
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModals()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={deleteFile} className={styles.submitButton}>
          Delete
        </Button>
      </Col>
    </Col>
  )
}

export default Delete;