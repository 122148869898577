import { TowingsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(TowingsActions);

const TowingsReducers = combineReducers({
  index,
  records,
});

export default TowingsReducers;
