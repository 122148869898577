import React, { useCallback } from 'react'
import { Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TableRow from './tableRow';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import { filterFetcher } from 'api/subscribers';
import { invoke } from 'actions';
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/subscribers';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { isResourceFetching, list, setRecord, openModal, modalType, activeTab, filters } = props
  const renderRecords = useCallback(() => {
    return list.map((record) => 
      <TableRow
        {...props}
        key={record.id} 
        openModal={openModal} 
        record={record} 
        setRecord={setRecord}
        modalType={modalType}    
      />
    );
  },[list, openModal, setRecord, modalType, props])

  return (
    <Col xs={12} className='p-1'>
      <IndexTable
        {...props}
        className={(activeTab === 'filters' || activeTab === 'setting') ? styles.tableActive : styles.table}
        isFetching={() => isResourceFetching }
        filterFetcher={filterFetcher}
        resource={resource}
        paginationQuery={filters}
        columns={
          <React.Fragment>
            <th attr="id" style={{width: '10%', minWidth: '150px' }} >User Id</th>
            <th attr="first_name" style={{width: '15%', minWidth: '150px' }} >First Name</th>
            <th attr="last_name" style={{width: '15%', minWidth: '150px' }} >Last Name</th>
            <th attr="email" style={{width: '15%', minWidth: '150px' }} >Email</th>
            <th attr="account_type" style={{width: '15%', minWidth: '150px' }} >Account Type</th>
            <th attr="vehicles_owned" style={{width: '20%', minWidth: '150px' }} >Number of Vehicles Owned</th>
            <th attr="status" style={{width: '10%', minWidth: '150px' }} >Status</th>
            <th style={{ width: '30%', minWidth: '275px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
          </React.Fragment>
        }
        renderRecords={renderRecords}
        entityName="Subscriber"
      >
      </IndexTable>
    </Col>
  )
}

const resource = 'subscriber';

const  mapDispatch = (dispatch) => {
  return bindActionCreators({ setListElement: invoke(SET_LIST_ELEMENT), setRecord: invoke(SET_SINGLE_RECORD) }, dispatch);
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(null, mapDispatch)(withFetching(TableData))
);
