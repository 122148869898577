import React, {useState, useEffect, useCallback} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import styles from './index.module.sass';
import { filterFetcher, downloadPdf } from 'api/webapp/citations';
import { isEmpty } from 'underscore';
import CitationModal from '../citationModal'
import ToolBar from './toolbar';
import withCurrentSubscriber from 'components/modules/with_current_subscriber';
import { capitalize } from 'components/helpers';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/download_icon.svg';

const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}) => {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search..."
    />
  );
};


const TableComponent = ({list, onRowClick, openModal}) => {

  const downloadCitationPdf = async (row) => {
    try {
      await downloadPdf({id: row.original.id, payment_link: 'jrebkl'})
    } catch (_error) {
    }
  }

  const data = React.useMemo(
    () => list,
    [list]
  );
  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id', Filter: DefaultColumnFilter },
      { Header: 'Violation', accessor: 'violation', Filter: DefaultColumnFilter },
      { Header: 'Status', accessor: 'status', Filter: DefaultColumnFilter,
        Cell: ({ value }) => <span>{capitalize(value)}</span>
      },
      { Header: 'Created At', accessor: 'created_at_parsed', Filter: DefaultColumnFilter,
        Cell: ({ value }) => <span>{(value)}</span>
      },      
      { Header: 'Updated At', accessor: 'updated_at_parsed', Filter: DefaultColumnFilter,
        Cell: ({ value }) => <span>{(value)}</span>
      },
      { Header: 'Dispute Type', accessor: 'dispute_type', Filter: DefaultColumnFilter },
      {
        Header: 'View',
        accessor: 'actions',
        Filter: DefaultColumnFilter,
        Cell: ({ row }) => (
          <div className='d-flex justify-content align-items-center pl-0 pr-3'>
            <button disabled={row.original.shared} className={styles.actionButton} onClick={() => openModal('show')}>
              <EyeIcon className={`${styles['icon-eye']} ${styles['icon-eye-active']}`}/>
            </button>
            <button className={styles.actionRenewButton} onClick={() => downloadCitationPdf(row)}>
              <DownloadIcon className={`${styles['icon-cross']} ${styles['icon-cross-active']}`} />
            </button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className={styles.tableContainer}>
      {!isEmpty(list) && (
        <div>
          <Table {...getTableProps()} className={styles.customTable}>
            {headerGroups.map((headerGroup) => (
              <Thead key={headerGroup.id} className={styles.tableHead}>
                <Tr {...headerGroup.getHeaderGroupProps()} className={styles.tableRow}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={styles.tableRow}
                      style={column.Header==='Action'? {width:'160px'} : {color:''}}
                      key={column.id}
                    >
                      {column.render('Header')}
                      <span className={styles.tableHeader}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className={`fas fa-sort-down ${styles.sortIcon}`}></i>
                          ) : (
                            <i className={`fas fa-sort-up ${styles.sortIcon}`}></i>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Th>
                  ))}
                </Tr>
              </Thead>
            ))}
            <Tbody {...getTableBodyProps()} className={styles.tableBody}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={row.id}
                    onClick={() => onRowClick(row.original)}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()} className={styles.tableData} key={cell.column.id}>
                        {cell.render('Cell')}                        
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <div className={styles.pagination}>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '}
           
            <span>
              | Go to page:{' '}
              <input
                type="number"
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size} per page
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {isEmpty(list) &&
        <div className={styles.noData}>
          No Data
        </div>
      }
    </div>
  );
};

const SubscriberIndexTable = (props) => {
  const { currentSubscriber } = props
  const [list, setList] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()
  const [paymentStatus, setPaymentStatus] = useState();
  const [disputeStatus, setDisputeStatus] = useState();
  const [formType, setFormType] = useState('all');
  const openModal = useCallback((modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'show':
        val = 'show'
        break;
      default:
        break;
    }
    setModalType(val)
  },[setModalType])



  useEffect(() => {
    const fetchList = async () => {
      try {
        const list = await filterFetcher()
        setList(list.data)
      } catch (_error) {
      }
    }
    if(isEmpty(list) && formType === 'all'){fetchList();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list, setList])
  return (
    <div>
      <ToolBar total={list.length} openModal={openModal} record={selectedRecord} setList={setList} formType={formType} setFormType={setFormType}/>
      <TableComponent list={list} onRowClick={(record) => { setSelectedRecord(record);}} openModal={openModal} modalType={modalType} />
      {modalType &&
        <CitationModal 
          record={selectedRecord}
          openModal={openModal}
          modalType={modalType}
          setModalType={setModalType}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentSubscriber={currentSubscriber}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          disputeStatus={disputeStatus}
          setDisputeStatus={setDisputeStatus}
        />
      }
    </div>
  );

}

export default withCurrentSubscriber(SubscriberIndexTable);