import resourceApi from 'components/modules/resource_api';

const { index, create, destroy, update } = resourceApi('parking/measures');

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

export {
  filterFetcher,
  create,
  destroy,
  update
};
