import React, { useEffect, useState } from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import { Col, Container, NavLink, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import env from '.env';
import { isEmpty } from 'underscore';
/* Api */
import { getDetails } from 'api/events';
/* Helpers */
import Loader from 'components/helpers/loader';
import WebAppHeader from 'components/base/webapp_header';
/* Styles */
import styles from './index.module.sass';
import {ReactComponent as MailboxIcon } from 'assets/mailbox_icon.svg'
/* Base */
import CollapsableCard from 'components/base/collapsable_card';
import { useTranslation } from 'react-i18next';
/* Styles */


const Header = ({name='', address=''}) => (<p className='m-0'>
    <MailboxIcon className={styles.defaultIconColor} />
    <span className='ml-2'>{name}</span>
    { ' - '}
    <span>{address}</span>
</p>)

const Events = ({ match, google })=> {
  const [event, setEvent] = useState({})
  const [fetching, setFetching] = useState(false)
  const { t } = useTranslation()
  
  useEffect(() => {
    
    if (!match.params?.event_id) return;
    const fetchEvent = async () => {
      setFetching(true)
      try {
        const res = await getDetails(match.params);
        setEvent(res.data);
      } catch (_error) {
      } finally {
        setFetching(false);
      }
    };
    
    if(isEmpty(event)) fetchEvent()
  }, [event, match.params]);

  if(fetching) return <Loader />

  return (
      <Container className={`${styles.default} w-100 mw-100 p-0 m-0`}>
      <WebAppHeader/>
      {      
        isEmpty(event) && !fetching 
          ? <p className='mt-5'>{`No event with id ${match.params?.event_id}`}</p>
          : <React.Fragment>
              <p className={`${styles.title} mx-0 mb-3 p-0`}>{t("events.private_events.event_parking")}</p>
              <Container className='pt-3'>
                <Row className='mx-100 flex-wrap'>
                  <Col className='col-12 my-2'>
                    <p className="m-0 p-0 text-center">{t("events.private_events.parking_navigation_note")}</p>
                  </Col>
                  <Col className="col-12 d-flex flex-column">
                  {
                   !isEmpty(event.parking_lots) && event.parking_lots.map((lot) => {
                      return (
                        <CollapsableCard
                          key={lot.id} 
                          className={`${styles.collapse} shadow-sm`} 
                          svgColor='grey-dark' 
                          header={<Header name={lot.name} address={lot.location.full_address}/>}
                        >
                          <Col className={`${styles.map} col-10 mx-auto mb-3 p-0`}>
                            <Map
                              google={google}
                              zoom={15}
                              initialCenter={{
                                lat: lot.location.ltd,
                                lng: lot.location.lng
                              }} 
                              center={{
                                lat: lot.location.ltd,
                                lng: lot.location.lng
                              }} 
                            >
                              <Marker
                                name={lot.name}
                                title={lot.name}
                                position={{
                                  lat: lot.location.ltd,
                                  lng: lot.location.lng
                                }}
                              />
                            </Map>
                          </Col>
                          <Col className='col-10 m-auto p-0 mb-2 d-flex justify-content-end'>
                            <NavLink className={styles.button} href={`https://www.google.com/maps/search/?api=1&query=${lot.location.ltd},${lot.location.lng}`} target='_blank'>
                              <span>
                                {t("events.private_events.open")}
                              </span>
                            </NavLink>
                          </Col>
                        </CollapsableCard>
                      )
                    })
                  }
                  </Col>
                </Row>
              </Container>
          </React.Fragment>
      }     
      </Container>
  )
}

export default GoogleApiWrapper({ apiKey: env.google_cloud_api_key, LoadingContainer: Loader })(withRouter(Events));
