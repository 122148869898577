import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const create = (params = {}) => {
  const { data } = params;
  return fetchApi(generatePath('v1/incident_reports'), {
    method: 'POST',
    data
  })
};

const create_by_unregistered_user = (params = {}) => {
  const { data } = params;
  return fetchApi(generatePath('v1/incident_reports/create_by_unregistered_user'), {
    method: 'POST',
    data
  })
};

export { create, create_by_unregistered_user };
