import React from 'react'
import { displayUnixTimestamp } from 'components/helpers';
import styles from './index.module.sass'

const TableRow = (props) => {
	const { record, setRecord } = props

	const secondsToHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
		return (
			<div>
				{hours>0 
				? <div className='d-flex'>
						<span className={`${styles.icon} mr-1`}>{hours < 10 ? `0${hours}` : hours}</span>
						<span className='mr-2'>Hours</span>
						<span className={`${styles.icon} mr-1`}>{minutes < 10 ? `0${minutes}` : minutes}</span>
						<span >Minutes</span>
					</div>
				: <div className='d-flex'>
						<span className={`${styles.icon} mr-1`}>{minutes}</span>
						<span >Minutes</span>
					</div>
				}
			</div>
		)
  }

	return (
		<tr onClick={() => setRecord(record)}>
			<td>{record?.parking_lot?.name}</td>
			<td>{record?.parking_slot?.id}</td>
			<td>{displayUnixTimestamp(Date.parse(record?.start_time)/1000)}</td>
			<td>{displayUnixTimestamp(Date.parse(record?.end_time)/1000)}</td>
			<td>{secondsToHours(record?.session_length)}</td>
			<td>{record?.charger?.name}</td>
			<td>{record?.vehicle?.plate_number}</td>
		</tr>
	);

};

export default TableRow