import React, { useMemo } from 'react'
import { Form } from 'informed';
import { reportFields } from 'components/helpers/fields/charging_sessions';
import styles from './index.module.sass';
import { renderFields } from 'components/base/forms/common_form';
import { Col, Row } from 'reactstrap'

const FilterForm = (props) => {
  const { sessionReport } = props

  const dollarIcon = () => <div className={styles.dollarIcon}>{sessionReport?.ev_chargers} </div>
  const attrs = useMemo(() => (
    {
      customLabelClass: styles.formLabel,
      customInputClass: styles.formInput,
      icon: dollarIcon(),
      iconInputClass: styles.formInput.concat(' ', styles.iconInput),
      customInputBlueClass: styles.formInput1
    }
    // eslint-disable-next-line
  ),[]) 

  const initialValues = useMemo(() =>(
    {
      ev_parkings_slots: sessionReport?.ev_parkings_slots,
      charging_session_completed: sessionReport?.charging_session_completed,
      ev_chargers: `(${sessionReport?.inactive_ev_chargers} Inactive)`,
      total_charging_hours: sessionReport?.total_charging_hours,
      total_energy_consumed: `${sessionReport?.total_energy_consumed} KWh`,
      active_sessions: sessionReport?.active_sessions,
    }
  ),[sessionReport])

  const getFields = () => reportFields({ ...attrs})

  return (
    <Form initialValues={initialValues} className={styles.form}>
      {({formState}) => {
        const fields = getFields()
        return <Row className='m-auto'>
          <Col className={`m-0 ${styles.fieldset}`} xs={4}>
            {renderFields(fields.slice(0, 2), {lSize: 5, iSize: 5})}
          </Col>
          <Col className={`m-0 ${styles.fieldset}`} xs={4}>
            {renderFields(fields.slice(2, 4), {lSize: 5, iSize: 5})}
          </Col>
          <Col className={`m-0 ${styles.fieldset}`} xs={4}>
            {renderFields(fields.slice(4, 6), {lSize: 5, iSize: 5})}
          </Col>
        </Row>
      }}
    </Form>
  )
}

export default FilterForm;
