import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { search as dropdownsSearch, searchV1 } from 'api/dropdowns';
/* Helpers */
import { fieldsAdd } from 'components/helpers/fields/permit_type_codes/fieldsNew'
/* Styles/Assets */
import styles from './index.module.sass';
import withFetching from 'components/modules/with_fetching';


const Add = (props) => {
  const  { addPermitType, closeModal, errors, setErrors ,isSaving, startFetching, code } = props;
  const [codeDropdowns, setCodeDropdowns] = useState()
  const [permitTypeDropdowns, setPermitTypeDropdowns] = useState()
  const formApiRef = useRef();

  useEffect(() => {
    const fetch = () => {
      Promise.all([
    
        startFetching(
          dropdownsSearch('permit_type_code_list')
        ).then((response) => setCodeDropdowns(response.data)),
        startFetching(
          searchV1('permit_type_list')
        ).then((response) => setPermitTypeDropdowns(response.data)),

      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    permit_type_code_list: codeDropdowns,
    permit_type_list: permitTypeDropdowns
  }
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const validateValues = (formState) => {
    const errors = {}
    const { id, permit_type_ids } = formState;
    if(!id) { errors['id'] = ['Permit Type Code is required']}
    if(!permit_type_ids) { errors['permit_type_ids'] = ['Permit Type is required']}
    return errors
  }
   
  const doubleFieldProps = {
    lSize: 3,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
  }
  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    addPermitType(values);
  },[setErrors, addPermitType]);
  
  
  const getFields = () => {
    return fieldsAdd({mute: false, ...attrs})
  }
  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const initialValues = {
    id: code?.id
  }


  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save} >

        {({ formState }) => {
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100'>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className='m-0 p-0 pl-2' xs={12}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                { map(newFields[1], (field, idx) => {
                  return (
                    <Col key={idx} className='m-0 p-0 pl-2' xs={12}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <Col className='d-flex justify-content-center mb-3 mt-3' xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={styles.button}  isLoading={isSaving}>
                    Create
                  </Button>
                </Col>
              </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Add);
