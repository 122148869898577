import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import { past as filterFetcher } from "api/charging_sessions";
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {

	const { list, setRecord, filters, isResourceFetching, openModal, modalType, selectedRecord, activeTab } = props
	
	const renderRecords = useCallback(() => {
		return list.map((record) => (
			<TableRow
				key={record?.id}
				record={record}
				selectedRecordId={selectedRecord?.id}
				openModal={openModal}
				modalType={modalType}
				setRecord={setRecord}
			/>
		));
	}, [list, modalType, setRecord, openModal, selectedRecord])

  return (
		<IndexTable
			{...props}
			className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
			isFetching={() => isResourceFetching}
			filterFetcher={filterFetcher}
			paginationQuery={filters}
			columns={
				<React.Fragment>
					<th attr="parking_lot.name" style={{ width: '25%', minWidth: '210px' }}>Parking Lot</th>
					<th attr="parking_slot_id" style={{ width: '25%', minWidth: '210px' }}>Parking Slot</th>
					<th attr="start_time" style={{ width: '25%', minWidth: '210px' }}>Session Start</th>
					<th attr="end_time" style={{ width: '25%', minWidth: '210px' }}>Session End</th>
					<th attr="session_length" style={{ width: '25%', minWidth: '210px' }}>Session Length</th>
					<th attr="chargers.name" style={{ width: '25%', minWidth: '210px' }}>EV Charger</th>
					<th attr="vehicles.plate_number" style={{ width: '25%', minWidth: '210px' }}>Vehicle LPN</th>
				</React.Fragment>
			}
			renderRecords={renderRecords}
			entityName='Past Charging Sessions'
		/>
	)
}

TableData.propTypes = {
	list: PropTypes.arrayOf(PropTypes.object).isRequired,
	match: PropTypes.object.isRequired
};

export default TableData