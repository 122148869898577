import React from 'react'
import { useSelector } from 'react-redux';
/* Base */
import NavigationToolbar from 'components/base/navigationToolbar';
/* Components */
import { Col } from 'reactstrap';
import styles from './index.module.sass'

const ToolBar = ({showTotal}) => {
  const totalPermits = useSelector(store => store.carpool_permit.index.total)

  return (
    <NavigationToolbar
      backTab='/dashboard/permits'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center ml-2'>
        <span className={styles.title}>{`${showTotal ? `Issued Car Pool Permits (${totalPermits})` : 'Car Pool Permits'}`}</span>
      </Col>
      <Col className='d-flex justify-content-end px-0'>

      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
