import { SubscriberNotificationsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "../entities";

const { index, records } = reduceEntity(SubscriberNotificationsActions);

const SubscriberNotificationsReducers = combineReducers({
  index,
  records,
});

export default SubscriberNotificationsReducers;
