import React from 'react';
import { asField } from 'informed';
import PhoneNumberField from 'components/base/phone_number_field';

const PhoneNumberFieldSelect = asField(({ fieldApi, fieldState, disabled, events = {}, placeholder, initialCountry, className }) => {
  const { value } = fieldState;
  const { setValue } = fieldApi;

  return (
    <PhoneNumberField
      value={value}
      onChange={(phoneNumber) => {
        setValue(phoneNumber);
        events.onChange && events.onChange(phoneNumber);
      }}
      disabled={disabled}
      placeholder={placeholder}
      customInputClass={className}
      initialCountry={initialCountry} // Pass the initial country value as a prop
    />
  );
});

export default PhoneNumberFieldSelect;

