import React, { useCallback, useContext, useReducer, useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Show from 'components/pages/subscriber/permit_applications/show';
import CancelApplicaiton from '../cancel';
/* Assets */
import styles from './index.module.sass'
/* API */
import { cancel } from 'api/webapp/permit_application';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Apply from '../apply_permit/index'
import { createPermitApplication} from 'api/app/apply_permit';
import Confirmation from '../apply_permit/confirmation'
import AddVehicle from '../add_vehicle'
import SharePermit from '../apply_permit/shared_permit'

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const PermitModal = (props) => {
  const { record, openModal, modalType,setListElement, currentSubscriber } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [permitName, setPermitName] = useState()
  const [errors, setErrors] = useState({})
  const [commuterId, setCommuterId] = useState()
  const [userType, setUserType] = useState() 
  const [commuterVehicleAttributes, setCommuterVehicleAttributes] = useState({})
  const [vmanufacturers, setVManufacturers] = useState([])
  const [sharedType, setSharedType] = useState()
  const [closeAllModal, setCloseAllModal] = useState()

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    if(state.innerModal === 'add_vehicle' || state.innerModal === 'share_permit'){
      setState('innerModal', null)
      setErrors({})
      if(closeAllModal){openModal();}
    }else {
      openModal();
      localStorage.removeItem('allCommutersData') 
    }
  }, [openModal, setState , state.innerModal, closeAllModal])


  const InnerModal = (innerModalType) => {
    setState('innerModal', innerModalType)

  }
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const cancelApplication = useCallback(async (values) => {
    setState('isSaving', true)
    try {
      const response = await cancel({...values })
      if(response.status === 200){
        setListElement({...record, status: response.data.status})
        addAlertMessages([{
          type: 'notice',
          text: 'Permit application was succesfully cancelled',
          onlyMessage: true
        }], 'center')
      }
    } catch (_error) {
      addAlertMessages([{
        type: 'notice',
        text: _error?.response.data?.errors?.status,
        onlyMessage: true
      }], 'center')
    }finally {
      setState('isSaving', false)
    }
  },[setState, setListElement, addAlertMessages, record])

 

  const createApplication = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await createPermitApplication({ data: { permit_application: data , allow_save: 1} })
      if(response.status === 200 || response.status === 201 ){
        closeModal()
        setState('innerModal', null)
        openModal('confirmation')
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
      closeModal()
      addAlertMessages([{
        type: 'danger',
        text: error?.response?.data?.errors?.permit_application? error?.response?.data?.errors?.permit_application : error?.response?.data?.errors?.base? error?.response?.data?.errors?.base : 'Something Went Wrong' ,
        onlyMessage: true
      }], 'center')
      setState('isSaving', false)
    } finally {
      setState('isSaving', false)
      setCloseAllModal(false)
    }

  },[setErrors, closeModal, addAlertMessages, openModal, setState])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'edit':
        return {title: 'Edit Permit Type', maxWidth: '715px'}
      case 'show':
        return {maxWidth: '715px'}
      case 'delete':
        return {title: 'Are you Sure?', maxWidth }
      case 'renew':
        return { maxWidth: '715px'}
      case 'cancel':
        return {title: 'Cancel Permit Application', maxWidth: '715px'}
      case 'activate_deactivate':
        return { title: `Permit Type ${record.status === 'active' ? 'Deactivation' : 'Activation' }`, maxWidth}
      case 'apply_permit':
        return {title: 'Apply for a Permit', maxWidth : '800px', maxHeight : '1000px'} 
        default:
        return {title: '', maxWidth}
    }
  },[modalType, record]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null); closeModal()}}
    />
  },[setState, closeModal]);


  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '98%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle}  mx-0 mb-2 p-0 text-capitalize text-center`}>{modalProps().title}</p>

        { modalType === 'show' && <Show record={record} closeModal={closeModal} /> }
        {
          modalType === 'apply_permit' && 
          <Apply
            state={state}
            setState={setState}
            closeModal={closeModal}
            applyPermitApplication={createApplication}
            setErrors={setErrors}
            errors={errors}
            openModal={openModal}
            permitName={permitName}
            setPermitName={setPermitName}
            isSaving={state.isSaving}
            currentSubscriber={currentSubscriber}
            openInnerModal={InnerModal}
            commuterId={commuterId}
            setCommuterId={setCommuterId}
            setFormApi={setFormApi}
            formApiRef={formApiRef}
            setUserType={setUserType}
            commuterVehicleAttributes={commuterVehicleAttributes}
            setVManufacturers={setVManufacturers}
          />
        }
        {
          modalType === 'confirmation' && 
          <Confirmation
            closeModal={closeModal}
            permitName={permitName}
            sharedType={sharedType}
          />
        }

        {
          modalType === 'cancel' && 
          <CancelApplicaiton
            state={state}
            setState={setState}
            closeModal={closeModal}
            cancelApplication={cancelApplication}
            setErrors={setErrors}
            errors={errors}
            openModal={openModal}
            setPermitName={setPermitName}
            isSaving={state.isSaving}
            currentSubscriber={currentSubscriber}
            record={record}
          />
        }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            backdropClassName={styles.backdropC}
            style={{maxWidth: '500', width: '100%'}}
          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody>
              {state.innerModal === 'add_vehicle' && 
                <AddVehicle
                  renewPermit={"add_vehicle"}
                  closeModals={() => {closeModal(); setState('innerModal', null)}}
                  record={record}
                  isSaving={state.isSaving}
                  errors={errors}
                  setErrors={setErrors}
                  setState={setState}
                  commuterId={commuterId}
                  setFormApi={setFormApi}
                  formApiRef={formApiRef}
                  userType={userType}
                  setCommuterVehicleAttributes={setCommuterVehicleAttributes}
                  commuterVehicleAttributes={commuterVehicleAttributes}
                  manufacturers={vmanufacturers}
                  
                />
              }
              {state.innerModal === 'share_permit' && 
                <SharePermit
                  closeModals={() => {closeModal(); setState('innerModal', null)}}
                  isSaving={state.isSaving}
                  errors={errors}
                  setErrors={setErrors}
                  setState={setState}
                  setFormApi={setFormApi}
                  formApiRef={formApiRef}
                  applyPermitApplication={createApplication}
                  setSharedType={setSharedType}
                  sharedType={sharedType}
                  setCloseAllModal={setCloseAllModal}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal;