import React from 'react'
import { Col, Container } from 'reactstrap'
/* Assets */
import styles from '../index.module.sass'
import Button from 'components/base/button'

const MtaFetch = ({closeModal, fetchVehicleInfo}) => {

  return (
    <Container>
      <Col className={`${styles.modalTitle} mb-4 mb text-center`}>Retrieve Vehicle Information</Col>
      <Col className={styles.modalContent} xs={11}>
        <span className='d-block'>The vehicle’s LPN is not registered on the system.</span>
        <span className='d-block'>Would you like to fetch vehicle information from the motor vehicle authority?"</span>
      </Col>
      <Col className='d-flex justify-content-center align-items-center mt-4'>
        <Button 
          className={`${styles.btn} ${styles['btn-light']} mr-3`}
          onClick={closeModal}
        >
          No
        </Button>
        <Button 
          className={`${styles.btn} ${styles['btn-primary']}`}
          onClick={() => { fetchVehicleInfo(); closeModal()}}
        >
          Yes
        </Button>
      </Col>
    </Container>
  )
}

export default MtaFetch;
