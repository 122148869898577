import React from 'react'
import { ReactComponent as MinusIcon } from 'assets/minus-circle-svgrepo-com.svg';
import styles from './index.module.sass';
import { map } from 'underscore';
import { useSelector } from 'react-redux';
import Action from './action';

const TableRow = (props) => {
  const { record, openModal, setRecord, modalType, code } = props
  const selectedRecordId = useSelector(state => state.permit_type.records.record?.id)

  const actions = [
    {
      icon: <MinusIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      onClick: () => {code && openModal('remove')},
      text: 'Remove',
      isActive: modalType === 'remove' && selectedRecordId === record.id
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td >{record?.name}</td>
      <td className='text-capitalize'>{record?.status}</td>
      <td className='text-capitalize'>{record?.payment_type}</td>
      <td>{record?.issued_to === null ? '0' : record?.issued_to} Users</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center`} >
          {  map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow
