import React, { useCallback, useEffect } from 'react'
import TableRow from './table_row';
/* Api */
import { filterFetcherDashboard } from 'api/themes';
/* Helpers */
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import { list as selectList } from "selectors/list";

const TableData = (props) => {
  const {listType, startFetching, setList, list, setRecord, filters, isResourceFetching, selectedRecord, activeTab, modalType, openModal, match, history, setListElement } = props

  const filterThemes = async () => {
    startFetching(
      filterFetcherDashboard(Object.assign({ ...match.params }, { query: {theme_category: listType} }))
    )
      .then((res) => {
        setList(selectList(res));
      })
      .catch((error) => console.log(error));
  }

  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        key={record.id} 
        record={record} 
        selectedRecordId={selectedRecord.id} 
        setRecord={setRecord}
        openModal={openModal}
        modalType={modalType}
        history={history}
        match={match}
        listType={listType}
        setListElement={setListElement}
        filterThemes={filterThemes}
      />
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list, setRecord, selectedRecord.id, modalType, openModal])


  useEffect(() => {
    filterThemes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setList, startFetching, match.params, listType])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcherDashboard}
      paginationQuery={filters}
      total={list?.length}
      columns={
        <React.Fragment>
            <th style={{width: "100%", minWidth: "210px"}} attr="event_name">Name</th>
            <th style={{width: "100%",minWidth: "100px"}} attr="applyAction" disableSort> </th>
            <th className='d-flex justify-content-center' style={{width: "100%",minWidth: "110px"}} attr="actions" disableSort> Action </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Themes'
    />
  )
}

export default TableData;