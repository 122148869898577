import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Col, Row, Spinner,Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { Tabs, Space, ConfigProvider, Input, notification, Alert } from 'antd';
import WebAppHeader from 'components/base/webapp_header';
import styles from './index.module.sass';
import Button from 'components/base/button';
import { ReactComponent as DetailsIcon } from 'assets/details_icon.svg'
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { index, verify, verifyAndGetEvent } from 'api/webapp/city_events'
import { ReactComponent as LocationIcon } from 'assets/location_icon.svg'
import { displayDate } from 'components/helpers';
import EventModal from './event_modal';
import FilterForm from './filterForm';
import { ReactComponent as SearchIcon } from 'assets/search_icon.svg';
import OtpForm from 'components/modules/otp_form';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from 'underscore';
import PrivateEventDetails from './get_details/index';
import ThemeProvider from 'subscriberThemeProvider';

const { TabPane } = Tabs;

const Index = (props) => {
  // eslint-disable-next-line
  const [selectedTab, setSelectedTab] = useState('city')
  const [selectedSubTab, setSelectedSubTab] = useState('upcoming');
  const [isFetching, setIsFetching] = useState(false)
  const [events, setEvents] = useState()
  const [filteredEvents, setFilteredEvents] = useState([])
  const [event, setEvent] = useState([{}])
  const [showFilter, setShowFilter] = useState(false);
  const { match } = props
  const [filters, setFilters] = useState(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [getVerificationCode, setGetVerificationCode] = useState(false)
  const [requestingCode, setRequestingCode] = useState(false)
  const [eventID, setEventID] = useState('')
  const [api, contextHolder] = notification.useNotification();
  const [errors, setErrors] = useState(null)
  const [verificationCode, setVerificationCode] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false)
  const [verified, setVerified] = useState(false)
  const [privateEvent, setPrivateEvent] = useState(null)
  const { t } = useTranslation()

  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: message,
      style: {
        width: 400,
      },
    });
  };
  // eslint-disable-next-line
  const [position, setPosition] = useState(['left', 'right']);

  const [modalType, setModalType] = useState()

    const openModal = useCallback((modalType) => {
      let val
      // Evaluate modalType
      switch (true) {
        case modalType === 'details':
          val = 'details'
          break;
        case modalType === 'get_pass':
          val = 'get_pass'
          break;
        case modalType === 'location':
          val = 'location'
          break;
        case modalType === 'purchase_event_parking':
          val = 'purchase_event_parking'
          break;
        case modalType === 'confirmation':
          val = 'confirmation'
          break;
        case modalType === 'preview':
          val = 'preview'
          break;
        default:
          break;
      }
      setModalType(val)
    },[setModalType])
  
  
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  
  const onPrimaryTabChange = (key) => {
    setSelectedTab(key)
  };

  const onSecondaryTabChange = (key) => {
    setShowFilter(false)
    setFilters(null);
    setSelectedSubTab(key)
  };
  
  useEffect(() => {
    const fetchEvents = async() => {
      setIsFetching(true)
      try {
        const response = await index({status: selectedSubTab, filters: null})
        setEvents(response?.data)
        setFilteredEvents(response?.data)
      } catch (_error) {
        console.log(_error)
      } finally {
        setIsFetching(false)
      }
    }
    fetchEvents()
  }, [selectedSubTab])

  useEffect(() => {
    const results = events?.filter(event =>
      event?.event_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilteredEvents(results);
  }, [searchTerm, events]);
  

  useEffect(()=>{
    setEvents([])
  },[selectedSubTab])

  const sendVerificationCode = async (value) => {
    setVerificationCode('')
    setErrors(null);
    setRequestingCode(true);
    setGetVerificationCode(false);
    if(!eventID){
      setErrors(t("events.private_events.verification.please_enter_event_id"));
      return
    }
    try {
      const response = await verify({ event_id: eventID ? eventID : value });
      setGetVerificationCode(response?.data.message ? true : false);
      setEventID(eventID ? eventID : value);
    } catch (error) {
      console.log(["error", error?.response?.data?.errors]);
      setErrors(t("events.private_events.verification.no_active_events"));
    } finally {
      setRequestingCode(false);
    }
  };

  const verifyCode = async () => {
    setIsVerifying(true);
    try {
      const response = await verifyAndGetEvent({
        eventID: eventID,
        verificationCode: verificationCode,
      });
      if (!response.data.private_event && verificationCode) {
        setPrivateEvent(response.data)
        setVerified(true)
      }
      setErrors(response?.data?.verification_code && !verificationCode ? t("events.private_events.verification.cannot_be_blank") : t("events.private_events.verification.incorrect_code") );
    } catch (error) {
      console.log(["error", error?.response?.data?.errors]);
      setErrors(error?.response?.data?.errors?.private_event);
    } finally {
      setIsVerifying(false);
      setVerificationCode('')
    }
  };

  useEffect(() => {
    let interval = null;
    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimeLeft((timeLeft) => {
          if (timeLeft <= 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return null;
          }
          return timeLeft - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  const handleResendClick = () => {
    setIsButtonDisabled(true);
    setTimeLeft(120);
    sendVerificationCode();
  };

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleEventIDInput = (e) => {
    setErrors("")
    setEventID(e.target.value)
  }
  const OperationsSlot = {
    left: (
      <Row className={styles.searchField}>
        <p className={styles.eventFieldLabel}>{`${t("events.private_events.enter_your_event_id")}`}: </p>
        <Input.Search
          placeholder={t("events.private_events.enter_event_id")}
          size="medium"
          enterButton={t("events.private_events.search")}
          className={styles.searchEvent}
          onSearch={sendVerificationCode}
          onChange={(e) => handleEventIDInput(e)}
        />
      </Row>
    ),
    right: (
      <Button
        onClick={() => openModal("purchase_event_parking")}
        className={styles.purchaseBtn}
      >
        {t("events.private_events.purchase_event_parking_btn")}
      </Button>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position, OperationsSlot]);

  const btnSpinner = (props = {}) => {
    return (
      <Row className="d-flex justify-content-center align-items-center">
        <Spinner {...props} size={props?.size ? props.size : 'l'} color="#6f48eb" />
      </Row>
    );
  };
  const handleClick = (item) => {
    setEvent(item);
  }
  const renderCards = (data) =>
    data?.map((item, index) => (
      <Col key={index} xs={24} sm={12} md={8} lg={6} xl={3} className={styles.cardCol}>
        <Card title={item?.event_name} className={styles.card} onClick={() => handleClick(item)}>
          <CardHeader className={styles.cardHeader}><p className={styles.cardTitle}>{item?.event_name}</p>
            <Button className={styles.squareButton} onClick={()=> openModal('details')}><DetailsIcon className={styles.detailsIcon}/></Button>
          </CardHeader>
          <CardBody>
            <div className={styles.detailsSection}> 
              <p className={styles.location}>{t("events.city_events.location")}</p>
              <p><LocationIcon className={styles.LocationIcon} />{item?.location?.full_address}</p>
              <div className={styles.detailsSection}><p className={styles.location}>{t("events.city_events.date")}</p>
                <p>{displayDate(item?.event_dates_and_times[0]?.date, 'Do MMM YYYY (dddd)')} - {displayDate(item?.event_dates_and_times[item?.event_dates_and_times.length - 1].date, 'Do MMM YYYY (dddd)')}</p>
              </div>
              <p className={styles.location}>{t("events.city_events.description")}</p>
              <p className={styles.description}>{item?.description?.split(' ').slice(0,15).join(' ')}</p>
            </div>
          </CardBody>
            <Col className='d-flex justify-content-center m-0 p-0'><Button className={styles.centerLink} onClick={()=> openModal('details')} >{`${t("events.city_events.more")}...`}</Button></Col>
            {selectedSubTab==='upcoming' && item.event_parking_lots.map((lot) => {return lot.passes_sold_out}).includes('NO')  && <Button className={styles.getPassBtn} onClick={() => openModal('get_pass')}>{`${t("events.city_events.get_parking_pass")} (${item?.attendees_cost_type==='paid'? `$ ${item?.attendees_cost}` : t("events.city_events.free")})`}</Button>}
            {selectedSubTab==='upcoming' && !item.event_parking_lots.map((lot) => {return lot.passes_sold_out}).includes('NO') && <Button className={styles.soldOut}>{t("events.city_events.sold_out")}</Button>}
        </Card>
      </Col>
   ));

  const renderCityEventsTabsContent = () => {
    const operations = (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A9CED",
          },
        }}
      >
        <Row className="d-flex">
          <Space direction="horizontal">
            <Input
              placeholder={t("events.city_events.search_by_event_name")}
              size="large"
              className={styles.searchButton}
              suffix={<SearchIcon/>}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Space>
          <Button
            onClick={toggleFilter}
            className={`${styles.btnFilter} ${showFilter ? styles.activeTab : '' }`}
            icon={<FilterIcon className={`${showFilter ? styles.activeIcon : ''}`}/>}
            size="md"
          />
        </Row>
      </ConfigProvider>
    );
    return (
      <React.Fragment>
        <Tabs
          className={styles.tabs2}
          defaultActiveKey="1"
          onChange={onSecondaryTabChange}
          tabBarStyle={{
            background: "#ffffff",
            borderRadius: "10px",
            padding: "10px",
          }}
          tabBarExtraContent={operations}
        >
          <TabPane
            key="upcoming"
            tab={
              <p style={{ fontSize: "20px", padding: "0", margin: "0" }}>
                {t("events.city_events.upcoming")}
              </p>
            }
          >
            <Collapse isOpen={showFilter} className={styles.collapse}>
              <Card className="border-0">
                <CardBody className="p-0 m-0">
                  <Row
                    className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-0 pb-3 mx-0`}
                    sm="12"
                  >
                    <FilterForm
                      filters={filters}
                      setFilters={setFilters}
                      setIsFetching={setIsFetching}
                      setEvents={setEvents}
                      selectedSubTab={selectedSubTab}
                      t={t}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Collapse>
            <Row gutter={[16, 16]}>{renderCards(filteredEvents)}</Row>
            {isFetching && (
              <Row className="d-flex jusitfy-content-center align-items-center">
                {btnSpinner({ className: `${styles.loader} spinner-border` })}
              </Row>
            )}
            {isEmpty(filteredEvents) && !isFetching && (
              <Row className={styles.resultText}>
                <p>No Results Found !</p>
              </Row>
            )}
          </TabPane>
          <TabPane
            key="expired"
            tab={
              <p
                style={{
                  fontSize: "20px",
                  padding: "0",
                  margin: "0",
                  cardPadding: "8px 19px",
                }}
              >
                {t("events.city_events.expired")}
              </p>
            }
          >
            <Collapse isOpen={showFilter} className={styles.collapse}>
              <Card className="border-0">
                <CardBody className="p-0 m-0">
                  <Row
                    className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`}
                    sm="12"
                  >
                    <FilterForm
                      filters={filters}
                      setFilters={setFilters}
                      setIsFetching={setIsFetching}
                      setEvents={setEvents}
                      selectedSubTab={selectedSubTab}
                      t={t}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Collapse>
            <Row gutter={[16, 16]}>
              {isFetching
                ? btnSpinner({ className: `${styles.loader} spinner-border` })
                : renderCards(filteredEvents)}
            </Row>
            {isEmpty(filteredEvents) && !isFetching && (
              <Row className={styles.resultText}>
                <p>No Results Found !</p>
              </Row>
            )}
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  };

  const renderPrivateEventsTabsContent = () => {
    return (
      <Tabs
        className={styles.tabs2}
        defaultActiveKey="1"
        onChange={onSecondaryTabChange}
        tabBarStyle={{
          background: "#ffffff",
          borderRadius: "10px",
          padding: "5px",
        }}
        tabBarExtraContent={slot}
      >
        {requestingCode && !errors && (
          <Row className="d-flex justify-content-center">
            {btnSpinner({ className: `${styles.loader} spinner-border` })}
          </Row>
        )}
        {errors && (
          <Row className="d-flex justify-content-center">
            <Space
              direction="vertical"
              style={{ width: "30%", textAlign: "center" }}
            >
              <Alert
                className={styles.errorMsg}
                message={errors}
                type="error"
              />
            </Space>
          </Row>
        )}
        {getVerificationCode && !verified &&  (
          <Row className="d-flex justify-content-center align-items-center">
            <Col>
              {!errors && (
                <Row className="d-flex justify-content-center align-items-center">
                  <Space direction="vertical" className={styles.spaceStyle}>
                    <Alert
                      className={styles.successMsg}
                      message={t(
                        "events.private_events.verification.sent_code_msg"
                      )}
                      type="success"
                      closable={getVerificationCode}
                    />
                  </Space>
                </Row>
              )}
              <p className={styles.checkTxt}>
                {t("events.private_events.verification.check_your_email")}
              </p>
              <p className={styles.expiryTxt}>
                {t("events.private_events.verification.expire_shortly")}
              </p>
              <div className={styles.verficationCodeFields}>
                <OtpForm
                  value={verificationCode}
                  setValue={setVerificationCode}
                />
              </div>
              <Row className="d-flex justify-content-center">
                <Button className={styles.verifyBtn} onClick={verifyCode}>
                  {isVerifying
                    ? btnSpinner({
                        className: `${styles.loader} spinner-border`,
                        size: "sm",
                      })
                    : t("events.private_events.verification.verify")}
                </Button>
              </Row>
              {localStorage.getItem("selectedLanguage") !== "hi" && (
                <Row className="d-flex justify-content-center align-items-center">
                  <Col
                    className={
                      timeLeft !== null
                        ? styles.resendbtnCol1
                        : styles.resendbtnCol2
                    }
                  >
                    <Button
                      disabled={isButtonDisabled}
                      className={styles.resendBtn}
                      onClick={handleResendClick}
                    >
                      {t("buttons.resend")}
                    </Button>
                  </Col>
                  {timeLeft !== null && (
                    <Col className={styles.btnCol2}>
                      <Trans
                        i18nKey="events.private_events.verification.timer_minutes"
                        components={{ 1: <span className={styles.timer} /> }}
                        values={{ time: formatTime() }}
                      />
                    </Col>
                  )}
                </Row>
              )}
              {localStorage.getItem("selectedLanguage") === "hi" && (
                <Row className="d-flex justify-content-center align-items-center">
                  {timeLeft !== null && (
                    <Col className={styles.btnCol2Hi}>
                      <Trans
                        i18nKey="events.private_events.verification.timer_minutes"
                        components={{ 1: <span className={styles.timer} /> }}
                        values={{ time: formatTime() }}
                    />
                    </Col>
                  )}
                  <Col
                      className={
                        timeLeft !== null
                          ? styles.resendbtnCol1Hi
                          : styles.resendbtnCol2Hi
                      }
                    >
                      
                    <Button
                      disabled={isButtonDisabled}
                      className={styles.resendBtn}
                      onClick={handleResendClick}
                    >
                      {t("buttons.resend")}
                    </Button>
                  </Col>
                </Row>
              )}
              <p className={styles.instruction}>
                {t("events.private_events.verification.check_spam")}
              </p>
            </Col>
          </Row>
        )}
        {verified && <Row className='w-100 m-0 p-0'> <PrivateEventDetails event={privateEvent} match={match}/> </Row>}
      </Tabs>
    );
  };

  return (
    <ThemeProvider>
      <WebAppHeader />
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{t("events.title")}</p>
      <Col className="d-flex m-0 p-0 justify-content-center pt-3">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A9CED",
          },
        }}
      >
        <Tabs
          className={styles.tabs}
          defaultActiveKey="1"
          onChange={onPrimaryTabChange}
        >
          <TabPane
            key="city"
            tab={
              <p style={{ fontSize: "24.9px", padding: "0", margin: "0" }}>
                {t("events.city_events.title")}
              </p>
            }
          >
            {renderCityEventsTabsContent()}
          </TabPane>
          <TabPane
            key="private"
            tab={
              <p style={{ fontSize: "24.9px", padding: "0", margin: "0" }}>
                {t("events.private_events.title")}
              </p>
            }
          >
            {renderPrivateEventsTabsContent()}
          </TabPane>
        </Tabs>
        </ConfigProvider>
      </Col>
      <EventModal t={t} contextHolder={contextHolder} openNotificationWithIcon={openNotificationWithIcon} event={event} openModal={openModal} modalType={modalType} />
    </ThemeProvider>
  );
};

Index.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClickFilter: PropTypes.func,
  label: PropTypes.string,
};

export default Index;