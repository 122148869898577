import React, { useCallback } from 'react'
import { displayDate } from 'components/helpers'
import { searchFields } from 'components/helpers/fields/broadcast_messages/search'
import Button from 'components/base/button'
import { filterFetcher } from "api/broadcast_messagse";
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import styles from './index.module.sass'
import { renderFields } from 'components/base/forms/common_form';

const FilterForm = (props) => {
  const {adminsList, categories ,filters, setFilters, match, setList, startFetching, listType, setCount, setFormApi, formApiRef } = props;
  const attrs = {adminsList, categories, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const filterBroadcastMessages= useCallback(async (filters={}) => {
    const { sent_date } = filters
    if(sent_date && !sent_date?.to) { delete sent_date.to}
    if(sent_date && !sent_date?.from) { delete sent_date.from}

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(res.data);
        setCount(res?.headers['x-total'])
      })
      .catch(error => console.log(error))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      status: listType,
      sent_date: {
        from: displayDate(filterValues.sent_date?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.sent_date?.to, 'yyyy-mm-dd')
      }
    }
    if(listType === 'sent'){
      Object.assign(filters, {...filters, status: ['resent', 'sent']})
    }
    setFilters(filters);
    filterBroadcastMessages(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      sent_date: { from: '', to: ''}, 
      sender: '', 
      recipient: '',
      category_ids: '', 
    })
    const filters = {status: listType}
    if(listType === 'sent'){
      Object.assign(filters, {...filters, status: ['resent', 'sent']})
    }
    setFilters(filters)
    filterBroadcastMessages(filters)
  }

  const resetDates = () => {
    setFilters({...filters, sent_date: { from: '', to: ''}})
    formApiRef.current.setValues({
      ...filters,
      sent_date: { from: '', to: ''},
    })
  }

  const initialValues = {
    ...filters,
  }

  const getFields = (fromDate, toDate) => searchFields({ ...attrs, fromDate, toDate})

  return (

      <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save}>
        {({formState}) => {
          const dateFrom = formState.values.sent_date?.from || ''
          const dateTo = formState.values.sent_date?.to || ''
          
          const fields = getFields(dateFrom, dateTo)
          return <Row className='m-auto'>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(0, 2), {lSize: 4, iSize: 8})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(2, 3), {lSize: 4, iSize: 8})}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={3}>
              {renderFields(fields.slice(3, 4), {lSize: 4, iSize: 8})}
            </Col>
            <Col className={styles.rightColumn} xs={3}>
              <span className={`${styles['fieldset-dateStroke']} position-absolute`}></span>
              {renderFields(fields.slice(4, 5), {lSize: 4, iSize: 8})}
              <span 
                role='button' 
                className={`${styles['fieldset-dateClear']} position-absolute`}
                onClick={resetDates}
              >
                Clear Date
              </span>
            </Col>
            <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
              <Button
                className={`${styles.btn} ${styles['background-primary']} mr-5`}
                type='submit'
              >
                Filter
              </Button>
              <Button
                type='button'
                onClick={resetFilters}
                className={`${styles.btn} ${styles['background-secondary']}`}
              >
                Reset
              </Button>
            </Col>
          </Row>
        }}
      </Form>

  )
}

export default FilterForm;
