import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/subscribers';
import connectList from 'components/modules/connect_list';
import resourceFetcher from 'components/modules/resource_fetcher';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import { filterFetcher } from 'api/subscribers';
import { showSetting } from 'api/violations';
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';
import SubscriberModal from '../subscriberModal';
import TableData from './tableData';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()
  const [listType, setListType] = useState('permitted')
  const [captureViolation, setCaptureViolation] = useState('inactive')
  const [captureNegative, setCaptureNegative] = useState(false)

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  useEffect(() => {
    const showViolationSetting = async () => {
      try {
        const response = await showSetting()
        setCaptureViolation(response?.data?.status)
        setCaptureNegative(response?.data?.unpaid_violation_negative_balance)
      } catch (error) {
        console.log(error)
      }
    }
    showViolationSetting()
  },[])

  const openCollapse = ['filters'].includes(activeTab)

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              openModal={openModal} 
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              modalType={modalType}
              openCollapse={openCollapse}
              listType={listType}
              setListType={setListType}
              captureViolation={captureViolation}
              captureNegative={captureNegative}
              setCaptureNegative={setCaptureNegative}
              setCaptureViolation={setCaptureViolation}
              {...props}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} modalType={modalType} openCollapse={openCollapse} activeTab={activeTab}/>
          </Col>
        </Row>
        <SubscriberModal
          {...props}
          modalType={modalType} 
          openModal={openModal}
          setActiveTab={setActiveTab}
          captureViolation={captureViolation}
          setCaptureViolation={setCaptureViolation}
          setCaptureNegative={setCaptureNegative}
          captureNegative={captureNegative}
        />
      </Container>
    </AccessControl>
  )
}

const resource = 'subscriber';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      addListElement: invoke(ADD_LIST_ELEMENT), 
      setListElement: invoke(SET_LIST_ELEMENT), 
      popListElement: invoke(POP_LIST_ELEMENT),
      setRecord: invoke(SET_SINGLE_RECORD)
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.subscriber.filters,
    record: state.subscriber.records.record
  }
}

Index.propTypes = {
  isResourceFetching: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  startFetching: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  record: PropTypes.object,
};

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {}, 
  SET_FILTERS
);