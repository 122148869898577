import React, { useCallback, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
import { fieldsEdit } from 'components/helpers/fields/violations/violators'
import styles from './index.module.sass';
import withFetching from 'components/modules/with_fetching';

const Edit = (props) => {
  const  { updateMeasures, closeModal, isSaving, selectedRecord } = props;
  const formApiRef = useRef();
  const [errors, setErrors] = useState({})

  const initialValues = {
    violation_type: `${selectedRecord?.violation_type?.display_name}`,
    repetitions_allowed: `${selectedRecord?.repetitions_allowed}`,
    measure_type: `${selectedRecord?.measure_type}`,
    status: `${selectedRecord?.status}`
  }

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const validateValues = (formState) => {
    const errors = {}
    const { repetitions_allowed, measure_type, status } = formState;
    if(!repetitions_allowed) { errors['repetitions_allowed'] = ['Maximum Repetitions is required']}
    if(!measure_type) { errors['measure_type'] = ['Measure is required']}
    if(!status) { errors['status'] = ['Status is required']}     
    return errors
  }

  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
  }
  
  const singleFieldProps = {
    lSize: 4,
    iSize: 8,
  }
  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    updateMeasures(values);
  },[updateMeasures]);
  
  
  const getFields = () => {
    return fieldsEdit({mute: false, ...attrs})
  }

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={styles.form} initialValues={initialValues} onSubmit={save} >

        {({ formState }) => {
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100'>                
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className='m-0 p-0' xs='12'>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs='12'>
                  { renderFields(newFields[1], { ...singleFieldProps, errors} )}
                </Col>
  
                <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs='12'>
                  { renderFields(newFields[2], { ...singleFieldProps,errors,})}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs='12'>
                  { renderFields(newFields[3], { ...singleFieldProps,errors,})}
                </Col>
                <Col className="d-flex justify-content-center mb-3 mt-3 ml-2" xs='12'>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={styles.button}  isLoading={isSaving}>
                    Save
                  </Button>
                </Col>
              </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Edit);
