const sessionDetails = (attrs) => {
    const { customInputClass='', customLabelClass='', customAddressInputClass, customIconInputClass, icon,locationIcon, customStatusInputClass} = attrs;
    return [
      {
        name: 'session_id',
        label: 'Parking Session Transaction ID',
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'parking_lot',
        label: 'Parking Lot',
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'date',
        label: 'Date',
        customInputClass,
        customLabelClass,
        disabled: true,
      },
     
      {
        name: 'rate',
        label: 'Rate',
        customInputClass: customIconInputClass,
        customLabelClass,
        icon: icon,
        disabled: true
      }, 
      {
        name: 'address',
        label: 'Address',
        customInputClass: customAddressInputClass,
        customLabelClass,
        icon: locationIcon,
        disabled: true,
      },
      {
        name: 'entry_time',
        label: 'Entry Time',
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'vehicle_lpn',
        label: 'Vehicle LPN',
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'exit_time',
        label: 'Exit Time',
        customInputClass,
        customLabelClass,
        disabled: true,
      }, 
      {
        name: 'total_cost',
        label: 'Total Cost',
        customInputClass: customIconInputClass,
        customLabelClass,
        disabled: true,
        icon: icon
      },
      {
        name: 'status',
        label: 'Status',
        customInputClass: customStatusInputClass,
        customLabelClass,
        disabled: true,
        mandatory: true,
      }, 
      
    ]
}
  
export default sessionDetails