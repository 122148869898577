const { FieldType } = require("components/helpers/form_fields");

const paymentFields = (attrs) => {
  const {
    customLabelClass = "",
    customInputClass = "",
    customDropdownInputClass,
    customExpiryDropdownInputClass,
    customExpiryLabel,
    years,
    customExpiryLabel2,
    months,
  } = attrs;  

  return [
    
    [
      {
        name: 'payment_amount',
        label: 'Payment Amount',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: "payment_methods",
        label: "Payment Method",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [
          {
            value: 0,
            label: "Google Pay",
          },
          {
            value: 1,
            label: "Credit Card",
          },
        ].map(({ value, label }) => {
          return { value, label };
        }),
        customInputClass: customDropdownInputClass,
        customLabelClass,
        placeholder: "Select Payment Method",
      },

    ],
    [      
      {
        name: "expiration_year",
        label: "Expiration Year",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: "Select a Year (YYYY)",
        options: years
          ? years.map((y) => {
              return { value: y, label: y };
            })
          : [],
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel2,
        disabled: false,
      },
      {
        name: "expiration_month",
        label: "Expiration Month",
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: "Select a Month (MM)",
        options: months.map((m) => {
          return { value: m < 10 ? `0${m}` : m, label: m < 10 ? `0${m}` : m };
        }),
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel,
        disabled: false,
      },

    ],
   
  ];
};


export { paymentFields };
