import { SubscriberActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records, filters } = reduceEntity(SubscriberActions);

const SubscriberReducers = combineReducers({
  index,
  records,
  filters
});

export default SubscriberReducers;
