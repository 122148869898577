import React from 'react'
import NavigationToolbar from 'components/base/navigationToolbar';


const ToolBar = () => {
  return (
    <NavigationToolbar
      backTab='/dashboard/permit_waiting_lists'
      className='d-flex justify-content-space-between'
    >
    </NavigationToolbar>
  )
}

export default ToolBar;