import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Delete = (props) => {

  const { deleteStaff, closeModal, selectedRecord } = props;
  
  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='mt-3'>
          {`This will delete ${selectedRecord?.name}'s account. The user will no longer be able to access the Park Smart Valet application.`}
        </Col>
        <Col className='mb-4 mt-1'>
          Are you sure?
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          No
        </Button>
        <Button type='button' onClick={()=>  deleteStaff()} className={styles.submitButton}>
          Yes
        </Button>
      </Col>
    </Col>
  )
}

export default Delete;