import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../../../lang/en.json";
import esTranslation from "../../../lang/es.json";
import hiTranslation from "../../../lang/hi.json";

i18n.use(initReactI18next).init({
  resources: {
    english: { translation: enTranslation },
    spanish: { translation: esTranslation },
    hindi: { translation: hiTranslation },
  },
  lng: localStorage.getItem("selectedSubscriberLanguage")? localStorage.getItem("selectedSubscriberLanguage") : localStorage.getItem("selectedLanguage"), // Default language
  fallbackLng: "english", // Fallback language
  interpolation: {
    escapeValue: false, 
  },
});

export default i18n;
