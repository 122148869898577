import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';
import resourceApi from 'components/modules/resource_api';

const { index, show, create, update, destroy } = resourceApi('broadcast_messages');

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params
  
  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const addCategory = (params = {}) => {
  const { data } = params;
  return fetchApi(generatePath(`dashboard/select_lists/broadcast_message_categories`), {
    method: 'POST',
    data
  })
}; 

const resend = (params = {}) => {
  const { id } = params;
  return fetchApi(generatePath(`dashboard/broadcast_messages/${id}/resend`), {
    method: 'PUT'
  })
};

export { index, filterFetcher, show, addCategory, create, update, destroy, resend };
