import React, { useState, useEffect } from "react";
import { Collapse, Button, Label, Col } from "reactstrap";
import styles from "./index.module.sass";
import { ReactComponent as ChevronUp } from "assets/chevron_up.svg";
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";
import BaseButton from "components/base/button";
import withFetching from "components/modules/with_fetching";
import { rewardHistoryFetcher } from "api/user_rewards";

const RewardsHistory = (props) => {
  const { record, closeModal, startFetching, userId } = props;
  const [earnedRewards, setEarnedRewards] = useState([]);
  const [redeemedRewards, setRedeemedRewards] = useState([]);
  const [isEarnedOpen, setIsEarnedOpen] = useState(false);
  const [isRedeemedOpen, setIsRedeemedOpen] = useState(false);

  const toggleEarnedOpen = () => setIsEarnedOpen(!isEarnedOpen);
  const toggleRedeemedOpen = () => setIsRedeemedOpen(!isRedeemedOpen);

  useEffect(() => {
    const fetchRewardHistory = async () => {
      if (!record?.id) return;
      try {
        const response = await startFetching(
          rewardHistoryFetcher({ user_id: userId, reward_id: record.id })
        );
        if (response.status === 200) {
          const { data } = response;
          setRedeemedRewards(
            data.filter((d) => {
              return d?.status === "redeemed";
            })
          );
          setEarnedRewards(
            data.filter((d) => {
              return d?.status === "awarded";
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchRewardHistory();
  }, [record, startFetching, userId]);

  return (
    <div className={styles.modalForm}>
      <div className={styles.formGroup}>
        <Col className="text-left" xs={4}>
          <Label for="field1" className={styles.label}>
            Points Earned
          </Label>
        </Col>
        <Col xs={8}>
          <div className={styles.dropdown}>
            <Button
              className={`${styles.dropdownHeader} ${
                styles["dropdownHeader-success"]
              } ${isEarnedOpen ? styles["dropdownHeader-open"] : ""}`}
              onClick={toggleEarnedOpen}
              style={{ backgroundColor: isEarnedOpen ? "#3A9CED" : "" }}
            >
              <Col
                className="d-flex justify-content-flex-start m-0 p-0"
                xs={10}
              >
                <span>+ {record.points_earned} Points</span>
              </Col>
              <Col className="d-flex justify-content-flex-end pl-3 ml-2" xs={2}>
                {isEarnedOpen ? (
                  <ChevronUp width="16" height="16" className={"svg-white"} />
                ) : (
                  <ChevronDown width="16" height="16" className={"svg-dark"} />
                )}
              </Col>
            </Button>
            <Collapse isOpen={isEarnedOpen} className={styles.collapse}>
              <div className={styles.scrollableContent}>
                {earnedRewards?.map((reward, idx) => {
                  return (
                    <div key={idx} className={styles.formInput}>
                      <Col
                        className={`${styles.colStyle} d-flex justify-content-flex-start align-items-center m-0 p-0 pl-2`}
                        xs={4}
                      >
                        <span>{reward?.points} Points</span>
                        <span className={styles.stroke}>|</span>
                      </Col>
                      <Col
                        className={`${styles.colStyle2} d-flex justify-content-flex-end align-items-center m-0 p-0`}
                        xs={8}
                      >
                        <span className={styles.createdText}>
                          {reward?.created_at_parsed}
                        </span>
                      </Col>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        </Col>
      </div>
      <div className={styles.formGroup}>
        <Col className="text-left" xs={4}>
          <Label for="field2" className={styles.label}>
            Points Redeemed
          </Label>
        </Col>
        <Col xs={8}>
          <div className={styles.dropdown}>
            <Button
              className={`${styles.dropdownHeader} ${
                styles["dropdownHeader-danger"]
              } ${isRedeemedOpen ? styles["dropdownHeader-open"] : ""}`}
              onClick={toggleRedeemedOpen}
            >
              <Col
                className="d-flex justify-content-flex-start m-0 p-0"
                xs={10}
              >
                <span>- {record.points_redeemed} Points</span>
              </Col>
              <Col className="d-flex justify-content-flex-end pl-3 ml-2" xs={2}>
                {isRedeemedOpen ? (
                  <ChevronUp width="16" height="16" className={"svg-white"} />
                ) : (
                  <ChevronDown width="16" height="16" className={"svg-dark"} />
                )}
              </Col>
            </Button>
            <Collapse isOpen={isRedeemedOpen} className={styles.collapse}>
              <div className={styles.scrollableContent}>
                {redeemedRewards?.map((reward, idx) => {
                  return (
                    <div key={idx} className={styles.formInput}>
                      <Col
                        className={`${styles.colStyle} d-flex justify-content-flex-start align-items-center m-0 p-0 pl-2`}
                        xs={4}
                      >
                        <span>{reward?.points} Points</span>
                        <span className={styles.stroke}>|</span>
                      </Col>
                      <Col
                        className={`${styles.colStyle2} d-flex justify-content-flex-end align-items-center m-0 p-0`}
                        xs={8}
                      >
                        <span className={styles.createdText}>
                          {reward?.created_at_parsed}
                        </span>
                      </Col>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        </Col>
      </div>
      <Col className="d-flex justify-content-center pt-3 m-0" xs={12}>
        <BaseButton
          type="button"
          onClick={closeModal}
          className={`${styles.btn} ${styles["btn-secondary"]}`}
        >
          Close
        </BaseButton>
      </Col>
    </div>
  );
};

export default withFetching(RewardsHistory);