import React, { useEffect, useState, useCallback } from "react";
import { Card, Row, Col, ConfigProvider } from "antd";
import styles from "./index.module.sass";
import { ReactComponent as FilterIcon } from "assets/filter_icon.svg";
import Button from "components/base/button";
import { index } from "api/webapp/transactions";
import { displayUnixTimestamp } from "components/helpers";
import { isEmpty } from "underscore";
import "./card.css";
import TransactionModal from "../transaction_modal";

const Transactions = () => {
  const [activeTab, setActiveTab] = useState("all");
  // eslint-disable-next-line
  const [showFilter, setShowFilter] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // To store the selected transaction details
  const [modalType, setModalType] = useState()

  const openModal = useCallback((modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'show':
        val = 'show'
        break;
      default:
        break;
    }
    setModalType(val)
  },[setModalType])

  useEffect(() => {
    const fetchTransactions = async () => {
      const filters = `${
        activeTab === "refunds"
          ? `payment_type=refund`
          : activeTab !== "all"
          ? `purpose=${activeTab}`
          : ""
      }`;
      try {
        setIsFetching(true);
        const response = await index(filters);
        setTransactions(response?.data);
      } catch (_error) {
        console.log(_error)
      } finally {
        setIsFetching(false);
      }
    };
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, setTransactions]);

  const tabsList = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "parking_session",
      label: "Sessions",
    },
    {
      key: "permit",
      label: "Permits",
    },
    {
      key: "citation_ticket",
      label: "Citations",
    },
    {
      key: "refunds",
      label: "All Refunds",
    },
  ];

  const getLabel = (status) => {
    switch (status) {
      case "Success":
        return "success";
      case "In Progress":
        return "inProgress";
      case "Failed":
        return "failed";
      default: 
        return ""
    }
  };

  const handleCardClick = (record) => {
    setSelectedRecord(record);
    openModal('show')
  };


  return (
    <div className={styles.container}>
      <span className={styles.title}>Transaction History</span>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A9CED",
          },
        }}
      >
        <Card
          style={{
            width: "100%",
          }}
          tabList={tabsList}
          activeTabKey={activeTab}
          tabBarExtraContent={
            <Button
              className={`${styles.btnFilter} ${
                showFilter ? styles.activeTab : ""
              }`}
              icon={
                <FilterIcon
                  className={showFilter ? styles.activeIcon : ''}
                />
              }
              size="md"
            />
          }
          onTabChange={setActiveTab}
          tabProps={{
            size: "large",
          }}
          className={styles.mainCard}
        >
          {!isEmpty(transactions) && transactions?.map((item, index) => (
            <Card
              key={index}
              className={styles.card}
              loading={isFetching}
              hoverable={true}
              onClick={() => handleCardClick(item)}
            >
              <Row className={styles.rowContent} gutter={[16, 16]}>
                <Col xs={6} sm={6} md={6} lg={3} className={styles.hideOnMobile}>
                  <div className={styles.detail}>
                    {displayUnixTimestamp(item.date_and_time, "Do MMM YYYY")}
                  </div>
                  <div className={styles.label}>
                    At {displayUnixTimestamp(item.date_and_time, "h:mm A")}
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={3}>
                  <div className={styles.label}>Transaction ID</div>
                  <div className={styles.detail}>{item?.id}</div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={4}>
                  <div className={styles.label}>Parking Lot</div>
                  <div className={styles.detail}>{item?.parking_lot?.name}</div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={4}>
                  <div className={styles.label}>Type</div>
                  <div className={styles.detail}>{item?.payment_type}</div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={4}>
                  <div className={styles.label}>Payment Type</div>
                  <div className={styles.detail}>{item?.reason}</div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={4}>
                  <div className={styles.label}>Amount</div>
                  <div className={`${styles.detail} ${styles.green}`}>
                    ${item?.amount}
                  </div>
                </Col>
                <Col className={styles.btnCol} xs={6} sm={6} md={6} lg={2}>
                  <div className={styles.statusDiv}>
                    <div className={styles.label}>Status</div>
                    <div
                      className={`${styles.detail} ${
                        styles[`status-${getLabel(item.status)}`]
                      }`}
                    >
                      {item.status}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className={styles.dateTime}>
                {displayUnixTimestamp(item.date_and_time, "Do MMM YYYY")} at 
                <span className="pl-1">
                  {displayUnixTimestamp(item.date_and_time, "h:mm A")}
                </span>
              </div>
            </Card>
          ))}
          {isEmpty(transactions) && !isFetching && <Row className="d-flex justify-content-center text-center align-items-center pt-4 mt-3"> <p>No <span>
              {
                tabsList.map((t) => {return t.key === activeTab && activeTab!=="all"  ? activeTab === 'refunds' ? t.label.split(" ")[1] : t.label : ""} )
              }</span> Transactions Found
            </p></Row>}
        </Card>
      </ConfigProvider>
      <TransactionModal modalType={modalType} openModal={openModal} record={selectedRecord} getLabel={getLabel} />
    </div>
  );
};

export default Transactions;