import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { FieldType } from 'components/helpers/form_fields';
/* Base */
import {renderFieldsWithGrid} from "components/base/forms/common_form";
/* Modules */
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";
import styles from "./violation_fines.module.sass";
import { Form } from "informed";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const Rules = (props) => {

  const {parkingRules, formApiRef, setFormApi, setUpdatedParkingRules, state} = props

  const renderSaveButton = () => {
    const values = formApiRef.current.getValues();
    const rules = parkingRules.map((rule) => {
      return rule.id
    });
  
    const object = Object.values(values).map((value,index)=>{
      return [
        { 
          id: rules[index],
          fine: value,
        },
      ]
    })
    const formattedValues = object.map((value)=>{
      return value[0]
    })

    setUpdatedParkingRules(formattedValues)    
  }

  const fieldProps = (lSize, iSize) => ({
    lSize: 4,
    iSize: 7,
    events: {
      onChange: () => renderSaveButton()
    }
  })

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

  const getFields = (id, name) => {
    return [
      { 
        name: `parking_rules_fine_${id}`,
        label: `${name} $`,
        type: FieldType.NUMBER_FIELD,
        min: 0,
        tooltipWidth: '23px',
        tooltipHeight: '23px',
        tooltipClass: 'mb-3',
        tooltipEnd: name === "Unpaid" ? 'This amount will be added to the parking session charges': '',
        renderValue: value => value,
        customInputClass: (styles.formInput).concat(' ', styles.iconInput),
        customLabelClass: styles.formLabel,
        icon: dollarIcon()
      },
    ]
  }

  useEffect(() => {
    const names = parkingRules.map((rule) => {
      return "parking_rules_fine_" + rule.id  
    });
    const values = parkingRules.map((rule) => {
      return rule.fine ? rule.fine : 0;
    });
    const object = names.reduce((acc, name, index) => {
        return {
            ...acc,
            [name]: values[index],
        };
    }, {});
    formApiRef.current.setValues(object)
  }, [parkingRules, formApiRef, state])


  const fields =()=>{
    const rules = parkingRules.map((rule) => {
      return getFields(rule.id, rule.violation_type.display_name);
    });
    const fields = rules.map((feild) => {
      return feild[0]
    });
    return fields
  }

  const renderVFields =  () => {
    return  <Row className='m-auto align-items-center'>
      <Col className={`m-0 ${styles.fieldset}`} xs={13}>
        {renderFieldsWithGrid(fields(parkingRules),3,4, { ...fieldProps(), errors: {} })}
      </Col>
     
    </Row>
  }

  

  
  const renderForm =() => {
    return (
      <Form
        getApi={setFormApi}
        className={`mt-4 ${styles.form}`}
      >
      <div>
        {renderVFields(parkingRules)}
      </div>
    </Form>
    )
  }
  return (
    <React.Fragment>
        {renderForm()}
    </React.Fragment>
  );
}


Rules.propTypes = {
  parkingLotId: PropTypes.number.isRequired
};

export default withFetching(withCurrentUser(Rules));