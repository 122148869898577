import React, {useCallback} from 'react'
import PropTypes from 'prop-types';
import { filterFetcher } from 'api/booting_and_towings';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {
  
  const { list,setRecord, filters, isResourceFetching, openModal, modalType, activeTab, setSelectedRecord } = props

  const renderRecords = useCallback(() => {
    return list?.map((record) => {
      return <TableRow 
        key={record.id} 
        record={record} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
        setSelectedRecord={setSelectedRecord}
      />
    });
  },[list, modalType, setRecord, openModal, setSelectedRecord])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <React.Fragment>
          <th attr="plate_number" style={{ width: '25%', minWidth: '210px' }}>Vehicle LPN</th>
          <th attr="vehicles.manufacturer_id" style={{ width: '25%', minWidth: '210px' }}>Vehicle Manufacturer</th>
          <th attr="vehicles.model" style={{ width: '25%', minWidth: '210px' }}>Vehicle Model</th>
          <th attr="vehicles.color" style={{ width: '25%', minWidth: '210px' }}>Vehicle Color</th>
          <th 
            attr="actions"
            style={{ width: '20%', minWidth: '210px', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Boot Eligible Vehicles'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData