import React, { useCallback } from 'react'
import TableRow from './tableRow';
/* Api */
import { filterFetcher } from 'api/permit_applications';
/* Helpers */
import { filterFields } from 'components/helpers/fields/permit_applications/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { list, setRecord, filters, isResourceFetching, openModal, modalType, selectedRecord, activeTab } = props
  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        key={record.id} 
        record={record} 
        selectedRecordId={selectedRecord.id} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    });
  },[list, modalType, setRecord, openModal, selectedRecord.id])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <>
          <th attr="created_at" style={{ width: '15%', minWidth: '210px', }} >Submitted on</th>
          <th attr="status" style={{ width: '15%', minWidth: '100px', }} >Status</th>
          <th attr="permit_types.name" style={{ width: '20%', minWidth: '210px', }} >Permit Type</th>
          <th attr="application_type" style={{ width: '20%', minWidth: '210px', }} >Application Type</th>
          <th attr="applicant_name" style={{ width: '15%', minWidth: '210px', }} >
            Applicant Name
          </th>
          <th attr="vehicles.plate_number" style={{ width: '10%', minWidth: '130px', }} >
            Vehicle LPN
          </th>
          <th className='d-flex justify-content-center' attr="permit_applications.actions" style={{ width: '20%', minWidth: '210px', }} disableSort>Action</th>
        </>
      }
      renderRecords={renderRecords}
      entityName="Permit Applications"
    />
  )
}

export default TableData;
