import resourceApi from "components/modules/resource_api";
import fetchApi from 'components/modules/fetch_api';

const { index, create } = resourceApi("permit_type_codes");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, filters = {}, query } = params

  return index({
    page,
    perPage,
    query: {
       ...query,
       ...filters,
    },
  });
};

const update_code = (params) => {
  const { id, data } = params
  return fetchApi(`dashboard/permit_type_codes/${id}`, { method: 'PUT', data })
};

const delete_code = (params) => {
  const { id } = params
  return fetchApi(`dashboard/permit_type_codes/${id}`, { method: 'DELETE' })
};

const add_permit_type = (params) => {
  const { id, data } = params
  return fetchApi(`dashboard/permit_type_codes/${id}/add_permit_type`, { method: 'PUT', data })
};

const remove_permit_type = (params) => {
  const { id, data } = params
  return fetchApi(`dashboard/permit_type_codes/${id}/remove_permit_type`, { method: 'PUT', data })
};

export { filterFetcher, index, create, update_code, delete_code, add_permit_type, remove_permit_type};