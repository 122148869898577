import React, { useCallback } from "react";
import TableRow from "./table_row";
import { filterFetcher } from "api/system_logs";
import { filterLogs } from 'api/system_logs';
import styles from "./index.module.sass";
import { IndexTable } from "components/base/table";

const TableData = (props) => {
  const {
    list,
    setRecord,
    filters,
    isResourceFetching,
    selectedRecord,
    listType,
    logName,
    moduleName,
    routeName
  } = props;
  
  const renderRecords = useCallback(() => {
    return list?.map((record) => {
      return (
        <TableRow
          {...props}
          key={record.id}
          record={record}
          selectedRecordId={selectedRecord.id}
          setRecord={setRecord}
          listType={listType}
        />
      );
    });
  }, [list, setRecord, selectedRecord, listType, props]);

  return (
    <IndexTable
      {...props}
      className={styles.table}
      isFetching={() => isResourceFetching}
      filterFetcher={(listType === 'error' || listType === 'email') ? filterFetcher : filterLogs}
      resource='system_logs'
      paginationQuery={{...filters}}
      columns={
        <React.Fragment>
          <th attr="created_at" style={{ width: listType === 'error' ? '18%' : '50%', minWidth: "210px" }}>
            Timestamp
          </th>
          <th attr="terminal" style={{ width: listType === 'error' ? '4%' : '50%', minWidth: "210px" }}>
            Terminal
          </th>
          <th disableSort className={listType !== 'error' ? 'd-none' : ''} attr="message" style={{ width: "20%", minWidth: "210px" }}>
            End Point
          </th>
          <th disableSort className={listType !== 'error' ? 'd-none' : ''} attr="message" style={{ width: "50%", minWidth: "210px" }}>
            Message
          </th>
          <th
            className={`${listType === 'error' ? 'd-none' : ''} justify-content-center`}
            attr="actions"
            style={{ width: "20%", minWidth: "210px", paddingLeft: '63px' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName={`${moduleName === 'Permit' ? routeName?.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase())) : logName} Logs`}
    />
  );
};

export default TableData;