import React from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/permit_types';
/* Helpers */
import { filterFields } from 'components/helpers/fields/permit_types/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';
import { isEmpty } from 'underscore';

const TableData = (props) => {
  const { filters, isResourceFetching, setRecord, initialList, openModal, activeTab, modalType, code } = props
  const renderRecords = () => {
    return !isEmpty(initialList) && initialList?.list?.map((record) => {
      return ( <TableRow 
        key={record.id}
        code={code}
        record={record} 
        openModal={openModal} 
        setRecord={setRecord} 
        modalType={modalType}
      />);
    });
  }
  
  return (
    <IndexTable
      {...props}
      className={`${ activeTab === 'filters' ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching }
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={{ ...filters }}
      columns={
        <React.Fragment>
          <th attr="permit_types.name" style={{ width: '35%', minWidth: '210px' }} >Name</th>
          <th attr="permit_types.status" style={{width: '35%', minWidth: '210px' }} >Status</th>
          <th attr="permit_types.payment_type" style={{ width: '45%', minWidth: '210px' }} >Paid/Free</th>
          <th attr="permit_types.hourly_rate" style={{ width: '45%', minWidth: '210px' }} >Issued To</th>
          <th attr="permit_types.actions" style={{ width: '45%', minWidth: '210px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="Permit Types"
      total={initialList?.total}
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData
