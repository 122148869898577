import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/event_parking_applications';
/* Helpers */
import { filterFields } from 'components/helpers/fields/event_parking_applications/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './table_row';

const TableData = (props) => {

  const { list, setRecord, filters, search , isResourceFetching, openModal, modalType, selectedRecord, activeTab, total } = props

  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow
        key={record.id}
        record={record}
        selectedRecordId={selectedRecord.id}
        openModal={openModal}
        modalType={modalType}
        setRecord={setRecord}
      />
    });
  }, [list, modalType, setRecord, openModal, selectedRecord.id])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      total={search ? list?.length : total}
      columns={
        <React.Fragment>
          <th attr="event_name">Event Name</th>
          <th attr="organizer_email" style={{ width: '45%', minWidth: '210px' }}>Organizer's Email</th>
          <th attr="organizer_phone_number" style={{ width: '45%', minWidth: '210px' }}> Organizer's Phone </th>
          <th attr="event_days" style={{ width: '45%', minWidth: '140px' }}> Event Days </th>
          <th attr="total_spaces" style={{ width: '45%', minWidth: '150px' }}> Parking Spaces </th>
          <th attr="event_cost" style={{ width: '45%', minWidth: '150px' }}> Cost </th>
          <th attr="status" style={{ width: '45%', minWidth: '150px' }}> Event Status </th>
          <th attr="payment_status" style={{ width: '45%', minWidth: '150px' }}> Payment Status </th>
          <th className='d-flex justify-content-center' attr="actions" style={{ width: '20%', minWidth: '210px', }} disableSort> Action </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Private Events'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData