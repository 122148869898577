import React from 'react'
/* API */
import { filterFetcher } from 'api/special_permit_types';
/* Helpers */
import { filterFields } from 'components/helpers/fields/special_permit_types';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';

const TableData = (props) => {
  const { 
    list, 
    setRecord, 
    filters, 
    isResourceFetching, 
    setModalType,
    modalType,
    activeTab,
  } = props
  
  const openModal = (modalType) => {
    setModalType(modalType)
  }
  
  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          setRecord={setRecord} 
          openModal={openModal} 
          modalType={modalType} 
        />
      )
    });
  };

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <>
          <th attr="permit_types.name" style={{ width: '25%', minWidth: '210px' }} >Name</th>
          <th attr="permit_types.status" style={{width: '15%', minWidth: '150px' }} >Status</th>
          <th attr="permit_types.payment_type" style={{ width: '15%', minWidth: '210px' }} >Paid/Free</th>
          <th attr="permit_types.hourly_rate" style={{ width: '15%', minWidth: '210px' }} >Cost Per Hour</th>
          <th attr="permit_types.parking_hour_from" style={{ width: '20%', minWidth: '210px' }} >
            Time (From - To)
          </th>
          <th attr="permit_types.actions" style={{ width: '30%', minWidth: '210px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </>
      }
      renderRecords={renderRecords}
      entityName="Permit Types"
    />
  )
}

export default TableData
