import React, { useCallback, useEffect, useState, useRef } from "react";
import { Col, Container, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Form } from "informed";
/* Module */
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";
/* API */
import { show, changeAvailability } from "api/parking_lots";
/* Helpers */
import Loader from "components/helpers/loader";
import { capitalize, displayDate } from "components/helpers";
import {
  fieldsShow,
  fieldsCustomHour,
  fieldsHour,
  lotSettingsFields,
  fieldsParkingHourTypeSettingsWeekdays,
  fieldsParkingHoursFormatDynamicWeekdays,
  fieldsNoChargePeriod,
  fieldsNoChargePeriodDynamic,
  fieldsNoChargeDay,
  closeLotFields
} from "components/helpers/fields/parking_lots";
/* Base */
import Button from "components/base/button";
import { renderFields } from "components/base/forms/common_form";
/* Assets */
import styles from "./show.module.sass";
import { map } from "underscore";
import { Link } from "react-router-dom";
import Tabs from "components/base/tabs";
import LocationForm from "../shared/location/form";
import { Label } from "reactstrap";
import { ReactComponent as CloudDownloadIcon } from "assets/cloud-download.svg";
import { isEmpty } from "underscore";
import { index as ParkingRules } from "api/parking_rules";
import ViolationFinesSection from "components/pages/parking_lots/show/shared/violation_fines";
import { cloneDeep } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { Divider } from "antd";
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const Show = (props) => {
  const {
    updateParkingLot,
    startFetching,
    closeModal,
    record,
    parkingAdmins,
    townManagers,
    agencies,
    state,
    contextHolder,
    errors, 
    setErrors,
  } = props;
  const [isFetching, setIsFetching] = useState();
  const [parkingLot, setParkingLot] = useState({});
  const [selectedTab, setSelectedTab] = useState("information");
  const [currentLocation, setCurrentLocation] = useState();
  const formApiRef = useRef();
  // eslint-disable-next-line
  const [fileName, setFileName] = useState();
  const [image, setImage] = useState(null);
  const [parkingSchedules, setParkingSchedules] = useState([]);
  const [fetchedParkingLot, setFetchedParkingLot] = useState([]);
  const initialDays = parkingSchedules?.map((s) => {
    return s.day;
  });
  const [selectedDays, setSelectedDays] = useState(initialDays);
  // eslint-disable-next-line
  const [pHSetting, setPHSetting] = useState("custom");
  const [parkingRules, setParkingRules] = useState([]);
  const [updatedParkingRules, setUpdatedParkingRules] = useState(parkingRules);
  // eslint-disable-next-line
  const [vehicleClassSettings, setVehicleClassSettings] = useState([]);
  const [noChargeFields, setNoChargeFields] = useState([]);
  const [staticNoChargeFields, setStaticNoChargeFields] = useState([]);
  const [customData, setCustomData] = useState({});
  const [customVehicleClass, setCustomVehicleClass] = useState("");
  const [fieldsState, setFieldsState] = useState({});
  const [disabledFields, setDisabledFields] = useState({
    hourly: {},
    disabled: {},
  });
  const [sets, setSets] = useState([]);
  const [status, setStatus] = useState();
  const [activeButton, setActiveButton] = useState(0);
  const fileRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };
  useEffect(() => {
    const fetchParkingRules = async () => {
      try {
        const { data } = await ParkingRules({
          query: { parking_lot_id: record?.id },
        });
        setParkingRules(data);
      } finally {
      }
    };
    if (isEmpty(parkingRules)) {
      fetchParkingRules();
    }
  });

  const fieldAttrs = {
    admins: parkingAdmins,
    managers: townManagers,
    agencies,
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customToolTipClass: styles.customTooltip,
    customIDInputClass: styles.idInput,
  };

  const weekDays = [
    {
      label: "Monday",
      value: "monday",
    },
    {
      label: "Tuesday",
      value: "tuesday",
    },
    {
      label: "Wednesday",
      value: "wednesday",
    },
    {
      label: "Thursday",
      value: "thursday",
    },
    {
      label: "Friday",
      value: "friday",
    },
  ];

  const doubleFieldProps = {
    lSize: 1,
    iSize: 12,
    events: {
      onChange: (_e) => setErrors({})
    }
  };

  const singleFieldProps = {
    iSize: 8,
    lSize: 3,
  };

  const settingFieldProps = {
    iSize: 8,
    lSize: 4,
  };

  const noChargeDayFieldProps = (id) => {
    return {
      iSize: 8,
      lSize: 4,
      events: {
        onChange: (e) => handleDayChange(id, e),
      },
    };
  };

  const classFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (e) => handleVehicleClassChange(e),
    },
  };

  const customClassFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (e) => handleCustomVehicleClassChange(e),
    },
  };

  const parkingLotData = useCallback(
    (data) => {
      const {
        name,
        avatar,
        id,
        parking_admin,
        town_manager,
        status,
        location,
        phone,
        email,
        setting,
        parking_lot_type,
        parking_hours_availability,
        closed_reason,
        closed_by
      } = data;
      setImage(avatar);
      setParkingSchedules(setting?.parking_schedules);
      setSelectedDays(
        setting?.parking_schedules?.map((s) => {
          return s.day;
        })
      );
      setStatus(parking_hours_availability === 'open' ? 'close' : 'open')
      setActiveButton(parking_hours_availability === 'open' ? 1 : 0)
      const parkingHour = {};
      const parkingHourDynamic = {};
      const parkingHourCustom = {};
      setVehicleClassSettings(setting?.vehicle_class_settings);
      if (setting?.active_day_setting === "hourly_static") {
        map(
          setting?.parking_schedules,
          (ph, idx) =>
            (parkingHour[`hourly_static`] = {
              from: ph?.parking_hours[0].parking_hour_from,
              to: ph?.parking_hours[0].parking_hour_to,
              id: idx,
            })
        );
      }
      if (setting?.active_day_setting === "hourly_dynamic") {
        map(setting?.parking_schedules, (ph, idx) => {
          ph.parking_hours.map((h, id) => {
            return (parkingHourDynamic[`${ph.day}`] = {
              from: h?.parking_hour_from,
              to: h?.parking_hour_to,
              id: idx,
            });
          });
        });
      }
      if (setting?.active_day_setting === "custom") {
        map(setting?.parking_schedules, (ph, idx) => {
          ph.parking_hours.forEach((h, id) => {
            const dayKey = ph.day;
            const entryKey = `${ph.day}-${id + 1}`;
            const vehicleClassSettings = h.vehicle_class_settings.reduce(
              (acc, vcs) => {
                acc.rate = vcs.hourly_rate;
                acc.disabled = vcs.disabled_parking_rate;
                return acc;
              },
              {}
            );

            if (!parkingHourCustom[dayKey]) {
              parkingHourCustom[dayKey] = {};
            }

            parkingHourCustom[dayKey][entryKey] = {
              from: h.parking_hour_from,
              to: h.parking_hour_to,
              ...vehicleClassSettings,
            };
          });
        });
      }
      if (Object.keys(parkingHourCustom).length !== 0) {
        initializeFields(parkingHourCustom, setting.active_day_setting);
      }

      const hrFormatDynamic = {};
      const hrFormatStatic = {};
      const doesHaveNoChargePeriodStatic = setting?.parking_schedules?.map(
        (ps, idx) => {
          return `${!isEmpty(ps?.no_charge_period)}`;
        }
      );
      const staticNoChargePeriods = {};
      let staticNoChargeFieldArray = [];
      if (setting?.active_day_setting === "hourly_static") {
        map(setting?.parking_schedules, (ph, idx) => {
          if (!isEmpty(ph.no_charge_period)) {
            // eslint-disable-next-line
            ph.no_charge_period.map((ncpd, id) => {
              if (
                staticNoChargeFieldArray.length === 0 &&
                staticNoChargeFieldArray < 2
              ) {
                staticNoChargeFieldArray.push({ id: id });
              }
              staticNoChargePeriods[id] = { from: ncpd?.from, to: ncpd?.to };
            });
          }
        });
      }
      setStaticNoChargeFields(staticNoChargeFieldArray);

      const dynamicNoChargePeriods = {};
      let noChargeFieldArray = [];
      if (setting?.active_day_setting === "hourly_dynamic") {
        map(setting?.parking_schedules, (ph, idx) => {
          if (!isEmpty(ph?.no_charge_period)) {
            noChargeFieldArray.push(idx);
            return map(
              ph?.no_charge_period,
              (ncp, id) =>
                (dynamicNoChargePeriods[`${idx}`] = {
                  from: ncp?.from,
                  to: ncp?.to,
                  day: ph?.day,
                  id: idx,
                })
            );
          }
        });
      }

      setNoChargeFields(noChargeFieldArray);

      map(
        setting?.parking_schedules,
        (ph, idx) => (hrFormatDynamic[`${ph.day}`] = "custom")
      );

      map(
        setting?.parking_schedules,
        (ph, idx) => (hrFormatStatic[`hourly_static`] = "custom")
      );

      return {
        id: id,
        name: name,
        parking_admin_id: parking_admin?.id,
        town_manager_id: town_manager?.id,
        phone: `${phone}`,
        email: email,
        location: location?.full_address,
        status: status,
        agency_id: record.agency_id,
        parking_lot_type: parking_lot_type,
        parking_hours_availability: parking_hours_availability,
        closed_reason: closed_reason,
        closed_by: closed_by,
        available_spaces: record?.spaces_count?.total,
        parking_days:
          setting?.parking_schedules?.length === 7 ? "global" : "custom",
        parking_hours_type: setting.active_day_setting,
        parking_hours_format: "custom",
        parkingHours:
          setting.active_day_setting === "hourly_static"
            ? parkingHour
            : setting.active_day_setting === "hourly_dynamic"
            ? parkingHourDynamic
            : "",
        customDays:
          setting.active_day_setting === "custom" ? parkingHourCustom : "",
        incremental: setting?.incremental,
        overtime: setting?.overtime,
        parked: setting?.parked,
        period: setting?.period,
        hourFormat:
          setting.active_day_setting === "hourly_static"
            ? hrFormatStatic
            : setting.active_day_setting === "hourly_dynamic"
            ? hrFormatDynamic
            : "",
        vehicle_class:
          setting.active_day_setting === "hourly_static" ||
          setting.active_day_setting === "hourly_dynamic"
            ? setting.vehicle_class_settings[
                setting.vehicle_class_settings.length - 1
              ].vehicle_class
            : setting.active_day_setting === "custom"
            ? "car"
            : "",
        rate:
          setting.active_day_setting === "hourly_static" ||
          setting.active_day_setting === "hourly_dynamic"
            ? setting.vehicle_class_settings[
                setting.vehicle_class_settings.length - 1
              ].hourly_rate
            : "",
        disabled_parking_rate:
          setting.active_day_setting === "hourly_static" ||
          setting.active_day_setting === "hourly_dynamic"
            ? setting.vehicle_class_settings[
                setting.vehicle_class_settings.length - 1
              ].disabled_parking_rate
            : "",
        noChargePeriod: doesHaveNoChargePeriodStatic?.includes("true")
          ? staticNoChargePeriods
          : "",
        noChargePeriodDynamic: dynamicNoChargePeriods,
      };
    },
    // eslint-disable-next-line
    [record, setVehicleClassSettings]
  );

  const renderLocationModal = (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
      />
    );
  };

  const handleVehicleClassChange = (e) => {
    if (
      parkingLot?.parking_hours_type === "hourly_static" ||
      parkingLot?.parking_hours_type === "hourly_dynamic"
    ) {
      const vehicleSetting =
        fetchedParkingLot?.setting?.vehicle_class_settings?.filter((vs) => {
          return vs.vehicle_class === e.value;
        });
      setVehicleClassSettings((prevSetting) => [
        ...prevSetting,
        {
          vehicle_class: e.value,
          hourly_rate: formApiRef.current.getValue("rate"),
          disabled_parking_rate: formApiRef.current.getValue(
            "disabled_parking_rate"
          ),
        },
      ]);
      formApiRef.current.setValue("rate", vehicleSetting[0]?.hourly_rate);
      formApiRef.current.setValue(
        "disabled_parking_rate",
        vehicleSetting[0]?.disabled_parking_rate
      );
      setVehicleCla();
    }
  };

  const setVehicleCla = () => {
    setVehicleClassSettings((prevData) => {
      const existingItemIndex = prevData?.findIndex(
        (item) =>
          item?.vehicle_class === formApiRef.current.getValue("vehicle_class")
      );
      if (existingItemIndex !== -1) {
        // Update existing item
        const updatedData = prevData.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                hourly_rate: formApiRef.current.getValue("rate"),
                disabled_parking_rate: formApiRef.current.getValue(
                  "disabled_parking_rate"
                ),
              }
            : item
        );
        return updatedData;
      } else {
        // Add new item
        return [
          ...prevData,
          {
            vehicle_class: formApiRef.current.getValue("vehicle_class"),
            hourly_rate: formApiRef.current.getValue("rate"),
            disabled_parking_rate: formApiRef.current.getValue(
              "disabled_parking_rate"
            ),
          },
        ];
      }
    });
  };

  const handleCustomVehicleClassChange = (e) => {
    // Get current form values
    const formValues = formApiRef.current.getValues().customDays;
    // Save current form data for the current vehicle class
    const updatedData = { ...customData };
    Object.keys(formValues).forEach((day) => {
      Object.keys(formValues[day]).forEach((entry) => {
        const { from, to, rate, disabled } = formValues[day][entry];
        if (!from || !to || !rate || !disabled) {
          return;
        }
        // Find the matching entry by from and to
        const dayEntries = Object.keys(updatedData[day] || {}).map(
          (key) => updatedData[day][key]
        );
        let matchingEntry = dayEntries.find(
          (d) => d.from === from && d.to === to
        );

        if (!matchingEntry) {
          // Create new entry if none found
          const newEntryKey = `${day}-${
            Object.keys(updatedData[day] || {}).length + 1
          }`;
          if (!updatedData[day]) updatedData[day] = {};
          updatedData[day][newEntryKey] = {
            from,
            to,
            vehicle_class_setting: [
              {
                vehicle_class: customVehicleClass
                  ? customVehicleClass
                  : e.value,
                rate,
                disabled_parking_rate: disabled,
              },
            ],
          };
        } else {
          // Update existing entry
          const vehicleClassIndex =
            matchingEntry.vehicle_class_setting?.findIndex(
              (vc) => vc.vehicle_class === customVehicleClass
            );

          if (vehicleClassIndex !== -1) {
            // Update existing vehicle class settings
            matchingEntry.vehicle_class_setting[vehicleClassIndex] = {
              vehicle_class: customVehicleClass ? customVehicleClass : e.value,
              rate,
              disabled_parking_rate: disabled,
            };
          } else {
            // Add new vehicle class settings
            matchingEntry.vehicle_class_setting.push({
              vehicle_class: customVehicleClass ? customVehicleClass : e.value,
              rate,
              disabled_parking_rate: disabled,
            });
          }
        }
      });
    });

    setCustomData(updatedData);
    // Switch to new vehicle class
    setCustomVehicleClass(e.value);
  };

  const transformDataForAPI = (data) => {
    const apiData = {
      parking_days_settings: [],
    };

    Object.keys(data).forEach((day) => {
      Object.keys(data[day]).forEach((entryKey) => {
        const { from, to, vehicle_class_setting } = data[day][entryKey];

        const dayEntry = {
          day_name: day,
          parking_hour_from: from,
          parking_hour_to: to,
          vehicle_class_setting: vehicle_class_setting.map((vc) => ({
            vehicle_class: vc.vehicle_class,
            hourly_rate: vc.rate,
            disabled_parking_rate: vc.disabled_parking_rate,
          })),
        };

        apiData.parking_days_settings.push(dayEntry);
      });
    });

    return apiData;
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        setImage(reader.result);
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    } else {
      setFileName(e.target.files[0]?.name);
      getBase64(e.target.files[0]);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (
        e.dataTransfer.files.format === ".pdf" ||
        e.dataTransfer.files.format === ".png" ||
        e.dataTransfer.files.format === ".jpg"
      ) {
        setFileName(e.dataTransfer.files[0].name);
        fileRef.current = { files: e.dataTransfer.files };
      }
    }
  };

  const getFields = () => {
    return fieldsShow({
      ...fieldAttrs,
      renderLocationModal: renderLocationModal.bind(this),
    });
  };

  const getLotSettingsFields = () => {
    return lotSettingsFields({
      ...fieldAttrs,
      renderLocationModal: renderLocationModal.bind(this),
    });
  };

  const list = () => [
    {
      label: <span style={{ opacity: "0.65" }}>Information</span>,
      value: "information",
    },
    {
      label: (
        <React.Fragment>
          <span className="mr-2" style={{ opacity: "0.65" }}>
            Settings
          </span>
        </React.Fragment>
      ),
      value: "settings",
    },
  ];
  const renderParkingLotFields = (fields) => {
    fields[0] = {
      ...fields[0],
      customInputClass: fieldAttrs.customIDInputClass.concat(
        " ",
        styles.iconInput
      ),
      icon: (
        <Link
          to={`parking_lots/${record?.id}/voi`}
          className={styles.checkDetailsLink}
        >
          Check Details
        </Link>
      ),
    };
    fields[10] = {
      ...fields[10],
      customInputClass: fieldAttrs.customIDInputClass.concat(
        " ",
        styles.iconInput
      ),
      icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
    };

    return (
      <React.Fragment>
        {renderFields(fields.slice(0, 1), { ...singleFieldProps })}
        {renderFields(fields.slice(1, 2), { ...singleFieldProps })}
        {renderFields(fields.slice(2, 3), { ...singleFieldProps })}
        {renderFields(fields.slice(3, 4), { ...singleFieldProps })}
        {renderFields(fields.slice(4, 5), { ...singleFieldProps })}
        {renderFields(fields.slice(5, 6), { ...singleFieldProps })}
        {renderFields(fields.slice(6, 7), { ...singleFieldProps })}
        {renderFields(fields.slice(7, 8), { ...singleFieldProps })}
        {renderFields(fields.slice(8, 9), { ...singleFieldProps })}
        {renderFields(fields.slice(9, 10), { ...singleFieldProps })}
        <span>
          <label className={styles.fileLabel}>Parking Lot Picture</label>
        </span>
        <div
          style={{ display: "flex" }}
          onDragEnter={handleDrag}
          onDrop={handleDrop}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        >
          <input
            ref={fileRef}
            name="id_proof"
            className="d-none"
            id="id-proof-file-upload"
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={showFileName}
          />
          {!image && (
            <Label
              htmlFor="id-proof-file-upload"
              className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}
            >
              <span className="mb-2">
                {image ? (
                  <img className={styles.img} alt="Parking Lot Pic" src={image} />
                ) : (
                  <CloudDownloadIcon />
                )}
              </span>
              <span>
                <span className={styles.boldText1}>Add Picture</span>
                <span className="ml-1">or drag it here</span>
              </span>
              <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
            </Label>
          )}
          {image && (
            <Label
              htmlFor="id-proof-file-upload"
              className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}
            >
              <span className="mb-2 mt-2">
                <img className={styles.img} alt="Parking Lot" src={image} />
              </span>
              <span className="mb-2 mt-2">
                <CloudDownloadIcon className={styles.cloudIcon} />
              </span>
              <span className={styles.uploadText}>
                <span className={styles.boldText2}>Add Another Picture</span>

                <p className={styles.formatsText2}>JPEG, PNG (200x200)</p>
              </span>
            </Label>
          )}
        </div>
        {renderFields(fields.slice(10, 11), { ...singleFieldProps })}
      </React.Fragment>
    );
  };

  const hourTypeFieldProps = {
    iSize: 8,
    lSize: 3,
    events: {
      onChange: () => setNoChargeFields([]),
    },
  };

  const renderParkingHourSettingsFields = useCallback(
    (fields) => {
      return (
        <Row className="pt-2 pl-3 ml-1">
          {renderFields(fields, 4, 12, { ...hourTypeFieldProps })}
        </Row>
      );
    },
    // eslint-disable-next-line
    [parkingLot, parkingSchedules, record, fetchedParkingLot]
  );

  const parkingHourTypeSettingFieldsWeekdays =
    fieldsParkingHourTypeSettingsWeekdays({
      ...fieldAttrs,
      renderLocationModal: renderLocationModal.bind(this),
    });

  const rateFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (e) => setVehicleCla(),
    },
  };
  const renderParkingLotSettingsFields = (fields, formState) => {
    return (
      <Col className={`${styles.fieldset} pt-2`} xs={10}>
        {renderFields(fields.slice(0, 1), { ...classFieldProps, formState })}
        {renderFields(fields.slice(1, 2), { ...rateFieldProps, formState })}
        {renderFields(fields.slice(2, 3), { ...rateFieldProps, formState })}
        {renderFields(fields.slice(3, 4), { ...settingFieldProps, formState })}
        {renderFields(fields.slice(4, 5), { ...settingFieldProps, formState })}
        {renderFields(fields.slice(5, 6), { ...settingFieldProps, formState })}
        {renderFields(fields.slice(6, 7), { ...settingFieldProps, formState })}
      </Col>
    );
  };

  const fetchParkingLot = async () => {
    if (!record.id) return;
    try {
      const response = await startFetching(show({ id: record.id }));
      if (response.status === 200) {
        const { data } = response;
        setParkingLot(parkingLotData(data));
        setParkingSchedules(data?.setting?.parking_schedules);
        setFetchedParkingLot(data);
        setCurrentLocation(data.location);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    setIsFetching(true);
    fetchParkingLot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTab = (e) => {
    setSelectedTab(e);
    if (e === "information") {
      setSelectedTab("information");
    } else {
      setSelectedTab("settings");
    }
  };
  const handleCheckboxChange = (day) => {
    const updatedSelectedDays = [...selectedDays];

    if (!updatedSelectedDays.includes(day)) {
      updatedSelectedDays.push(day);
    } else {
      const dayIndex = updatedSelectedDays.indexOf(day);
      updatedSelectedDays.splice(dayIndex, 1);
    }

    setSelectedDays(updatedSelectedDays);
  };

  const submit = useCallback(
    async (values) => {
      const { parking_hours_type, parkingHours, noChargePeriodDynamic, customDays } =
        values;

      values.location = cloneDeep(currentLocation);
      values.rules = !isEmpty(updatedParkingRules)
        ? updatedParkingRules
        : parkingRules;
      if (fileName) {
        values.avatar = image;
      }

      const parkingDaySettings =
        parking_hours_type === "hourly_static"
          ? [
              {
                parking_hour_from: parkingHours["hourly_static"]["from"],
                parking_hour_to: parkingHours["hourly_static"]["to"],
              },
            ]
          : parking_hours_type === "hourly_dynamic"
          ? selectedDays.map((day, idx) => {
              return {
                day_name: day,
                parking_hour_from: parkingHours[day]?.from,
                parking_hour_to: parkingHours[day]?.to,
              };
            })
          : parking_hours_type === "custom"
          ? transformDataForAPI(customData? customData : customDays).parking_days_settings
          : [];

      const noChargePeriods =
        parking_hours_type === "hourly_static" &&
        !isEmpty(values?.noChargePeriod)
          ? values?.noChargePeriod?.map((ncp) => {
              return { setting: [{ from: ncp?.from, to: ncp?.to }] };
            })
          : parking_hours_type === "hourly_dynamic" &&
            !isEmpty(values?.noChargePeriodDynamic)
          ? noChargePeriodDynamic.map((period, idx) => {
              return {
                day_name: period?.day,
                setting: [
                  {
                    from: period.from,
                    to: period.to,
                  },
                ],
              };
            })
          : [];

      values.setting = {
        active_days: selectedDays,
        active_day_setting: parking_hours_type,
        parking_days: {
          no_charge_period: noChargePeriods,
          parking_days_settings: parkingDaySettings,
          vehicle_class_setting:
            parking_hours_type !== "custom"
              ? [
                  {
                    vehicle_class: values?.vehicle_class,
                    hourly_rate: values?.rate,
                    disabled_parking_rate: values.disabled_parking_rate,
                  },
                ]
              : [],
        },
      };
      updateParkingLot(values);
    },
    [
      updatedParkingRules,
      parkingRules,
      currentLocation,
      image,
      customData,
      updateParkingLot,
      selectedDays,
      fileName,
    ]
  );

  const handleNoChargePeriodAddition = (type) => {
    if (type === "hourly_static") {
      const newId =
        staticNoChargeFields.length > 0
          ? staticNoChargeFields[staticNoChargeFields.length - 1].id + 1
          : 0;

      setStaticNoChargeFields([...staticNoChargeFields, { id: newId }]);
    } else if (type === "hourly_dynamic") {
      const newSetId = sets.length ? sets[sets.length - 1].id + 1 : 1;
      setSets([...sets, { id: newSetId, day: "monday", fields: [{ id: 1 }] }]);
    }
  };

  const handleNoChargePeriodRemoval = (type, id) => {
    if (type === "hourly_static") {
      setStaticNoChargeFields(
        staticNoChargeFields.filter((field) => field.id !== id)
      );
    } else if (type === "hourly_dynamic") {
      //logic for dynamic
      setNoChargeFields(noChargeFields.filter((fieldId) => fieldId !== id));
    }
  };

  const initializeFields = (customDays, activeDaySetting) => {
    const fields = {};
    if (activeDaySetting === "custom") {
      for (const day in customDays) {
        fields[day] = Object.keys(customDays[day]).map((key, index) => ({
          id: index + 1,
        }));
      }
    } else {
      selectedDays.forEach((day) => {
        fields[day] = [{ id: 1 }]; // Initialize with one set of fields
      });
    }
    setFieldsState(fields);
  };

  useEffect(() => {
    if (selectedDays && selectedDays.length > 0) {
      initializeFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  const onAdd = (day) => {
    setFieldsState((prevState) => {
      const lastField = prevState[day][prevState[day].length - 1];
      const newId = lastField ? lastField.id + 1 : 1;
      return {
        ...prevState,
        [day]: [...prevState[day], { id: newId }],
      };
    });
  };

  const onRemove = (day, id) => {
    setFieldsState((prevState) => ({
      ...prevState,
      [day]: prevState[day].filter((field) => field.id !== id),
    }));
  };

  const handleHourlyRateCheckboxChange = (day, id) => {
    setDisabledFields((prevState) => {
      const newHourlyDisabled = {
        ...prevState.hourly,
        [`${day}-${id}`]: !prevState.hourly[`${day}-${id}`],
      };
      return { ...prevState, hourly: newHourlyDisabled };
    });
  };

  const handleDisabledRateCheckboxChange = (day, id) => {
    setDisabledFields((prevState) => {
      const newDisabledRateDisabled = {
        ...prevState.disabled,
        [`${day}-${id}`]: !prevState.disabled[`${day}-${id}`],
      };
      return { ...prevState, disabled: newDisabledRateDisabled };
    });
  };

  const fourFieldProps = {
    lSize: 3,
    iSize: 7,
  };

  const handleRemoveSet = (setId) => {
    setSets(sets.filter((set) => set.id !== setId));
  };

  const handleAddField = (setId) => {
    setSets(
      sets.map((set) => {
        if (set.id === setId) {
          const newFieldId = set.fields.length
            ? set.fields[set.fields.length - 1].id + 1
            : 1;
          return { ...set, fields: [...set.fields, { id: newFieldId }] };
        }
        return set;
      })
    );
  };

  const handleRemoveField = (setId, fieldId) => {
    setSets(
      sets.map((set) => {
        if (set.id === setId) {
          return {
            ...set,
            fields: set.fields.filter((field) => field.id !== fieldId),
          };
        }
        return set;
      })
    );
  };

  const handleDayChange = (setId, event) => {
    const newDay = event.value;
    setSets(
      sets.map((set) => {
        if (set.id === setId) {
          return { ...set, day: newDay };
        }
        return set;
      })
    );
  };

  const closeFields = () => {
    return closeLotFields({...fieldAttrs})
  }

  const closeParkingLot = useCallback(async (data) => {
    try {
      const response  = await changeAvailability({data: data, id: record.id})
      if(response.status === 200){
        closeModal()
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
    }
  },[record, closeModal, setErrors])

  const validateFields = () => {
    const { closed_till_date, closed_till_time } = formApiRef.current.getValues();
    const errors = {};
    if(status!=='open')
    {
      if(!closed_till_date || closed_till_date === undefined){
        errors['closed_till_date'] = ['Parking Lot Name is required!']
      }
      if(!closed_till_time || closed_till_time === undefined){
        errors['closed_till_time'] = ['Email is required !']
      }
    }
    return Object.assign(errors)
  };

  const closeLot = () => {
    const {values} = formApiRef.current.getState()
    setErrors({})
    const validationErrors = validateFields()
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
    }
    const updatedHash={
      availability: status,
      closed_till_date: displayDate(values.closed_till_date, 'yyyy-mm-dd'),
      closed_till_time: values.closed_till_time
    }
    closeParkingLot(updatedHash)
  };

  const statusColor = (color) => {
    if(!color) return;
    const colorMapping = {
      open: 'open',
      close: 'close',
    }
    return colorMapping[color?.toLowerCase()]
  }

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Container className="p-0">
      {contextHolder}
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Parking Lot Details</p>
      <fieldset>
        <Form
          getApi={setFormApi}
          className={styles.form}
          initialValues={parkingLot}
          onSubmit={submit}
        >
          {({ formState }) => {
            const { parking_hours, parking_hours_type, parking_hours_format } =
              formState.values;
            setPHSetting(parking_hours);
            const fields = getFields();
            const lotSettings = getLotSettingsFields();
            const newFields = closeFields()
            return (
              <Row className="mx-auto w-100">
                <Col xs={11} className="m-auto py-3 pb-3 pr-4 pl-1">
                  <Tabs
                    list={list()}
                    defaultTab={selectedTab}
                    className={styles.tabs}
                    onClick={(val) => updateTab(val)}
                  />
                </Col>
                <Col
                  className={
                    selectedTab === "information"
                      ? `${styles.fieldset} pt-3 pl-4 ml-3`
                      : "d-none"
                  }
                >
                  <div className={styles.closedMessage}>{parkingLot?.parking_hours_availability === 'close' && (parkingLot?.closed_reason === 'Manually Closed' ? `This Parking lot is manually closed by ${parkingLot?.closed_by}` : 'Outside Parking Hours')} </div>
                  <Row className='d-flex justify-content-end pb-4' style={{paddingRight: '4rem'}}>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="span" className="pointer">
                        <div className={styles.availabiliy}>
                          <span className={`${styles.icon} ${styles[`icon-${statusColor(parkingLot?.parking_hours_availability)}`]} mr-2`}> </span>
                          <span className={styles.availabilityStatus}>{parkingLot?.parking_hours_availability === 'open' ? 'Open' : 'Closed'}</span>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right style={{marginTop: '10px', borderRadius: '8px', minWidth: '33rem'}}>
                        {parkingLot?.parking_hours_availability === 'close' && <DropdownItem type='button' toggle={false} className="p-3 text-grey" style={{outline: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #dee2e6'}}>
                          <div className='d-flex align-items-center' onClick={()=> {setStatus('open'); setActiveButton(0)}}>
                            <div 
                              className={`mr-3 ${styles.radioButton} ${activeButton === 0 ? styles.active : styles.inactive}`}
                            >
                              { <FontAwesomeIcon icon={faCircle} />}
                            </div>
                            <span className={`${styles.icon} ${styles[`icon-${statusColor('open')}`]} mr-2`}> </span>
                            <span className={styles.availabilityStatus}>Open</span>
                          </div>
                        </DropdownItem>}
                        <DropdownItem onClick={()=> {setStatus('close'); setActiveButton(1)}} type='button' toggle={false} className="p-3 text-grey" style={{outline: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #dee2e6'}}>
                          <div className='d-flex align-items-center'>
                            <div 
                              className={`mr-3 ${styles.radioButton} ${activeButton === 1 ? styles.active : styles.inactive}`}
                            >
                              { <FontAwesomeIcon icon={faCircle} />}
                            </div>
                            <span className={styles.availabilityStatus}>Close until...</span>
                          </div>
                          {activeButton === 1 && <div className="d-flex mt-3">
                            <span>{renderFields(newFields.slice(0, 1),{ ...doubleFieldProps, errors })}</span>
                            <span style={{marginLeft: '5rem'}}>{renderFields(newFields.slice(1, 2),{ ...doubleFieldProps, errors })}</span>
                          </div>}
                        </DropdownItem>
                        <DropdownItem type='button' toggle={false} className="p-3 text-grey" style={{outline: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #dee2e6'}}>
                          <Button disabled={!status} className={styles.okButton} type='button' onClick={() => closeLot()}>OK</Button>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Row>
                  {renderParkingLotFields(fields)}
                </Col>
                <Row
                  className={
                    selectedTab === "settings" ? "pl-3 pr-3" : "d-none"
                  }
                >
                  <Col>
                    <div className="d-flex align-items-center my-3 ml-2">
                      <span className={styles.detailsLabel}>Parking Days</span>
                      <span className="border border-2 flex-grow-1 ml-2"></span>
                    </div>
                  </Col>
                  <div className={styles.daysContainer}>
                    {map(weekDays, (d, idx) => {
                      return (
                        <div>
                          <label className={styles.checkboxText}>
                            <input
                              type="checkbox"
                              checked={selectedDays?.includes(d.value)}
                              onChange={() => handleCheckboxChange(d.value)}
                              className={styles.customCheckBox}
                            />
                            {`${capitalize(d?.label)}`}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.daysContainer2}>
                    <div>
                      <label className={styles.checkboxText}>
                        <input
                          type="checkbox"
                          checked={selectedDays?.includes("saturday")}
                          onChange={() => handleCheckboxChange("saturday")}
                          className={styles.customCheckBox}
                        />
                        Saturday
                      </label>
                    </div>
                    <div className={styles.sundaySection}>
                      <label className={styles.checkboxText}>
                        <input
                          type="checkbox"
                          checked={selectedDays?.includes("sunday")}
                          onChange={() => handleCheckboxChange("sunday")}
                          className={styles.customCheckBox}
                        />
                        Sunday
                      </label>
                    </div>
                  </div>
                  <div className={styles.form}>
                    <div className="d-flex align-items-center my-3 mr-2">
                      <span className={styles.detailsLabel}>Parking Hours</span>
                      <span className="border border-2 flex-grow-1 ml-2 mr-2"></span>
                    </div>
                    <div>
                      {renderParkingHourSettingsFields(
                        parkingHourTypeSettingFieldsWeekdays
                      )}
                    </div>
                    {parking_hours_format === "custom" && (
                      <Row
                        className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-double"]} pl-2`}
                        xs={8}
                      >
                        {renderFields(
                          fieldsHour({
                            name: "",
                            customInputClass: styles.input,
                            customLabelClass: styles.inputLabel,
                          }),
                          { ...doubleFieldProps }
                        )}
                      </Row>
                    )}
                    {selectedDays?.map((selectedDay) => (
                      <Row
                        className={
                          parking_hours_type === "hourly_dynamic"
                            ? "m-auto align-items-center"
                            : "d-none"
                        }
                        key={selectedDay}
                      >
                        <Col>
                          <div className="d-flex align-items-center pl-0 pb-2">
                            <span className={styles.subdetailsLabel}>
                              {capitalize(selectedDay)}
                            </span>
                          </div>
                        </Col>
                        <Col
                          className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-radio"]} pt-2`}
                          xs={8}
                        >
                          {renderFields(
                            fieldsParkingHoursFormatDynamicWeekdays({
                              name: `${selectedDay}`,
                              customInputClass: styles.input,
                              customLabelClass: styles.inputLabel,
                              ...fieldAttrs,
                            })
                          )}
                        </Col>
                        {formState?.values?.hourFormat &&
                          formState?.values?.hourFormat[selectedDay] ===
                            "custom" && (
                            <Row
                              className={`p-0 ${styles.fieldset} ${styles["fieldset-double"]}`}
                              style={{ marginLeft: "11.5rem" }}
                              xs={8}
                            >
                              {renderFields(
                                fieldsHour({
                                  name: selectedDay,
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps }
                              )}
                            </Row>
                          )}
                      </Row>
                    ))}

                    {
                      <Row
                        className={
                          parking_hours_type === "hourly_static"
                            ? "m-auto align-items-center"
                            : "d-none"
                        }
                        key="hourly_static"
                      >
                        <Col>
                          <div className="d-flex align-items-center">
                            <span className={styles.subdetailsLabel}>
                              Parking Hours
                            </span>
                          </div>
                        </Col>
                        <Col
                          className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-radio"]} pt-2`}
                          xs={8}
                        >
                          {renderFields(
                            fieldsParkingHoursFormatDynamicWeekdays({
                              name: `hourly_static`,
                              customInputClass: styles.radioBtns,
                              customLabelClass: styles.inputLabel,
                              ...fieldAttrs,
                            })
                          )}
                        </Col>
                        {formState?.values?.hourFormat &&
                          formState?.values?.hourFormat["hourly_static"] ===
                            "custom" && (
                            <Row
                              className={`p-0 ${styles.fieldset} ${styles["fieldset-double"]}`}
                              style={{ marginLeft: "11.5rem" }}
                              xs={8}
                            >
                              {renderFields(
                                fieldsHour({
                                  name: "hourly_static",
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps }
                              )}
                            </Row>
                          )}
                      </Row>
                    }

                    {parking_hours_type !== "custom" &&
                      renderParkingLotSettingsFields(lotSettings, formState)}

                    {parking_hours_type === "hourly_static" &&
                      staticNoChargeFields.map((field, index) => (
                        <React.Fragment key={field.id}>
                          <Row
                            className={
                              parking_hours_type === "hourly_static"
                                ? "m-auto align-items-center"
                                : "d-none"
                            }
                            key="no_charge"
                          >
                            <Col>
                              <div className="d-flex align-items-center pl-1">
                                <span
                                  className={
                                    index === 0
                                      ? styles.subdetailsLabel
                                      : "d-none"
                                  }
                                >
                                  No Charge Period
                                </span>
                              </div>
                            </Col>

                            <Row
                              className={`p-0 ${styles.fieldset} ${styles["fieldset-noCharge"]}`}
                              xs={10}
                            >
                              {renderFields(
                                fieldsNoChargePeriod({
                                  name: field.id,
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps }
                              )}
                            </Row>
                            <TrashIcon
                              className={styles.trashIconStatic}
                              onClick={() =>
                                handleNoChargePeriodRemoval(
                                  parking_hours_type,
                                  field.id
                                )
                              }
                            />
                          </Row>
                        </React.Fragment>
                      ))}

                    {sets.map((set) => (
                      <React.Fragment key={set.id}>
                        <Col
                          className={
                            parking_hours_type === "hourly_dynamic"
                              ? `${styles.fieldset} pt-2`
                              : "d-none"
                          }
                          xs={10}
                        >
                          {renderFields(
                            fieldsNoChargeDay({
                              name: `day-${set.id}`,
                              customInputClass: styles.input,
                              customLabelClass: styles.inputLabel,
                              days: selectedDays,
                            }),
                            { ...noChargeDayFieldProps(set.id) }
                          )}
                          <TrashIcon
                            className={styles.setTrashIcon}
                            onClick={() => handleRemoveSet(set.id)}
                          />
                          <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.setAddIcon}
                            onClick={() => handleAddField(set.id)}
                          />
                        </Col>
                        {set.fields.map((field, index) => (
                          <Row
                            key={field.id}
                            className={
                              parking_hours_type === "hourly_dynamic"
                                ? "m-auto align-items-center"
                                : "d-none"
                            }
                          >
                            <Col>
                              <div className="d-flex align-items-center pl-1">
                                {index === 0 && (
                                  <span className={styles.subdetailsLabel}>
                                    No Charge Hours
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Row
                              className={`p-0 ${styles.fieldset} ${styles["fieldset-noCharge"]}`}
                              xs={10}
                            >
                              {renderFields(
                                fieldsNoChargePeriodDynamic({
                                  name: `field-${set.id}-${field.id}`,
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps }
                              )}
                            </Row>
                            {index !== 0 && (
                              <TrashIcon
                                className={styles.fieldTrashIcon}
                                onClick={() =>
                                  handleRemoveField(set.id, field.id)
                                }
                              />
                            )}
                          </Row>
                        ))}
                      </React.Fragment>
                    ))}

                    {parking_hours_type === "custom" && (
                      <Col
                        className={`${styles.fieldset} m-0 p-0 pl-2`}
                        xs={10}
                      >
                        {renderFields(lotSettings.slice(0, 1), {
                          ...customClassFieldProps,
                          formState,
                        })}
                      </Col>
                    )}
                    {parking_hours_type === "custom" &&
                      selectedDays?.map((selectedDay) => {
                        const fields = fieldsState[selectedDay];
                        return (
                          <React.Fragment key={selectedDay}>
                            {fields?.map((field, idx) => (
                              <Row key={field.id}>
                                <Row className={styles.seperator}>
                                  {idx === 0 && (
                                    <Divider className={styles.divider} />
                                  )}
                                </Row>
                                <Row className={styles.customLabelRow}>
                                  <Col
                                    className={styles.customLabelSection}
                                    xs={5}
                                  >
                                    <label className={styles.customLabels}>
                                      Duration (From -To)
                                    </label>
                                  </Col>
                                  <Col
                                    className={styles.customLabelSection2}
                                    xs={4}
                                  >
                                    <label className={styles.customLabels2}>
                                      Hourly Rate $
                                    </label>
                                  </Col>
                                  <Col
                                    className={styles.customLabelSection3}
                                    xs={3}
                                  >
                                    <label className={styles.customLabels3}>
                                      Disabled Rate $
                                    </label>
                                  </Col>
                                </Row>
                                <Row className="m-auto align-items-center">
                                  <Col style={{ maxWidth: "22%" }}>
                                    <div className="d-flex align-items-center pl-3 pb-2">
                                      {idx === 0 && (
                                        <span
                                          className={styles.subdetailsLabel}
                                        >
                                          {capitalize(selectedDay)}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-four"]} d-flex`}
                                    xs={8}
                                  >
                                    {renderFields(
                                      fieldsCustomHour({
                                        day: selectedDay,
                                        id: field.id,
                                        customInputClass: styles.input,
                                        customLabelClass: styles.inputLabel,
                                        isHourlyRateDisabled:
                                          disabledFields.hourly[
                                            `${selectedDay}-${field.id}`
                                          ],
                                        isDisabledRateDisabled:
                                          disabledFields.disabled[
                                            `${selectedDay}-${field.id}`
                                          ],
                                      }),
                                      { ...fourFieldProps }
                                    )}
                                    {idx === 0 && (
                                      <Button
                                        type="button"
                                        onClick={() => onAdd(selectedDay)}
                                        className={styles.addButtonCustom}
                                      >
                                        +
                                      </Button>
                                    )}
                                    {idx !== 0 && (
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          onRemove(selectedDay, field.id)
                                        }
                                        className={styles.removeButtonCustom}
                                      >
                                        -
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                                <Row className={styles.checkboxRow}>
                                  <label className={styles.checkboxFree1}>
                                    <input
                                      type="checkbox"
                                      checked={
                                        disabledFields.hourly[
                                          `${selectedDay}-${field.id}`
                                        ] || false
                                      }
                                      onChange={() =>
                                        handleHourlyRateCheckboxChange(
                                          selectedDay,
                                          field.id
                                        )
                                      }
                                      className={styles.customCheckBox}
                                    />
                                    <span className={styles.checkboxLabel}>
                                      Free
                                    </span>
                                  </label>
                                  <label className={styles.checkboxFree2}>
                                    <input
                                      type="checkbox"
                                      checked={
                                        disabledFields.disabled[
                                          `${selectedDay}-${field.id}`
                                        ] || false
                                      }
                                      onChange={() =>
                                        handleDisabledRateCheckboxChange(
                                          selectedDay,
                                          field.id
                                        )
                                      }
                                      className={styles.customCheckBox}
                                    />
                                    <span className={styles.checkboxLabel}>
                                      Free
                                    </span>
                                  </label>
                                </Row>
                              </Row>
                            ))}
                          </React.Fragment>
                        );
                      })}

                    {parking_hours_type === "custom" && (
                      <React.Fragment>
                        <Row className={styles.seperator}>
                          <Divider className={styles.divider} />
                        </Row>
                        <Col className={styles.fieldset} xs={10}>
                          {renderFields(lotSettings.slice(3, 7), {
                            ...settingFieldProps,
                            formState,
                          })}
                        </Col>
                      </React.Fragment>
                    )}

                    {parking_hours_type !== "custom" && (
                      <Col className={`${styles.fieldset} pt-2`} xs={10}>
                        <Button
                          type="button"
                          onClick={() =>
                            handleNoChargePeriodAddition(parking_hours_type)
                          }
                          className={`${styles.addButton} mr-4 mt-2`}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.plusIcon}
                          />
                          <span> Add No Charge Period</span>
                        </Button>
                      </Col>
                    )}
                  </div>

                  <Col className={styles.form}>
                    <div className="d-flex align-items-center my-3 mr-2 ml-1">
                      <span className={styles.detailsLabel}>
                        Violation Fines
                      </span>
                      <span className="border border-2 flex-grow-1 ml-2 mr-0 pr-0"></span>
                    </div>
                    <div />
                    <ViolationFinesSection
                      parkingLotId={record.id}
                      setFormApi={setFormApi}
                      formApiRef={formApiRef}
                      prevalues={formApiRef?.current?.getValues()}
                      parkingRules={parkingRules}
                      setUpdatedParkingRules={setUpdatedParkingRules}
                      updatedParkingRules={updatedParkingRules}
                      state={state}
                      record={record}
                    />
                  </Col>
                </Row>
                <Col
                  className="d-flex justify-content-center mb-3 mt-3"
                  xs={12}
                >
                  <Button
                    onClick={() => closeModal()}
                    className={`${styles.cancelButton} mb-2`}
                  >
                    Close
                  </Button>
                  {
                    <Button type="submit" className={styles.submitButton}>
                      Save
                    </Button>
                  }
                </Col>
              </Row>
            );
          }}
        </Form>
      </fieldset>
    </Container>
  );
};

export default withFetching(withCurrentUser(Show));