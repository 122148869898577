import React from 'react'
import NavigationToolbar from 'components/base/navigationToolbar';
import {  Col } from 'reactstrap';

const ToolBar = ( props) => {
  return (
    <NavigationToolbar
      backTab={'spaces'}
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span>{`Parking Slots (${props.list.length})`}</span>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;