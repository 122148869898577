import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed'
import { SET_RECORD } from 'actions/disputes'
import { change_status, show } from 'api/notifications';
import Loader from 'components/helpers/loader';
import connectRecord from 'components/modules/connect_record';
import resourceFetcher from 'components/modules/resource_fetcher';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import { update } from 'api/notifications';
import NavigationToolbar from 'components/base/navigationToolbar';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { Card, CardBody, CardHeader, CardFooter, Container } from 'reactstrap';
import { map } from 'underscore';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import draftToHtml from 'draftjs-to-html'
import { filterFetcher } from "api/emails";
import { AlertMessagesContext } from 'components/helpers/alert_messages';

const Show = (props) => {
  const { record, match,location, setRecord } = props
  const [emailList, setEmailList] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [disable, setDisable] = useState(location?.state?.record?.status === 'active' ? true : false)
  const [senderEmail, setSenderEmail] = useState(location?.state?.record?.sender_email)
  const [emailSubject, setEmailSubject] = useState(location?.state?.record?.title)
  const checkboxref = useRef(false)
  const [body, setbody] = useState(record?.content ? record?.content : location?.state?.record?.content)
  const [bodyEditorState, setbodyEditorState] = useState(() => EditorState.createEmpty())
  const [bodyField, setbodyField] = useState('')
  const [signature, setsignature] = useState(record?.signature ? record?.signature : location?.state?.record?.signature)
  const [signatureEditorState, setsignatureEditorState] = useState(() => EditorState.createEmpty())
  const [signatureField, setsignatureField] = useState()
  const [selectedBCC, setSelectedBCC] = useState(location?.state?.record?.bcc ? location?.state?.record?.bcc : [])
  const [selectedCC, setSelectedCC] = useState(location?.state?.record?.cc ? location?.state?.record?.cc : [])
  const [changeStatus, setChangeStatus] = useState(false)
  const [file, setFile] = useState()
  const fileRef = useRef()
  const formApiRef = useRef();
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  useEffect(() => {
    const fetchEmails = async () => {
      setIsFetching(true)
      try {
        const response = await filterFetcher()
        if(response.status === 200 || response.status === 201) {
          setEmailList(response?.data);
        }
      } catch(error) {
        console.log(error)
      } finally{
        setIsFetching(false)
      }
    };
    fetchEmails();
  }, [setEmailList])

  useEffect(() => {
    const fetchNotification = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: match?.params?.id})
        if(response.status === 200 || response.status === 201) {
          setRecord(response?.data)
          setSenderEmail(response?.data?.sender_email);
          setEmailSubject(response?.data?.title)
          setbody(response?.data?.content)
          setSelectedCC(response?.data?.cc ? response?.data?.cc : [])
          setSelectedBCC(response?.data?.bcc ? response?.data?.bcc : [])
          setDisable(response?.data?.status === 'active' ? true : false)
        }
      } catch(error) {
        console.log(error)
      } finally{
        setIsFetching(false)
      }
    };
    fetchNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSenderEmail, setEmailSubject, setbody])

  const onCCChange = label => {
    const initialSelectedCC = selectedCC;
    const findIdx = initialSelectedCC?.indexOf(label);
    if (findIdx > -1) {
      initialSelectedCC.splice(findIdx, 1);
    } else {
      initialSelectedCC.push(label);
    }
    setSelectedCC(initialSelectedCC)
  };

  const onBCCChange = label => {
    const initialSelectedBCC = selectedBCC;
    const findIdx = initialSelectedBCC?.indexOf(label);
    if (findIdx > -1) {
      initialSelectedBCC.splice(findIdx, 1);
    } else {
      initialSelectedBCC.push(label);
    }
    setSelectedBCC(initialSelectedBCC)
  };

  useEffect(() => {
    if(location?.state?.record?.content !== record?.content) {
      setbody(record?.content)
      setbodyEditorState(() => EditorState.createWithContent(stateFromHTML(record?.content)))
    }

    if(location?.state?.record?.signature !== record?.signature) {
      setsignature(record?.signature)
      setsignatureEditorState(() => EditorState.createWithContent(stateFromHTML(signature)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  useEffect(() => {
    setbodyEditorState(() => EditorState.createWithContent(stateFromHTML(body)))
    setsignatureEditorState(() => EditorState.createWithContent(stateFromHTML(signature)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handlebodyChange = (state) => {
      setbodyEditorState(state)
      setbody(draftToHtml(convertToRaw(bodyEditorState.getCurrentContent())))
    }

    const importComponent = async () => {
      const module = await import('react-draft-wysiwyg')
      const Editor = module.Editor
      setbodyField(
        <Editor
          editorState={bodyEditorState}
          toolbarClassName={styles.toolbarClassName}
          wrapperClassName={styles.wrapperClassName}
          editorClassName={styles.editorClassName}
          onEditorStateChange={handlebodyChange}
        />,
      )
    }
    importComponent()
  }, [bodyEditorState])


  useEffect(() => {
    const handlesignatureChange = (state) => {
      setsignatureEditorState(state)
      setsignature(draftToHtml(convertToRaw(signatureEditorState.getCurrentContent())))
    }

    const importComponent = async () => {
      const module = await import('react-draft-wysiwyg')
      const Editor = module.Editor
      setsignatureField(
        <Editor
          editorState={signatureEditorState}
          toolbarClassName={styles.toolbarClassName}
          wrapperClassName={styles.wrapperClassName}
          editorClassName={styles.editorClassName}
          onEditorStateChange={handlesignatureChange}
        />
      )
    }
    importComponent()
  }, [signatureEditorState])

  const handleDocumentClick = () => {
    fileRef.current.click();
  };

  const getBase64= file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        setFile(reader.result);
        resolve(baseURL);
      };
    });
  };

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
      getBase64(event.target.files[0])
    }
  };

  const toggleStatus = useCallback(async () => {
    try {
      await change_status({id : record?.id})
    } catch (error) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record])
  
  const save = useCallback(async () => {
    const data = { 
      sender_email: senderEmail,
      title: emailSubject,
      cc: selectedCC,
      bcc: selectedBCC,
      content: body,
      signature: signature,
      file: file
    }
    try {
      if(changeStatus) toggleStatus()
      const response = await update({id: record?.id, data: {notification_message: data}})
      if(response.status === 200 || response.status === 201){
        addAlertMessages([{
          type: 'primary',
          text: 'Changes successfully saved',
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[senderEmail, emailSubject, disable, selectedCC, selectedBCC, body, signature, fileRef, record, file])
  
  if (isFetching) {
    return <Loader />;
  }

  return (
    <Form getApi={setFormApi} initialValues={record} className={styles.form} onSubmit={save}>
      <NavigationToolbar
        backTab='/dashboard/notifications'
        className='d-flex justify-content-space-between'
      />
      <Container className={`px-0 pb-4 mw-100 ${styles.container}`}>
        <div className={styles.rightSection}>
          <div className={styles.event_name}>
            Notification Event: {record?.event_name.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}
          </div>
          <div className='align-items-center w-auto mr-3 mt-4 pb-4'>
            <span>From Email Address</span>
            <div className='pt-2'>
              <input
                type="email"
                id="sender_email"
                name="sender_email"
                placeholder="Sender Email"
                value={senderEmail}
                onChange={(e) => setSenderEmail(e.target.value)}
                className={styles.inputField}
              />
              </div>
          </div>

          <div className='d-flex align-items-center w-auto mr-3 mt-3 pb-4'>
            <input
              type="checkbox"
              checked={!disable}
              onChange={() =>  {setDisable(!disable); setChangeStatus(!changeStatus)} }
              ref={checkboxref}
              className={`${styles.customCheckStyle} d-flex align-items-center mr-3`}
            />
            <span>Disable Email</span>
          </div>
        
          <div className={`${styles.listContainer} mt-3 pb-4`}>
            <label className={styles.applyLabel}>CC Email Address</label>
            <ul className={styles.ul}>
              {map(emailList, item => ( 
                <div className={`${styles.li} d-flex align-items-center w-auto pl-2`}>
                  <input
                    type="checkbox"
                    defaultChecked={selectedCC?.includes(item?.id)}
                    value={item.id}
                    onChange={() => onCCChange(item?.id)}
                    selected={selectedCC?.includes(item?.id)}
                    className={styles.customCheckStyle}
                    />
                    <div><span>{item?.email}</span></div>
                </div>
              ))}
            </ul>
          </div>

          <div className={`${styles.listContainer} mt-3 pb-4`}>
            <label className={styles.applyLabel}>BCC Email Address</label>
            <ul className={styles.ul}>
              {map(emailList, item => ( 
                <div className={`${styles.li} d-flex align-items-center w-auto pl-2`}>
                  <input
                    type="checkbox"
                    defaultChecked={selectedBCC?.includes(item?.id)}
                    value={item.id}
                    onChange={() => onBCCChange(item?.id)}
                    selected={selectedBCC?.includes(item?.id)}
                    className={styles.customCheckStyle}
                    />
                    <div><span>{item?.email}</span></div>
                </div>
              ))}
            </ul>
          </div>

          <div className='align-items-center w-auto mr-3 mt-3 pb-4'>
            <span>Email Subject</span>
            <div className='pt-2'>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Email Subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                className={styles.inputField}
              />
              </div>
          </div>

          <div className='align-items-center w-auto mr-3 mt-3 pb-4'>
            <span>Email Body</span>
            <div className='pt-2 pb-1' style={{ width: '121%', borderRadius: '8px' }}>
              {bodyField}
            </div>
          </div>

          <div className='align-items-center w-auto mr-3 mt-3 pb-4'>
            <span>Email Attachment</span>
            <div className='pt-2 pb-1' style={{ width: '121%', borderRadius: '8px' }}>
              <div className={styles.addButton}>
                <input
                  style={{display: 'none'}}
                  ref={fileRef}
                  type="file"
                  onChange={handleFileChange}
                />
                <Button className={styles.button} onClick={() => handleDocumentClick()}>
                  Add More
                </Button>
              </div>
            </div>
          </div>

          <div className='align-items-center w-auto mr-3 mt-3 pb-4'>
            <span>Email Signature</span>
            <div className='pt-2 pb-2'>
              <input
                type="text"
                id="signature"
                name="signature"
                placeholder="Signature"
                className={styles.inputSignatureField}
              />
            </div>
            <div style={{ width: '121%', borderRadius: '5px' }}>{signatureField}</div>
          </div>
        </div>

        <div className={styles.leftSection}>
          <Card style={{ background: '#ebf8ff'}}>
            <CardHeader className={styles.header}>
              <div>Available attributes for selected event</div>  
            </CardHeader>
            <CardBody className={styles.rightSectionAttr}>
              <div>FullName</div>
              <div>PermitNumber</div>
              <div>PermitType</div>
              <div>PermitApplicationID</div>
              <div>IssueDate</div>
              <div>ExpiryDate</div>
              <div>ValidParkingLots</div>
            </CardBody>
            <CardBody className={styles.rightSectionAttr}>
              <div>FullName</div>
              <div>VehiclePlateNumber</div>
              <div>PermitType</div>
              <div>ValidityPeriod</div>
              <div>TotalCost</div>
              <div>Status</div>
              <div>RejectionReason</div>
              <div>RejectionNote</div>
              <div>ParkingLot</div>
              <div>PermitNumber</div>
              <div>ApplicationType</div>
            </CardBody>
            <CardFooter className={styles.footer}>
              <div style={{fontWeight: 'bold', fontSize: '15px', lineHeight: '1.2'}}>For example:</div>
              If you want to use PermitNumber write PermitNumber and it will be replicated by original value
            </CardFooter>
          </Card>
        </div>
      </Container>
      <Button type='submit' className={styles.button}>
        Save
      </Button>
    </Form>
  );
}

Show.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object
};

export default connectRecord(
  'dispute',
  SET_RECORD,
  resourceFetcher(show),
  withFetching(withCurrentUser(Show))
);
