import React, { useCallback, useRef } from 'react'
import { Form } from 'informed';
import { list as selectList } from 'selectors/list';
import { filterFetcher } from 'api/permit_waiting_lists';
import { filterFields } from 'components/helpers/fields/permit_waiting_lists';
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import { renderFieldsWithGrid } from 'components/base/forms/common_form';
import Button from 'components/base/button';

const FilterForm = (props) => {
  const {parkingLots,filters, permitTypes, permitTypeCategory, setFilters, match, setList, startFetching } = props;

  const attrs = {
    parkingLots,
    permitTypes,
    permitTypeCategory,
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel
  }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterPermits = useCallback(async (filters={}) => {

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
      // eslint-disable-next-line
  },[setList, startFetching, match?.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
    }
    setFilters(filters);
    filterPermits(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      permit_type_ids: '', 
      parking_lot_ids: '', 
    })
    setFilters({})
    filterPermits({})
  }

  const initialValues = {
    ...filters,
  }

  const getFields = () => filterFields({ ...attrs})

  return (
    <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        const fields = getFields()
        return <React.Fragment>
          <div lg={12} >
          {
            renderFieldsWithGrid(
              fields,
              3, 4, { lSize: 5, iSize: 7 }
            )
          }
          </div>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Search
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
        </React.Fragment>
      }}
    </Form>
  )
}

export default FilterForm;
