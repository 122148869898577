import { FieldType } from 'components/helpers/form_fields'

const fieldsNew = (status = [], violation_types = [], parking_lots = [], customLabelClass='', customInputClass='', startDateCreated='', endDateCreated='', startDateUpdated, endDateUpdated, popperPlacement='bottom-end') => {
  
  return ([     
    { 
      name: 'status', 
      label: 'Citation Status', 
      type: FieldType.DROPDOWN_SELECT_FIELD, 
      options: status,
      placeholder: "Choose a status",
      customLabelClass, 
      customInputClass 
    },
    { 
      name: 'violation_type_ids', 
      label: 'Violation Types', 
      type: FieldType.MULTISELECT_FIELD,
      showToolTip: false,
      options: [{ value: 0, label: 'All Violation Types' }].concat(violation_types.map(item => { return { value: item.value, label: item.label } })),
      placeholder: "Please select", 
      customLabelClass, 
      customInputClass,
      showCount: true  
    },
    { 
      name: 'parking_lot_ids', 
      label: 'Parking Lots', 
      type: FieldType.MULTISELECT_FIELD,
      showToolTip: false, 
      options: [{ value: 0, label: 'All Parking Lots' }].concat(parking_lots.map(lot => { return { value: lot.value, label: lot.label } })),
      placeholder: "Choose a parking lot", 
      customLabelClass, 
      customInputClass,
      showCount: true  
    },
    
    

  ])
};

const dateFields = ( customLabelClass='', customInputClass='', startDateCreated='', endDateCreated='', startDateUpdated, endDateUpdated) => {
  return ([     
    
    [{
        name: "created_at[from]",
        max: new Date(), 
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        placeholder: 'From'
      },
      {
        name: "created_at[to]",
        min: startDateCreated?.startDateCreated,
        max: new Date(),
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        placeholder: 'To'
      },
      {
        name: "updated_at[from]",
        max: new Date(), 
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        placeholder: 'From',
      },
      {
        name: "updated_at[to]",
        min: startDateUpdated?.startDateUpdated,
        max: new Date(), 
        showIcon: true,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        placeholder: 'To',
      }]

  ])
};

export { fieldsNew ,dateFields};