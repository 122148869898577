import React, { useEffect, useState } from "react";
import styles from "./index.module.sass";
import { displayUnixTimestamp } from "components/helpers";
import { show } from "api/webapp/transactions";
import { isEmpty } from "underscore";
import Loader from "components/helpers/loader";

const ShowDetails = (props) => {
  const { selectedRecord, getLabel } = props;
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    const fetchTransaction = async () => {
      setTransaction(null)
      try {
        const response = await show({ id: selectedRecord?.id });
        setTransaction(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedRecord?.id) {
      fetchTransaction();
    }
  }, [selectedRecord]);

  return (
    <div className={styles.modalContent}>
      {isEmpty(transaction) && <Loader />}
      {transaction && (
        <div className={styles.data}>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Transaction ID:</div>
            <div className={styles.modalValue}>{transaction?.id}</div>
            <div className={styles.modalLabelRight}>Type:</div>
            <div className={styles.modalValueRight}>
              {transaction?.payment_type}
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Date:</div>
            <div className={styles.modalValue}>
              {displayUnixTimestamp(transaction?.date_and_time, "MM/DD/YYYY")}
            </div>
            <div className={styles.modalLabelRight}>Time:</div>
            <div className={styles.modalValueRight}>
              {displayUnixTimestamp(transaction?.date_and_time, "hh:mm A")}
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Payment Type:</div>
            <div className={styles.modalValue}>
              <b>{transaction?.reason}</b>
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Session ID:</div>
            <div className={styles.modalValue}>
              <b>
                {transaction?.parking_session_id
                  ? transaction?.parking_session_id
                  : "N/A"}
              </b>
            </div>
            <div className={styles.modalLabelRight}>Permit ID:</div>
            <div className={styles.modalValueRight}>
              <b>{transaction?.permit_id ? transaction.permit_id : "N/A"}</b>
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Parking Lots:</div>
            <div className={styles.modalValue}>
              {transaction?.parking_lot?.name} <br />
              <span className={styles.addressLine}>
                ({transaction?.parking_lot?.address})
              </span>
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Vehicle LPN:</div>
            <div className={styles.modalValue}>{transaction?.vehicle?.lpn}</div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Amount:</div>
            <div className={`${styles.modalValue} ${styles.green}`}>
              ${transaction.amount}
            </div>
            <div className={styles.modalLabelRight}>Status:</div>
            <div
              className={`${styles.modalValueRight} ${
                styles[`status-${getLabel(transaction?.status)}`]
              }`}
            >
              {transaction?.status}
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.modalLabel}>Payment Method:</div>
            <div className={styles.modalValue}>
              {transaction?.payment_method}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowDetails;