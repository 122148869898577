import React, { useCallback, useEffect, useState } from 'react'
import {  Col, Row } from 'reactstrap';
import FilterForm from './filterForm';
import styles from './index.module.sass';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { search as dropdownsSearch } from "api/dropdowns";

const CollapseContent = ( props ) => {
  const { activeTab, filters, startFetching, setList, setFilters, match, resource } = props

  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
    permitTypes: [],
  })
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch('parking_lot_list')).then((response) =>
          setDropdowns(state => ({...state, parkingLots: response.data }))),
        startFetching(
          dropdownsSearch('permit_type_list')
        ).then((response) => setDropdowns(state => ({...state, permitTypes: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      { activeTab === 'filters' && 
        <FilterForm 
          {...dropdowns}
          {...props}
          startFetching={startFetching} 
          setList={setList} 
          match={match} 
          resource={resource} 
          filters={filters}
          setFilters={setFilters}
        /> 
      }
    </Row>
  )
}

const ToolBar = (props) => {
  const {activeTab, setActiveTab, isResourceFetching, waitingListRecord, setWaitingListRecord} = props;
  const handleToggleCollapse = useCallback((tab) => {
     if(isResourceFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isResourceFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
          waitingListRecord={waitingListRecord}
          setWaitingListRecord={setWaitingListRecord}
        />
      }
      backTab='/dashboard/permits'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-end px-0'>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;