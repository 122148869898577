import React, { useCallback, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import View from '../view';
import Delete from '../delete';
import ParkingSession from '../../parking_session';
import { delete_notification, filterFetcher } from 'api/webapp/notifications';
import RaiseDispute from '../../disputes/raise_dispute';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initState = { errors: {}, isSaving: false, statuses: [] }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'statuses':
      return { ...state, statuses: payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const NotificationModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { record, openModal, modalType, setModalType, setList, setCount } = props

  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'view':
        return { width: '680px' };
      default:
        return { width };
    }
  },[modalType])

  const innerModalProps = useCallback(() => {
    const width = '480px'
    switch (state.innerModal) {
      case 'show_session':
        return { width: '680px' };
      case 'raise_dispute':
        return { width: '680px' };
      default:
        return { width };
    }
  },[state.innerModal])
      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
    setState('innerModal', null)
  }, [openModal, setState])
        
  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null)}}
    />
  },[setState]);

  const deleteNotification = async (item) => {
    try{
      const response = await delete_notification( {id: item.id} )
      if(response?.status === 200){
        const filter = await filterFetcher();
        setList(filter.data)
        setCount(filter?.headers['x-total'])
        closeModal()
      }
    }catch(error){
      console.log(error)
    }
  }

  const commonProps = {
    record,
    state,
    setState,
    openModal,
    setModalType,
    closeModal
  };
 
  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().width, width: '100%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        { modalType === 'view' &&  
          <View 
          {...commonProps}
            openInnerModal={() => setState('innerModal', 'show_session')}
          /> 
        }
        { modalType === 'delete' &&  
          <Delete 
          {...commonProps}
            deleteNotification={deleteNotification}
          /> 
        }
      </ModalBody>
      {!!state.innerModal &&
        <Modal
          isOpen={!!state.innerModal}
          centered
          style={{maxWidth: innerModalProps().width, width: '100%'}}
        >
          { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
          <ModalBody>
            { state.innerModal === 'show_session' &&  
              <ParkingSession 
                {...commonProps}
                modalType={modalType}
                closeModals={() => {setState('innerModal', null)}}
                openInnerModal={() => setState('innerModal', 'raise_dispute')}
                isSaving={state.isSaving}
              /> 
            }
            { state.innerModal === 'raise_dispute' &&  
              <RaiseDispute 
                {...commonProps}
                modalType={modalType}
                closeModals={() => {setState('innerModal', 'show_session')}}
                openInnerModal={() => setState('innerModal', 'raise_dispute')}
                isSaving={state.isSaving}
              /> 
            }
          </ModalBody>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClicks
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Modal>
      }
    </Modal>
  )
}

export default NotificationModal
