import React, { useCallback, useMemo, useRef } from "react";
import { Form } from "informed";
/* API */
import { index } from "api/webapp/city_events";
/* Helpers */
import filterFields from "components/helpers/fields/app/city_events/filterFields";
/* Styles/Assets */
import styles from "./index.module.sass";
import { Col, Row } from "reactstrap";
/* Base */
import { renderField } from "components/base/forms/common_form";
import Button from "components/base/button";
import { map } from "underscore";

const FilterForm = (props) => {
  const { filters, setFilters, setIsFetching, setEvents, selectedSubTab, t} = props;

  const formApiRef = useRef();
  const setFormApi = (formApi) => (formApiRef.current = formApi);

  const filterFieldProps = {
    lSize: 3,
    iSize: 6,
  };

  const fetchEvents = async (filters) => {
    setIsFetching(true);
    try {
      const response = await index({
        status: selectedSubTab,
        filters: filters,
      });
      setEvents(response?.data);
    } catch (_error) {
    } finally {
      setIsFetching(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      ...filters,
    }),
    [filters]
  );
  const handleFilters = useCallback(() => {
    const { values: filterValues } = formApiRef.current.getState();
    setFilters(filterValues);
    fetchEvents(filterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters]);

  const resetFilters = useCallback(async () => {
    formApiRef.current.setValues({
      search: "",
      cost_type: "",
      start_date: {
        from: "",
        to: "",
      },
    });
    setFilters(null);
    fetchEvents(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters]);

  return (
    <Row className="mx-auto w-100">
      <Form
        getApi={setFormApi}
        initialValues={initialValues}
        className={styles.form}
        onSubmit={handleFilters}
      >
        <Row className="d-flex">
          {map(filterFields(styles.formLabel, styles.formInput, styles.formDropdown, t, styles.calendarIcon), (field, idx) => {
            return (
              <Col key={idx} className="w-100">
                {renderField(field, { ...filterFieldProps })}
              </Col>
            );
          })}
        </Row>

        <Col className="d-flex justify-content-center pt-3 pb-2">
          <Button
            className={`${styles.btn} ${styles["background-primary"]} mr-5`}
            type="submit"
          >
            {t("events.filter")}
          </Button>
          <Button
            type="button"
            onClick={resetFilters}
            className={`${styles.btn} ${styles["background-secondary"]}`}
          >
            {t("events.reset")}
          </Button>
        </Col>
      </Form>
    </Row>
  );
};

export default FilterForm;