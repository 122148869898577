import React, { useEffect, useState, useCallback } from "react";
import {
  ConfigProvider,
  Row,
  Col,
  Form,
  Input,
  Radio,
  Upload,
  Select,
  message,
  DatePicker,
  Divider,
  Modal,
  Button,
  Tag,
} from "antd";
import styles from "./index.module.sass"; // Assuming you have a Sass module for styling
import {
  PlusOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  FilePdfOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import { isEmpty } from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { PDFReader } from "reactjs-pdf-reader";
import { ReactComponent as EditIcon } from "assets/edit_icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { debounce } from "lodash";
import BaseButton from "components/base/button";
import TooltipInfo from "components/helpers/tooltip_info";
import "./antd.css";
import { update } from "api/webapp/subscriber";
import { Spinner } from "reactstrap";

const { Dragger } = Upload;
const { Option } = Select;

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default" />
    </span>
  );
};
const MyProfile = (props) => {
  const { currentSubscriber, t } = props;
  const [profilePicture, setProfilePicture] = useState(
    currentSubscriber?.avatar
  );
  const [base64ProfilePicture, setBase64ProfilePicture] = useState("");
  // eslint-disable-next-line
  const [uploadProgress, setUploadProgress] = useState(0);
  const [accountTypes, setAccountTypes] = useState(null);
  const [dlnExpiryDate, setDlnExpiryDate] = useState("");
  const [stateList, setStateList] = useState(null);
  const [countries, setCountries] = useState(null);
  const [countryCode, setCountryCode] = useState("us");
  const [idProof, setIdProof] = useState({
    url: currentSubscriber?.address_proof?.data_url,
  });
  const [idProofBase64, setIdProofBase64] = useState();
  const [errors, setErrors] = useState();
  const [file, setFile] = useState(currentSubscriber?.address_proof?.url);
  const [base64, setBase64] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showInnerDelete, setShowInnerDelete] = useState(false);
  const [requireAccessibilityAsst, setRequireAccessibilityAsst] = useState(
    currentSubscriber?.accessibility_assistance
  );
  const [disabilities, setDisabilities] = useState();
  const [certificateProof, setCertificateProof] = useState({
    url: currentSubscriber?.disability_certificate?.data_url,
  });
  const [certificateFile, setCertificateFile] = useState(
    currentSubscriber?.disability_certificate?.url
  );
  const [certificateBase64, setCertificateBase64] = useState(null);
  const [certificatePreviewVisible, setCertificatePreviewVisible] = useState(false);
  const [showCertificateDelete, setShowCertificateDelete] = useState(false);
  const [showInnerCertificateDelete, setShowInnerCertificateDelete] = useState(false);
  const [addressProofFileType, setAddressProofFileType] = useState(
    currentSubscriber?.address_proof?.content_type
  );
  const [certificateFileType, setCertificateProofFileType] = useState(
    currentSubscriber?.disability_certificate?.content_type
  );
  const [deleteAvatar, setDeleteAvatar] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [subscriberLanguage, setSubscriberLanguage] = useState(currentSubscriber?.language)
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Saved Changes Successfully',
    });
  };

  const initialIdProof = {
    url: currentSubscriber?.address_proof?.data_url, // Replace with your actual link
    name: "Initial ID Proof",
  };

  const initialDisabilityCertificate = {
    url: currentSubscriber?.disability_certificate?.data_url, // Replace with your actual link
    name: "Initial Certificate",
  };

  useEffect(() => {
    if (initialIdProof?.data_url) {
      setIdProof(initialIdProof);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialDisabilityCertificate?.data_url) {
      setCertificateProof(initialDisabilityCertificate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadProps = {
    name: "file",
    accept: "image/*",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isValidFileType) {
        message.error(
          t("signup.validations.invalid_file_format")
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfilePicture(file);
        setBase64ProfilePicture(reader.result);
        setDeleteAvatar(false);
      };
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} ${t("signup.validations.file_upload_success")}`);
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ${t("signup.validations.file_upload_fail")}`);
        setUploadProgress(0);
      }
    },
  };

  const idProofUploadProps = {
    accept: ".jpg,.jpeg,.png,.pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      setTimeout(() => {
        handleChange({ file: { status: "done", originFileObj: file } });
      }, 1000);
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error(
          t("subscriber.profile.document_upload_failed")
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setIdProofBase64(reader.result);
        setIdProof({ url: reader.result, name: file.name, type: file.type });
        setAddressProofFileType(file.type);
      };
      return false;
    },
  };

  const handleCertificateChange = (info) => {
    if (info.file.status === "done") {
      setCertificateFile(info.file.originFileObj);
      message.success(
        `${info.file.originFileObj.name} ${t("signup.validations.file_upload_success")}`
      );
    } else if (info.file.status === "error") {
      message.error(`${info.file.originFileObj.name} ${t("signup.validations.file_upload_fail")}`);
    }
  };

  const certificateUploadProps = {
    accept: ".jpg,.jpeg,.png,.pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      setTimeout(() => {
        handleCertificateChange({
          file: { status: "done", originFileObj: file },
        });
      }, 1000);
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error(
          "Invalid file format. Please upload JPG, JPEG, PNG, or PDF files only."
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setCertificateBase64(reader.result);
        setCertificateProof({
          url: reader.result,
          name: file.name,
          type: file.type,
        });
        setCertificateProofFileType(file.type)
      };
      return false;
    },
  };

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const accountTypeList = await dropdownsSearch("account_types_list");
        setAccountTypes(accountTypeList.data);
      } catch (_error) {
        console.log(_error)
      }
    };
    if (isEmpty(accountTypes)) fetchAccountTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesList = await dropdownsSearch("states_list", {
          country_code: countryCode,
        });
        setStateList(statesList.data);
      } catch (_error) {
        console.log(_error)
      }
    };
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesList = await dropdownsSearch("countries_list");
        setCountries(countriesList.data);
      } catch (_error) {
        console.log(_error)
      }
    };
    if (isEmpty(countries)) fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeProfilePicture = () => {
    setProfilePicture(null);
    setBase64ProfilePicture("");
    setDeleteAvatar(true);
  };

  useEffect(() => {
    const fetchDisabilities = async () => {
      try {
        const disabilitiesList = await dropdownsSearch("disabilities_list");
        setDisabilities(disabilitiesList.data);
      } catch (_error) {
        console.log(_error)
      }
    };
    if (isEmpty(disabilities)) fetchDisabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledExpiryDates = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const debouncedHandleFieldChange = debounce((_, allFields) => {
    const updatedErrors = { ...errors };
    allFields.forEach((field) => {
      updatedErrors[field.name[0]] = field.errors.length > 0;
    });
    setErrors(updatedErrors);
  }, 300);

  const handleFieldChange = (_, allFields) => {
    debouncedHandleFieldChange(_, allFields);
  };

  const getLabel = (fieldName, label, required) => {
    const isError = errors?.[fieldName] || false;
    return (
      <Col className={styles.labelCol}>
        <span className={styles.formLabel}>{t(`signup.${label}`)}</span>
        {required && (
          <Tag
            className={styles.requiredMark}
            style={{ color: isError ? "#FB745B" : "#3a9ced" }}
          >
            *
          </Tag>
        )}
      </Col>
    );
  };

  useEffect(() => {
    const isLinkActiveStorage = (value) => {
      return value?.startsWith(currentSubscriber?.address_proof?.url);
    };
    const extractFileName = (link) => {
      return link.split("/").pop();
    };

    const initialValue = currentSubscriber?.address_proof?.url;

    if (isLinkActiveStorage(initialValue)) {
      setFile({ name: extractFileName(initialValue), url: initialValue });
      setBase64(initialValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isLinkActiveStorage = (value) => {
      return value?.startsWith(currentSubscriber?.disability_certificate?.url);
    };
    const extractFileName = (link) => {
      return link.split("/").pop();
    };
    const initialValue = currentSubscriber?.disability_certificate?.url;
    if (isLinkActiveStorage(initialValue)) {
      setCertificateFile({
        name: extractFileName(initialValue),
        url: initialValue,
      });
      setCertificateBase64(initialValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const handleCancelPreview = () => {
    setPreviewVisible(false);
  };

  const handleCertificatePreview = () => {
    setCertificatePreviewVisible(true);
  };

  const handleCancelCertificatePreview = () => {
    setCertificatePreviewVisible(false);
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      setFile(info.file.originFileObj);
      setBase64(URL.createObjectURL(info.file.originFileObj));
      message.success(
        `${info.file.originFileObj.name} ${t("signup.validations.file_upload_success")}`
      );
    } else if (info.file.status === "error") {
      message.error(`${info.file.originFileObj.name} ${t("signup.validations.file_upload_fail")}`);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setBase64(null);
    setShowDelete(false);
    setShowInnerDelete(false);
    message.success(t("subscriber.profile.file_deleted"));
  };

  const handleCertificateDelete = () => {
    setCertificateFile(null);
    setCertificateBase64(null);
    setShowCertificateDelete(false);
    setShowInnerCertificateDelete(false);
    message.success(t("subscriber.profile.file_deleted"));
  };

  const setDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const setDeleteCertificateModal = () => {
    setShowCertificateDelete(!showCertificateDelete);
  };

  const setInnerDeleteModal = () => {
    setShowInnerDelete(!showInnerDelete);
  };

  const setInnerCertificateDeleteModal = () => {
    setShowInnerCertificateDelete(!showInnerCertificateDelete);
  };

  const validateIDProof = async () => {
    if (!idProof) {
      return Promise.reject(t("signup.validations.id_proof"));
    }
  };

  const validateCertificate = async () => {
    if (!certificateProof) {
      return Promise.reject(t("signup.validations.id_proof"));
    }
  };

  const updateUser = useCallback(async (user) => {
    setIsSaving(true);
    try {
      const response = await update(user);
      if (response.status === 200 || response.status === 201) {
        success();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    } finally {
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = useCallback(
    (values) => {
      const data = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        avatar: base64ProfilePicture,
        delete_avatar: deleteAvatar,
        phone: values?.phoneNumber,
        email: values.email,
        address_proof: idProofBase64,
        id_number: values?.idNumber,
        dln_expiry_date: dlnExpiryDate,
        accessibility_assistance: `${requireAccessibilityAsst}`,
        disability_certificate: requireAccessibilityAsst
          ? certificateProof.url
          : null,
        disabilities_ids: values?.disabilities_ids,
        language: subscriberLanguage,
        home_address: {
          address1: values?.address1,
          address2: values?.address2,
          city: values?.city,
          state_code: values?.state,
          country_code: values?.country,
          postal_code: values?.zipcode,
        },
      };
      updateUser(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      requireAccessibilityAsst,
      base64ProfilePicture,
      idProofBase64,
      dlnExpiryDate,
      certificateBase64,
      deleteAvatar,
      certificateProof,
      subscriberLanguage
    ]
  );

  const initialValues = {
    firstName: currentSubscriber?.first_name,
    lastName: currentSubscriber?.last_name,
    email: currentSubscriber?.email,
    phoneNumber: currentSubscriber?.phone,
    accountType: currentSubscriber?.account_type_id,
    expiryDate: currentSubscriber?.dln_expiry_date
      ? dayjs(currentSubscriber?.dln_expiry_date)
      : "",
    idNumber: currentSubscriber?.id_number,
    address1: currentSubscriber?.home_address?.address1,
    address2: currentSubscriber?.home_address?.address2,
    state: currentSubscriber?.home_address?.state_code,
    country: currentSubscriber?.home_address?.country_code,
    city: currentSubscriber?.home_address?.city,
    zipcode: currentSubscriber?.home_address?.postal_code,
    disabilities_ids: currentSubscriber.disabilities?.map((disability, idx) => {
      return disability.id;
    }),
    additional_note: currentSubscriber?.assistance_note,
  };

  return (
    <div className={styles.formContainer}>
      {contextHolder}
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "#242E42",
              labelRequiredMarkColor: "#7ab7f3",
              labelFontSize: "13px",
              colorError: "#FB745B",
              fontFamily: "Roboto, sans-serif",
              fontSize: "13px",
            },
            Radio: {
              buttonBg: "#000000",
            },
            Select: {
              optionFontSize: "13px",
              colorBorderSecondary: "transparent",
              controlOutline: "transparent",
              colorPrimaryBorder: "#3A9CED",
              colorBorderBg: "#3A9CED",
            },
          },
          token: {
            colorPrimary: "#1890ff",
            fontFamily: "Roboto, sans-serif",
          },
        }}
      >
      <div className={styles.languageSection}>
        <label className={styles.languageLabel}>{t("subscriber.profile.preferred_language")}</label>
        <Select
          className={styles.languageSelector}
          defaultValue={currentSubscriber?.language}
          onChange={setSubscriberLanguage}
          style={{ border: "none" }}
        >
          <Option value="english"><span role="img" aria-label="us">🇺🇸</span> EN</Option>
          <Option value="spanish"><span role="img" aria-label="es">🇪🇸</span> ES</Option>
          <Option value="hindi"><span role="img" aria-label="hi">🇮🇳</span> HI</Option>
        </Select>
      </div>
        <Form
          initialValues={initialValues}
          onFieldsChange={handleFieldChange}
          requiredMark={false}
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 12 }}
          labelAlign="left"
          colon={false}
          className={styles.form}
          name="basic"
          onFinish={(values) => submitForm(values)}
        >
          <div className={styles.formRow}>
            <Row className="d-flex justify-content-center align-items-center pb-4 pr-3 m-0 p-0">
              <Upload {...uploadProps}>
                {profilePicture ? (
                  <div className={styles.uploadedFileContainer}>
                    <img
                      src={
                        base64ProfilePicture
                          ? base64ProfilePicture
                          : profilePicture
                      }
                      alt="Profile"
                      className={styles.profilePicture}
                    />
                    <Button
                      type="text"
                      className={styles.deleteButton}
                      onClick={removeProfilePicture}
                    >
                      <DeleteOutlined className={styles.removeIcon} />
                    </Button>
                  </div>
                ) : (
                  <div
                    className={styles.upload}
                    style={{ opacity: profilePicture ? 0.5 : 1 }}
                  >
                    <PlusOutlined />
                    <div className={styles.uploadText}>{t("signup.Upload")}</div>
                  </div>
                )}
              </Upload>
            </Row>
            <div className={styles.formItem1}>
              <Form.Item
                label={getLabel("firstName", "First Name", true)}
                name="firstName"
                rules={[
                  { required: true, message: t("signup.validations.first_name") },
                ]}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <Form.Item
                label={getLabel("lastName", "Last Name", true)}
                name="lastName"
                rules={[
                  { required: true, message: t("signup.validations.last_name") },
                ]}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name="email"
                label={getLabel("email", "Email", true)}
                rules={[
                  { required: true, message: t("signup.validations.email.required")},
                  {
                    type: "email",
                    message: t("signup.validations.email.format"),
                  },
                ]}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={getLabel("phoneNumber", "Phone Number", true)}
                rules={[
                  {
                    required: true,
                    message: t("signup.validations.phone_number.required"),
                  },
                  {
                    pattern: /^\d{11 || 12}$/,
                    message: t("signup.validations.phone_number.format"),
                  },
                ]}
              >
                <PhoneInput
                  inputClass={styles.phoneInput}
                  dropdownClass={styles.dropdown}
                  searchClass={styles.search}
                  searchStyle={{
                    fontSize: "13px",
                    height: "30px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  disableSearchIcon
                  searchPlaceholder="Search for Countries"
                  searchNotFound="Not Found"
                  country="us"
                  enableSearch={true}
                  autocompleteSearch={true}
                />
              </Form.Item>
            </div>
          </div>
          <div className="pt-2">
            <Form.Item
              name="accountType"
              label={getLabel("accountType", "Account Type", true)}
              labelCol={{ span: 6 }}
              rules={[
                {
                  required: true,
                  message: t("signup.validations.account_type.required"),
                },
              ]}
            >
              <Select
                options={accountTypes ? accountTypes : ""}
                showSearch
                placeholder={t("signup.validations.account_type.placeholder")}
                optionFilterProp="label"
                className={styles.formInputDropdown}
              />
            </Form.Item>
            <div className={styles.formRow}>
              <Form.Item
                name="idNumber"
                label={getLabel("idNumber", "Driving License Number", true)}
                rules={[
                  {
                    required: true,
                    message: t("signup.validations.dln"),
                  },
                ]}
                className={styles.formItem}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name="expiryDate"
                label={getLabel("expiryDate", "Expiration Date", true)}
                rules={[
                  {
                    required: true,
                    message: t("signup.validations.dln_expiry.required"),
                  },
                ]}
                className={styles.formItem}
              >
                <DatePicker
                  disabledDate={disabledExpiryDates}
                  suffixIcon={
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      color="grey"
                      className={styles.calenderIcon}
                    />
                  }
                  className={styles.calenderInput}
                  placeholder={t("signup.validations.dln_expiry.placeholder")}
                  format="DD/MM/YYYY"
                  onChange={(date, dateString) => setDlnExpiryDate(dateString)}
                />
              </Form.Item>
            </div>
            <Divider className="pl-2" orientation="left" orientationMargin="0">
              {t("subscriber.my_permits.show.home_address")}
            </Divider>
            <div className={styles.formRow}>
              <Form.Item
                name="address1"
                label={getLabel("address1", "Address Line 1", true)}
                rules={[{ required: true, message: t("signup.validations.address1") }]}
                className={styles.formItem}
              >
                <Input className={styles.formInput} />
              </Form.Item>
              <Form.Item
                name="zipcode"
                label={getLabel("zipcode", "Postal Code", true)}
                className={styles.formItem}
                rules={[
                  { required: true, message: t("signup.validations.postal_code") },
                ]}
              >
                <Input className={styles.formInput} />
              </Form.Item>
            </div>
            <div className={styles.formRow}>
              <Form.Item
                name="address2"
                label={getLabel("address2", "Address Line 2", false)}
                className={styles.formItem}
              >
                <Input className={`${styles.formInput} pr-3`} />
              </Form.Item>
              <Form.Item
                name="state"
                label={getLabel("state", "State", true)}
                rules={[{ required: true, message: t("signup.validations.state") }]}
                className={styles.formItem}
              >
                <Select
                  options={stateList}
                  showSearch
                  optionFilterProp="label"
                  className={styles.formInput}
                />
              </Form.Item>
            </div>
            <div className={styles.formRow}>
              <Form.Item
                name="country"
                label={getLabel("country", "Country", true)}
                rules={[{ required: true, message: t("signup.validations.country")}]}
                className={styles.formItem}
              >
                <Select
                  options={countries}
                  showSearch
                  optionFilterProp="label"
                  className={styles.formInput}
                  onChange={(e) => setCountryCode(e)}
                />
              </Form.Item>
              <Form.Item
                name="city"
                label={getLabel("city", "City", true)}
                className={styles.formItem}
                rules={[{ required: true, message: t("signup.validations.city") }]}
              >
                <Input className={styles.formInput} />
              </Form.Item>
            </div>

            <div className={`${styles["file-upload-field"]} pl-4`}>
              {file ? (
                <div className={styles["file-info"]}>
                  {(file.type && file.type.includes("image")) ||
                  (addressProofFileType &&
                    addressProofFileType.split("/")[0] === "image") ? (
                    <FileImageOutlined
                      style={{ fontSize: "20px", marginRight: "8px" }}
                    />
                  ) : (
                    <FilePdfOutlined
                      style={{ fontSize: "20px", marginRight: "8px" }}
                    />
                  )}
                  <span>{file.name}</span>
                </div>
              ) : (
                <span className={styles["file-info"]}>{t("subscriber.profile.no_file_selected")}</span>
              )}
              <div className={styles["action-buttons"]}>
                <Button
                  className={styles.viewBtn}
                  onClick={handlePreview}
                  disabled={!file}
                >
                  {t("buttons.view")}
                </Button>
              </div>
              <div className={styles["upload-button"]}>
                <Upload {...idProofUploadProps}>
                  <Button
                    className={styles.uploadBtn}
                    icon={<EditIcon className={styles.editIcon} />}
                  ></Button>
                </Upload>
              </div>
              <div className={styles["action-buttons"]}>
                <Button
                  className={styles.deleteBtn}
                  icon={<TrashIcon className={styles.trashIcon} />}
                  onClick={setDeleteModal}
                  disabled={!file}
                ></Button>
              </div>
              <Modal
                visible={previewVisible}
                onCancel={handleCancelPreview}
                footer={null}
                centered
                width={600}
                bodyStyle={{ padding: "20px" }}
                className={styles["custom-modal"]}
              >
                <div className={styles["modal-header"]}>
                  <p className={styles["modal-title"]}>
                    {t("subscriber.profile.address_proof_document")}
                  </p>
                </div>
                {idProof && file && (
                  <div className={styles["modal-description"]}>
                    <p>{t("subscriber.profile.uploaded_file_preview")}</p>
                  </div>
                )}
                <div className={styles["modal-content"]}>
                  {base64 && file ? (
                    <React.Fragment>
                      <Row className={styles.deleteIdProofButtonInModal}>
                        <BaseButton
                          className={styles.deleteButton2}
                          onClick={setInnerDeleteModal}
                        >
                          <TrashIcon
                            className={`svg-white ${styles.trashIcon}`}
                          />
                        </BaseButton>
                      </Row>
                      <div className={styles["preview-container"]}>
                        {(file.type && file.type.includes("image")) ||
                        (addressProofFileType &&
                          addressProofFileType.split("/")[0] === "image") ? (
                          <img
                            alt="Preview"
                            className={styles["preview-image"]}
                            src={base64}
                          />
                        ) : (file?.type && file?.type.includes("pdf")) ||
                          (addressProofFileType &&
                            addressProofFileType === "application/pdf") ? (
                          <div className={styles["preview-pdf"]}>
                            <PDFReader url={idProof?.url} scale={0.7} />
                          </div>
                        ) : (
                          <p className="text-center">{t("subscriber.profile.no_preview")}</p>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <Form.Item
                      name="id_proof"
                      valuePropName="fileList"
                      labelAlign="right"
                      getValueFromEvent={(e) => e && [e.file]}
                      rules={[
                        {
                          validator: validateIDProof,
                        },
                      ]}
                      className={styles.idProofUploadStyle}
                    >
                      <Dragger {...idProofUploadProps}>
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined style={{ color: "grey" }} />
                        </p>
                        <p className="ant-upload-text">
                          {t("signup.uploadText")}
                        </p>
                        <p className="ant-upload-hint">
                          {t("signup.formatsText")}
                        </p>
                      </Dragger>
                    </Form.Item>
                  )}
                  <Modal
                    visible={showInnerDelete}
                    onCancel={setInnerDeleteModal}
                    footer={null}
                    centered
                    width={400}
                    bodyStyle={{ padding: "10px" }}
                    className={styles["custom-modal"]}
                  >
                    <div className={styles["modal-header"]}>
                      <p className={styles["delete-modal-title"]}>
                        {t("subscriber.profile.are_you_sure")}
                      </p>
                    </div>
                    <div className={styles.deleteModalContent}>
                      <p>{t("subscriber.profile.document_will_be_removed")}</p>
                    </div>
                    <Col className={styles.buttonContainer}>
                      <Button
                        className={styles.prevButton}
                        onClick={setInnerDeleteModal}
                      >
                        {t("buttons.cancel")}
                      </Button>
                      <Button
                        type="primary"
                        className={styles.confirmButton}
                        onClick={handleDelete}
                      >
                        {t("buttons.confirm")}
                      </Button>
                    </Col>
                  </Modal>
                </div>
              </Modal>
              <Modal
                visible={showDelete}
                onCancel={setDeleteModal}
                footer={null}
                centered
                width={400}
                bodyStyle={{ padding: "10px" }}
                className={styles["custom-modal"]}
              >
                <div className={styles["modal-header"]}>
                  <p className={styles["delete-modal-title"]}>
                    {t("subscriber.profile.are_you_sure")}
                  </p>
                </div>
                <div className={styles.deleteModalContent}>
                  <p>{t("subscriber.profile.document_will_be_removed")}</p>
                </div>
                <Col className={styles.buttonContainer}>
                  <Button
                    className={styles.prevButton}
                    onClick={setDeleteModal}
                  >
                    {t("buttons.cancel")}
                  </Button>
                  <Button
                    type="primary"
                    className={styles.confirmButton}
                    onClick={handleDelete}
                  >
                    {t("buttons.confirm")}
                  </Button>
                </Col>
              </Modal>
            </div>
            <div className="pt-3">
              <div className={styles.formRow}>
                <p className="pt-3 pl-2">
                  {t("signup.assistancetext")}
                </p>
                <TooltipInfo
                  width="20"
                  height="20"
                  className={styles.tooltip}
                  text={t("subscriber.profile.disabilities_tooltip")}
                  target="share"
                />
                <Radio.Group
                  buttonStyle="solid"
                  required
                  className={styles.radioBtns}
                  onChange={(e) => setRequireAccessibilityAsst(e.target.value)}
                  value={requireAccessibilityAsst}
                >
                  <Radio buttonStyle="solid" className="pr-4" value={true}>
                    {t("choices.yes")}
                  </Radio>
                  <Radio buttonStyle="solid" className="pl-2" value={false}>
                    {t("choices.no")}
                  </Radio>
                </Radio.Group>
              </div>
              <Col className="pl-1 m-0">
                {requireAccessibilityAsst && (
                  <React.Fragment>
                    <p className="pt-2 pl-2" style={{ fontSize: "13px" }}>
                      {t("signup.disabilityNote")}
                    </p>
                    <div className={`${styles.formRow} pt-2 pl-2`}>
                      <Form.Item
                        name="disabilities_ids"
                        label={getLabel(
                          "disabilities_ids",
                          "Disabilities",
                          true
                        )}
                        rules={[
                          {
                            required: requireAccessibilityAsst,
                            message: "Please Select Disabitlity",
                          },
                        ]}
                        className={styles.formItem}
                      >
                        <Select
                          mode="multiple"
                          options={disabilities}
                          maxTagCount="responsive"
                          showSearch
                          optionFilterProp="label"
                          className={styles.formInput}
                        />
                      </Form.Item>
                      <Form.Item
                        name="additional_note"
                        label={t("signup.Additional Note")}
                        rules={[{ required: false }]}
                        className={styles.formItem}
                      >
                        <Input className={styles.formInput} />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                )}
              </Col>
              <div className={`${styles["file-upload-field"]} pl-4`}>
                {certificateFile ? (
                  <div className={styles["file-info"]}>
                    {(certificateFile.type &&
                      certificateFile.type.includes("image")) ||
                    (certificateFileType &&
                      certificateFileType.split("/")[0] === "image") ? (
                      <FileImageOutlined
                        style={{ fontSize: "20px", marginRight: "8px" }}
                      />
                    ) : (
                      <FilePdfOutlined
                        style={{ fontSize: "20px", marginRight: "8px" }}
                      />
                    )}
                    <span>{certificateFile.name}</span>
                  </div>
                ) : (
                  <span className={styles["file-info"]}>{t("subscriber.profile.no_file_selected")}</span>
                )}
                <div className={styles["action-buttons"]}>
                  <Button
                    className={styles.viewBtn}
                    onClick={handleCertificatePreview}
                    disabled={!certificateFile}
                  >
                    {t("buttons.view")}
                  </Button>
                </div>
                <div className={styles["upload-button"]}>
                  <Upload {...certificateUploadProps}>
                    <Button
                      className={styles.uploadBtn}
                      icon={<EditIcon className={styles.editIcon} />}
                    ></Button>
                  </Upload>
                </div>
                <div className={styles["action-buttons"]}>
                  <Button
                    className={styles.deleteBtn}
                    icon={<TrashIcon className={styles.trashIcon} />}
                    onClick={setDeleteCertificateModal}
                    disabled={!certificateFile}
                  ></Button>
                </div>
                <Modal
                  visible={certificatePreviewVisible}
                  onCancel={handleCancelCertificatePreview}
                  footer={null}
                  centered
                  width={600}
                  bodyStyle={{ padding: "20px" }}
                  className={styles["custom-modal"]}
                >
                  <div className={styles["modal-header"]}>
                    <p className={styles["modal-title"]}>
                      {t("subscriber.profile.disability_certificate_document")}
                    </p>
                  </div>
                  {certificateProof && certificateFile && (
                    <div className={styles["modal-description"]}>
                      <p>{t("subscriber.profile.uploaded_file_preview")}</p>
                    </div>
                  )}
                  <div className={styles["modal-content"]}>
                    {certificateBase64 && certificateFile ? (
                      <React.Fragment>
                        <Row className={styles.deleteIdProofButtonInModal}>
                          <BaseButton
                            className={styles.deleteButton2}
                            onClick={setInnerCertificateDeleteModal}
                          >
                            <TrashIcon
                              className={`svg-white ${styles.trashIcon}`}
                            />
                          </BaseButton>
                        </Row>
                        <div className={styles["preview-container"]}>
                          {(certificateFile.type &&
                            certificateFile.type.includes("image")) ||
                          (certificateFileType &&
                            certificateFileType.split("/")[0] === "image") ? (
                            <img
                              alt="Preview"
                              className={styles["preview-image"]}
                              src={certificateBase64}
                            />
                          ) : (certificateFile?.type &&
                              certificateFile?.type.includes("pdf")) ||
                            (certificateFileType &&
                              certificateFileType === "application/pdf") ? (
                            <div className={styles["preview-pdf"]}>
                              <PDFReader
                                url={certificateProof.url}
                                scale={0.7}
                              />
                            </div>
                          ) : (
                            <p className="text-center">{t("subscriber.profile.no_preview")}</p>
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      <Form.Item
                        name="id_proof"
                        valuePropName="fileList"
                        labelAlign="right"
                        getValueFromEvent={(e) => e && [e.file]}
                        rules={[
                          {
                            validator: validateCertificate,
                          },
                        ]}
                        className={styles.idProofUploadStyle}
                      >
                        <Dragger {...certificateUploadProps}>
                          <p className="ant-upload-drag-icon">
                            <CloudUploadOutlined style={{ color: "grey" }} />
                          </p>
                          <p className="ant-upload-text">
                            {t("signup.uploadText")}
                          </p>
                          <p className="ant-upload-hint">
                            {t("signup.formatsText")}
                          </p>
                        </Dragger>
                      </Form.Item>
                    )}
                    <Modal
                      visible={showInnerCertificateDelete}
                      onCancel={setInnerCertificateDeleteModal}
                      footer={null}
                      centered
                      width={400}
                      bodyStyle={{ padding: "10px" }}
                      className={styles["custom-modal"]}
                    >
                      <div className={styles["modal-header"]}>
                        <p className={styles["delete-modal-title"]}>
                          {t("subscriber.profile.are_you_sure")}
                        </p>
                      </div>
                      <div className={styles.deleteModalContent}>
                        <p>{t("subscriber.profile.document_will_be_removed")}</p>
                      </div>
                      <Col className={styles.buttonContainer}>
                        <Button
                          className={styles.prevButton}
                          onClick={setInnerCertificateDeleteModal}
                        >
                          {t("buttons.cancel")}
                        </Button>
                        <Button
                          type="primary"
                          className={styles.confirmButton}
                          onClick={handleCertificateDelete}
                        >
                          {t("buttons.confirm")}
                        </Button>
                      </Col>
                    </Modal>
                  </div>
                </Modal>
                <Modal
                  visible={showCertificateDelete}
                  onCancel={setDeleteCertificateModal}
                  footer={null}
                  centered
                  width={400}
                  bodyStyle={{ padding: "10px" }}
                  className={styles["custom-modal"]}
                >
                  <div className={styles["modal-header"]}>
                    <p className={styles["delete-modal-title"]}>
                      {t("subscriber.profile.are_you_sure")}
                    </p>
                  </div>
                  <div className={styles.deleteModalContent}>
                    <p>{t("subscriber.profile.document_will_be_removed")}</p>
                  </div>
                  <Col className={styles.buttonContainer}>
                    <Button
                      className={styles.prevButton}
                      onClick={setDeleteCertificateModal}
                    >
                      {t("buttons.cancel")}
                    </Button>
                    <Button
                      type="primary"
                      className={styles.confirmButton}
                      onClick={handleCertificateDelete}
                    >
                      {t("buttons.confirm")}
                    </Button>
                  </Col>
                </Modal>
              </div>
            </div>
            <Row className={styles.saveBtnRow}>
              <Button
                type="primary"
                className={styles.saveBtn}
                htmlType="submit"
              >
                {isSaving? btnSpinner({className: 'spinner-border'})   : t("subscriber.profile.save_changes")}
              </Button>
            </Row>
          </div>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default withCurrentSubscriber(MyProfile);