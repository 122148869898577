import React, { useCallback, useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/coupons/fieldsNew'
/* Styles/Assets */
import styles from './index.module.sass';
import withFetching from 'components/modules/with_fetching';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const initialValues = { 
    beneficiary_type: 'subscriber', 
}

const attrs = {
  customLabelClass: `${styles.formLabel}`,
  customInputClass: `${styles.formInput}`,
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const Create = (props) => {
  const  { generateCouponCode, closeModal, errors, setErrors ,isSaving } = props;
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const validateValues = (formState) => {
    const errors = {}
      const { beneficiary_type, user_email, amount, validity_period, reason } = formState;
      if(!beneficiary_type) { errors['beneficiary_type'] = ['Please Select Beneficiary']}
      if(beneficiary_type === "subscriber" && !user_email) { errors['user_email'] = ['Email can\'t be blank']}
      if(!amount) { errors['amount'] = ['Coupon Amount can\'t be blank']}
      if (!(/^\d+$/.test(amount))) { errors['amount'] = ['Amount is not a valid type']}
      if(!validity_period) { errors['validity_period'] = ['Validity period can\'t be blank']}
      if(!reason) { errors['reason'] = ['Reason type can\'t be blank']}     
      return errors
   }
   
  const doubleFieldProps = {
    lSize: 3,
    iSize: 8,
  }
  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    generateCouponCode(values);
  },[setErrors, generateCouponCode]);
  
  
  const getFields = () => {
    return fieldsNew({mute: false, ...attrs, icon: dollarIcon(), customIconInput: attrs.customInputClass.concat(' ', styles.iconInput)})
  }
  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >
          {({ formState }) => {
            const { beneficiary_type} = formState.values;
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100'>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 pl-2`} xs={12}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                {beneficiary_type === "subscriber" && <React.Fragment>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[1], { ...doubleFieldProps, errors} )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[2], { ...doubleFieldProps, errors, formState})}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[3], { ...doubleFieldProps, errors, formState} )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[4], { ...doubleFieldProps, errors} )}
                  </Col>
                </React.Fragment>}
                {beneficiary_type === "bulk_coupons" && <React.Fragment>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[2], { ...doubleFieldProps,errors } )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[3], { ...doubleFieldProps, errors, formState} )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                    { renderFields(newFields[4], { ...doubleFieldProps,errors} )}
                  </Col>
                </React.Fragment>}
        
                <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={`${styles.button}`}  isLoading={isSaving}>
                    Generate
                  </Button>
                </Col>
              </Row>
            )
          }}
        </Form>
      </fieldset>
    </Container>
  )
}

export default withFetching(Create);