import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";
import fetchApi from "components/modules/fetch_api";

const { create, index, destroy } = resourceApi("reward_types");
const search = searchApi("reward_types");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, query } = params

  return index({
    page,
    perPage,
    query: {
       ...query,
    },
  });
};

const usersEnrolledFilterFetcher = (params = {}) => {
  const { id } = params;
  return fetchApi(`dashboard/reward_types/${id}`, { method: 'GET'})
};

const pause = (params) => {
  const { id } = params;
  return fetchApi(`dashboard/reward_types/${id}/pause`, { method: 'PUT' })
};

const resume = (params) => {
  const { id } = params;
  return fetchApi(`dashboard/reward_types/${id}/resume`, { method: 'PUT' })
};


export { filterFetcher, create, index, destroy, search, usersEnrolledFilterFetcher, pause, resume};