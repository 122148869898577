import React from "react";
import styles from "./index.module.sass";
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import Action from "./action";

const TableRow = (props) => {
  const { record, setRecord, openModal, listType } = props;
  
  const actions = [
    {
      icon: <EyeIcon className={`${styles["icon-eye"]} mx-3`} />,
      highlightedClass: `${styles["background-primary"]} mx-3 text-white`,
      onClick: () => openModal("changes"),
      text: "Changes",
    },
  ];
  
  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.created_at_parsed}</td>
      <td>{record?.terminal ? record?.terminal : record?.action_performed_at}</td>
      <td className={listType !== 'error' ? 'd-none' : ''}>{record?.endpoint}</td>
      <td className={listType !== 'error' ? 'd-none' : ''}>
        {listType === 'error' && 
        ((record?.message?.error && Object?.keys(record?.message)?.[0] === 'error') && `${Object?.keys(record?.message)?.[0]}: ${record?.message?.error?.substring(0, 150)}${record?.message?.error?.length >= 150 ? '...' : ''}`)}
        {listType === 'error' && ((record?.message && Object?.keys(record?.message)?.[0] !== 'error') && `${Object?.keys(record?.message)?.[0]}: ${Object?.values(record?.message)?.[0]}`)}
      </td>
      <td className={listType === 'error' ? 'd-none' : ''}>
        <div className={`${styles.actions} d-flex justify-content-center`}>
          {actions.map((action, idx) => (
            <Action key={idx} {...action} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;