import React, { useCallback, useReducer, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "./index.module.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Show from "../show";

const initState = { errors: {}, isSaving: false, permitTypes: [] };

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case "isSaving":
      return { ...state, isSaving: payload, errors: payload ? {} : errors };
    case "errors":
      return { ...state, errors: payload };
    case "innerModal":
      return { ...state, innerModal: payload };
    default:
      return { ...state };
  }
};

const ChargingSessionModal = (props) => {
  const { openModal, modalType, record } = props;
  const [state, dispatch] = useReducer(reducer, initState);
  const [status, setStatus] = useState(record?.charger?.status);

  const modalProps = useCallback(() => {
    const width = "480px";
    switch (modalType) {
      case "stationCreate":
        return { width: "680px" };
      case "stationEdit":
        return { width: "680px" };
      case "show":
        return { width: "680px" };
      default:
        return { width };
    }
  }, [modalType]);

  const setState = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === undefined) setStatus(record?.charger?.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, record]);

  const closeModal = useCallback(() => {
    openModal();
    setState("innerModal", null);
  }, [openModal, setState]);

  const closeBtn = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.closeIcon}
        onClick={closeModal}
      />
    );
  }, [closeModal]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.permitModal}
      style={{ maxWidth: modalProps().width, width: "100%" }}
      backdropClassName={styles.backdropC}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        {modalType === "show" && (
          <Show
            {...props}
            status={status}
            setStatus={setStatus}
            closeModal={closeModal}
            setState={setState}
            state={state}
            openInnerModal={() => setState("innerModal", "activate_deactivate")}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const mapState = (state) => {
  return {
    record: state.user_charging_session.records.record,
  };
};
export default connect(mapState, null)(ChargingSessionModal);