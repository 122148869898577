import React from 'react'
import { filterFetcher } from 'api/parking_rules';
import styles from './index.module.sass';
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';

const TableData = (props) => {
  const { filters, isResourceFetching, setRecord, list, openModal, activeTab, modalType } = props

  const renderRecords = () => {
    return list?.map((record) => {
      return ( <TableRow 
        key={record.id} 
        record={record} 
        openModal={openModal} 
        setRecord={setRecord} 
        modalType={modalType}
      />);
    });
  }
  
  return (
    <IndexTable
      {...props}
      className={`${ activeTab === 'filters' ? styles.tableActive : styles.table}`}
      isFetching={() => isResourceFetching }
      filterFetcher={filterFetcher}
      paginationQuery={{ ...filters }}
      columns={
        <React.Fragment>
          <th attr="parking_rule.name" style={{ width: '45%', minWidth: '210px' }} >Rule's Name</th>
          <th attr="parking_rule.status" style={{width: '25%', minWidth: '210px' }} >Status</th>
          <th attr="parking_rule.agency_officer" style={{ width: '100%', minWidth: '210px' }} >Assigned Agency Officer</th>
          <th attr="parking_rule.actions" style={{ width: '100%', minWidth: '210px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="Parking Rules"
    />
  )
}

export default TableData
