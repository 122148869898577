import React, { useCallback, useReducer, useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Apply from './apply_permit';

/* Assets */
import styles from './index.module.sass'
/* API */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { applyForPermitApplication } from 'api/app/apply_permit';
import Confirmation from './apply_permit/confirmation'
import AddVehicle from './add_vehicle'
import SharePermit from './apply_permit/shared_permit'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'

const initState = { errors: {}, isSaving: false, parkingLots: []}


const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}


const AppModal = (props) => {
  const { openModal, modalType } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const [errors, setErrors] = useState({})
  const [permitName, setPermitName] = useState()
  const [commuterId, setCommuterId] = useState()
  const [userType, setUserType] = useState() 
  const [commuterVehicleAttributes, setCommuterVehicleAttributes] = useState({})
  const [vmanufacturers, setVManufacturers] = useState([])
  const [closeAllModal, setCloseAllModal] = useState(false)
  const [base64IdProof, setBase64IdProof] = useState()
  const { t } = useTranslation()

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

 const closeModal = useCallback(() => {
    if(state.innerModal === 'add_vehicle' || state.innerModal === 'share_permit'){
      setState('innerModal', null)
      if(closeAllModal){openModal();}
    }else {
      openModal();
      localStorage.removeItem('allCommutersData') 
    }
  }, [openModal, setState , state.innerModal, closeAllModal])


  const InnerModal = (innerModalType) => {
    setState('innerModal', innerModalType)

  }
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }


  const applyPermitApplication = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await applyForPermitApplication({ data: { permit_application: data , allow_save: 1} })
      if(response.status === 200 || response.status === 201 ){
        closeModal()
        openModal('confirmation')
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
      if(error.response?.data?.errors?.permitapplications_payment){
        toast.error(t("permit_application.apply_for_permit.payment_failed"))
      }
      
    } finally{
      setState('isSaving', false)
      setCloseAllModal(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, closeModal , setState, openModal])


  

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'apply_permit':
        return {title: t("permit_application.apply_for_permit.apply_for_a_permit"), maxWidth : '800px', maxHeight : '1000px'}
      case 'find_parking':
        return {maxWidth : '500px'}
      default:
        break;
    }
    return { width }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null); closeModal()}}
    />
  },[setState, closeModal]);


  return (
    <Modal 
      isOpen={!!modalType}  
      centered 
      contentClassName={styles.appModal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '98%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'apply_permit' && 
          <Apply
            state={state}
            setState={setState}
            closeModal={closeModal}
            applyPermitApplication={applyPermitApplication}
            setErrors={setErrors}
            errors={errors}
            openModal={openModal}
            setPermitName={setPermitName}
            isSaving={state.isSaving}
            openInnerModal={InnerModal}
            commuterId={commuterId}
            setCommuterId={setCommuterId}
            setFormApi={setFormApi}
            formApiRef={formApiRef}
            setUserType={setUserType}
            commuterVehicleAttributes={commuterVehicleAttributes}
            setVManufacturers={setVManufacturers}
            base64IdProof={base64IdProof}
            setBase64IdProof={setBase64IdProof}
          />
        }
        {
          modalType === 'confirmation' && 
          <Confirmation
            closeModal={closeModal}
            permitName={permitName}
            t={t}
          />
        }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            backdropClassName={styles.backdropC}
            style={state.innerModal==='share_permit' ? {maxWidth: '550px', width: '100%'} : {maxWidth: '560px', width: '100%'}}
          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody>
              {state.innerModal === 'add_vehicle' && 
                <AddVehicle
                  renewPermit={"add_vehicle"}
                  closeModals={() => {closeModal(); setState('innerModal', null)}}
                  isSaving={state.isSaving}
                  errors={errors}
                  setErrors={setErrors}
                  setState={setState}
                  commuterId={commuterId}
                  setFormApi={setFormApi}
                  formApiRef={formApiRef}
                  userType={userType}
                  setCommuterVehicleAttributes={setCommuterVehicleAttributes}
                  commuterVehicleAttributes={commuterVehicleAttributes}
                  manufacturers={vmanufacturers}                  
                  
                />
              }
              {state.innerModal === 'share_permit' && 
                <SharePermit
                  closeModals={() => {closeModal(); setState('innerModal', null)}}
                  isSaving={state.isSaving}
                  errors={errors}
                  setErrors={setErrors}
                  setState={setState}
                  setFormApi={setFormApi}
                  formApiRef={formApiRef}
                  applyPermitApplication={applyPermitApplication}
                  setCloseAllModal={setCloseAllModal}
                  base64IdProof={base64IdProof}
                  setBase64IdProof={setBase64IdProof}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default AppModal;
