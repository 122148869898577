import Loader from 'components/helpers/loader';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { each, isEmpty, map, values } from 'underscore';
/* Helpers */
import { ownerFields, permitFields, vehicleFields } from 'components/helpers/fields/permits/edit';
/* API */
import { show } from 'api/permits';
import { searchV1 as dropdownsSearchV1 } from 'api/dropdowns';
import { search as dropdownsSearch } from 'api/dropdowns';

/* Base */
import { renderFields, renderInput } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Modules */
import withFetching from 'components/modules/with_fetching';
/* Styles */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { capitalize, displayDate } from 'components/helpers';
import Tabs from 'components/base/tabs';
import { FieldType } from 'components/helpers/form_fields';

const  Edit = ({record, startFetching, closeModal, updatePermit, isSaving, permitTypes, setState})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [permit, setPermit] = useState({})
  const [vehicleLpns, setVehicleLpns] = useState([])
  const [selectedTab, setSelectedTab] = useState('primary')
  const [selectedCommuter, setSelectedCommuter] = useState()

  const formApiRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    mute: false,
  }
  
  const doubleFieldProps = {
    iSize: 7,
    lSize: 5,
    events: {
      onChange: (e) => {
        fetchSpecialParkingLots(e)
      }
    }
  }

  const fetchSpecialParkingLots = useCallback((e) => {
    const value = formApiRef.current.getValues()
    if(selectedTab === 'primary'){
      setPermit((prevState) => {
        return {
          ...prevState,
          owner: value?.owner,
          vehicle: value?.vehicle,
        };
      });
    }
  else{
    setPermit((prevState) => {
      const lastField = prevState?.applicants[selectedCommuter];
      const updatedData = {
        ...value?.owner,
        id: lastField?.id,
        vehicle: value?.vehicle,
      }
      const applicants = permit.applicants
      return {
        ...prevState,
        applicants: {...applicants, [`${selectedCommuter}`]: updatedData}
      };
    });
  }
  },[selectedCommuter, selectedTab, permit])
  
  const formatUserAndVehicleOfCommuter = (data) => {
    return {
        id: data?.id,
        full_name: data?.full_name, 
        home_address: data?.home_address,
        home_phone: data?.home_phone,
        work_address: data?.work_address,
        work_phone: data?.work_phone,
        email: data?.email,
        vehicle: {
          plate_number: data?.vehicle?.[0]?.plate_number?.toUpperCase(),
          make: capitalize(data?.vehicle?.[0]?.manufacturer),
          color: capitalize(data?.vehicle?.[0].color),
          model: capitalize(data?.vehicle?.[0]?.model),
          registration_state: capitalize(data?.vehicle?.[0]?.registration_state),
          year: data?.vehicle?.[0]?.manufacture_year
        },
    }
  }

  const permitData = (data) => {
    const { vehicle={}, owner_name='', home_address={}, work_address={}, permit_number, expiry_date, issue_date, permit_expired, permit_type_id, cost, status, refund_completed, payment_type, imported_permit, commuter_details } = data
    
    const applicants = {}
    each(commuter_details, (data, idx) => {
      applicants[`commuter-${idx+1}`] = formatUserAndVehicleOfCommuter(data, idx+1)
    })
    
    return ({
      vehicle: {
        ...vehicle,
        plate_number: imported_permit? vehicle?.plate_number?.toUpperCase() : vehicle?.id,
        make: imported_permit? vehicle?.make : vehicle?.manufacturer,
        color: vehicle.color,
        model: vehicle.model,
        registration_state: vehicle.registration_state,
        type: vehicle?.vehicle_type
      },
      owner: { 
        full_name: owner_name, 
        home_address: home_address.address1,
        home_phone: home_address.phone,
        work_address: work_address.address1,
        work_phone: work_address.phone,
      },
      permit: {
        number: permit_number,
        expires_on: expiry_date === 'Non-Expiring' ? 'Non-Expiring' : displayDate(expiry_date,'DD/MM/YYYY'),
        issued_on: issue_date,
        type: permit_type_id,
        expired: permit_expired ? 'YES' : 'NO',
        cost,
        payment_type,
        refund_completed,
        status
      },
      commuter_details: commuter_details,
      applicants,
    })
  }
  
  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['primary-color']} ${styles.dollarIcon}`} />
  
  const renderOwnerFields = () => {
    const  fields = ownerFields(fieldAttrs);
    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Owner Information</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
      </Col>
    )
  }
    

  const renderVehicleFields = useCallback(() => {
    const fields = vehicleFields({...fieldAttrs, vehicleLpns, imported: record?.imported_permit, selectedTab: selectedTab})

    return(
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Vehicle Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset}`}>
          {renderFields(fields.slice(6,7), {...doubleFieldProps})}
        </div>
      </Col>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicleLpns, record, selectedCommuter, selectedTab])

  const renderPermitFields = useCallback(() => {
    const fields = permitFields({...fieldAttrs, permitTypes})
    fields[5] = {
      ...fields[5], 
      icon: dollarIcon(), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }
    
    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center mt-3 mb-2 w-100">
          <span className={styles.detailsLabel}>Permit Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <p className={`${styles.detailsLabel} text-capitalize mb-4 p-0 w-100`}>
          {`${record.permit_category} Permit`}
        </p>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
      </Col>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permitTypes, record.permit_category])


  const submitValues = useCallback(() => {
    const data = { 
      permit_attributes: {
        permit_type_id: permit.permit.type,
        vehicle_id: permit.vehicle.plate_number
      },
      vehicle_attributes: {
        registration_state: permit.vehicle.registration_state,
        color: permit.vehicle.color,
        vehicle_type: permit?.vehicle?.type,
        plate_number: permit.vehicle.plate_number,
        model: permit.vehicle.model,
        manufacture_year: permit.vehicle.manufacture_year,
        make: permit.vehicle.make,
      },
      user_attributes: {
        first_name: permit.owner.full_name.split(' ')[0],
        last_name: permit.owner.full_name.split(' ').slice(1).join(' ')
      },
      home_address_attributes: {
        phone: permit.owner.home_phone,
        address1: permit.owner.home_address
      },
      work_address_attributes: {
        phone: permit.owner.work_phone,
        address1: permit.owner.work_address
      },
    }
    let commuter = []
    if(permit?.commuter_details?.length > 0){
      Object.keys(permit?.applicants).forEach(function(key) {
        const vehicleDetails = Object.keys(permit?.applicants?.[key]).filter(objKey =>
          objKey !== 'vehicle').reduce((newObj, key1) =>
          {
            newObj[key1] = permit?.applicants?.[key][key1];
            return newObj;
          }, {}
        );
        commuter.push({...vehicleDetails, vehicle_attributes: permit?.applicants?.[key].vehicle})
      });
      Object.assign(data, {commuters_attributes: commuter})
    }
    updatePermit(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updatePermit, permit])
  
  useEffect(() => {
    const fetchPermit = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: record.id})
        const newPermit = permitData(response.data);
        setPermit(newPermit)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(record.id) fetchPermit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record.id])

  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownsSearchV1('permit_type_list'))
        setState('permitTypes', permitTypesList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(permitTypes && !record.imported_permit)) {
      fetchPermitTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const fetchVehicleList = async () => {
      try {
        const vehiclesList = await startFetching(dropdownsSearch('vehicles_list', {id: record.id}))
        setVehicleLpns(vehiclesList.data)
      } catch (_error) {
      }
    }
    if(record.imported_permit === false && isEmpty(vehicleLpns)) fetchVehicleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record, vehicleLpns])

  const permitCommuterField = (users) => {
    return {
      name: 'permit_user',
      type: FieldType.SELECT_FIELD,
      customInputClass: `${styles.input1} mt-4 mb-2`,
      options: map(users, (user) => ({label: user?.full_name, value: user?.id}))
    }
  }

  const listCommuter = (userCount) => ([
    { label: <span style={{opacity: '0.65'}}>Applicant Details</span>,  value: 'primary'},
    { label: <React.Fragment><span className='mr-2' style={{opacity: '0.65'}}>Commuter Details</span><span className={styles.tabBadge}>{userCount}</span></React.Fragment>,  value: 'secondary'},
  ])

  const updateCommuterApplicantData = useCallback((e) => {
    const secondary = e?.target?.value ? e?.target?.value : e
    const applicantValues = Object.keys(permit.applicants).filter(u => `${permit.applicants[u]?.id}` === secondary)
    // eslint-disable-next-line
    Object.keys(permit.applicants).filter(objKey =>{
      if(`${permit.applicants[objKey]?.id}` === secondary) setSelectedCommuter(objKey)
    })
    const values = formApiRef.current.getValues()
    updateCommuterApplicationData(values, permit.applicants[applicantValues] )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permit])

  const updateCommuterApplicationData =(values, data ) =>{
    const updatedData = {
      vehicle: {
        plate_number: data?.vehicle?.[0]?.plate_number,
        make: data?.vehicle?.[0]?.manufacturer,
        color: data?.vehicle?.[0]?.color,
        model: data?.vehicle?.[0]?.model,
        registration_state: data?.vehicle?.[0]?.registration_state,
        manufacture_year: data?.vehicle?.[0]?.manufacture_year,
      },
      owner: { 
        full_name: data?.full_name, 
        home_address: data?.home_address,
        home_phone: data?.home_phone,
        work_address: data?.work_address,
        work_phone: data?.work_phone,
        email: data?.email
      },
    }
    formApiRef.current.setValues({...values, vehicle: updatedData.vehicle, owner: updatedData.owner})
  }

  const updateTab = (e) => {
    const values = formApiRef.current.getValues()
    setSelectedTab(e)
    if (e === 'primary') {
      formApiRef.current.setValues({...values, vehicle: permit.vehicle, owner: permit.owner})
    } else {
      setSelectedCommuter('commuter-1')
      if(permit?.commuter_details?.length > 0) updateCommuterApplicationData(values,permit.applicants?.['commuter-1'])
    }
  }
  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0 my-3'>
      <p className={`${styles.title} m-0 mb-3 p-0`}>Edit Permit</p>
      <Form initialValues={permit} className={styles.form} onSubmit={submitValues} getApi={setFormApi}>
        { record.permit_type === 'Carpool Permit' && <Col xs={11} className='m-auto py-3'>
          <Tabs
            list={listCommuter(permit?.commuter_details?.length)} 
            defaultTab={selectedTab} className={styles.tabs} 
            onClick={(val) => updateTab(val)} 
          />
          { selectedTab === 'secondary' && renderInput(
              permitCommuterField(values(permit.commuter_details)),
              {events: {onChange: updateCommuterApplicantData}}
            )
          }
        </Col> }
        { renderVehicleFields() }
        { renderOwnerFields() }
        { renderPermitFields() }
        <Col className="d-flex justify-content-center mb-3 mt-4" xs={12}>
          <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            Cancel
          </Button>
          <Button type='submit' className={`${styles.button}`}   isLoading={isSaving}>
            Save
          </Button>
        </Col>
      </Form>
    </Container>
  )
}

export default withFetching(Edit);
