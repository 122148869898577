import React from 'react'
import { Col, Container, Row } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';
import { ReactComponent as FailIcon } from 'assets/app/fail-icon.svg';

const Confirmation = ({closeModal, record, paymentStatus}) => {

  const successMessage = () => {
    return (
      <React.Fragment>
        <Col style={{marginTop:"-1rem"}}>
        <Row className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>You have successfully paid the fine for your</Row>
        <Row className={`${styles.content} d-flex justify-content-center`} style={{marginTop:"-1.5rem"}}>
          <span>
            Citation Ticket ID - 
          </span>
          <span className='ml-1' style={{color: '#3A9CED', fontWeight: 'bold'}}>
            {record.id}
          </span></Row>
        <Row className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>Your Citation is now "Settled"</Row>
        </Col>
      </React.Fragment>
      )
  }

  const failureMessage = () => {
    return (
      <React.Fragment>
        <br/>
        <Row className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>The payment could not be completed. If the amount is</Row>
        <Row className={`${styles.content} d-flex justify-content-center`} style={{marginTop:"-1.5rem", marginBottom:"1rem"}}>deducted from your card then it will be refunded back.</Row>
        <br/>
      </React.Fragment>
      )
  }

  return (
    
    <Container className='p-0' style={{marginTop: '-1rem'}}>
      <Col className="d-flex justify-content-center my-2 pb-2">
        {paymentStatus === 'success' && <SuccessIcon/>}
        {paymentStatus === 'failure' && <FailIcon/>}
      </Col>
      {paymentStatus === 'success' && <span className='mx-1 d-flex justify-content-center' style={{fontWeight:"16px"}}>Payment Successful</span>}
      {paymentStatus === 'failure' && <span className='mx-1 d-flex justify-content-center' style={{fontWeight:"16px"}}>Payment Failed</span>}
    <Container className='mb-4' >
      <Row className="d-flex justify-content-center my-2">
        { paymentStatus === 'success' && successMessage() }
        { paymentStatus === 'failure' && failureMessage() }
      </Row>

      <Col className="d-flex justify-content-center my-2">
        {
            <Button 
                type='button' 
                onClick={() => {closeModal()}} 
                className={styles.confirmBtn}
              >
                OK
            </Button>
        }  
      </Col>
    </Container>
  </Container>
  )
}

export default Confirmation;
