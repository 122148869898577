import { PastChargingSessionActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(PastChargingSessionActions);

const PastChargingSessionReducers = combineReducers({
  index,
  records,
  filters
});

export default PastChargingSessionReducers;