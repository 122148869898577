import React, {useEffect} from 'react'
/* Base */
import NavigationToolbar from 'components/base/subscriberNavigationBar';
/* Components */
import { Card, Col, Row } from 'reactstrap';
import styles from './index.module.sass';
import Tabs from 'components/base/tabs';
import { filterFetcher  } from 'api/webapp/citations';
import { Input, Space, ConfigProvider } from 'antd';

const ToolBar = (props) => {
  const {setList, formType, setFormType} = props
  // eslint-disable-next-line
  const { Search } = Input;
  const tabs = [
    {label: 'All', value: 'all'},
    {label: 'Settled', value: 'settled'},
    {label: 'Disputed', value: 'disputed'}
  ]

  useEffect(() => {
    const fetchData = () => {
      filterFetcher({ status: formType })
        .then(response => {
          if (response?.status === 200) {
            setList(response.data);
          }
        })
        .catch(error => console.error(error));
    }
    fetchData();
  }, [formType, setList]);

  return (
    <NavigationToolbar className='d-flex justify-content-space-between'>
      <Card className={styles.cardfilter}>
        <Row >
          <Col style={{width: '70%'}}>
            <Row>
              <Tabs list={tabs} defaultTab='all' onClick={setFormType} />
            </Row>
            <Row style={{marginTop: '1rem'}}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#3A9CED',
                  },
                }}
              >
              <Space direction="vertical" >
                <Search
                  placeholder="input search text"
                  allowClear
                  enterButton="Search"
                  size="large"
                />
              </Space>
              </ConfigProvider>
            </Row>
          </Col>
        </Row>
      </Card>
    </NavigationToolbar>
  )
}

export default ToolBar;
