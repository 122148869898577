const visitorDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, customInput1Class='' } = attrs;
  return [
    [
      {
        name: 'purpose_of_visit',
        label: 'Purpose Of Visit',
        customInputClass: customInput1Class,
        customLabelClass,
        disabled: mute,
      }
    ],
    [
      {
        name: 'visitor_address[full_name]',
        label: "Resident's Full Name",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[phone]',
        label: 'Contact Number',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[address1]',
        label: 'Address Line 1',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[address2]',
        label: 'Address Line 2',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[country_code]',
        label: 'Country',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[postal_code]',
        label: 'Zip Code',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[state_code]',
        label: 'State',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[city]',
        label: 'City',
        customInputClass,
        customLabelClass,
        disabled: mute,
      }
    ]
  ]
}

export default visitorDetails;
