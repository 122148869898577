import React, { useCallback, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
/* API */
import { filterFetcher, showSetting } from 'api/parking/citation_tickets';
import { showSetting as showPermitSetting } from 'api/permit_applications';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import connectList from 'components/modules/connect_list';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import { connect } from 'react-redux';
import resourceFetcher from 'components/modules/resource_fetcher';
import { bindActionCreators } from 'redux';
/* Actions */
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/parking_citation_tickets';
import { invoke } from 'actions';
/* Components */
import ToolBar from './toolbar';
import TicketModal from '../ticket_modal';
import TableData from './table_data';

const ParkingCitationTickets = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [createCitation, setCreateCitation] = useState(false)
  const [restrictApplication, setRestrictApplication] = useState(false)
  const [allowApplication, setAllowApplication] = useState(false)
  const [setting, setSetting] = useState('')

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  useEffect(() => {
    const showCitationSetting = async () => {
      try {
        const response = await showSetting()
        setCreateCitation(response?.data?.citation_ticket_creation)
      } catch (error) {
        console.log(error)
      }
    }
    showCitationSetting()
  },[])

  useEffect(() => {
    const showPermitSettings = async () => {
      try {
        const response = await showPermitSetting()
        setRestrictApplication(response?.data?.restrict_application_with_open_citation)
        setAllowApplication(response?.data?.allow_to_permit_application_and_mark_under_review)
      } catch (error) {
        console.log(error)
      }
    }
    showPermitSettings()
  },[])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.AGENCY_MANAGER, ROLES.AGENCY_OFFICER, ROLES.PARKING_ADMIN, ROLES.TOWN_MANAGER]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              openModal={openModal} 
              activeTab={activeTab} 
              setActiveTab={setActiveTab}
              total={props.total}
              modalType={modalType}
              createCitation={createCitation}
              setCreateCitation={setCreateCitation}
              setSetting={setSetting}
              allowApplication={allowApplication}
              restrictApplication={restrictApplication}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} modalType={modalType} activeTab={activeTab} />
          </Col>
        </Row>
        <TicketModal
          {...props}
          openModal={openModal}
          modalType={modalType}
          setActiveTab={setActiveTab}
          createCitation={createCitation}
          setCreateCitation={setCreateCitation}
          restrictApplication={restrictApplication}
          allowApplication={allowApplication}
          setRestrictApplication={setRestrictApplication}
          setAllowApplication={setAllowApplication}
          setting={setting}
          setSetting={setSetting}
        />
      </Container>
    </AccessControl>
  )
}

const resource = 'parking_citation_ticket';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD)
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.parking_citation_ticket.filters,
    selectedRecord: state.parking_citation_ticket.records.record

  }
}


export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(ParkingCitationTickets))),
);
