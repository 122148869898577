import React from "react";
/* Styles */
import styles from "./index.module.sass";
import { Row } from "reactstrap";
/* Components */
import FilterForm from "../filterForm";

const CollapseContent = ({
  activeTab,
  filters,
  startFetching,
  setList,
  list,
  setFilters,
  match,
  setIsResourceFetching,
  permitTypes,
  plateNos,
  t
}) => {
  return (
    <Row
      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0 pr-1`}
      sm="12"
    >
      {activeTab === "filters" && (
        <FilterForm
          setIsFetching={setIsResourceFetching}
          setList={setList}
          match={match}
          filters={filters}
          setFilters={setFilters}
          list={list}
          startFetching={startFetching}
          permitTypes={permitTypes}
          plateNos={plateNos}
          t={t}
        />
      )}
    </Row>
  );
};

export default CollapseContent;