import React from 'react'
import { Col, Container } from 'reactstrap'
import env from '.env'
/* Assets */
import styles from '../index.module.sass'
import Button from 'components/base/button'

const GeneratePdf = ({ closeModal, recordId}) => {

  const generateTicketPdf = () => {
    const url = `${env.backend_url}/dashboard/parking/citation_tickets/${recordId}/citation_ticket_pdf?token=${localStorage.TOKEN}`
    window.open(url, '_blank');
  }

  return (
    <Container>
      <Col className={`${styles.modalTitle} my-4 mb text-center`}>Generate Printable Ticket</Col>
      <Col className={styles.modalContent} xs={11}>
        <span className='d-block'>
          {"The vehicle’s LPN is not registered on the system. You may generate a citation ticket in PDF, print it, and hand it over to local authorities for follow-up."}
        </span>
      </Col>
      <Col className='d-flex justify-content-center align-items-center mt-4'>
        <Button 
          className={`${styles.btn} ${styles['btn-light']} mr-3`}
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button 
          className={`${styles.btn} ${styles['btn-primary']}`}
          onClick={() => { generateTicketPdf(); closeModal()}}
        >
          Generate
        </Button>
      </Col>
    </Container>
  )
}

export default GeneratePdf;
