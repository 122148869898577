import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Delete = (props) => {

  const { deleteRole, state, closeModal, selectedRecord } = props;

  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='mt-3'>
          <span className={`${styles.boldText} mr-1`}>{selectedRecord?.name}</span>
          will deleted immediately.
        </Col>
        <Col className='mb-4'>
          This operation cannot be undone?
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={deleteRole} className={styles.submitButton} disabled={state.isDeleting}>
          Delete
        </Button>
      </Col>
    </Col>
  )
}

export default Delete;