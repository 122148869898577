import React, { useCallback, useContext, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Edit from '../edit';
import Create from '../create';
import ActivateDeactivate from '../activateDeactivate';
import { map } from 'underscore';
/* API */
import { create, update } from 'api/special_permit_types';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
/* Styles/Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, parkingLots: []}
const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'parkingLots':
      return { ...state, parkingLots: payload }
    default:
      return { ...state };
  }
}

const PermitModal = (props) => {
  const { modalType, openModal, record, setListElement, addListElement, setActiveTab } = props

  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const closeModal = () => {
    openModal();
    setActiveTab();
  }

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])
  

  const setParkingLots = useCallback((lots) => {
    dispatch({type: 'parkingLots', payload: lots})
  },[dispatch])

  const updatePermitType = async (data) => {
    setState('isSaving', true)
    try {
      const response  = await update({data: {permit_type: data}, id: record.id })
      setListElement(response.data)
      addAlertMessages([{
        type: 'primary',
        text: `The ${data.name} permit type has been saved`,
        onlyMessage: true,
      }], 'center')
    } catch (error) {
      setState('errors', error.response.data.errors)
    }finally {
      setState('isSaving', false)
    }
  }

  const setList = (list, data, perPage, page) => {
    const listLength = list.length;
    switch (true) {
      case listLength < perPage:
        return [data].concat(list);
      case listLength === perPage:
        return [data].concat(list).slice(0, perPage);
      default:
        return list;
    }
  }

  const addPermitType = async (data) => {
    try {
      setState('isSaving', true)
      const response = await create({ data: {permit_type: data} })
      addListElement({setList, data: response.data})
      addAlertMessages([{
        type: 'primary',
        text: `The ${data.name} permit type has been created`,
        onlyMessage: true,
      }], 'center')
    } catch (error) {
      setState('errors', error.response?.data?.errors)
    }finally{
      setState('isSaving', false)
    }
  }

  const toggleStatus = useCallback(async (attrs) => {
    const data = Object.assign(record, attrs)
    delete data.allow_cancellation
    data.parking_lots_array = map(record.permit_parking_lots, ppl => ({id: ppl.parking_lot.id, reserved_spaces: ppl.reserved_spaces}))

    try {
      const response  = await update({data: {permit_type: data}, id: record.id })
      const message = `The ${response.data.name} special permit type is successfully ${response.data.status === 'active' ? 'activated' : 'deactivated'}`
      addAlertMessages([{
        type: response.data.status === 'active' ? 'primary' : 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement])

  const closeBtn = () => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  };

  const modalProps = () => {
    const width = '480px'
    switch (modalType) {
      case 'edit':
        return {width: 710}
      case 'create':
        return {width: 710}
      default:
        return { width};
    }
  }
  

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.applicationModal} 
      style={{maxWidth: modalProps().width, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        {
          modalType === 'activate_deactivate' && 
          <ActivateDeactivate
            permitName={record.name}
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={`${record.status === 'active' ? 'deactivate' : 'activate'}`}
          />
        }

        {
          modalType === 'edit' && 
          <Edit
            record={record} 
            closeModal={closeModal} 
            updateRecord={updatePermitType}
            setState={setState}
            {...state}
          />
        }

        {
          modalType === 'create' && 
          <Create
            record={{}} 
            closeModal={closeModal} 
            setParkingLots={setParkingLots}
            addRecord={addPermitType}
            setState={setState}
            {...state}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal;
