import { FieldType } from "components/helpers/form_fields";
import searchFields from "./search";
import showFields from "./showFields";
import createFields from "./createFields"

const filterFields = () => ([
  {name: 'status', label: 'Status'},
])

const dateTimeDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=false, date } = attrs;
  return [
    
    {
      name: `event_start_time${date? `_${date}`:''}`,
      label: "From",
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: `event_end_time${date? `_${date}`:''}`,
      label: "To",
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    }
  ]
}

const exampleData = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? {
  country: "USA",
  city: "Easton",
  state: "MD",
  zip:  21601,
  street: "E Earle Ave",
  ltd: '38.768148',
  lng: '-76.075782'
} : {};




export { searchFields, filterFields,showFields,createFields,dateTimeDetails, exampleData };