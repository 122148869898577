import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, isAuthorized, isSubAuthorized, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (props.match.path.indexOf("dashboard") > -1) ? (
        isAuthorized ? (<Component {...props} />) : (
          <Redirect
          to={{
            pathname: '/dashboard/login',
          }}
        />
        )
      ) : (
        isSubAuthorized ? (<Component {...props} />) : (
          <Redirect
          to={{
            pathname: '/homepage',
          }}
        />
        )
      )
      
    }
  />
);

function mapState (state) {
  const { isAuthorized } = state.user.auth;
  const { isSubAuthorized } = state.user.subscriberAuth;
  return { isAuthorized, isSubAuthorized };
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isSubAuthorized: PropTypes.bool.isRequired,
  location: PropTypes.object
};

export default connect(
  mapState
)(PrivateRoute);
