import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'

const RejectApplication = ({rejectApplication, closeModal,isSaving, record}) => {

  const message = () => <>
    <span className='mx-1'>Are you sure?</span>
    <br/>
    <br/>
    <span className={styles.content}>The <span className={styles.boldText}>{record.event_name} </span>Event Application will be rejected</span>
  </>

  return (
    <Container className='p-0'>
    <Container className='mb-4' >
      <Col className=''>
        { message() }
      </Col>
      <br/>

      <Col className="d-flex justify-content-center my-2">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} ${styles['button-bg-light']} mr-4`}>
          Cancel
        </Button>
        {
            <Button 
                type='button' 
                onClick={() => {rejectApplication({status: 'rejected'}); closeModal()}} 
                className={`${styles.submitButton} ${styles['button-bg-primary']}`}
                disabled={isSaving}
              >
                Reject
            </Button>
        }
      </Col>
    </Container>
  </Container>
  )
}

export default RejectApplication;
