import React, { useMemo} from 'react'
import styles from './index.module.sass';
import { capitalize, displayDate } from 'components/helpers';
import { ReactComponent as ScissorIcon } from 'assets/scissor-icon.svg';
import SlideButton from 'components/base/slide_button';

const TableRow = ({record}) => {
  
const { status } = record;  
  const statusColor = useMemo(() => {
    if(!status) return;
    const colorMapping = {
      active: 'success',
      expired: 'danger',
    }
    return colorMapping[status?.toLowerCase()] || 'default'
  },[status])
  
  return (
    <tr>
      <td>
        <div className='d-flex no-wrap align-items-center'>
          <span>{record?.coupon_code}</span>
          <span className='mt-1'>
            {
              record.coupon_type==='used' &&  (
                <SlideButton icon={<ScissorIcon />} className={` ${styles.badge} ml-2`} status='primary'>
                  <span className='mr-3 d-inline-block' style={{width: '20px'}}>Used</span>
                </SlideButton>)
            }
          </span>
        </div>
      </td>
      <td>{ record?.generated_on } </td>
      <td>{ capitalize(record?.reason) }</td>
      <td>${ record?.amount }</td>
      <td>{record?.coupon_type !== 'unused' ?
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`}> </span>
          <span >{status?.toUpperCase() }</span>
        </div> :"N/A"}
      </td>    
      <td>{ record?.expiry_date ? displayDate(record?.expiry_date, 'ddd, MMM Do YYYY') : "N/A" }</td>
    </tr>
  );
};

export default TableRow