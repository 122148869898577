import React, { useState } from 'react';
import { asField } from 'informed';
import CustomDropdown from 'components/base/dropdown';
import styles from './multi_select.module.sass';
import { filter, isEmpty, map } from 'underscore';

const CustomMultiSelect = asField(({ fieldApi, fieldState, options, disabled, events = {}, entityName, showToolTip, customInputClass, showCount=true, placeholder }) => {
  const { value } = fieldState;
  const { setValue } = fieldApi;
  const selectValues = value ? options.filter(option => value.includes(option.value)) : [];
  const [selectedAll, setSelectedAll] = useState(false)

  const coveringText = (value) => {

    if(isEmpty(value) && placeholder) return placeholder
    if(showCount) return `Selected (${value ? value.length : '0'}) ${entityName || ''}`;
    
    return map(filter(value, val => val.value !== 0), item => (item.label)).join(', ')
  };
  
  const handleChange = (selectedOptions, selectAllChecked) => {
    let newOptions
    if(selectAllChecked && !selectedAll) {
      setSelectedAll(true)
      newOptions = options
    }else if(selectAllChecked && selectedAll) {
      setSelectedAll(false)
      newOptions = []
    }else {
      setSelectedAll(false)
      newOptions = selectedOptions.filter(op => op.value !== 0)
    }

    setValue(newOptions ? newOptions.map(element => element.value) : []);
  }

  return (
    <CustomDropdown
      multiple
      value={selectValues}
      onChange={(selectedOptions, selectAllChecked) => {
        handleChange(selectedOptions, selectAllChecked)
        events.onChange && events.onChange(selectedOptions, selectAllChecked);
      }}
      options={options}
      disabled={disabled}
      coveringText={coveringText}
      className={styles.dropdown}
      showToolTip={showToolTip}
      customInputClass={customInputClass}
    />
  );
});

export default CustomMultiSelect;
