import { FieldType } from 'components/helpers/form_fields'

const fields = (agencies = [], admins = []) => ({
  recipient: { name: 'recipient', label: `Violation Notification Recipient (${admins.length})`, type: FieldType.MULTISELECT_FIELD, options: admins.map(admin => { return { value: admin.id, label: admin.email }})  },
  description: { name: 'description', label: 'Description' },
  agency_id: { name: 'agency_id', label: 'Assigned Agency', type: 'select', options: agencies.map(agency => { return {value: agency.id, label: agency.email}}) },
  status: {
    name: 'status',
    innerLabel: 'Status',
    mandatory: true,
    type: FieldType.TOGGLER_FIELD,
    options: {
      on: {
        value: 'active',
        labelButton: 'Activate'
      },
      off: {
        value: 'inactive',
        labelButton: 'Deactivate'
      }
    },
    defaultValue: 'inactive'
  }
});

const fieldsNew = (attrs) => {
  const { agencyOfficer=[], customLabelClass='', customInputClass=''} = attrs
  return ([
    [
      {
        name: 'admin_id',
        label: 'Officer Name',
        placeholder: 'Please select',
        customInputClass,
        customLabelClass,
        type: FieldType.DROPDOWN_SELECT_FIELD,
        mandatory: true,
        options: agencyOfficer,
        tooltip: 'List all assignable officers from parking lots assigned agency',
      },
    ]
  ]);
} 

const adminFields = (attrs) => {
  const { customInputClass='', customLabelClass='', agencies=[]} = attrs;
  
  return [
    {
      name: "admin_id",
      customInputClass,
      customLabelClass,
      type: FieldType.SELECT_FIELD,
      options: agencies
    },
  ]
}


export { fields, fieldsNew, adminFields };