import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
/* Helpers */
import { displayDate } from 'components/helpers';
/* Base */
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
// import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';


const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, className='' } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${className}`}
      role='button'
    >
      { showText 
        ? <span 
            className={`${showText ? 'd-inline-flex justify-content-center align-items-center' : 'd-none'} ${highlightedClass}`}
            style={{padding: '5px 10px', borderRadius: '15px' }}
          >
            {text}
          </span>
        : <span className={`${showText ? 'd-none' : 'd-inline-flex justify-content-center align-items-center'}`}>{icon}</span>
      }
    </div>
  )
}

const TableRow = ({openModal, record, setRecord, modalType}) => {

  const selectedRecordId = useSelector(state => state.permit.records.record?.id)
  
  const actions = [
    {
      className: 'border-right',
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      text: 'View',
      isActive: modalType === 'show' && selectedRecordId === record.id,
    },
    { 
      className: 'border-left',
      icon: <EditIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecordId === record.id
    }
  ]

  return (
    <tr>
      <td className=''>{record.permit_number}</td>
      <td className=''>{record.commuters}</td>
      <td className=''>{displayDate(record.issue_date, 'MMMM Do YYYY')}</td>
      <td className='text-uppercase'>{record.vehicle?.plate_number}</td>
      <td className='text-capitalize'>{record.vehicle?.owner_name}</td>
      <td className='text-capitalize'>{record.permit_expired ? 'Yes' : 'No'}</td>
      <td className=''>{displayDate(record.expiry_date, 'MMMM Do YYYY')}</td>
      <td onClick={() => setRecord(record)}>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          <Action {...actions[0]} />
          {
            record.status === 'revoked' 
              ? <span className={`${styles.revokedText} mx-3 d-flex align-items-center justify-content-center`}>Revoked</span> 
              : <span>
                  <Button
                    className={`${record.status === 'cancelled' ?  styles['background-disabled'] : styles['background-danger']} ${styles.revokeButton} mx-3`} 
                    onClick={() => openModal('revoke', record.status)}
                  >
                    Revoke
                  </Button>
                </span>
          }
          <Action {...actions[1]} />
        </div>
      </td>
    </tr>

  )
}

export default TableRow;
