const emailFields = ({customInputClass, customLabelClass}) => [
  {
    name: 'name',
    label: 'Name',
    mandatory: false,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'email',
    label: 'Email Address',
    mandatory: true,
    customInputClass,
    customLabelClass,
  },
];

export { emailFields};
