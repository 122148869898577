import React, { useCallback, useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty } from 'underscore';
/* Base */
import { renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Modules */
import withFetching from 'components/modules/with_fetching';
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/coupons/fieldsNew'
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const initialValues = { 
    amount: 0,
    validity_period: 0
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />


const Create = (props) => {
  const  { generateCouponCode, closeModal, errors, setErrors, isSaving, couponUniquenessVal, setCouponUniquenessVal } = props;
  const formApiRef = useRef();

  const attrs = {
    customLabelClass: `${styles.formLabel}`,
    customInputClass: `${styles.formInput}`,
  }
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  
  const validateValues = (formState) => {
    let errors = {}
    const { coupon_code, amount, validity_period, reason } = formState;
    if(!coupon_code)  {errors['coupon_code'] = ['Coupon Code can\'t be blank']}
    if(!amount)  {errors['amount'] = ['Coupon Amount can\'t be blank']}
    if (!(/^\d+$/.test(amount))) { errors['amount'] = ['Amount is not a valid type']}
    if(!validity_period)  {errors['validity_period'] = ['Validity period can\'t be blank']}
    if(!reason)  {errors['reason'] = ['Reason can\'t be blank']}
    return errors
  }

  useEffect(() => {
    if(couponUniquenessVal){
      setErrors({coupon_code: ['Coupon Code Already Exists !']});
    }
  },[couponUniquenessVal, setErrors])



  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events:{
      onChange:(_e) => setErrors({})
    }
  }

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(inputErrors)
      return
    };
    values.beneficiary_type = 'third_party'
    generateCouponCode(values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, generateCouponCode, couponUniquenessVal]);

  const getFields = () => {
    return fieldsNew({mute: false, ...attrs, customCouponCodeInputClass: couponUniquenessVal? `${styles.invalidCodeFormInput}` : `${styles.formInput}` , icon: dollarIcon(), customIconInput: attrs.customInputClass.concat(' ', styles.iconInput) })
  }
  useEffect(() => {
    setErrors({})
    setCouponUniquenessVal(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >
          {({ formState }) => {
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100 pl-1'>                  
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[5], { ...doubleFieldProps, errors} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[2], { ...doubleFieldProps,errors,formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[3], { ...doubleFieldProps, errors, formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                  { renderFields(newFields[4], { ...doubleFieldProps, errors} )}
                </Col>
                <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={`${styles.button}`}  isLoading={isSaving}>
                    Add
                  </Button>
                </Col>
              </Row>
            )
          }}
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);