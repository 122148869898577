import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";

import { isEmpty } from "underscore";

const token = localStorage.getItem('SUB_TOKEN');

const getParkingLots = (data) => {
  const {ltd, lng, vehicle_type} = data 
  let url = new URL(env.backend_url);
  if(!isEmpty(data)){
  url = `${url.protocol}//${url.host}/api/v1/parking_lots?location_radius[distance]=10&location_radius[ltd]=${ltd}&location_radius[lng]=${lng}&vehicle_type=${vehicle_type}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )}
}

const getParkingLot = (data) => {
  const {id} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/parking_lots/${id}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}


export { getParkingLots, getParkingLot};