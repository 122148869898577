import React, { useState } from 'react'
import { Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import EventCategories from './event_categories';
import Manufacturers from './manufacturers';
import Disabilities from './disabilities';
import ResidentialTypes from './residential_types'
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';

const Index = (props) => {
  const { currentUser } = props;
  const [listType, setListType] = useState('manufacturers');

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <ToolBar
          listType={listType}
          setListType={setListType}
          {...props}
        />
        <Row className='m-auto p-1'>
          {listType === 'event_categories' &&
            <EventCategories
              {...props}
            />
          }
          {listType === 'manufacturers' &&
            <Manufacturers
              {...props}
            />
          }
          {listType === 'disabilities' &&
            <Disabilities
              {...props}
            />
          }
          {listType === 'residential_types' &&
            <ResidentialTypes
              {...props}
            />
          }
        </Row>
      </Container>
    </AccessControl>
  )
}

export default withCurrentUser(Index)