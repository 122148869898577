import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './index.module.sass';

const PhoneNumberField = ({
  value,
  onChange,
  initialCountry,
  placeholder = '',
  disabled = false,
  customInputClass = '',

}) => {

  return (
    <PhoneInput
      inputClass={`${customInputClass || ''} ${styles.PhoneInput}`}
      dropdownClass={styles.dropdown}
      buttonClass={styles.buttonC}
      showDropdown={false}
      searchClass ={styles.search}
      searchStyle={{fontSize:"13px", height: "30px", width:'90%', marginLeft:'auto',marginRight:'auto'}}
      disableSearchIcon
      searchPlaceholder='Search for Countries'
      searchNotFound='Not Found'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      country={initialCountry} // Set the initial country value
      enableSearch={true}
      autocompleteSearch={true}
    />
  );

};
PhoneNumberField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialCountry: PropTypes.string, // New prop for initial country value
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default PhoneNumberField;