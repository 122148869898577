import React, { useEffect, useState } from 'react'
import styles from './index.module.sass'

const Tab = ({item, isActive, onClick, setActiveTab, classNameInnerTab}) => {
  const handleClick = () => {
    setActiveTab(item.value)
    onClick && onClick(item.value)
  }
  return (
    <li 
      className={`${isActive ? styles.activeTab : ''} ${styles.tab} border-right ${classNameInnerTab}`}
      onClick={handleClick}
    >
      {item.label}
    </li>
  ) 
}

const Tabs = ({className, list=[], defaultTab, onClick, classNameInnerTab}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab)
  }, [defaultTab])
  
  return (
    <ul className={`d-flex ${styles.tabs} ${className} m-0 p-0 d-flex pointer`}>
      {list.map(item => {
        return (
          <Tab 
            key={item.value} 
            item={item} 
            isActive={item.value === activeTab} 
            setActiveTab={setActiveTab}
            onClick={onClick}
            classNameInnerTab={classNameInnerTab}
          />
        )
      })}
    </ul>
  )
}

export default Tabs;
