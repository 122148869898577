import React, { useState } from "react";
import { Drawer, Button, Menu } from "antd";
import { HomeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "../homepage.module.sass";
import "./menu.css";
import { ReactComponent as ReportIcon } from "assets/menu_icons/reports_icon.svg";
import { ReactComponent as ParkingLotIcon } from "assets/menu_icons/parking_lot_icon.svg";
import { ReactComponent as VehiclesIcon } from "assets/menu_icons/vehicles_management_icon.svg";
import { ReactComponent as PaymentsIcon } from "assets/menu_icons/payments_transaction_icon.svg";
import { ReactComponent as ClipboardIcon } from "assets/menu_icons/clipboard.svg";
import { ReactComponent as ClipboardBarsIcon } from "assets/menu_icons/clipboard-bars.svg";
import { ReactComponent as CalendarIcon } from "assets/menu_icons/calendar_icon.svg";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { useTranslation } from "react-i18next";

const { Item } = Menu;
const SubscriberSideNavigation = (props) => {
  const [visible, setVisible] = useState(false);
  const { currentSubscriber } = props;
  const { t } = useTranslation();

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<RightOutlined />}
        onClick={showDrawer}
        className={styles.menuButton}
      />
      <Drawer
        placement="left"
        onClose={closeDrawer}
        visible={visible}
        className={styles.drawer}
        closeIcon={
          <Button className={localStorage.getItem("selectedSubscriberLanguage") === "spanish"? styles.closeBtn2 :styles.closeBtn} icon={<LeftOutlined />} />
        }
        width={localStorage.getItem("selectedSubscriberLanguage") === "spanish"? 290 : 230}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="vertical" className={styles.menu}>
          <Item key="1" className={styles.menuItem}>
            <Link to="/subscriber" className={styles.anchor}>
              <HomeOutlined className="float-left ml-1" />
              <span className={styles.homeTxt}>{t("subscriber.side_navigation_items.home")}</span>
            </Link>
          </Item>
          <Item key="2" className={styles.menuItem}>
            <Link to="/subscriber/my_profile" className={styles.anchor}>
              <img
                src={
                  currentSubscriber.avatar ||
                  "https://i.stack.imgur.com/34AD2.jpg"
                }
                alt="profile"
                className="rounded-circle mr-2"
                width="22"
                height="22"
              />
              <span className={styles.linkTxt}>{t("subscriber.side_navigation_items.my_profile")}</span>
            </Link>
          </Item>
          <Item key="3" className={styles.menuItem}>
            <Link to="/subscriber/permits" className={styles.anchor}>
              <ReportIcon className="float-left mr-2" />
              <span className={styles.linkTxt}>{t("subscriber.side_navigation_items.my_permits")}</span>
            </Link>
          </Item>
          <Item key="4" className={styles.menuItem}>
            <Link to="/subscriber/my_parkings" className={styles.anchor}>
              <ParkingLotIcon className="float-left mr-2" />
              <span className={styles.linkTxt}>{t("subscriber.side_navigation_items.my_parkings")}</span>
            </Link>
          </Item>
          <Item key="5" className={styles.menuItem}>
            <Link to="/subscriber/vehicles" className={styles.anchor}>
              <VehiclesIcon
                className={`${styles.vehicleIcon} float-left mr-1`}
              />
              <span className={styles.vehicleMenuTxt}>{t("subscriber.side_navigation_items.my_vehicles")}</span>
            </Link>
          </Item>
          <Item key="6" className={styles.menuItem}>
            <Link to="/subscriber/citations" className={styles.anchor}>
              <span
                className={`${styles.citationIcons} position-relative d-inline-block`}
              >
                <ClipboardIcon
                  className={`${styles.clipBoardIcon} float-left`}
                />
                <ClipboardBarsIcon
                  className={`${styles.clipBoardBarIcon} position-absolute`}
                  style={{ left: 5, top: 6 }}
                />
              </span>
              <span className={styles.citationTxt}>{t("subscriber.side_navigation_items.citations")}</span>
            </Link>
          </Item>
          <Item key="7" className={styles.menuItem}>
            <Link
              to="/subscriber/transaction_history"
              className={styles.anchor}
            >
              <PaymentsIcon className="float-left mr-2 pl-0 pr-1" />
              <span className={styles.transactionTxt}>{t("subscriber.side_navigation_items.transaction_history")}</span>
            </Link>
          </Item>
          <Item key="8" className={styles.menuItem}>
            <Link to="/subscriber/event_parking" className={styles.anchor}>
              <CalendarIcon className="float-left mr-2 pl-0 pr-1" />
              <span className={styles.linkTxt}>{t("subscriber.side_navigation_items.event_parking")}</span>
            </Link>
          </Item>
        </Menu>
      </Drawer>
    </React.Fragment>
  );
};

export default withCurrentSubscriber(SubscriberSideNavigation);
