import { ReservedParkingLotActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(ReservedParkingLotActions);

const ReservedParkingLotReducers = combineReducers({
  index,
  records,
});

export default ReservedParkingLotReducers;
