import { FieldType } from "components/helpers/form_fields";

const filterFields = (customLabelClass = "", customInputClass = "",customDropdownClass, permitTypes, customIconClass, t) => [  
  {
    name: "submitted_date[from]",
    label: t("subscriber.my_permit_applications.submitted_from"),
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("subscriber.my_permits.filterForm.date_placeholder"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "submitted_date[to]",
    label: t("subscriber.my_permit_applications.submitted_to"),
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("subscriber.my_permits.filterForm.date_placeholder"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "status",
    label: t("subscriber.my_permit_applications.application_status"),
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: [
      { value: "pending", label: "Pending" },
      { value: "approved", label: "Approved" },
      { value: "rejected", label: "Rejected" },
      { value: "under_review", label: "Under Review" },
      { value: "cancelled", label: "Cancelled" },
      { value: "incomplete", label: "Incomplete" },
      { value: "in_waitlist", label: "In Waitlist" },
    ],
    placeholder: t("subscriber.my_permits.filterForm.permit_status_placeholder"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
  {
    name: "application_type",
    label: `${t("subscriber.my_permit_applications.application_type")}:`,
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: [
      { value: "new_application", label: "New Application" },
      { value: "permit_renewal", label: "Permit Renewal" },
      { value: "permit_extension", label: "Permit Extension" },
      { value: "permit_exchange", label: "Permit Exchange" },
      { value: "transfer_request", label: "Transfer Request" },
    ],
    placeholder: t("subscriber.my_permits.filterForm.permit_type_placeholder"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
  {
    name: "permit_type_name",
    label:`${t("permit_application.apply_for_permit.labels.step3.permit_type")}:`,
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: permitTypes,
    placeholder: t("subscriber.my_permits.filterForm.permit_type_placeholder"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
  
];

export default filterFields;
