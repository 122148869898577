import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import withCurrentUser from "components/modules/with_current_user";
import TableData from "./tableData";
import ToolBar from "./toolbar";

const AuditLogsModules = (props) => {
  const [list, setList] = useState([
    {id:"Permit", name: "Permit Management"},
    {id:"Vehicle", name: "Vehicle Management"},
    {id:"Event", name: "Event Parking"},
    {id:"ParkingLot", name: "Parking Lots"},
    {id:"Violation", name: "Violations"},
    {id:"CitationTicket", name: "Citation Tickets"},
    {id:"Disputes", name: "Disputes"},
    {id:"mtas", name: "MTA Integration"},
    {id:"Coupon", name: "Coupons"},
    {id:"Rewards", name: "Rewards"},
    {id:"Arrival Time", name: "Arrival Time"},
    {id:"booting_and_towing", name: "Booting & Towing"},
    {id:"parking_scout", name: "Parking Scout"},
    {id:"Waitlists", name: "Waitlists"},
    {id:"Payment", name: "Payments"},
    {id:"Admin", name: "User Management"},
    {id:"User", name: "Subscriber Management"},
    {id:"Valet Parking", name: "Valet Parking"},
    {id:"Duplicate Records", name: "Duplicate Records"},
  ]);

  const [selectedModule, setSelectedModule] = useState('')

  return (
    <Container className="mw-100 p-0">
      <Row className="m-auto">
        <Col xs={12} className="p-0">
          <ToolBar setSelectedModule={setSelectedModule} selectedModule={selectedModule} {...props} resource={resource} total={list.length} setList={setList}/>
        </Col>
        <Col xs={12} className="p-0">
          <TableData setSelectedModule={setSelectedModule} selectedModule={selectedModule} {...props} list={list} setList={setList}/>
        </Col>
      </Row>
    </Container>
  );
};

const resource = "audit_logs_modules";

export default withCurrentUser(AuditLogsModules);