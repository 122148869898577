import { FieldType } from "components/helpers/form_fields";

const fields = (attrs) => {
  const {mute=false, customLabelClass = "", customInputClass = "" } = attrs;
  return [
    [
      {
        name: "name",
        label: "Name",
        mandatory: true,
        disabled: mute,
        customInputClass,
        customLabelClass,
      },
    ],
    [
      {
        name: "description",
        label: "Description",
        type: FieldType.TEXT_AREA,
        rows: 3,
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute,
      },
    ],
  ];
};

export { fields };