import React, { useCallback, useMemo } from "react";
/* Styles */
import styles from "./index.module.sass";
/* Assets */
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
/* Components */
import Action from "./action";

const TableRow = (props) => {
  const { record, setRecord, selectedRecordId, openModal, modalType } = props;

  const highlightAction = useCallback(
    (actionType) => {
      return actionType === modalType && record.id === selectedRecordId;
    },
    [modalType, selectedRecordId, record.id]
  );

  const actions = useMemo(() => {
    return [
      {
        icon: <EyeIcon />,
        className: `${styles["icon-eye"]}`,
        onClick: () => !record.shared && openModal("history"),
        activeClass: styles["icon-eye-active"],
        status: modalType === "history" && highlightAction("history"),
        allowActive: !record.shared,
      },
    ];
  }, [openModal, modalType, highlightAction, record.shared]);

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.name}</td>
      <td>{record?.created_at_parsed}</td>
      <td>{record?.points_earned}</td>
      <td>{record?.points_redeemed}</td>
      <td>{record?.points_redeemed_in_dollar}</td>
      <td>{record?.last_redemption_date}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {actions.map((action, idx) => (
            <Action key={idx} {...action} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;