const fieldsName = [
  'zip',
  'street',
  'state',
  'country',
  'building',
  'city',
  'ltd',
  'lng'
];

const fields = (t) => {
  return [
    { name: 'location.country', label: t("permit_application.apply_for_permit.labels.step2.country.label"), mandatory: true },
    { name: 'location.state', label: t("permit_application.apply_for_permit.labels.step2.state.label"), mandatory: true },
    { name: 'location.city', label: t("permit_application.apply_for_permit.labels.step2.city.label"), mandatory: true },
    { name: 'location.zip', label: t("permit_application.apply_for_permit.labels.step2.zip_code.label"), mandatory: true },
    { name: 'location.street', label: 'Street', mandatory: true },
    { name: 'location.ltd', label: t("events.city_events.latitude"), mandatory: true },
    { name: 'location.lng', label: t("events.city_events.latitude"), mandatory: true }
  ];
}

const exampleData = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? {
  country: "USA",
  city: "Easton",
  zip:  21601,
  street: "E Earle Ave",
  ltd: '38.768148',
  lng: '-76.075782'
} : {};

export { fields, exampleData, fieldsName };