import React, { useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
import { fieldsNew } from 'components/helpers/fields/parking_rules'
import styles from '../edit/index.module.sass';
import withFetching from 'components/modules/with_fetching';

const attrs = {
  customLabelClass: styles.formLabel,
  customInputClass: styles.formInput,
}

const Edit = (props) => {
  const  { updateRecord, record ,closeModal, errors, setErrors ,isSaving, agencyOfficer} = props;
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const singleFieldProps = {
    lSize: 3,
    iSize: 8,
  }

  const verifyTimeValues = (formState) => {
    const errors = {}
    const { admin_id} = formState;
    if(!admin_id) { errors.admin_id = ['Officer Name can\'t be blank']}
    return errors
  }

  const save = () => {
    setErrors({})
    const {values: formValues} = formApiRef.current.getState();
    const inputErrors = verifyTimeValues(formValues)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = {
      admin_id: `${formValues.admin_id}`,
      id: record.id
    }
    updateRecord(updatedHash)
  }

  const getFields = () => {
    return fieldsNew({mute: true, agencyOfficer,disable: true, ...attrs})
  }
  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={styles.form} initialValues={{ ...record}} onSubmit={save} >
          {({ formState }) => {
              const { validity, payment_type, expiring_permit } = formState.values;
              const newFields = getFields(validity, payment_type, expiring_permit)
              return (
                <Row className='mx-auto w-100'>
                  { map(newFields[0], (field, idx) => {
                    if(idx === 3) {
                      field = {
                        ...field,
                      }
                    }
                    return (
                      <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                        {renderField(field, { ...singleFieldProps, errors} )}
                      </Col>
                    )
                  }) }
                  <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      Cancel
                    </Button>
                    <Button type='submit' className={styles.button}   isLoading={isSaving}>
                      Assign
                    </Button>
                  </Col>
                </Row>
              )
            }
          }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Edit);
