import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import Single from './single.jsx';
import BulkCoupons from './bulk.jsx'
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
/* Base */
import Tabs from 'components/base/tabs';
import styles from './index.module.sass';

const list = () => ([
  { label: <span style={{opacity: '0.65'}}>Single Coupon</span>,  value: 'single'},
  { label: <span className='mr-2' style={{opacity: '0.65'}}>Bulk Coupon</span>,  value: 'bulk'},
])

const Index = (props) => {
  const [formType, setFormType] = useState('single');
  return (
    <Container className='mw-100 p-0'>
      <Col className={`d-flex justify-content-center`} style={{marginTop:"30px"}} >
        <Tabs className={styles.tabs} list={list()} defaultTab='single' onClick={setFormType} />
      </Col>
      <Row className='m-auto p-1'>
        { formType === 'single' && <Single {...props} /> }
        { formType === 'bulk' && <BulkCoupons {...props} /> }
      </Row>
    </Container>
  )
}

export default withCurrentUser(Index)