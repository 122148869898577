import React from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { renderFields } from 'components/base/forms/common_form';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import styles from './index.module.sass'
import { confirmPasswordFields } from 'components/helpers/fields/admins';

const PasswordConfirmation = ({closeModals, isSaving, checkPassword, errors, setErrors}) => {

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
  }
  
  const singleFieldProps = {
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const fields = () => {
    return confirmPasswordFields({...fieldAttrs})
  }

  const validateValues = (formState) => {
    const errors = {}
     const { current_user_password } = formState;
     if(!current_user_password) { errors['current_user_password'] = ['Password is required']}
     return errors
   }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    checkPassword(values?.current_user_password)
  }

return (
  <Container className='p-0'>
    <p className={`${styles.title} mx-0 mb-3 p-0`}>Password Verification</p>
    <Form className={styles.form} onSubmit={submitValues}>
      {({ formState }) => {
        const newFields = fields()
        return (
          <Col>
          <p className={`${styles.title1} mx-0 mb-3 p-0`}>We need to verify your identity before change the password</p>
            <Col>
            <div >
              {renderFields(newFields.slice(0, 1), {...singleFieldProps, errors})}
            </div>
          </Col>
          <Col className='d-flex justify-content-center'>
            <Button 
              onClick={()=>  closeModals()}
              className={`${styles.deleteButton} mb-1`}
            >
              Cancel
            </Button>
            <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
              Verify
            </Button>
          </Col>
        </Col>
      )}}
    </Form>
  </Container> 
)
}

export default PasswordConfirmation;
