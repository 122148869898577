import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { destroy, create, index } from 'api/valet_parkings';
import { create as createPricing } from 'api/pricing_models';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Enable from '../enable';
import Disable from '../disable';
import PricingModel from '../pricing_model';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const ValetModal = (props) => {
  const { openModal, modalType, setActiveTab, setValetStatus } = props
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    setErrors({})
    openModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const showValetSetting = useCallback(async () => {
    try {
      const response = await index()
      if(response.status===200){
        Object.values(response.data).forEach(function (key) {
          if(key.valet_enabled) { 
            setValetStatus(true) 
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  },[setValetStatus])

  const enableValet = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: {valet_parking: data}})
      if(response.status === 200 || response.status === 201 )
      {
        showValetSetting()
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `${modalType === 'enable' ? 'The valet parking functionality is now enabled' : 'Changes saved'}`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addAlertMessages, closeModal, showValetSetting, modalType])

  const disableValet = useCallback(async () => {
    setState('isDeleting', true)
    try {
      const response = await destroy()
      if(response.status===200){
        setValetStatus(false) 
        addAlertMessages([{
          type: 'danger',
          text: 'The valet parking functionality is disabled',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      closeModal()
      setState('isDeleting', false)
    }
  },[setState,setErrors, addAlertMessages, closeModal, setValetStatus])

  const createPricingRate = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await createPricing({data: {valet_setting: data}})
      if(response.status === 200 || response.status === 201 )
      {
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Changes saved',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addAlertMessages, closeModal])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'enable':
        return {title: 'Enable Valet Parking'}
      case 'disable':
        return {title: 'Disable Valet Parking'}
      case 'edit':
        return {title: 'Valet Parking'}
      case 'pricing_model':
        return {maxWidth: '680px', title: 'Pricing Models'}
      default:
        break;
    }
    return { width }
  },[modalType])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  if(modalType !== 'enable' && modalType !== 'edit' && modalType !== 'disable' && modalType !== 'pricing_model') return null

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.vehicleModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          (modalType === 'enable' || modalType === 'edit') && 
          <Enable
            closeModal={closeModal}
            enableValet={enableValet}
            setErrors={setErrors}
            errors={errors}
            modalType={modalType}
          />
        }
        {
          modalType === 'disable' && 
          <Disable
            closeModal={closeModal}
            disableValet={disableValet}
          />
        }
        {
          modalType === 'pricing_model' && 
          <PricingModel
            {...props}
            closeModal={closeModal}
            createPricingRate={createPricingRate}
            setErrors={setErrors}
            errors={errors}
            modalType={modalType}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default ValetModal;
