import { FieldType } from "components/helpers/form_fields";

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    [
      {
        name: 'schedule_date_and_time',
        label: 'Schedule Date',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'send_date',
        label: 'Send Date',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'sender',
        label: "Sender",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'recipient',
        label: "Recipients",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'user_emails',
        label: "User Emails",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'permit_types',
        label: "Permit Type(s)",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'category',
        label: "Category",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'title',
        label: "Title",
        customInputClass,
        customLabelClass,
        disabled: mute,
        type: FieldType.TEXT_AREA,
        rows: 2
      },
      {
        name: 'message',
        label: "Message",
        customInputClass,
        customLabelClass,
        disabled: mute,
        type: FieldType.TEXT_AREA,
        rows: 3
      },
      {
        name: 'read_by',
        label: "Read by",
        customInputClass,
        customLabelClass,
        disabled: mute,
      }
    ]
  ]
}

export {showFields};