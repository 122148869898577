import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Row, Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/broadcast_messagse";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/broadcast_messages/showFields';
import Button from 'components/base/button';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { isEmpty } from 'underscore'
import { capitalize, dateFormatter } from 'components/helpers';

const Show = ({ startFetching, closeModal , record, updateStatus}) => {
  const [isFetching, setIsFetching] = useState()
  const [broadcastMessage, setBroadcastMessage] = useState({})
  const [message, setMessage] = useState({})
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }
  const applicationData = useCallback((data) => {
    const { created_at_parsed, sender, recipient, category, title, read_by, schedule_date_and_time, user_emails, permit_type_ids, total_recipients, message  } = data
    const dateTime = schedule_date_and_time !== null ? dateFormatter(`${schedule_date_and_time?.[0].date} ${schedule_date_and_time?.[0].time}`) : ''
    return ({  
      send_date: created_at_parsed ,
      sender: sender,
      recipient: `${capitalize(recipient.split('_').join(' '))} (${total_recipients} users)`,
      category: category,
      title: title,
      read_by: read_by,
      schedule_date_and_time: dateTime,
      user_emails: user_emails,
      permit_types: permit_type_ids,
      message: message
    })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!record.id)  return;
      try {
        const response = await show({id: record.id})
        if(response.status === 200) {
          const { data } = response;
          setMessage(data)
          setBroadcastMessage(applicationData(data));
        }
      } catch(error) {
      } finally {
        
        setIsFetching(false)
      }
    };
    fetchCitationTicket();
  }, [record, applicationData, startFetching])

  const fields = showFields(fieldAttrs)

  const submitValues = useCallback((values) => {
    const data = { 
      status: values.status,    
    }
    updateStatus(data)
  },[updateStatus])

  if(isFetching) { return <Loader /> }

  return (

    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Message Details</p>
      <Form getApi={setFormApi} initialValues={broadcastMessage} className={styles.form} onSubmit={submitValues}>
      {({ formState }) => {
        return (
          <Row className='mx-auto w-100'>
          <Row className='mx-auto w-100'>
              <Col>
              <div style={{ margin: '10px'}}>
              {!isEmpty(broadcastMessage?.schedule_date_and_time) && (renderFields(fields[0].slice(0, 1), {...singleFieldProps}))}
              {renderFields(fields[0].slice(1, 4), {...singleFieldProps})}
              {message?.recipient === 'specific_users' && renderFields(fields[0].slice(4, 5), {...singleFieldProps})}
              {message?.recipient === 'permit_holders' && renderFields(fields[0].slice(5, 6), {...singleFieldProps})}
              {renderFields(fields[0].slice(6, 10), {...singleFieldProps})}
              </div>
            </Col>
            </Row>
            <Col className='d-flex justify-content-center'>
              <Button onClick={()=>  closeModal()} type='button' className={`${styles.button} ${styles['button-bg-secondary']}`}>
                Cancel
              </Button>
            </Col>
            </Row>
        )}}
      </Form>
    </Container> 
  )
}

export default (Show);