import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { Form } from "informed";
import { list as selectList } from "selectors/list";
/* API */
import { filterFetcher } from "api/city_events";
/* Helpers */
import {
  fieldsNew,
  dateFields,
} from "components/helpers/fields/event_parking_applications/city_events/filterFields";
/* Styles/Assets */
import styles from "./filter.module.sass";
/* Base */
import {
  renderFieldsWithGrid,
  renderField,
} from "components/base/forms/common_form";
import Button from "components/base/button";
import { search as dropdownSearch } from "api/dropdowns";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const FilterForm = (props) => {
  const { filters, setFilters, startFetching, setList, match } = props;
  const [parkingLotsDropdown, setParkingLotsDropdown] = useState([]);
  const formApiRef = useRef();
  const setFormApi = (formApi) => (formApiRef.current = formApi);
  
  const fromCostdollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
  const toCostdollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIconTo} />

  const filterCityEvents = useCallback(
    async (filters) => {
      startFetching(
        filterFetcher(Object.assign({ ...match.params }, { query: filters }))
      )
        .then((res) => {
          setList(selectList(res));
        })
        .catch((error) => console.log(error));
    },
    [setList, startFetching, match.params]
  );

  const fieldProps = { lSize: 3, iSize: 8 };

  const initialValues = useMemo(
    () => ({
      ...filters,
    }),
    [filters]
  );

  const resetFilters = useCallback(async () => {
    formApiRef.current.setValues({
      event_name: "",
      email: "",
      parking_lot_ids: "",
      event_days: "",
      start_date: {from: '', to: ''},
      end_date: {from: '', to: ''},
      cost: {},
    });
    setFilters({});
    filterCityEvents({});
  }, [setFilters, filterCityEvents]);

  const handleSearchFilters = useCallback(() => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      start_date: {
        from: filterValues?.start_date?.from,
        to: filterValues?.start_date?.to,
      },
      end_date: {
        from: filterValues?.end_date?.from,
        to: filterValues?.end_date?.to,
      },
    };
    setFilters(filters);
    filterCityEvents(filters);
  }, [setFilters, filterCityEvents]);

  useEffect(() => {
    const get_parking_lots = async () => {
      await dropdownSearch("parking_lot_list").then((response) =>
        setParkingLotsDropdown(response.data)
      );
    };
    get_parking_lots();
  }, []);

  return (
    <Form
      getApi={setFormApi}
      initialValues={initialValues}
      onSubmit={handleSearchFilters}
      className="w-100 m-10"
    >
      {({ formState }) => {
        const { start_date = {}, end_date = {} } = formState.values;
        const allFields = dateFields(
          styles.calenderIconFrom,
          styles.calenderIconToStartDate,
          styles.calenderIconToEndDate,
          fromCostdollarIcon(),
          toCostdollarIcon(),
          styles.toDateInput,
          styles.costFromInput.concat(' ', styles.iconInput),
          styles.costToInput.concat(' ', styles.iconInput),
          styles.formDateInput,
          styles.formToDateInput,
          styles.formLabel,
          styles.formInput,
          styles.daysField,
          start_date?.from ,
          start_date?.to,
          end_date?.from ,
          end_date?.to 
        );

        return (
          <React.Fragment>
            <Row
              className={styles.fields}
              style={{ margin: "10px", marginTop: "30px" }}
            >
              <Col className={styles.fieldset}>
                {renderFieldsWithGrid(
                  fieldsNew(
                    parkingLotsDropdown,
                    styles.formLabel,
                    styles.formInput,
                    styles.formDropdownInput
                  ),
                  5,
                  4,
                  fieldProps
                )}
              </Col>
            </Row>

            <Col className={styles.fields2} xs={12}>
              <div className={`${styles["fieldset-double"]}`}>
                <div className="general-text-2">
                  <span className={styles.doubleLabel}>Event Days</span>
                </div>
                <div className={styles.createdAtFields}>
                  {renderField(allFields[0][0], { iSize: 8 })}
                </div>
              </div>
              <div className={`${styles["fieldset-double1"]}`}>
                <div className="general-text-2">
                  <span className={styles.doubleLabel2}>
                    Event Start (Date)
                  </span>
                </div>
                <div className={styles.updatedAtFields}>
                  {renderField(allFields[0][1], { iSize: 8 })}
                  <span
                    className={`${styles["fieldset-stroke"]} position-relative`}
                  ></span>
                  {renderField(allFields[0][2], { iSize: 8 })}
                </div>
              </div>
              <div className={`${styles["fieldset-double2"]}`}>
                <div className="general-text-2">
                  <span className={styles.doubleLabel3}>Event End (Date)</span>
                </div>
                <div className={styles.endDateFields}>
                  {renderField(allFields[0][3], { iSize: 8 })}
                  <span
                    className={`${styles["fieldset-stroke3"]} position-relative`}
                  ></span>
                  {renderField(allFields[0][4], { iSize: 8 })}
                </div>
              </div>
              <div className={`${styles["fieldset-double3"]} pt-2`}>
                <div className="general-text-2">
                  <span className={styles.doubleLabel4}>Cost Per Pass</span>
                </div>
                <div className={styles.costFields}>
                  {renderField(allFields[0][5], { iSize: 8 })}
                  <span
                    className={`${styles["fieldset-stroke4"]} position-relative`}
                  ></span>
                  {renderField(allFields[0][6], { iSize: 8 })}
                </div>
              </div>
            </Col>

            <Col className="d-flex justify-content-center my-3" xs={12}>
              <Button
                className={`${styles.button} ${styles["button-disabled"]} mr-5`}
                type="button"
                onClick={() => resetFilters()}
              >
                Reset
              </Button>
              <Button className={styles.button} type="submit">
                Filter
              </Button>
            </Col>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

export default FilterForm;