import { BootEligibleVehiclesActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(BootEligibleVehiclesActions);

const BootEligibleVehiclesReducers = combineReducers({
  index,
  records,
});

export default BootEligibleVehiclesReducers;
