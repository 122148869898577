import React from 'react';
import PropTypes from 'prop-types';
import Index from 'components/pages/reward_types/index';
import Show from 'components/pages/reward_types/show'
import { Route, Switch, withRouter } from 'react-router';
import renderWithBackPath from 'components/modules/render_with_back_path';

const Routing = ({ match }) => (
  <React.Fragment>
    <Route exact path={match.path} component={Index} />
    <Switch>
        <Route path={`${match.path}/:id`} render={(props) => (
          <React.Fragment>
            <Route exact path={`${props.match.path}`} component={renderWithBackPath(Show, `${match.url}/`)} />
          </React.Fragment>
        )} />
      </Switch>
  </React.Fragment>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);