import React from "react";
/* Styles/Assets */
import styles from "./index.module.sass";
import { ReactComponent as EditIcon } from "assets/pencil-icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash-icon.svg";
import { map } from "underscore";
import Action from "./action";
import Button from "components/base/button";
import { ReactComponent as ApproveIcon } from 'assets/approve-icon.svg';
import { applyTheme } from "api/themes";


const TableRow = (props) => {
  const {filterThemes, record, setRecord, selectedRecordId, openModal, modalType, history, match, listType } = props;

  const actions = [
    {
      icon: <EditIcon className="mx-3" />,
      highlightedClass: `${styles["background-secondary"]} mx-3 text-white`,
      onClick: () => {
        history.push(`${match.path}/${record.id}`, {listType: 'dashboard', record: record})
      },
      text: "Edit",
      isActive: modalType === "edit" && selectedRecordId === record.id,
      disableHighlight: record.theme_type === "default"
    },
    {
      icon: <TrashIcon className="ml-3" />,
      highlightedClass: `${styles["background-danger"]} ml-3 text-white`,
      onClick: () => {
        openModal("delete");
      },
      text: "Delete",
      isActive: modalType === "delete" && selectedRecordId === record.id,
      disableHighlight: record.theme_type === "default" || record.applied,
    },
  ];

  const apply = async () => {
    const response = await applyTheme(record.id);
    if(response?.status === 200 && response?.data?.applied && listType ==='subscriber'){
      filterThemes()
    }
    if(listType==='dashboard'){
      window.location.reload()
    }
  };

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.theme_name}</td>
      <td>
        <span>
          <Button
            className={`${styles.applyButton} ${record.applied? styles.appliedBg : styles.primaryBg} mx-3`}
            onClick={apply}
            disabled={record.applied}
          >
            {record.applied && <ApproveIcon className={styles.approveIcon}/>} 
            {record.applied ?  <span className={styles.btnAppliedText}>Applied</span> : <span className={styles.btnApplyText}>Apply</span>}
          </Button>
        </span>
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;