import React, { useCallback, useState, useEffect, useMemo } from 'react'
/* Helpers */
import { camelize } from 'components/helpers';
/* Styles */
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';

const Action = ({status, activeClass, allowActive, className, icon, onClick}) => {
  const [isActive, setIsActive] = useState(false)
  
  const handleColorChange = (val) => {
    if(status || !allowActive) return
    setIsActive(val)
  }

  useEffect(() => {
    setIsActive(status)
  }, [status])

  return <span 
    role='button'
    onMouseEnter={() => handleColorChange(true)}
    onMouseLeave={() => handleColorChange(false)}
    onClick={() => onClick && onClick()}
    className={`${isActive ? `${className} ${activeClass}` : `${className}`} d-flex justify-content-center align-items-center`}
  >
    {icon}
  </span>
}

const TableRow = (props) => {
  const { record, setRecord, selectedRecordId, openModal, modalType } = props
  
  const backgroundColor = useCallback(() => {
    switch (record.status) {
      case 'open':
        return 'secondary'
      case 'settled':
        return 'success'
      case 'unsettled':
        return 'orange'
      case 'canceled':
        return 'danger'
      default:
        return 'default';
    }
  },[record.status] )

  const highlightAction = useCallback((actionType) => {
    return actionType === modalType && record.id === selectedRecordId
  }
  ,[modalType, selectedRecordId, record.id])

  const actions = useMemo(() => {
    return [
      {
        icon: <EyeIcon />,
        className: `${styles['icon-eye']}`,
        onClick: () => openModal('show'),
        activeClass: styles['icon-eye-active'],
        status: modalType === 'show' && highlightAction('show'),
        allowActive: true
      }
    ]
  },[openModal, modalType, highlightAction])

  return (
    <tr onClick={() => setRecord(record)}>
      <td className=''>{record.id}</td>
      <td>{camelize(record.violation)}</td>
      <td>
        <div className="d-flex align-items-center">
          <span className={`${styles.icon} ${styles[`icon-${backgroundColor(record.status)}`]}`}></span>
          <span>{camelize(record.status)}</span>
        </div>
      </td>
      <td>{ record.created_at_parsed}</td>
      <td>{ record.updated_at_parsed}</td>
      <td>{camelize(record.dispute_type) || 'N/A'}</td>
      <td>
        <div className='d-flex justify-content-center align-items-center'>
            { actions.map((action, idx) => <Action key={idx} {...action} />)}
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow
