import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";

const getDetails = (data) => {
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/get_details?event_id=${data.event_id}&online_portal=${'true'}`;

  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const show = (data) => {
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/${data.id}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}


const create = (params = {}) => {
  const { data = {} } = params;
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications`;
  const options = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const update = (params = {}) => {
  const { id, data = {} } = params;
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/${id}/edit_contact_details`;
  const options = {
    method: 'PUT',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const getParkingLots = (data) => {
  const {ltd, lng} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1//parking_lots?location_radius[distance]=3&location_radius[ltd]=${ltd}&location_radius[lng]=${lng}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const cancel = (params = {}) => {
  const { id } = params;
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/${id}/cancel?online_portal=${'true'}`;
  const options = {
    method: 'PUT',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const getTotalCost = (data) => {
  
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/total_cost`;
  const options = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const payEventApplication = (params) => {
  const {data, id} = params
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/${id}/pay?online_portal=${'true'}`;
  const options = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const accessEventParking = (data) => {
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/access_event_parking?event_id=${data.event_id}`;

  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const nearestParkingLot = (data) => {
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/event_parking_applications/nearest_parking_lot?event_id=${data.event_id}&location[ltd]=${data.location.ltd}&location[lng]=${data.location.lng}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

export { getDetails, update, cancel ,create ,getParkingLots, getTotalCost, payEventApplication, show, accessEventParking, nearestParkingLot };
