import React from "react";
import { map } from "underscore";
import styles from "./index.module.sass";
import Action from "./action";
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';

const TableRow = ({record, selectedRecord, openModal, modalType, setSelectedRecord}) => {

  const actions = [
    { 
      icon: <TrashIcon className='mx-3'/>,
      highlightedClass: `${styles['background-danger']} mx-3 text-white`,
      onClick: () => {openModal('delete')},
      text: 'Delete',
      isActive: modalType === 'delete' && selectedRecord === record.id,
      disableHighlight: !record.removable,
      width: '50px'
    },
  ];

  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td>{record?.id}</td>
      <td>{record?.name}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;