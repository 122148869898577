import React, { useState, useEffect } from "react";
import { isEmpty } from "underscore";
import { Row, Col, Card, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DonutIcon } from 'assets/dashboard_card_icons/donut-chart-svgrepo-com.svg';
import { ReactComponent as StatisticsIcon } from 'assets/dashboard_card_icons/statistical-chart-svgrepo-com.svg';
import { ReactComponent as ChevronDown } from "assets/chevron_down.svg";
import moment from "moment";
import DateModal from "components/base/date_modal";
import Chart from 'react-apexcharts'
import { ConfigProvider } from 'antd';
import DetailedReportApi from "api/detailed_reports";
import Loader from "components/helpers/loader";
import style from "./data_card.module.sass";
import { Segmented } from 'antd';
import './index.css';

const Donut = (props) => {
  const { data } = props
  let seriesData = []
  let seriesLabel = []
  
  // eslint-disable-next-line
  {Object.keys(data.violations).forEach((type) => {
    seriesData.push(data.violations[type])
    seriesLabel.push(type?.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase())))
  })}
  
  let state = {
    optionsRadial: {
      stroke: {
        width: 0
      },
      chart: {
        type: "donut"
      },
      tooltips:{
        enabled:false
      },
      hover: {mode: null},    
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: -180,
          endAngle: 180,
          donut: {
            labels: {
              show: true,
            total: {
                show: true,
                label: data.percentage_of_open_violations,
                color: "#242E42D9",
                fontSize: "26px",
                fontWeight: "600",
                formatter: function (w) {
                  return 'of open violations'
                },
            },
            value: {
              formatter: function () {
                return'of open violations';
              },
              color: "#B6B6B6",
              fontSize: "13px",
              fontWeight: "400",
              show: true
            }
              }
            }
        }
      },
      fill: {
        opacity: 0.9,
        type: 'solid',
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      theme: {
        mode: 'light',  
        monochrome: {
          enabled: true,
          color: '#3A9CED',
          shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },
      labels: seriesLabel,
    },
    seriesRadial: seriesData
  };

  return (
    <div className={style.app}>
      <Chart
        series={state.seriesRadial}
        options={state.optionsRadial}
        type="donut"
        width="100%"
      />
    </div>
  )
}

const Pie = () => {
  
  let state = {
    optionsRadial: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: [2,0],
      },
      toolbar: {
        show: false,
      },
      grid: {
        show: false
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
    },seriesRadial: [{
      name: 'Open',
      color: '#3A9CED',
      type: 'area',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: 'Total',
      color: '#E8EBED',
      type: 'area',
      data: [11, 32, 45, 32, 34, 52, 41]
    }
  ]
  };

  return (
    <Chart
      series={state.seriesRadial}
      options={state.optionsRadial}
      width="100%"
    />
  );
}


const ViolationReportCard = (props) => {
  const { maxDate, type, info } = props
  const [permitInfoOpen, setPermitInfoOpen] = useState(false);
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [currentLabel, setCurrentLabel] = useState('Today')
  const [currentComp, setCurrentComp] = useState('yesterday')
  //eslint-disable-next-line 
  const [formType, setFormType] = useState('donut');

  const state = {
    state_data: {},
    from: null,
    to: null,
    datesToFilter: [
      {
        from: moment(),
        to: null,
        label: "Today",
        name: 'yesterday',
        text: `Today (${moment().format("L")})`,
        since: "since yesterday",
      },
      {
        from: moment().startOf("isoWeek"),
        to: moment().endOf("isoWeek"),
        label: "This week",
        name: 'last week',
        text: `This week (${moment()
          .startOf("isoWeek")
          .format("MM/DD")}-${moment().endOf("isoWeek").format("MM/DD")})`,
        since: "since last week",
      },
      {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        label: "This month",
        name: 'last month',
        text: `This month (${moment().startOf("month").format("MMM")})`,
        since: "since last month",
      },
    ],
    currentSinceText: "last week",
    tileInfoOpen: false,
  };

  useEffect(() => {
    if (isEmpty(data)) {
      try{
        DetailedReportApi.index({
          resource: 'violation_comparisons'
        }).then(({ data }) => {
          setData(data);
        });
      } catch (error) {
        console.log(error)
      }
    }
  }, [data]);

  const fetchData = async (from, to, value) => {
    try {
      DetailedReportApi.index({
        configs: {pie_chart: { range: { from: from, to: to}}},
        resource: 'violation_comparisons'
      }).then(({ data }) => {
        setData(data)
        setModal(false)
        setCurrentLabel(value === undefined ? 'Custom' : value?.label)
        setCurrentComp(value?.name)
      });
    } catch (error) {
      console.log(error)
    }
  };

  const toggleTileInfo = () => {
    setPermitInfoOpen(!permitInfoOpen);
  };

  return(
    <React.Fragment>
      <Card className={style.borderPrimary}>
        {isEmpty(data) ? (
              <Loader />
            ) :
              <React.Fragment>
                <CardTitle className={`${style.cardTitle} row`}>
                  <Col className={`${style.title} pr-0`}>
                    Violation Reports
                    {
                      <React.Fragment>
                        <button
                          className="ml-2 border-0 bg-white text-primary"
                          id={type}
                        >
                          <FontAwesomeIcon
                            color="#C7CED9"
                            icon={faQuestionCircle}
                          />
                        </button>
                        <Popover
                          placement="bottom"
                          isOpen={permitInfoOpen}
                          target={type}
                          toggle={toggleTileInfo}
                          trigger="click hover focus"
                        >
                          <PopoverBody>{info}</PopoverBody>
                        </Popover>
                      </React.Fragment>
                    }
                  </Col>
                  <Col xs="auto" style={{marginTop: '-11px'}} className="d-flex align-items-center pl-0">
                    <span className={style.secondaryText}>
                      {currentLabel}
                    </span>
                    {
                      <UncontrolledDropdown>
                        <DropdownToggle tag="span" className="pointer">
                          <ChevronDown width="18" height="18" />
                        </DropdownToggle>
                        <DropdownMenu right className={style.dateDropdown}>
                          {state.datesToFilter &&
                            state.datesToFilter.map((data) => (
                              <DropdownItem
                              className={style.dateDropdown1}
                                key={data?.from?.format("YYYY-M-D")}
                                onClick={() =>
                                  fetchData(
                                    data?.from?.format("YYYY-M-D"),
                                    data?.to ? data?.to?.format("YYYY-M-D") : null,
                                    data
                                  )
                                }
                              >
                                {data?.text}
                              </DropdownItem>
                            ))}
                          <DropdownItem
                            onClick={() => setModal(true)}
                          >
                            <span className="general-text-1">
                              Select custom...
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    }
                  </Col>
                </CardTitle>
                {formType === 'donut' && <Row>
                  <Col xs={8} className="p-0">
                    <Donut 
                      {...props}
                      data={data}
                    />
                  </Col>
                  <Col className={style.rightSide} xs={4}>
                    <div className={style.name}>Open</div>
                    <div className={style.number}>{data?.open_violations}</div>
                    <div className={currentComp === undefined ? 'd-none' : style.bottom}>
                      <span className={style.percentage}>
                        {data?.percentage_change_from_previous_range}
                      </span>
                      <span className={`ml-1 ${style.number1}`}>from {currentComp}</span>
                    </div>
                    <div className={`flex-grow-1 ml-2 ${style.borderDown}`} style={{marginBottom: '25px'}}></div>
                    <div className={style.name}>Total</div>
                    <div className={style.number2}>{data?.total_violations}</div>
                  </Col>
                </Row>}
                {formType === 'pie' && <Row>
                  <Col xs="auto" className="d-flex align-items-center pl-0">
                    <Pie 
                      {...props}
                      data={data}
                    />
                  </Col>
                  <div className={style.vline}></div>
                  <Col className={style.rightSide}>
                    <div className={style.name}>Open</div>
                    <div className={style.number}>{data?.open_violations}</div>
                    <div className={currentComp === undefined ? 'd-none' : style.bottom}>
                      <span className={style.percentage}>
                        {data?.percentage_change_from_previous_range}
                      </span>
                      <span className="ml-1">from {currentComp}</span>
                    </div>
                    <div className={`flex-grow-1 ml-2 ${style.borderDown}`}></div>
                    <div className={style.name}>Total</div>
                    <div className={style.number1}>{data?.total_violations}</div>
                  </Col>
                </Row>
                }
                <Row style={{justifyContent: 'center', marginTop: '5%'}}>
                  <ConfigProvider
                    theme={{
                      token: {
                        borderRadius: 15,
                      },
                    }}
                  >
                  <Segmented
                    value={formType}
                    options={[
                      {
                        value: 'donut',
                        icon: <DonutIcon style={{marginTop: '-3px'}} />,
                      },
                      {
                        value: 'pie',
                        icon: <StatisticsIcon style={{marginTop: '-3px'}}/>,
                      },
                    ]}
                  />
                  </ConfigProvider>
                </Row>
            </React.Fragment>
        }
      </Card>
      <DateModal
        maxDate={maxDate && maxDate.toDate()}
        isOpen={modal}
        apply={fetchData}
        toggleModal={() => setModal(false)}
        title='Violation Reports'
      />
    </React.Fragment>
  );
}

export default ViolationReportCard;