import { FieldType } from "components/helpers/form_fields";

const applicationDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, customInput2Class='' } = attrs;
  return [
    {
      name: 'application[issue_on]',
      label: 'Application Date',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[type_applying_for]',
      label: 'Type Applying For',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[type_currently_held]',
      label: 'Type Currently Held',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[cost]',
      label: 'Total Cost',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[period]',
      label: 'Validity Period',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'application[cost_per_user]',
      label: 'Cost Per User',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'note',
      label: 'Add Notes',
      type: FieldType.TEXT_AREA,
      customInputClass: customInput2Class,
      customLabelClass,
      rows: 3,
    },
  ]
}

export default applicationDetails;