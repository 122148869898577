import React, { useState, useCallback, useEffect } from 'react';
/* Styles/Assets */
import styles from '../index.module.sass';
import ParkingLotForm from './parkingLotForm';
import NearbyPlaces from '../../shared/nearby_places';
import ParkingLotModal from '../../parking_modal'

const ParkingLotDetails = (props) => {

  const {record} = props
  const [selectedRecord, setSelectedRecord] = useState([])

  const [modalType, setModalType] = useState('')

  useEffect(() => {
    setSelectedRecord(record)
  }, [record])

  const openModal = useCallback((modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'settings':
        val = 'settings'
        break;
      default:
        break;
    }
    setModalType(val)
  },[setModalType])

  const parkingLotLocation = {
    lat: record?.location?.ltd,
    lng: record?.location?.lng,
    name: record?.name,
  };

  return (
    <React.Fragment>
      <section className={styles.subSection}>
        <ParkingLotForm {...props} record={selectedRecord} openModal={openModal}/>
      </section>
      <section className={`${styles.subSection} mt-3`} style={{ minHeight: 140 }}>
        <NearbyPlaces parkingLotLocation={parkingLotLocation} places={record?.places[0]} record={record}   />
      </section>
      <ParkingLotModal openModal={openModal} modalType={modalType} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord}/>
    </React.Fragment>
  )
}
export default ParkingLotDetails;