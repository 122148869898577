import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
/* Helpers */
import { FieldType } from 'components/helpers/form_fields';
/* Modules */
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";
import styles from "./violation_fines.module.sass";
/* Base */
import {renderFields} from "components/base/forms/common_form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const Rules = (props) => {

  const {parkingRules, formApiRef, setUpdatedParkingRules, state} = props
  const [ruleValues, setRuleValues] = useState({})
  const renderSaveButton = () => {
    const rules = parkingRules.map((rule) => {
      return rule.id
    });
    const object = Object.values(ruleValues).map((value,index)=>{
      return [
        { 
          id: rules[index],
          fine: value
        },
      ]
    })
    const formattedValues = object.map((value)=>{
      return value[0]
    })
    setUpdatedParkingRules(formattedValues)    
  }

  const fieldProps = (lSize, iSize) => ({
    lSize: 4,
    iSize: 8,
    events: {
      onChange: () => renderSaveButton()
    }
  })

  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

  const getFields = (id, name) => {
    return [
      { 
        name: `parking_rules_fine_${id}`,
        label: `${name} $`,
        type: FieldType.NUMBER_FIELD,
        min: 0,
        tooltipWidth: '23px',
        tooltipHeight: '23px',
        tooltipClass: styles.customTooltip,
        tooltipEnd: 'This amount will be added to the parking session charges',
        renderValue: value => value,
        customInputClass: (styles.input).concat(' ', styles.iconInput),
        customLabelClass: styles.inputLabel,
        icon: dollarIcon()
      },
    ]
  }

  useEffect(() => {
    const names = parkingRules.map((rule) => {
      return "parking_rules_fine_" + rule.id  
    });
    const values = parkingRules.map((rule) => {
      return rule.fine ? rule.fine : 0;
    });
    const object = names.reduce((acc, name, index) => {
        return {
            ...acc,
            [name]: values[index],
        };
    }, {});
    setRuleValues(object)
    formApiRef.current.setValues(object)
  }, [parkingRules, formApiRef, state])


  const fields =()=>{
    const rules = parkingRules.map((rule) => {
      return getFields(rule.id, rule.violation_type.display_name);
    });
    const fields = rules.map((feild) => {
      return feild[0]
    });
    return fields
  }

  const renderVFields =  () => {
    return  <React.Fragment>
      <Col className="m-0 p-0 pr-1 pl-1" xs={8}>
        {renderFields(fields(parkingRules),{ ...fieldProps(), errors: {} })}
      </Col>
     
    </React.Fragment>
  }

  const renderForm =() => {
    return (
      <div className={`mt-2 ${styles.form}`}>
        {renderVFields(parkingRules)}
      </div>
    )
  }

  
  
  return (
    <React.Fragment>
        {renderForm()}
    </React.Fragment>
  );
}


Rules.propTypes = {
  parkingLotId: PropTypes.number.isRequired
};

export default withFetching(withCurrentUser(Rules));