import React, { useEffect, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap'
/* Base */
import Button from 'components/base/button'
/* Assets */
import styles from './index.module.sass'

const UnderReviewApplication = ({putApplicationOnReview, record, closeModal, state}) => {

  const isSavingRef = useRef();
  useEffect(() => {
    if(isSavingRef.current === true && state.isSaving === false) {
      closeModal();
    }
    isSavingRef.current = state.isSaving
  },[state.isSaving, closeModal])
  return (
    <Container className='p-0 mw-100 m-0'>
      <p className={`${styles.title} m-0 p-0`}>Put Under Review</p>
      <Row className='flex-wrap w-100 m-0 justify-content-center'>
        <Col xs={10} className={`${styles.content} mt-4`}>
          This will put the permit application under review. Would you like to proceed?
        </Col>
        <Col className='my-4 d-flex justify-content-center' xs={12}>
          <Button 
            onClick={() => closeModal()}
            className={styles.cancelButton}
          >
            No
          </Button>
          <Button
            onClick={() => putApplicationOnReview({id: record.id})}
            className={styles.submitButton}
            disabled={state.isSaving}
          >
            Yes
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default UnderReviewApplication;
