import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
/* Actions */
import { SET_RECORD } from 'actions/violations'
/* API */
import { show } from 'api/violations';
import { search as dropdownsSearch } from 'api/dropdowns';
import { filterFetcher as searchVehicle } from 'api/vehicles';
import { getDetails } from 'api/mtas'
/* Helpers */
import Loader from 'components/helpers/loader';
/* Module */
import connectRecord from 'components/modules/connect_record';
import resourceFetcher from 'components/modules/resource_fetcher';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
/* Components */
import Comments from './comments';
import ActivityLogs from './activity_logs';
import ToolBar from './toolbar';
import { Container } from 'reactstrap';
import ViolationModal from './violationModal';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { each, isArray, isEmpty } from 'underscore';

const initState = {
  isDropdownFetching: false,
  isSaving: false,
  inputChanged: false,
  vehicleInfo: {},
  dropdowns: {
    officers: [],
    statuses: [],
    types: []
  },
  errors: {},
  activeTab: '',
  modalType: '',
  mtaFetching: false,
}

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors, vehicleInfo } = state
  switch (type) {
    case 'isDropdownFetching':
      return { ...state, isDropdownFetching: payload }
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'inputChanged':
      return { ...state, inputChanged: payload }
    case 'dropdowns':
      return { ...state, dropdowns: {...state.dropdowns, ...payload} }
    case 'errors':
      return { ...state, errors: payload }
    case 'activeTab':
      return { ...state, activeTab: payload }
    case 'modalType':
      return { ...state, modalType: payload }
    case 'vehicleInfo':
      return { ...state, vehicleInfo: payload, modalType: '' }
    case 'filters':
      return { ...state, filters: payload }
    case 'mtaFetching':
      return { ...state, mtaFetching: payload,  vehicleInfo: payload ? {} : vehicleInfo }
    default:
      return { ...state };
  }
};

const vehicleData = data => {
  return {
    vehicle_details:  { 
      state: data.registration_state, 
      make: data.manufacturer?.name, 
      model: data.model, 
      year: data.manufacture_year, 
      color: data.color,
    },
    owner_details: { 
      name: `${data.user?.first_name || ''} ${data.user?.last_name || ''}`, 
      email: data.user?.email, 
      phone: data.user?.phone 
    }
  }
}

const mtaVehicleData = ({vehicle_details, owner_details}) => {
  return {
    vehicle_details,
    owner_details: { 
      name: `${owner_details.first_name || ''} ${owner_details.last_name || ''}`, 
    }
  }
}

const Show = (props) => {
  const { isResourceFetching, record={}, startFetching } = props
  const [state, dispatch] = useReducer(reducer, initState)
  const { addAlertMessages } = useContext(AlertMessagesContext);
  
  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])
  
  const setState = useCallback((type, payload) => {
    dispatch({ type, payload })
  },[dispatch])

  const setDropdowns = useCallback((key, data) => {
    setState('dropdowns', { [key]: data } )
  },[setState])

  const loadOfficers = useCallback(async () => {
    startFetching(dropdownsSearch('agency_officers_list', { agency_id: record?.agency?.id }))
      .then(response => setDropdowns('officers', response.data));
  },[startFetching, record, setDropdowns])

  const loadVehicle = useCallback(async () => {
    const res = await searchVehicle({ query: {plate_number: record.plate_number} })
    if(res.status === 200 && res.data.length === 1) {
      setState('vehicleInfo', vehicleData(res.data[0]))
    } else {
      setState('vehicleInfo', 'Not found')
    }
  },[record.plate_number, setState])

  const fetchVehicleInfo = useCallback(async () => {
    setState('mtaFetching', true )
    try {
      const response = await getDetails({plate_number: record.plate_number})

      if(isArray(response.data?.vehicle) && response.data?.vehicle[0] === 'Not found') {
        setState('vehicleInfo', 'Not found' )
        addAlertMessages([{
          type: 'danger',
          text:  'LPN not found',
          onlyMessage: true,
        }], 'center')
        return
      }else{
        setState('vehicleInfo', mtaVehicleData(response.data) )
        addAlertMessages([{
          type: 'primary',
          text:  `Information for vehicle with LPN ${record.plate_number.toUpperCase()} was successfully retrieved`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (_error) {
    }finally {
      setState('mtaFetching', false )
    }
  },[setState, record.plate_number, addAlertMessages])

  useEffect(() => {
    Promise.all([
      startFetching(dropdownsSearch('tickets_statuses_field'))
        .then(response => setDropdowns('statuses', response.data)),
      startFetching(dropdownsSearch('tickets_types_field'))
        .then(response => setDropdowns('types', response.data))
    ])
      .finally(() => setState({ isDropdownFetching: false }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (isFetching()) {
    return <Loader />;
  }

  return (
    <Container className='px-0 pb-4 mw-100 '>
      <ToolBar
        state={state}
        setState={setState}
        fetchVehicleInfo={fetchVehicleInfo}
        {...props}
      />
      <ActivityLogs filters={state.filters} activeTab={state.activeTab} />
      <Comments agencyId={record?.agency?.id} />
      <DelayedAction check={record} actions={[loadOfficers, loadVehicle]} />
      <ViolationModal record={record} state={state} setState={setState} fetchVehicleInfo={fetchVehicleInfo} />
    </Container>
  );
}

const DelayedAction = ({ check, actions }) => {
  useEffect(() => {
    if (!isEmpty(check)) {
      each(actions, action => action());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

Show.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object
};

export default connectRecord(
  'violation',
  SET_RECORD,
  resourceFetcher(show),
  withFetching(withCurrentUser(Show))
);
