import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Col, Container } from 'reactstrap';
import { Form } from 'informed';
/* API */
import { show } from 'api/permits';
/* Helpers */
import { cancelledFields, ownerFields, permitFields, revokedFields, vehicleFields, visitorDetails } from 'components/helpers/fields/permits/show';
import { capitalize, displayDate, displayUnixTimestamp } from 'components/helpers';
import Loader from 'components/helpers/loader';
/* Base */
import { renderFields, renderFieldsWithGrid, renderInput } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Styles */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { each, filter, isEmpty, map, values } from 'underscore';
import { ReactComponent as ShareIcon } from 'assets/share.svg';
import Tabs from 'components/base/tabs';
import { FieldType } from 'components/helpers/form_fields';

const validDaysData = (days) => {
  const data = {}
  each(days, (day, idx) => {
    data[`day-${idx + 1}`] = day
  })
  return data
}

const fieldAttrs = {
  customInputClass: styles.input,
  customLabelClass: styles.inputLabel,
  customLotInputClass: styles.lotInput,
  customInput1Class: styles.input1,
}

const doubleFieldProps = {
  iSize: 7,
  lSize: 5
}

const singleFieldProps = {
  iSize: 10,
  lSize: 2
}


const lotFieldProps = {
  iSize: 9,
  lSize: 3
}

const refundMessage = (payment_type, refund_amount, refund_on, refund_completed) => {
  if (payment_type === 'paid' && refund_completed) {
    return `${refund_amount} refund issued on ${displayDate(refund_on, 'MMMM Do YYYY')}`
  }
  if(payment_type === 'paid' && !refund_completed){
    return 'Refund pending'
  }
}

const permitData = (data) => {
  const {
    cancelled_details = {},
    vehicle = {},
    revoked_details = {},
    owner_name = "",
    home_address = {},
    work_address = {},
    permit_number,
    expiry_date,
    issue_date,
    permit_expired,
    permit_type,
    cost,
    status,
    refund_completed,
    payment_type,
    valid_days,
    imported_permit,
    parking_lot = {},
    shared,
    secondary_vehicles=[],
    commuter_details=[],
    email_address,
    purpose_of_visit,
    visitor_address
  } = data;
  return ({
    vehicle: {
      ...vehicle,
      plate_number: vehicle.plate_number?.toUpperCase(),
      make: imported_permit? capitalize(vehicle?.make) : capitalize(vehicle?.manufacturer),
      color: capitalize(vehicle.color),
      model: capitalize(vehicle.model),
      registration_state: capitalize(vehicle.registration_state),
    },
    owner: { 
      full_name: owner_name, 
      home_address: home_address.address1,
      home_phone: home_address.phone,
      work_address: work_address.address1,
      work_phone: work_address.phone,
      email: email_address
    },
    permit: {
      number: permit_number,
      expires_on: expiry_date === 'Non-Expiring' ? 'Non-Expiring' : displayDate(expiry_date,'DD/MM/YYYY'),
      issued_on: issue_date,
      type: permit_type,
      expired: permit_expired ? 'YES' : 'NO',
      cost,
      payment_type,
      refund_completed,
      status,
      parking_lot: `${parking_lot?.name} (${parking_lot?.address})`,
      valid_days: map(valid_days, date => displayUnixTimestamp(new Date(date).getTime()/ 1000, 'ddd, MMM Do, YYYY')),
      shared: shared
    },
    revoked_details: {
      ...revoked_details,
      status: capitalize(status),
      revoked_on: displayUnixTimestamp(new Date(revoked_details?.revoked_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
    },
    cancelled_details: {
      ...cancelled_details,
      status: capitalize(status),
      cancelled_on: displayUnixTimestamp(new Date(cancelled_details?.cancelled_on).getTime() / 1000, 'ddd, MMM Do YYYY, h:mm:ss A'),
      description: refundMessage(payment_type, cancelled_details?.refund_amount, cancelled_details?.refund_on, refund_completed)
    },
    secondary_vehicles: secondary_vehicles,
    commuter_details: commuter_details,
    purpose_of_visit,
    visitor_address
  })
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['primary-color']} ${styles.dollarIcon}`} />
const dotIcon = (status) => <FontAwesomeIcon icon={faCircle} className={`${styles[`${status}-color`]} ${styles.dotIcon}`} />

const dotVehicleIcon = (status, color='') => <FontAwesomeIcon icon={faCircle} className={`${styles[`${status}-color`]} ${styles.dotIcon}`} color={color}/>

const categoryColor = (status) => {
  switch (true) {
    case ['cancelled', 'revoked',].includes(status):
      return status
    default:
      return 'primary';
  }
}

const  Show = ({record, isSaving, openInnerModal, renewIssuedPermit})  => {
  const [isFetching, setIsFetching] = useState(false);
  const [permit, setPermit] = useState({})
  const [selectedTab, setSelectedTab] = useState('primary')

  const formApiRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const renderOwnerFields = () => {
    const  fields = ownerFields(fieldAttrs);
    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Owner Information</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
        {!isEmpty(permit?.purpose_of_visit) && <Col className='px-3' style={{paddingBottom: '1px'}}>
          {renderFields(visitorDetails(fieldAttrs)[0].slice(0,1), {iSize: 9,lSize: 3})}
        </Col>}
      </Col>
    )
  }

  const renderVehicleFields = () => {
    const fields = vehicleFields(fieldAttrs)
    fields[1] = {
      ...fields[1], 
      icon: dotVehicleIcon(null, permit.vehicle?.color), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }
    return(
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Vehicle Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
      </Col>
    )
  }

  const renderPermitFields = useCallback(() => {
    let fields = permitFields(fieldAttrs)
    const iconStatus = permit.permit?.expired === 'NO' ? 'success' : 'revoked'
    fields[4] = {...fields[4], icon: dotIcon(iconStatus), customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)}
    fields[5] = {
      ...fields[5], 
      icon: dollarIcon(), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }

    // if(record.permit_type === 'Day Pass') {
    //   fields = filter(fields, (_field, idx) => [0, 2, 5].includes(idx))
    //   slicePoint = 2
    // }

    return (
      <Col className='d-flex flex-wrap px-0'>
        <div className="d-flex align-items-center my-3 w-100">
          <span className={styles.detailsLabel}>Permit Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className={styles.fieldset}>
        {renderFields(fields.slice(0,3), {...doubleFieldProps})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,6), {...doubleFieldProps})}
        </div>
        <div className={styles.lotfieldset}>
          {renderFields(fields.slice(6,7), {...lotFieldProps})}
        </div>
      </Col>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permit, record])
  
  const renderCancelledFields = useCallback(() => {
    if(record.status !== 'cancelled') return
    const showDescription =  permit.permit?.payment_type === 'paid'
    const fields = cancelledFields(fieldAttrs, showDescription);
    fields[0] = {
      ...fields[0], 
      icon: dotIcon('cancelled'), 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
    }
    if(showDescription && permit.permit?.refund_completed) {
      fields[2] = {
        ...fields[2], 
        icon: dollarIcon(), 
        customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)
      } 
    }
    return (
      <Col className='px-0'>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Permit Status</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className='px-3'>
          {renderFields(fields, {...singleFieldProps})}
        </div>
      </Col>
    )
  },[permit, record.status])

  const renderRevokedFields = useCallback(() => {
    if(record.status !== 'revoked') return
    const fields = revokedFields(fieldAttrs)
    fields[0] = {...fields[0], icon: dotIcon('revoked'), customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput)}
    return (
      <Col className='px-0'>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Permit Status</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div className='px-3'>
          {renderFields(fields, {...singleFieldProps})}
        </div>
      </Col>
    )
  },[record.status])

  const renderVisitorApplicationDetails = useCallback((fields) => {
    
    return (
      <Col className='px-0'>
        <div className="d-flex align-items-center my-3">
          <span className={styles.detailsLabel}>Residential Details</span>
          <span className="border border-2 flex-grow-1 ml-2"></span>
        </div>
        <div>
          {renderFieldsWithGrid(fields, 2, 6, { ...doubleFieldProps })}
        </div>
      </Col>
    )
  },[])

  const validDateFields = useMemo(() => (
    map(permit.permit?.valid_days, (_date, idx) => (
      {
        name: `day-${idx + 1}`, 
        label: `Valid Day ${idx + 1}`,
        disabled: true,
        ...fieldAttrs
      }
    ))
  ), [permit])
    

  useEffect(() => {
    const fetchPermit = async () => {
      setIsFetching(true)
      try {
        const response = await show({id: record.id})
        const newPermit = permitData(response.data);
        setPermit(newPermit)
      } catch (error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    }
    if(record.id) { fetchPermit() }
  },[record.id])

  const list = (userCount) => ([
    { label: <span style={{opacity: '0.65'}}>Primary Permit Holder</span>,  value: 'primary'},
    { label: <React.Fragment><span className='mr-2' style={{opacity: '0.65'}}>Secondary Permit Holders</span><span className={styles.tabBadge}>{userCount}</span></React.Fragment>,  value: 'secondary'},
  ])

  const listCommuter = (userCount) => ([
    { label: <span style={{opacity: '0.65'}}>Applicant Details</span>,  value: 'primary'},
    { label: <React.Fragment><span className='mr-2' style={{opacity: '0.65'}}>Commuter Details</span><span className={styles.tabBadge}>{userCount}</span></React.Fragment>,  value: 'secondary'},
  ])
  

  const permitUserField = (users) => {
    return {
      name: 'permit_user',
      type: FieldType.SELECT_FIELD,
      customInputClass: `${styles.input1} mt-4 mb-2`,
      options: map(users, (user) => ({label: user?.user?.first_name, value: user?.id}))
    }
  }

  const permitCommuterField = (users) => {
    return {
      name: 'permit_user',
      type: FieldType.SELECT_FIELD,
      customInputClass: `${styles.input1} mt-4 mb-2`,
      options: map(users, (user) => ({label: user?.full_name, value: user?.id}))
    }
  }

  const updateApplicantData = useCallback((e) => {
    const secondary = e?.target?.value ? e?.target?.value : e
    const applicantValues = map(permit.secondary_vehicles.filter((u) =>`${u.id}` === secondary))
    const values = formApiRef.current.getValues()
    updateApplicationData(values, applicantValues[0] )
  },[permit])

  const updateApplicationData =(values, secondary ) =>{
    const updatedData = {
      vehicle: {
        plate_number: secondary?.plate_number?.toUpperCase(),
        make: capitalize(secondary?.manufacturer.name),
        color: capitalize(secondary?.color),
        model: capitalize(secondary?.model),
        registration_state: capitalize(secondary?.registration_state),
        manufacture_year: secondary?.manufacture_year,
      },
      owner: { 
        full_name: `${secondary?.user?.first_name} ${secondary?.user?.last_name}`, 
        home_address: secondary?.user?.home_address?.address1,
        home_phone: secondary?.user.phone,
        work_address: secondary?.user?.work_address.address1,
        work_phone: secondary?.user?.phone,
        email: secondary?.user?.email
      },
    }
    formApiRef.current.setValues({...values, vehicle: updatedData.vehicle, owner: updatedData.owner})
  }

  const updateCommuterApplicantData = useCallback((e) => {
    const secondary = e?.target?.value ? e?.target?.value : e
    const applicantValues = map(permit.commuter_details.filter((u) =>`${u.id}` === secondary))
    const values = formApiRef.current.getValues()
    updateCommuterApplicationData(values, applicantValues[0] )
  },[permit])

  const updateCommuterApplicationData =(values, data ) =>{
    const updatedData = {
      vehicle: {
        plate_number: data?.vehicle[0]?.plate_number?.toUpperCase(),
        make: capitalize(data?.vehicle[0]?.manufacturer),
        color: capitalize(data?.vehicle[0]?.color),
        model: capitalize(data?.vehicle[0]?.model),
        registration_state: capitalize(data?.vehicle[0]?.registration_state),
        manufacture_year: data?.vehicle[0]?.manufacture_year,
      },
      owner: { 
        full_name: data?.full_name, 
        home_address: data?.home_address,
        home_phone: data?.home_phone,
        work_address: data?.work_address,
        work_phone: data?.work_phone,
        email: data?.email
      },
    }
    formApiRef.current.setValues({...values, vehicle: updatedData.vehicle, owner: updatedData.owner})
  }


  const updateTab = (e) => {
    setSelectedTab(e)
    const values = formApiRef.current.getValues()
    if (e === 'primary') {
      formApiRef.current.setValues({...values, vehicle: permit.vehicle, owner: permit.owner})
    } else {
      const values = formApiRef.current.getValues()
      if(permit?.permit?.shared) updateApplicationData(values,permit.secondary_vehicles?.[0])
      if(permit?.commuter_details?.length > 0) updateCommuterApplicationData(values,permit.commuter_details?.[0])
    }
  }
  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0 my-3'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>
      </p>
      <div className='mb-1'>
        <p className={`${styles.title} mx-0 mb-2 p-0`}>{record?.permit_type}</p>
          { permit?.permit?.shared && (
            <Badge className={`${styles.badge} ${styles['badge-orange-background']} px-2 py-1 `}>
              <ShareIcon className='mr-1'/>
              <span>Shared</span>
            </Badge>
          )}
          { 
            record.permit_type !== 'Day Pass' && 
              <p className={`${styles[`${categoryColor(permit.permit?.status)}-color`]} ${styles.category} text-capitalize m-0 p-0`}>
                {`${record.permit_category} Permit`}
              </p>
          }
          { 
            !['cancelled', 'revoked', 'expired'].includes(permit.permit?.status)
              &&  (
                <p 
                  className={`${permit.permit?.status === 'active' ? styles['success-color'] : styles['cancelled-color']} text-capitalize m-0 p-0`}
                  style={{fontSize: 14}}
                >
                  {`${permit.permit?.status}`}
                </p>
              )
             
          }    
      </div>
      <Form initialValues={{...permit, ...validDaysData(permit.permit?.valid_days)}} className={styles.form} getApi={setFormApi} >
        { permit?.permit?.shared && <Col xs={11} className='m-auto py-3'>
          <Tabs
            list={list(permit.secondary_vehicles.length)} 
            defaultTab={selectedTab} className={styles.tabs} 
            onClick={(val) => updateTab(val)} 
          />
          { selectedTab === 'secondary' && renderInput(
              permitUserField(values(permit.secondary_vehicles)),
              {events: {onChange: updateApplicantData}}
            )
          }
        </Col> }
        { record.permit_type === 'Carpool Permit' && <Col xs={11} className='m-auto py-3'>
          <Tabs
            list={listCommuter(permit?.commuter_details?.length)} 
            defaultTab={selectedTab} className={styles.tabs} 
            onClick={(val) => updateTab(val)} 
          />
          { selectedTab === 'secondary' && renderInput(
              permitCommuterField(values(permit.commuter_details)),
              {events: {onChange: updateCommuterApplicantData}}
            )
          }
        </Col> }
        { renderCancelledFields() }
        { renderRevokedFields() }
        { renderVehicleFields() }
        { renderOwnerFields() }
        {!isEmpty(permit?.visitor_address) && renderVisitorApplicationDetails(visitorDetails(fieldAttrs)[1]) }
        { renderPermitFields() }
        { !isEmpty(permit.permit?.valid_days) && (
            <Col className='d-flex flex-wrap px-0'>
            <div className="d-flex align-items-center my-3 w-100">
              <span className={styles.detailsLabel}>Valid Days</span>
              <span className="border border-2 flex-grow-1 ml-2"></span>
            </div>
            <div className={styles.fieldset}>
              {renderFields(filter(validDateFields, (_f, idx) => idx % 2 === 0), {...doubleFieldProps})}
            </div>
            <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
              {renderFields(filter(validDateFields, (_f, idx) => idx % 2 === 1), {...doubleFieldProps})}
            </div>
          </Col>
        )}
      </Form>
      { record.permit_category === 'temporary' &&
        <Col className='d-flex justify-content-center align-items-center mb-5'>
          <Button
            onClick={renewIssuedPermit}
            className={`${styles.button} ${permit.permit?.expired === 'NO' && styles['button-bg-secondary']}`}
            disabled={isSaving || permit.permit?.expired === 'NO'}
          >
            Renew
          </Button>
          <Button 
            onClick={openInnerModal}
            className={`${styles.button} ${styles['button-bg-orange']} ml-4`}
            disabled={isSaving}
          >
            Extend
          </Button>
        </Col>
      }
    </Container>
  )
}

export default Show;
