import { FieldType } from 'components/helpers/form_fields';

const raiseFields = ({customInputClass, customLabelClass, disputeOn, disputeTypes=[]}) => [
  {
    name: 'dispute_type',
    label: 'Dispute Type',
    mandatory: true,
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Please Select',
    options: disputeTypes,
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'text',
    placeholder: `Please elaborate on why you dispute on this ${disputeOn === 'session' ? 'session' : 'citation'} *`,
    type: FieldType.TEXT_AREA,
    mandatory: true,
    rows: 4,
    maxLength: 10,
    customLabelClass,
    customInputClass
  },
  { 
    name: 'user_email',
    placeholder:"Enter Email Address *",
    mandatory: true,
    customLabelClass,
    customInputClass
  },
  {
    name: 'entry_time',
    type: FieldType.SINGLE_HOUR_FIELD,
    customInputClass,
    customLabelClass,
    label: 'Entry Time:'
  },
  {
    name: 'exit_time',
    type: FieldType.SINGLE_HOUR_FIELD,
    customInputClass,
    customLabelClass,
    label: 'Exit Time:'
  },
];

const raiseFields1 = ({customInputClass, customLabelClass, customDropdownInputClass, disputeTypes=[]}) => [
    {
      name: 'dispute_type',
      label: 'Dispute Type',
      mandatory: true,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      placeholder: 'Please Select',
      options: disputeTypes,
      customInputClass: customDropdownInputClass,
      customLabelClass,
    },
    { 
      name: 'detailed_reason',
      placeholder: "Please elaborate on why you dispute on this citation *",
      type: FieldType.TEXT_AREA,
      mandatory: true,
      rows: 4,
      maxLength: 10,
      customLabelClass,
      customInputClass
    },
    { 
      name: 'user_email',
      placeholder:"Enter Email Address *",
      mandatory: true,
      customLabelClass,
      customInputClass
    },
    {
      name: 'entry_time',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      label: 'Entry Time:'
    },
    {
      name: 'exit_time',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      label: 'Exit Time:'
    },
  ];

  const subscriberRaiseFields = ({customInputClass, customLabelClass, disputeTypes=[]}) => [
    {
      name: 'dispute_type',
      label: 'Dispute Type',
      mandatory: true,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a type',
      options: disputeTypes,
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'text',
      placeholder: "Please elaborate on why you dispute this citation.",
      type: FieldType.TEXT_AREA,
      mandatory: false,
      rows: 3,
      maxLength: 10,
      customLabelClass},
    {
      name: 'entry_time',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      label: 'Entry Time:'
    },
    {
      name: 'exit_time',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      label: 'Exit Time:'
    },
  ];

export { raiseFields, subscriberRaiseFields, raiseFields1};
