import { combineReducers } from 'redux';
import TicketReducers from './tickets';
import UserReducers from './users';
import AdminReducers from './admins';
import AgencyReducers from './agencies';
import PaymentsReducers from './payments';
import AgencyTypeReducers from './agency_types';
import VehiclesReducers from './vehicles';
import CameraReducers from './cameras';
import ParkingLotReducers from './parking_lots';
import ParkingSlotReducers from './parking_slots';
import ParkingLotCameraReducers from './parking_lots_camera';
import ParkingSessionReducers from './parking_sessions';
import ReportReducers from './reports';
import ServerErrorReducers from './server_errors';
import ParkingLotVoiReducers from './parking_lot_voi';
import DetailedReportsReducers from './detailed_reports';
import RolesReducers from './roles';
import SideNavReducers from './side-navigation';
import ViolationReducers from './violations';
import ViolationTypeReducers from './violation_types';
import CommentReducers from './comments';
import ActivityLogReducers from './activity_logs';
import NotificationReducers from './notifications';
import SubscriberReducers from './subscribers';
import SubscriberVehicleReducers from './subscriber_vehicles';
import { LOG_OUT, SUB_LOG_OUT } from 'actions/users';
import DisputeReducers from './disputes';
import PaymentActivityLogReducers from './payment_activity_logs';
import PermitApplicationReducers from './permit_applications';
import PermitTypeReducers from './permit_types';
import PermitReducers from './permits';
import SpecialPermitTypeReducers from './special_permit_types';
import SpecialPermitReducers from './special_permits';
import ReservedParkingLotReducers from './reserved_parking_lots';
import ParkingCitationTicketReducers from './parking_citation_tickets';
import CarpoolPermitReducers from './car_pool_pemits';
import SubscriberPermitReducers from './subscribers/permits';
import SubscriberPermitApplicationsReducers from './subscribers/permit_applications';
import MonitorVehiclesReducers from './monitor_vehicles';
import EventParkingApplicationsReducers from './event_parking_applications';
import EventsReducers from './events'
import RewardTypesReducers from './reward_types';
import CouponsReducers from './coupons';
import SubscriberNotificationsReducers from './subscribers/notifications';
import PermitTypeCodesReducers from './permit_type_codes';
import BootingsReducers from './bootings';
import ParkingRulesActions from './parking_rules';
import PermitWaitingListReducers from './permit_waiting_lists';
import SubscriberVehiclesReducers from './subscribers/vehicles';
import ViolatorReducers from './violators';
import AccountTypeReducers from './account_types';
import EventCategoriesReducers from './event_categories';
import CityEventsReducers from './city_events';
import UserRewardsReducers from './user_rewards';
import UserCouponsReducers from './user_coupons';
import ThemeReducers from './themes';
import UserChargingSessionsReducers from './user_charging_sessions'
import BroadcastMessagesReducers from './broadcast_messages';
import ParkingRegulationsReducers from './parking_regulations';
import ChargingSessionsReducers from './charging_sessions';
import PastChargingSessionsReducers from './past_charging_sessions';
import ManufacturersReducers from './manufacturers';
import DisabilitiesReducers from './disabilities';
import ValueAddedServicesReducers from './value_added_services';
import UserEnrolledReducers from './users_enrolled';
import SystemLogsReducers from './system_logs'
import TowingsReducers from './towings';
import BootEligibleVehiclesReducers from './boot_eligible_vehicles';
import EmailsReducers from './emails';
import ResidentialTypesReducers from './residential_types'
import ValetStaffManagementsReducer from './valet_staff_management'
import VehicleCitationTicketsReducers from './vehicle_citation_tickets'

const reducers = combineReducers({
  user: UserReducers,
  admin: AdminReducers,
  vehicles: VehiclesReducers,
  agency: AgencyReducers,
  agency_type: AgencyTypeReducers,
  ticket: TicketReducers,
  camera: CameraReducers,
  payments: PaymentsReducers,
  parking_lot: ParkingLotReducers,
  parking_slot: ParkingSlotReducers,
  parking_lot_camera: ParkingLotCameraReducers,
  parking_session: ParkingSessionReducers,
  parking_lot_voi: ParkingLotVoiReducers,
  report: ReportReducers,
  subscriber: SubscriberReducers,
  subscriber_vehicle: SubscriberVehicleReducers,
  server: ServerErrorReducers,
  detailedReports: DetailedReportsReducers,
  role: RolesReducers,
  side_nav: SideNavReducers,
  violation: ViolationReducers,
  violation_type: ViolationTypeReducers,
  comment: CommentReducers,
  activity_log: ActivityLogReducers,
  notification: NotificationReducers,
  dispute: DisputeReducers,
  permit_type: PermitTypeReducers,
  permit_application: PermitApplicationReducers,
  payment_activity_log: PaymentActivityLogReducers,
  permit: PermitReducers,
  carpool_permit: CarpoolPermitReducers,
  special_permit_type: SpecialPermitTypeReducers,
  special_permit: SpecialPermitReducers,
  reserved_parking_lot: ReservedParkingLotReducers,
  parking_citation_ticket: ParkingCitationTicketReducers,
  monitor_vehicles: MonitorVehiclesReducers,
  event_parking_applications: EventParkingApplicationsReducers,
  events: EventsReducers,
  reward_types: RewardTypesReducers,
  coupons: CouponsReducers,
  subscriber_permits: SubscriberPermitReducers,
  subscriber_permit_applications: SubscriberPermitApplicationsReducers,
  subscriber_notifications: SubscriberNotificationsReducers,
  permit_type_codes: PermitTypeCodesReducers,
  bootings: BootingsReducers,
  parking_rules: ParkingRulesActions,
  subscriber_vehicles: SubscriberVehiclesReducers,
  violators: ViolatorReducers,
  account_types: AccountTypeReducers,
  event_categories: EventCategoriesReducers,
  city_event: CityEventsReducers,
  permit_waiting_list: PermitWaitingListReducers,
  user_reward: UserRewardsReducers,
  user_coupon: UserCouponsReducers,
  user_charging_session: UserChargingSessionsReducers,
  theme: ThemeReducers,
  broadcast_messages: BroadcastMessagesReducers,
  parking_regulations: ParkingRegulationsReducers,
  charging_sessions: ChargingSessionsReducers,
  past_charging_sessions: PastChargingSessionsReducers,
  manufacturers: ManufacturersReducers,
  disabilities: DisabilitiesReducers,
  value_added_services: ValueAddedServicesReducers,
  user_enrolled: UserEnrolledReducers,
  system_logs: SystemLogsReducers,
  towings: TowingsReducers,
  boot_eligible_vehicles: BootEligibleVehiclesReducers,
  emails: EmailsReducers,
  residential_types: ResidentialTypesReducers,
  valet_staff_management :ValetStaffManagementsReducer,
  vehicle_citation_tickets :VehicleCitationTicketsReducers,
});

export default (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }

  if (action.type === SUB_LOG_OUT) {
    state = undefined;
  }

  return reducers(state, action);
};
