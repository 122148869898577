import React, { useContext, useRef, useState } from 'react'
import { Col, Container, Label, Row } from 'reactstrap';
import Button from 'components/base/button';
import { flatten, isEmpty, map, values } from 'underscore';
/* API */
import { importPermitTypes } from 'api/permit_types';
/* Helpers */
import Loader from 'components/helpers/loader';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
/* styles/Assets */
import styles from './index.module.sass'
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'

const permitTypeAttrs = [
  { name: 'Permit Name'},
  { name: 'Category', altText: '(accepts only <em>Permanent</em> or <em>Temporary</em>)'},
  { name: 'Validity', altText: '(accepts only <em>Days</em>, <em>Weeks</em>, or <em>Months</em>)'},
  { name: 'Period', altTextArray: [
   { no: 'i', text: 'If validity is <em>Days</em>, accepts only <em>1</em>, <em>2</em>, <em>3</em>, <em>4</em>, <em>5</em>, or <em>6</em>.'},
    {no: 'ii', text: 'If validity is <em>Weeks</em>, accepts only <em>1</em>, <em>2</em>, or <em>3</em>.'},
    {no: 'iii', text: 'If validity is <em>Months</em>, accepts only <em>1</em>, <em>3</em>, or <em>6</em>.'},
  ]},
  { name: 'Parking Lot', altTextArray: [
    { no: 'i', text: 'Accepts parking lot ID. If there are several parking lots, the IDs should be separated by a comma'}
  ]},
  { name: 'Parking Time From', altText: '(accepts time in 12-hour format)'},
  { name: 'Parking Time To', altText: '(accepts time in 12-hour format)'},
  { name: 'Free/Paid', altText: `(accepts only <em>Free</em> or <em>Paid</em>)`},
  { name: 'Hourly Rate', altText: '(accepts numbers including decimals)'},
]

const PermitTypes = ({closeModal}) => {
  const [fileName, setFileName] = useState();
  const [isSaving, setIsSaving] = useState(false)
  const fileRef = useRef()
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const importData = async (e) => {
    e.preventDefault()
    e.stopPropagation();
    const formData = new FormData()
    formData.append('file', fileRef.current.files[0])
    try {
      setIsSaving(true)
      await importPermitTypes(formData);
      addAlertMessages([{
        type: 'primary',
        text:  `${capitalize(fileName.split('.')[0])} CSV file was successfully imported`,
        onlyMessage: true,
      }],
      'center'
      )
    } catch (error) {
      addAlertMessages(
        map(flatten(values(error.response?.data?.errors)), (message) => {
          return (
            {
              type: 'danger',
              text:  message,
              onlyMessage: true,
            }
          )
        }),
        'center'
      )
    } finally {
      setIsSaving(false)
      closeModal()
    }
  }
  
  const showFileName = (e) => {
    setFileName(e.target.files[0]?.name)
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileName(e.dataTransfer.files[0].name)
      fileRef.current = { files: e.dataTransfer.files }
    }
  };

  return (
    <Container>
      <p className={`${styles.title} m-0 p-0`}>Import Permits Types Data</p>
      { 
        isSaving 
          ? <Loader />
          : (
            <Col className={`${styles.body} text-left`}>
              <Col className='my-4 p-0'>
                <span className='d-block'>
                  Please upload permit data in CSV file. 
                  The file should contain the following 
                  columns in the same order as listed below.
                </span>
                <span className='d-block my-2'>
                  * Time should be written in 12-hour format e.g. 7:00 AM.
                </span>
              </Col>
              <Row className='my-4 mx-0 p-0'>
                {permitTypeAttrs.map((attr, idx) => (
                  <React.Fragment key={attr.name}>
                    <p className='p-0 d-flex align-items-center w-100 mb-1'>
                      <span className={`${styles.numberLabel} mr-2`}>
                        {`${idx + 1}.`}
                      </span>
                      <span className={styles.boldText}>
                        {attr.name}
                      </span>
                      { attr.altText && <span className='ml-1' dangerouslySetInnerHTML={{ __html: attr.altText}}></span>}
                    </p>
                    { attr.altTextArray && 
                        <p className='mb-1 p-0 d-flex flex-column'>
                          {map(attr.altTextArray, (value) => {
                            return <span key={value.no} className='d-flex' style={{marginLeft: 30}}>
                              <span className={`mr-2 ${!!value.no} && 'hidden'`}>{`${value.no}`}</span>
                              <span dangerouslySetInnerHTML={{ __html: value.text}}></span>
                            </span>
                          })}
                        </p>
                    }
                  </React.Fragment>
                ))}
              </Row>
              <form onSubmit={importData}>
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                  <input ref={fileRef} className='d-none' id='csv-file-upload' type='file' accept='.csv' onChange={showFileName} />
                  <Label htmlFor='csv-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-100 w-100 text-center p-3`}>
                    <span className='mb-2'>
                      <CloudDownloadIcon />
                    </span>
                    { fileName && <span>{fileName}</span> }
                    <span>
                      <span className={styles.boldText}>
                        Choose a CSV file
                      </span>
                      <span className='ml-1'>
                        or drag it here
                      </span>
                    </span>
                  </Label>
                </div>
                <Col className="d-flex justify-content-center mb-3 mt-5 p-0">
                  <Button 
                    type='button' 
                    className={`${styles.greyButton} mr-4`}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isEmpty(fileName)} type='submit' className={styles.saveButton}>
                    save
                  </Button>
                </Col>
              </form>
            </Col>
          )
      }
    </Container>
  )
}

export default PermitTypes;
