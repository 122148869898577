import React, {useState, useEffect} from 'react'
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { map } from 'underscore';
import Toggle from 'components/base/toggle';
import Action from './action'

const TableRow = (props) => {
    const { record, setRecord, selectedRecordId, openModal, modalType } = props
    const [highlightEnabled, setHighlightEnabled] = useState(false)

    useEffect(() => {
      setHighlightEnabled(modalType ==='activate_deactivate')
    },[modalType])
  
    const actions = [
        {
            icon: <EditIcon className='mx-3' />,
            highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
            onClick: () => { openModal('edit') },
            text: 'Edit',
            isActive: modalType === 'edit' && selectedRecordId === record.id,
            disableHighlight: record.shared || record.permit_type === "Day Pass"
        },
        {
            icon: <TrashIcon className='ml-3' />,
            highlightedClass: `${styles['background-danger']} ml-3 text-white`,
            onClick: () => { openModal('delete') },
            text: 'Delete',
            isActive: modalType === 'delete' && selectedRecordId === record.id
        }
    ]

    return (
        <tr onClick={() => setRecord(record)}>
            <td>{record?.category_name}</td>
            <td>
                <Toggle
                    onClick={() => { setHighlightEnabled(true); openModal('activate_deactivate') }}
                    customToggleClass={styles.toggle}
                    customToggleOnClass={styles.toggleOn}
                    positiveText=''
                    negativeText=''
                    value={record?.status}
                    offHighlightClass={styles.toggleHighlight}
                    onHighlightClass={styles.toggleOnHighlight}
                    highlightEnabled={highlightEnabled && modalType === 'activate_deactivate' && selectedRecordId === record?.id}
                />
            </td>
            <td>
                <div className={`${styles.actions} d-flex justify-content-center `} >
                    {map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
                </div>
            </td>
        </tr>
    );

};

export default TableRow