import React, { useState, useRef, useCallback} from "react";
import GooglePayDetails from 'components/modules/google_pay.js'
import { Form } from 'informed';
/* Base */
import { renderField } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass';
import GooglePayButton from '@google-pay/button-react';
import { map, isEmpty } from 'underscore';
import Cards from 'react-credit-cards';
import { paymentFields } from 'components/helpers/fields/parking_citation_tickets/paymentFields';
import { Col, Row } from 'reactstrap';
import ErrorWrapper from "components/base/forms/common_form/error";

const Payment =(props) =>{
  const { closeModals, payCitation, record } = props;

  const [cardNumber, setCardNumber] = useState('');
  const [name, setName] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [expiryM, setExpiryM] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const creditCardNum = useRef('')
  const holder_name = useRef('')
  const cvv = useRef('')
  const [showCreditCardFields, setShowCreditCardFields] = useState(false)
  const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,12])
  const [errors, setErrors] = useState({})

  const years = Array.from(
    { length: 20 },
    (_, index) => new Date().getFullYear() + index
  );

  const fieldAttrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInputClass: styles.formDropdownInput,
    customExpiryInputClass: styles.cardInput,
    years: years,
    months: months
  }
   
  const [showGooglePay, setGooglePayShow] = useState(false);
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const formApiRef = useRef();

  const cardMFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_month')
    }
  }
  const cardYFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_year'),
    }
  }

  const handleCardFieldsChange = (value, field) => {
    const updatedErrors = Object.keys(errors)
      .filter((objKey) => objKey !== field)
      .reduce((newObj, key) => {
        newObj[key] = errors[key];
        return newObj;
      }, {});
    setErrors(updatedErrors);
    if (field === "credit_card_number") {
      setCardNumber(value);
    } else if (field === "holder_name") {
      setName(value);
    } else if (field === "expiration_month") {
      setExpiryM(value);
      setFocus("expiry");
    } else if (field === "expiration_year") {
      handleExpiryYearChange(value);
    } else if (field === "cvc") {
      setCvc(value);
    }
  };

  const handleExpiryYearChange  = (e) => {
    setExpiryY(e) ;
    if(e > new Date().getFullYear()){
      setMonths([1,2,3,4,5,6,7,8,9,10,12])
    } else {
      setMonths(months.filter((m) => {return m > (new Date().getMonth() + 1)}))
    }
  }

  const validateFields = () => {
    const {payment_methods, expiration_month, expiration_year } = formApiRef.current.getValues();
    const errors = {};
      if(!payment_methods){errors['payment_methods'] = ['Payment method is Required!']}
      if(!creditCardNum?.current?.value){errors['credit_card_number'] = ['Credit Card Number is required!']}
      if(!holder_name?.current?.value){errors['holder_name'] = ['Card Holder Name is required !']}
      if(!cvv?.current?.value){errors['cvc'] = ['CVC is required !']}
      if(!expiration_month){errors['expiration_month'] = ['Expiration Month is required !']}
      if(!expiration_year){errors['expiration_year'] = ['Expiration Year is required!']} 

   return errors 
  }

  const submitValues = useCallback((values) => {
    const validationErrors = validateFields()
   
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }

    const data = { 
      gateway_params:{
        amount: record?.fine,
        credit_card_attributes:{
          number:  creditCardNum?.current?.value,
          cvv: cvv?.current?.value,
          holder_name: holder_name?.current?.value,
          expiration_month: values?.expiration_month,
          expiration_year: values?.expiration_year
        },
        
      }
    }
    payCitation(data)
  },[record, payCitation])

  const submitValuesGPay = useCallback((paymentToken) => {
    const data = { 
      gateway_params:{
        amount: record?.fine,
        digital_wallet_attributes: {
          encryptionhandler: 'EC_GOOGLE_PAY',
          devicedata: paymentToken?.paymentMethodData?.tokenizationData?.token
        }      
      }
    }
    payCitation(data)
  },[record, payCitation])

  
  const initialValues = {
    payment_amount: record?.fine
  }
  const getFields = () => {
    return paymentFields({
      ...fieldAttrs,
      customDropdownInputClass: styles.formDropdownInput,
      customExpiryDropdownInputClass:
        !isEmpty(errors) ? styles.expiryDropdownInnerModal2 : styles.expiryDropdown,
      customExpiryLabel: styles.expiryDropdownLabel,
      customExpiryLabel2: styles.cardLabels2,
    });
  }
  
  
  const showPaymentOption = () =>{
    if (formApiRef.current?.getValue('payment_methods')===0){
      setGooglePayShow(true)
      setShowCreditCardFields(false)
  
    }else if (formApiRef.current?.getValue('payment_methods')===1) {
      setShowCreditCardFields(true)
      setGooglePayShow(false)
    }
  }
  
  const newFields = getFields()

  const paymentFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (_e) => showPaymentOption()
    }
  }

  return (
    
    <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={submitValues}>
      <Row className='mx-auto w-100'>
        <Col style={{marginLeft: '2.4rem'}}>
          <Row className='flex-column w-100 text-center p-1'>
            <p className={styles.modalTitle}> Make Payment </p>
              { map(newFields[0], (field, idx) => {
                return (
                  <React.Fragment>
                    { idx === 0 && 
                      <Col className={`m-0 pt-3 ${styles['fieldset-double']} pl-1`} xs={12}>
                        { renderField(field, { ...paymentFieldProps, errors } )}
                      </Col>
                    }
                    {idx === 1 &&
                      <Col className={`m-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                        { renderField(field, { ...paymentFieldProps, errors} )}
                      </Col>
                    }
                    
                  </React.Fragment>
                )
                })
              }
              {showCreditCardFields && !showGooglePay &&
                <Col style={{marginLeft: '-1.5rem'}}>
                  <div className={styles.cardContainer}>
                    <div className={styles.cardPreview}>
                      <Cards
                        number={cardNumber}
                        name={name}
                        expiry={`${expiryM}${expiryY}`}
                        cvc={cvc}
                        focused={focus}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.cardInputs}>
                      <label className={styles.cardLabels}>Card Number</label>
                      <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'credit_card_number'}}>
                        <input
                          type="tel"
                          id="credit_card_number"
                          name="credit_card_number"
                          placeholder="Card Number"
                          maxLength={16}
                          value={cardNumber}
                          onChange={(e) => handleCardFieldsChange(e.target.value, 'credit_card_number')}
                          onFocus={() => setFocus('number')}
                          className={styles.cardInput}
                          ref={creditCardNum}
                        />
                      </ErrorWrapper>
                      <label className={styles.cardLabels}>Holder Name</label>
                      <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'holder_name'}}>
                        <input
                          type="text"
                          id="holder_name"
                          name="holder_name"
                          placeholder="Holder Name"
                          value={name}
                          onChange={(e) => handleCardFieldsChange(e.target.value, 'holder_name')}
                          onFocus={() => setFocus('name')}
                          className={styles.cardInput}
                          ref={holder_name}
                        />
                      </ErrorWrapper>
                      { map(newFields[1], (field, idx) => {
                        return (
                          <React.Fragment>
                            {idx=== 0 &&
                              renderField(field, { ...cardYFieldProps, errors} )}
                            {idx===1 &&
                              renderField(field, { ...cardMFieldProps, errors} )
                            }
                          </React.Fragment>
                        )
                      }) }
                      <label htmlFor="cvc" className={styles.cvcLabel}>CVC</label>
                      <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'cvc'}}>
                        <input
                          type="tel"
                          id="cvc"
                          name="cvc"
                          placeholder="CCV"
                          value={cvc}
                          onChange={(e) => handleCardFieldsChange(e.target.value, 'cvc')}
                          onFocus={() => setFocus('cvc')}
                          className={styles.cardInput}
                          maxLength={3}
                          ref={cvv}
                        />
                      </ErrorWrapper>
                    </div>
                  </div>
                </Col>
              }
              <div centered>
                {showGooglePay  && <GooglePayButton
                environment="TEST"
                paymentRequest={GooglePayDetails(record?.fine)}
                onLoadPaymentData={paymentRequest => {
                  submitValuesGPay(paymentRequest)
                }}
                buttonType='pay'
                className={styles.gpayBtn}

              />}
              </div>
            </Row>
        </Col>
      </Row>
      <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
        {!showCreditCardFields && 
          <Button onClick={closeModals} type='button' className={`${styles.button} ${styles['button-bg-secondary']}`}>
            Cancel
          </Button>  
        }
        {showCreditCardFields &&     
          <Button onClick={closeModals} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
            Cancel
          </Button>  
        }
        {showCreditCardFields &&     
          <Button type='submit'  className={`${styles.button} ${styles['button-bg-primary']}`}>
            Pay
          </Button>
        }
      </Col>
    </Form>
  );
}

export default Payment;
