import React from 'react'
import { Col, Container, Row } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'

const OnHoldApplication = ({holdApplication, closeModal,isSaving, record}) => {

  return (
    <Container className='p-0 mw-100 m-0'>
      <p className={`${styles.title} m-0 p-0`}>Put On Hold</p>
      <Row className='flex-wrap w-100 m-0 justify-content-center'>
        <Col xs={10} className={`${styles.content} mt-4`}>
          This will put the {record.event_name} Event Application On Hold. Would you like to proceed?
        </Col>

        <Col className="my-4 d-flex justify-content-center" xs={12}>
          <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} ${styles['button-bg-light']} mr-4`}>
            No
          </Button>
          <Button 
              type='button' 
              onClick={() => {holdApplication({status: 'on_hold'}); closeModal()}} 
              className={`${styles.submitButton} ${styles['button-bg-primary']}`}
              disabled={isSaving}
            >
              Yes
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default OnHoldApplication;
