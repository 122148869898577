const mtaVehicleFields = (attrs) => [
  { name: 'vehicle_details[state]', label: 'Registration State', ...attrs },
  { name: 'vehicle_details[make]', label: 'Vehicle Make', ...attrs },
  { name: 'vehicle_details[model]', label: 'Vehicle Model', ...attrs },
  { name: 'vehicle_details[year]', label: 'Vehicle Year', ...attrs },
  { name: 'vehicle_details[color]', label: 'Vehicle Color', ...attrs },
]

const mtaOwnerFields = (attrs) => [
  { name: 'owner_details[name]', label: 'Full Name', ...attrs },
  { name: 'owner_details[email]', label: 'Email Address', ...attrs },
  { name: 'owner_details[phone]', label: 'Phone Number', ...attrs },
]

export { mtaOwnerFields, mtaVehicleFields }