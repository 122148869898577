import resourceApi from "components/modules/resource_api";
import fetchApi from "components/modules/fetch_api";

const { index, show } = resourceApi("special_permits");
const {index: reservedLotsIndex} = resourceApi('reserved_parking_lots')

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const parkingLotFilterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return reservedLotsIndex({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const renewPermit = ({id}) => {
  return fetchApi(`dashboard/permits/${id}/renew_temporary_permit`, { method: 'PUT' })
};

const extendPermit = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permits/${id}/extend_temporary_permit`, { method: 'PUT', data })
};

export { filterFetcher, parkingLotFilterFetcher,show ,renewPermit, extendPermit};
