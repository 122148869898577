import React from 'react';
import PropTypes from 'prop-types';
import Index from 'components/pages/permit_applications/index';

import { Route, Switch, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={Index} />
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);