import fetchApi from 'components/modules/fetch_api';

const myPermitFetcher = (data={}) => {
  const { filters } = data;
  const { status = '', application_date = {},  permit_valid = {}, permit_number = '', permit_type_name='', plate_number } = filters || {};
  const queryParams = new URLSearchParams({
    ...(status && { status }),
    ...(application_date.from !== undefined && { 'application_date[from]': application_date.from }),
    ...(application_date.to !== undefined && { 'application_date[to]': application_date.to }),
    ...(permit_valid.from !== undefined && { 'permit_valid[from]': permit_valid.from }),
    ...(permit_valid.to !== undefined && { 'permit_valid[to]': permit_valid.to }),
    ...(permit_number && { permit_number }),
    ...(plate_number && { plate_number }),
    ...(permit_type_name && { permit_type_name }),
  });
  const queryString = queryParams.toString();
  const url = `v1/permits?per_page=100000${queryString ? `&${queryString}&` : '?'}`;
  return fetchApi(url, { method: 'GET' });
};

const show = (params) => {
  const { id } = params
  return fetchApi(`v1/permits/${id}`, { method: 'GET' })
};

const renew = (params) => {
  const { id, data } = params
  return fetchApi(`v1/permits/${id}/renew`, { method: 'PUT', data })
};


const extend = (params) => {
  const { id, ...data } = params
  return fetchApi(`v1/permits/${id}/extend`, { method: 'PUT', data })
};

const cancel = (id) => {
  return fetchApi(`v1/permits/${id}/cancel`, { method: 'PUT' })
};


export {
  myPermitFetcher, extend, renew, show, cancel
};
