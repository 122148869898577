import { FieldType } from "components/helpers/form_fields";

const filterFields = (customLabelClass = "", customInputClass = "",customDropdownClass, permitTypes, plateNos,customIconClass, t) => [  
  {
    name: "permit_number",
    label: t("subscriber.permit_number"),
    placeholder: t("subscriber.my_permits.filterForm.permit_number_placeholder"),
    customInputClass,
    customLabelClass,
  },
  
  {
    name: "application_date[from]",
    label: t("subscriber.my_permits.filterForm.application_from"),
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("subscriber.my_permits.filterForm.date_placeholder"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "application_date[to]",
    label: t("subscriber.my_permits.filterForm.application_to"),
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("subscriber.my_permits.filterForm.date_placeholder"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "permit_type_name",
    label: t("permit_application.apply_for_permit.labels.step3.permit_type"),
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: permitTypes,
    placeholder: t("subscriber.my_permits.filterForm.permit_type_placeholder"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
  
  {
    name: "permit_valid[from]",
    label: t("subscriber.my_permits.filterForm.permit_valid_from"),
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("subscriber.my_permits.filterForm.date_placeholder"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "permit_valid[to]",
    label: t("subscriber.my_permits.filterForm.permit_valid_to"),
    type: FieldType.SUBSCRIBER_DATE_FIELD,
    placeholder: t("subscriber.my_permits.filterForm.date_placeholder"),
    customInputClass,
    customLabelClass,
    customIconClass
  },
  {
    name: "status",
    label: t("subscriber.my_permits.filterForm.permit_status"),
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: [
      { value: "pending", label: "Pending" },
      { value: "active", label: "Active" },
      { value: "revoked", label: "Revoked" },
      { value: "expired", label: "Expired" },
      { value: "cancelled", label: "Cancelled" },
      { value: "returned", label: "Returned" },
    ],
    placeholder: t("subscriber.my_permits.filterForm.permit_status_placeholder"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
  {
    name: "plate_number",
    label: t("subscriber.my_permits.filterForm.vehicle_plate_number"),
    type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
    options: plateNos,
    placeholder: t("subscriber.my_permits.filterForm.plate_number_placeholder"),
    customInputClass: customDropdownClass,
    customLabelClass,
  },
];

export default filterFields;