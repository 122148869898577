import React, { useCallback, useEffect, useRef, useState, useReducer } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import {isEmpty, map, times } from 'underscore';
/* Base */
import { renderField, renderFields, renderFieldsWithGrid } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { searchV1 as dropdownsSearch } from 'api/dropdowns';
/* Helpers */
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDollarSign } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';
import { exampleData, permitFields, fieldsIdentification } from 'components/helpers/fields/app/apply_permit';
import LocationForm from 'components/pages/subscriber/permit_applications/location/form/index.jsx'
import { getParkingLots, validateStep2,checkParkingLot, getPermitType, getVehicleDetails, getTotalCost,fetchStatesList, getOwnerDetails, fetchVehicleData, applyCoupon } from 'api/app/apply_permit';
import { ReactComponent as CrosshairsIcon } from 'assets/app/crosshairs-icon.svg';
import { capitalize } from 'lodash'
import { Label } from 'reactstrap';
import  vehicleFields  from 'components/helpers/fields/app/vehicleFields';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import GooglePayButton from '@google-pay/button-react';
import GooglePayDetails from 'components/modules/google_pay.js'
import { Spinner } from 'reactstrap';
import { ReactComponent as FetchIcon } from 'assets/refresh_icon.svg';
import moment from 'moment';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CommutersCard from 'components/base/commuters_card';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import env from ".env";
import { useTranslation, Trans } from 'react-i18next';
import ErrorWrapper from 'components/base/forms/common_form/error';

const initState = {
  errors: {},
  fetching: false,
  show_details: false,
  parkingLots: {},
  selectedParkingLots:[],
  totalSelectedSpaces: 0,
  disableCheckboxes: false,
};

const reducer = (state, action) => {
  const { citation } = state;
  switch (action.type) {
    case 'errors':
      return { ...state, errors: action.payload };
    case 'parkingLots':
      return { ...state, citation: action.payload };
    case 'fetching':
      return { ...state, fetching: action.payload, citation: action.payload ? {} : citation };
    case 'show_details':
      return { ...state, show_details: action.payload };
    case 'selectParkingLot':
      return {
        ...state,
        selectedParkingLots: action.payload.selectedParkingLots,
        disableCheckboxes: action.payload.disableCheckboxes,
      };
    default:
      return { ...state };
  }
};


const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};



const rateCostProps = {
  lSize: 5,
  iSize: 5
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
const reddollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['revoked-color']} ${styles.dollarIcon}`} />


const Apply = (props) => {
  const  {currentSubscriber,setCommuterId, setVManufacturers,setUserType, setState, closeModal, errors, setErrors, isSaving, startFetching, applyPermitApplication, setPermitName, permitName, openInnerModal, formApiRef, setFormApi } = props;
  const [permitTypes, setPermitTypes] = useState([])
  const [nearbyParkingLots, setNearbyParkingLots] = useState()
  const [currentLocation , setCurrentLocation] = useState(exampleData)
  const [isFetchingLocation, setIsFetchingLocation] = useState(false)
  const [step , setStep] = useState(1)
  const [permit , setPermit] = useState("")
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initState);
  const [selectedParkingLots, setSelectedParkingLots] = useState(null);
  const [lotAvailablity, setLotAvailability] = useState(false)
  const [alertMsg, setAlertMsg] = useState("")

  const [permitTypeID, setPermitTypeID] = useState()
  const [permitType, setPermitType] = useState({})
  const [vehicle, setVehicle] = useState()
  const [plateNumber, setPlateNumber] = useState()

  const [periodDropdown, setPeriodDropdown] = useState([])

  const [idType, setIDType] = useState()

  const [fileName, setFileName] = useState();
  const fileRef = useRef()

  const [showGooglePay, setGooglePayShow] = useState(false);
  const [showCreditCardFields, setShowCreditCardFields] = useState(false)
  const [totalCost, setTotalCost] = useState()
  const [permitTypePeriod, setPermitTypePeriod] = useState()
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false)
  const [isFetchingOwner, setIsFetchingOwner] = useState(false)
  const [userLocation, setUserLocation] = useState()
  const [checkP, setCheckP] = useState(false)
  const [checkV, setCheckV] = useState(false)
  const [countries, setCountries] = useState()
  const [stateList, setStateList] = useState()
  const [mute, setMute] = useState(true)
  const [plateNError, setPlateNError] = useState(false)
  const vehicles = currentSubscriber?.vehicles_attributes.map((v) => {return {value: v.id, label: v.plate_number}})
  const [isUSWorkPhone, setIsUSWorkPhone] = useState(false)
  const [isUSHomePhone, setIsUSHomePhone] = useState(false)
  // eslint-disable-next-line
  const [validPLots, setValidPLots] = useState([])
  // eslint-disable-next-line
  const [invalidPLots, setInvalidPLots] = useState([])
  const [isValidFile, setIsValidFile] = useState(true)
  const [manufacturers, setManufacturers] = useState()
  const [cardNumber, setCardNumber] = useState('');
  const [name, setName] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [expiryM, setExpiryM] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const creditCardNum = useRef('')
  const holder_name = useRef('')
  const cvv = useRef('')
  const [muteManufact, setMuteManufact] = useState(true)
  const [initialCountry, setInitialCountry] = useState('')
  const [msg, setMsg] = useState('')

  const [commuters] = useState({})
  const [filePresence, setFilePresence] = useState(true)
  const [autoRenew, setAutoRenew] = useState(false)
  const checkboxref = useRef(false)
  const savecheckboxref = useRef(false)
  const [saveCard , setSaveCard] = useState(false)
  const [base64IdProof, setBase64IdProof] = useState()
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const [coupon, setCoupon] = useState('')
  const couponRef = useRef('')
  const [validateCoupon, setValidateCoupon] = useState('')
  const [couponApplied, setCouponApplied] = useState(false)
  const [applyingCoupon, setApplyingCoupon] = useState(false)
  const [subscriberCoordinates, setSubscriberCoordinates] = useState({ltd: null, lng: null})
  const [fetchedCurrentLocation, setFetchedCurrentLocation] = useState(false)
  const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,12])
  const [subclasses, setSubClasses] = useState([])
  const { t } = useTranslation()

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
  
      // Convert the file to base64 text
      reader.readAsDataURL(file);
  
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        setBase64IdProof(reader.result);
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

 
  const handleCheckboxChange = (parkingLot) => {
    const selectedParkingLots = state.selectedParkingLots.slice();
    const parkingLotIndex = selectedParkingLots.indexOf(parkingLot);

    if (parkingLotIndex === -1) {
      selectedParkingLots.push(parkingLot);
    } else {
      selectedParkingLots.splice(parkingLotIndex, 1);
    }
    setCheckP(true)
    const disableCheckboxes = (selectedParkingLots.length === 2);
    setState('selectParkingLot', {
      selectedParkingLots,
      disableCheckboxes,
    });
    setSelectedParkingLots(parkingLot)
    // eslint-disable-next-line array-callback-return
    nearbyParkingLots.map((lot) => {
      checkPermitAvailability(lot)
    })
    
  };
  const valPrProps = {
    lSize: 5,
    iSize: 5,
    events: {
      onChange: (_e) => setCheckV(true)
    }
  }
  

  const validateFields = () => {
    const {
      location,
      category,
      permit_type_id,
      period,
      plate_number,
      applied_from_date,
      manufacturer_id,
      year,
      model,
      color,
      registration_state,
      vehicle_category,
      institution_name,
      vehicle_class,
      id_type,
      id_number,
      full_name,
      birthday,
      home_phone,
      address1,
      city,
      country_code,
      state_code,
      postal_code,
      email,
      work_phone,
      payment_methods,
      vehicle_type,
      expiration_month,
      expiration_year,
      sub_class,
    } = formApiRef.current.getValues();
    const errors = {};
    const current_date = new Date()
    const entered_date = new Date(applied_from_date)
    current_date.setHours(0,0,0,0)
    entered_date.setHours(0,0,0,0)
    // eslint-disable-next-line no-useless-escape
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;


    if (step === 1) {
      if (!location && !currentLocation) {
        errors["location"] = [
          t(
            "permit_application.apply_for_permit.labels.step1.location.required"
          ),
        ];
      }
      if (!category) {
        errors.category = [
          t(
            "permit_application.apply_for_permit.labels.step1.select_permit_category.required"
          ),
        ];
      }
      if (!permit_type_id) {
        errors.permit_type_id = [
          t(
            "permit_application.apply_for_permit.labels.step1.select_permit_type.required"
          ),
        ];
      }
      if (!period && permitType) {
        errors.period = [
          t("permit_application.apply_for_permit.labels.step1.period.required"),
        ];
      }
      if (!applied_from_date && permitType) {
        errors.applied_from_date = [
          t(
            "permit_application.apply_for_permit.labels.step1.applied_from_date.required"
          ),
        ];
      }
      if (entered_date < current_date) {
        errors.applied_from_date = ["Cannot be a past date !"];
      }
    }
    if (step ===  2) {
      if (!plate_number) {
        errors["plate_number"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_lpn.required"
          ),
        ];
      }      
      if (!manufacturer_id) {
        errors["manufacturer_id"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.manufacturer.required"
          ),
        ];
      }
      if (!year) {
        errors.year = [
          t("permit_application.apply_for_permit.labels.step2.year.required"),
        ];
      }
      if (!model) {
        errors.model = [
          t("permit_application.apply_for_permit.labels.step2.model.required"),
        ];
      }
      if (!color) {
        errors.color = [
          t("permit_application.apply_for_permit.labels.step2.color.required"),
        ];
      }
      if (!registration_state) {
        errors.registration_state = [
          t(
            "permit_application.apply_for_permit.labels.step2.registration_state.required"
          ),
        ];
      }
      if (!vehicle_category) {
        errors["vehicle_category"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.category.required"
          ),
        ];
      }
      if (vehicle_category === "government" && !institution_name) {
        errors["institution_name"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.institution_name.required"
          ),
        ];
      }
      if (!sub_class) {
        errors["sub_class"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_sub_class.required"
          ),
        ];
      }
      if (!vehicle_class) {
        errors["vehicle_class"] = [
          t("permit_application.apply_for_permit.labels.step2.class.required"),
        ];
      }
      if (!vehicle_type) {
        errors["vehicle_type"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_type.required"
          ),
        ];
      }
      if (!id_type) {
        errors["id_type"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.id_type.required"
          ),
        ];
      }
      if (!id_number) {
        errors["id_number"] = [
          t("permit_application.apply_for_permit.labels.step2.id_number"),
        ];
      }
      if (!full_name) {
        errors["full_name"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.full_name.required"
          ),
        ];
      }
      if (!birthday) {
        errors["birthday"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.date_of_birth.required"
          ),
        ];
      }
      if (birthday > moment().subtract(18, "years")._d) {
        errors["birthday"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.date_of_birth.must_be_18"
          ),
        ];
      }
      if (!home_phone) {
        errors["home_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.home_phone.required"
          ),
        ];
      }
      if (!address1) {
        errors["address1"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.address1.required"
          ),
        ];
      }
      if (!city) {
        errors["city"] = [
          t("permit_application.apply_for_permit.labels.step2.city.required"),
        ];
      }
      if (!country_code) {
        errors["country_code"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.country.required"
          ),
        ];
      }
      if (!state_code) {
        errors["state_code"] = [
          t("permit_application.apply_for_permit.labels.step2.state.required"),
        ];
      }
      if (!postal_code) {
        errors["postal_code"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.zip_code.required"
          ),
        ];
      }
      if (!email) {
        errors["email"] = [
          t("permit_application.apply_for_permit.labels.step2.email.required"),
        ];
      }
      if (email && !email?.match(isValidEmail)) {
        errors["email"] = [
          t("permit_application.apply_for_permit.labels.step2.email.invalid"),
        ];
      }
      if (!work_phone) {
        errors["work_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.work_phone.required"
          ),
        ];
      }
      if (plateNError) {
        errors["plate_number"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_lpn.already_present"
          ),
        ];
      }

      if (!isUSWorkPhone) {
        errors["work_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.work_phone.invalid"
          ),
        ];
      }
      if (!isUSHomePhone) {
        errors["home_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.home_phone.invalid"
          ),
        ];
      }
    }
    if (step === 3 && permitType?.name !== "Carpool Permit") {
      if (!payment_methods) {
        errors["payment_methods"] = ["Payment method is Required!"];
      }
      if (!creditCardNum?.current?.value) {
        errors["credit_card_number"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.card_number.validation"
          ),
        ];
      }
      if (!holder_name?.current?.value) {
        errors["holder_name"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.holder_name.validation"
          ),
        ];
      }
      if (!cvv?.current?.value) {
        errors["cvc"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.cvc.validation"
          ),
        ];
      }
      if (!expiration_month) {
        errors["expiration_month"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_month.validation"
          ),
        ];
      }
      if (!expiration_year) {
        errors["expiration_year"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_year.validation"
          ),
        ];
      }
    }

    if (step === 4 && permitType?.name === "Carpool Permit") {
      if (!payment_methods) {
        errors["payment_methods"] = ["Payment method is Required!"];
      }
      if (!creditCardNum?.current?.value) {
        errors["credit_card_number"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.card_number.validation"
          ),
        ];
      }
      if (!holder_name?.current?.value) {
        errors["holder_name"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.holder_name.validation"
          ),
        ];
      }
      if (!cvv?.current?.value) {
        errors["cvc"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.cvc.validation"
          ),
        ];
      }
      if (!expiration_month) {
        errors["expiration_month"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_month.validation"
          ),
        ];
      }
      if (!expiration_year) {
        errors["expiration_year"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_year.validation"
          ),
        ];
      }
    }

  return Object.assign(
    errors, 
    validateCommuterAttrs(
      formApiRef.current.getValue('commuter_attributes'), 
      formApiRef.current?.getValue('commuters_per_vehicle')
    ),
  )
  };

  const validateUserAttrs = (attrs={}, type) => {
    const { full_name, birthday, home_phone, address1, city, country_code, state_code, postal_code,  email, work_phone/* Add more fields as needed */ } = attrs
    const errors = {};
    // eslint-disable-next-line 
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if(!full_name){errors[`${type}[full_name]`] = [t("permit_application.apply_for_permit.labels.step2.full_name.required")]}
    if(!birthday){errors[`${type}[birthday]`] = [t("permit_application.apply_for_permit.labels.step2.date_of_birth.required")]}
    if(birthday > moment().subtract(18, "years")._d){errors[`${type}[birthday]`] = [t("permit_application.apply_for_permit.labels.step2.date_of_birth.must_be_18")]}
    if(!home_phone){errors[`${type}[home_phone]`] = [t("permit_application.apply_for_permit.labels.step2.home_phone.required")]}
    if(!address1){errors[`${type}[address1]`] = [t("permit_application.apply_for_permit.labels.step2.address1.required")]}
    if(!city){errors[`${type}[city]`] = [t("permit_application.apply_for_permit.labels.step2.city.required")]}
    if(!country_code){errors[`${type}[country_code]`] = [t("permit_application.apply_for_permit.labels.step2.country.required")]}
    if(!state_code){errors[`${type}[state_code]`] = [t("permit_application.apply_for_permit.labels.step2.state.required")]}
    if(!postal_code){errors[`${type}[postal_code]`] = [t("permit_application.apply_for_permit.labels.step2.zip_code.required")]}
    if(!email){errors[`${type}[email]`] = [t("permit_application.apply_for_permit.labels.step2.email.required")]}
    if(email && !(email?.match(isValidEmail))){errors[`${type}[email]`] = [t("permit_application.apply_for_permit.labels.step2.email.invalid")]}
    if(!work_phone){errors[`${type}[work_phone]`] = [t("permit_application.apply_for_permit.labels.step2.work_phone.required")]}
    if(plateNError){errors[`${type}[plate_number]`] = [t("permit_application.apply_for_permit.labels.step2.vehicle_lpn.already_present")]}
    if(!isUSWorkPhone){errors[`${type}[work_phone]`] = [t("permit_application.apply_for_permit.labels.step2.work_phone.invalid")]}
    if(!isUSHomePhone){errors[`${type}[home_phone]`] = [t("permit_application.apply_for_permit.labels.step2.home_phone.invalid")]}
    return errors
  }

  const validateCommuterAttrs = (commuterAttrs={}, totalComutters=0) => {

    if(step === 3 && permitType.name === 'Carpool Permit'){let errors = {}
    times(totalComutters, idx => {
      const fieldErrors = validateUserAttrs(commuterAttrs[`commuter-${idx + 1}`], `commuter_attributes[commuter-${idx + 1}]`)
      errors = {...errors, ...fieldErrors}
    })

    return errors;
  }
  }
  

  const userFieldProps = (idx)  => {
    return (
      {
        lSize: 5,
        iSize: 6,
        events: {
          onChange: (_e) => setErrors({})
        }
      }
    )
  }

  const openInnModal = (idx, userType) => {
    setCommuterId(idx)
    setUserType(userType)
    openInnerModal('add_vehicle')
  }


  const renderCommuters = (totalComutters, commuterValues={}) => {
    const initialFormValues = JSON.parse(localStorage.getItem('allCommutersData')) || {};
    const customLabelClass = styles.commuterLabel
    const customInputClass = styles.formInput
    let allCommuterFields = []
    if(totalComutters && totalComutters > 0){
      times(totalComutters, (idx) => {
        const id_type = commuterValues[`commuter-${idx + 1}`]?.id_type
        const userFields = fieldsIdentification(
          true, 
          `commuter_attributes[commuter-${idx + 1}]`, 
          id_type,
          {customInputClass, customLabelClass, customDropdownInputClass: styles.formDropdownInput, stateList ,countries, initialCountry, t},
          !isEmpty(commuters[`commuter-${idx + 1}`])
        )
        allCommuterFields.push(userFields)
      })
    }
    if(isEmpty(allCommuterFields)) return
    return <React.Fragment>
      {
        allCommuterFields.map((userFields, idx) => {
          
          return (
            
            <React.Fragment key={idx}>
              <CommutersCard 
                key={idx}
                headerClassName={styles.headerClass}
                className={`${styles.collapsable} w-100 pr-2`}
                index={idx}
                openCardIndex={openCardIndex}
                setOpenCardIndex={setOpenCardIndex}
                header={`${t("permit_application.apply_for_permit.commuter")} ${idx + 1}`}
              >
                {
                  userFields.map((fields, index )=> {
                    return (
                      <React.Fragment key={index}>
                        {renderFieldsWithGrid(fields, 2, 6, {...userFieldProps(idx), errors})}
                      </React.Fragment>
                    )
                  })
                }
                <Button
                  type="button"
                  className={styles.addVehicleBtn}
                  onClick={() =>
                    openInnModal(
                      idx + 1,
                      `vehicle_attributes[commuter-${idx + 1}]`
                    )
                  }
                >
                  {!isEmpty(initialFormValues[`commuter-${idx + 1}`])
                    ? `${initialFormValues[`commuter-${idx + 1}`]?.color} ${
                        manufacturers.find(
                          (m) =>
                            m.value ===
                            initialFormValues[`commuter-${idx + 1}`]
                              ?.manufacturer_id
                        )?.label
                      } ${initialFormValues[`commuter-${idx + 1}`]?.model}`
                    :  t("permit_application.apply_for_permit.add_vehicle")}
                  <EditIcon
                    style={{ marginBottom: "5px", marginRight: "2px" }}
                  />
                </Button>
              </CommutersCard>
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  }
 
  const validatePlateAndEmail = async() => {
    setErrors({})
    const plateNo = formApiRef?.current?.getValue('plate_number')
    const vehicleId = formApiRef?.current?.getValue('vehicle_id')
    const userVehiclePlateNo = vehicles.find((v) => v.value === vehicleId)?.label
    try {
      // eslint-disable-next-line no-unused-vars
      const response =  await validateStep2({plate_number:plateNo})
      setPlateNError(false)
    } catch (_error) {
      if(_error?.response?.data?.errors?.plate_number && userVehiclePlateNo !== plateNo){
        setPlateNError(true)
      }else if(_error?.response?.data?.errors?.email){
      }
    }
  }

  const validatePhoneNumbers = () => {
    const usPhoneNumberRegex = /^\+?1[2-9]\d{9}$/;
    const work_phone = formApiRef?.current?.getValue('work_phone')
    const home_phone = formApiRef?.current?.getValue('home_phone')
    setIsUSHomePhone(usPhoneNumberRegex.test(home_phone))
    setIsUSWorkPhone(usPhoneNumberRegex.test(work_phone))
  }

  
  const ownerFieldsProps = {
    lSize: 4,
    iSize: 7,
    events: {onChange: (_e) => validatePlateAndEmail()}
  
  }

  const emailFieldsProps = {
    lSize: 4,
    iSize: 7,
    events: {onChange: (_e) => validatePlateAndEmail()}
  
  }

  const phoneFieldsProps = {
    lSize: 4,
    iSize: 7,
    events: {onChange: (_e) => validatePhoneNumbers()}
  }
  

  const plateFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => fetchVehicle()
    }
  }

  const lpnFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {onChange: (_e) => validatePlateAndEmail()}
  }

  useEffect(() => {
    const getSubscriberCoordinates = () => {
      const apiKey = env.google_cloud_api_key;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${currentSubscriber?.home_address?.postal_code}&key=${apiKey}`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setSubscriberCoordinates({ ltd: lat, lng });
            const locationsVars = {ltd: lat, lng, city: currentSubscriber?.home_address?.city, state: currentSubscriber?.home_address.state, street: currentSubscriber?.home_address?.address1, country: currentSubscriber?.home_address?.country, zip: currentSubscriber?.home_address?.postal_code}
            setCurrentLocation({ltd: lat, lng, city: currentSubscriber?.home_address?.city, state: currentSubscriber?.home_address.state, street: currentSubscriber?.home_address?.address1, country: currentSubscriber?.home_address?.country, zip: currentSubscriber?.home_address?.postal_code, full_address: formatFullAddress(locationsVars) })
          } else {
            console.error('Invalid postal code');
          }
        })
        .catch((error) => {
          console.error('Error fetching geocoding data', error);
        });
    };
    if(!isEmpty(currentSubscriber)){getSubscriberCoordinates();}
  }, [currentSubscriber])

  const formatFullAddress = (address) => {
    return ['street', 'building', 'city', 'country', 'zip'].map(key => {
      return address[key]
    }).filter(Boolean).join(', ')
  }

  useEffect(() => {
    const fetchNearbyParkingLots = async () => {
      setErrors({})
      setAlertMsg('')
      const ptId = formApiRef.current.getValue('permit_type_id')
      if(!permitTypeID){
        return
      }
      try {
        const response = await getParkingLots({ permit_type_id: ptId, subscriberCoordinates , zipcode: currentSubscriber?.home_address?.postal_code });
        setNearbyParkingLots(response.data);
        if(isEmpty(response.data)){setAlertMsg(" ") }
      } catch (error) {
      }
    };
    if(isEmpty(nearbyParkingLots) && !isEmpty(subscriberCoordinates)){fetchNearbyParkingLots();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentSubscriber, subscriberCoordinates, permitTypeID])
  
  
  const fetchCurrentLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      setIsFetchingLocation(true)
      navigator.geolocation.getCurrentPosition(
  
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            // Using OpenStreetMap Nominatim API for reverse geocoding
  
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
  
            if (!response.ok) {
              throw new Error('Failed to fetch address details');
            }
  
            const data = await response.json();
            const city = data.address.city || '';
            const street = data.address.road || '';
            const state = data.address.state || '';
            const country = data.address.country || '';
            const zip = data.address.postcode
  
  
            setCurrentLocation({
              ltd: latitude,
              lng: longitude,
              city,
              street,
              state,
              country,
              zip
            })
  
            setUserLocation({
              ltd: latitude,
              lng: longitude,
              city,
              street,
              state,
              country,
              zip
            })
            setFetchedCurrentLocation(true)
            fetchNearbyParkingLots()
          } catch (error) {
            setErrors(`Error: ${error.message}`);
            setFetchedCurrentLocation(false)
          } finally{
            setIsFetchingLocation(false)
            setErrors({})
          }
        },
        (error) => {
          setErrors(`${error.message}`);
        }
      );
    } else {
      setErrors('Geolocation is not supported by your browser.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentLocation, currentLocation, setErrors]);


  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownsSearch('permit_type_list'))
        setPermitTypes( permitTypesList.data)
      } catch (_error) {
        
      }
    }
    if(isEmpty(permitTypes)) fetchPermitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const fetchNearbyParkingLots = async (values) => {
    setErrors({})
    setAlertMsg('')
    const latitude = values?.ltd;
    const longitude = values?.lng;
    const zipcode = values?.zip;
    const ptId = formApiRef.current.getValue('permit_type_id')
    if(!permitTypeID){
      return
    }
    try {
      const response = await getParkingLots({ permit_type_id: ptId, ltd: latitude? latitude : subscriberCoordinates?.ltd, lng: longitude? longitude : subscriberCoordinates.lng, zipcode: zipcode? zipcode : currentSubscriber?.home_address?.postal_code });
      setNearbyParkingLots(response.data);
      if(isEmpty(response.data)){setAlertMsg(" ") }
    } catch (error) {
    }
  };

  useEffect(() => {
    if(!checkP){
      return
    }
    const checkPermitAvailability = async () => {
      try {
        setErrors({})
        const response = await checkParkingLot({id: selectedParkingLots?.id, name: permitType?.name});
        if(response.status === 200){
          if(response?.data?.status){
            setLotAvailability(false); setAlertMsg(response.data.status.toString())
            
          }else {
            setAlertMsg("Permits are available in the selected parking lot. Please proceed with the application.")
            setLotAvailability(true);
           
          }
        }
      } catch (error) {
      }
    };
    checkPermitAvailability();
  }, [selectedParkingLots, setLotAvailability, checkP, setErrors, permitType]);

  const checkPermitAvailability = async (lot) => {
    try {
      const response = await checkParkingLot({id: lot?.id, name: permitType?.name});
      if(response.status === 200){
        if(response?.data?.status){
          invalidPLots.push(lot)
        }else {
          validPLots.push(lot)
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const fetchPermitType = async () => {
      try {
        const response = await getPermitType(permitTypeID)
  
        setPermitType(response.data)
      } catch (error) {
        console.log(error)
      } finally {
  
      }
    }
    if(permitTypeID){fetchPermitType();}
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permitTypeID, isSaving])

  
  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
  }
  const permitTypeFieldProps = {
    lSize: 4,
    iSize: 8, 
    events: {
      onChange: (_e) => fetchNearbyParkingLots()
    } 
  }


  useEffect(() => {
    const fetchPermitTypeValues = async () => {
      try {
        formApiRef.current.setValue('validity', capitalize(permitType?.validity))
        setPeriodDropdown(permitType?.period)
        formApiRef.current.setValue('parking_hour_from',`${permitType?.parking_hour_from}`)
        formApiRef.current.setValue('parking_hour_to', `${permitType?.parking_hour_to}`)
        formApiRef.current.setValue('hourly_rate', `${permitType?.hourly_rate}`)
        if(permitType?.name === 'Carpool Permit'){formApiRef.current.setValue('commuters_per_vehicle', `${permitType?.commuters_per_vehicle}`)}
        if(permitType?.expiring_permit === 'NO'){formApiRef.current.setValue('total_cost', permitType?.total_cost)}
        formApiRef.current.setValue('permit_type', `${permitType?.name}`)
        formApiRef.current.setValue('period', '')
  
      } catch (error) {
        console.log(error)
      } finally {
  
      }
    }
     if(!isEmpty(permitType)){fetchPermitTypeValues();}
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permitType, isSaving])

  const handleNext = () => {
    setErrors({})
    setFilePresence('')
    setMsg('')
    const validationErrors = validateFields()
   
    if(!isEmpty(validationErrors) || (step ===2 && (!isValidFile || !fileName))){
      if(step===2 && !mute && (!fileName && !filePresence)){setFilePresence(false); setMsg('Please Upload ID Proof !')}else{setFilePresence(true);setAlertMsg('')}
      setErrors(validationErrors)
      return
    }else if(step < 8) {
      setStep(step + 1);
    }   
  };

  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setPermitName(permitType?.name)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permitName])
  
  
  const handlePrevious = () => {
    if (step > 1) {
     setStep(step - 1);
    }
  };

  useEffect(() => {
    formApiRef.current.setValues({'commuter_attributes': commuters})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[commuters])


  const initialValues = {
    parking_hour_from: "",
    full_name: `${currentSubscriber.first_name} ${currentSubscriber.last_name}`,
    email: `${currentSubscriber.email}`,
    birthday: currentSubscriber?.birthday,
    home_phone: currentSubscriber?.home_address?.phone,
    address1: currentSubscriber?.home_address?.address1,
    address2: currentSubscriber?.home_address?.address2,
    city: currentSubscriber?.home_address?.city,
    country_code: 'US',
    state_code: currentSubscriber?.home_address?.state_code,
    postal_code: currentSubscriber?.home_address?.postal_code,
    work_phone: currentSubscriber?.work_address?.phone,
    id_type: currentSubscriber.id_type,
    id_number: currentSubscriber.id_number,
  }

  const attrs = {
    periodOptions: periodDropdown?.map((pr) => { return {value: pr, label: capitalize(pr)}}),
    permit: permit,
    permitTypes: permitTypes,
    manufacturers:  manufacturers,
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInputClass: styles.formDropdownInput,
    customExpiryInputClass: styles.cardInput,
    customVehicleInputClass: styles.plateInput,
    muteManufact,
    t: t,
    months: months,
    subclasses
  }
  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        userLocation={userLocation}
        fetchNearbyParkingLots={fetchNearbyParkingLots}

      />
    );
  }

  const years = Array.from(
    { length: 20 },
    (_, index) => new Date().getFullYear() + index
  );
  

  const getFields = () => {
    return permitFields({mute, ...attrs, renderLocationModal: renderLocationModal.bind(this), identification: idType, permitOption: permit, countries, statesList: stateList, vehicles, customDropdownInputClass: styles.formDropdownInput,customExpiryDropdownInputClass:styles.expiryDropdown, customExpiryLabel: styles.expiryDropdownLabel, cutomExpiryLabel2: styles.cardLabels2, years, initialCountry})
  }
  
  const fetchVehicleDetails = useCallback(async() => {
    setIsFetchingVehicle(true)
    try {
      const response =  await getVehicleDetails(plateNumber)
      if(response.data){setVehicle(response.data.vehicle_details)}
      if(response.data?.vehicle?.toString() === 'Not found'){setVehicle([]);
        toast.error("Vehicle Not Found")
      }

    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingVehicle(false)
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[plateNumber])

  const fetchOwnerDetails = useCallback(async() => {
    const idNumber = formApiRef.current.getValue('id_number')
    setIsFetchingOwner(true)
    try {
      const response =  await getOwnerDetails({id_type: idType, id_number:idNumber})
      if(response.data === null){
        toast.error("User Not Found")
      }
      formApiRef.current.setValue('full_name',`${response.data.first_name} ${response.data.last_name}`)
      formApiRef.current.setValue('home_phone', `${response.data.home_phone}`)
      formApiRef.current.setValue('email', `${response.data.email}`)
      formApiRef.current.setValue('work_phone', `${response.data.work_phone}`)
      formApiRef.current.setValue('address1', `${response.data.address_line1}`)
      formApiRef.current.setValue('state_code', `${response.data.state}`)
      formApiRef.current.setValue('postal_code', `${response.data.zip}`)
      formApiRef.current.setValue('city', `${response.data.city}`)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingOwner(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formApiRef, idType])

  useEffect(() => {

    const fetchVehicleValues = async () => {
      try {
        formApiRef.current.setValue('manufacturer_id',capitalize(vehicle.make))
        formApiRef.current.setValue('year', vehicle.year)
        formApiRef.current.setValue('color', vehicle.color)
        formApiRef.current.setValue('model', vehicle.model)
        formApiRef.current.setValue('registration_state', vehicle.state)
      } catch (error) {
        console.log(error)
      } finally {
  
      }
    }
    if(vehicle){fetchVehicleValues()}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicle, isSaving])
  

  const vehicleFieldProps = {
    lSize: 4,
    iSize: 7,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const vehicleClassProps = {
    lSize: 4,
    iSize: 7,
    events: {
      onChange: (e) => fetchVehicleSubclasses(e)
    }
  }

  const countryFieldProps = {
    lSize: 4,
    iSize: 7,
    events: {
      onChange: (_e) => fetchStates()
    }
  }
  const renderVehicleFields = (fields) => {
    const vehicle_category = formApiRef.current.getValue('vehicle_category')
    
    return(
      <Col className='d-flex flex-wrap px-0' xs={11}>
         
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(0,3), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(3,6), {...vehicleFieldProps, errors})}
        </div>
        
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(6,7), {...vehicleClassProps, errors})}
        </div>

        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
        </div>
        
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(7,8), {...vehicleFieldProps, errors})}
        </div>
        {vehicle_category === 'government' && 
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(9,10), {...vehicleFieldProps, errors})}
          </div>
        }
        <div className={vehicle_category === "government" ? `${styles.fieldset} pl-2` : `${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
        </div>
      </Col>
    )
    }
  
  const renderVehicleOwnerFields = (fields) => {
    return(
      <Col className='d-flex flex-wrap px-0 pl-1' xs={11}>       
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(0,2), {...ownerFieldsProps, errors})}
          {renderFields(fields.slice(2,3), {...phoneFieldsProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(3,4), {...emailFieldsProps, errors})}
          {renderFields(fields.slice(4,5), {...phoneFieldsProps, errors})}
          {renderFields(fields.slice(5,6), {...ownerFieldsProps, errors})}
        </div>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(6,7), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(7,8), {...countryFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(9,10), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
        </div>
      </Col>
    )
  }

  const renderIDFields = (fields) => {
    return(
      <Col className='d-flex flex-wrap px-0 pl-1' xs={11}>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(0,1), {...ownerFieldsProps, errors})}
        </div>
        {idType && <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(1,2), {...ownerFieldsProps, errors})}
        </div>}
      </Col>
    )
  }

    

  const cardMFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_month')
    }
  }
  const cardYFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_year')
    }
  }


  const fields = vehicleFields(attrs, {identification: idType, mute: muteManufact })

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    if(selectedFile){
      setFilePresence(true)
    }
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
    if (!validFileTypes.includes(selectedFile?.type)) {
      setIsValidFile(false)
      return;
    }else {
      setFileName(e.target.files[0]?.name)
      setIsValidFile(true)
      getBase64(fileRef.current.files[0])
    }
    
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if(e.dataTransfer.files.format === '.pdf' || e.dataTransfer.files.format === '.png' || e.dataTransfer.files.format === '.jpg'){
        setFileName(e.dataTransfer.files[0].name)
        fileRef.current = { files: e.dataTransfer.files }
      }
    }
  };
  const paymentFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (_e) => showPaymentOption()
    }
  }

  const showPaymentOption = () =>{
    if (formApiRef.current?.getValue('payment_methods')===0){
      setGooglePayShow(true)
      setShowCreditCardFields(false)
  
    }else if (formApiRef.current?.getValue('payment_methods')===1) {
      setShowCreditCardFields(true)
      setGooglePayShow(false)
    }else{
    }
  }

  const setFieldsVisibility=() =>{
    if(mute){
    setMute(false)
    }else if(!mute){
      setMute(true)
  
    }
  }


  useEffect(() => {
    const vp = formApiRef?.current?.getValue('period')
  
    const fetchTotalCost= async () => {
      if(!checkV){
        return
      }
      try {
        const response = await getTotalCost({permit_type_id: permitTypeID, validity_period: vp  })
        setTotalCost(response.data)
        if(permitType.expiring_permit === 'YES'){formApiRef.current.setValue('total_cost', response.data)}
        if(permitType.expiring_permit === 'YES'){formApiRef.current.setValue('permit_cost', response.data)}else{formApiRef.current.setValue('permit_cost', permitType?.total_cost)}
        formApiRef.current.setValue('payment_amount', response?.data)
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
    if(permitTypeID && vp){fetchTotalCost();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permitTypeID,permitTypePeriod, checkV])

  const fetchVehicle= async () => {
    setErrors({})
    const vehicleId = formApiRef.current.getValue('vehicle_id')
    const plateN = vehicles.find((v) => v.value === vehicleId)?.label
    try {
      const response =  await fetchVehicleData(plateN)
      if(response.data){setVehicle(response.data)}
      formApiRef.current.setValue('plate_number',response.data[0]?.plate_number)
      formApiRef.current.setValue('manufacturer_id',response.data[0]?.manufacturer.name)
      formApiRef.current.setValue('year', response.data[0]?.manufacture_year)
      formApiRef.current.setValue('color', response.data[0]?.color)
      formApiRef.current.setValue('model', response.data[0]?.model)
      formApiRef.current.setValue('registration_state', response.data[0]?.registration_state)
      formApiRef.current.setValue('vehicle_category', response.data[0]?.category)
      formApiRef.current.setValue('vehicle_class', response.data[0]?.vehicle_class)
      formApiRef.current.setValue('vehicle_type', response.data[0]?.vehicle_type)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  const submitValues = (values) => {
    const storageKey = 'allCommutersData';
    const initialFormValues = JSON.parse(localStorage.getItem(storageKey)) || {};
    const commuterVals =  values?.commuter_attributes 
    const updatedCommuterAttributes = commuterVals? Object.keys(commuterVals)?.reduce((acc, idx) => {
      const commuterHash = commuterVals[idx];
      const updatedCommuterHash = {
        ...commuterHash,
        vehicle_attributes: initialFormValues[`${idx}`], // Add or update attributes here
      };
      // Updating the accumulator with the updated commuter hash
      return {
        ...acc,
        [idx]: updatedCommuterHash,
      };
    }, {}) : ''
    const arrayOfCommuters = Object.entries(updatedCommuterAttributes).map(([commuterId, commuterData]) => ({
      [commuterId]: commuterData,
    }));
  
    const data = { 
      category: values?.category,
      permit_type_id: values?.permit_type_id,
      parking_lot_id: selectedParkingLots?.id,
      validity_period:values?.period.toString(),
      total_cost: couponApplied? values?.payment_amount?.toString() : values?.permit_cost.toString(),
      start_date: values?.applied_from_date,
      auto_renewable: `${checkboxref.current.checked}`,
      vehicle_attributes: {
        registration_state: values?.registration_state,
        color: values?.color,
        plate_number: values?.plate_number,
        model: values?.model,
        manufacture_year: values?.year,
        category: values?.vehicle_category,
        vehicle_class: values?.vehicle_class,
        institution_name: values?.institution_name
        // manufacturer_id: values?.vehicle?.manufacturer_id,
        // id: vehicle?.id
      },
      primary_user_attributes: {
        vehicle_lpn: values?.plate_number,
        full_name: values?.full_name,
        id_type:  values?.id_type,
        id_number: values?.id_number,
        home_phone: values?.home_phone,
        work_phone: values?.work_phone,
        email: values?.email,
        home_address: values?.address1,
        birthday: values?.birthday,
        id_proof: base64IdProof
  
      },
      home_address:{
        address1: values.address1,
        address2: values.address2,
        phone: values.home_phone,
        city: values.city,
        country_code: values.country_code,
        state_code: values.state_code,
        postal_code: values.postal_code
      },
      commuter_attributes: arrayOfCommuters? arrayOfCommuters : '',
      gateway_params:{
        amount: couponApplied? values?.payment_amount?.toString() : values?.permit_cost.toString(),
        credit_card_attributes:{
          number:  creditCardNum?.current?.value,
          cvv: cvv?.current?.value,
          holder_name: holder_name?.current?.value,
          expiration_month: values?.expiration_month,
          expiration_year: values?.expiration_year,
          should_store: savecheckboxref.current.checked ? 1 : 0
        },
        
      }
    }
    
    applyPermitApplication(data)
      
  }

  const submitValuesGPay =(paymentToken) => {
    const values = formApiRef?.current?.getValues()
    const storageKey = 'allCommutersData';
    const initialFormValues = JSON.parse(localStorage.getItem(storageKey)) || {};
    const commuterVals =  values.commuter_attributes 
    const updatedCommuterAttributes = commuterVals? Object.keys(commuterVals)?.reduce((acc, idx) => {
      const commuterHash = commuterVals[idx];
      const updatedCommuterHash = {
        ...commuterHash,
        vehicle_attributes: initialFormValues[`${idx}`], // Add or update attributes here
      };
      // Updating the accumulator with the updated commuter hash
      return {
        ...acc,
        [idx]: updatedCommuterHash,
      };
    }, {}) : ''
    const arrayOfCommuters = Object.entries(updatedCommuterAttributes).map(([commuterId, commuterData]) => ({
      [commuterId]: commuterData,
    }));
  
    const data = { 
      category: values?.category,
      permit_type_id: values?.permit_type_id,
      parking_lot_id: selectedParkingLots?.id,
      validity_period:values?.period.toString(),
      total_cost: couponApplied? values?.payment_amount?.toString() : values?.permit_cost.toString(),
      start_date: values?.applied_from_date,
      auto_renewable: `${checkboxref.current.checked}`,
      vehicle_attributes: {
        registration_state: values?.registration_state,
        color: values?.color,
        plate_number: values?.plate_number,
        model: values?.model,
        manufacture_year: values?.year,
        category: values?.vehicle_category,
        vehicle_class: values?.vehicle_class,
        institution_name: values?.institution_name
        // manufacturer_id: values?.vehicle?.manufacturer_id,
        // id: vehicle?.id
      },
      primary_user_attributes: {
        vehicle_lpn: values?.plate_number,
        full_name: values?.full_name,
        id_type:  values?.id_type,
        id_number: values?.id_number,
        home_phone: values?.home_phone,
        work_phone: values?.work_phone,
        email: values?.email,
        home_address: values?.address1,
        birthday: values?.birthday,
        id_proof: base64IdProof
  
      },
      home_address:{
        address1: values.address1,
        address2: values.address2,
        phone: values.home_phone,
        city: values.city,
        country_code: values.country_code,
        state_code: values.state_code,
        postal_code: values.postal_code
      },
      commuter_attributes: arrayOfCommuters? arrayOfCommuters : '',
      gateway_params:{
        amount: couponApplied? values?.payment_amount?.toString() : values?.permit_cost.toString(),
        digital_wallet_attributes: {
          encryptionhandler: 'EC_GOOGLE_PAY',
          devicedata: paymentToken?.paymentMethodData?.tokenizationData?.token
        }      
      }
    }
  
    applyPermitApplication(data)
  }
  
  
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesList = await startFetching(dropdownsSearch('countries_list'))
        setCountries(countriesList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(countries)) fetchCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const manufacturersList = await startFetching(dropdownsSearch('manufacturers_list'))
        setManufacturers(manufacturersList.data)
        setVManufacturers(manufacturersList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(manufacturers)) fetchManufacturers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchStates = async () => {
    setErrors({})
    formApiRef.current.setValue('state_code', '')
    const country = formApiRef.current.getValue('country_code')
    try {
      const statesList = await fetchStatesList(country? country : 'US')
      setStateList(statesList.data)
    } catch (_error) {
    }
  }


  useEffect(() => {
    fetchStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchVehicleSubclasses = async (e) => {
    try {
      const vehicleSubclassList = await dropdownsSearch("vehicle_sub_classes_list", {
        vehicle_class: e,
      });
      setSubClasses( vehicleSubclassList?.data)
    } catch (_error) {
      console.log(_error)
    }  
  }

  const applyCouponCode = useCallback(async() => {
    setCouponApplied(false)
    setValidateCoupon('')
    try {
      setApplyingCoupon(true)
      const response =  await applyCoupon({coupon_code: couponRef?.current?.value , total_cost: totalCost})
      if(response.status===201 || response.status===200){
      setCouponApplied(true)
      formApiRef.current.setValue("coupon_discount", (formApiRef.current.getValue('permit_cost') - response.data))
      formApiRef.current.setValue('payment_amount', response?.data)
      }
          
    } catch (error) {
      
      setValidateCoupon(error?.response?.data?.errors?.permit_application? error?.response?.data?.errors?.permit_application[0]
        : error.response.data.errors.coupon? error.response.data.errors.coupon[0] : error.response.data.errors.total_cost? error.response.data.errors.total_cost[0] : '')
      console.log(error)
    } finally {
      setApplyingCoupon(false)
    }  
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[totalCost])
  
  useEffect(() => {
    if(coupon && !couponApplied){formApiRef.current.setValue("payment_amount", totalCost)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponApplied])

  const handleExpiryYearChange  = (e) => {
    setExpiryY(e) ;
    setExpiryM('')
    formApiRef.current.setValue('expiration_month', '')
    if(e > new Date().getFullYear()){
      setMonths([1,2,3,4,5,6,7,8,9,10,12])
    } else {
      setMonths(months.filter((m) => {return m > (new Date().getMonth() + 1)}))
    }
  }
  const handleCardFieldsChange = (value, field) => {
    const updatedErrors = Object.keys(errors)
      .filter((objKey) => objKey !== field)
      .reduce((newObj, key) => {
        newObj[key] = errors[key];
        return newObj;
      }, {});

    setErrors(updatedErrors);
    if (field === "credit_card_number") {
      setCardNumber(value);
    } else if (field === "holder_name") {
      setName(value);
    } else if (field === "expiration_month") {
      setExpiryM(value);
      setFocus("expiry");
    } else if (field === "expiration_year") {
      handleExpiryYearChange(value);
    } else if (field === "cvc") {
      setCvc(value);
    }
  };

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form initialValues={initialValues}  getApi={setFormApi} className={styles.form}  onSubmit={submitValues} >
        {({ formState }) => {
          const {permit, category, permit_type_id, total_cost, plate_number, id_type, period, location,manufacturer_id, country_code, commuters_per_vehicle, payment_amount, permit_cost} = formState.values
          setIDType(id_type)
          manufacturer_id === manufacturers?.length ? setMuteManufact(false) : setMuteManufact(true)
          setInitialCountry(country_code)
          setPermitTypeID(permit_type_id)
          setPermit(permit)
          setPlateNumber(plate_number)
          if(period){setPermitTypePeriod(period)}
          const newFields = getFields()
          return (
            <Row className='mx-auto w-99'>
              <Row className={step === 1 ? `m-0 p-0 ${styles['fieldset-double']}   transition-container fade-in` : 'd-none transition-container fade-out'}>
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1.7`} xs={12}>
                  { renderFields(newFields[3], { ...permitTypeFieldProps, errors} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                  { renderFields(newFields[2], { ...permitTypeFieldProps, errors} )}
                </Col>
                { permitType?.name==='Carpool Permit' && 
                <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                  { renderFields(newFields[12], { ...permitTypeFieldProps, errors} )}
                </Col>
                }
                <Row className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                  <p className={styles.noteLabel}>{t("permit_application.apply_for_permit.labels.step1.please_select_location")}</p>
                </Row>
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                  { renderFields(newFields[1], { ...doubleFieldProps, errors} )}
                  <Row className={styles.currentLoc}>
                    <Button type='button' className={isFetchingLocation? styles.fetching : styles.locationbtn} onClick={fetchCurrentLocation}>
                      { isFetchingLocation? 
                      `${t("permit_application.apply_for_permit.labels.step1.fetching_user_location")}..` : 
                        t("permit_application.apply_for_permit.labels.step1.current_location")} <CrosshairsIcon style={{marginTop:"-2px"}}/>
                    </Button>
                  </Row>
                </Col>
                <label className={currentLocation && permit_type_id && category && (location || fetchedCurrentLocation) ? styles.formLabel : 'd-none'} style={{marginLeft: "-5px", fontWeight: 1000, fontSize:"13px"}}>
                  {t("permit_application.apply_for_permit.labels.step1.parking_lot_nearby")}:
                </label>                
                <Row className={currentLocation && permit_type_id && category && (location || fetchedCurrentLocation) ? `mx-auto w-100`: 'd-none'}>
                  <Col className="d-flex flex-wrap justify-content w-100">
                  <div className={styles.scrollableContainer}>
                      {nearbyParkingLots?.map((parkingLot) => (
                        <div
                          key={parkingLot.name}
                          className={validPLots && validPLots?.includes(parkingLot)? `${styles.validParkingLotBox} ${
                            selectedParkingLots && selectedParkingLots.id === parkingLot.id
                              ? styles.selectedParkingLot
                              : ''
                          }` : invalidPLots && invalidPLots?.includes(parkingLot)? `${styles.invalidParkingLotBox} ${
                            selectedParkingLots && selectedParkingLots.id === parkingLot.id
                              ? styles.selectedParkingLot
                              : ''
                          }` : `${styles.parkingLotBox} ${
                            selectedParkingLots && selectedParkingLots.id === parkingLot.id
                              ? styles.selectedParkingLot
                              : ''
                          }` }
                          onClick={() => handleCheckboxChange(parkingLot)}
                        >
                        <span>
                         <div className={styles.parkingLotCell}>
                             <input
                               type="checkbox"
                               checked={selectedParkingLots===parkingLot}
                               onChange={() => handleCheckboxChange(parkingLot)}
                               disabled={(!selectedParkingLots=== parkingLot && state.disableCheckboxes) }
                               label={`${parkingLot.name}`}
                               className={styles.customCheckStyle}
                             /><br/>
                             <span style={{textAlign: 'left'}}>                              
                               <span style={{fontSize:"15px", fontWeight:500, color:"grey", position:"relative"}}>{parkingLot.name}     ({Math.round(((parseFloat(parkingLot.distance.split(" ")[0]) * 0.000621371192) + Number.EPSILON) * 100)/100 } mi)</span> 
                               <br/> {parkingLot.address}
                             </span>
                         </div>
                        </span>
                      </div>
                      ))}
                    </div>
                    
                    {alertMsg && lotAvailablity  &&
                      <p className={`${styles.notify}`}>{alertMsg}</p>
                    }
                    {
                      alertMsg && !lotAvailablity &&
                      <p className={`${styles.alertMsg}`}>{alertMsg}</p>
                    }
                  </Col>
                </Row>
                { map(newFields[4], (field, idx) => {
                  return (
                    <React.Fragment>
                      {permitType?.expiring_permit === 'YES' &&
                        <Col key={idx} className={ (subscriberCoordinates || location || fetchedCurrentLocation) &&  permit_type_id && category ? `m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2 pr-0` : 'd-none'} xs={6}>
                        { renderField(field, { ...valPrProps, errors} )}
                        </Col>
                      }
                    </React.Fragment>
                  )
                })}

              { map(newFields[5], (field, idx) => {
                if(idx === 2) {
                  field = {
                    ...field,
                    icon: dollarIcon(), 
                    customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                  }
                }
                if(idx === 3) {
                  field = {
                    ...field,
                    icon: dollarIcon(), 
                    customInputClass: attrs.customInputClass.concat(' ', styles.iconInput),
                  }
                }
               
                return (
                  <React.Fragment>
                    {permitType?.expiring_permit === 'NO' && idx !== 2  &&
                      <Col key={idx} className={(subscriberCoordinates || location || fetchedCurrentLocation) && permit_type_id && category ? `m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2` : 'd-none'} xs={6}>
                      { renderField(field, { ...rateCostProps, errors} )}
                      </Col>
                    }
                    {permitType?.expiring_permit === 'YES' &&
                      <Col key={idx} className={(subscriberCoordinates || location || fetchedCurrentLocation) && permit_type_id && category ?`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2` : 'd-none'} xs={6}>
                      { renderField(field, { ...rateCostProps, errors} )}
                      </Col>
                    }
                    
                  </React.Fragment>
                )
              })
              }
              {(subscriberCoordinates || location || fetchedCurrentLocation) && permit_type_id && total_cost && <Row className={styles.spacesSection}>
                <Col className={styles.infoSection}>
                  <p>{t("permit_application.apply_for_permit.share_permit_note")}</p>
                </Col>
                <Col>
                  <Button type='button' className={styles.addBtn} icon={<PlusIcon className={`svg-white ${styles.addIcon}`}/>} onClick={() => openInnerModal('share_permit')}>{t("buttons.add")}</Button>
                </Col>
              </Row>}
              </Row>
              <Row className={step === 2  ? `mx-auto w-98` : `d-none`}>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pr-3`} xs={12}>
                    { renderFields(newFields[11], { ...plateFieldProps, errors} )}
                </Col>
               
                <div className="d-flex align-items-center my-3 w-100">
                  <span className={styles.detailsLabel}>{t("permit_application.apply_for_permit.labels.step2.vehicle_details")}</span>
                  <span className="border border-1  ml-1"></span>
                </div>
                <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                  { renderFields(newFields[6], { ...lpnFieldProps, errors} )}
                  <Button type='button' className={errors?.plate_number ?`${styles.okBtnErrorStyle}`:`${styles.okBtn}`}  onClick={fetchVehicleDetails}>{isFetchingVehicle? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}</Button>
                </Col>
               
                <Row className={`m-0 p-0 pl-1`} xs={12}>
                  
                  {renderVehicleFields(fields[0])}
                </Row>
                <div className="d-flex align-items-center my-3 w-100">
                  <span className={styles.detailsLabel}>{t("permit_application.apply_for_permit.labels.step2.owner_details")}</span>
                  <span className={styles.detailsLabel}> <Button type='button' className={styles.editBtn}  onClick={setFieldsVisibility}>{mute ?'Edit' : "Done"}</Button></span>
                </div>
                <Col className="m-0 p-0  pl-1 pt-2" xs={12}>
                  {renderIDFields(newFields[7])}
                </Col>
                {!mute && idType &&
                 <Button type='button' className={ errors?.id_number ? styles.fetchBtnErrorStyle : styles.fetchBtn}  onClick={fetchOwnerDetails}>{isFetchingOwner? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}</Button>
                }
                <Col className="m-0 p-0  pl-1" xs={12}>
                  {renderVehicleOwnerFields(newFields[8])}
                </Col>
                {!mute &&
                  <Row className={styles.idProofSection} onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                    <Col className={styles.idProofLabel} xs={6}>
                      <input ref={fileRef} name='id_proof' className='d-none' id='id-proof-file-upload' type='file' accept='.jpg, .png, .jpeg, .pdf' onChange={showFileName}/>
                      <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel2} d-flex flex-column h-20 w-30 text-justify p-1`}>
                        <span>
                          <span className={styles.boldText2}>
                            <Trans
                              i18nKey="permit_application.apply_for_permit.labels.step2.please_upload_id.label"
                              components={[<br key="1" />]}
                            />
                          </span>
                          
                        </span>
                      </Label>
                      </Col>
                      <Col className={styles.idProofField} xs={6}>
                        <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}>
                          <span className='mb-2'>
                            <CloudDownloadIcon />
                          </span>
                          { fileName && <span>{fileName}</span> }
                          <span>
                            <span className={styles.boldText1}>
                              {t("permit_application.apply_for_permit.uploadText1")}
                            </span>
                            <span className='ml-1'>
                              {t("permit_application.apply_for_permit.uploadText2")}
                            </span>
                          </span>
                          <p className={styles.formatsText}>{t("permit_application.apply_for_permit.formatsText")}</p>
                          {!isValidFile && 
                            <p className={styles.formatsErrorText}>Please Upload Valid File Format</p>
                    
                          }
                          {!filePresence && 
                            <p className={styles.formatsPresenceErrorText}>{msg}</p>
                          }
                        </Label>
                    </Col>
                  </Row>
                }
              </Row>
              
              <Col className={step === 3 && permitType?.name === 'Carpool Permit' ? `` : 'd-none'} xs={12}>
                <p className={styles.commuterTitle}> {t("permit_application.apply_for_permit.commuter_title")} </p>
                { renderCommuters(commuters_per_vehicle, formState.values.commuter_attributes) }
              </Col>
              <Col>
                <Row className={(step === 3 && permitType?.name !== 'Carpool Permit') || (step === 4 && permitType?.name === 'Carpool Permit') ? `flex-column w-100 text-center` : 'd-none'}>
                <p className={`${styles.modalTitle}`}> {t("permit_application.apply_for_permit.labels.step3.title")} </p>
                    
                    { map(newFields[9], (field, idx) => {
                        if(idx === 1) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        return (
                          <React.Fragment>
                            { idx === 0 && 
                              <Col className={`m-0 pt-5 ${styles['fieldset-double-step3']} pl-1`} xs={12}>
                                { renderField(field, { ...paymentFieldProps, errors} )}
                              </Col>
                            }
                            {idx === 1 && payment_amount !== '0.0' &&
                              <Col className={`m-0 ${styles['fieldset-double-step3']} pl-1`} xs={12}>
                                { renderField(field, { ...paymentFieldProps, errors} )}
                              </Col> 
                            }
                            
                          </React.Fragment>
                        )
                      })
                      }
                      <Row className={styles.autoRenewRow} xs={12}>
                          <Col className={styles.renewCol} xs={6}>
                            <div className={styles.renewCheckCol}>
                              <input
                                type="checkbox"
                                checked={autoRenew === true }
                                onChange={() =>  setAutoRenew(autoRenew? false : true) }
                                ref={checkboxref}
                                className={`${styles.uniqueCheckbox} ${autoRenew ? styles['style-secondary'] : ''}`}
                              />
                            </div>
                            <div className={styles.renewLabelCol} xs={4}>
                              <label className={styles.checkboxTextRenew}> 
                                <span className={styles.autorenewLabell}>{t("permit_application.apply_for_permit.labels.step3.auto_renew", {days: permitTypePeriod?.split(" ")[0]})}</span>
                              </label>
                            </div>
                          </Col>
                          <Col className={styles.applyCol} xs={6}>
                          {permit_cost > 0.0 && <React.Fragment>
                            <div className={styles.couponLabelCol} >
                              <label className={styles.applyLabel}>{t("permit_application.apply_for_permit.labels.step3.apply_coupon")}</label>
                            </div>
                            <div className={styles.couponInputCol}>
                              <input
                                type="text"
                                id="apply_coupon"
                                name="apply_coupon"
                                placeholder={t("permit_application.apply_for_permit.labels.step3.enter_coupon_code")}
                                value={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                                className={styles.couponInput}
                                ref={couponRef}
                              />
                                          
                              <Button 
                                type='button' 
                                className={styles.applyCBtn}  
                                disabled={!coupon}
                                onClick={applyCouponCode}>
                                  {applyingCoupon? btnSpinner({ className: 'spinner-border' }) : 
                                    t("permit_application.apply_for_permit.labels.step3.apply")}
                              </Button>
                            </div>
                          </React.Fragment>}
                          </Col>
                          <span className={!couponApplied ? styles.couponValidation : styles.couponApplied}>{validateCoupon? t("permit_application.apply_for_permit.labels.step3.invalid_coupon"): couponApplied? t("permit_application.apply_for_permit.labels.step3.coupon_applied") : ""}</span>
                        </Row>
                      
                    
                      { totalCost && totalCost > 0 && 
                       map(newFields[9], (field, idx) => { 
                        if(idx === 2) {
                          field = {
                            ...field,
                            icon: reddollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        if(idx === 3) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                         
                        return (
                          <>
                            { idx === 2 && couponApplied && 
                              <Col className={`m-0 pt-5 ${styles['fieldset-double']} pl-1`} xs={12}>
                                { renderField(field, { ...paymentFieldProps, errors} )}
                              </Col>
                            }
                            {idx === 3 &&
                              <Col className={couponApplied ? `m-0 ${styles['fieldset-double']} pl-1`: `m-0 ${styles['fieldset-double']} pl-1 pt-3 mt-2`} xs={12}>
                                { renderField(field, { ...paymentFieldProps, errors} )}
                              </Col> 
                            }
                            {idx === 4 && payment_amount > 0.0 &&
                              <Col className={`m-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                                { renderField(field, { ...paymentFieldProps, errors} )}
                              </Col> 
                            }
                            
                          </>
                        )
                      })
                      }
                    {showCreditCardFields && !showGooglePay &&
                     
                      <div className={styles.cardContainer}>
                        <div className={styles.cardPreview}>
                          <Cards
                            number={cardNumber}
                            name={name}
                            expiry={`${expiryM}${expiryY}`}
                            cvc={cvc}
                            focused={focus}
                          />
                        <div className={cardNumber?`${styles.saveData}`: 'd-none'}>
                          <label className={`${styles.savecheckboxText}`}>
                            <input
                              type="checkbox"
                              checked={saveCard === true }
                              onChange={() =>  setSaveCard(saveCard? false : true) }
                              ref={savecheckboxref}
                              className={`${styles.checkbox} ${saveCard ? styles['checkbox-active'] : ''}`}
                            />
                            <span className={styles.saveCheckText}>{`Save Credit Card Details For Future Use`}</span>
                          </label>
                        </div>
                        </div>
                        <div className={styles.cardInputs}>
                          <label className={styles.cardLabels}>{t("events.city_events.get_pass_form.payment.credit_card.card_number.label")}</label>
                          <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'credit_card_number'}}>                          
                            <input
                              type="tel"
                              id="credit_card_number"
                              name="credit_card_number"
                              placeholder="Card Number"
                              maxLength={16}
                              value={cardNumber}
                              onChange={(e) => handleCardFieldsChange(e.target.value, 'credit_card_number')}  
                              onFocus={() => setFocus('number')}
                              className={styles.cardInput}
                              ref={creditCardNum}
                            />
                          </ErrorWrapper>
                          <label className={styles.cardLabels}>{t("events.city_events.get_pass_form.payment.credit_card.holder_name.label")}</label>
                          <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'holder_name'}}>
                            <input
                              type="text"
                              id="holder_name"
                              name="holder_name"
                              placeholder="Holder Name"
                              value={name}
                              onChange={(e) => handleCardFieldsChange(e.target.value, 'holder_name')}
                              onFocus={() => setFocus('name')}
                              className={styles.cardInput}
                              ref={holder_name}
                              required={showCreditCardFields && !showGooglePay}
                            />
                          </ErrorWrapper>                          
                          { map(newFields[10], (field, idx) => {
                            return (
                              <React.Fragment>
                                {idx=== 0 &&
                                renderField(field, { ...cardYFieldProps, errors} )}
                                {idx===1 &&
                                  renderField(field, { ...cardMFieldProps, errors} )
                                }
                              </React.Fragment>
                            )
                          }) }

                          <label htmlFor="cvc" className={styles.cardLabels}>{t("events.city_events.get_pass_form.payment.credit_card.cvc.label")}</label>
                          <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'cvc'}}>                          
                            <input
                              type="tel"
                              id="cvc"
                              name="cvc"
                              placeholder="CCV"
                              value={cvc}
                              onChange={(e) => setCvc(e.target.value)}
                              onFocus={() => setFocus('cvc')}
                              className={styles.cardInput}
                              maxLength={3}
                              ref={cvv}
                              required={showCreditCardFields && !showGooglePay}
                            />
                          </ErrorWrapper>
                          </div>
                        </div>
                      
                    }
                  <div centered>
                    {showGooglePay  && <GooglePayButton
                    environment="TEST"
                    paymentRequest={GooglePayDetails(payment_amount)}
                    onLoadPaymentData={paymentRequest => {
                      submitValuesGPay(paymentRequest)
                    }}
                    buttonType='pay'
                    className={styles.gpayBtn}
                  />}
                  </div>
                </Row>
              </Col>
              <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
                {step > 1 &&
                <Button onClick={handlePrevious} type='button' className={`${styles.button} ${styles['button-bg-secondary']} ${!showCreditCardFields ? styles.prevBtn : ''} mr-4`}>                  
                  {t("buttons.previous")}
                </Button>
                }
                { step === 1 &&
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    {t("buttons.cancel")}
                  </Button>
                }
               
                { ((step < 3 && permitType?.name !== 'Carpool Permit') || (step < 4 && permitType?.name === 'Carpool Permit') ) && 
                  <Button type='button' className={styles.button}  onClick={handleNext}>
                    {t("buttons.next")}
                  </Button>
                }
                {((showCreditCardFields && ((step === 3 && permitType?.name !== 'Carpool Permit') || (step === 4 && permitType?.name === 'Carpool Permit'))) || (permit_cost === 0.0 && ((step === 3 && permitType?.name !== 'Carpool Permit') || (step === 4 && permitType?.name === 'Carpool Permit')))) &&
                <Button type='submit' className={styles.button}>
                  {isSaving ? btnSpinner({ className: 'spinner-border' }) : t("buttons.submit")}
                </Button>
                }
               
              </Col>
            </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}


export default withFetching(Apply);
