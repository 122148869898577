import React, { useCallback, useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import ActivateDeactivate from '../activate_deactivate';
import Edit from '../edit';
import Delete from '../delete';
import { SET_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/violators';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { update, create, destroy } from 'api/violators';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Create from '../create';
import { flatten, values } from 'underscore';

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const ViolatorModal = (props) => {
  const { modalType, openModal, selectedRecord, addListElement } = props
  const [errors, setErrors] = useState({})
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const dispatch = useDispatch();
  
  const closeModal = useCallback(() => {
    openModal(null)
  },[openModal])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const setListElement = useCallback((payload) => {
    dispatch({type: SET_LIST_ELEMENT, payload});
  }, [dispatch]);

  const popListElement = useCallback((payload) => {
    dispatch({type: POP_LIST_ELEMENT, payload});
  }, [dispatch])

  const message = useCallback(() => {
    return(
      <React.Fragment>
        <span className='mr-1'>The measure</span>
        <span >{selectedRecord.measure_type.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}</span>
        <span className='mx-1'>for violation type</span>
        <span >{selectedRecord?.violation_type?.display_name}</span>
        <span className='mx-1'>is successfully</span>
        <span>{`${selectedRecord.status === 'active' ? 'deactivated' : 'activated' }.`}</span>
      </React.Fragment>
    )
  },[selectedRecord]  );

  const toggleStatus = useCallback(async (attrs) => {
    try {
      const response  = await update({data: attrs, id : selectedRecord.id})
      closeModal()
      addAlertMessages([{
        type: response.data.status === 'active' ? 'primary' : 'danger',
        text: message(),
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[addAlertMessages, setListElement, closeModal, selectedRecord, message])

  const createMeasure = useCallback(async (data) => {
    try {
      const response = await create({data: {parking_measure: data}})
      if(response.status === 200 || response.status === 201 )
      {
        addListElement({setList: setList, data: response.data})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: 'Automatic Measure Created.',
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addAlertMessages, setListElement, selectedRecord, closeModal])
  
  const updateMeasures = useCallback(async (data) => {
    try {
      const response  = await update({data: data, id: selectedRecord.id })
      if(response.status=== 200 || response.status===201){
        setListElement(response.data)
        closeModal();
        addAlertMessages([{
          type: 'primary',
          text: `The measure ${response?.data?.measure_type.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))} for violation type ${response?.data?.violation_type?.display_name} was succesfully edited`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
      console.log(error)
    }
  },[addAlertMessages, setListElement, selectedRecord, closeModal,setErrors])

  const deleteMeasure = useCallback(async () => {
    try {
      const response  = await destroy({id: selectedRecord.id}) 
      if(response.status=== 200 || response.status===201){
        popListElement({id: selectedRecord.id})
        closeModal()
        addAlertMessages([{
          type: 'danger',
          text: `The measure with ID ${selectedRecord?.id} was succesfully deleted`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      closeModal()
      addAlertMessages([{
        type: 'danger',
        text:  flatten(values(error?.response?.data.errors)),
        onlyMessage: true,
      }], 'center')
    }

  },[popListElement, addAlertMessages, closeModal, selectedRecord])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'activate_deactivate':
        return { title: `Measure ${selectedRecord.status === 'active' ? 'Deactivation' : 'Activation' }`, maxWidth}
      case 'create':
        return { title: 'Violators Management', maxWidth: '580px'}
      case 'edit':
        return { title: `Edit Measure`, maxWidth: 580}
      case 'delete':
        return { title: `Delete Measure`, maxWidth: 480}
      default:
        return {maxWidth}
    }
  },[modalType, selectedRecord]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered
      className={styles.violationModal}
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody className='pb-4'>
      <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'activate_deactivate' && 
          <ActivateDeactivate
            selectedRecord={selectedRecord}
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={`${selectedRecord.status === 'active' ? 'deactivate' : 'activate'}`}
          />
        }
        {
          modalType === 'create' && 
          <Create
            {...props}
            closeModal={closeModal}
            setErrors={setErrors}
            errors={errors}
            createMeasure={createMeasure}
          />
        }
        {
          modalType === 'edit' && 
          <Edit
            {...props}
            selectedRecord={selectedRecord}
            closeModal={closeModal}
            updateMeasures={updateMeasures}
          />
        }
        {
          modalType === 'delete' && 
          <Delete
            {...props}
            selectedRecord={selectedRecord}
            closeModal={closeModal}
            deleteMeasure={deleteMeasure}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default ViolatorModal;
