import React from 'react';
import PropTypes from 'prop-types';
import Index from 'components/pages/select_lists';

import { Route, withRouter } from 'react-router';

const Routing = ({ match }) => (
  <React.Fragment>
    <Route exact path={match.path} component={Index} />
  </React.Fragment>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);