import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';

const Confirmation = ({closeModal, permitName, t}) => {

  const message = () => 
    <React.Fragment>
      <br/>
      <span className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}>{t("permit_application.apply_for_permit.confirmation_msg", {permitName: permitName})} </span>
    </React.Fragment>

  return (
    <Container className='p-0 m-0'>
      <Col className="d-flex justify-content-center my-2 pb-2">
        <SuccessIcon/>
      </Col>
      <span className='mx-1' style={{fontWeight:"", marginTop:"10%"}}>{t("permit_application.apply_for_permit.success_msg")}</span>
      <Container className='mb-4' >
        <Col className="d-flex justify-content-center my-2">
          { message() }
        </Col>
        <br/>
        <Col className="d-flex justify-content-center my-2">
          {
            <Button 
              type='button' 
              onClick={() => {closeModal()}} 
              className={`${styles.confirmBtn}`}
            >
                {t("buttons.ok")}
            </Button>
          }  
        </Col>
      </Container>
  </Container>
  )
}

export default Confirmation;
