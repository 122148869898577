import React, { useEffect, useCallback, useRef, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { isEmpty } from 'underscore'
import { index } from "api/valet_parkings";
import Dropdown from "components/base/dropdown";

const Enable = ({closeModal, enableValet, errors, setErrors, modalType}) => {
  const [selectedParkingLot, setSelectedParkingLot] = useState([])
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
  })
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  useEffect(() => {
    const fetch = async () => {
      await index().then((response) =>
        setDropdowns(state => ({...state, parkingLots: response.data })
      ))
    };
    fetch();
  }, []);

  useEffect(() => {
    Object.values(dropdowns.parkingLots).forEach(function (value) {
      if(value.valet_enabled) { 
        setSelectedParkingLot((prevState) => [...prevState, { value: value.id, label: value.name }]);
      }
    })
  },[dropdowns])

  const validateValues = (selectedParkingLot) => {
    const errors = {}
     if(isEmpty(selectedParkingLot)) { errors['valet_parking_ids'] = ['Parking Lot is required']}
     return errors
   }

  const save = useCallback(() => {
    setErrors({})
    const inputErrors = validateValues(selectedParkingLot)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const selectedParkingLotsValues = selectedParkingLot.map(
      (selectedParkingLot) => selectedParkingLot.value
    );
    enableValet({valet_parking_ids: selectedParkingLotsValues});
  },[setErrors, enableValet, selectedParkingLot]);


  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleChange = (selectedParkingLots, selectAllChecked) => {
    setErrors({})
    const isUserCheckedParkingLot = selectedParkingLots?.some((parkingLot) => parkingLot.value === 0);
    const filteredSelectedParkingLots = selectedParkingLots.filter(
      (parkingLot) => parkingLot.value !== 0
    );
    setSelectedParkingLot(selectAllChecked ? (isUserCheckedParkingLot ? dropdowns.parkingLots : []) : filteredSelectedParkingLots);
  }

  const coveringText = (value) => {
    return `Selected (${value ? value.length : "0"})`;
  };

  return (
    <Container>
      <p className={`${styles.title}  p-0`}></p>
      <p className={`${styles.subTitle} mx-0 mb-3 p-0 mt-3`}>
        {
          modalType === 'enable' ?
          'To enable the valet parking functionality, please specify the parking lot(s) where you would like to offer the service' : 
          'Change the parking lots where valet parking is offered'
        }
      </p>
      <Form initialValues={selectedParkingLot} getApi={setFormApi} className='m-auto' onSubmit={save}>
        {({ formState }) => {
          return (
            <Row className='w-100 ml-0' style={{padding: '10px'}}>
              <Col className='pb-4'>
                <Row>
                  <Col xs={4}>
                    <label className={styles.formLabel}>Parking Lot(s) *</label>
                  </Col>
                  <Col xs={8} style={{marginLeft: '-22px'}}>
                    <Dropdown
                      multiple
                      coveringText={() => coveringText(selectedParkingLot)}
                      options={dropdowns.parkingLots.map(lot => { return { value: lot.id, label: lot.name } })}
                      onChange={handleChange}
                      value={selectedParkingLot}
                      width="16.67rem"
                      size="sm"
                      showToolTip={false}
                      customInputClass={styles.formInput}
                    />
                  </Col>
                </Row>
                <p className={styles.error}>{errors['valet_parking_ids']}</p>
              </Col>
              <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={styles.button}>
                  {modalType === 'enable' ? 'Enable' : 'Save'}
                </Button>
              </Col>
            </Row>
          )
        }}
      </Form>
    </Container>
  )
}

export default Enable;
