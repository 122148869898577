import React, { useCallback, useRef } from 'react'
import { displayDate } from 'components/helpers'
import { filterFields } from 'components/helpers/fields/booting_and_towing'
import { renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button'
import { filterFetcher } from "api/booting_and_towings";
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'

const FilterForm = (props) => {
  const { setFilters, match, setList, startFetching, filters } = props;

  const attrs = { customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterRecords = useCallback(async (filters={}) => {
    const { range } = filters
    if(range && !range?.to) { delete range.to}
    if(range && !range?.from) { delete range.from}

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const addFilter = {
      ...filters,
      ...filterValues,
      range: {
        from: displayDate(filterValues.range?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.range?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(addFilter);
    filterRecords(addFilter);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      range: { from: '', to: ''}, 
    })
    setFilters({...filters, range: { from: '', to: ''}})
    filterRecords({...filters, range: { from: '', to: ''}})
  }

  const resetDates = () => {
    setFilters({...filters, range: { from: '', to: ''}})
    formApiRef.current.setValues({
      ...filters,
      range: { from: '', to: ''},
    })
  }

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (
    <Form  getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        const dateFrom = formState.values.range?.from || ''
        const dateTo = formState.values.range?.to || ''
        
        const fields = getFields(dateFrom, dateTo)
        return <Row className='m-auto'>
          <Col className={`m-0 ${styles.fieldset}`} xs='4'>
            {renderFields(fields.slice(0, 1), {lSize: 4, iSize: 8})}
          </Col>
          <Col className={styles.rightColumn} xs='4'>
            <span className={`${styles['fieldset-dateStroke']} position-absolute`}></span>
              {renderFields(fields.slice(1, 2), {lSize: 4, iSize: 8})}
            <span 
              role='button' 
              className={`${styles['fieldset-dateClear']} position-absolute`}
              onClick={resetDates}
            >
              Clear Date
            </span>
          </Col>
          <Col className='d-flex justify-content-center pt-3 m-0' xs='12' >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
        </Row>
      }}
    </Form>
  )
}

export default FilterForm;
