import React from 'react'
import { map } from 'underscore';
import styles from './index.module.sass';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/arrow-down-arrow-up.svg';
import Action from './action';

const TableRow = (props) => {
  const {record, modalType, openModal, setuserWaitingListRecord, userWaitingListRecord} = props
  const actions = [
    {
      icon: <ArrowIcon className={`${styles['icon-arrow']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      text: 'Shift',
      onClick: () => {openModal('shift')},
      isActive: modalType === 'shift' && userWaitingListRecord === record,
    },
    { 
      icon: <TrashIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      text: 'Delete',
      onClick: () => {openModal('delete')},
      isActive: modalType === 'delete' && userWaitingListRecord === record,
    },
  ]

  return (
    <tr onClick={() => setuserWaitingListRecord(record)}>
      <td>{record?.user_id}</td>
      <td>{record?.full_name}</td>
      <td>{record?.waiting_list_number}</td>
      <td>{record?.email}</td>
      <td>{record?.created_at_parsed}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow