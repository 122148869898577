import { FieldType } from 'components/helpers/form_fields';

const fieldsNew = (attrs, disabled) => {
    const { 
      mute=true,parking_lots=[],customLabelClass='', customInputClass=''} = attrs
    return ([
        [
          {
            name: 'monitor_type',
            label: 'Monitor Type',
            type: FieldType.RADIO_BUTTON_FIELD,
            mandatory: true,
            list: [
              { label: 'Individual', value: 'individual' },
              { label: 'Group of Vehicles', value: 'group' }
            ],
            customInputClass,
            customLabelClass,
            disabled: mute,
          },
        ],
        [
          {
            name: `owner_name`,
            label: 'Vehicle Name',
            customInputClass,
            customLabelClass,
            mandatory: true,
            disabled,
            placeholder: "Vehicle Name"
          },
          {
            name: `plate_number`,
            label: 'Vehicle LPN',
            disabled ,
            customInputClass,
            customLabelClass,
            mandatory: true,
            placeholder: 'Vehicle LPN',
          },
        ],
        [
          {
            name: 'expected_arrival_time',
            type: FieldType.SINGLE_HOUR_FIELD,
            customInputClass,
            customLabelClass,
            label: 'Expected Arrival Time:',
            placeholder: 'Choose a Time',
          }
        ],
  
        [
          {
            name: 'monitor_days',
            label: 'Monitored Days:',
            type: FieldType.MULTISELECT_FIELD,
            options: [
              {value: 'sunday', label: 'Sunday'},
              { value: 'monday', label: 'Monday' },
              { value: 'tuesday', label: 'Tuesday' },
              {value: 'wednesday', label: 'Wednesday'},
              {value: 'thursday', label: 'Thursday'},
              {value: 'friday', label: 'Friday'},
              {value:'saturday', label:'Saturday'}
            ],
            customInputClass,
            customLabelClass,
            placeholder: 'Choose Days to Monitor',
            tooltip:""
          }
        ],      
        [
          { 
            name: 'parking_lot_ids', 
            label: 'Parking Lots', 
            mandatory: true, 
            type: FieldType.MULTISELECT_FIELD,
            showToolTip: false, 
            options: [{ value: 0, label: 'All Parking Lots' }].concat(parking_lots.map(lot => { return { value: lot.value, label: lot.label } })),
            placeholder: "Choose a parking lot", 
            customLabelClass, 
            customInputClass,
            showCount: true  ,
          },
      
        ],
      ]);
    } 


export { fieldsNew };
