import React from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Switch } from "react-router";
import Index from "components/pages/vehicles/index";
import renderWithBackPath from "components/modules/render_with_back_path";
import renderWithParentPath from "components/modules/render_with_parent_path";
import CitationTicket from 'components/pages/vehicles/parking_citation_tickets/index';

const Routing = ({ match }) => (
  <React.Fragment>
    <Route
      exact
      path={match.path}
      component={Index}
    />
    <Switch>
        <Route path={`${match.path}/:id`} render={(props) => (
          <React.Fragment>
            <Route exact path={`${props.match.path}/citation_tickets`} render={renderWithParentPath(renderWithBackPath(CitationTicket, `${match.url}/`), props.match.url)}/>
          </React.Fragment>
        )} />
      </Switch>
  </React.Fragment>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);

