import React from "react";
import styles from "./index.module.sass";
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { map } from "underscore";
import Action from "./action";
import { useHistory } from "react-router";

const TableRow = (props) => {
  const { record, setSelectedModule } = props;

  const history  = useHistory()

  const actions = [
    {
      icon: <EyeIcon className={`${styles["icon-eye"]} mx-3`} />,
      highlightedClass: `${styles["background-primary"]} mx-3 text-white`,
      onClick: () => history.push(`/dashboard/system_logs/${record?.id}`, {logName: record?.name}),
      text: "View",
    },
  ];

  return (
    <tr onClick={() => setSelectedModule(record)}>
      <td>{record.name}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;