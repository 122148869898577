import fetchApi from 'components/modules/fetch_api';
import resourceApi from 'components/modules/resource_api';
import { generatePath } from 'react-router';


import env from ".env";
import axios from "axios";
import withApiCatch from "components/modules/with_api_catch";


const { create, index, destroy, show } = resourceApi('permit_types');

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const importPermitTypes = (data) => {
  return fetchApi(`dashboard/permit_types/import`, { method: 'PUT', data })  
}

const update = (params = {}) => {
  const { id, data = {} } = params;
  return fetchApi(generatePath(`dashboard/permit_types/${id}`), { method: 'PUT', data})
};

const getParkingLotsByZip = (zipcode) => {
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/permit_applications/nearby_parkings?zipcode=${zipcode}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}

const getParkingLotsByLatLong = (data) => {
  const {ltd, lng, distance} = data
  let url = new URL(env.backend_url);
  url = `${url.protocol}//${url.host}/api/v1/permit_applications/nearby_parkings?location_radius[distance]=${distance ? distance : "1"}&location_radius[ltd]=${ltd}&location_radius[lng]=${lng}`;
  const options = {
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return withApiCatch(
    axios(Object.assign(options))
  )
}
export { create, filterFetcher, importPermitTypes, index, update, destroy, show, getParkingLotsByZip,getParkingLotsByLatLong };
