import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterFetcher } from 'api/permit_waiting_lists';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD } from 'actions/permit_waiting_lists';
import { invoke } from 'actions';
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';
import TableData from './tableData';
import PermitWaitingListModal from '../waitinglist_modal';

const Index = (props) => {
  const { currentUser } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  
  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar {...props} openModal={openModal} activeTab={activeTab} setActiveTab={setActiveTab} />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} activeTab={activeTab} modalType={modalType} />
          </Col>
        </Row>
        <PermitWaitingListModal {...props} openModal={openModal} modalType={modalType} setActiveTab={setActiveTab} />
      </Container>
    </AccessControl>
  )
  
}

const resource = 'permit_waiting_list';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD)
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.permit_waiting_list.filters,
    record: state.permit_waiting_list.records.record,
    selectedRecord: state.permit_waiting_list.records.record,
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  {},
  SET_FILTERS
);
