import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import styles from 'components/pages/coupons/create/index.module.sass';
import React from 'react'

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass='', customSingleInputClass='' } = attrs;
  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
  return [
    {
      name: 'id',
      label: 'Session ID',
      customInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'slot_name',
      label: 'Slot ID',
      customInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'chargingTime',
      label: 'Charging Time',
      customInputClass: customSingleInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'energy_transferred',
      label: 'Energy Consumed',
      customInputClass: customSingleInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'cost_per_kwh',
      label: 'Cost Per KWh',
      customLabelClass,
      customInputClass: customInputClass.concat('   ', styles.iconInput),
      disabled: true,
      icon:dollarIcon()
    },
    {
      name: 'total_cost',
      label: 'Total Cost',
      customLabelClass,
      customInputClass: customInputClass.concat('   ', styles.iconInput),
      disabled: true,
      icon:dollarIcon()
    },
    {
      name: 'vehicle[model]',
      label: 'Vehicle Model',
      customInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'vehicle[plate_number]',
      label: 'Plate Number',
      customInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'vehicle_owner_name',
      label: 'Owner',
      customInputClass,
      customLabelClass,
      disabled: true
    },
    {
      name: 'charging_percentage',
      label: 'Progress',
      customInputClass,
      customLabelClass,
      disabled: true
    },
    {
        name: 'charger_name',
        label: 'Charging Name',
        customInputClass: customSingleInputClass,
        customLabelClass,
        disabled: true
      },
  ]
}

export { newFields };