import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Delete = (props) => {
  const { deleteEmail, state, closeModal } = props;

  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='my-3'>
        <span className={styles.content}>Do you want to proceed with deletion?</span>
        </Col>
      </div>
      <Col className="d-flex justify-content-center mt-4 mb-1">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={deleteEmail} className={styles.submitButton}   disabled={state?.isDeleting}>
          Remove
        </Button>
      </Col>
    </Col>
  )
}

export default Delete;
