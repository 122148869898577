import React from "react";
import { Container, Row, Col } from "reactstrap";
import { ROLES } from "config/permissions";
import withCurrentUser from "components/modules/with_current_user";
import AccessControl from "components/helpers/access-control";
import ToolBar from "./toolbar";
import connectRecord from "components/modules/connect_record";
import { bindActionCreators } from "redux";
import { SET_LIST_ELEMENT, SET_SINGLE_RECORD } from "actions/subscribers";
import resourceFetcher from "components/modules/resource_fetcher";
import { invoke } from "actions";
import { connect } from "react-redux";
import withFetching from "components/modules/with_fetching";
import { show } from "api/subscribers";

const Index = (props) => {
  const { currentUser, match } = props;
  const userId = match?.params?.id;

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className="mw-100 p-0">
        <Row className="m-auto">
          <Col xs={12} className="p-0">
            <ToolBar match={match} userId={userId} {...props} />
          </Col>
        </Row>
      </Container>
    </AccessControl>
  );
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
    },
    dispatch
  );
};
export default connectRecord(
  "subscriber",
  SET_SINGLE_RECORD,
  resourceFetcher(show),
  connect(null, mapDispatch)(withFetching(withCurrentUser(Index)))
);
