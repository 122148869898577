import React, { useCallback} from 'react'
/* Styles/Assets */
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as SettingIcon } from 'assets/setting-gear-icon.svg';
import styles from './index.module.sass';
/* Base */
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Toggle from 'components/base/toggle';
import Button from 'components/base/button';

const CollapseContent = ({ activeTab, createCitation, openModal, restrictApplication, allowApplication, setSetting}) => {

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">

      {activeTab === 'setting' && 
        <Row className='ml-3 mb-2'>
          <Toggle
            onClick={() => {openModal('setting_confirmation'); setSetting('citation_setting')}}
            customToggleClass= {styles.toggle}
            customToggleOnClass= {styles.toggleOn}
            positiveText=''
            negativeText=''
            value={createCitation}
            offHighlightClass={styles.toggleHighlight}
            onHighlightClass={styles.toggleOnHighlight}
          />
          <span className={styles.checkboxDescription}>
            Citation Ticket Creation for subscriber is 
          </span>
          <span className={styles.checkboxDescription1}>
            {createCitation ? 'enabled' : 'disabled'}
          </span>
          <Row className={styles.checkboxRow}>
            <Toggle
              onClick={() => {openModal('permit_setting_confirmation'); setSetting('restrict_setting')}}
              customToggleClass= {styles.toggle}
              customToggleOnClass= {styles.toggleOn}
              positiveText=''
              negativeText=''
              value={restrictApplication}
              offHighlightClass={styles.toggleHighlight}
              onHighlightClass={styles.toggleOnHighlight}
            />
            <span className={styles.checkboxDescription}>
              Completely restrict applications from accounts with open citations.
            </span>
          </Row>
          <Row className={styles.checkboxRow}>
            <Toggle
              onClick={() => {openModal('permit_setting_confirmation'); setSetting('review_setting')}}
              customToggleClass= {styles.toggle}
              customToggleOnClass= {styles.toggleOn}
              positiveText=''
              negativeText=''
              value={allowApplication}
              offHighlightClass={styles.toggleHighlight}
              onHighlightClass={styles.toggleOnHighlight}
            />
            <span className={styles.checkboxDescription}>
              Allow applications but mark them for administrative review.
            </span>
          </Row>
        </Row>
      }
    </Row>
  )
}

const ToolBar = (props) => {
  const {openModal, total, modalType, activeTab, setActiveTab, isFetching, history} = props

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
       <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={styles.title}>{`Parking / Citation Tickets (${total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <Button
          className={styles.citationSettingTab}
          onClick={() => history.push("citation_tickets/citation_escalations")}
        >
          <span className='p-3'>Citation Escalations</span>
        </Button>
        <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
        >
        <Link to='citation_tickets/generate_citation_report' className={`${styles.button} mr-3 d-inline-block text-decoration-none text-white`}>Generate New Report</Link>        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('setting')}
          className={`${styles.citationSettingTab} ${activeTab === 'setting' ? styles.activeCitationSettingTab : ''}`}
        >
          <span className='p-3'>Citation Setting</span>
        </Button>
        <SlideButton
          disableAnimation={true}
          isActive={modalType === 'disputeTypes'}
          status={`${modalType === 'disputeTypes' ? 'activeSecondary' : 'secondary'}`}
          className={`${modalType === 'disputeTypes' ? styles.activeSettingTab : ''}`}
          icon={<SettingIcon className={`${modalType === 'disputeTypes' ? styles.activeSettingIcon : ''}`} />}
          onClick={() => {openModal('disputeTypes')}}
        >
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;