import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';

const Confirmation = (props) => {
  const { updateLogSettings, closeModal, status, logName } = props;
  
  return (
    <Col className={styles.body}>
      <p className={`${styles.title} m-0 p-0`}>{`${status === 'pause' ? 'Pause' : 'Resume'} ${logName} Logs`}</p>
      <div className={styles.content}>
        <Col className='mt-4'>
          {status === 'pause' && 'By pausing logs, new log entries will no longer be displayed on the page in real-time'}
          {status === 'resume' && 'By resuming logs, new log entries will be displayed on the page in real-time'}
        </Col>
      </div>
      <Col className="d-flex justify-content-center mt-4">
        <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={()=>  updateLogSettings()} className={status === 'pause' ? styles.pauseButton : styles.resumeButton}>
          {status === 'pause' ? 'Pause' : 'Resume'}
        </Button>
      </Col>
    </Col>
  )
}

export default Confirmation;