import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import NavigationToolbar from 'components/base/navigationToolbar';

const ToolBar = (props) => {
  const { total } = props

  return (
    <NavigationToolbar>
      <Col className='d-flex justify-content-start align-items-center p-0'>
        <span className={styles.title}>{`Audit Log Modules (${total})`}</span>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;