import React, { useCallback, useEffect, useState } from 'react'
import { SET_SINGLE_RECORD } from 'actions/violation_types';
import { useDispatch } from 'react-redux';
import styles from './index.module.sass';
import Toggle from 'components/base/toggle';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { map } from 'underscore';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
    onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
      style={{cursor: `${disableHighlight && 'not-allowed'}`}}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = (props) => {
  const { record, openModal, modalType, setSelectedRecord, selectedRecord } = props
  const dispatch = useDispatch()
  const setRecord = useCallback((data) => {
    dispatch({type: SET_SINGLE_RECORD, payload: data})
  },[dispatch])

  const [highlightEnabled, setHighlightEnabled] = useState(false)

  useEffect(() => {
    setHighlightEnabled(modalType ==='activate_deactivate')
  },[modalType])

  const actions = [
    { 
      icon: <EditIcon className='mr-3'/>,
      highlightedClass: `${styles['background-secondary']} mr-3 text-white`,
      onClick: () => {openModal('edit'); setSelectedRecord(record)},
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecord === record.id
    },
    {
      icon: <TrashIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      onClick: () => {openModal('delete'); setSelectedRecord(record)},
      text: 'Delete',
      isActive: modalType === 'delete' && selectedRecord === record.id,
      disableHighlight: record?.status === 'active'
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.violation_type?.display_name}</td>
      <td>{record?.repetitions_allowed}</td>
      <td>
        <div className='d-flex align-items-center'>
          <span >{record.measure_type.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}</span>
        </div>
      </td>
      <td>
        <Toggle
          onClick={() => {setHighlightEnabled(true); openModal('activate_deactivate'); setSelectedRecord(record)}}
          customToggleClass= {styles.toggle}
          customToggleOnClass= {styles.toggleOn}
          positiveText=''
          negativeText=''
          value={record.status === 'active'}
          offHighlightClass={styles.toggleHighlight}
          onHighlightClass={styles.toggleOnHighlight}
          highlightEnabled={highlightEnabled && modalType === 'activate_deactivate'}
        />
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow;
