import { SpecialPermitActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records } = reduceEntity(SpecialPermitActions);

const SpecialPermitReducers = combineReducers({
  index,
  records,
});

export default SpecialPermitReducers;
