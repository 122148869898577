import React, { useEffect, useReducer, useState } from 'react';
import { asField } from 'informed';
import { Card, CardBody, Collapse } from 'reactstrap';
import TimeInput from './timeInput';
/* Helpers */
import useOutsideClick from 'components/helpers/useOutSideClick';
/* Styles */
import styles from './index.module.sass';
import { ReactComponent as ClockIcon } from 'assets/clock-icon.svg';


const HOURS = 'hours'
const MINUTES = 'minutes'
const MEDIRIEM = 'meridiem'

const HourInput = asField(({ fieldState, fieldApi, events = {}, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const {className=''} = props
  const { setValue } = fieldApi;
  const { value } = fieldState;
  
  const [hours, minutes] =  value?.split(':') || []
  const collapseRef = useOutsideClick(() => setIsOpen(false))
  
  const meridiem = (val) => {
    switch (true) {
      case val === 0:
        return 'AM'
      case val && val >= 12:
        return 'PM'
      case val && val < 12:
        return 'AM'
      default:
        return '';
    }
  }

  const convertHoursTo12 = (val) => {
    if(val % 12 === 0) return 12;
    return (val % 12) || '--';
  }
  const setNewState = (hoursValue, minutesValue, meridiemValue, currentDate) => {
    let obj = {}
    if(hoursValue === '--') {obj['hours'] = convertHoursTo12(currentDate.getHours(currentDate.getHours()))}
    if(minutesValue === '--') {obj['minutes']= currentDate.getMinutes().toString().padStart(2, '0')}
    if(meridiemValue === '') {obj['meridiem'] = meridiem(currentDate.getHours())}

    return obj
  }

  const timeReducer = (state, action={}) => {
    const date = new Date();
    const {type, payload} = action;
    const {hours, minutes, meridiem} = state
    const newState = setNewState(hours, minutes, meridiem, date)

    switch (type) {
      case HOURS:
        return { ...state, ...newState, hours: payload  }
      case MINUTES:
        return { ...state, ...newState, minutes: payload }
      case MEDIRIEM:
        return { ...state, ...newState, meridiem: payload}
      default:
        return { ...state }
    }
  }

  const initState = {
    hours: convertHoursTo12(hours),
    minutes: minutes?.padStart(2, '0') || '--',
    meridiem: meridiem(hours),
  }
  
  const handleChange = (_e) => {}

  const [timeState, dispatch] = useReducer(timeReducer, initState);
  const time = `${timeState.hours}:${timeState.minutes} ${timeState.meridiem}`

  useEffect(() => {
    if(timeState.hours && timeState.minutes && timeState.meridiem){
      const dateObj = new Date(`March 10, 2020 ${time}`)
      if(dateObj){
        setValue(`${dateObj.getHours()?.toString()?.padStart(2, '0')}:${dateObj.getMinutes()?.toString()?.padStart(2,'0')}`)
      }
    }
  },[timeState, setValue, time])

  return (
    <div className='positon-relative d-flex flex-column' ref={collapseRef}>
      <div className="d-flex flex-no-wrap align-items-center">
        <ClockIcon className={`${styles.clockIcon}`}/>
        <input
          className={`${className} form-control`} 
          onChange={handleChange}
          value={time}
          disabled={!!props.disabled}
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className={styles.collapseCard}>
        <Collapse isOpen={isOpen} className='mt-1'>
          <Card className=''>
            <CardBody className='d-flex justify-content-between p-0'>
            {
              isOpen && (
                <>
                  <TimeInput
                    selected={timeState.hours}
                    list={[...new Array(12).fill(null).map((_el, idx) => idx + 1)]} 
                    type={HOURS} 
                    dispatch={dispatch}
                  />
                  <TimeInput
                    selected={timeState.minutes}
                    list={[...new Array(60).fill(null).map((_el, idx) => idx.toString().padStart(2,'0'))]} 
                    type={MINUTES} 
                    dispatch={dispatch}
                  />
                  <TimeInput
                    selected={timeState.meridiem} 
                    list={['AM', 'PM']} 
                    type={MEDIRIEM} 
                    dispatch={dispatch}
                  />
                </>
              )
            }
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
});

export default HourInput;
