import { ParkingRegulationsActions } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records } = reduceEntity(ParkingRegulationsActions);

const ParkingRegulationsReducers = combineReducers({
  index,
  records
});

export default ParkingRegulationsReducers;
