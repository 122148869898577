import React, { useCallback, useContext, useReducer } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
/* Helpers */
import { AlertMessagesContext } from "components/helpers/alert_messages";
import { capitalize } from "components/helpers";
/* API */
import { create, pause, resume, destroy } from "api/reward_types";
/* Components */
import Create from "../create";
import ActivateDeactivate from "../activate_deactivate";
import Delete from "../delete";
/* Styles/Assets */
import styles from "./index.module.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const initState = {
  errors: {},
  isSaving: false,
  record: {},
  dropdownsFetched: false,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case "isSaving":
      return { ...state, isSaving: payload, errors: payload ? {} : errors };
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case "errors":
      return { ...state, errors: payload };
    case "record":
      return { ...state, record: payload };
    case "dropdowns":
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

const RewardModal = (props) => {
  const { modalType, openModal, addListElement, record, selectedRecord, setListElement, setSelectedRecord, source, popListElement } =
    props;

  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);

  const modalProps = useCallback(() => {
    let width = "480px";
    switch (modalType) {
      case "create":
        width = "678px";
        break;
      case "activate_deactivate":
        return {
          title: `${
            selectedRecord
              ? selectedRecord?.status === "active"
                ? "Pause"
                : "Resume"
              : record?.status === "active"
              ? "Pause"
              : "Resume"
          } Reward Program`,
          width,
        };
      case 'delete':
        return {title: 'Are you Sure?', width }
      default:
        break;
    }
    return { width };
  }, [modalType, selectedRecord, record]);

  const setState = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    openModal();
  }, [openModal]);

  const setList = (list, data, perPage, page) => {
    const listLength = list.length;
    switch (true) {
      case listLength < perPage:
        return [data].concat(list);
      case listLength === perPage:
        return [data].concat(list).slice(0, perPage);
      default:
        return list;
    }
  };

  const createRewardType = async (data) => {
    try {
      setState("isSaving", true);
      const response = await create({ data: { reward_type: data } });
      addListElement({ setList, data: response.data });
      addAlertMessages(
        [
          {
            type: "primary",
            text: `The ${capitalize(
              response.data.category
            )} Reward Program has been created`,
            onlyMessage: true,
          },
        ],
        "center"
      );
      closeModal();
    } catch (error) {
      setState("errors", error.response?.data?.errors);
    } finally {
      setState("isSaving", false);
    }
  };

  const deleteRewardProgram = useCallback(async () => {
    const id = selectedRecord? selectedRecord?.id : record?.id 
    setState('isDeleting', true)
    try {
      const response = await destroy({id: id})
      const message = `${response.data.name} Reward Program was successfully deleted`
      addAlertMessages([{
        type: 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      popListElement({id: id})
      closeModal();
    } catch (error) {
      setState('errors', error.response.data.errors)
    }finally {
      setState('isDeleting', false)
    }
  },[setState, addAlertMessages, popListElement, record, selectedRecord, closeModal])

  const pauseRewardProgram = useCallback(async () => {
    const id = selectedRecord? selectedRecord?.id : record?.id 
    try {
      const response  = await pause({ id: id })
      closeModal()
      const message = `The ${response.data.name} Reward Program was successfully paused`
      addAlertMessages([{
        type: 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
      if(source==='details'){
        setSelectedRecord(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal, selectedRecord, setSelectedRecord, source])


  const resumeRewardProgram = useCallback(async () => {
    const id = selectedRecord? selectedRecord?.id : record?.id 
    try {
      const response  = await resume({ id: id })
      closeModal()
      const message = `The ${response.data.name} Reward Program was successfully resumed`
      addAlertMessages([{
        type: 'primary',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
      if(source==='details'){
        setSelectedRecord(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal, selectedRecord, setSelectedRecord, source])

  const closeBtn = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.closeIcon}
        onClick={closeModal}
      />
    );
  }, [closeModal]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.rewardModal}
      style={{ maxWidth: modalProps().width, width: "100%" }}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {modalType === "create" && (
          <Create
            {...state}
            closeModal={closeModal}
            setState={setState}
            createRewardType={createRewardType}
          />
        )}
        {modalType === "activate_deactivate" && (
          <ActivateDeactivate
            {...state}
            record={selectedRecord? selectedRecord : record}
            closeModal={closeModal}
            setState={setState}
            pauseRewardProgram={pauseRewardProgram}
            resumeRewardProgram={resumeRewardProgram}
          />
        )}
        {modalType === "delete" && (
          <Delete
            {...state}
            record={selectedRecord? selectedRecord : record}
            closeModal={closeModal}
            setState={setState}
            state={state}
            deleteRewardProgram={deleteRewardProgram}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default RewardModal;
