import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Col, Container, Label } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/valet_staff_management";
import { newFields } from 'components/helpers/fields/valet_staff_fields';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import { ReactComponent as TrashIcon } from 'assets/trash_icon.svg';
import Loader from 'components/helpers/loader';

const Edit = (props) => {
  const { startFetching, closeModal, isSaving, editStaff, errors, setErrors, selectedRecord }= props
  const [isFetching, setIsFetching] = useState()
  const [fileName, setFileName] = useState();
  const fileRef = useRef()
  const [file, setFile] = useState()
  const [image, setImage] = useState(null)
  const [staff, setStaff] = useState()
  const [initialFileName, setInitialFileName] = useState();
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
  })
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch('valet_parking_list')
        ).then((response) => setDropdowns(state => ({...state, parkingLots: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fieldAttrs = {
    customInputClass: styles.input,
    customInput1Class: styles.input1,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    parkingLots: dropdowns.parkingLots,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const applicationData = useCallback((data) => {
    const { name, username, phone, email, password, role={}, status, parking_lots } = data
    return ({
      name: name,
      username: username,
      phone: phone,
      email: email,
      role_type: role?.name?.toLowerCase().split(' ').join('_'),
      password: password,
      status: status,
      parking_lot_id: parking_lots[0]?.id
    })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchStaff = async () => {
      if(!selectedRecord)  return;
      try {
        const response = await show({id: selectedRecord?.id})
        if(response.status === 200) {
          const { data } = response;
          setStaff(applicationData(data));
          setInitialFileName(response.data.avatar.split('/').at(-1))
          setImage(response.data.avatar)
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchStaff();
  }, [selectedRecord, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const { name, username, email, role_type, status, parking_lot_id } = formState;
    if(!name) { errors['name'] = ['Name is required']}
    if(!username) { errors['username'] = ['User Name is required']}
    if(!email) { errors['email'] = ['Email is required']}
    if(!role_type) { errors['role_type'] = ['Role is required']}
    if(!status) { errors['status'] = ['Status is required']}
    if(!parking_lot_id) { errors['parking_lot_id'] = ['Parking Lot is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = {
      ...values,
      avatar: file,
    };
    editStaff(updatedHash)
  }


  const getBase64= file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        setFile(reader.result);
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    }else {
      setErrors({})
      setFileName(e.target.files[0]?.name)
      setImage(URL.createObjectURL(e.target.files[0]));
      getBase64(e.target.files[0])
    }
  }
  
  const handleDrag = (e) => {
    setErrors({})
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      if (validFileTypes.includes(e.dataTransfer.files[0].type)) {
        setErrors({})
        setFileName(e.dataTransfer.files[0].name)
        setImage(URL.createObjectURL(e.dataTransfer.files[0]));
        getBase64(e.dataTransfer.files[0])
      }
    }
  };

  const fields = () => {
    return newFields({...fieldAttrs})
  }
  
  if(isFetching) { return <Loader /> }
  
  return (
    <Container className='p-0'>
      <Form className={styles.form} initialValues={staff} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col className='pt-4'>
              <div >
                {renderFields(newFields.slice(0, 7), {...singleFieldProps, errors})}
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} className={styles.uploadField}>
                  <span className={styles.inputLabel} style={{marginLeft: '-550px'}}>
                    <span className='mr-1'>Account Picture</span>
                    <span className={styles.astrick}>*</span>
                  </span>
                  <span className={styles.uploadContainer} onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                    <input ref={fileRef} name='id_proof' className='d-none' id='id-proof-file-upload' type='file' accept='.jpg, .png, .jpeg' onChange={showFileName}/>
                    {!image && <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '-0.9rem', width: '68.5%'}}>
                      <span className='mb-2'>
                        {image ? <img className={styles.img} alt="avatar" src={image}/>
                          : <CloudDownloadIcon />}
                      </span>
                      <span>
                        <span className={styles.boldText1}>
                          Add Picture
                        </span>
                        <span className='ml-1'>
                          or drag it here
                        </span>
                      </span>
                      <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
                    </Label>
                    }
                    {image && (<div className='d-flex'>
                    <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel1} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '-0.9rem'}}>
                      <span className='mb-2 mt-2'>
                        <img className={styles.img} alt="" src={image}/>
                      </span>
                      <span className={styles.uploadText}>
                        <span className={styles.boldText2}>
                        {fileName?.substring(0, 30)}{fileName?.length >= 30 ? '...' : ''}
                        {isEmpty(fileName) && initialFileName}
                        </span>
                      </span>
                    </Label>  
                    <div className={styles.deleteField}>
                      <TrashIcon className={styles.icon} onClick={() =>  {setFileName(); setImage(); setFile()}}/>
                    </div>
                    </div>)
                    }
                  </span>
                  <div className={`text-left general-error general-text-1 ${styles.error}`}>{errors?.avatar && errors.avatar }</div>
                </div>
                {renderFields(newFields.slice(7, 8), {...singleFieldProps, errors})}
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                onClick={()=>  closeModal()}
                className={`${styles.cancelButton} mb-3`}
              >
                Cancel
              </Button>
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Edit);