const periodOptions = (type, months) => {
  switch (type) {
    case 'days':
      return [1,2,3,4,5,6].map(val => ({value: `${val} ${val === 1 ? 'day' : 'days'}`, label: `${val} ${val === 1 ? 'day' : 'days'}`}))
    case 'weeks':
      return [1,2,3].map(val => ({value: `${val} ${val === 1 ? 'week' : 'weeks'}`, label: `${val} ${val === 1 ? 'week' : 'weeks'}`}))
    case 'months':
      return months.map(val => ({value: `${val} ${val === 1 ? 'month' : 'months'}`, label: `${val} ${val === 1 ? 'month' : 'months'}`}))
    default:
      return [];
  }
}

export default periodOptions;
