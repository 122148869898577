import { FieldType } from 'components/helpers/form_fields'

const showFields = [
  { name: 'id', label: 'Transaction number' },
  { name: 'vehicle.plate_number', label: 'Vehicle Plate' },
  { name: 'user_id', label: 'Account Linked' },
  { name: 'kiosk_id', label: 'Kiosk Number' },
  { name: 'created_at', label: 'Date' },
  { name: 'check_in', label: 'Start' },
  { name: 'check_out', label: 'End' },
  { name: 'slot_id', label: 'Parking Space ID' },
  { name: 'fee_applied', label: 'Parking Fee' },
  { name: 'total_price', label: 'Total Fee' },
  { name: 'paid', label: 'Payment Status' },
  { name: 'status', label: 'Parking Session Status' },
  { name: 'payment_method', label: 'Payment Method' },
]

const filterFields = (attrs) => {
  const { customInputClass='', customLabelClass='', paymentMethods=[], parkingSessionStatuses=[], parkingSessionsKioskIds=[] } = attrs;
  return [
    { 
      name: 'id',
      label: 'Transaction number',
      customInputClass,
      customLabelClass
    },
    { 
      name: 'vehicles_plate_number',
      label: 'Vehicle Plate',
      customInputClass,
      customLabelClass
    },
    { 
      name: 'user_ids', 
      label: 'Account Linked ID',
      customInputClass,
      customLabelClass
    },
    {
      name: 'kiosk_ids',
      label: 'Kiosk Number',
      customInputClass,
      customLabelClass,
      type: FieldType.MULTISELECT_FIELD,
      options: parkingSessionsKioskIds?.map(({ value, label }) => {
        return { value, label };
      })
    },
    {
      name: 'created_at[from]',
      type: FieldType.DATE_FIELD,
      label: 'Date',
      customInputClass,
      customLabelClass
    },
    {
      name: 'created_at[to]',
      type: FieldType.DATE_FIELD,
      label: 'Date',
      customInputClass,
      customLabelClass
    },
    {
      name: 'check_in[from]',
      type: FieldType.DATE_FIELD,
      label: 'Start',
      customInputClass,
      customLabelClass
    },
    {
      name: 'check_in[to]',
      type: FieldType.DATE_FIELD,
      label: 'End',
      customInputClass,
      customLabelClass
    },
    {
      name: 'slot_name',
      label: 'Parking Space ID',
      customInputClass,
      customLabelClass
    },
    {
      name: 'fee_applied',
      label: 'Parking Fee',
      customInputClass,
      customLabelClass
    },
    {
      name: 'total_price',
      label: 'Total Fee',
      customInputClass,
      customLabelClass
    },
    {
      name: 'statuses',
      label: 'Statuses',
      type: FieldType.MULTISELECT_FIELD,
      customInputClass,
      customLabelClass,
      options: parkingSessionStatuses?.map(({ value, label }) => {
        return { value, label };
      })
    },
    {
      name: 'payment_methods',
      label: 'Payment Method',
      type: FieldType.MULTISELECT_FIELD,
      customInputClass,
      customLabelClass,
      options: paymentMethods?.map(({ value, label }) => {
        return { value, label };
      })
    }
  ]
}

const viewFields = (attrs) => {
  const { customInputClass='', customLabelClass='' } = attrs;
  return [
    [
      {
        name: 'id',
        label: 'Parking session number',
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'user_id',
        label: "Account",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'plate_number',
        label: 'Plate Number',
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'created_at',
        label: "Created at",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'entered_at',
        label: "Entered at",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'parked_at',
        label: "Parked at",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'status',
        label: "Session Status",
        customInputClass,
        customLabelClass,
        disabled: true
      },
      {
        name: 'paid',
        label: "Paid Status",
        customInputClass,
        customLabelClass,
        disabled: true
      }
    ]
  ]
}

const detailFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'id',
      label: 'Parking Session Transaction ID',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'lot[name]',
      label: 'Parking Lot',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'created_at',
      label: 'Date',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'rate',
      label: 'Rate',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'lot[full_address]',
      label: 'Address',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'entered_at',
      label: 'Entry Time',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[plate_number]',
      label: 'Vehicle LPN',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'status',
      label: 'Status',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'exit_at',
      label: 'Exit Time',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'total_price',
      label: 'Total Cost',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export { filterFields, showFields, viewFields, detailFields };
