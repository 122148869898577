import Button from 'components/base/button';
import React from 'react'
import { Col, Container } from 'reactstrap';
import styles from './index.module.sass';

const Delete = (props) => {

  const { deleteDocument, state, closeModal } = props;

  return (
    <Container>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Delete Document</p>
      <Col className={styles.body}>
        <div className={styles.content}>
          <Col className='my-3'>
            You are about to delete the parking regulations document. This action cannot be undone. Are you sure?
          </Col>
        </div>
        <Col className="d-flex justify-content-center my-3">
          <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
            Cancel
          </Button>
          <Button type='button' onClick={deleteDocument} className={styles.submitButton} disabled={state.isDeleting}>
            Delete
          </Button>
        </Col>
      </Col>
    </Container>
  )
}

export default Delete;