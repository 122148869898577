import { FieldType } from "components/helpers/form_fields";

const searchFields = (attrs) => {
  const {permitTypes=[], customLabelClass='', customInputClass='', submittedFromDate='', submittedToDate=''} = attrs
  return [
    { 
      name: 'submitted_date["from"]', 
      label: 'Submitted From Date:',
      type: FieldType.SINGLE_DATE_FIELD,
      max: submittedToDate || new Date(),
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'submitted_date["to"]', 
      label: 'Submitted To Date:', 
      type: FieldType.SINGLE_DATE_FIELD,
      max: new Date(),
      min: submittedFromDate,
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'status', 
      label: 'Application Status:', 
      type: FieldType.SELECT_FIELD,
      customInputClass,
      customLabelClass,
      options: [
        { label: 'Pending', value: 'pending' },
        { label: 'Under Review', value: 'under_review' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Cancelled', value: 'cancelled' },
      ] 
    },
    { 
      name: 'application_type',
      label: 'Application Type:',
      type: FieldType.SELECT_FIELD,
      customInputClass,
      customLabelClass,
      options: [
        { label: 'New Application', value: 'new_application' },
        { label: 'Permit Renewal', value: 'permit_renewal' },
        { label: 'Permit Extension', value: 'permit_extension' },
      ] 
    },
  
    { 
      name: 'permit_type_id',
      label: 'Permit Type:',
      type: FieldType.SELECT_FIELD,
      customInputClass,
      customLabelClass,
      options: permitTypes.map(pt => ({ label: pt.name, value: pt.id })) 
    },
  ];
};

export default searchFields;
