import { FieldType } from "components/helpers/form_fields";

const dateTimeDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, id } = attrs;
  return [
    {
      name: `eventDateTime[eventDateTime-${id}][date]`,
      label: 'Date',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: `eventDateTime[eventDateTime-${id}][start_time]`,
      label: "Start Time",
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: `eventDateTime[eventDateTime-${id}][end_time]`,
      label: "End Time",
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    }
  ]
}

export default dateTimeDetails;