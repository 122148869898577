import { FieldType } from "components/helpers/form_fields";

const eventDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'event[name]',
      label: 'Event Name',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'event[organizer_email]',
      label: "Organizer's Email",
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'event[organizer_phone_number]',
      label: "Organizer's Phone Number",
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'event[parking_spaces]',
      label: "Parking Spaces",
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'event[total_cost]',
      label: "Cost",
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
   
  ]
}

export default eventDetails;