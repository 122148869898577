import React, { useEffect, useState } from 'react'
import { map } from 'underscore';
import styles from './index.module.sass'
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { useSelector } from 'react-redux';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) setShowText(false)}}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = ({record, openModal, modalType, setRecord}) => {
  const selectedRecordId = useSelector(state => state.notification.records.record?.id)

  const actions = [
    { 
      icon: <EditIcon className='mr-3'/>,
      highlightedClass: `${styles['background-secondary']} mr-3 text-white`,
      onClick: () => {openModal('edit_email')},
      text: 'Edit',
      isActive: modalType === 'edit_email' && selectedRecordId === record.id
    },
    {
      icon: <TrashIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      onClick: () => {openModal('delete_email')},
      text: 'Delete',
      isActive: modalType === 'delete_email' && selectedRecordId === record.id
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.name}</td>
      <td>{record?.email}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;
