import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import Events from 'components/pages/app/events';
import CitationTicket from 'components/pages/app/citation_ticket';
import renderWithBackPath from 'components/modules/render_with_back_path';
import Payment from 'components/pages/app/event_parking_applications/payment'
import Index from 'components/pages/app/event_parking_applications/index'
import Details from 'components/pages/app/event_parking_applications/get_details/index'
import AccessEventParking from 'components/pages/app/event_parking_applications/access_event_parking'
import ParkingLotDetails from 'components/pages/app/event_parking_applications/access_event_parking/parkingLotDetails'

const Routing = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/events/:event_id`} component={Events} />
    <Route path={`${match.path}/citation_ticket`} component={CitationTicket} />
    <Route exact path={`${match.path}/event_parking_applications`}  component={Index}/>
    <Route path={`${match.path}/event_parking_applications`} render={(props) => (
        <React.Fragment>
          <Route exact path={`${props.match.path}/:id/payment`} component={renderWithBackPath(Payment, `${match.url}/`)} />
          <Route exact path={`${props.match.path}/get_details/:event_id`} component={renderWithBackPath(Details, `${match.url}/`)} />
          <Route exact path={`${props.match.path}/access_event_parking`} component={renderWithBackPath(AccessEventParking, `${match.url}/`)} />
            <React.Fragment>
              <Route exact path={`${props.match.path}/access_event_parking/parkingLotDetails`} component={renderWithBackPath(ParkingLotDetails, `${match.url}/`)} />
            </React.Fragment>
        </React.Fragment>
    )}/>
  </Switch>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Routing);