const { FieldType } = require("components/helpers/form_fields");

const periodOptions = (type, months) => {
  switch (type) {
    case 'days':
      return [1,2,3,4,5,6,7].map(val => ({value: `${val} ${val === 1 ? 'day' : 'days'}`, label: `${val} ${val === 1 ? 'day' : 'days'}`}))
    case 'weeks':
      return [1,2,3].map(val => ({value: `${val} ${val === 1 ? 'week' : 'weeks'}`, label: `${val} ${val === 1 ? 'week' : 'weeks'}`}))
    case 'months':
      return months.map(val => ({value: `${val} ${val === 1 ? 'month' : 'months'}`, label: `${val} ${val === 1 ? 'month' : 'months'}`}))
    default:
      return [];
  }
}

const vehicleFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'vehicle[plate_number]',
      label: 'Plate Number',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[color]',
      label: 'Color',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[make]',
      label: 'Vehicle Make',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'vehicle[registration_state]',
      label: 'State',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[manufacture_year]',
      label: 'Year',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[model]',
      label: 'Vehicle Model',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[type]',
      label: 'Vehicle Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: 'gasoline', label: 'Gasoline' },
        { value: 'electric', label: 'Electric' },
      ],
      disabled: mute,
      customInputClass,
      customLabelClass,
    },
    
  ]
}

const ownerFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'owner[full_name]',
      label: 'Full Name',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[home_phone]',
      label: 'Home Phone',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[work_phone]',
      label: 'Work Phone',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[email]',
      label: 'Email',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[home_address]',
      label: 'Home Address',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'owner[work_address]',
      label: 'Work Address',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

const permitFields = (attrs) => {
  const { customInputClass='', customLabelClass='', customLotInputClass, mute=true } = attrs;
  return [
    {
      name: 'permit[number]',
      label: 'Number',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[type]',
      label: 'Type',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[issued_on]',
      label: 'Issue Date',
      type: FieldType.DATE_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
      showIcon: false
    },
    {
      name: 'permit[expires_on]',
      label: 'Expiry Date',
      customInputClass,
      customLabelClass,
      disabled: mute,
      showIcon: false,
    },
    {
      name: 'permit[expired]',
      label: 'Expired?',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[cost]',
      label: 'Cost',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'permit[parking_lot]',
      label: 'Valid Parking Lot',
      type: FieldType.TEXT_AREA,
      rows: 2,
      customInputClass: customLotInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

const cancelledFields = (attrs, showDescription) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  const defaultFields = [
    {
      name: 'cancelled_details[status]',
      label: 'Permit Status',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'cancelled_details[cancelled_on]',
      label: 'Cancelled on',
      customInputClass,
      customLabelClass,
      disabled: mute,
      showIcon: false,
    },
  ]
  if(showDescription) {
    defaultFields.push(    {
      name: 'cancelled_details[description]',
      label: 'Description',
      customInputClass,
      customLabelClass,
      disabled: mute,
    })
  }
  return defaultFields
}

const revokedFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'revoked_details[status]',
      label: 'Permit Status',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'revoked_details[revoked_on]',
      label: 'Revoked on',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'revoked_details[revoked_by]',
      label: 'Revoked by',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'revoked_details[revoked_reason]',
      label: 'Description',
      customInputClass,
      customLabelClass,
      type: FieldType.TEXT_AREA,
      rows: 2,
      disabled: mute,
    },
  ]
}

const extendFields = (attrs) => {
  const { validity, customInputClass, customLabelClass, } = attrs
  return [
    {
      name: 'validity',
      label: 'Permit Validity',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: 'days', label: 'Days' },
        { value: 'weeks', label: 'Weeks' },
        { value: 'months', label: 'Months' },
      ],
      disabled: true,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'period',
      label: 'Period',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Please select',
      options: periodOptions(validity, [1,3,6]),
      mandatory: true,
      showToolTip: false,
      customInputClass,
      customLabelClass,
      showCount: false,
    },
    {
      name: 'parking_hour_from',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'parking_hour_to',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'expiry_date',
      label: 'Expiry Date',
      type: FieldType.SINGLE_DATE_FIELD,
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'hourly_rate',
      label: 'Hourly Rate',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'cost',
      label: 'Cost',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
  ]
}

const visitorDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, customInput1Class='' } = attrs;
  return [
    [
      {
        name: 'purpose_of_visit',
        label: 'Purpose Of Visit',
        customInputClass: customInput1Class,
        customLabelClass,
        disabled: mute,
      }
    ],
    [
      {
        name: 'visitor_address[full_name]',
        label: "Resident's Full Name",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[phone]',
        label: 'Contact Number',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[address1]',
        label: 'Address Line 1',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[address2]',
        label: 'Address Line 2',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[country_code]',
        label: 'Country',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[postal_code]',
        label: 'Zip Code',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[state_code]',
        label: 'State',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'visitor_address[city]',
        label: 'City',
        customInputClass,
        customLabelClass,
        disabled: mute,
      }
    ]
  ]
}

export { cancelledFields, extendFields, ownerFields, permitFields, revokedFields, vehicleFields, visitorDetails };
