import React from 'react'
import { Col, Container } from 'reactstrap';
import Button from 'components/base/button';
import styles from './index.module.sass'

const Import = ({openModal}) => {

  return (
    <Container>
      <p className={`${styles.title} m-0 p-0`}>Import Permit Data</p>
      <Col className={styles.body}>
        <div>
          <Col className='my-4 p-0'>
            <span>What kind of permit data would you like to import?</span>
          </Col>
        </div>
        <Col className="d-flex justify-content-center my-3 p-0">
          <Button 
            type='button' 
            className={`${styles.orangeButton} mr-4`}
            onClick={() => openModal('import_issued_permits')}
          >
            Issued Permits
          </Button>
          <Button 
            type='button' 
            className={`${styles.primaryButton}`}
            onClick={() => openModal('import_permit_types')}
          >
            Permit Types
          </Button>
        </Col>
      </Col>
    </Container>
  )
  
}

export default Import;
