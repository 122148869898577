import React from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/permit_types';
/* Helpers */
import { filterFields } from 'components/helpers/fields/permit_types/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';

const TableData = (props) => {
  const { filters, isResourceFetching, setRecord, list, openModal, activeTab, modalType } = props

  const renderRecords = () => {
    return list.map((record) => {
      return ( <TableRow 
        key={record.id} 
        record={record} 
        openModal={openModal} 
        setRecord={setRecord} 
        modalType={modalType}
      />);
    });
  }
  
  return (
    <IndexTable
      {...props}
      className={(activeTab === 'filters' || activeTab === 'setting') ? styles.tableActive : styles.table}
      isFetching={() => isResourceFetching }
      filterFields={filterFields()}
      filterFetcher={filterFetcher}
      paginationQuery={{ ...filters }}
      columns={
        <>
          <th attr="permit_types.name" style={{ width: '22%', minWidth: '210px' }} >Name</th>
          <th attr="permit_types.status" style={{width: '22%', minWidth: '210px' }} >Status</th>
          <th attr="permit_types.payment_type" style={{ width: '20%', minWidth: '210px' }} >Paid/Free</th>
          <th attr="permit_types.hourly_rate" style={{ width: '15%', minWidth: '210px' }} >Cost Per Hour</th>
          <th attr="permit_types.parking_hour_from" style={{ width: '20%', minWidth: '210px' }} >
            Time (From - To)
          </th>
          <th attr="permit_types.actions" style={{ width: '30%', minWidth: '210px', display: 'flex', justifyContent: 'center' }} disableSort>Action</th>
        </>
      }
      renderRecords={renderRecords}
      entityName="Permit Types"
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData
