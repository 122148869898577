import { FieldType } from 'components/helpers/form_fields';

const filterFields = ({customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'plate_number',
    label: 'Vehicle Plate Number',
    placeholder: 'Vehicle Plate Number',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
];

export { filterFields };
