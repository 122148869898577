import React, { useRef, useCallback } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { auditLogFilterFields } from 'components/helpers/fields/system_logs';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { filterLogs } from 'api/system_logs';
import { list as selectList } from 'selectors/list';

const FilterLogs = (props) => {
  const { startFetching, match, location, setList, setFilters, filters, moduleName, routeName, setRouteName } = props

  const formApiRef = useRef();

  const options = {
    CitationTicket: [{ value: 'all', label: 'Citation Tickets' },{ value: 'disputed', label: 'Citation Disputes' }],
    Coupon: [{ value: 'user', label: 'User Coupons' },{ value: 'event', label: 'Event Coupons' }],
    Disputes: [{ value: 'session_dispute', label: 'Parking Disputes' },{ value: 'citation_dispute', label: 'Citation Disputes' }],
    Permit: [
      { value: 'permit_applications', label: 'Permit Application' },
      { value: 'permit_types', label: 'Permit Types' },
      { value: 'permits', label: 'Issued Permits' },
      { value: 'permit_renewal_letters', label: 'Permit renewal letters' },
      { value: 'permit_type_codes', label: 'Permit type codes' },
      { value: 'permit_invoice_settings', label: 'Permit invoices' },
    ]
  }

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    options: options[moduleName]
  }

  const filterDisputeLogs = useCallback(async (filters) => {
    startFetching(filterLogs(Object.assign({ ...match.params }, {attribute: moduleName==='Permit' ? filters.type_of : routeName}, {filters: filters}, { query: filters }, {page: location?.search?.split('=')[1]})))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params, routeName, moduleName])

  const singleFieldProps = {
    iSize: 12,
    events: {
      onChange: (e) => {
        if(moduleName==='Permit'){
          setRouteName(e?.target?.value)
        }
        setFilters({...filters, type_of: e?.target?.value})
        filterDisputeLogs({...filters, type_of: e?.target?.value})
      }
    }
  }

  const fields = () => {
    return auditLogFilterFields({...fieldAttrs})
  }
  
  return (
    <Container className='p-0'>
      <Form getApi={setFormApi} className={styles.form}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <div >
                {renderFields(newFields.slice(0, 1), {...singleFieldProps})}
              </div>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default (FilterLogs);
