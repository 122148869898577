import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { asField } from 'informed';
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { isDate } from 'underscore';

const SingleDateInput = asField(({ fieldState, fieldApi, events = {}, ...props }) => {
  const { setValue } = fieldApi;
  const { value } = fieldState;
  const { className, fieldProps={} } = props;
  const { min, max, showIcon=true, customIconClass='', dateFormat, placeholder, popperPlacement='bottom-start' } = fieldProps

  
  const validDate = (date) => 
  isDate(date) ? date : ''
  const [selected, setSelected] = useState(validDate(value) )

  const { onChange} = events
  const onInputChange = (e) => {
    setSelected(e)
    setValue(e)
    onChange && onChange(e)
  }
  
  useEffect(() => {
    if(value  === undefined) { 
      setSelected('') 
      return
    }
    const date = new Date(value)
    if(isDate(date)) {
      setSelected(date)
    }

  }, [value])

  return (
    <div className="position-relative" xs='12' >
      <ReactDatePicker
        onSelect={(e) => onInputChange(e)}
        showIcon={showIcon}
        wrapperClassName='w-100'
        className={`${className || ''} ${styles.input} form-control shadow-none`}
        selected={selected}
        dateFormat={dateFormat || 'dd/MM/yyyy'}
        readOnly={!!props.disabled}
        maxDate={validDate(max)}
        minDate={validDate(min)}
        placeholderText={placeholder}
        popperPlacement={popperPlacement}
      />
      {showIcon && <FontAwesomeIcon icon={faCalendarAlt} color='grey' className={`${styles.calenderIcon} ${customIconClass}`}/>}
    </div>
  );
});

SingleDateInput.propTypes = {
  field: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  className: PropTypes.string
};

export default SingleDateInput;