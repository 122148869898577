import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty } from 'underscore';
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
import { searchV1 as dropdownsSearch } from 'api/dropdowns';
import withFetching from 'components/modules/with_fetching';
import  vehicleFields  from 'components/helpers/fields/subscriber/vehicles/createFields';
import styles from './index.module.sass'
import { Label } from 'reactstrap';
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import { validateStep2 } from 'api/app/apply_permit';

const Create = (props) => {
  const  { addVehicle , closeModal, errors, setErrors,isSaving, startFetching } = props;
  const formApiRef = useRef();
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [image, setImage] = useState(null)
  const fileRef = useRef()
  const [plateNError, setPlateNError] = useState(false)

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const vehiclePlateNumberFieldProps = {
    lSize: 5,
    iSize: 6,
    events: {
      onChange: (e) => {
        validatePlateAndEmail(e?.target?.value)
        setErrors({})
      }
    }
  }

  const validatePlateAndEmail =  useCallback(async (plateNumber) => {
    try {
      const response = await validateStep2({ plate_number: plateNumber, email: ' '})
      setPlateNError(response.status !== 200 && response.status !== 201);
    } catch (error) {
      setPlateNError(true)
    }
  }, []);

  const validateFields = (values) => {
    const {plate_number, manufacturer_id, manufacture_year, model, color ,category, vehicle_class, vehicle_type, battery_capacity, registration_state, sub_class } = values;
    const errors = {};
    if(!manufacturer_id){errors['manufacturer_id'] = ['Manufacturer is required!']}
    if(!manufacture_year){errors['manufacture_year'] = ['Year is required !']}
    if(!model){errors.model = ['Vehicle model is required!']}
    if(!color){errors.color = ['Vehicle color is required !']}
    if(!category){errors['category'] = ['Vehicle Category is required !']}
    if(!vehicle_class){errors['vehicle_class'] = ['Vehicle Class is required !']}
    if(!vehicle_type){errors['vehicle_type'] = ['Vehicle Type is required !']}
    if(!plate_number){errors['plate_number'] = ['Plate Number is required !']}
    if(vehicle_type==='electric' && !battery_capacity){errors['battery_capacity'] = ['Battery Capacity is required !']}
    if(plateNError){errors['plate_number'] = ['Entered plate number is already present !']}
    if(!registration_state){errors['registration_state'] = ['State is required!']}
    if(!sub_class){errors['sub_class'] = ['Sub Class is required!']}
    return errors
  }


  const [dropdowns, setDropdowns] = useState({
    manufacturers: [],
    vehicleClass: [],
    category: [],
    vehicleType: [],
    vehicleSubClass: [],
  })

  const fetchDropdowns = useCallback(() => {
    Promise.all([
      startFetching(dropdownsSearch("manufacturers_list")).then((response) =>
        setDropdowns(state => ({...state, manufacturers: response.data }))),
      startFetching(
        dropdownsSearch("vehicle_types_list")
      ).then((response) => setDropdowns(state => ({...state, vehicleType: response.data }))),
      startFetching(dropdownsSearch("vehicle_classes_list")).then((response) =>
        setDropdowns(state => ({...state, vehicleClass: response.data }))),
      startFetching(dropdownsSearch("vehicle_categories_list")).then((response) =>
        setDropdowns(state => ({...state, category: response.data }))),  
    ])
  }, [startFetching]);

  useEffect(() => {
    fetchDropdowns();
  }, [fetchDropdowns]);

   
  const vehicleFieldProps = {
    lSize: 5,
    iSize: 6,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const vehicleClassProps = {
    lSize: 5,
    iSize: 6,
    events: {
      onChange: (_e) => {setErrors({}); fetchVehicleSubclasses()}
    }
  }

  const fetchVehicleSubclasses = async () => {
    const {values} = formApiRef.current.getState();
    try {
      const subclasses = await dropdownsSearch("vehicle_sub_classes_list", {
        vehicle_class: values.vehicle_class,
      });
      setDropdowns(state => ({...state, vehicleSubClass: subclasses.data }))
    } catch (_error) {}
  };
  
  const getBase64= file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        setFile(reader.result);
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    }else {
      setFileName(e.target.files[0]?.name)
      const formData = new FormData()
      formData.append('file', fileRef.current.files[0])
      getBase64(fileRef.current.files[0])
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  }

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if(e.dataTransfer.files.format === '.pdf' || e.dataTransfer.files.format === '.png' || e.dataTransfer.files.format === '.jpg'){
        setFileName(e.dataTransfer.files[0].name)
        fileRef.current = { files: e.dataTransfer.files }
      }
    }
  };
  
  const save = useCallback(() => {
    const {values} = formApiRef.current.getState();
    
    const inputErrors = validateFields(values)
    if(!isEmpty(inputErrors)){ 
      setErrors(inputErrors)
      return
    };
    
    const data = { 
      plate_number: values.plate_number, 
      vehicle_type: values.vehicle_type,
      color: values.color,
      manufacturer_id: values.manufacturer_id,
      model: values.model,
      registration_state: values.registration_state,
      category: values.category,
      vehicle_class: values.vehicle_class,
      sub_class: values.sub_class,
      battery_capacity: values?.battery_capacity,
      registration_card: file,
      manufacture_year: values.manufacture_year
    }
    addVehicle(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, addVehicle, file,plateNError]);

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fields = (vehicleType) => {
    return vehicleFields({ 
      manufacturers:  dropdowns.manufacturers,
      vehicleClass: dropdowns.vehicleClass,
      category: dropdowns.category,
      vehicleType: vehicleType,
      vehicleSubClass: dropdowns.vehicleSubClass,
      customLabelClass: styles.formLabel,
      customInputClass: styles.formInput,
      customDropdownInputClass: styles.formDropdownInput,
      customExpiryInputClass: styles.cardInput,
    })
  }
  
  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={styles.form} onSubmit={save} >

        {({ formState }) => {
          const { vehicle_type} = formState.values
            return (
              <Row className='mx-auto w-100'>
                <Row className='mx-auto w-100'>
                  <Row className='m-0 p-0 pl-1' xs={12}>
                  <Col className='d-flex flex-wrap flex-column px-0'>
                    <Row>
                    <Col className={styles.fieldset}>
                      {renderFields(fields(vehicle_type)[0], {...vehiclePlateNumberFieldProps, errors})}
                      {renderFields(fields(vehicle_type)[1], {...vehicleFieldProps, errors})}
                      {renderFields(fields(vehicle_type)[2], {...vehicleClassProps, errors})}
                      {renderFields(fields(vehicle_type)[3], {...vehicleFieldProps, errors})}
                      {vehicle_type==='electric' && renderField(fields(vehicle_type)[5], {...vehicleFieldProps, errors})}
                    </Col>
                    <Col className={`${styles.fieldset} ${styles['fieldset-right-label']} pr-1`}>
                      {renderFields(fields(vehicle_type)[4], {...vehicleFieldProps, errors})}
                    </Col>
                    </Row>
                  </Col>
                    </Row>
                  </Row>
                    <Row style={{width: '80%', marginLeft: '4.5rem'}}>
                      <Col>
                      <div  onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                        <input ref={fileRef} name='id_proof' className='d-none' id='id-proof-file-upload' type='file' accept='.jpg, .png, .jpeg, .pdf' onChange={showFileName}/>
                        {!image && <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}>
                          <span className='mb-2'>
                            {image ? <img className={styles.img} alt="" src={image}/>
                              : <CloudDownloadIcon />}
                          </span>
                          <span>
                            <span className={styles.boldText1}>
                              Add Picture
                            </span>
                            <span className='ml-1'>
                              or drag it here
                            </span>
                          </span>
                          <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
                          
                        </Label>
                        }
                        {image && <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}>
                          <span className='mb-2 mt-2'>
                            <img className={styles.img} alt="" src={image}/>
                          </span>
                          <span className={styles.uploadText}>
                            <span className={styles.boldText2}>
                            {fileName}
                            </span>
                          </span>
                        </Label>
                        }
                      </div>
                    </Col>
                    </Row>
                  <Col className="d-flex justify-content-center mb-2 mt-3" style={{marginLeft: '1rem'}} xs={12}>
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      Cancel
                    </Button>
                    <Button type='submit' className={styles.button}  isLoading={isSaving}>
                      Create
                    </Button>
                  </Col>
            </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);