import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { ROLES } from "config/permissions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
/* API */
import { filterFetcher } from "api/user_charging_sessions";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
/* Actions */
import {
  SET_FILTERS,
  SET_LIST,
  SET_LIST_ELEMENT,
  SET_SINGLE_RECORD,
} from "actions/user_charging_sessions";
import { invoke } from "actions";
/* Helpers */
import AccessControl from "components/helpers/access-control";
/* Components */
import TableData from "./table_data";
import ToolBar from "../../toolbar";
import CharginSessionsModal from "../charging_sessions_modal";

const resource = "user_charging_session";

const UserChargingSessions = (props) => {
  const { currentUser, location, match } = props;

  const listType = location?.state?.listType;
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState();
  const userId = match?.params?.id;

  const openModal = useCallback(
    (modalType) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "show":
          val = "show";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className="mw-100 p-0">
        <Row className="m-auto">
          <Col xs={12} className="p-0">
            <ToolBar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              userId={userId}
              match={match}
              pageType="charging_sessions"
              {...props}
            />
          </Col>
          <Col xs={12} className="p-0">
            <TableData
              {...props}
              listType={listType}
              userId={userId}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
            />
          </Col>
        </Row>
        <CharginSessionsModal
          {...props}
          userId={userId}
          modalType={modalType}
          openModal={openModal}
          setActiveTab={setActiveTab}
        />
      </Container>
    </AccessControl>
  );
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

const mapState = (state) => {
  return {
    filters: state.user_charging_session.filters,
    selectedRecord: state.user_charging_session.records.record,
  };
};

export default withRouter(
  connectList(
    resource,
    SET_LIST,
    resourceFetcher(filterFetcher, resource),
    connect(
      mapState,
      mapDispatch
    )(withFetching(withCurrentUser(UserChargingSessions))),
    { fetchCondition: () => true }
  )
);