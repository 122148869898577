const applicantDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, t } = attrs;
  return [
    {
      name: 'applicant[full_name]',
      label: t("permit_application.apply_for_permit.labels.step2.full_name.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[home_address]',
      label: t("subscriber.my_permits.show.home_address"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[home_phone]',
      label: t("permit_application.apply_for_permit.labels.step2.home_phone.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[work_address]',
      label: t("subscriber.my_permits.show.work_address"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'applicant[work_phone]',
      label: t("permit_application.apply_for_permit.labels.step2.work_phone.label"),
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export default applicantDetails;
