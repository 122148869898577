import React, { useEffect, useState } from 'react'
import { map } from 'underscore';
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { useSelector } from 'react-redux';
import Toggle from 'components/base/toggle';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) setShowText(false) }}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = ({history, record, match, openModal, modalType, setRecord, emailList}) => {
  const selectedRecordId = useSelector(state => state.notification.records.record?.id)

  const [highlightEnabled, setHighlightEnabled] = useState(false)

  useEffect(() => {
    setHighlightEnabled(modalType ==='view')
  },[modalType])

  const actions = [
    { 
      icon: <EyeIcon className={`${styles['icon-eye']} mr-3`}/>,
      highlightedClass: `${styles['background-primary']} mr-3 text-white`,
      onClick: () => {openModal('view')},
      text: 'View',
      isActive: modalType === 'view' && selectedRecordId === record.id,
    },
    { 
      icon: <EditIcon className={`${styles['icon-deails']} d-flex justify-content-center align-items-center ml-3`}/>,
      highlightedClass: `${styles['background-secondary']} ml-3 text-white`,
      text: 'Edit',
      onClick: () => {history.push(`${match.path}/${record.id}`, { emailList: emailList, record: record })},
      isActive: modalType === 'edit' && selectedRecordId === record.id,
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.event_name?.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}</td>
      <td>{record?.notification_type}</td>
      <td>
        <Toggle
          onClick={() => {setHighlightEnabled(true); openModal('activate_deactivate')}}
          customToggleClass= {styles.toggle}
          customToggleOnClass= {styles.toggleOn}
          positiveText=''
          negativeText=''
          value={record.status === 'active'}
          offHighlightClass={styles.toggleHighlight}
          onHighlightClass={styles.toggleOnHighlight}
          highlightEnabled={highlightEnabled && modalType === 'activate_deactivate' && selectedRecordId === record.id}
        />
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;
