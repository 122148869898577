import React, { useEffect, useState } from 'react'
/* Base */
import Toggle from 'components/base/toggle';
import { map } from 'underscore';
import { useSelector } from 'react-redux';
/* Styles/Assets */
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import styles from './index.module.sass';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])
  
  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = (props) => {
  const { record, openModal, setRecord, modalType } = props
  const selectedRecordId = useSelector(state => state.special_permit_type.records.record?.id)
  
  const [highlightEnabled, setHighlightEnabled] = useState(false)

  useEffect(() => {
    setHighlightEnabled(modalType ==='activate_deactivate')
  },[modalType])

  const actions = [
    { 
      icon: <EditIcon className='mr-3'/>,
      highlightedClass: `${styles['background-secondary']} mr-3 text-white`,
      onClick: () => {openModal('edit')},
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecordId === record.id
    },
    { 
      icon: <TrashIcon className='ml-3'/>,
      highlightedClass: `${styles['background-danger']} ml-3 text-white`,
      // onClick: () => {openModal('delete')},
      text: 'Delete',
      isActive: modalType === 'delete' && selectedRecordId === record.id 
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td className=''>{record?.name}</td>
      <td>
        <Toggle
          onClick={() => {setHighlightEnabled(true); openModal('activate_deactivate')}}
          customToggleClass= {styles.toggle}
          customToggleOnClass= {styles.toggleOn}
          positiveText=''
          negativeText=''
          value={record.status === 'active'}
          offHighlightClass={styles.toggleHighlight}
          onHighlightClass={styles.toggleOnHighlight}
          highlightEnabled={highlightEnabled && modalType === 'activate_deactivate' && selectedRecordId === record.id}
        />
      </td>
      <td className='text-capitalize'>{record.payment_type}</td>
      <td>{record.payment_type === 'paid' ? `$${record.hourly_rate}` : 'N/A'}</td>
      <td>
        {new Date("2023-02-26T" + record.parking_hour_from).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
          {' - '}
        {new Date("2023-02-26T" + record.parking_hour_to).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  );
  
};

export default TableRow;
