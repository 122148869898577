import React from 'react'
import { Col } from 'reactstrap';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';

const ToolBar = (props) => {
  const { total, openModal } = props

  return (
    <NavigationToolbar
      backTab='violations'
      className='justify-content-end'
    >
      <Col className='d-flex justify-content-start align-items-center'>
        <span>{`Automatic Measures (${total})`}</span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          isActive={true}
          icon={<PenToSquareSvg />}
          onClick={() => openModal('create')}
        >
          <span className='mr-3 d-inline-block' style={{width: '155px'}}>Create New Measure</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;
