import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
/* Actions */
import {
  SET_LIST,
  SET_FILTERS,
  SET_LIST_ELEMENT,
  SET_SINGLE_RECORD,
} from "actions/event_parking_applications";
/* API */
import { filterFetcher } from "api/event_parking_applications";
/* Modules */
import resourceFetcher from "components/modules/resource_fetcher";
import connectList from "components/modules/connect_list";
import withFetching from "components/modules/with_fetching";
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import { connect } from "react-redux";
/* Components */
import TableData from "./table_data";
import { invoke } from "actions";
import { bindActionCreators } from "redux";
import EventModal from "components/pages/event_parking_applications/event_modal";

const PrivateEvents = (props) => {
  const { list, openModal, modalType, activeTab, setUpdatedProps, search, listType } = props;

  const [updatedList, setUpdatedList] = useState(list);
  
  setUpdatedProps(props);
  useEffect(() => {
    const results = list?.filter((event) =>
      event?.event_name?.toLowerCase()?.includes(search?.toLowerCase())
    );
    setUpdatedList(results);
  }, [search, list]);
  return (
    <Container className="mw-100 p-0">
      <Row className="m-auto">
        <Col xs={12} className="p-0">
          <TableData
            {...props}
            list={updatedList}
            search={search}
            listType={listType}
            modalType={modalType}
            openModal={openModal}
            activeTab={activeTab}
          />
        </Col>
      </Row>
      <EventModal {...props} modalType={modalType} openModal={openModal} />
    </Container>
  );
};

const resource = "event_parking_applications";

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

const mapState = (state) => {
  return {
    filters: state.event_parking_applications.filters,
    selectedRecord: state.event_parking_applications.records.record,
  };
};

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(PrivateEvents)))
);
