import React, { useCallback, useState } from "react";
import { Container } from "reactstrap";
import { ROLES } from "config/permissions";
import Themes from "./admin_themes";
/* Modules */
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import AccessControl from "components/helpers/access-control";
import ToolBar from "./toolbar";

const Index = (props) => {
  const { currentUser } = props;
  const [listType, setListType] = useState("dashboard");
  const [showFilters, setShowFilters] = useState(false);
  const [updatedProps, setUpdatedProps] = useState();
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState("");

  const openModal = useCallback(
    (modalType) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "edit":
          val = "edit";
          break;
        case modalType === "delete":
          val = "delete";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  const toggleShowFilters = useCallback(() => {
    setShowFilters((val) => !val);
  }, [setShowFilters]);

  const commmonProps = {
    ...props,
    openModal,
    modalType,
    activeTab,
    setActiveTab,
  };

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className="mw-100 p-0">
        <ToolBar
          listType={listType}
          setListType={setListType}
          showFilters={showFilters}
          toggleShowFilters={toggleShowFilters}
          setFilters={updatedProps?.setFilters}
          filters={updatedProps?.filters}
          startFetching={updatedProps?.startFetching}
          setList={updatedProps?.setList}
          list={updatedProps?.list}
          match={updatedProps?.match}
          {...commmonProps}
        />
        <Themes {...commmonProps} setUpdatedProps={setUpdatedProps} listType={listType}/>
      </Container>
    </AccessControl>
  );
};

export default withCurrentUser(Index);