import fetchApi from 'components/modules/fetch_api';
import resourceApi from 'components/modules/resource_api';
import { generatePath } from 'react-router';

const { index } = resourceApi('special_permit_types');
const { create } = resourceApi('permit_types');

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const update = (params = {}) => {
  const { id, data = {} } = params;
  const { nestedParams } = data
  delete data.nestedParams

  return fetchApi(generatePath(`dashboard/permit_types/${id}`, nestedParams), {
    method: 'PUT',
    data,
  })
};

export { create, filterFetcher, index, update };