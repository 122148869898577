import fieldsNew from "./create";

const filterFields = () => ([
  { name: 'permit_number', label: 'Permit Number'},
  { name: 'commuters', label: 'Commuters'},
  { name: 'issued_date', label: 'Issued Date'},
  { name: 'vehicle_plate_number', label: 'Vehicle Plate Number'},
  { name: 'vehicle_owner_name', label: 'Vehicle Owner name'},
  { name: 'permit_expired', label: 'Permit Expired'},
  { name: 'permit_expiry_date', label: 'Expiry Date'},
  { name: 'action', label: 'Action'},
]);

export { filterFields, fieldsNew }
