import resourceApi from "components/modules/resource_api";
import searchApi from "components/modules/search_api";

const { index, show } = resourceApi("charging_sessions");
const search = searchApi("charging_sessions");

const filterFetcher = (params = {}) => {
  const { page, perPage = 10, id, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      user_id: id,
      ...filters,
    },
  });
};


export { filterFetcher, index, search, show };
