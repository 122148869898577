import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
/* Base */
import { Card, CardBody, Row, Col } from 'reactstrap';
/* Helpers */
import styles from './index.module.sass'
import { ReactComponent as SearchIcon } from 'assets/app/search-svgrepo-com.svg';
import { ReactComponent as DocumentIcon } from 'assets/app/document-svgrepo-com.svg';
import { ReactComponent as CalenderIcon } from 'assets/app/calender-svgrepo-com.svg';
import { ReactComponent as ClipBoardIcon } from 'assets/app/clipboard-svgrepo-com.svg';
import { ReactComponent as TowTructIcon } from 'assets/app/tow-truck-svgrepo-com.svg';
/* Modules */
import AppModal from '../app/app_modal';
import RedirectIfAuthorized from 'components/modules/redirect_if_authorized';
import 'react-toastify/dist/ReactToastify.css';
import WebappHeader from 'components/base/webapp_header';
import ThemeProvider from 'subscriberThemeProvider';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

const Homepage = (props) => {
  const { history } = props
  const { t, i18n } = useTranslation();
  const [ modalType, setModalType ] = useState('')
  const openModal = (modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'apply_permit':
        val = 'apply_permit'
        break;
      case modalType === 'find_parking':
        val = 'find_parking'
        break;
      case modalType === 'confirmation': 
        val = 'confirmation'
        break;
      default:
        break;
    }
    setModalType(val)
  }

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("selectedLanguage"))
  }, [i18n])

  const handleFindParking = () => {
    // eslint-disable-next-line no-lone-blocks
    {props.isSubAuthorized ? props.history.push(`/subscriber`) : props.history.push(`/findparking`)}
  };

  return (
    <ThemeProvider>
      {!props.isSubAuthorized ? <WebappHeader /> : null}
      <div className={`${styles.helpText} d-flex justify-content-center mt-5 mb-3`}>
        {t('landing_page.greeting')}
      </div>
      <Row className={`m-0 p-0 d-flex justify-content-center w-90 pt-4`}>
        <Col className={`m-0 p-0 ${styles.cardCol}`} xs={24} sm={12} md={8} lg={6} xl={4}>
        <Card className={styles.card} onClick={handleFindParking}>
          <CardBody className={styles.cardbodyicon}>
            <SearchIcon className={styles.icon}/>
          </CardBody>
          <CardBody className={styles.cardbody}>
            {t('landing_page.find_parking')}
          </CardBody>
        </Card>
        </Col>
        <Col className={`m-0 p-0 ${styles.cardCol}`} xs={24} sm={12} md={8} lg={6} xl={4}>
        <Card className={styles.card} onClick={() => {openModal('apply_permit')}}>
          <CardBody className={styles.cardbodyicon}>
            <DocumentIcon className={styles.icon}/>
          </CardBody>
          <CardBody className={styles.cardbody}>
          {t('landing_page.apply_permit')}
          </CardBody>
        </Card>
        </Col>
        <Col className={`m-0 p-0 ${styles.cardCol}`} xs={24} sm={12} md={8} lg={6} xl={4}>
        <Card className={styles.card} onClick={() => history.push(`app/event_parking_applications`)}>
          <CardBody className={styles.cardbodyicon}>
            <CalenderIcon className={styles.icon}/>
          </CardBody>
          <CardBody className={styles.cardbody}>
          {t('landing_page.city_events')}
          </CardBody>
        </Card>
        </Col>
        <Col className={`m-0 p-0 ${styles.cardCol}`} xs={24} sm={12} md={8} lg={6} xl={4}>
        <Card className={styles.card} onClick={() => history.push(`app/citation_ticket`)}>
          <CardBody className={styles.cardbodyicon}>
            <ClipBoardIcon className={styles.icon}/>
          </CardBody>
          <CardBody className={styles.cardbody}>
          {t('landing_page.citation')}
          </CardBody>
        </Card>
        </Col>
        <Col className={`m-0 p-0 ${styles.cardCol}`} xs={24} sm={12} md={8} lg={6} xl={4}>
        <Card className={styles.card}>
          <CardBody className={styles.cardbodyicon}>
            <TowTructIcon className={styles.icon}/>
          </CardBody>
          <CardBody className={styles.cardbody}>
          {t('landing_page.booting_and_towing')}
          </CardBody>
        </Card>
        </Col>   
      <AppModal  openModal={openModal} modalType={modalType}   />
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ThemeProvider>
  );
}

export default connect(
  null,
)(RedirectIfAuthorized(Homepage));
