import React, { useCallback, useMemo } from "react";
import { map } from "underscore";
/* Styles and Assets */
import styles from "./index.module.sass";
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import Action from "./action";

const TableRow = ({
  history,
  record,
  match,
  selectedRecordId,
  openModal,
  modalType,
  setSelectedRecordId,
}) => {
  const { status } = record;

  const highlightAction = useCallback(() => {
    return record.id === selectedRecordId;
  }, [selectedRecordId, record.id]);

  const statusColor = useMemo(() => {
    if(!status) return;
    const colorMapping = {
      active: 'success',
      suspended: 'danger',
    }
    return colorMapping[status?.toLowerCase()] || 'default'
  },[status])

  const actions = [
    {
      icon: <EyeIcon />,
      className: `${styles["icon-eye"]}`,
      onClick: () => {openModal('show')},
      activeClass: styles["icon-eye-active"],
      status: highlightAction("show"),
      allowActive: true,
    },
  ];

  return (
    <tr onClick={() => setSelectedRecordId(record)}>
      <td>{record.username}</td>
      <td>{record.name}</td>
      <td>{record.email}</td>
      <td>{record.role && record.role.name && record.role.name}</td>
      <td>
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`}> </span>
          <span >{status.toUpperCase() }</span>
        </div>
      </td>  
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;