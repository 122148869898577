import { FieldType } from "components/helpers/form_fields";
import moment from "moment";

const identificationLable = (identification) => {
  switch (identification) {
    case 'employee_id':
      return 'Employee ID'
    case 'driving_license':
      return 'DLN'
    case 'ssn':
      return 'SSN'
    default:
      return '';
  }
}

const fieldsUser = (userType, attrs) => {
  const {customDropdownInputClass, customInputClass, countries, stateList, initialCountry,customLabelClass} = attrs
  return [
    {
      name: `${userType}[full_name]`, 
      label: 'Full Name',
      disabled: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
      placeholder: 'First Name Last Name'
    },
    {
      name: `${userType}[birthday]`, 
      label: 'Date of Birth',
      type: FieldType.SUBSCRIBER_DATE_FIELD,
      disabled: false,
      customInputClass,
      customLabelClass,
      max: moment().subtract(18, "years")._d,
      mandatory: true,
    },
    {
      name: `${userType}[home_phone]`,  
      label: 'Home Phone',
      disabled: false,
      type: FieldType.PHONE_NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      initialCountry: initialCountry?.toLowerCase()
    },
    {
      name: `${userType}[email]`, 
      label: 'Email',
      disabled: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
      placeholder:"user@example.com"
    },
    {
      name: `${userType}[work_phone]`, 
      label: 'Work Phone',
      disabled: false,
      type: FieldType.PHONE_NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      initialCountry: initialCountry?.toLowerCase()

    },
    {
      name: `${userType}[home_address]`, 
      label: 'Address Line 1',
      disabled: false,
      customInputClass,
      customLabelClass,
      placeholder:'123 Street Name'
    },
    {
      name: `${userType}[address2]`, 
      label: 'Address Line 2',
      disabled: false,
      customInputClass,
      customLabelClass,
    },
    {
      name: `${userType}[country_code]`, 
      label: 'Country',
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      customInputClass: customDropdownInputClass,
      customLabelClass,
      options: countries,
      disabled: false,
      mandatory: true,
      defaultValue:'US'
    },
    {
      name: `${userType}[postal_code]`, 
      label: 'Zip Code',
      disabled: false,
      ...attrs,
      mandatory: true
    },
    {
      name: `${userType}[state_code]`, 
      label: 'State',
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      disabled: false,
      customInputClass: customDropdownInputClass,
      options: stateList,
      customLabelClass,
      mandatory: true,

    },
    {
      name: `${userType}[city]`, 
      label: 'City',
      disabled: false,
      customInputClass,
      customLabelClass,
      mandatory: true
    },
  ]
}

const fieldsIdentification = (mute, userType, identification, attrs) => {
  
  let data = [[
    {
      name: `${userType}[id_type]`,
      label: 'ID Type',
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [
        { value: 'driving_license', label: 'Driver\'s License' },
        { value: 'employee_id', label: 'Employee ID' },
        { value: 'ssn', label: 'SSN' },
      ],
      horizontalDisplay: true,
      mandatory: true,
      customInputClass: attrs?.customDropdownInputClass,
    },
  ]]
  if(identificationLable(identification)) {
    data[0] = data[0].concat([
      {
        name: `${userType}[id_number]`,
        label: identificationLable(identification),
        horizontalDisplay: true,
        mandatory: true,
        // disabled: mute,
        ...attrs
      },
    ])
  }


  
  data.push(fieldsUser(userType, attrs))
  
  return data
}

export default fieldsIdentification;
