import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import TooltipInfo from 'components/helpers/tooltip_info';
import styles from './dropdown.module.sass';

const { Option } = Select;

const CustomSearchDropdown = ({
  value,
  onChange,
  options,
  customOptions,
  mode='default',
  width = '100%',
  size = 'md',
  className,
  selectedOptionClassName,
  error,
  disabled = false,
  coveringText,
  customInputClass = '',
  showToolTip = false,
  placeholder=''
}) => {
  const tooltipText = () => {
    if (!value || !value.length) return 'No selected option';
    return value.map((e, i) => (
      <div key={i} className={styles.tooltipInfo}>
        {e.label}
      </div>
    ));
  };

  return (
    <div>
      <Select
        className={`antd-select-container ${styles.input} ${error ? styles.error : ""}  ${customInputClass}`}
        style={{ width }}
        value={value}
        onChange={onChange}
        mode={mode}
        disabled={disabled}
        maxTagCount= {'responsive'}
        dropdownClassName={`${styles.antDropdown} ${className || ''}`}
        showSearch
        optionFilterProp="label"
        placeholder={placeholder}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Option>
        ))}
      </Select>
      { showToolTip && (
        <TooltipInfo
          white
          className="ml-2"
          text={tooltipText()}
          target="antd-select"
        />
      )}
    </div>
  );
};

CustomSearchDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.any,
    })
  ).isRequired,
  customOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      className: PropTypes.string,
    })
  ),
  mode: PropTypes.string,
  width: PropTypes.string, // width can be 100% or number px
  size: PropTypes.string, // we have 2 size sm and md
  className: PropTypes.string,
  selectedOptionClassName: PropTypes.string,
  error: PropTypes.array,
  disabled: PropTypes.bool,
  coveringText: PropTypes.func,
  customInputClass: PropTypes.string,
  showToolTip: PropTypes.bool,
};

export default CustomSearchDropdown;