import React, { useCallback, useEffect, useRef, useState, useReducer } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map, times } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { searchV1 as dropdownsSearch } from 'api/dropdowns';
/* Helpers */
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';
import { permitFields, fieldsIdentification} from 'components/helpers/fields/app/apply_permit';
import LocationForm from '../location/form/index.jsx'
import { getParkingLots, validateStep2,checkParkingLot, getPermitType, getVehicleDetails, getTotalCost,fetchStatesList, getOwnerDetails, applyCoupon } from 'api/app/apply_permit';
import { ReactComponent as CrosshairsIcon } from 'assets/app/crosshairs-icon.svg';
import { capitalize } from 'lodash'
import { Label } from 'reactstrap';
import  vehicleFields  from 'components/helpers/fields/app/vehicleFields';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GooglePayButton from '@google-pay/button-react';
import GooglePayDetails from 'components/modules/google_pay.js'
import { Spinner } from 'reactstrap';
import { ReactComponent as FetchIcon } from 'assets/refresh_icon.svg';
import moment from 'moment';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CommutersCard from 'components/base/commuters_card';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import { useTranslation, Trans } from 'react-i18next';
import ErrorWrapper from 'components/base/forms/common_form/error';
import dayjs from 'dayjs';
import Dragger from 'antd/es/upload/Dragger';
import { message } from 'antd';
import { CloudUploadOutlined } from "@ant-design/icons";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import "./antd.css";

const initState = {
  errors: {},
  fetching: false,
  show_details: false,
  parkingLots: {},
  selectedParkingLots:[],
  totalSelectedSpaces: 0,
  disableCheckboxes: false,
};

const reducer = (state, action) => {
  const { citation } = state;
  switch (action.type) {
    case 'errors':
      return { ...state, errors: action.payload };
    case 'parkingLots':
      return { ...state, citation: action.payload };
    case 'fetching':
      return { ...state, fetching: action.payload, citation: action.payload ? {} : citation };
    case 'show_details':
      return { ...state, show_details: action.payload };
    case 'selectParkingLot':
      return {
        ...state,
        selectedParkingLots: action.payload.selectedParkingLots,
        disableCheckboxes: action.payload.disableCheckboxes,
      };
    default:
      return { ...state };
  }
};

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};


const years = Array.from(
  { length: 20 },
  (_, index) => new Date().getFullYear() + index
);



const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />
const reddollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={`${styles['discount-color']} ${styles.dollarIcon}`} />


const Apply = (props) => {
  const  { setState, closeModal, errors, setErrors, isSaving, startFetching, applyPermitApplication, setPermitName, setCommuterId, setVManufacturers,setUserType, openInnerModal, formApiRef, setFormApi, base64IdProof, setBase64IdProof } = props;
  const [permitTypes, setPermitTypes] = useState([])
  const [nearbyParkingLots, setNearbyParkingLots] = useState()
  const [currentLocation , setCurrentLocation] = useState('')
  const [isFetchingLocation, setIsFetchingLocation] = useState(false)
  const [step , setStep] = useState(1)
  const [permit , setPermit] = useState("")
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initState);
  const [selectedParkingLots, setSelectedParkingLots] = useState(null);
  const [lotAvailablity, setLotAvailability] = useState(false)
  const [alertMsg, setAlertMsg] = useState("")
  const [permitTypeID, setPermitTypeID] = useState()
  const [permitType, setPermitType] = useState({})
  const [vehicle, setVehicle] = useState()
  const [plateNumber, setPlateNumber] = useState()
  const [periodDropdown, setPeriodDropdown] = useState([])
  const [idType, setIDType] = useState()
  const [showGooglePay, setGooglePayShow] = useState(false);
  const [showCreditCardFields, setShowCreditCardFields] = useState(false)
  const [totalCost, setTotalCost] = useState()
  const [permitTypePeriod, setPermitTypePeriod] = useState()
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false)
  const [isFetchingOwner, setIsFetchingOwner] = useState(false)
  const [userLocation, setUserLocation] = useState()
  const [checkP, setCheckP] = useState(false)
  const [checkV, setCheckV] = useState(false)
  const [countries, setCountries] = useState()
  const [stateList, setStateList] = useState()
  // eslint-disable-next-line
  const [mute, setMute] = useState(false)
  const [emailErr, setEmailErr] = useState(false)
  const [plateNError, setPlateNError] = useState(false)
  const [isUSWorkPhone, setIsUSWorkPhone] = useState(false)
  const [isUSHomePhone, setIsUSHomePhone] = useState(false)
  // eslint-disable-next-line
  const [validPLots, setValidPLots] = useState([])
  // eslint-disable-next-line
  const [invalidPLots, setInvalidPLots] = useState([])
  // eslint-disable-next-line
  const [isValidFile, setIsValidFile] = useState(true)
  const [manufacturers, setManufacturers] = useState()
  const [cardNumber, setCardNumber] = useState('');
  const [name, setName] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [expiryM, setExpiryM] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const creditCardNum = useRef('')
  const holder_name = useRef('')
  const cvv = useRef('')
  const [initialCountry, setInitialCountry] = useState('')
  const [filePresence,setFilePresence] = useState(true)
  const [msg, setMsg] = useState('')
  const [autoRenew, setAutoRenew] = useState(false)
  const checkboxref = useRef(false)
  const savecheckboxref = useRef(false)
  const [saveCard , setSaveCard] = useState(false)
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const [coupon, setCoupon] = useState('')
  const couponRef = useRef('')
  // eslint-disable-next-line
  const [validateCoupon, setValidateCoupon] = useState('')
  const [couponApplied, setCouponApplied] = useState(false)
  const [applyingCoupon, setApplyingCoupon] = useState(false)
  const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,12])
  const { t } = useTranslation()
  const [subclasses, setSubClasses] = useState([])
  const [fetchedCurrentLocation, setFetchedCurrentLocation] = useState(false)
  const [validityOptions, setValidityOptions] = useState([])
  const [propertyTypeList, setPropertyTypeList] = useState([])
  const [residenceTypeList, setResidenceTypeList] = useState([])
  const dayPeriod = [1,2,3,4,5,6].map(val => ({value: `${val} ${val === 1 ? 'day' : 'days'}`, label: `${val} ${val === 1 ? 'day' : 'days'}`}))
  const weekPeriod = [1,2,3].map(val => ({value: `${val} ${val === 1 ? 'week' : 'weeks'}`, label: `${val} ${val === 1 ? 'week' : 'weeks'}`}))
  const monthPeriod = [1,3,6].map(val => ({value: `${val} ${val === 1 ? 'month' : 'months'}`, label: `${val} ${val === 1 ? 'month' : 'months'}`}))
  // eslint-disable-next-line
  const [uploadProgress, setUploadProgress] = useState(0);
  const [idProofFileName, setIdProofFileName] = useState("");

  const rateCostProps = {
    lSize: 5,
    iSize: 5,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const handleCheckboxChange = (parkingLot) => {
    const selectedParkingLots = state.selectedParkingLots.slice();
    const parkingLotIndex = selectedParkingLots.indexOf(parkingLot);
    if (parkingLotIndex === -1) {
      selectedParkingLots.push(parkingLot);
    } else {
      selectedParkingLots.splice(parkingLotIndex, 1);
    }
    setCheckP(true)
    const disableCheckboxes = (selectedParkingLots.length === 2);
    setState('selectParkingLot', {
      selectedParkingLots,
      disableCheckboxes,
    });
    setSelectedParkingLots(parkingLot)
    for (var i = 0; i < 4; i++) {
       //eslint-disable-next-line 
      nearbyParkingLots.map((lot) => {
        checkPermitAvailability(lot)
      })  
    }
    
    
  };

  const checkPermitAvailability = async (lot) => {
    try {
      const response = await checkParkingLot({id: lot?.id, name: permitType?.name});
      if(response.status === 200){
        validPLots.push(lot)
      }
    } catch (error) {
      if(error?.response?.data?.status){
        invalidPLots.push(lot)
      }
    }
  };
  const valPrProps = {
    lSize: 5,
    iSize: 5,
    events: {
      onChange: (_e) => {
                          setCheckV(true) || setErrors({})
                          setVisitorPeriod(_e)
                        }
    }
  }

  const setVisitorPeriod = (e) => {
    if(permitType.name === 'Visitor Permit'){
      if(formApiRef.current.getValue('validity') === 'days') setPeriodDropdown(dayPeriod)
      if(formApiRef.current.getValue('validity') === 'weeks') setPeriodDropdown(weekPeriod)
      if(formApiRef.current.getValue('validity') === 'months') setPeriodDropdown(monthPeriod)
    }
  }
  
  
  const validateFields = useCallback(() => {
    const {
      location,
      category,
      permit_type_id,
      period,
      plate_number,
      applied_from_date,
      manufacturer_id,
      year,
      model,
      color,
      registration_state,
      vehicle_category,
      institution_name,
      vehicle_class,
      id_type,
      id_number,
      full_name,
      birthday,
      home_phone,
      address1,
      city,
      country_code,
      state_code,
      postal_code,
      email,
      work_phone,
      payment_methods,
      vehicle_type,
      expiration_month,
      expiration_year,
      sub_class,
    } = formApiRef.current.getValues();
    const errors = {};
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const current_date = new Date();
    const entered_date = new Date(applied_from_date);
    current_date.setHours(0, 0, 0, 0);
    entered_date.setHours(0, 0, 0, 0);

    if (step === 1) {
      if (!location && !currentLocation) {
        errors["location"] = [
          t(
            "permit_application.apply_for_permit.labels.step1.location.required"
          ),
        ];
      }
      if (!category) {
        errors.category = [
          t(
            "permit_application.apply_for_permit.labels.step1.select_permit_category.required"
          ),
        ];
      }
      if (!permit_type_id) {
        errors.permit_type_id = [
          t(
            "permit_application.apply_for_permit.labels.step1.select_permit_type.required"
          ),
        ];
      }
      if (!period && permitType) {
        errors.period = [
          t("permit_application.apply_for_permit.labels.step1.period.required"),
        ];
      }
      if (!applied_from_date && permitType) {
        errors.applied_from_date = [
          t(
            "permit_application.apply_for_permit.labels.step1.applied_from_date.required"
          ),
        ];
      }
      if (entered_date < current_date) {
        errors.applied_from_date = ["Cannot be a past date !"];
      }
    }
    if (step === 2) {
      if (!plate_number) {
        errors["plate_number"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_lpn.required"
          ),
        ];
      }
      if (!manufacturer_id) {
        errors["manufacturer_id"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.manufacturer.required"
          ),
        ];
      }
      if (!year) {
        errors.year = [
          t("permit_application.apply_for_permit.labels.step2.year.required"),
        ];
      }
      if (!model) {
        errors.model = [
          t("permit_application.apply_for_permit.labels.step2.model.required"),
        ];
      }
      if (!color) {
        errors.color = [
          t("permit_application.apply_for_permit.labels.step2.color.required"),
        ];
      }
      if (!registration_state) {
        errors.registration_state = [
          t(
            "permit_application.apply_for_permit.labels.step2.registration_state.required"
          ),
        ];
      }
      if (!vehicle_category) {
        errors["vehicle_category"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.category.required"
          ),
        ];
      }
      if (vehicle_category === "government" && !institution_name) {
        errors["institution_name"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.institution_name.required"
          ),
        ];
      }
      if (!sub_class) {
        errors["sub_class"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_sub_class.required"
          ),
        ];
      }
      if (!vehicle_class) {
        errors["vehicle_class"] = [
          t("permit_application.apply_for_permit.labels.step2.class.required"),
        ];
      }
      if (!vehicle_type) {
        errors["vehicle_type"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_type.required"
          ),
        ];
      }
      if (!id_type) {
        errors["id_type"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.id_type.required"
          ),
        ];
      }
      if (!id_number) {
        errors["id_number"] = [
          t("permit_application.apply_for_permit.labels.step2.id_number"),
        ];
      }
      if (!full_name) {
        errors["full_name"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.full_name.required"
          ),
        ];
      }
      if (!birthday) {
        errors["birthday"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.date_of_birth.required"
          ),
        ];
      }
      if (birthday > moment().subtract(18, "years")._d) {
        errors["birthday"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.date_of_birth.must_be_18"
          ),
        ];
      }
      if (!home_phone) {
        errors["home_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.home_phone.required"
          ),
        ];
      }
      if (!address1) {
        errors["address1"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.address1.required"
          ),
        ];
      }
      if (!city) {
        errors["city"] = [
          t("permit_application.apply_for_permit.labels.step2.city.required"),
        ];
      }
      if (!country_code) {
        errors["country_code"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.country.required"
          ),
        ];
      }
      if (!state_code) {
        errors["state_code"] = [
          t("permit_application.apply_for_permit.labels.step2.state.required"),
        ];
      }
      if (!postal_code) {
        errors["postal_code"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.zip_code.required"
          ),
        ];
      }
      if (!email) {
        errors["email"] = [
          t("permit_application.apply_for_permit.labels.step2.email.required"),
        ];
      }
      if (email && !email?.match(isValidEmail)) {
        errors["email"] = [
          t("permit_application.apply_for_permit.labels.step2.email.invalid"),
        ];
      }
      if (!work_phone && permitType?.name !== "Visitor Permit") {
        errors["work_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.work_phone.required"
          ),
        ];
      }
      if (plateNError) {
        errors["plate_number"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.vehicle_lpn.already_present"
          ),
        ];
      }
      if (emailErr) {
        errors["email"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.email.already_present"
          ),
        ];
      }
      if (!isUSWorkPhone  && permitType?.name !== "Visitor Permit") {
        errors["work_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.work_phone.invalid"
          ),
        ];
      }
      if (!isUSHomePhone) {
        errors["home_phone"] = [
          t(
            "permit_application.apply_for_permit.labels.step2.home_phone.invalid"
          ),
        ];
      }
    }

    if (step === 3 && permitType?.name !== "Carpool Permit") {
      if (!payment_methods) {
        errors["payment_methods"] = ["Payment method is Required!"];
      }
      if (!creditCardNum?.current?.value) {
        errors["credit_card_number"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.card_number.validation"
          ),
        ];
      }
      if (!holder_name?.current?.value) {
        errors["holder_name"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.holder_name.validation"
          ),
        ];
      }
      if (!cvv?.current?.value) {
        errors["cvc"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.cvc.validation"
          ),
        ];
      }
      if (!expiration_month) {
        errors["expiration_month"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_month.validation"
          ),
        ];
      }
      if (!expiration_year) {
        errors["expiration_year"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_year.validation"
          ),
        ];
      }
    }

    if (step === 4 && permitType?.name === "Carpool Permit") {
      if (!payment_methods) {
        errors["payment_methods"] = ["Payment method is Required!"];
      }
      if (!creditCardNum?.current?.value) {
        errors["credit_card_number"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.card_number.validation"
          ),
        ];
      }
      if (!holder_name?.current?.value) {
        errors["holder_name"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.holder_name.validation"
          ),
        ];
      }
      if (!cvv?.current?.value) {
        errors["cvc"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.cvc.validation"
          ),
        ];
      }
      if (!expiration_month) {
        errors["expiration_month"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_month.validation"
          ),
        ];
      }
      if (!expiration_year) {
        errors["expiration_year"] = [
          t(
            "events.city_events.get_pass_form.payment.credit_card.expiration_year.validation"
          ),
        ];
      }
    }

    return Object.assign(
      errors,
      validateCommuterAttrs(
        formApiRef.current.getValue("commuter_attributes"),
        formApiRef.current?.getValue("commuters_per_vehicle")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[emailErr, plateNError, currentLocation, formApiRef, isUSHomePhone, isUSWorkPhone, permitType, step, t]);


  const validateUserAttrs = (attrs={}, type) => {
    const { full_name, birthday, home_phone, address1, city, country_code, state_code, postal_code,  email, work_phone/* Add more fields as needed */ } = attrs
    const errors = {};
    // eslint-disable-next-line 
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if(!full_name){errors[`${type}[full_name]`] = [t("permit_application.apply_for_permit.labels.step2.full_name.required")]}
    if(!birthday){errors[`${type}[birthday]`] = [t("permit_application.apply_for_permit.labels.step2.date_of_birth.required")]}
    if(birthday > moment().subtract(18, "years")._d){errors[`${type}[birthday]`] = [t("permit_application.apply_for_permit.labels.step2.date_of_birth.must_be_18")]}
    if(!home_phone){errors[`${type}[home_phone]`] = [t("permit_application.apply_for_permit.labels.step2.home_phone.required")]}
    if(!address1){errors[`${type}[address1]`] = [t("permit_application.apply_for_permit.labels.step2.address1.required")]}
    if(!city){errors[`${type}[city]`] = [t("permit_application.apply_for_permit.labels.step2.city.required")]}
    if(!country_code){errors[`${type}[country_code]`] = [t("permit_application.apply_for_permit.labels.step2.country.required")]}
    if(!state_code){errors[`${type}[state_code]`] = [t("permit_application.apply_for_permit.labels.step2.state.required")]}
    if(!postal_code){errors[`${type}[postal_code]`] = [t("permit_application.apply_for_permit.labels.step2.zip_code.required")]}
    if(!email){errors[`${type}[email]`] = [t("permit_application.apply_for_permit.labels.step2.email.required")]}
    if(email && !(email?.match(isValidEmail))){errors[`${type}[email]`] = [t("permit_application.apply_for_permit.labels.step2.email.invalid")]}
    if(!work_phone){errors[`${type}[work_phone]`] = [t("permit_application.apply_for_permit.labels.step2.work_phone.required")]}
    if(plateNError){errors[`${type}[plate_number]`] = [t("permit_application.apply_for_permit.labels.step2.vehicle_lpn.already_present")]}
    if(emailErr){errors[`${type}[email]`] = [t("permit_application.apply_for_permit.labels.step2.email.already_present")]}
    if(!isUSWorkPhone){errors[`${type}[work_phone]`] = [t("permit_application.apply_for_permit.labels.step2.work_phone.invalid")]}
    if(!isUSHomePhone){errors[`${type}[home_phone]`] = [t("permit_application.apply_for_permit.labels.step2.home_phone.invalid")]}
    return errors
  }


  const validateCommuterAttrs = (commuterAttrs={}, totalComutters=0) => {

    if(step === 3 && permitType.name === 'Carpool Permit'){
      let errors = {}
      times(totalComutters, idx => {
        const fieldErrors = validateUserAttrs(commuterAttrs[`commuter-${idx + 1}`], `commuter_attributes[commuter-${idx + 1}]`)
        errors = {...errors, ...fieldErrors}
      })
  
      return errors;
    }
  }

  const openInnModal = (idx, userType) => {
    setCommuterId(idx)
    setUserType(userType)
    openInnerModal('add_vehicle')
  }

  const renderCommuters = (totalComutters, commuterValues={}) => {
    const initialFormValues = JSON.parse(localStorage.getItem('allCommutersData')) || {};
    const customLabelClass = styles.commuterLabel
    const customInputClass = styles.commuterFormInput
    let allCommuterFields = []
    if(totalComutters && totalComutters > 0){
      times(totalComutters, (idx) => {
        const id_type = commuterValues[`commuter-${idx + 1}`]?.id_type
        const userFields = fieldsIdentification(
          true, 
          `commuter_attributes[commuter-${idx + 1}]`, 
          id_type,
          {customInputClass, customLabelClass, customDropdownInputClass: styles.formDropdownInput, stateList ,countries, initialCountry, t},
          t
        )
        allCommuterFields.push(userFields)
      })
    }
    if(isEmpty(allCommuterFields)) return
    return <React.Fragment>
      {
        allCommuterFields.map((userFields, idx) => {
          
          return (
            <React.Fragment key={idx}>
              <CommutersCard
                key={idx}
                headerClassName={styles.headerClass}
                className={`${styles.collapsable} w-100 pr-2`}
                index={idx}
                openCardIndex={openCardIndex ? openCardIndex : 0}
                setOpenCardIndex={setOpenCardIndex}
                header={`${t("permit_application.apply_for_permit.commuter")} ${idx + 1}`}
              >
                {userFields.map((fields, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Col className='d-flex flex-wrap px-0' xs={12}>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(0,1), {...ownerFieldsProps, errors})}
                        </div>
                        {<div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(1,2), {...ownerFieldsProps, errors})}
                      {commuterValues[`commuter-${idx + 1}`]?.id_type && commuterValues[`commuter-${idx + 1}`]?.id_number && index === 0 &&
                      <Button
                        type='button'
                        className={ errors?.id_number ? styles.fetchBtnErrorStyle1 : styles.fetchBtn1}
                        onClick={() => fetchOwnerDetails(commuterValues[`commuter-${idx + 1}`]?.id_type, commuterValues[`commuter-${idx + 1}`]?.id_number, idx + 1)}
                      >
                        {isFetchingOwner? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}
                      </Button>}
                        </div>}
                      </Col>
                      <Col className='d-flex flex-wrap px-0'>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(2,5), {...ownerFieldsProps, errors})}
                        </div>
                        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(5,8), {...ownerFieldsProps, errors})}
                        </div>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
                        </div>
                        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(9,10), {...countryFieldProps, errors})}
                        </div>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
                        </div>
                        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(11,12), {...vehicleFieldProps, errors})}
                        </div>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(12,13), {...vehicleFieldProps, errors})}
                        </div>
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Button
                  type="button"
                  className={styles.addVehicleBtn}
                  onClick={() =>
                    openInnModal(
                      idx + 1,
                      `vehicle_attributes[commuter-${idx + 1}]`
                    )
                  }
                >
                  {!isEmpty(initialFormValues[`commuter-${idx + 1}`])
                    ? `${initialFormValues[`commuter-${idx + 1}`]?.color} ${
                        manufacturers.find(
                          (m) =>
                            m.value ===
                            initialFormValues[`commuter-${idx + 1}`]
                              ?.manufacturer_id
                        )?.label
                      } ${initialFormValues[`commuter-${idx + 1}`]?.model}`
                    :  t("permit_application.apply_for_permit.add_vehicle")}
                  <EditIcon
                    style={{ marginBottom: "5px", marginRight: "2px" }}
                  />
                </Button>
              </CommutersCard>
            </React.Fragment>
          );
        })
      }
    </React.Fragment>
  }


  const validatePlateAndEmail = async() => {
    setErrors({})
    const plateNo = formApiRef?.current?.getValue('plate_number')
    const email = formApiRef?.current?.getValue('email')
    setPlateNError(false);
    setEmailErr(false);
    try {
      // eslint-disable-next-line
      await validateStep2({
        plate_number: plateNo,
        email: email,
      });
    } catch (error) {
      console.log(error?.response?.data?.errors)
      if (!isEmpty(error?.response?.data?.errors?.plate_number)) {
        setPlateNError(true);
      } 
      if (!isEmpty(error?.response?.data?.errors?.email)) {
        setEmailErr(true);
      }
    }
  }

  const validatePhoneNumbers = () => {
    const usPhoneNumberRegex = /^\+?1[2-9]\d{9}$/;
    const work_phone = formApiRef?.current?.getValue('work_phone')
    const home_phone = formApiRef?.current?.getValue('home_phone')
    setIsUSHomePhone(usPhoneNumberRegex.test(home_phone))
    setIsUSWorkPhone(usPhoneNumberRegex.test(work_phone))
  }
  

  const lpnFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {onChange: (_e) => validatePlateAndEmail()}
  }

  const ownerFieldsProps = {
    lSize: 4,
    iSize: 7,
    events: {onChange: (_e) => setErrors({})}
  
  }
  
  const emailFieldsProps = {
    lSize: 4,
    iSize: 7,
    events: {onChange: (_e) => validatePlateAndEmail()}
  
  }

  const phoneFieldsProps = {
    lSize: 4,
    iSize: 7,
    events: {onChange: (_e) => validatePhoneNumbers()}
  }

  const singleFieldsProps = {
    lSize: 4,
    iSize: 8,
    events: {onChange: (_e) => setErrors({})}
  }

  const fetchCurrentLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      setIsFetchingLocation(true)
      navigator.geolocation.getCurrentPosition(
  
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            // Using OpenStreetMap Nominatim API for reverse geocoding
  
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
  
            if (!response.ok) {
              throw new Error('Failed to fetch address details');
            }
  
            const data = await response.json();
            const city = data.address.city || '';
            const street = data.address.road || '';
            const state = data.address.state || '';
            const country = data.address.country || '';
            const zip = data.address.postcode
  
  
            setCurrentLocation({
              ltd: latitude,
              lng: longitude,
              city,
              street,
              state,
              country,
              zip
            })

            setUserLocation({
              ltd: latitude,
              lng: longitude,
              city,
              street,
              state,
              country,
              zip
            })
            setFetchedCurrentLocation(true)
            fetchNearbyParkingLots()
          } catch (error) {
            setErrors(`Error: ${error.message}`);
            setFetchedCurrentLocation(false)
          } finally{
            setIsFetchingLocation(false)
            fetchNearbyParkingLots()
          }
        },
        (error) => {
          setErrors(`${error.message}`);
        }
      );
    } else {
      setErrors('Geolocation is not supported by your browser.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentLocation, currentLocation, setErrors]);

  useEffect(() => {
    if(currentLocation){
      fetchNearbyParkingLots()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation])

  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownsSearch('permit_type_list'))
        setPermitTypes( permitTypesList.data)
      } catch (_error) {
        
      }
    }
    if(isEmpty(permitTypes)) fetchPermitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchNearbyParkingLots = async (values) => {
    setErrors({})
    setAlertMsg(" ")
    const latitude = values?.ltd;
    const longitude = values?.lng;
    const zipcode = values?.zip;
    const ptId = formApiRef.current.getValue('permit_type_id')
    
    if(!permitTypeID){
      return
    }
    try {
      const response = await getParkingLots({ permit_type_id: ptId, ltd: latitude? latitude : currentLocation?.ltd, lng: longitude? longitude : currentLocation.lng, zipcode: zipcode? zipcode : currentLocation?.zip });
      setNearbyParkingLots(response.data);
      if(isEmpty(response.data)){setAlertMsg(" ") }
    } catch (error) {
    }
  };


  useEffect(() => {
    if(!checkP){
      return
    }
    const checkPermitAvailability = async () => {
      try {
        const response = await checkParkingLot({id: selectedParkingLots?.id, name: permitType?.name});
        if(response.status === 200){
          setAlertMsg(t("permit_application.apply_for_permit.labels.step1.permits_available"))
          setLotAvailability(true);
        }
      } catch (error) {
          if(error?.response?.data?.status){
            setLotAvailability(false); setAlertMsg(t("permit_application.apply_for_permit.labels.step1.permits_unavailable"))
          }
      }
    };
    checkPermitAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParkingLots?.id, setLotAvailability, permitType]);

  

useEffect(() => {
  const fetchPermitType = async () => {
    try {
      const response = await getPermitType(permitTypeID)
      setPermitType(response.data)
    } catch (error) {
    } finally {
    }
  }
   if(permitTypeID){fetchPermitType();}
},[permitTypeID, isSaving])

  
const doubleFieldProps = {
  lSize: 4,
  iSize: 8,  
  events: {
    onChange: (_e) => setErrors({}) || setAlertMsg('')
  }
}

const permitTypeFieldProps = {
  lSize: 4,
  iSize: 8, 
  events: {
    onChange: (_e) => fetchNearbyParkingLots()
  } 
}




useEffect(() => {
  const fetchPermitTypeValues = async () => {
    try {
      if(permitType.name === 'Visitor Permit'){
        setValidityOptions(permitType?.validity.map(val => ({value: val, label: capitalize(val)})))
        setPeriodDropdown([])
      }
      if(permitType?.name !== 'Visitor Permit') {
        setPeriodDropdown(permitType?.period)
        formApiRef.current.setValue('validity', capitalize(permitType?.validity))
      }
      formApiRef.current.setValue('parking_hour_from',`${permitType?.parking_hour_from}`)
      formApiRef.current.setValue('parking_hour_to', `${permitType?.parking_hour_to}`)
      formApiRef.current.setValue('hourly_rate', `${permitType?.hourly_rate}`)
      formApiRef.current.setValue('permit_type', `${permitType?.name}`)
      formApiRef.current.setValue('period', '')
      if(permitType?.name === 'Carpool Permit'){formApiRef.current.setValue('commuters_per_vehicle', `${permitType?.commuters_per_vehicle}`)}
      if(permitType?.expiring_permit === 'NO'){formApiRef.current.setValue('total_cost', permitType?.total_cost)}
        } catch (error) {
      console.log(error)
    }
  }
  if(!isEmpty(permitType)) fetchPermitTypeValues()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[permitType, isSaving])



  const handleNext = () => {
    setErrors({})
    setFilePresence()
    setMsg('')
    const validationErrors = validateFields()
    if(!isEmpty(validationErrors) || (step ===2 && (!isValidFile))){
      if(step===2 && !mute && (!filePresence)){
        setFilePresence(false); setMsg(t("permit_application.apply_for_permit.labels.step2.please_upload_id.required"))
      }else{
        setFilePresence(true);
        setAlertMsg('')
      }
      setErrors(validationErrors)
      return
    }else if(step < 8) {
      setStep(step + 1);
    }
  };


  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setPermitName(permitType?.name)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  
  const handlePrevious = () => {
    if (step > 1) {
     setStep(step - 1);
    }
  };

  const initialValues = {
    parking_hour_from: "",
    country_code: 'US'
  }

  const attrs = {
    periodOptions: permitType?.name === 'Visitor Permit' ? periodDropdown : isEmpty(periodDropdown) ? [] : periodDropdown?.map((pr) => { return {value: pr, label: capitalize(pr)}}),
    permit: permit,
    permitTypes: permitTypes,
    manufacturers:  manufacturers,
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInputClass: styles.formDropdownInput,
    customExpiryInputClass: styles.cardInput,
    customVehicleInputClass: styles.plateInput,
    customSingleInputClass: styles.formSingleInput,
    t: t,
    months: months,
    subclasses,
    permitType: permitType,
    validityOptions: validityOptions
  }
  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        userLocation={userLocation}
        fetchNearbyParkingLots={fetchNearbyParkingLots}
        t={t}
      />
    );
  }
  
  const getFields = () => {
    return permitFields({ mute,
                          ...attrs, 
                          renderLocationModal: renderLocationModal.bind(this),
                          identification: idType,
                          permitOption: permit,
                          countries,
                          statesList: stateList,
                          customDropdownInputClass: styles.formDropdownInput,
                          customExpiryDropdownInputClass: styles.expiryDropdown,
                          customExpiryLabel: styles.expiryDropdownLabel,
                          cutomExpiryLabel2: styles.cardLabels2,
                          years,
                          initialCountry,
                          propertyTypeList,
                          residenceTypeList
                        })
  }
  
  const fetchVehicleDetails = useCallback(async() => {
    setIsFetchingVehicle(true)
    try {
      const response =  await getVehicleDetails(plateNumber)
      if(response.data){setVehicle(response.data.vehicle_details)}

      if(response.data?.vehicle?.toString() === 'Not found'){setVehicle([]);
        toast.error("Vehicle Not Found")
      }

    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingVehicle(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[plateNumber])

  const fetchOwnerDetails = useCallback(async() => {
    const idNumber = formApiRef.current.getValue('id_number')
    setIsFetchingOwner(true)
    try {
      const response =  await getOwnerDetails({id_type: idType, id_number:idNumber})
      if(response.data === null){
        toast.error("User Not Found")
      }
      var tempBirthDate = new Date(response?.data?.birthday);
      var formattedBirthday = dayjs(tempBirthDate);
      formApiRef.current.setValue('full_name',`${response?.data?.first_name} ${response?.data?.last_name}`)
      formApiRef.current.setValue('birthday', formattedBirthday? formattedBirthday : '')
      formApiRef.current.setValue('home_phone', `${response.data.home_phone}`)
      formApiRef.current.setValue('email', response.data.email? response.data.email : '')
      formApiRef.current.setValue('work_phone', `${response.data.work_phone}`)
      formApiRef.current.setValue('address1', response?.data?.home_address?.address1)
      formApiRef.current.setValue('address2', response?.data?.home_address?.address2? response?.data?.home_address?.address2 : '' )
      formApiRef.current.setValue('state_code', response?.data?.home_address?.state? response?.data?.home_address?.state : '' )
      formApiRef.current.setValue('postal_code', response?.data?.home_address?.zip)
      formApiRef.current.setValue('city', response?.data?.home_address?.city)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingOwner(false)
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[idType, formApiRef])

  useEffect(() => {

    const fetchVehicleValues = async () => {
      try {
        formApiRef.current.setValue('manufacturer_id',capitalize(vehicle.make))
        formApiRef.current.setValue('year', vehicle.year)
        formApiRef.current.setValue('color', vehicle.color)
        formApiRef.current.setValue('model', vehicle.model)
        formApiRef.current.setValue('registration_state', vehicle.state)
      } catch (error) {
        console.log(error)
      } finally {
  
      }
    }
    if(vehicle){fetchVehicleValues();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicle, isSaving])
  

  const vehicleFieldProps = {
    lSize: 4,
    iSize: 7,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const vehicleClassProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => fetchVehicleSubclasses(e)
    }
  }

  const vehicleField1Props = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const countryFieldProps = {
    lSize: 4,
    iSize: 7,
    events: {
      onChange: (_e) => fetchStates()
    }
  }
  
  const renderVehicleOwnerFields = (fields) => {
    return(
      <Col className='d-flex flex-wrap px-0 pl-1' xs={11}>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(0,2), {...ownerFieldsProps, errors})}
          {permitType.name !== 'Visitor Permit' && renderFields(fields.slice(2,3), {...phoneFieldsProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(3,4), {...emailFieldsProps, errors})}
          {permitType.name === 'Visitor Permit' && renderFields(fields.slice(2,3), {...phoneFieldsProps, errors})}
          {permitType.name !== 'Visitor Permit' && renderFields(fields.slice(4,5), {...phoneFieldsProps, errors})}
          {permitType.name !== 'Visitor Permit' && renderFields(fields.slice(5,6), {...ownerFieldsProps, errors})}
        </div>
        {permitType.name !== 'Visitor Permit' && <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(6,7), {...vehicleFieldProps, errors})}
        </div>}
        {permitType.name !== 'Visitor Permit' && <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(7,8), {...countryFieldProps, errors})}
        </div>}
        {permitType.name !== 'Visitor Permit' && <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
        </div>}
        {permitType.name !== 'Visitor Permit' && <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(9,10), {...vehicleFieldProps, errors})}
        </div>}
        {permitType.name !== 'Visitor Permit' && <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
        </div>}
        {permitType.name === 'Residential Permit' && <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(13,14), {...vehicleFieldProps, errors})}
        </div>}
        {permitType.name === 'Residential Permit' && <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(14,15), {...vehicleFieldProps, errors})}
        </div>}
      </Col>
    )
  }

  const renderResidentilFields = (fields) => {
    return(
      <Col className='d-flex flex-wrap px-0 pl-1' xs={11}>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(11,12), {...vehicleFieldProps, errors})}
          {renderFields(fields.slice(5,6), {...ownerFieldsProps, errors})}
          {renderFields(fields.slice(7,8), {...countryFieldProps, errors})}
          {renderFields(fields.slice(9,10), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(12,13), {...vehicleFieldProps, errors})}
          {renderFields(fields.slice(6,7), {...vehicleFieldProps, errors})}
          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
        </div>
      </Col>
    )
  }

  const renderIDFields = (fields) => {
    return(
      <Col className='d-flex flex-wrap px-0 pl-1' xs={11}>
        <div className={`${styles.fieldset} pl-2`}>
          {renderFields(fields.slice(0,1), {...ownerFieldsProps, errors})}
        </div>
        {idType && <div className={`${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
          {renderFields(fields.slice(1,2), {...ownerFieldsProps, errors})}
        </div>}
      </Col>
    )
  }

  const cardMFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_month')
    }
  }
  const cardYFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_year')
    }
  }




  const fields = vehicleFields(attrs, {identification: idType })
  
  const paymentFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (_e) => showPaymentOption()
    }
  }

  const showPaymentOption = () =>{
    if (formApiRef.current?.getValue('payment_methods')===0){
      setGooglePayShow(true)
      setShowCreditCardFields(false)
  
    }else if (formApiRef.current?.getValue('payment_methods')===1) {
      setShowCreditCardFields(true)
      setGooglePayShow(false)
    }else{
      
    }
  }
  
  useEffect(() => {
    const vp = formApiRef?.current?.getValue('period')
  
    const fetchTotalCost= async () => {
      if(!checkV){
        return
      }
      try {
        const response = await getTotalCost({permit_type_id: permitTypeID, validity_period: vp  })
        setTotalCost(response.data)
        if(permitType.expiring_permit === 'YES'){formApiRef.current.setValue('total_cost', response.data)}
        if(permitType.expiring_permit === 'YES'){formApiRef.current.setValue('permit_cost', response.data)}else{formApiRef.current.setValue('permit_cost', permitType?.total_cost)}
        formApiRef.current.setValue('payment_amount', response?.data)
  
      } catch (error) {
        console.log(error)
      } finally {
      }
    }
    if(permitTypeID && vp){fetchTotalCost();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permitTypeID,permitTypePeriod])

  const submitValues = (values) => {
    const validationErrors = validateFields()
   
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }
    const storageKey = 'allCommutersData';
    const initialFormValues = JSON.parse(localStorage.getItem(storageKey)) || {};
    const commuterVals =  values.commuter_attributes 
    const updatedCommuterAttributes = commuterVals? Object.keys(commuterVals)?.reduce((acc, idx) => {
      const commuterHash = commuterVals[idx];
      const vehicleDetails = Object.keys(initialFormValues[`${idx}`]).filter(objKey =>
        objKey !== 'year' && objKey !== 'vehicle_category').reduce((newObj, key) =>
        {
          newObj[key] = initialFormValues[`${idx}`][key];
          return newObj;
        }, {}
      );
      const updatedCommuterHash = {
        ...commuterHash,
        home_address: commuterHash.address1,
        vehicle_attributes: {...vehicleDetails, manufacture_year: initialFormValues[`${idx}`]?.year}, // Add or update attributes here
      };
      // Updating the accumulator with the updated commuter hash
      return {
        ...acc,
        [idx]: updatedCommuterHash,
      };
    }, {}) : ''
    const arrayOfCommuters = Object.entries(updatedCommuterAttributes).map(([commuterId]) => (
      updatedCommuterAttributes[commuterId]
    ));

    const data = { 
      category: values?.category,
      permit_type_id: values?.permit_type_id,
      parking_lot_id: selectedParkingLots?.id,
      validity_period: permitTypePeriod,
      total_cost: couponApplied? values?.payment_amount?.toString() : values?.permit_cost?.toString(),
      start_date: values?.applied_from_date,
      auto_renewable: `${checkboxref.current.checked}`,
      type_of_property: values?.type_of_property,
      type_of_residence: values?.type_of_residence,
      vehicle_attributes: {
        registration_state: values?.registration_state,
        color: values?.color,
        plate_number: values?.plate_number,
        model: values?.model,
        manufacture_year: values?.year,
        category: values?.vehicle_category,
        vehicle_class: values?.vehicle_class,
        institution_name: values?.institution_name,
        sub_class: values?.sub_class,
      },
      primary_user_attributes: {
        vehicle_lpn: values?.plate_number,
        full_name: values?.full_name,
        id_type:  values?.id_type,
        id_number: values?.id_number,
        home_phone: values?.home_phone,
        work_phone: values?.work_phone,
        email: values?.email,
        home_address: values?.address1,
        birthday: values?.birthday,
        id_proof: base64IdProof
  
      },
      home_address:{
        address1: values?.address1,
        address2: values?.address2,
        phone: values?.home_phone,
        city: values?.city,
        country_code: values?.country_code,
        state_code: values?.state_code,
        postal_code: values?.postal_code,
        full_name: values?.resident_full_name,
        
      },
      commuters_attributes: arrayOfCommuters? arrayOfCommuters : '',
      purpose_of_visit: values?.purpose_of_visit,
      gateway_params:{
        amount: couponApplied? values?.payment_amount?.toString() : values?.permit_cost.toString(),
        credit_card_attributes:{
          number:  creditCardNum?.current?.value,
          cvv: cvv?.current?.value,
          holder_name: holder_name?.current?.value,
          expiration_month: values?.expiration_month,
          expiration_year: values?.expiration_year,
          should_store: savecheckboxref.current.checked ? 1 : 0
        },
        
      }
    }
    applyPermitApplication(data)
      
  }

  const submitValuesGPay = (paymentToken) => {
    const validationErrors = validateFields()
   
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }
    const values = formApiRef?.current?.getValues()
    const storageKey = 'allCommutersData';
    const initialFormValues = JSON.parse(localStorage.getItem(storageKey)) || {};
    const commuterVals =  values.commuter_attributes 
    const updatedCommuterAttributes = commuterVals? Object.keys(commuterVals)?.reduce((acc, idx) => {
      const commuterHash = commuterVals[idx];
      const updatedCommuterHash = {
        ...commuterHash,
        vehicle_attributes: initialFormValues[`${idx}`], // Add or update attributes here
      };
      // Updating the accumulator with the updated commuter hash
      return {
        ...acc,
        [idx]: updatedCommuterHash,
      };
    }, {}) : ''
    const arrayOfCommuters = Object.entries(updatedCommuterAttributes).map(([commuterId, commuterData]) => ({
      [commuterId]: commuterData,
    }));
  
    const data = { 
      category: values?.category,
      permit_type_id: values?.permit_type_id,
      parking_lot_id: selectedParkingLots?.id,
      validity_period: permitTypePeriod,
      total_cost: couponApplied? values?.payment_amount?.toString() : values?.permit_cost?.toString(),
      start_date: values?.applied_from_date,
      auto_renewable: `${checkboxref.current.checked}`,
      type_of_property: values?.type_of_property,
      type_of_residence: values?.type_of_residence,
      vehicle_attributes: {
        registration_state: values?.registration_state,
        color: values?.color,
        plate_number: values?.plate_number,
        model: values?.model,
        manufacture_year: values?.year,
        category: values?.vehicle_category,
        vehicle_class: values?.vehicle_class,
        institution_name: values?.institution_name,
        sub_class: values?.sub_class,
      },
      primary_user_attributes: {
        vehicle_lpn: values?.plate_number,
        full_name: values?.full_name,
        id_type:  values?.id_type,
        id_number: values?.id_number,
        home_phone: values?.home_phone,
        work_phone: values?.work_phone,
        email: values?.email,
        home_address: values?.address1,
        birthday: values?.birthday,
        id_proof: base64IdProof
      },
      home_address:{
        address1: values?.address1,
        address2: values?.address2,
        phone: values?.home_phone,
        city: values?.city,
        country_code: values?.country_code,
        state_code: values?.state_code,
        postal_code: values?.postal_code
      },
      commuter_attributes: arrayOfCommuters? arrayOfCommuters : '',
      purpose_of_visit: values?.purpose_of_visit,
      gateway_params:{
        amount: couponApplied? values?.payment_amount?.toString() : values?.permit_cost.toString(),
        digital_wallet_attributes: {
          encryptionhandler: 'EC_GOOGLE_PAY',
          devicedata: paymentToken?.paymentMethodData?.tokenizationData?.token
        }

      }
  
      
    }
  
    applyPermitApplication(data)
  }

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesList = await startFetching(dropdownsSearch('countries_list'))
        setCountries(countriesList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(countries)) fetchCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const manufacturersList = await startFetching(dropdownsSearch('manufacturers_list'))
        setManufacturers(manufacturersList.data)
        setVManufacturers(manufacturersList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(manufacturers)) fetchManufacturers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchStates = async () => {
    setErrors({})
    formApiRef.current.setValue('state_code', '')
    const country = formApiRef.current.getValue('country_code')
    try {
      const statesList = await fetchStatesList(country? country : 'US')
      setStateList(statesList.data)
    } catch (_error) {
    }
  }


  useEffect(() => {
    fetchStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchVehicleSubclasses = async (e) => {
    try {
      const vehicleSubclassList = await dropdownsSearch("vehicle_sub_classes_list", {
        vehicle_class: e,
      });
      setSubClasses( vehicleSubclassList?.data)
    } catch (_error) {
    }  
  }

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("residence_types_list?type_of=property")).then((response) =>
          setPropertyTypeList(response.data)),
        startFetching(dropdownsSearch("residence_types_list?type_of=residence")).then((response) => 
          setResidenceTypeList(response.data)),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const applyCouponCode = useCallback(async() => {
    setCouponApplied(false)
    setValidateCoupon('')
    if(!totalCost){
      return
    }
    try {
      setApplyingCoupon(true)
      const response =  await applyCoupon({coupon_code: couponRef?.current?.value , total_cost: totalCost})
      if(response.status===201 || response.status===200){
      setCouponApplied(true)
      formApiRef.current.setValue("coupon_discount", (formApiRef.current.getValue('permit_cost') - response.data))
      formApiRef.current.setValue('payment_amount', response?.data)
      }      
      
    } catch (error) {
      
      setValidateCoupon(
        error?.response?.data?.errors?.permit_application
          ? error?.response?.data?.errors?.permit_application[0]
          : error.response.data.errors.coupon
          ? error.response.data.errors.coupon[0]
          : error.response.data.errors.total_cost
          ? error.response.data.errors.total_cost[0]
          : ""
      );
      console.log(error)
    } finally {
      setApplyingCoupon(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[totalCost])

  useEffect(() => {
    if(coupon && !couponApplied){formApiRef.current.setValue("payment_amount", totalCost)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponApplied])

  const handleExpiryYearChange  = (e) => {
    setExpiryY(e) ;
    setExpiryM('')
    formApiRef.current.setValue('expiration_month', '')
    if(e > new Date().getFullYear()){
      setMonths([1,2,3,4,5,6,7,8,9,10,12])
    } else {
      setMonths(months.filter((m) => {return m > (new Date().getMonth() + 1)}))
    }
  }
  const handleCardFieldsChange = (value, field) => {
    const updatedErrors = Object.keys(errors)
      .filter((objKey) => objKey !== field)
      .reduce((newObj, key) => {
        newObj[key] = errors[key];
        return newObj;
      }, {});

    setErrors(updatedErrors);
    if (field === "credit_card_number") {
      setCardNumber(value);
    } else if (field === "holder_name") {
      setName(value);
    } else if (field === "expiration_month") {
      setExpiryM(value);
      setFocus("expiry");
    } else if (field === "expiration_year") {
      handleExpiryYearChange(value);
    } else if (field === "cvc") {
      setCvc(value);
    }
  };

  const uploadIDProps = {
    name: "file",
    accept: ".jpg,.jpeg,.png,.pdf",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "application/pdf";
      if (!isValidFileType) {
        message.error(
          "Invalid file format. Please upload JPG, JPEG, PNG, or PDF files only."
        );
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setIdProofFileName(file?.name);
      reader.onload = () => {
        setBase64IdProof(reader.result);
      };
      setFilePresence(true)
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadProgress(0);
      }
    },
    customRequest: ({ file, onError, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
  };

  const removeIDProof = () => {
    setBase64IdProof(null);
    setIdProofFileName("");
  };


  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form initialValues={initialValues}  getApi={setFormApi} className={styles.form}  onSubmit={submitValues} >
        {({ formState }) => {
          const {permit, category, permit_type_id, plate_number, id_type, period, location, manufacturer_id, country_code, commuters_per_vehicle, payment_amount, permit_cost, id_number, vehicle_category} = formState.values
          setIDType(id_type)
          setPermitTypeID(permit_type_id)
          setInitialCountry(country_code)
          setPermit(permit)
          setPlateNumber(plate_number)
          if(period){setPermitTypePeriod(period)}
          const newFields = getFields()
            return (
              <Row className='mx-auto w-99'>
                <Row className={step === 1 ? `mx-auto w-100 pr-0` : 'd-none transition-container fade-out'}>
                  
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1.7`} xs={12}>
                    { renderFields(newFields[3], { ...permitTypeFieldProps, errors} )}
                  </Col>

                  <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    { renderFields(newFields[2], { ...permitTypeFieldProps, errors} )}
                  </Col>

                { permitType?.name==='Carpool Permit' && 
                  <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    { renderFields(newFields[12], { ...permitTypeFieldProps, errors} )}
                  </Col>
                }

                  <Row className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                         <p className={styles.noteLabel}>{t("permit_application.apply_for_permit.labels.step1.please_select_location")}</p>
                  </Row>

                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[1], { ...doubleFieldProps, errors} )}
                    <Row className={styles.currentLoc}>
                      <Button type='button' className={isFetchingLocation? styles.fetching : styles.locationbtn} onClick={fetchCurrentLocation}>
                        { isFetchingLocation? 
                        `${t("permit_application.apply_for_permit.labels.step1.fetching_user_location")}..` : 
                          t("permit_application.apply_for_permit.labels.step1.current_location")} <CrosshairsIcon style={{marginTop:"-2px"}}/>
                      </Button>
                    </Row>
                  </Col>

                  <label className={currentLocation && permit_type_id && category && (location || fetchedCurrentLocation) ? styles.formLabel : 'd-none'} style={{marginLeft: "-5px", fontWeight: 1000, fontSize:"13px"}}>
                    {t("permit_application.apply_for_permit.labels.step1.parking_lot_nearby")}:
                  </label>
                  <Row className={currentLocation && permit_type_id && category && (location || fetchedCurrentLocation) ? `mx-auto w-100`: 'd-none'}>
                  <Col className="d-flex flex-wrap justify-content w-100">
                    <div className={styles.scrollableContainer}>
  
                      {nearbyParkingLots?.map((parkingLot) => (
                        
                        <div
                          key={parkingLot.name}
                          className={validPLots && validPLots?.includes(parkingLot)? `${styles.validParkingLotBox} ${
                            selectedParkingLots && selectedParkingLots.id === parkingLot.id
                              ? styles.selectedParkingLot
                              : ''
                          }` : invalidPLots && invalidPLots?.includes(parkingLot)? `${styles.invalidParkingLotBox} ${
                            selectedParkingLots && selectedParkingLots.id === parkingLot.id
                              ? styles.selectedParkingLot
                              : ''
                          }` : `${styles.parkingLotBox} ${
                            selectedParkingLots && selectedParkingLots.id === parkingLot.id
                              ? styles.selectedParkingLot
                              : ''
                          }` }
                          onClick={() => handleCheckboxChange(parkingLot)}
                        >
                          
                        <span>
                         <div className={styles.parkingLotCell}>
                            <input
                               type="checkbox"
                               checked={selectedParkingLots===parkingLot}
                               onChange={() => handleCheckboxChange(parkingLot) && checkPermitAvailability(parkingLot)}
                               disabled={(!selectedParkingLots=== parkingLot && state.disableCheckboxes) }
                               label={`${parkingLot.name}`}
                               className={styles.uniqueCheckbox}
                               />
                             <span style={{textAlign: 'left'}}>
                               <span style={{fontSize:"15px", fontWeight:500, color:"grey", position:"relative"}}>{parkingLot.name} ({Math.round(((parseFloat(parkingLot.distance.split(" ")[0]) * 0.000621371192) + Number.EPSILON) * 100)/100 } ml)</span> <br/> {parkingLot.address}
                             </span>
                         </div>
                        </span>

                      </div>
                      ))}
                    </div>
                    {alertMsg && lotAvailablity  &&
                      <p className={styles.notify}>{alertMsg}</p>
                    }
                    {
                      alertMsg && !lotAvailablity &&
                      <p className={styles.alertMsg}>{alertMsg}</p>
                    }
                  </Col>
                </Row>

                  
                { map(newFields[4], (field, idx) => {
                    return (
                      <React.Fragment>
                        {permitType?.expiring_permit === 'YES' &&
                          <Col key={idx} className={(location || fetchedCurrentLocation) && permit_type_id && category ? `m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2 pr-0` : 'd-none'} xs={6}>
                          { renderField(field, { ...valPrProps, errors} )}
                          </Col>
                        }
                      </React.Fragment>
                    )
                })
                }
                  
                { map(newFields[5], (field, idx) => {
                  if(idx === 2) {
                    field = {
                      ...field,
                      icon: dollarIcon(), 
                      customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                    }
                  }

                  if(idx === 3) {
                    field = {
                      ...field,
                      icon: dollarIcon(), 
                      customInputClass: attrs.customInputClass.concat(' ', styles.iconInput),

                    }
                  }
                 
                  return (
                    <React.Fragment>
                      {permitType?.expiring_permit === 'NO' && idx !== 2  &&
                        <Col key={idx} className={(location || fetchedCurrentLocation) && permit_type_id && category ? `m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2` : 'd-none'} xs={6}>
                        { renderField(field, { ...rateCostProps, errors} )}
                        </Col>
                      }
                      {permitType?.expiring_permit === 'YES' &&
                        <React.Fragment>
                          {permitType.name === 'Visitor Permit' && ( idx === 3 || idx ===4 ) && idx !==2 && 
                            <Col key={idx} className={(location || fetchedCurrentLocation) && permit_type_id && category ?`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2` : 'd-none'} xs={6}>
                            { renderField(field, { ...rateCostProps, errors} )}
                            </Col>
                          }
                          {permitType.name === 'Visitor Permit' && (idx ===0 || idx === 1) && idx !==2 && period === '1 day' && 
                            <Col key={idx} className={(location || fetchedCurrentLocation) && permit_type_id && category ?`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2` : 'd-none'} xs={6}>
                            { renderField(field, { ...rateCostProps, errors} )}
                            </Col>
                          }
                          {permitType.name !== 'Visitor Permit' && 
                            <Col key={idx} className={(location || fetchedCurrentLocation) && permit_type_id && category ?`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2` : 'd-none'} xs={6}>
                            { renderField(field, { ...rateCostProps, errors} )}
                            </Col>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  )
                }) }

                </Row>

                <Row className={step === 2 ? `mx-auto w-98` : `d-none`}>
                 

                  <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    { renderFields(newFields[6], { ...lpnFieldProps, errors} )}
                    <Button type='button' className={errors?.plate_number ?`${styles.okBtnErrorStyle}`:`${styles.okBtn}`}  onClick={fetchVehicleDetails}>{isFetchingVehicle? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}</Button>
                  </Col>
                 
                <div className="d-flex align-items-center my-3 w-100">
                  <span className={styles.detailsLabel}>{t("permit_application.apply_for_permit.labels.step2.vehicle_details")}</span>
                  <span className="border border-1  ml-1"></span>
                </div>
                  
                 
                  <Row className="m-0 p-0 pl-1" xs={12}>
                  <Col className='d-flex flex-wrap px-1' xs={11}>
                    
                    { map(fields[0], (field, idx) => {
                      if(manufacturer_id === 0 && idx%2 !== 0) {
                        field = {
                          ...field,
                          customLabelClass: attrs.customLabelClass.concat(' ', styles.customLabelvehicle)
                        }
                      }
                      if(manufacturer_id !== 0 && idx%2 === 0 && idx !== 0) {
                        field = {
                          ...field,
                          customLabelClass: attrs.customLabelClass.concat(' ', styles.customLabelvehicle)
                        }
                      }
                      return (
                        <React.Fragment>
                          {(!manufacturer_id || manufacturer_id !== 0) && idx !== 1 && idx !== 10 &&
                            <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2`} xs={6}>
                              {idx !== 6 && renderField(field, { ...vehicleField1Props, customLabelClass: styles.customLabelvehicle, errors} )}
                              {idx === 6 &&  renderField(field, { ...vehicleClassProps, errors} )}
                            </Col>
                          }
                          {manufacturer_id === 0 && idx === 1 && 
                            <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2`} xs={6}>
                              {renderField(field, { ...vehicleField1Props, errors} )}
                            </Col>
                          }
                          {vehicle_category === 'government' && idx === 10 && 
                            <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-valPr']} pl-2`} xs={6}>
                              {renderField(field, { ...vehicleField1Props, errors} )}
                            </Col>
                          }
                        </React.Fragment>
                      )
                    }) }
                    </Col>
                  </Row>

                  <div className="d-flex align-items-center my-3 w-100">
                    <span className={styles.detailsLabel}>{t("permit_application.apply_for_permit.labels.step2.owner_details")}</span>
                  </div>
                  <Col className="m-0 p-0  pl-1 pt-2" xs={12}>
                    {renderIDFields(newFields[7])}
                  </Col>

                  {idType && id_number &&
                   <Button type='button' className={ errors?.id_number ? `${styles.fetchBtnErrorStyle}` : `${styles.fetchBtn}`}  onClick={fetchOwnerDetails}>{isFetchingOwner? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}</Button>
                  }
                
                  <Col className="m-0 p-0  pl-1" xs={12}>
                    {renderVehicleOwnerFields(newFields[8])}
                  </Col>

                  {permitType?.name === 'Visitor Permit' && permitType?.purpose_of_visit && <Col className="mr-0 p-0  pl-1" style={{marginLeft: '12px'}} xs={11}>
                    {renderFields(newFields[13].slice(0,1), {...singleFieldsProps, errors})}
                  </Col>}
                  <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel2} d-flex flex-column text-justify p-1`}>
                      <span>
                        <span className={styles.boldText2}>
                          <Trans
                            i18nKey="permit_application.apply_for_permit.labels.step2.please_upload_id.label"
                            components={[<br key="1" />]}
                          />
                        </span>
                        
                      </span>
                    </Label>
                  <Row className={styles.idProofSection}>
                    <Dragger {...uploadIDProps}>
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined
                          style={{ color: idProofFileName ? "#3A9CED" : "grey" }}
                        />
                      </p>
                      <p className="ant-upload-text">
                        {!idProofFileName
                          ? t("signup.uploadText")
                          : idProofFileName}
                      </p>
                      <p className="ant-upload-hint">
                        {t("signup.formatsText")}
                      </p>
                      {!filePresence && 
                        <p className={styles.formatsPresenceErrorText}>{msg}</p>
                      }
                      {idProofFileName && (
                        <Button
                          type="text"
                          className={styles.deleteIdProofButton}
                          onClick={removeIDProof}
                          icon={<TrashIcon className={styles.removeIcon} />}
                        />
                        
                      )}
                    </Dragger>
                </Row>
                { permitType.name === 'Visitor Permit' &&
                  permitType?.details_of_resident &&
                  <React.Fragment>
                    <div className="d-flex align-items-center my-3 w-100">
                      <span className={styles.detailsLabel}>{t("permit_application.apply_for_permit.labels.step2.resident_details")}</span>
                    </div>
                  
                    <Col className="m-0 p-0  pl-1" xs={12}>
                      {renderResidentilFields(newFields[8])}
                    </Col>
                  </React.Fragment>
                }
                {(location || fetchedCurrentLocation) && base64IdProof && <Row className={styles.spacesSection}>
                  <Col className={styles.infoSection}>
                    <p>{t("permit_application.apply_for_permit.share_permit_note")}</p>
                  </Col>
                  <Col>
                    <Button type='button' className={styles.addBtn} icon={<PlusIcon className={`svg-white ${styles.addIcon}`}/>} onClick={() => openInnerModal('share_permit')}> {t("buttons.add")} </Button>
                  </Col>
                </Row>}
                </Row>

                <Col className={step === 3 && permitType?.name === 'Carpool Permit' ? `` : 'd-none'} xs={12}>
                  <p className={styles.commuterTitle}> {t("permit_application.apply_for_permit.commuter_title")} </p>
                    { renderCommuters(commuters_per_vehicle, formState?.values?.commuter_attributes) }
                </Col>
                

                <Col>
                  <Row className={(step === 3 && permitType?.name !== 'Carpool Permit') || (step === 4 && permitType?.name === 'Carpool Permit') ? `flex-column w-100 text-center` : 'd-none'}>
                    <p className={styles.modalTitle}> {t("permit_application.apply_for_permit.labels.step3.title")} </p>
                      
                      { map(newFields[9], (field, idx) => {
                          if(idx === 1) {
                            field = {
                              ...field,
                              icon: dollarIcon(), 
                              customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                            }
                          }
                          
                          return (
                            <React.Fragment>
                              { idx === 0 && 
                                <Col className={`m-0 pt-5 ${styles['fieldset-double-step3']} pl-1`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps, errors} )}
                                </Col>
                              }
                              {idx === 1 && payment_amount !== '0.0' &&
                                <Col className={`m-0 ${styles['fieldset-double-step3']} pl-1`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps, errors} )}
                                </Col> 
                              }
                              
                            </React.Fragment>
                          )
                        })
                        }
                        
                        <Row className={styles.autoRenewRow} xs={12}>
                          <Col className={styles.renewCol} xs={6}>
                            <div className={styles.renewCheckCol}>
                              <input
                                type="checkbox"
                                checked={autoRenew === true }
                                onChange={() =>  setAutoRenew(autoRenew? false : true) }
                                ref={checkboxref}
                                className={`${styles.uniqueCheckbox} ${autoRenew ? styles['style-secondary'] : ''}`}
                              />
                            </div>
                            <div className={styles.renewLabelCol} xs={4}>
                              <label className={styles.checkboxTextRenew}> 
                                <span className={styles.autorenewLabell}>{t("permit_application.apply_for_permit.labels.step3.auto_renew", {days: permitTypePeriod?.split(" ")[0]})}</span>
                              </label>
                            </div>
                          </Col>
                          <Col className={styles.applyCol} xs={6}>
                          {permit_cost > 0.0 && <React.Fragment>
                            <div className={styles.couponLabelCol} >
                              <label className={styles.applyLabel}>{t("permit_application.apply_for_permit.labels.step3.apply_coupon")}</label>
                            </div>
                            <div className={styles.couponInputCol}>
                              <input
                                type="text"
                                id="apply_coupon"
                                name="apply_coupon"
                                placeholder={t("permit_application.apply_for_permit.labels.step3.enter_coupon_code")}
                                value={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                                className={styles.couponInput}
                                ref={couponRef}
                              />
                                          
                              <Button 
                                type='button' 
                                className={styles.applyCBtn}  
                                disabled={!coupon}
                                onClick={applyCouponCode}>
                                  {applyingCoupon? btnSpinner({ className: 'spinner-border' }) : 
                                    t("permit_application.apply_for_permit.labels.step3.apply")}
                              </Button>
                            </div>
                          </React.Fragment>}
                          </Col>
                          <span className={!couponApplied ? styles.couponValidation : styles.couponApplied}>{validateCoupon? t("permit_application.apply_for_permit.labels.step3.invalid_coupon"): couponApplied? t("permit_application.apply_for_permit.labels.step3.coupon_applied") : ""}</span>
                        </Row>

                        { ((totalCost && totalCost > 0.0)) && 
                         map(newFields[9], (field, idx) => {  
                          if(idx === 2) {
                            field = {
                              ...field,
                              icon: reddollarIcon(), 
                              customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                            }
                          }
                          if(idx === 3) {
                            field = {
                              ...field,
                              icon: dollarIcon(), 
                              customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                            }
                          }
                          return (
                            <React.Fragment>
                              { idx === 2 && couponApplied && 
                                <Col className={`m-0 pt-5 ${styles['fieldset-double-step3']} pl-1`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps, errors} )}
                                </Col>
                              }
                              {idx === 3 &&
                                <Col className={couponApplied ? `m-0 ${styles['fieldset-double-step3']} pl-1`: `m-0 ${styles['fieldset-double-step3']} pl-1 pt-3 mt-2`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps, errors} )}
                                </Col> 
                              }
                              {idx === 4 && payment_amount > 0.0 &&
                                <Col className={`m-0 ${styles['fieldset-double-step3']} pl-1`} xs={12}>
                                  { renderField(field, { ...paymentFieldProps, errors} )}
                                </Col> 
                              }
                              
                            </React.Fragment>
                          )
                        })
                        }
                      {showCreditCardFields && !showGooglePay && (payment_amount > 0.0 || isSaving) && 
                        
                        <div className={styles.cardContainer}>
                          <div className={styles.cardPreview}>
                            <Cards
                              number={cardNumber}
                              name={name}
                              expiry={`${expiryM}${expiryY}`}
                              cvc={cvc}
                              focused={focus}
                              required={showCreditCardFields && !showGooglePay}
                            />
                            <div className={cardNumber? styles.saveData : 'd-none'}>
                            <label className={styles.savecheckboxText}>
                              <input
                                type="checkbox"
                                checked={saveCard === true }
                                onChange={() =>  setSaveCard(saveCard? false : true) }
                                ref={savecheckboxref}
                                className={`${styles.checkbox} ${saveCard ? styles['checkbox-active'] : ''}`}
                              />
                              <span className={styles.saveCheckText}>{t("permit_application.apply_for_permit.labels.step3.save_for_future_use")}</span>
                            </label>
                          </div>
                          </div>
                          <div className={styles.cardInputs}>
                            <label className={styles.cardLabels}>{t("events.city_events.get_pass_form.payment.credit_card.card_number.label")}</label>
                            <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'credit_card_number'}}>
                                <input
                                  type="tel"
                                  id="credit_card_number"
                                  name="credit_card_number"
                                  placeholder={t("events.city_events.get_pass_form.payment.credit_card.card_number.label")}
                                  maxLength={16}
                                  value={cardNumber}
                                  onChange={(e) => handleCardFieldsChange(e.target.value, 'credit_card_number')}
                                  onFocus={() => setFocus('number')}
                                  className={styles.cardInput}
                                  ref={creditCardNum}
                                />
                            </ErrorWrapper>
                            <label className={styles.cardLabels}>{t("events.city_events.get_pass_form.payment.credit_card.holder_name.label")}</label>
                            <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'holder_name'}}>
                              <input
                                type="text"
                                id="holder_name"
                                name="holder_name"
                                placeholder={t("events.city_events.get_pass_form.payment.credit_card.holder_name.label")}
                                value={name}
                                onChange={(e) => handleCardFieldsChange(e.target.value, 'holder_name')}
                                onFocus={() => setFocus('name')}
                                className={styles.cardInput}
                                ref={holder_name}
                              />
                            </ErrorWrapper>

                          { map(newFields[10], (field, idx) => {
                            return (
                              <React.Fragment>
                                {idx=== 0 &&
                                renderField(field, { ...cardYFieldProps, errors} )}
                                {idx===1 &&
                                  renderField(field, { ...cardMFieldProps, errors} )
                                }
                              </React.Fragment>
                            )
                          }) }

                            <label htmlFor="cvc" className={styles.cardLabels}>{t("events.city_events.get_pass_form.payment.credit_card.cvc.label")}</label>
                            <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'cvc'}}>
                              <input
                                type="tel"
                                id="cvc"
                                name="cvc"
                                placeholder={t("events.city_events.get_pass_form.payment.credit_card.cvc.label")}
                                value={cvc}
                                onChange={(e) => handleCardFieldsChange(e.target.value, 'cvc')}
                                onFocus={() => setFocus('cvc')}
                                className={styles.cardInput}
                                maxLength={3}
                                ref={cvv}
                              />
                            </ErrorWrapper>
                            </div>
                          </div>
                        
                      }
                    <div centered>
                      {showGooglePay && <GooglePayButton
                      environment="TEST"
                      paymentRequest={GooglePayDetails(payment_amount)}
                      onLoadPaymentData={paymentRequest => {
                        submitValuesGPay(paymentRequest)
                      }}
                      buttonType='pay'
                      className={styles.gpayBtn}
                    />}
                    </div>
                  </Row>
                </Col>

                <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
                  {step > 1 &&
                  <Button onClick={handlePrevious} type='button' className={`${styles.button} ${styles['button-bg-secondary']} ${!showCreditCardFields ? styles.prevBtn : ''} mr-4`}>
                    {t("buttons.previous")}
                  </Button>
                  }
                  { step === 1 &&
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      {t("buttons.cancel")}
                    </Button>
                  }
                 
                  { ((step < 3 && permitType?.name !== 'Carpool Permit') || (step < 4 && permitType?.name === 'Carpool Permit') ) && 
                    <Button type='button' className={styles.button}  onClick={handleNext}>
                      {t("buttons.next")}
                    </Button>
                  }
                  {((showCreditCardFields && ((step === 3 && permitType?.name !== 'Carpool Permit') || (step === 4 && permitType?.name === 'Carpool Permit'))) || (totalCost === '0.0' && ((step === 3 && permitType?.name !== 'Carpool Permit') || (step === 4 && permitType?.name === 'Carpool Permit')))) &&
                  <Button type='submit' className={styles.button}>
                    {isSaving ? btnSpinner({ className: 'spinner-border' }) : t("buttons.submit")}
                  </Button>
                  }
                  
                </Col>
              </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}


export default withFetching(Apply);
