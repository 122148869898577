import React from 'react'
import { Container } from 'reactstrap';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import { ReactComponent as EmailIcon } from 'assets/email_logo.svg'
import styles from './index.module.sass'

const View = ({ record }) => {

  return (
    <Container className={`${styles.subject} pt-3 mb-3 p-0`} >
      <span className={`${styles.title} pt-3 mb-3 p-0`}>Subject: </span>
      <span style={{fontSize: '14px'}}>{record?.title}</span>
      <div className="d-flex align-items-center my-3 w-98" style={{width: '98%'}}>
        <span className={`${styles.border} flex-grow-1 ml-2`} />
      </div>
      <div>
        <span className={`${styles.icon} d-flex align-items-left`}>
          <EmailIcon style={{ width: 60, height: 60}} />
          <span className='pt-3 mb-3 p-0' style={{fontSize: '14px', marginLeft: '10px'}}>Park Smart</span>
        </span>
      </div>
      <div className="d-flex align-items-left my-3 w-98" style={{width: '98%'}}>
        <span className={styles.content}>{record?.content}</span>
      </div>
      <div className="d-flex align-items-center my-3 w-98" style={{width: '98%'}}>
        <span className={`border border-2 flex-grow-1 ml-2`} ></span>
      </div>
      <span className={`${styles.title} pt-3 mb-3 p-0`}>Signature: </span>
      <div className='pb-5' >
        <span className={`${styles.title} pt-3 mb-3 pb-4`}>{record?.signature}</span>
      </div>
    </Container> 
  )
}

export default withFetching(withCurrentUser(View));