import React, { useState, useEffect } from "react";

const Action = ({
  status,
  activeClass,
  allowActive,
  className,
  icon,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleColorChange = (val) => {
    if (status || !allowActive) return;
    setIsActive(val);
  };

  useEffect(() => {
    setIsActive(status);
  }, [status]);

  return (
    <span
      role="button"
      onMouseEnter={() => handleColorChange(true)}
      onMouseLeave={() => handleColorChange(false)}
      onClick={() => onClick && onClick()}
      className={`${
        isActive ? `${className} ${activeClass}` : `${className}`
      } d-flex justify-content-center align-items-center`}
    >
      {icon}
    </span>
  );
};

export default Action;