import React, { useState ,useRef, useEffect } from 'react'
import { Col, Container } from 'reactstrap';
import { isEmpty,} from 'underscore';
import { Form } from 'informed';
/* Base */
import Button from 'components/base/button';
import { renderField } from 'components/base/forms/common_form';
/* Helper */
import { rejectFields } from 'components/helpers/fields/permit_applications/index';
/* Assets */
import styles from './index.module.sass'
import { search as dropdownSearch } from 'api/dropdowns'


const RejectApplication = (props) => {

  const {rejectPermitApplication, record, closeModal, state, setState, errors} = props

  const [rejectionReasons, setRejectionReasons] = useState([])

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
    
  const save = () => {
    setState('errors', {})
    const {values: formValues} = formApiRef.current.getState();
    rejectPermitApplication(formValues)
  }

  useEffect(() => {
    const fetchRejectionReasons = async () => {
      try {
        const rejectionReasonsDropdown = await dropdownSearch('permit_application_rejection_reasons_field')
        setRejectionReasons(rejectionReasonsDropdown.data)
      } catch (_error) {
      }
    }
    if(isEmpty(rejectionReasons)){fetchRejectionReasons();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rejectionReasons])

  const rejectMessage = (record) => {
    if(record.shared) return  <React.Fragment>
      <span>This will reject the</span>
      {' '}
      <span className={styles.boldText}>shared</span>
      {' '}
      <span>permit application for the</span>
      {' '}
      <span className={styles.boldText}>{record.permit_type}</span>
      {' '}
      <span>permit.</span>
    </React.Fragment>
    return <React.Fragment>
      <span>This will reject</span>
      {' '}
      <span>{record.applicant_name}'s </span>
      {' '}
      <span className={styles.boldText}>{record.permit_type}</span>
      {' '}
      <span>application for vehicle</span>
      {' '}
      <span className={`${styles.boldText} text-uppercase`}>{record.vehicle_lpn}.</span>
    </React.Fragment>
   }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 mt-2`}>Reject Permit Application</p>
      <Container className={styles.content}>
        <Col>
          { rejectMessage(record) }
        </Col>
        <Col>
          <fieldset disabled={state.isSaving}>
            <Form getApi={setFormApi} onSubmit={save} className={styles.form}>
              <div >
                {renderField(rejectFields(styles.formLabel, styles.formInput, rejectionReasons)[0], { lSize: 2, iSize: 10, errors})}
              </div>
              {renderField(rejectFields(styles.formTextLabel, styles.formInput)[1], { isize: 12, errors})}
              <div className="d-flex justify-content-center mb-2">
                <Button onClick={() => closeModal()} type='button' className={`${styles.cancelButton} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={`${styles.submitButton}`}  disabled={state.isSaving}>
                  Reject
                </Button>
              </div>
            </Form>
          </fieldset>
        </Col>
      </Container>
    </Container>
  )

}

export default RejectApplication;