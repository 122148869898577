import { FieldType } from "components/helpers/form_fields";
import { ownerFields, vehicleFields as showVehicleFields } from "./show";

const vehicleFields = (attrs) => {
  const { customInputClass='', customLabelClass='', imported, vehicleLpns, selectedTab } = attrs;

  const fields = showVehicleFields(attrs)
  if(imported || selectedTab === 'secondary'){fields[0] = {
    name: 'vehicle[plate_number]',
    label: 'Plate Number',
    customInputClass,
    customLabelClass,
    disabled: true,
  }}else{fields[0] = {
    name: 'vehicle[plate_number]',
    label: 'Plate Number',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: vehicleLpns.map((lpn, idx) => {return {value: lpn?.value, label: lpn.label.toUpperCase()}}),
    customInputClass,
    customLabelClass,
    disabled: false,
  }}
  return fields
}

const permitFields = (attrs) => {
  const { customInputClass='', customLabelClass='', permitTypes=[] } = attrs;
  return [
    {
      name: 'permit[number]',
      label: 'Number',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'permit[issued_on]',
      label: 'Issue Date',
      type: FieldType.DATE_FIELD,
      customInputClass,
      customLabelClass,
      disabled: true,
      showIcon: false
    },
    {
      name: 'permit[expired]',
      label: 'Expired?',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'permit[type]',
      label: 'Type',
      customInputClass,
      customLabelClass,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: permitTypes
    },
    {
      name: 'permit[expires_on]',
      label: 'Expiry Date',
      customInputClass,
      customLabelClass,
      disabled: true,
      showIcon: false,
    },
    {
      name: 'permit[cost]',
      label: 'Cost',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: true,
    },
  ]
};

export { vehicleFields, ownerFields, permitFields}