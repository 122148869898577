import { FieldType } from "components/helpers/form_fields";

const fieldsNewSignup = (attrs) => {
  const { 
    mute=true, customLabelClass='', customInputClass=''} = attrs
  return ([
    [
      {
        name: 'category',
        customInputClass,
        customLabelClass,
        type: FieldType.RADIO_BUTTON_FIELD,
        mandatory: true,
        list: [
          { label: 'Signup Rewards', value: "signup" },
          { label: 'Refer a Friend', value: "refer" },
          { label: 'Other', value: "other" }

        ]
      },
    ],
    [
      {
        name: 'reward_points',
        label: '# Points Earned On Signup',
        type: FieldType.INCREASER_FIELD,
        disabled: mute,
        mandatory: true,
        customInputClass,
        customLabelClass,
        placeholder: "# Points",
        min: 1,
        step: 1,
        renderValue: value => value,
      },
      
    ],
    [
      {
        name: 'points_to_dollar',
        label: '# Points To The Dollar',
        type: FieldType.INCREASER_FIELD,
        disabled: mute,
        mandatory: true,
        customInputClass,
        customLabelClass, 
        placeholder: "# Points",
        min: 1,
        step: 1,
        renderValue: value => value,
        tooltipWidth: '25px',
        tooltipHeight: '25px',
        tooltipClass: 'mb-3 mr-3',
        tooltipEnd: 'The number of points equivalent to $1.',
      },
        
    ],
  ]);
} 

const fieldsNewRefer = (attrs) => {
  const { 
    mute=true, customLabelClass='', customInputClass=''} = attrs
  return ([
    [
      {
        name: 'reward_points',
        label: '# Points Awarded',
        type: FieldType.INCREASER_FIELD,
        disabled: mute,
        min: 1,
        placeholder: "# Points",
        mandatory: true,
        customInputClass,
        customLabelClass,
        step: 1,
        renderValue: value => value,
      },
      
    ],
    [
      {
        name: 'points_to_dollar',
        label: '# Points To The Dollar',
        type: FieldType.INCREASER_FIELD,
        disabled: mute,
        mandatory: true,
        step: 1,
        min:1,
        placeholder: "# Points",
        customInputClass,
        customLabelClass, 
        renderValue: value => value,
        tooltipWidth: '25px',
        tooltipHeight: '25px',
        tooltipClass: 'mb-3 mr-3',
        tooltipEnd: 'The number of points equivalent to $1.',
      },
        
    ],
  ]);
} 

export {fieldsNewSignup, fieldsNewRefer };