import React, { useCallback } from 'react';
import { Form } from 'informed';
import { map } from 'underscore';
import { Col, Row } from 'reactstrap';
/* Base */
import { renderFields } from 'components/base/forms/common_form';
import Button from 'components/base/button';
/* Helpers */
import { mtaVehicleFields, mtaOwnerFields } from 'components/helpers/fields/violations/index';
import Loader from 'components/helpers/loader';
/* Styles/Assets */
import styles from '../index.module.sass';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const attrs = { customInputClass: styles.formInput, customLabelClass: styles.formLabel, disabled: true }
const fieldProps = { lSize: 5, iSize: 7 }
const ownerFields = mtaOwnerFields(attrs);

const VehicleOptions = ({state, setState}) => {

  const openModal = useCallback((modal) => {
    setState('modalType', modal)
  }, [setState])

  const vehicleFields = map(mtaVehicleFields(attrs), (field, idx) => {

    if(idx === 4 && !!state.vehicleInfo.vehicle?.color) {
      const dotIcon = () => <FontAwesomeIcon icon={faCircle} className={`${styles.dotIcon}`} color={state.vehicleInfo.vehicle?.color?.toLowerCase()} />
      field = {...field, icon: dotIcon(), customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)}
    }

    return field
  });

  if(state.mtaFetching) return <Loader />


  return (
    <Row className='mx-auto flex-wrap justify-content-center w-100'>
    {
      state.vehicleInfo !== 'Not found' &&
        <Form
          initialValues={state.vehicleInfo}
          className={`${styles.form}`}
        >
          <Row className='m-auto'>

            <Col className="d-flex align-items-center mt-3 mb-4" xs={12}>
              <span className='highlight-text-1'>Retrieved Details: Vehicle Information</span>
            </Col>

            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(vehicleFields.slice(0, 2), { ...fieldProps })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(vehicleFields.slice(2, 4), { ...fieldProps })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(vehicleFields.slice(4, 6), { ...fieldProps })}
            </Col>

    
            <Col className="d-flex align-items-center mt-3 mb-4" xs={12}>
              <span className='highlight-text-1'>Retrieved Details: Owner Information</span>
              <span className="border border-2 flex-grow-1 ml-2 text-bolder"></span>
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(ownerFields.slice(0, 1), { ...fieldProps })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(ownerFields.slice(1, 2), { ...fieldProps })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(ownerFields.slice(2, 3), { ...fieldProps })}
            </Col>
          </Row>
        </Form>
      }
  
      {
        state.vehicleInfo === 'Not found' &&
          <>
            <p className={`my-3 ${styles.errorStyle}`}>The vehicle’s LPN is not registered on the system. Click the button below to fetch vehicle information from the motor vehicle authority.</p>
            <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
              <Button
                className={`${styles.btn} ${styles['background-primary']}`}
                type='button'
                icon= <PlusIcon className={styles.icon}/>
                onClick={() => openModal('mta_fetch')}
              >
                Retrieve Information
              </Button>
            </Col>
          </>
      }
    </Row>
  )
}

export default VehicleOptions;
