import React from "react";
import { FieldType } from 'components/helpers/form_fields';
import permissions from 'config/permissions';
import fieldsWithPermission from '.././fields_with_permission';
import { mtaOwnerFields, mtaVehicleFields } from './mta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


const filterFields = ({agencies, officers, parkingLots, ticketStatuses, violationTypes, customInputClass, customLabelClass, fromDate, toDate}) => [
  {
    name: 'violation_type',
    label: 'Violation Type',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a type',
    options: violationTypes,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'parking_lot_id',
    label: 'Parking Lot',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a parking lot',
    options: parkingLots,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'agency_id',
    label: 'Agency Name',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose an agency',
    options: agencies,
    customInputClass,
    customLabelClass,
  },
  {
    name: 'officer_id',
    label: 'Officer Name',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose an officer',
    options: officers,
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: toDate || new Date(),
    placeholder: 'From',
    customInputClass,
    customLabelClass,
  },
  {
    name: 'ticket_status',
    label: 'Status',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    placeholder: 'Choose a Status',
    options: ticketStatuses,
    customInputClass,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    min: fromDate,
    placeholder: 'To',
    customInputClass,
    customLabelClass,
  },
];

const notEditableFields = (attrs) => [
  { name: 'created_at', label: 'Created Date', disabled: true, ...attrs },
  { name: 'updated_at', label: 'Updated Date', disabled: true, ...attrs },
  { name: 'updated_by', label: 'Updated By', disabled: true, ...attrs },
  { name: 'parking_lot', label: 'Parking Lot Name', disabled: true, ...attrs },
  { name: 'agency', label: 'Law Enforcement Agency Name', disabled: true, ...attrs },
  { name: 'plate_number', label: 'Plate Number', disabled: true, ...attrs }
];

const editableFields = (officers, violationTypes, ticketStatuses, customInputClass, customLabelClass) => {

  return ([
    {
      name: 'admin_id',
      label: 'Assignee',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: officers,
      customInputClass,
      customLabelClass
    },
    {
      name: 'violation_type',
      label: 'Violation Type',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: violationTypes,
      customInputClass,
      customLabelClass
    },
    {
      name: 'status',
      label: 'Status',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: ticketStatuses,
      customInputClass,
      customLabelClass
    }
  ]);
};

const fieldsShow = (
  { 
    currentUserPermissions: userPermissions,
     statuses: ticketStatuses, 
     officers, 
     types: violationTypes, 
     customInputClass, 
     customLabelClass 
  }) => [
    ...notEditableFields({customInputClass, customLabelClass}),
    ...fieldsWithPermission(
    editableFields(officers, violationTypes, ticketStatuses, customInputClass, customLabelClass),
    userPermissions,
    permissions.UPDATE_VIOLATION
  )
];

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='', agencies=[], violationStatus=[], ticketStatus=[], icon, customLabelClass1='', } = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      Open: 'green',
      Canceled: 'red',
      Accepted: 'green',
      Closed: '#8b9197',
      'Auto Accepted': 'orange'
    }
    return colorMapping[label] || 'default'
  }

  return [
    {
      name: 'id',
      label: 'Ticket ID',
      customInputClass,
      customLabelClass: customLabelClass1,
      disabled: true,
      icon: icon
    },
    {
      name: 'created_at',
      label: 'Created Date',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'updated_date',
      label: 'Updated Date',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'updated_by',
      label: 'Updated By',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'parking_lot',
      label: 'Parking Lot Name',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'agency',
      label: 'Agency Name',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'plate_number',
      label: 'Plate Number',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'admin_id',
      label: 'Assignee',
      customInputClass,
      customLabelClass,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: agencies,
      mandatory: true
    },
    {
      name: 'violation_type',
      label: 'Violation Type',
      customInputClass,
      customLabelClass,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: violationStatus,
      mandatory: true
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: ticketStatus.map(({value, label}) => ({
        value: label,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
           /> 
          {label}
        </React.Fragment>
      })),
      customInputClass,
      customLabelClass,
      mandatory: true
    },
    {
      name: 'logs_number',
      label: 'Logs Number',
      customInputClass,
      customLabelClass: customLabelClass1,
      disabled: true,
      icon: icon
    },
  ]
}

export { filterFields, fieldsShow, mtaOwnerFields, mtaVehicleFields, showFields };
