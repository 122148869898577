import React,  { useCallback, useEffect, useState } from 'react'
import styles from "./index.module.sass";
import NavigationToolbar from 'components/base/navigationToolbar';
import { filterFetcher, statistics } from 'api/booting_and_towings';
import {  Col, Row } from 'reactstrap';
import Tabs from 'components/base/tabs';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import Button from 'components/base/button';
import { search as dropdownsSearch } from "api/dropdowns";
import FilterForm from './filterForm';
import { ReactComponent as SettingIcon } from 'assets/setting-gear-icon.svg';
import SlideButton from 'components/base/slide_button';
import { list as selectList } from "selectors/list";
import FilterParkingLot from './filterParkingLot';

const CollapseContent = ({ startFetching,filters, setList, setFilters, match, resource}) => {
  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm 
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        filters={filters}
      />
    </Row>
  )
}

const ToolBar = ( props) => {

  const {activeTab, setActiveTab, startFetching, setList, match, isFetching, openModal, history, pageType, selectedParkingLot, setSelectedParkingLot} = props;
  const [listType, setListType] = useState(pageType);
  const [totalBooted, setTotalBooted] = useState()
  const [totalImpounded, setTotalImpounded] = useState()
  const [filters, setFilters] = useState()
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
          setDropdowns(state => ({...state, parkingLots: response.data })))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const setStatistics = async () => {
      try {
        const response = await statistics({parking_lot_id: selectedParkingLot})
        setTotalBooted(response?.data?.booted_vehicles)
        setTotalImpounded(response?.data?.impounded_vehicles)
      } catch (error) {
        console.log(error)
      } 
    }
    if(selectedParkingLot) setStatistics()
  },[selectedParkingLot, listType])
  
  const filterBroadcasts = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params])
  
  const handleTabsFilter = () => {
    const filters = {parking_lot_id: selectedParkingLot}
    if(listType === 'bootings'){
      Object.assign(filters, {...filters, booted: true})
    }else if(listType === 'towings'){
      Object.assign(filters, {...filters, impounded: true})
    }else{
      Object.assign(filters, {...filters, boot_eligible: true})
    }
    setFilters(filters)
    filterBroadcasts(filters)
  }
  
  useEffect(() => {
    if (listType && selectedParkingLot) {
      handleTabsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setListType, listType,selectedParkingLot]);

  useEffect(() => {
    if (!selectedParkingLot) {
      setSelectedParkingLot(dropdowns?.parkingLots?.[0]?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setListType, listType,dropdowns]);

  const tabs = [
    {label: `Booted ${totalBooted? `(${totalBooted})` : ''}`, value: 'bootings'},
    {label: `Impounded ${totalImpounded? `(${totalImpounded})` : ''}`, value: 'towings'},
    {label: 'Boot Eligible Vehicles', value: 'boot_eligible_vehicles'}
  ]

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  const handleTabsChange = (tab) => {
    setListType(tab);
    history.push(`/dashboard/${tab}`);
  };

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          {...props}
          activeTab={activeTab}
          filters={filters}
          setFilters={setFilters}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start pl-0'>
        <Tabs className={styles.tabs} list={tabs} defaultTab={listType} onClick={handleTabsChange} classNameInnerTab={styles.innerTab}/>
        <FilterParkingLot {...props} filters={filters} parkingLots={dropdowns?.parkingLots} selectedParkingLot={selectedParkingLot} setSelectedParkingLot={setSelectedParkingLot} setFilters={setFilters}/>
      </Col>

      <Col className='d-flex justify-content-end px-0'>
        <SlideButton
          isActive={true}
          icon={<SettingIcon />}
          onClick={() => {openModal('configuration')}}
        >
          <span className='pr-3'>Configurations</span>
        </SlideButton>
        {listType === 'bootings' && <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`ml-3 ${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />}
      </Col>        
    </NavigationToolbar>
  )
}

export default ToolBar;