import { FieldType } from "components/helpers/form_fields";

const eventFields = (attrs) => {
  const {customRadioInputClass, customDateInputClass, customLabelClass='', customInputClass='',renderLocationModal, t, customIconClass} = attrs

  return [
    [
      {
        name: 'event_name',
        label: t('events.city_events.event_name'),
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      }
    ],
      
    [
      {
        name: 'contact_person_name',
        label: t('events.private_events.purchase_event_parking.organizer_name'),
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      }
    ],
    [
      {
      name: 'organizer_email',
      label: t('events.private_events.purchase_event_parking.organizer_email'),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true,
      }
    ],
    [
      {
        name: 'organizer_phone_number',
        label: t('events.private_events.purchase_event_parking.organizer_phone'),
        type: FieldType.PHONE_NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: true,
      }
    ],
    [
      {
        name: 'number_of_vehicles',
        label: t('events.private_events.purchase_event_parking.no_of_vehicles'),
        type: FieldType.NUMBER_FIELD,
        step: 1,
        min: 0,
        mandatory: true,
        disabled: false,
        customInputClass,
        customLabelClass,
        renderValue: value => value
      }
    ],
    [
      {
        name: 'event_dates',
        label: t('events.private_events.purchase_event_parking.select_dates'),
        type: FieldType.MULTIPLE_DATE_FIELD,
        customInputClass: customDateInputClass,
        customLabelClass,
        customIconClass,
        disabled: false,
        min: new Date(),
        mandatory: true,
      }
    ],
    [
      {
        name: 'event_time',
        type: FieldType.RADIO_BUTTON_FIELD,
        mandatory: true,
        disabled: false,
        list: [
          { label: t("events.private_events.purchase_event_parking.same_for_all_days"), value: 'same' },
          { label: t("events.private_events.purchase_event_parking.custom"), value: 'custom' }
        ],      
        customInputClass: customRadioInputClass,
      }
    ],
    [
      {
        name: 'location',
        label: t("events.city_events.location"),
        mandatory: true,
        render: renderLocationModal,
        disabled: false,
        customInputClass,
        customLabelClass,
      
      }
    ],  
    [
      {
        name: 'selected_days',
        label: t('events.private_events.purchase_event_parking.selected_days'),
        customInputClass,
        customLabelClass,
        disabled: true,
      },
    
      {
        name: 'event_cost',
        label: t('events.private_events.purchase_event_parking.event_cost'),
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
    ],
  ];
};


const dateTimeDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=false, date, t } = attrs;
  return [
    
    {
      name: `event_start_time${date? `_${date}`:''}`,
      label: t("events.private_events.purchase_event_parking.from"),
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
      mandatory: true,
    },
    {
      name: `event_end_time${date? `_${date}`:''}`,
      label: t("events.private_events.purchase_event_parking.to"),
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
      mandatory: true,
    }
  ]
}

const exampleData = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? {
  country: "USA",
  city: "Easton",
  zip:  21601,
  street: "E Earle Ave",
  ltd: '38.768148',
  lng: '-76.075782'
} : {};

export {eventFields, exampleData, dateTimeDetails};