import React, { useEffect, useState } from "react";
import { Row, Col, Collapse } from "antd";
import styles from "./index.module.sass"; // Assuming you have a Sass module for styling
import Wallet from "./wallet";
import MyProfile from "./profile";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { useTranslation } from "react-i18next";

const MyProfilePage = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false)
  const [activeKey, setActiveKey] = useState(["profile", "wallet"]);
  const { t } = useTranslation();

  const checkScreenSize = () => {
    if (window.innerWidth <= 1199) { 
      setIsMobileScreen(true);
      setActiveKey(['profile']); // Ensure no default active key for mobile
    } else {
      setIsMobileScreen(false);
      setActiveKey(["profile", "wallet"]); // Set both as active for larger screens
    }
  };

  const handleCollapseChange = (key) => {
    if(isMobileScreen){
      setActiveKey(key?.length > 0 ? [key[key?.length - 1]] : []);
    }else {
      setActiveKey(["profile", "wallet"]);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize); 

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Row gutter={24}>
        <Col
          className={styles.leftSection}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={12}
        >
          <Collapse
            collapsible={isMobileScreen? 'header' : "disabled"}
            defaultActiveKey={["profile"]}
            activeKey={activeKey}
            onChange={handleCollapseChange}
            ghost={!isMobileScreen}
            items={[
              {
                key: "profile",
                label: <p className={styles.collapseTitle}>{t("subscriber.profile.profile_header")}</p>,
                children: <MyProfile t={t}/>,
                showArrow: isMobileScreen
              },
            ]}
          />
        </Col>
        <Col className={isMobileScreen? "pt-3" : ''} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Collapse
            collapsible={isMobileScreen? 'header' : "disabled"}
            defaultActiveKey={["wallet"]}
            activeKey={activeKey}
            onChange={handleCollapseChange}
            ghost={!isMobileScreen}
            items={[
              {
                key: "wallet",
                label: <p className={styles.collapseTitle}>{t("subscriber.profile.wallet_header")}</p>,
                children: <Wallet t={t}/>,
                showArrow: isMobileScreen
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default withCurrentSubscriber(MyProfilePage);