import React, { useEffect, useState } from "react";

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx } = props;
  const [showText, setShowText] = useState(isActive);

  const handleClick = () => {
    if (!onClick) return;

    setShowText(true);
    onClick();
  };

  useEffect(() => {
    setShowText(isActive);
  }, [isActive]);

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => {
        if (!isActive) {
          setShowText(false);
        }
      }}
      className={`d-flex align-items-center justify-content-center ${
        idx === 0 ? "border-right" : ""
      }`}
    >
      {showText ? <span className={highlightedClass}>{text}</span> : icon}
    </div>
  );
};

export default Action;