import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Col, Container, Label } from 'reactstrap';
import { Form } from 'informed';
import { newFields } from 'components/helpers/fields/agencies';
import Button from 'components/base/button';
import { isEmpty, map } from 'underscore';
import { show } from 'api/agencies';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import Loader from 'components/helpers/loader';
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import { Link } from 'react-router-dom';
import { ReactComponent as TrashIcon } from 'assets/trash_icon.svg';
import LocationForm from '../location/form'
import {exampleData} from 'components/helpers/fields/permit_types/index'
import { cloneDeep } from "lodash";

const Edit = (props) => {
  const { startFetching, closeModal , selectedRecord, isSaving, updateAgency, errors, setErrors }= props
  
  const [agency, setAgency] = useState();
  const [isFetching, setIsFetching] = useState()
  const [fileName, setFileName] = useState();
  const fileRef = useRef()
  const [file, setFile] = useState()
  const [image, setImage] = useState(null)
  const [currentLocation, setCurrentLocation] = useState(exampleData)
  const [loc, setLoc] = useState(false)
  const [dropdowns, setDropdowns] = useState({
    managers: [],
    parkingLots: [],
    agencyTypes: [],
    officers: []
  })
  const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
      startFetching(dropdownsSearch('agency_type')
      ).then((response) => setDropdowns(state => ({...state, agencyTypes:  response.data}))),
      startFetching(dropdownsSearch('admins_by_role-officer')
      ).then((response) => setDropdowns(state => ({...state, officers: response.data}))),
      startFetching(dropdownsSearch('admins_by_role-manager')
      ).then((response) => setDropdowns(state => ({...state, managers: response.data}))),
      startFetching(dropdownsSearch('parking_lots_without_agency_list')
      ).then((response) => setDropdowns(state => ({...state, parkingLots: response.data}))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const parkingLotsChose = agency?.parking_lots.map((parkingLot) => ({
    value: parkingLot.id,
    label: parkingLot.name,
  }));

  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        setLoc={setLoc}
        loc={loc}
        setErrors={setErrors}
      />
    );
  }

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customInputClass1: styles.input1,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
    managers: dropdowns.managers,
    parkingLots: [...(parkingLotsChose || []), ...dropdowns.parkingLots],
    agencyTypes: dropdowns.agencyTypes,
    officers: dropdowns.officers,
    renderLocationModal: renderLocationModal
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  useEffect(() => {
    if(!isEmpty(agency?.avatar)){
      setFileName(agency.avatar.split('/').at(-1))
      setImage(agency.avatar)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecord, setFile, setFileName, setImage, agency])

  const applicationData = useCallback((data) => {
    const { email, name, status, manager, officers, agency_type, parking_lots, phone, violation_id, avatar, location } = data
    let officer_ids =[]
    let lot_ids =[]
    // eslint-disable-next-line
    {map(officers, officer => ( officer_ids.push(officer.id)))}
    // eslint-disable-next-line
    {map(parking_lots, lot => ( lot_ids.push(lot.id)))}
    return ({  
      email: email,
      name: name,
      phone: phone,
      violation_id: violation_id,
      status: status,
      manager_id: manager.id,
      officer_ids: officer_ids,
      agency_type_id: agency_type.id,
      parking_lot_ids: lot_ids,
      parking_lots: parking_lots,
      avatar: avatar,
      location: location.full_address
    })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!selectedRecord.id)  return;
      try {
        const response = await show({id: selectedRecord?.id})
        if(response.status === 200) {
          const { data } = response;
          setAgency(applicationData(data));
          setCurrentLocation(data.location)
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();
  }, [selectedRecord, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const { location, name, email, manager_id, agency_type_id } = formState;
    if(!location) errors['location'] = ['Location is required']
    if(!name) errors['name'] = ['Name is required']
    if(!email) errors['email'] = ['Email is required']
    if(!agency_type_id) errors['agency_type_id'] = ['Agency Type is required']
    if(!manager_id) errors['manager_id'] = ['Manager is required']
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    values.location = cloneDeep(currentLocation);
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = {
      ...values,
      avatar: file,
    };
    updateAgency(updatedHash)
  }


  const getBase64= file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        setFile(reader.result);
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    }else {
      setFileName(e.target.files[0]?.name)
      setImage(URL.createObjectURL(e.target.files[0]));
      getBase64(e.target.files[0])
    }
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (validFileTypes.includes(e.dataTransfer.files[0].type)) {
        setFileName(e.dataTransfer.files[0].name)
        setImage(URL.createObjectURL(e.dataTransfer.files[0]));
        getBase64(e.dataTransfer.files[0])
      }
    }
  };

  const fields = () => {
    return newFields({...fieldAttrs})
  }

  if(isFetching) return <Loader />

  return (
    <Container className='p-0'>
      <Form getApi={setFormApi}  className={styles.form} initialValues={agency} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col className='mt-4 p-0'>
              <Col>
              <div >
                {renderFields(newFields.slice(0, 9), {...singleFieldProps, errors})}
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} className={styles.uploadField}>
                  <span className={styles.inputLabel} style={{marginLeft: '-42.5rem'}}>
                    Account Picture
                  </span>
                  <span className={styles.uploadContainer} onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                    <input ref={fileRef} name='id_proof' className='d-none' id='id-proof-file-upload' type='file' accept='.jpg, .png, .jpeg' onChange={showFileName}/>
                    {!image && <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '1.5rem', width: '86%'}}>
                      <span className='mb-2'>
                        {image ? <img className={styles.img} alt="avatar" src={image}/>
                          : <CloudDownloadIcon />}
                      </span>
                      <span>
                        <span className={styles.boldText1}>
                          Add Picture
                        </span>
                        <span className='ml-1'>
                          or drag it here
                        </span>
                      </span>
                      <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
                    </Label>
                    }
                    {image && (<div className='d-flex'>
                      <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel1} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '1.4rem'}}>
                        <span className='mb-2 mt-2'>
                          <img className={styles.img} alt="avatar" src={image}/>
                        </span>
                        <span className={styles.uploadText}>
                          <span className={styles.boldText2}>
                          {fileName}
                          </span>
                        </span>
                      </Label>  
                      <div className={styles.deleteField}>
                        <TrashIcon className={styles.icon} onClick={() =>  {setFileName(); setImage(); setFile()}}/>
                      </div>
                    </div>)
                    }
                  </span>
                </div>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                onClick={()=>  closeModal()}
                className={`${styles.cancelButton} mb-3`}
              >
                Cancel
              </Button>
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Edit);