import Button from "components/base/button";
import React from "react";
import { Col } from "reactstrap";
/* Styles/Assets */
import styles from "./index.module.sass";

const Delete = (props) => {
  const { topUpStatus, closeModal, t } = props;
  return (
    <Col className={styles.body}>
      <p className={topUpStatus ? styles.successTitle : styles.failedStatus}>{topUpStatus? t("subscriber.profile.top_up_modal.success") : t("subscriber.profile.top_up_modal.failed")}</p>
      <div className={styles.content}>
        {!topUpStatus && 
          <Col className="my-3">
            {t("subscriber.profile.top_up_modal.top_up_failed")}
          </Col>
        }
        {topUpStatus && 
          <Col className="my-3">
            {t("subscriber.profile.top_up_modal.top_up_success")}
          </Col>
        }
      </div>
      <Col className="d-flex justify-content-center my-3 pt-2">
        <Button
          onClick={() => closeModal()}
          type="button"
          className={styles.cancelButton}
        >
          {t("buttons.close")}
        </Button>
      </Col>
    </Col>
  );
};

export default Delete;