import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopUp from '../topUp';
/* Assets */
import styles from './index.module.sass'
/* API */
import { topUp } from 'api/webapp/subscriber';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Confirmation from '../topUp/confirmation'
const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const WalletModal = (props) => {
  const { openModal, modalType,setListElement, fetchWalletTransactions, setWalletBalance, t } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState()
  const [topUpStatus, setTopUpStatus] = useState(false)
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const closeModal = useCallback( () => {
    openModal()
    setState('innerModal', null)
  }, [openModal, setState])


  const topUpWallet = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await topUp(data)
      if(response.status === 200) {
        setTopUpStatus(true);
        fetchWalletTransactions();
        setWalletBalance(response?.data?.wallet_balance)
      }
    } catch (error) {
      setErrors(error?.response?.data?.error)
      setTopUpStatus(false)
    }finally {
      setState('isSaving', false)
      openModal('confirmation')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, setListElement, addAlertMessages, setTopUpStatus, setErrors])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'top_up':
        return {title: t("subscriber.profile.top_up_modal.title"), maxWidth: '500px'}
      case 'confirmation':
        return { maxWidth:'400px' }
      default:
        return {title: '', maxWidth}
    }
  },[modalType, t]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().maxWidth, width: '98%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0 text-center`}>{modalProps().title}</p>
        { modalType === 'top_up' && <TopUp t={t} errors={errors} setErrors={setErrors} topUp={topUpWallet} closeModal={closeModal} isSaving={state.isSaving} /> }
        { modalType === 'confirmation' && <Confirmation t={t} topUpStatus={topUpStatus} closeModal={closeModal} /> }
      </ModalBody>
    </Modal>
  )
}

export default WalletModal;