import React, { useCallback, useRef } from 'react'
import { displayDate } from 'components/helpers'
import { filterFields } from 'components/helpers/fields/parking_sessions' 
import Button from 'components/base/button'
import { filterFetcher } from "api/parking_sessions";
import { Form } from 'informed'
import { Col } from 'reactstrap'
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'
import { renderFieldsWithGrid } from 'components/base/forms/common_form';


const FilterForm = (props) => {
  const {paymentMethods, parkingSessionStatuses ,parkingSessionsKioskIds,filters, setFilters, match, setList, startFetching } = props;

  const attrs = {paymentMethods: paymentMethods,
    parkingSessionStatuses: parkingSessionStatuses,
    parkingSessionsKioskIds: parkingSessionsKioskIds, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterParkingLots = useCallback(async (filters={}) => {
    const { check_in, created_at } = filters
    if(check_in && !check_in?.to) { delete check_in.to}
    if(check_in && !check_in?.from) { delete check_in.from}
    if(created_at && !created_at?.to) { delete created_at.to}
    if(created_at && !created_at?.from) { delete created_at.from}

    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      check_in: {
        from: displayDate(filterValues.check_in?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.check_in?.to, 'yyyy-mm-dd')
      },
      created_at: {
        from: displayDate(filterValues.created_at?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.created_at?.to, 'yyyy-mm-dd')
      }
    }
    setFilters(filters);
    filterParkingLots(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      id: '', 
      statuses:'',
      vehicles_plate_number: '',
      user_ids: '',
      kiosk_ids: '',
      created_at: { from: '', to: ''},
      check_in: { from: '', to: ''},
      check_out: '',
      slot_name: '',
      fee_applied: '',
      total_price: '',
      payment_methods: ''
    })
    setFilters({})
    filterParkingLots({})
  }


  const initialValues = {
    ...filters,
  }

  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (

      <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} onSubmit={save}>
        {({formState}) => {
          const dateFrom = formState.values.range?.from || ''
          const dateTo = formState.values.range?.to || ''
          
          const fields = getFields(dateFrom, dateTo)
          return <React.Fragment>
          <div lg={12} >
            {renderFieldsWithGrid(fields, 3, 4, { iSize: 7, lSize: 4 })}
          </div>
          <Col className='d-flex justify-content-center pt-3 m-0 mb-2' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
          </React.Fragment>
        }}
      </Form>

  )
}

export default FilterForm;
