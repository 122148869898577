import React, { useCallback, useEffect } from "react";
/* Base */
import NavigationToolbar from "components/base/navigationToolbar";
import SlideButton from "components/base/slide_button";
import { Col } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/plus_icon.svg";
import styles from "./index.module.sass";
import { filterFetcher } from "api/residential_types";
import { list as selectList } from "selectors/list";
import Tabs from "components/base/tabs";

const tabs = [
  {
    value: "residence",
    label: <span>Type of Residence</span>,
  },
  {
    value: "property",
    label: <span>Type of Property</span>,
  },
];

const ToolBar = (props) => {
  const {
    openModal,
    activeTab,
    match,
    setList,
    startFetching,
    setFilters,
    typeOf,
    setTypeOf,
  } = props;

  const filterResidentialTypes = useCallback(
    async (filters) => {
      startFetching(
        filterFetcher(
          Object.assign(
            { ...match.params },
            { filters: filters },
            { query: filters }
          )
        )
      )
        .then((res) => {
          setList(selectList(res));
        })
        .catch((error) => console.log(error));
    },
    [setList, startFetching, match.params]
  );

  const handleTabsFilter = () => {
    const filters = { type_of: typeOf };
    setFilters(filters);
    filterResidentialTypes(filters);
  };

  useEffect(() => {
    if (typeOf) {
      handleTabsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters, setTypeOf, typeOf]);

  return (
    <NavigationToolbar className="d-flex justify-content-space-between pt-2">
      <Col className="d-flex justify-content-start m-0 p-0">
        <Tabs
          className={styles.tabs}
          list={tabs}
          defaultTab={typeOf}
          onClick={setTypeOf}
        />
      </Col>
      <Col className="d-flex justify-content-end pr-0">
        <SlideButton
          isActive={true}
          icon={<PlusIcon className={`${styles.addSvg} svg-white`} />}
          onClick={() => {
            openModal("create");
          }}
          className={`${styles.addBtn} ${
            activeTab === "create" ? styles.activeTab : ""
          }`}
        >
          <span className={styles.btnText}>Add Residential Type</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  );
};

export default ToolBar;