import React, { useCallback, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
/* API */
import {  update, cancel } from 'api/events';
/* Actions */
import { SET_LIST_ELEMENT } from 'actions/events';
import Edit from '../edit';
import Cancel from '../cancel';
/* Styles/Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    default:
      return { ...state };
  }
}

const EventModal = ({openNotificationWithIcon,openModal, modalType, event, fetchEventDetails, t, setEvent }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const dispatchAction = useDispatch();

 

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'edit':
        return {title: t("events.private_events.edit_contact_details"), width: '500px' };
      default:
        return { width };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalType])
      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
    setState('innerModal', null)
  }, [openModal, setState])
        

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const updateDetails = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({ data:{event_parking_application: data}, id: event.id})
      if(response.status === 200){
        fetchEventDetails({event_id: event?.event_id})
        setListElement(response.data)
        openNotificationWithIcon('success',t("events.private_events.edit_validations.details_saved"))
      }
      closeModal()
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ setState, closeModal, setListElement, event, fetchEventDetails, openNotificationWithIcon])

  const cancelEvent = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await cancel({id: event.id})
      if(response.status === 200){
        setListElement(response.data)
        openNotificationWithIcon('success',t("events.private_events.edit_validations.cancelled"))
      }
      closeModal()
    } catch (error) {
      openNotificationWithIcon('error', t("events.private_events.edit_validations.cancellation_not_allowed"))
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ setState, closeModal, setListElement, event, openNotificationWithIcon])




  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.applicationModal} 
      backdropClassName={styles.backdropC}
      style={{maxWidth: modalProps().width, width: '200%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        { modalType === 'edit' &&  
          <Edit 
            event={event} 
            setEvent={setEvent}
            {...state}
            closeModal={closeModal}
            updateDetails={updateDetails}
            setState={setState}
            fetchEventDetails={fetchEventDetails}
            errors={state.errors}
            isSaving={state.isSaving}
            t={t}
          /> 
        }
        {modalType==='cancel' && 
          <Cancel 
            event={event} 
            setEvent={setEvent}
            {...state}
            closeModal={closeModal}
            cancelEvent={cancelEvent}
            setState={setState}
            isSaving={state?.isSaving}
            t={t}
          /> 
        }
      </ModalBody>
    </Modal>
  )
}

export default EventModal
