import React from 'react'
import { Container, Row } from 'reactstrap';
import Button from 'components/base/button';
import styles from './index.module.sass'
import { show } from 'api/permit_applications';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';

class RejectApplicationReason extends React.Component {

  state = {
    permitApplication: {},
    isFetching: false,
  }
  
  componentDidMount () {
    this.setState(state => ({...state, isFetching: true}));
    const {record} = this.props
    const fetchPermitApplication = async () => {
      const { startFetching } = this.props;
      if(!record?.id)  return;

      try {
        const response = await startFetching(show({id: record.id}))
        if(response.status === 200) {
          const { data } = response;
          this.setState(state => ({...state, permitApplication: data }))
        }
      }
      catch(error) {
        console.log(error)
      }
      finally {
        this.setState(state => ({...state, isFetching: false}))
      }

    };
    fetchPermitApplication();
  };
  render () {
    const {record, closeModal} = this.props
    return (
      <Container>
        <p className={`${styles.title} m-0 p-0`}>Permit Application Rejected</p>
        <Row className={`${styles.body} mw-100 m-auto`}>
          <p className={`${styles.dangerColor} mt-3 mb-2 col-12`}>
            Reason
          </p>
          <p className={`${styles.content} col-12`}>
            <em>{this.state.permitApplication?.rejection_note || record.rejection_note }</em>
          </p>
          <p className='col-12 my-2'>
            <Button onClick={() => closeModal()} className={`${styles.closeButton} m-auto`}>Close</Button>
          </p>
        </Row>
      </Container>
    )
  };
  
};

export default withFetching(withCurrentUser(RejectApplicationReason));