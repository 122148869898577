import React from "react";

const TableRow = (props) => {
  const { record, setRecord } = props;
  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record.user}</td>
      <td>{record.points_earned}</td>
      <td>{record.points_redeemed} </td>
      <td>{record.points_in_dollar}</td>
      <td>{record.last_redemption_date}</td>
    </tr>
  );
};

export default TableRow;