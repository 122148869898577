import React, { useCallback } from "react";
import TableRow from "./table_row";
/* Api */
import { filterFetcher } from "api/user_rewards";
/* Styles */
import styles from "./index.module.sass";
/* Base */
import { IndexTable } from "components/base/table";

const TableData = (props) => {
  const {
    list,
    setRecord,
    filters,
    isResourceFetching,
    openModal,
    modalType,
    selectedRecord,
    activeTab,
  } = props;
  const renderRecords = useCallback(() => {
    return list?.map((record) => {
      return (
        <TableRow
          key={record.id}
          record={record}
          selectedRecordId={selectedRecord.id}
          openModal={openModal}
          modalType={modalType}
          setRecord={setRecord}
        />
      );
    });
  }, [list, modalType, setRecord, openModal, selectedRecord.id]);

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ""}`}
      isFetching={() => isResourceFetching}
      filterFetcher={filterFetcher}
      resource='user_reward'
      paginationQuery={{...filters}}
      columns={
        <React.Fragment>
          <th attr="name" style={{ width: "15%", minWidth: "210px" }}>
            Reward Program Name
          </th>
          <th attr="created_at" style={{ width: "45%", minWidth: "210px" }}>
            Enrolled On Date
          </th>
          <th attr="points_earned" style={{ width: "20%", minWidth: "210px" }}>
            Points Earned
          </th>
          <th
            attr="points_redeemed"
            style={{ width: "20%", minWidth: "210px" }}
          >
            Points Redeemed
          </th>
          <th
            attr="points_redeemed_in_dollar"
            style={{ width: "15%", minWidth: "210px" }}
          >
            Points Redeemed in $
          </th>
          <th
            attr="last_redemption_date"
            style={{ width: "45%", minWidth: "210px" }}
          >
            Last Redemption Date
          </th>
          <th
            className="d-flex justify-content-center"
            attr="user_rewards.actions"
            style={{ width: "20%", minWidth: "130px" }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName="User Rewards"
    />
  );
};

export default TableData;