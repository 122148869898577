import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* Modules */
import withCurrentUser from 'components/modules/with_current_user';
/* Assets */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.sass';
import { Card, CardBody, Collapse } from 'reactstrap';

const NavigationToolBar = (props) => {
  const {title, backPath, backTab, children, total, className, collapseContent, openCollapse} = props
  return (
    <>
      <div className={`${styles.toolbar} no-gutters`}>
        {backPath &&
          <div className="d-flex align-items-center mr-3 w-auto">
              <Link to={backPath} className={styles.backIcon} >
                <FontAwesomeIcon size="sm" color="grey" icon={faChevronLeft} />
              </Link>
              <span className={`${styles.title} general-text-1`}>
                {`${title} ${total ? `(${total})` : ''}`}
              </span>
          </div>
        }
        {backTab &&
          <div className="d-flex align-items-center mr-2">
            <Link to={backTab} className={`${styles.backTab} ${styles.tab} ${styles.title} ${styles.activeTab}`} >
              <FontAwesomeIcon size="sm" color="grey" icon={faChevronLeft} className='mr-2' />
              <span>
                Back
              </span>
            </Link>
          </div>
        }
        <div className={`d-flex flex-grow-1 ${className} `}>
          {children}
        </div>
      </div>
      {
        collapseContent &&
          <Collapse isOpen={openCollapse}>
            <Card className='border-0'>
              <CardBody className='p-0 m-0'>
                {collapseContent}
              </CardBody>
            </Card>
          </Collapse>
      }
    </>
  );
}

NavigationToolBar.propTypes = {
  title: PropTypes.string,
};

export default withCurrentUser(NavigationToolBar);
