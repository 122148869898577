import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/* API */
import { filterFetcherDashboard } from 'api/themes';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* Actions */
import { SET_LIST, SET_LIST_ELEMENT, SET_FILTERS, SET_SINGLE_RECORD, POP_LIST_ELEMENT } from 'actions/themes';
import { invoke } from 'actions';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
/* Components */
import TableData from './table_data';
import ThemeModal from '../../theme_modal';

const resource = 'theme';

const Index = (props) => {
  const {list, currentUser, activeTab, setUpdatedProps, modalType, openModal, listType } = props
  setUpdatedProps(props) 
  
  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>  
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <TableData {...props} list={list} listType={listType} activeTab={activeTab} modalType={modalType} openModal={openModal} />
          </Col>
        </Row>
        <ThemeModal
        {...props}
        openModal={openModal}
        modalType={modalType}
      />
      </Container>
    </AccessControl>
  )
}

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setFilters: invoke(SET_FILTERS), 
      setRecord: invoke(SET_SINGLE_RECORD),
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.theme.filters,
    selectedRecord: state.theme.records.record,
    record: state.theme.records.record,
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcherDashboard, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);