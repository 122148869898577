import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';

const { index, show, destroy, create, update } = resourceApi('notification_configurations');
const { index: fetchNotiTypes } = resourceApi('notifications/types');

const filterFetcher = (params = {}) => {
  const { page, perPage, query, filters = {} } = params;

  return index({
    page,
    perPage,
    query: Object.assign({}, query, {
      types: filters.type
    })
  });
};

const change_status = (params = {}) => {
  const { id } = params;
  return fetchApi(`dashboard/notification_configurations/${id}/change_status`, { method: 'PUT' });
};

export { filterFetcher, fetchNotiTypes, show, update, create, destroy, change_status };
