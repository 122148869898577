import { UserChargingSessionsActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "./entities";

const { index, records, filters } = reduceEntity(UserChargingSessionsActions);

const UserChargingSessionsReducers = combineReducers({
  index,
  records,
  filters,
});

export default UserChargingSessionsReducers;