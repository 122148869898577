import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Create from '../create';
import Single from '../add';
/* Assets */
import styles from './index.module.sass'
/* API */
import { ADD_LIST_ELEMENT } from 'actions/coupons';
import { create } from 'api/coupons';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, parkingLots: []}


const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const CouponModal = (props) => {
  const { openModal, modalType, setActiveTab } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})
  const [couponUniquenessVal, setCouponUniquenessVal] = useState(false)

  const addListElement = useCallback((payload) => {
    dispatch({type: ADD_LIST_ELEMENT, payload});
  }, [dispatch]);

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
  }, [openModal, setActiveTab])

  

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const generateCouponCode = useCallback(async (data) => {
    try {
      const response = await create({ data: { coupon: data } })
      addListElement(response.data)
      addAlertMessages([{
        type: 'primary',
        text: modalType==='create'? `${response.data.coupon_code} Coupon Code Generated Successfully`:"Coupon Successfully Added",
        onlyMessage: true,
      }], 'center')
      closeModal()
    } catch (error) {
      setErrors(error.response?.data?.errors)
      if(error?.response?.data?.errors?.coupon && error?.response?.data?.errors?.coupon[0] === 'Coupon should be unique.'){
        setErrors(error.response.data.errors.coupon_code , ["Coupon Code Must be unique !"])
      }
      setCouponUniquenessVal(error?.response?.data?.errors?.coupon && error?.response?.data?.errors?.coupon[0] === `Coupon should be unique.`)
      if(error?.response?.data?.errors?.coupon &&  error?.response?.data?.errors?.coupon[0] !== `Coupon should be unique.`){
        addAlertMessages([{
          type: 'danger',
          text: error.response?.data?.errors.coupon.toString(),
          onlyMessage: true,
        }], 'center')
        closeModal();
      }
    }

  },[setErrors, addListElement, closeModal, addAlertMessages, modalType, setCouponUniquenessVal])


  

  const modalProps = () => {
    let width = '500px'
    switch (modalType) {
      case 'create':
        return {title: 'Generate Coupon Code', maxWidth : '600px'}
      case 'add':
        return {title: 'Add a Coupon', maxWidth : '600px'}
      default:
        break;
    }
    return { width }
  }

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.couponModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'create' && 
          <Create
            state={state}
            setState={setState}
            closeModal={closeModal}
            generateCouponCode={generateCouponCode}
            setErrors={setErrors}
            errors={errors}
            couponUniquenessVal={couponUniquenessVal}
          />
        }
        {
          modalType === 'add' && 
          <Single
            state={state}
            setState={setState}
            closeModal={closeModal}
            generateCouponCode={generateCouponCode}
            setErrors={setErrors}
            errors={errors}
            couponUniquenessVal={couponUniquenessVal}
            setCouponUniquenessVal={setCouponUniquenessVal}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default CouponModal;
