import React, { useEffect, useState, useCallback } from "react";
import { Card, Row, Col, ConfigProvider, Input } from "antd";
import { Card as ReactStrapCard, CardBody, Collapse } from "reactstrap";
import styles from "./index.module.sass";
import { ReactComponent as FilterIcon } from "assets/filter_icon.svg";
import Button from "components/base/button";
import { displayDate, capitalize } from "components/helpers";
import { isEmpty } from "underscore";
import "./card.css";
import PermitModal from "../../permitModal";
import { myPermitFetcher } from "api/webapp/permits";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { ReactComponent as SearchIcon } from "assets/search_icon.svg";
import { ReactComponent as PDFIcon } from "assets/app/pdf-icon.svg";
import PermitApplicationModal from "../../../permit_applications/permitApplicaitonModal/index";
import env from ".env";

import CollapseContent from "./toolbar";
import { searchV1 as dropdownsSearch } from "api/dropdowns";

const IssuedPermits = (props) => {
  const { currentSubscriber, setTotal, openModal, modalType, t } = props;
  const [activeTab, setActiveTab] = useState("");
  // eslint-disable-next-line
  const [showFilter, setShowFilter] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // To store the selected transaction details
  const [initialList, setInitialList] = useState([]);
  const [list, setList] = useState(initialList);
  const [filters, setFilters] = useState("");
  const [status, setStatus] = useState("all");
  const [permitTypes, setPermitTypes] = useState([]);
  const [plateNos, setPlateNos] = useState([]);
  const [search, setSearch] = useState('')

  const handleTabChange = (e) => {
    setStatus(e);
    fetchList(e);
  };

  useEffect(() => {
    const fetchPlateNumbers = async () => {
      try {
        const plateNosList = await dropdownsSearch("vehicle_lpn_list");
        setPlateNos(
          plateNosList?.data?.map((pl) => {
            return { value: pl?.value, label: pl?.label?.toUpperCase() };
          })
        );
      } catch (_error) {}
    };
    if (isEmpty(plateNos)) fetchPlateNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await dropdownsSearch("permit_list");
        setPermitTypes(permitTypesList.data);
      } catch (_error) {}
    };
    if (isEmpty(permitTypes)) fetchPermitTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleCollapse = (tab) => {
    if (isFetching) return;
    setShowFilter(!showFilter);
    setActiveTab((activeTab) => (activeTab === tab ? null : tab));
  };

  const fetchList = useCallback(
    async (statusFilter) => {
      setIsFetching(true);
      try {
        const list = await myPermitFetcher({
          filters: statusFilter === "all" ? "" : { status: statusFilter },
        });
        setTotal(list?.data?.length);
        setInitialList(list.data);
        setList(list.data);
      } catch (_error) {
      } finally {
        setIsFetching(false);
      }
    },
    [setTotal]
  );

  const handleSearch = (value) => {
    setSearch(value);
    if(!value){
      fetchList(status)
      return
    }
    const filteredPermits = list?.filter(permit =>
      Object.values(permit).some(val =>
        typeof val === 'object' && val 
          ? Object?.values(val)?.some(nestedVal => nestedVal?.toString()?.toLowerCase()?.includes(value?.toLowerCase()))
          : val?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
    setList(filteredPermits)
  };

  useEffect(() => {
    if (isEmpty(initialList) && status === "all") {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialList, setInitialList, status]);

  useEffect(() => {
    handleSearch(search)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])


  const tabsList = [
    {
      key: "all",
      label: t("subscriber.my_permits.tabs.all"),
    },
    {
      key: "active",
      label: t("subscriber.my_permits.tabs.active"),
    },
    {
      key: "expired",
      label: t("subscriber.my_permits.tabs.expired"),
    },
    {
      key: "revoked",
      label: t("subscriber.my_permits.tabs.revoked"),
    },
    {
      key: "cancelled",
      label: t("subscriber.my_permits.tabs.cancelled"),
    },
  ];

  const handleCardClick = (record) => {
    setSelectedRecord(record);
    openModal("show");
  };

  const handleButtonClick = (event, modalType, record) => {
    event.stopPropagation();
    setSelectedRecord(record);
    openModal(modalType, record.status);
  };

  const downloadPDF = async (event, record) => {
    event.stopPropagation();
    const response = await fetch(
      `${env.backend_url}/v1/permits/${record.id}/permit_pdf`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.SUB_TOKEN,
        },
      }
    );

    if (!response.ok) {
      console.error("Failed to download file");
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${record.permit_number} Permit.pdf`; // You can set the filename dynamically if needed
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.container}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A9CED",
          },
        }}
      >
        <Card
          style={{
            width: "100%",
          }}
          tabList={tabsList}
          activeTabKey={status}
          tabBarExtraContent={
            <Col className="d-flex justify-content-end pr-1">
              <Input
                suffix={<SearchIcon />}
                className={styles.filterInput}
                placeholder={t("subscriber.search_placeholder")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                onClick={() => handleToggleCollapse("filters")}
                className={`${styles.btnFilter} ${
                  activeTab === "filters" ? styles.activeTab : ""
                }`}
                icon={
                  <FilterIcon
                    className={`${
                      activeTab === "filters" ? styles.activeIcon : ""
                    }`}
                  />
                }
                size="md"
              />
            </Col>
          }
          onTabChange={handleTabChange}
          tabProps={{
            size: "large",
          }}
          className={styles.mainCard}
        >
          {showFilter && (
            <Collapse isOpen={showFilter} className={styles.collapse}>
              <ReactStrapCard className="border-0">
                <CardBody className="p-0 m-0">
                  <CollapseContent
                    activeTab={activeTab}
                    filters={filters}
                    setFilters={setFilters}
                    setList={setList}
                    setIsResourceFetching={setIsFetching}
                    permitTypes={permitTypes}
                    plateNos={plateNos}
                    t={t}
                  />
                </CardBody>
              </ReactStrapCard>
            </Collapse>
          )}
          {!isEmpty(list) &&
            list?.map((item, index) => (
              <Card
                key={index}
                className={styles.card}
                loading={isFetching}
                hoverable={true}
                onClick={() => handleCardClick(item)}
              >
                <div className={styles.dateTime}>
                  <span>
                    {item.status === "expired" ? "Expired" : "Expires"}
                  </span>
                  on
                  <span className="pl-1">
                    {displayDate(item?.expiry_date, "MMMM Do YYYY")}
                  </span>
                </div>
                <Row className={styles.rowContent} gutter={[16, 16]}>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    className={styles.hideOnMobile}
                  >
                    <div className={styles.label}>{t("signup.Expiration Date")}</div>
                    <div className={styles.detail}>
                      {
                        <span>
                          {displayDate(item?.expiry_date, "MMMM Do YYYY")}
                        </span>
                      }
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={3}>
                    <div className={styles.label}>{t("subscriber.permit_number")}</div>
                    <div className={styles.detail}>{item?.permit_number}</div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={4}>
                    <div className={styles.label}>{t("permit_application.apply_for_permit.labels.step3.permit_type")}</div>
                    <div className={styles.detail}>{item?.permit_type}</div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={4}>
                    <div className={styles.label}>{t("events.city_events.get_pass_form.vehicle_lpn.label")}</div>
                    <div className={styles.detail}>
                      {item?.vehicle?.plate_number}
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={4}>
                    <div className={styles.label}>{t("events.private_events.status")}</div>
                    <div className={styles.detail}>
                      {capitalize(item?.status)}
                    </div>
                  </Col>
                  <Col
                    className={styles.actionsCol}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    <div className={styles.actionsLabel}>{t("subscriber.actions")}</div>
                    <div className={styles.actions}>
                      <Button
                        className={
                          item.status === "active"
                            ? styles.cancel
                            : `${styles.cancel} ${styles["button-bg-secondary"]}`
                        }
                        onClick={(e) => handleButtonClick(e, "cancel", item)}
                      >
                        {t("buttons.cancel")}
                      </Button>
                      <Button
                        className={
                          item.status === "expired"
                            ? styles.renewBtn
                            : `${styles.renewBtn} ${styles["button-bg-secondary"]}`
                        }
                        onClick={(e) => handleButtonClick(e, "renew", item)}
                      >
                        {t("buttons.renew")}
                      </Button>
                      <Button
                        className={
                          item.status !== "expired"
                            ? styles.extendBtn
                            : `${styles.extendBtn} ${styles["button-bg-secondary"]}`
                        }
                        onClick={(e) => handleButtonClick(e, "extend", item)}
                      >
                        {t("buttons.extend")}
                      </Button>
                      <PDFIcon
                        className={styles.pdfIcon}
                        onClick={(e) => downloadPDF(e, item)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
          {isEmpty(list) && !isFetching && (
            <Row className="d-flex justify-content-center text-center align-items-center pt-4 mt-3">
              <p>{t("no_results_found")}</p>
            </Row>
          )}
        </Card>
      </ConfigProvider>
      <PermitModal
        record={selectedRecord}
        openModal={openModal}
        modalType={modalType}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        currentSubscriber={currentSubscriber}
      />
      {modalType === "apply_permit" && (
        <PermitApplicationModal
          record={selectedRecord}
          openModal={openModal}
          modalType={modalType}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentSubscriber={currentSubscriber}
        />
      )}
    </div>
  );
};

export default withCurrentSubscriber(IssuedPermits);