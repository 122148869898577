import React from "react";
import { Alert, ConfigProvider } from "antd";
/* Styles/Assets */
import styles from "./index.module.sass";

const Description = (props) => {
  const { selectedColor, title } = props;

  const getDescription = (name) => {
    switch (name) {
      case "basic_theme":
        return "This is the Basic theme of the Application. This will change the primary color of the buttons, navigation bar etc. in the application.";
      case "default_text":
        return "This is the Default Text Color of the Application. This will apply to all the texts of the buttons, input fields, labels etc. in the appliction";
      case "navbar":
        return "This is the background color of the Navigation Bar of the application.";
      case "navbar_text":
        return "This is the text Color of the Navigation Bar of the application.";
      case "table_header":
        return "This is the background color of the headers of the Tables in the application.";
      case "table_header_text":
        return "This is the Text Color of the Table Headers of the Tables in the application.";
      case "table_items_bg_one":
        return "This is the primary background color of the table data in the tables of the application.";
      case "table_items_bg_two":
        return "This is the secondary background color of the table data in the tables of the application.";
      case "table_item_text":
        return "This is the Text Color of the tablel data in the tables of the application.";
      case "page_header_text":
        return "This is the Text Color of all the Page Headers of the application including the table titles etc.";
      case "primary_button_bg":
        return "This is the Background Color of the primary buttons in the project like Submit, Save Buttons etc.";
      case "default_button_text":
        return "This is the default Text Color for the buttons in the application.";
      case "danger_button_bg":
        return "This is the Background Color for danger buttons used in the application like Delete, Revoke, Remove buttons etc.";
      case "save_button_bg":
        return "This is the Background Color for Save buttons in the application.";
      case "cancel_button_bg":
        return "This is the Background Color for Cancel buttons in the application.";
      default:
        return "";
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorInfo: "#3A9CED"
        },
      }}
    >
      <Alert
        message={title.toUpperCase()}
        description={getDescription(selectedColor)}
        type="info"
        showIcon
        className={styles.description}
      />
    </ConfigProvider>
  );
};

export default Description;