import React, { useState, useEffect } from "react";
import { isEmpty } from "underscore";
import { Row, Col, Card, Popover, PopoverBody} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DocumentIcon } from 'assets/app/document-svgrepo-com.svg';
import Dropdown from "components/base/dropdown";
import Chart from 'react-apexcharts'
import DetailedReportApi from "api/detailed_reports";
import Loader from "components/helpers/loader";
import style from "./data_card.module.sass";

const Donut = (props) => {
  const { data } = props
  const percentage = parseInt(data.permits_data.pie_chart_percentage.todays_percentage)
  let state = {
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 100,
            size: "80%",
            background: "#EFF2F4",
            position: "front",
            dropShadow: {
              enabled: false,
            }
          },
          track: {
            background: "#EFF2F4",
            strokeWidth: "85%",
            margin: -12,
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 30,
              show: true,
              color: "#B6B6B6",
              fontSize: "14px",
              fontWeight: "400"
            },
            value: {
              formatter: function (val) {
                return `${val}%`;
              },
              offsetY: -25,
              color: "#242E42D9",
              fontSize: "31.34px",
              fontWeight: "600",
              show: true
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#3A9CED"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: ["of past permits"]
    },
    seriesRadial: [`${isNaN(percentage) ? 70 : percentage}`],
  }
  
  return(
    <div className={style.app}>
      <Chart
        series={state.seriesRadial}
        options={state.optionsRadial}
        type="radialBar"
        width="100%"
      />
      <div className={`d-flex ${style.belowLabel}`} style={{marginLeft: '25%', color: '#A0A0A0'}}>
        <div style={{width: '8rem', fontSize: '11px', textAlign: 'right', fontWeight: '550'}}>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: '#E8EBED',
              width: 10
            }}
          /> 
          Past
        </div>
        <div className={style.vl}></div>
        <div style={{width: '25rem', fontSize: '11px', fontWeight: '550'}}>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: '#3A9CED',
              width: 10
            }}
          /> 
          Present
        </div>
      </div>
    </div>
  )
}



const PermitCard = (props) => {
  const { parkingLots, defaultParkingLot, type } = props
  const [data, setData] = useState();
  const [permitInfoOpen, setPermitInfoOpen] = useState(false);
  const [selectedParkingLot, setSelectedParkingLot] = useState([defaultParkingLot])
  
  useEffect(() => {
    if((!isEmpty(parkingLots) && selectedParkingLot.length ===1 && selectedParkingLot[0]?.label === "All Parking Lots")){
      setSelectedParkingLot(parkingLots);
    }
  },[selectedParkingLot, parkingLots, setSelectedParkingLot])

  const fetchData = () => {
    try{
      DetailedReportApi.index({
      resource: 'permits'
      }).then(({ data }) => {
        setData(data);
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(isEmpty(data)){
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  const updateData = (selectedParkingLots, selectAllChecked) => {
    const isUserCheckedParkingLot = selectedParkingLots?.some((parkingLot) => parkingLot.value === 0);
    const filteredSelectedParkingLots = selectedParkingLots.filter(
      (parkingLot) => parkingLot.value !== 0
    );
    const selectedParkingLotsValues = filteredSelectedParkingLots.map(
      (selectedParkingLot) => selectedParkingLot.value
    );
    setSelectedParkingLot(selectAllChecked ? (isUserCheckedParkingLot ? parkingLots : []) : filteredSelectedParkingLots);
    DetailedReportApi.index({
      configs: {parking_lot_ids: selectedParkingLotsValues},
      resource: 'permits',
      parkingLotIds: selectedParkingLotsValues,
    }).then(({ data }) => {
      setData(data)
    });
  };

  const toggleTileInfo = () => {
    setPermitInfoOpen(!permitInfoOpen);
  };

  return(
    <React.Fragment>
      <Card className={style.borderPrimary}>
        {isEmpty(data) ? (
              <Loader />
            ) : (
              <React.Fragment>
                <Row className={`${style.cardTitle}`}>
                <Col className={`${style.title} pr-0`}>
                  Permits
                  {
                    <React.Fragment>
                      <button
                        className="ml-2 border-0 bg-white text-primary"
                        id={type}
                      >
                        <FontAwesomeIcon
                          color="#C7CED9"
                          icon={faQuestionCircle}
                        />
                      </button>
                      <Popover
                        placement="bottom"
                        isOpen={permitInfoOpen}
                        target={type}
                        toggle={toggleTileInfo}
                        trigger="click hover focus"
                      >
                        <PopoverBody>{data?.title}</PopoverBody>
                      </Popover>
                    </React.Fragment>
                  }
                </Col>
                <Col xs="auto" className="pl-0" style={{marginTop: '-5px'}}>
                  <Dropdown
                    multiple
                    coveringText={() => coveringText(selectedParkingLot)}
                    options={parkingLots}
                    onChange={updateData}
                    value={selectedParkingLot}
                    width="150px"
                    size="sm"
                    showToolTip={false}
                    customInputClass={style.className}
                  />
                </Col>
              </Row>
              <Row xs={12}>
                <Col xs={7}>
                  <Donut 
                    {...props}
                    data={data}
                  />
                </Col>
                <div className={style.vline}></div>
                <Col className={style.rightSide} xs={4}>
                  <DocumentIcon className={style.icon}/>
                  <div className={style.number}>{data?.permits_data?.todays_permits}</div>
                  <div className={style.data} >New Permits Today</div>
                  <div className={style.data1}>
                    <span style={{fontWeight: '500', color: '#3A9CED'}}>
                      {data?.permits_data?.percentage}
                    </span>
                    <span className="ml-1" style={{color: '#B6B6B6'}}>from yesterday</span>
                  </div>
                  <div className={`flex-grow-1 ml-2 ${style.borderDown}`}></div>
                  <div className={style.number}>{data?.permits_data?.yesterdays_permits}</div>
                  <div className={style.data}>New Permits Yesterday</div>
                </Col>
              </Row>
            </React.Fragment>
          )
        }
      </Card>
    </React.Fragment>
  );
}
const coveringText = (value) => {
  return( 
    <div className={style.lotContainer}>
      <span className={style.lotContainer1}>Selected</span>
      <span className={style.lotContainer2}>{value ? value.length : "0"}</span>
    </div>
  )
};

export default PermitCard;
