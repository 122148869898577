import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { list as selectList } from 'selectors/list';
import { Form } from 'informed';
import { isEmpty, map } from 'underscore';
/* API */
import { filterFetcher } from 'api/permit_types';
import { search as dropdownSearch } from 'api/dropdowns'
/* Helpers */
import { sortFields } from 'components/helpers/fields/permit_types/index';
/* Styles/Assets */
import { ReactComponent as SearchIcon } from 'assets/search_icon.svg';
import { ReactComponent as SquareIcon } from 'assets/share-square.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { ReactComponent as ShareIcon } from 'assets/share-icon.svg';
import styles from './index.module.sass';
/* Base */
import Button from 'components/base/button';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { useSelector } from 'react-redux';
import { renderField } from 'components/base/forms/common_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as StarIcon } from 'assets/star-icon.svg'
import { Link } from 'react-router-dom';
import Toggle from 'components/base/toggle';

const ShareSquareIcon = () => {
  return (
    <div className='position-relative'>
      <SquareIcon />
      <ShareIcon  style={{ position: 'absolute', top: '7px', left: '3px' }}/>
    </div>
  )
}

const CollapseContent = (props) => {
  const { setFormApi, assignFilters, activeTab, openModal, setSetting, allowMultipleVehicle, allowMultipleDailyPermit } = props
  const [admins, setAdmins] = useState()
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const adminsList = await dropdownSearch('admins_list')
        setAdmins(adminsList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(admins)){fetchAdmins();}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[admins])

  const filters = useSelector(state => state.permit_type.filters)

  const sortFieldProps = {
    lSize: 5,
    iSize: 7,
    events: {
      onChange: (_e) => assignFilters()
    }
  }

  return (
    <Row className={`${styles.collapseSection} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">

      {activeTab === 'setting' && 
        <Col className='ml-3 mb-2'>
          <Row className={styles.checkboxRow}>
          <Toggle
            onClick={() => {openModal('permit_setting_confirmation'); setSetting('vehicle_setting')}}
            customToggleClass= {styles.toggle}
            customToggleOnClass= {styles.toggleOn}
            positiveText=''
            negativeText=''
            value={allowMultipleVehicle}
            offHighlightClass={styles.toggleHighlight}
            onHighlightClass={styles.toggleOnHighlight}
          />
          <span className={styles.checkboxDescription}>
            Allow users to add multiple vehicles to a permit 
          </span>
          </Row>
          <Row className={styles.checkboxRow} style={{marginTop: '20px'}}>
            <Toggle
              onClick={() => {openModal('permit_setting_confirmation'); setSetting('daily_permit_setting')}}
              customToggleClass= {styles.toggle}
              customToggleOnClass= {styles.toggleOn}
              positiveText=''
              negativeText=''
              value={allowMultipleDailyPermit}
              offHighlightClass={styles.toggleHighlight}
              onHighlightClass={styles.toggleOnHighlight}
            />
            <span className={styles.checkboxDescription}>
              Allow users to apply for multiple daily permits in single transaction
            </span>
          </Row>
        </Col>
      }

    {activeTab !== 'setting' && 
      <Col className="col-12 p-0">
        <Form getApi={setFormApi} initialValues={{...filters}} className='d-flex px-3'>
          {map(sortFields(admins, styles.formLabel, styles.formInput), (field, idx) => {
            if(idx === 0) {
              field = {...field, options: map(field.options, ({value, label}) => ({
                value,
                label: <>
                <FontAwesomeIcon 
                  className='mr-2' 
                  icon={faCircle}
                  style={{ 
                    color: `${value === 'active' ? 'green' : 'red'}`,
                    width: 10
                  }}
                /> 
                  {label}
                </>
              }))}
            }
            return (
              <div key={idx} className='w-25'>
                {renderField(field, {...sortFieldProps })}
              </div>
            )
          })}
        </Form>
      </Col>
    }
    </Row>
  )
}

const ToolBar = (props) => {
  const { startFetching, match, setList, openModal, filters, setFilters, activeTab, setActiveTab, isResourceFetching } = props
  const [showSearchBox, setShowSearchBox] = useState();
  const [name, setName] = useState('')

  const formApiRef = useRef()

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const searchPermitTypes = useCallback((query={}) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, { query })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[match, startFetching, setList])
  
  const handleToggleCollapse = (tab) => {
    if(isResourceFetching) return
    setActiveTab(activeTab => activeTab === tab ? null : tab)
  }

  const assignFilters = (values) => { 
    let formFilters = {}
    if(formApiRef.current) { formFilters = formApiRef.current.getValues() }

    setFilters(Object.assign(formFilters, values))
    searchPermitTypes(Object.assign(formFilters, values))
  }

  useEffect(() => {
    if(isEmpty(filters) && formApiRef.current && !isEmpty(formApiRef.current.getValues())) {
      formApiRef.current.setValues({status: '', payment_type: '', category: '', created_by: ''})
    }
  }, [filters])

  useEffect(() => {
    setName(filters.name || '')
  },[ filters.name])

  const openCollapse = useMemo(() => (
    !!activeTab &&  !['search', 'issue_permit', 'create'].includes(activeTab)
  ),[activeTab])
 
  return (
    <NavigationToolbar
      openCollapse={openCollapse}
      backPath={'permits'}
      {...props}
      className='justify-content-end'
      title='Manage Permit Types'
      collapseContent={
        <CollapseContent
          {...props}
          activeTab={activeTab}
          formApiRef={formApiRef}
          setFormApi={setFormApi}
          assignFilters={assignFilters}
          setFilters={setFilters}
        />
      }
    >
      <Button
          onClick={() => handleToggleCollapse('setting')}
          className={`${styles.citationSettingTab} ${activeTab === 'setting' ? styles.activeCitationSettingTab : ''}`}
        >
          <span className='px-3'>Setting</span>
        </Button>
      <SlideButton
        isActive={showSearchBox}
        hideIconOnHover={true}
        icon={<SearchIcon />}
        onClick={() => {handleToggleCollapse(); setShowSearchBox(true)}}
      >
        <Form 
          className={`${styles.searchForm} d-flex position-relative`} 
          onSubmit={() => assignFilters({name})}
        >
          <SearchIcon style={{position: 'absolute', top: '8px', left: '6px'}} />
          <input 
            type='search' 
            name='name' 
            value={name}
            placeholder='Type to search'
            className='form-control shadow-none'
            onChange={ (e) => setName(e.target.value) }      
          />
          <Button className={styles.headerButton} type='submit'>
            search
          </Button>
        </Form>
      </SlideButton>
      <SlideButton
        isActive={activeTab === 'create'}
        icon={<PenToSquareSvg />}
        onClick={() => {
          handleToggleCollapse('create'); 
          setShowSearchBox(false)
          openModal('create')
        }}
      >
        <span className='mr-3 d-inline-block' style={{width: '168px'}}>Create new permit type</span>
      </SlideButton>
      <SlideButton
          icon={<StarIcon style={{ width: '18px', height: '18px'}} />}
        >
          <Link to='special_permits' className='mr-3 d-inline-block text-decoration-none text-white' style={{width: '113px'}}>Special Permits</Link>
        </SlideButton>
      <SlideButton
        isActive={activeTab === 'issue_permit'}
        icon={<ShareSquareIcon />}
        onClick={
          () => {
            handleToggleCollapse('issue_permit'); 
            setShowSearchBox(false);
            openModal('issue_permit')
          }
        }
      >
        <span className='mr-3 d-inline-block' style={{width: '90px'}}>Issue Permit</span>
      </SlideButton>
      <SlideButton
        isActive={activeTab === 'filters'}
        status={`${activeTab === 'filters' ? 'activeSecondary' : 'secondary'}`}
        className={`${activeTab === 'filters' ? styles.activeFilterTab : ''}`}
        icon={<ArrowDownIcon className={`${activeTab === 'filters' ? styles.activeFilterIcon : ''}`} />}
        onClick={() => {handleToggleCollapse('filters'); setShowSearchBox(false)}}
      >
        <span className='mr-3 d-inline-block'>Sort</span>
      </SlideButton>
    </NavigationToolbar>
  )
}

export default ToolBar;