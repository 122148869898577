import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { map } from 'underscore';
import styles from './index.module.sass';
import { useHistory } from "react-router-dom";
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as UnionDotsIcon } from 'assets/union-dots.svg'
import { ReactComponent as VehicleIcon } from 'assets/vehicle_icon.svg'

import Toggle from 'components/base/toggle';
import Action from './action';

const TableRow = (props) => {
  const {openModal, record, setRecord, modalType, match} = props;
  const selectedRecordId = useSelector(state => state.permit.records.record?.id)
  const [highlightEnabled, setHighlightEnabled] = useState(false)
  const history = useHistory();

  const DetailsIcon = () => {
    return <div 
      role='button'
      className={`${styles['icon-details']} d-flex justify-content-center align-items-center ml-3`}
    >
      <UnionDotsIcon style={{ width: 14, height: 10}} />
    </div>
  }
  
  const actions = [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => {openModal('show')},
      text: 'View',
      isActive: modalType === 'show' && selectedRecordId === record.id,
    },
    {
      icon: <VehicleIcon className={`${styles['icon-vehicle']} mx-3`} />,
      highlightedClass: `${styles['background-vehicles']} mx-3 text-white`,
      onClick: () => history.push(`${match.path}/${record.id}/vehicles`),
      text: 'Vehicles',
      isActive: modalType === 'show' && selectedRecordId === record.id,
    },
    { 
      icon: <DetailsIcon/>,
      highlightedClass: `${styles['background-secondary']} ml-3 text-white`,
      text: 'Details',
      onClick: () => {history.push(`${match.path}/${record.id}/coupons`, {user_id: record?.id })}
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.id}</td>
      <td>{record?.first_name}</td>
      <td>{record?.last_name}</td>
      <td>{record?.email}</td>
      <td>{record?.account_type?.name}</td>
      <td>{record?.vehicles_owned}</td>
      <td>
        <Toggle
          onClick={() => {setHighlightEnabled(true); openModal('activate_deactivate')}}
          customToggleClass= {styles.toggle}
          customToggleOnClass= {styles.toggleOn}
          positiveText=''
          negativeText=''
          value={record?.status === 'active'}
          offHighlightClass={styles.toggleHighlight}
          onHighlightClass={styles.toggleOnHighlight}
          highlightEnabled={highlightEnabled && modalType === 'activate_deactivate' && selectedRecordId === record?.id}
        />
      </td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center`} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>

  )
}

export default TableRow;
