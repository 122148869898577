import React from 'react'
import styles from './index.module.sass'
import Loader from 'components/helpers/loader';
import {Col} from 'reactstrap'
import Button from 'components/base/button';

const TableRow = (props) => {
  const {record, setSelectedRecord, isFetching, openModal} = props
  const {color} = record
  const renderLoader = () => {
    return (
      <Col className={styles.loader}>
        <Loader />
      </Col>
    );
  }

  return (
    isFetching? renderLoader() :
      <tr onClick={() => setSelectedRecord(record)}>
        <td className='text-uppercase'>{record?.plate_number}</td>
        <td className='text-capitalize'>{record?.manufacturer?.name}</td>  
        <td className='text-capitalize'>{record?.model}</td>  
        <td className='text-capitalize'>
          <div className='d-flex'>
            <span style={{backgroundColor: record.color}} className={`${styles.dotIcon} mr-2`}> </span>
            <span style={{marginTop: '-0.1rem'}}>{color && color}</span>
          </div>
        </td>
        <td>
          <div className={`${styles.actions} d-flex justify-content-center `} >
            <span>
              <Button
                className={`${styles.bootButton} mx-3`} 
                onClick={() => openModal('boot')}
              >
                Boot
              </Button>
            </span>
          </div>
        </td>
      </tr>
  );
};

export default TableRow