/* eslint default-case: "off" */
import { INIT_SET_TOKEN, UserActions } from 'actions';

const persistTokenMiddleware = _ => next => action => {
  switch (action.type) {
    case INIT_SET_TOKEN:
      // TODO: add token verification logic here (via fetch 'verify-token')
      const tokenPayload = localStorage.TOKEN;
      const subTokenPayload = localStorage.SUB_TOKEN;

      if (tokenPayload) {
        return next(UserActions.setDashboardToken(tokenPayload));
      }
      if (subTokenPayload) {
        return next(UserActions.setSubscriberToken(subTokenPayload));
      }
      break;
    case UserActions.SET_TOKEN:
      localStorage.setItem('TOKEN', action.payload);
      break;
    case UserActions.CLEAR_TOKEN:
      localStorage.removeItem('TOKEN');
      break;
    case UserActions.SET_SUB_TOKEN:
      localStorage.setItem('SUB_TOKEN', action.payload);
      break;
    case UserActions.CLEAR_SUB_TOKEN:
      localStorage.removeItem('SUB_TOKEN');
  }

  return next(action);
};

export default persistTokenMiddleware;
