import React, { useEffect, useState } from 'react'
import { map } from 'underscore';
import styles from './index.module.sass';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx !== 0 ? 'border-left' : ''}`}
      role='button'
      style={{cursor: `${disableHighlight && 'not-allowed'}`}}
    >
      { showText
        ? <span 
            className={`${showText ? 'd-inline-flex justify-content-center align-items-center' : 'd-none'} ${highlightedClass}`}
            style={{padding: '5px 10px', borderRadius: '15px' }}
          >
            {text}
          </span>
        : <span className={`${showText ? 'd-none' : 'd-inline-flex justify-content-center align-items-center'}`}>{icon}</span>
      }
    </div>
  )
}

const TableRow = (props) => {
  const {record, modalType, history, location} = props
  const actions = [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => {history.push(`waitlist_users`, {permit_type_data: location?.state?.data, parking_lot_data: record })},
      text: 'View',
      isActive: modalType === 'show'
    },
    { 
      icon: <EditIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      onClick: () => {''},
      text: 'Edit',
      isActive: modalType === 'edit'
    },
  ]

  return (
    <tr >
      <td>{record?.id}</td>
      <td>{record?.name}</td>
      <td>{record?.user_enrolled}</td>
      <td >{record?.spaces_reserved_for_permit}</td>
      <td>{record?.last_enrolled_on}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow