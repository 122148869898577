import React from 'react'
import { Col, Container } from 'reactstrap';
/* Base */
import Button from 'components/base/button';
/* Assets */
import styles from './index.module.sass'
import { ReactComponent as SuccessIcon } from 'assets/app/success-icon.svg';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

const Confirmation = (props) => {
const {closeModal } = props
const history = useHistory();

  const message = () => <>
    <br/>
    <span className={`${styles.content} d-flex justify-content-center my-2 'mx-1`}> Payment for your Event Parking is Successful. You will receive the payment receipt on the given email address.</span>
    <br/>

  </>

  const redirect = () =>{ 
    let path = `/login`; 
    closeModal()
    history.push(path);
  }

  return (
    <Container className='p-0 m-0'>
      <Col className="d-flex justify-content-center my-2 pb-2">
        <SuccessIcon/>
      </Col>
      <span className='mx-1' style={{fontWeight:"", marginTop:"10%"}}>Payment Successful</span>
    <Container className='mb-4' >
      <Col className="d-flex justify-content-center my-2">
        { message() }
      </Col>
      <br/>
      <Col className="d-flex justify-content-center m-0 p-0">
        <p className={styles.content} style={{marginTop:'-1.5rem'}}>Thank You !</p>
      </Col>

      <Col className="d-flex justify-content-center my-2">
        {
            <Button 
                type='button' 
                onClick={redirect} 
                className={`${styles.confirmBtn}`}
              >
               Ok
            </Button>
        }  
      </Col>
    </Container>
  </Container>
  )
}

Confirmation.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default Confirmation;
