import applicantDetails from "./applicantDetails";
import applicationDetails from "./applicationDetails/approve";
import vehicleDetails from "./vehicleDetails";
import visitorDetails from "./visitorPermitFields";

const approveFields = (attrs) => {
  return [
    applicantDetails(attrs),
    vehicleDetails(attrs),
    applicationDetails(attrs),
    visitorDetails(attrs)
  ]
}

export default approveFields;
