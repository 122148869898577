import React from 'react'
import { Col, Container } from 'reactstrap'
import { map } from 'underscore'
import Button from 'components/base/button';
import { Link } from 'react-router-dom';
/* Assets */
import styles from '../violationTypeModal/index.module.sass'


const ParkingLots = ({lots, violationType, closeModal}) => {

  return (
    <Container className='mw-100'>
      <Col className={`my-4 mb-2 text-center`}>
        <p className={`${styles.modalTitle} mb-2`}>{`Violation Rule - ${violationType}`}</p>
        <span className={`${styles.boldText} text-success`}>Active Parking Lots</span>
      </Col>
      <Col className={`${styles.modalContent} d-flex flex-column`} xs={12}>
        {map(lots, lot => {
          return (
            <div key={lot.id} className={`${styles.lot} d-flex align-items-center mb-3 mx-5` }>
              <span className={`${styles.icon} ${styles['icon-success']} mx-2`}> </span>
              <span >{lot.name}</span>
              <div className='flex-grow-1 text-right'>
                <Link to={`/dashboard/parking_lots/${lot.id}/rules`} className='justify-self-end text-primary text-decoration-none mr-3'>
                  Edit
                </Link>
              </div>
            </div>
          )
        })}
      </Col>
      <Col className='d-flex justify-content-center align-items-center mt-4 mb-2'>
        <Button 
          className={`${styles.btn} ${styles['btn-light']}`}
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Col>
    </Container>
  )
}

export default ParkingLots;
