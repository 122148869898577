import React, { useState } from "react";
/* Styles/Assets */
import { ReactComponent as SearchIcon } from "assets/search_icon.svg";
import { ReactComponent as FilterIcon } from "assets/filter_icon.svg";
import { ReactComponent as SettingIcon } from "assets/setting-gear-icon.svg";
import { ReactComponent as PenToSquareSvg } from "assets/pen-to-square.svg";
import styles from "./index.module.sass";
/* Base */
import Button from "components/base/button";
import NavigationToolbar from "components/base/navigationToolbar";
import { Col, Row } from "reactstrap";
import SlideButton from "components/base/slide_button";
import Tabs from "components/base/tabs";
/* Component */
import PrivateEventsFilterForm from "./private_events/filterForm";
import CityEventsFilterForm from "./city_events/filterForm";

const tabs = [
  { label: "City Events", value: "city" },
  { label: "Private Events", value: "private" },
];

const CollapseContent = ({
  activeTab,
  filters,
  startFetching,
  setList,
  setFilters,
  match,
  resource,
  listType,
}) => {
  return (
    <Row
      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0 pr-1`}
      sm="12"
    >
      {activeTab === "filters" && listType === "private" && (
        <PrivateEventsFilterForm
          startFetching={startFetching}
          setList={setList}
          match={match}
          resource={resource}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {activeTab === "filters" && listType === "city" && (
        <CityEventsFilterForm
          startFetching={startFetching}
          setList={setList}
          match={match}
          resource={resource}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </Row>
  );
};

const ToolBar = (props) => {
  const {
    activeTab,
    setActiveTab,
    isResourceFetching,
    openModal,
    modalType,
    setListType,
    listType,
    search,
    setSearch,
  } = props;
  // eslint-disable-next-line
  const [showSearchBox, setShowSearchBox] = useState();

  const handleToggleCollapse = (tab) => {
    if (isResourceFetching) return;
    setActiveTab((activeTab) => (activeTab === tab ? null : tab));
  };

  const handleTabsChange = (tab) => {
    setSearch('')
    setActiveTab("");
    setListType(tab);
  };

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent activeTab={activeTab} listType={listType} {...props} />
      }
    >
      <Col className="d-flex justify-content-start m-0 p-0 pl-1">
        <Tabs list={tabs} defaultTab="city" onClick={handleTabsChange} />
      </Col>

      <Col className="d-flex justify-content-end px-0">
      <SlideButton
          isActive={true}
          className={`${styles.button} ${
            activeTab === "create" ? styles.activeTab : ""
          }`}
          icon={<PenToSquareSvg />}
          onClick={() => {
            openModal("create");
          }}
        >
          <span className="mr-3 d-inline-block" style={{ width: "140px" }}>
            Create New Event
          </span>
        </SlideButton>
        <SlideButton
          isActive={search ? true : false}
          hideIconOnHover={true}
          icon={<SearchIcon />}
          className={styles.btnSearch}
          onClick={() => {
            setShowSearchBox(true);
          }}
        >
          <SearchIcon
            style={{ position: "absolute", top: "8px", left: "6px" }}
          />
          <input
            type="search"
            name="search"
            value={search}
            placeholder="Type to search"
            className={`form-control shadow-none ${styles.searchInput}`}
            onChange={(e) => setSearch(e.target.value)}
          />
        </SlideButton>
        

        <Button
          onClick={() => handleToggleCollapse("filters")}
          className={`${listType === 'city' ? styles.btnFilter : styles.btnFilter2} ${
            activeTab === "filters" ? styles.activeTab : ""
          }`}
          icon={
            <FilterIcon
              className={`${activeTab === "filters" ? styles.activeIcon : ""}`}
            />
          }
          size="md"
        />
        {listType === "private" && (
          <Button
            className={`${styles.btnSettings} ${
              modalType === "setting" ? styles.activeTab : ""
            }`}
            icon={
              <SettingIcon
                className={`${
                  modalType === "setting" ? styles.activeIcon : ""
                }`}
              />
            }
            onClick={() => {
              openModal("setting");
            }}
          ></Button>
        )}
      </Col>
    </NavigationToolbar>
  );
};

export default ToolBar;
