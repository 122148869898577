import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {DatePicker} from 'antd';
import { asField } from 'informed';
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { isDate } from 'underscore';
import dayjs from 'dayjs';

const SubscriberDateInput = asField(({ fieldState, fieldApi, events = {}, ...props }) => {
  const { setValue } = fieldApi;
  const { value } = fieldState;
  const { className, fieldProps={} } = props;
  const { min, max, customIconClass='', dateFormat, placeholder, popperPlacement='bottom-start' , disabledDate=false} = fieldProps

  const validDate = (date) => 
  isDate(date) ? date : ''
  const [selected, setSelected] = useState(validDate(value?.$d) )

  const { onChange} = events
  const onInputChange = (e) => {
    setSelected(e)
    setValue(e)
    onChange && onChange(e)
  }

    // eslint-disable-next-line arrow-body-style
  const disabledDates = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
  };
  
  useEffect(() => {
    if(value?.$d  === undefined) { 
      setSelected('') 
      return
    }
    const date = new Date(value?.$d)
    if(isDate(date)) {
      setSelected(date)
    }
  }, [value])


  return (
    <div className="position-relative" xs='12' >
      <DatePicker
        onSelect={(e) => onInputChange(e)}
        showIcon={false}
        value={value? value : undefined}
        allowClear={false}
        disabledDate={disabledDate ? disabledDates : '' }
        wrapperClassName='w-100'
        className={`${className || ''} ${styles.input} form-control shadow-none`}
        selected={selected}
        format={dateFormat || 'DD/MM/YYYY'}
        readOnly={!!props.disabled}
        max={validDate(max)}
        min={validDate(min)}
        placeholder={placeholder}
        placement={popperPlacement}
        suffixIcon={<FontAwesomeIcon icon={faCalendarAlt} color='grey' className={`${styles.calenderIcon} ${customIconClass}`}/>}
      />
    </div>
  );
});

SubscriberDateInput.propTypes = {
  field: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  className: PropTypes.string
};

export default SubscriberDateInput;