import React, { useCallback, useContext, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { capitalize } from 'components/helpers';
/* API */
import { revokePermit } from 'api/permits';
import {  create } from 'api/permit_types';
/* Actions */
import { SET_LIST_ELEMENT } from 'actions/carpool_pemits';
/* Components */
import RevokePermit from '../revoke';
/* Styles/Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Create from '../permitType/create';

const initState = { errors: {}, isSaving: false, record: {}, parkingLots: [], codes: [], dropdownsFetched: false }

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'record':
      return {...state, record: payload }
    case 'dropdowns':
      return { ...state, ...payload}
    default:
      return { ...state };
  }
}

const PermitModal = ({openModal, modalType, setPermitType }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  
  const dispatchAction = useDispatch();

  const setListElement = useCallback((payload) => {
    dispatchAction({ type: SET_LIST_ELEMENT, payload })
  },[dispatchAction]);
  
  const modalProps = useCallback(() => {
    let width = '480px'
    switch (modalType) {
      case 'new_permit_type':
        width = '678px'
        break;
      default:
        break;
    }
    return { width }
  },[modalType])
      
  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  },[dispatch])
  
  const closeModal = useCallback(() => {
    openModal(); 
  }, [openModal])
        
  const revokeIssuedPermit = useCallback(async (values) => {
    setState('isSaving', true)
    try {
      const response = await revokePermit(values)
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'danger',
          text:  `${capitalize(state.record.permit_type)} was successfully revoked`,
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, setListElement, state.record])

  const createCarpoolPermit = useCallback(async (data) => {
    try {
      const response = await create({ data: { permit_type: data } })
      setPermitType(response.data)
      addAlertMessages([{
        type: 'primary',
        text:  `${response.data.name} type was successfully created`,
        onlyMessage: true,
      }], 'center')
      closeModal()
    } catch (error) {
      setState('errors', error.response?.data?.errors)
    }

  },[setState, setPermitType, closeModal, addAlertMessages])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);
 
  return (
    <Modal 
      isOpen={!!modalType} 
      centered contentClassName={styles.permitModal} 
      style={{maxWidth: modalProps().width, width: '100%'}}
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        {
          modalType === 'revoke' && 
          <RevokePermit
            {...state}
            revokePermit={revokeIssuedPermit} 
            setState={setState}
            closeModal={closeModal} 
          />
        }

        { modalType === 'new_permit_type' && 
          <Create 
            {...state}
            closeModal={closeModal}
            setState={setState}
            createCarpoolPermit={createCarpoolPermit}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal;
