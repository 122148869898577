import React, { useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import styles from './index.module.sass';
import Toggle from 'components/base/toggle';
import { map } from 'underscore';
import { useSelector } from 'react-redux';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) setShowText(false)}}
      className='d-flex align-items-center justify-content-center'
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const TableRow = (props) => {
  const { record, openModal, setRecord, modalType } = props
  const selectedRecordId = useSelector(state => state.permit_type.records.record?.id)
  
  const [highlightEnabled, setHighlightEnabled] = useState(false)

  useEffect(() => {
    setHighlightEnabled(modalType ==='activate_deactivate')
  },[modalType])

  const actions = [
    { 
      icon: <EditIcon className='mr-3'/>,
      highlightedClass: `${styles['background-secondary']} mr-3 text-white`,
      onClick: () => {openModal('edit')},
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecordId === record.id
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record?.violation_type?.display_name}</td>
      <td>
        <Toggle
          onClick={() => {setHighlightEnabled(true); openModal('activate_deactivate')}}
          customToggleClass= {styles.toggle}
          customToggleOnClass= {styles.toggleOn}
          value={record.status === true}
          positiveText=''
          negativeText=''
          offHighlightClass={styles.toggleHighlight}
          onHighlightClass={styles.toggleOnHighlight}
          highlightEnabled={highlightEnabled && modalType === 'activate_deactivate' && selectedRecordId === record.id}
        />
      </td>
      <td className='text-capitalize'>{record.admin_id}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center`} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  );
};

export default TableRow
